import { ASSETS_PATH } from '@health-activity-ui/shared';
// RDS Components
import { RdsLink } from '@rally/energon-react/dist/es6';
import React from 'react';
import { Container, Section } from '../layout';

export const FiveZeroZero = (): React.ReactElement => (
  <Section className="has-text-centered has-rds-pb-none" isFirst data-testid="five-zero-zero">
    <Container>
      <h1 className="is-rds-h1">
        We&apos;re having a little trouble with this page. <br />
        Sorry for the paws in your experience.
      </h1>
      <p className="is-rds-body-1 has-rds-mb-64">
        If you keep seeing this message,
        <br />
        let us know at{' '}
        <RdsLink href="mailto:support@werally.com" variant="inline">
          support@werally.com
        </RdsLink>
      </p>
      <img className="cat" src={`${ASSETS_PATH}/img/errors/500_cat.png`} alt="" />
    </Container>
  </Section>
);

export default FiveZeroZero;
