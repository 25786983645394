import React from 'react';

interface Props {
  width?: number;
  height?: number;
}

export const ExerciseIcon = ({ width = 200, height = 165 }: Props): React.ReactElement => (
  <svg viewBox="0 0 200 165" width={width} height={height}>
    <ellipse fill="#E5E5E4" cx={115.6} cy={140.7} rx={25.8} ry={3.5} />
    <path fill="#E5E5E4" d="M131 55.1l-2.8-2.8-5.4 5.9.9.8-.1 6.5h8.7v-7.4c-.1-1-.5-2.1-1.3-3z" />
    <path
      fill="#FDC986"
      d="M141.1 77.3c-.6.6-.6 1.6 0 2.2.6.6 1.6.6 2.2 0l2.5-2.4c.6-.6.6-1.6 0-2.2-.6-.6-1.6-.6-2.2 0l-2.5 2.4z"
    />
    <path
      fill="#E5B77C"
      d="M76.9 54.5c0-.9-.7-1.6-1.6-1.6-.9 0-1.6.7-1.6 1.6V58c0 .9.7 1.6 1.6 1.6.9 0 1.6-.7 1.6-1.6v-3.5z"
    />
    <path
      fill="#E5B77C"
      d="M84.2 36.8c-1.2 0-2.3.5-3.1 1.3L67.6 51.5c-1.7 1.7-1.7 4.5 0 6.2 1.7 1.7 4.5 1.7 6.2 0L86 45.5h10.7v-8.7H84.2z"
    />
    <path
      fill="#FDC986"
      d="M146.5 76.5h-14.2V65.6h-8.7v15.3c0 2.4 2 4.4 4.4 4.4h18.6c2.4 0 4.4-2 4.4-4.4-.2-2.4-2.1-4.4-4.5-4.4z"
    />
    <path fill="#E5E5E4" d="M96.7 36.8v8.7h4l-.8.8 9.7-9.5z" />
    <path
      fill="#E5B77C"
      d="M115.8 43.1c-1.8 1.8-1.8 4.7 0 6.5 1.8 1.8 4.7 1.8 6.5 0l6.7-6.7c1.8-1.8 1.8-4.7 0-6.5-1.8-1.8-4.7-1.8-6.5 0l-6.7 6.7z"
    />
    <path
      fill="#E5B77C"
      d="M135.8 24.4c2 2.6 2.7 6.1 1.4 9.4l-2 5c-1.9 4.9-7.4 7.2-12.3 5.3l-1.8-.9c1 1.3 2.3 2.3 3.9 2.9 4.9 1.9 10.4-.4 12.3-5.3l2-5c1.7-4.3.1-9-3.5-11.4z"
    />
    <path
      fill="#FDC986"
      d="M135.2 38.8l2-5c1.3-3.3.7-6.8-1.4-9.4-.5-.4-1.1-.7-1.8-.9-4.9-1.9-10.4.4-12.3 5.3l-2 5c-1.3 3.3-.7 6.8 1.4 9.4.5.4 1.1.7 1.8.9 4.8 2 10.3-.4 12.3-5.3z"
    />
    <path
      fill="#FDC986"
      d="M132.4 38.6l2.1 2.2c.7.7 1.8.2 1.8-.7l.1-4.4c0-1-1.1-1.5-1.8-.8l-2.2 2.1c-.4.5-.4 1.2 0 1.6z"
    />
    <path
      fill="#796041"
      d="M138.1 26.6s-1.8.2-3.6-1.6c-1.8-1.7-6.5-.1-7.1 2-.5 2-.4 4.1-.4 4.1l-1.5.5s-3.8 5-6.4 4.2c0 0-2.3-1.4-.3-6.2 2-4.8 4.8-8.7 9.4-8.1.1 0 8.3-3.3 9.9 5.1z"
    />
    <path fill="#FDC986" d="M123.4 31.5c-.8.8-.8 2.1-.1 3l3.1-3.1c-.8-.8-2.1-.7-3 .1z" />
    <path
      fill="#E5E5E4"
      d="M113.9 38c-.8-.8-1.9-1.3-3.1-1.3h-1.1l-9.7 9.5-20.2 20.1L98.6 82l24.1-23.8 5.4-5.9L113.9 38z"
    />
    <path fill="#E5B77C" d="M76.1 105H55.2v12.5h27.2c3.5 0 6.3-2.8 6.3-6.3V99.8H76.1v5.2z" />
    <path
      fill="#2EAC9B"
      d="M80.2 68.1L78 70.4c-1.2 1.2-1.8 2.8-1.8 4.4v25h12.5V86.3l1.6 1.6-8.8-8.8c-.1 0-3.9-3.6-1.3-11z"
    />
    <path
      fill="#47BEB0"
      d="M97.3 83.3l1.3-1.3-18.8-15.7-3.7 3.7c-2.6 7.3 1.2 11 1.2 11l8.8 8.8 14.5 14.5 8.9-8.9-12.2-12.1z"
    />
    <path fill="#FDC986" d="M116.4 102.4l-6.9-6.9-8.9 8.9 5 5v25.7h12.5v-28.2c.1-1.8-.5-3.4-1.7-4.5z" />
    <path fill="#EF5B4B" d="M119.1 134.3c-7 0-12.7 2.4-13.5 5.4h26.9c-.7-3-6.4-5.4-13.4-5.4z" />
    <path fill="#EF5B4B" d="M105.7 134.3h13.5v5.4h-13.5z" />
    <path fill="#FFF" d="M105.7 139.8h26.9v1.3h-26.9z" />
    <g>
      <path fill="#EF5B4B" d="M55.9 118.5c0-7-2.4-12.7-5.4-13.5v26.9c3-.7 5.4-6.5 5.4-13.4z" />
      <path fill="#EF5B4B" d="M50.5 105h5.4v13.5h-5.4z" />
      <path fill="#FFF" d="M49.2 105h1.3v26.9h-1.3z" />
    </g>
  </svg>
);

export default ExerciseIcon;
