import React from 'react';
import classnames from 'classnames';
import { ZenTimerLabel } from '../timer.interface';
import { padZeros } from '@health-activity-ui/utils';
import { useZenTimerContext } from './zen-timer.component';

export const ZenTimerDays = ({
  label = 'Days',
  ariaLabel = 'Day',
  className = null,
}: ZenTimerLabel): React.ReactElement => {
  const { days } = useZenTimerContext();
  return (
    <span aria-hidden={true}>
      <span className="remaining-time-days">{padZeros(days, 2)}</span>
      <span
        className={classnames(
          'remaining-time-days-label timer-label',
          {
            'has-rds-mr-8': !className,
          },
          className
        )}
        aria-label={ariaLabel}
      >
        {label}
      </span>
    </span>
  );
};
