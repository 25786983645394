import React from 'react';
import { RDS_COLOR_NEUTRAL_50, RDS_COLOR_SYSTEM_CAMARONE } from '@rally/ui-themes/dist/web/rally';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { DashCircleIcon } from '../icons/dash-circle-icon';
import { CheckMarkGreenIcon } from '../icons/check-mark-green-icon';

interface Props {
  isDailyCheckinCapMet: boolean;
  shouldShowDailyCapText?: boolean;
  className?: string;
}

export const DailyCheckinMetIndicator = ({
  isDailyCheckinCapMet,
  shouldShowDailyCapText = false,
  className = '',
}: Props) => {
  const { t } = useTranslation();

  return (
    <div
      className={classnames(
        'daily-checkin-met-indicator is-flex is-flex-direction-row is-justify-content-space-between is-align-items-baseline is-w-full',
        className
      )}
    >
      <div
        className="daily-checkin-met-indicator-inner is-rds-h6 is-flex is-flex-direction-row has-rds-pt-16 is-align-items-center"
        style={{ color: isDailyCheckinCapMet ? RDS_COLOR_SYSTEM_CAMARONE : RDS_COLOR_NEUTRAL_50 }}
        data-testid="checkin-text"
      >
        <span className="has-rds-mr-8">{t('checkedIn')}</span>
        {isDailyCheckinCapMet ? <CheckMarkGreenIcon alt={t('yes')} /> : <DashCircleIcon alt={t('no')} />}
      </div>
      {shouldShowDailyCapText && <span className="has-rds-text-charcoal-light is-rds-body-3">{t('dailyMaxMet')}</span>}
    </div>
  );
};
