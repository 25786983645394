import React from 'react';

export const PodiumIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg width={153} height={137} {...props}>
    <defs>
      <path id="podium_icon_prefix__a" d="M0 136.644h152.354V.157H0z" />
      <path id="podium_icon_prefix__c" d="M0 136.644h152.354V.157H0z" />
      <path id="podium_icon_prefix__e" d="M0 136.644h152.354V.157H0z" />
      <path id="podium_icon_prefix__g" d="M0 136.644h152.354V.157H0z" />
      <path id="podium_icon_prefix__i" d="M0 136.644h152.354V.157H0z" />
      <path id="podium_icon_prefix__k" d="M0 136.644h152.354V.157H0z" />
      <path id="podium_icon_prefix__m" d="M0 136.644h152.354V.157H0z" />
      <path id="podium_icon_prefix__o" d="M0 136.644h152.354V.157H0z" />
      <path id="podium_icon_prefix__q" d="M0 136.644h152.354V.157H0z" />
      <path id="podium_icon_prefix__s" d="M0 136.644h152.354V.157H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill="#999" fillRule="nonzero" d="M94.14 102.301v34.628h58.213l-4.422-34.628z" />
      <path fill="#B3B4B4" fillRule="nonzero" d="M4.422 86.442L0 136.929h58.213V86.442z" />
      <path fill="#CAC9C9" fillRule="nonzero" d="M53.616 61.38l-4.423 75.55h53.792l-4.423-75.55z" />
      <path
        d="M106.772 10.04c0-.94-.763-1.703-1.703-1.703H80.35v3.407h22.03c.487 0 .866.432.795.914-1.455 9.949-11.093 17.695-22.825 17.943v3.412c14.622-.275 26.422-10.922 26.422-23.972"
        fill="#CFAF39"
        fillRule="nonzero"
      />
      <path
        d="M73.515 30.613c-11.993 0-21.925-7.842-23.404-17.955a.802.802 0 01.795-.914h23.188V8.337H48.218c-.941 0-1.704.763-1.704 1.704 0 13.221 12.113 23.978 27 23.978.194 0 .387-.003.58-.007v-3.41c-.193.003-.386.01-.58.01"
        fill="#DFBD3E"
        fillRule="nonzero"
      />
      <g transform="translate(0 .285)">
        <mask id="podium_icon_prefix__b" fill="#fff">
          <use xlinkHref="#podium_icon_prefix__a" />
        </mask>
        <path
          fill="#DFBD3E"
          fillRule="nonzero"
          mask="url(#podium_icon_prefix__b)"
          d="M70.541 45.803H82.4V35.651H70.541z"
        />
      </g>
      <g transform="translate(0 .285)">
        <mask id="podium_icon_prefix__d" fill="#fff">
          <use xlinkHref="#podium_icon_prefix__c" />
        </mask>
        <path
          d="M94.971 4.05c0 17.453-8.283 31.6-18.5 31.6-10.219 0-18.502-14.147-18.502-31.6h37.002z"
          fill="#DFBD3E"
          fillRule="nonzero"
          mask="url(#podium_icon_prefix__d)"
        />
      </g>
      <g transform="translate(0 .285)">
        <mask id="podium_icon_prefix__f" fill="#fff">
          <use xlinkHref="#podium_icon_prefix__e" />
        </mask>
        <path
          d="M84.068 37.483H68.872a.849.849 0 01-.849-.85V34.21c0-.47.38-.85.85-.85h15.195c.47 0 .849.38.849.85v2.424c0 .469-.38.849-.849.849"
          fill="#CFAF39"
          fillRule="nonzero"
          mask="url(#podium_icon_prefix__f)"
        />
      </g>
      <g transform="translate(0 .285)">
        <mask id="podium_icon_prefix__h" fill="#fff">
          <use xlinkHref="#podium_icon_prefix__g" />
        </mask>
        <path
          d="M87.43 4.05c0 14.28-4.764 26.508-11.523 31.585.187.01.375.016.563.016 10.218 0 18.501-14.148 18.501-31.601h-7.54z"
          fill="#CFAF39"
          fillRule="nonzero"
          mask="url(#podium_icon_prefix__h)"
        />
      </g>
      <g transform="translate(0 .285)">
        <mask id="podium_icon_prefix__j" fill="#fff">
          <use xlinkHref="#podium_icon_prefix__i" />
        </mask>
        <path
          fill="#CFAF39"
          fillRule="nonzero"
          mask="url(#podium_icon_prefix__j)"
          d="M88.067 7.942H58.026l-.043-2.06-.014-2.061 3.197.458h26.9z"
        />
      </g>
      <g transform="translate(0 .285)">
        <mask id="podium_icon_prefix__l" fill="#fff">
          <use xlinkHref="#podium_icon_prefix__k" />
        </mask>
        <path
          d="M95.078 4.279H57.863a.849.849 0 01-.85-.85V1.007a.85.85 0 01.85-.849h37.215c.469 0 .848.38.848.849V3.43c0 .469-.38.849-.848.849"
          fill="#EFCA43"
          fillRule="nonzero"
          mask="url(#podium_icon_prefix__l)"
        />
      </g>
      <g transform="translate(0 .285)">
        <mask id="podium_icon_prefix__n" fill="#fff">
          <use xlinkHref="#podium_icon_prefix__m" />
        </mask>
        <path
          d="M90.29 47.135a3.554 3.554 0 00-3.554-3.505H65.224a3.554 3.554 0 00-3.554 3.505l-.056 1.817h28.732l-.056-1.817z"
          fill="#EFCA43"
          fillRule="nonzero"
          mask="url(#podium_icon_prefix__n)"
        />
      </g>
      <g transform="translate(0 .285)">
        <mask id="podium_icon_prefix__p" fill="#fff">
          <use xlinkHref="#podium_icon_prefix__o" />
        </mask>
        <path
          d="M54.171 56.404v4.664H97.79v-4.663a3.554 3.554 0 00-3.716-3.55H57.887a3.554 3.554 0 00-3.716 3.55"
          fill="#EFCA43"
          fillRule="nonzero"
          mask="url(#podium_icon_prefix__p)"
        />
      </g>
      <g transform="translate(0 .285)">
        <mask id="podium_icon_prefix__r" fill="#fff">
          <use xlinkHref="#podium_icon_prefix__q" />
        </mask>
        <path
          d="M90.358 49.353l-.012-.4H61.614l-.012.4a3.554 3.554 0 01-3.715 3.501h36.186a3.554 3.554 0 01-3.715-3.501"
          fill="#DFBD3E"
          fillRule="nonzero"
          mask="url(#podium_icon_prefix__r)"
        />
      </g>
      <g transform="translate(0 .285)">
        <mask id="podium_icon_prefix__t" fill="#fff">
          <use xlinkHref="#podium_icon_prefix__s" />
        </mask>
        <path
          fill="#E59D50"
          fillRule="nonzero"
          mask="url(#podium_icon_prefix__t)"
          d="M54.171 61.068H97.79v-4.664H54.17z"
        />
      </g>
    </g>
  </svg>
);

export default PodiumIcon;
