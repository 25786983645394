import { RdsLink, LinkProps as RdsLinkProps } from '@rally/energon-react/dist/es6';
import React from 'react';
import { LinkProps, useHref, useLinkClickHandler } from 'react-router-dom';

type Props = RdsLinkProps & LinkProps;

export const RdsRouterLink = React.forwardRef<HTMLAnchorElement, Props>(
  ({ onClick, replace = false, state, target, to, ...rest }, ref) => {
    const href = useHref(to);
    const handleClick = useLinkClickHandler(to, {
      replace,
      state,
      target,
    });
    return (
      <RdsLink
        {...rest}
        href={href}
        onClick={(event) => {
          onClick?.(event);
          if (!event.defaultPrevented) {
            handleClick(event);
          }
        }}
        ref={ref}
        target={target}
      />
    );
  }
);
