import { TrackingProvider } from '@health-activity-ui/core';
import {
  AuthorizationProvider,
  CommonProvider,
  DomainProvider,
  ProfileProvider,
  UiConfigProvider,
  EngageUserProfileProvider,
} from '@health-activity-ui/data-access';
import { combineContexts } from './combine-contexts';

const providers = [
  DomainProvider,
  ProfileProvider,
  UiConfigProvider,
  CommonProvider,
  TrackingProvider,
  AuthorizationProvider,
  EngageUserProfileProvider,
];

const AppContextProvider = combineContexts(...providers);

export default AppContextProvider;
