export interface IDashboardArticle {
  id: string;
  title: string;
  image: string;
  source: string;
  author: string;
  pubDate: Date;
  tags: string[];
}

export interface IArticle {
  id: string;
  title: string;
  description: string;
  image: string;
  pubDate: number[];
  source: string;
  url: string;
  curatedUrl?: string;
  expiration?: number;
  evergreen: boolean;
  body?: string;
  isCurated: boolean;
  published: boolean;
  weight: number;
  cmsType: string;
  author: string;
  extended: IArticleExtended;
  locale: string;
  articleExcerpt?: string;
}

export interface IArticleExtended {
  comments?: ICommentItem[];
  views?: number;
  cssClass?: string;
  injectImageUrl?: string;
  addedDate: number;
  lastModifiedDate: number;
  subtitle?: string;
  sourceId: string;
  startDate?: number;
  tags: string[];
}

export interface ICommentItem {
  userData: ISimpleUserItem;
  text: string;
  timestamp: Date;
}

export interface ISimpleUserItem {
  userId: string;
  displayName: string;
  email?: string;
  title?: string;
}

export interface IContentArticleCategory {
  id: string;
  title?: string;
  uri?: string;
}

export interface IContentArticle {
  id: string;
  title?: string;
  dates: IContentArticleDate;
  uri?: string;
  articleByline?: string;
  author?: string;
  articleSubtitle?: string;
  articleContent?: string;
  articleSource?: string;
  locationIDs?: string[];
  primaryCategory?: IContentArticleCategory;
  resourceCategories: IContentArticleCategory[];
  featuredImage?: IContentArticleMedia;
  articleExcerpt?: string;
  externalLinkUrl?: string;
  articleType?: string;
  userTag?: string;
  permalink?: string;
  metaTitle?: string;
  resourceType?: string;
}

export interface IContentArticleDate {
  dateCreated?: Date | string;
  dateUpdated?: Date | string;
  postDate?: Date | string;
  expiryDate?: Date | string;
}

export interface IContentArticleMedia {
  id?: string;
  url?: string;
  title?: string;
  contentType?: string;
}

export interface IContentArticleParams {
  // backend default is 0
  skip?: number;
  // backend default is 12
  limit?: number;
}

export type CategoryTitleStyleType = 'titlecase-space' | 'lowercase-hyphen';
export type CategoryScopeType = 'primary' | 'primary-secondary';

export enum OrderBy {
  'latest' = 'latest',
  'popular' = 'popular',
}

export interface IContentArticleCategoryParams extends IContentArticleParams {
  /**
   * Two possible values will return title as such
   *
   * Value("titlecase-space") //example "Mental Health"
   * Value("lowercase-hyphen") //example "mental-health"
   */
  categoryTitleStyle?: CategoryTitleStyleType;

  /**
   * How specific the category returned is
   *
   * Value("primary")
   * Value("primary-secondary")
   */
  categoryScope?: CategoryScopeType;
}

export interface IContentArticleOrderParams extends IContentArticleParams {
  /**
   * Two possible values will return ordered articles like so
   *
   * Value("latest")
   * Value("popular")
   */
  orderBy?: OrderBy;
}

export interface IContentArticleEntryParam {
  entryLevel?: number;
}

export interface IContentArticleSearchParams extends IContentArticleParams {
  search: string;
  categoryTitle?: string;

  /**
   * Two possible values will return title as such
   *
   * Value("titlecase-space") //example "Mental Health"
   * Value("lowercase-hyphen") //example "mental-health"
   */
  categoryTitleStyle?: CategoryTitleStyleType;

  /**
   * How specific the category returned is
   *
   * Value("primary")
   * Value("primary-secondary")
   */
  categoryScope?: CategoryScopeType;

  /**
   * Two possible values will return ordered articles like so
   *
   * Value("latest")
   * Value("popular")
   */
  orderBy?: OrderBy;
}

export interface IArticleResponse {
  articles: IContentArticle[];
  total: number;
}
