import React from 'react';
import classnames from 'classnames';
import { ZenTimerLabel } from '../timer.interface';
import { padZeros } from '@health-activity-ui/utils';
import { useZenTimerContext } from './zen-timer.component';

export const ZenTimerSeconds = ({
  label = 'Seconds',
  ariaLabel = 'Second',
  className = null,
}: ZenTimerLabel): React.ReactElement => {
  const { seconds } = useZenTimerContext();

  return (
    <span aria-hidden={true}>
      <span className="remaining-time-seconds">{padZeros(seconds, 2)}</span>
      <span
        className={classnames(
          'remaining-time-seconds-label timer-label',
          {
            'has-rds-mr-8': !className,
          },
          className
        )}
        aria-label={ariaLabel}
      >
        {label}
      </span>
    </span>
  );
};
