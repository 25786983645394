import { appConfig } from '../configs/app.config';

export const ROUTE_PRODUCT_NAME = appConfig.HAUI_APP_HA_ROUTE_PRODUCT_NAME;
export const ROUTE_PRODUCT_PATH = `/${appConfig.HAUI_APP_HA_ROUTE_PRODUCT_NAME}`;
export const ASSETS_PATH = `${ROUTE_PRODUCT_PATH}/haui/assets`;
export const NOTIFICATION_DELAY = 5000;
export const CDN_PROD_URL = 'https://rally-challenges-cdn.prod.werally.in';
export const CDN_NON_PROD_URL = 'https://rally-health-activity.int.werally.in';
export const CDN_RALLY_PROD_URL = 'https://rally-prod-cdn.prod.werally.in';
export const CDN_RALLY_NON_PROD_URL = 'https://rally-non-prod-cdn-int.werally.in';
export const DEVICES_MANUAL_CLIENT = 'devices_manualClient';
export const DEVICES_LAUNCH_URL = 'devices_launchUrl';
export const KAISER_PARTNER = 'kaiser_permanente';
export const KAISER_CLIENT = 'kaiser_base';
export const COMMUNITY_COMMENT_CHAR_LIMIT = 234;
export const COMMUNITY_DISCUSSION_DESCRIPTION_CHAR_LIMIT = 406;

/**
 * Base domains that use domain unification.
 * Note: When adding a new "Unified Base Domain" path here, also add it to the `shouldUseUnifiedDomain`
 * function in `data-access/src/utils.ts`.
 * More info: https://wiki.audaxhealth.com/display/ENG/Domain+Unification+Migration+Guides
 */
export const ADVANTAGE_BASE_URI = '/rest/advantage';
export const ALANUI_BASE_URI = '/rest/alanui';
export const CHALLENGE_V2_BASE_URI = '/rest/challengesv2';
export const COMMUNITY_BASE_URI = '/rest/communities';
export const CONTENT_ARTICLES_BASE_URI = '/rest/content/external/public/v1';
export const CONNECTION_BASE_URI = '/rest/communities/connection';
export const DEVICE_BASE_URI = '/rest/devices';
export const FUNNEL_BASE_URI = '/rest/funnel';
export const GEOLOCATION_BASE_URI = '/rest/care/geolocation/v1';

export const HEALTHPROFILE_BASE_URI = '/rest/healthprofile/v3';
export const MEMBER_BASE_URI = '/rest/care/member/v1';
export const MESSAGES_BASE_URI = '/rest/communities/messages';
export const MISSIONS_BASE_URI = '/rest/missions';
export const NOW_BASE_URI = '/rest/now';
export const PROFILE_BASE_URI = '/rest/profile';
export const PROGRAM_BASE_URI = '/rest/syllabus/v2';
export const R2C_BASE_V1_URI = '/rest/r2c/v1';
export const R2C_BASE_V2_URI = '/rest/r2c/v2';
export const SSO_BASE_URI = '/rest/sso';
export const SETTING_BASE_URI = '/rest/settings/v1/';

export const LEADER_BOARD_LIMIT = 10;
export const MIN_SWIPE_DISTANCE = 80;
