import React from 'react';

interface Props {
  width?: string;
  height?: string;
}

export const Meditation = ({ height, width }: Props): React.ReactElement => (
  <svg
    width={width ? width : '88px'}
    height={height ? height : '88px'}
    viewBox="0 0 88 88"
    aria-hidden="true"
    data-testid="meditation-icon"
  >
    <title>Meditation Icon</title>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g fill="#EFEFEF">
        <path d="M44,0 C49.5223456,0 54,4.47691797 54,10.0004745 C54,15.523082 49.5223456,20 44,20 C38.4776544,20 34,15.523082 34,10.0004745 C34,4.47691797 38.4776544,0 44,0" />
        <g id="Group-28" transform="translate(0.000000, 23.000000)">
          <path d="M75.1055197,55.8440753 C72.1669129,57.8949929 68.2494298,58.2376753 68.2494298,58.2376753 L48.7678118,61.4274165 L72.4932051,63.5932518 C72.4932051,63.5932518 78.4791826,63.8210165 78.9152275,60.4024753 C79.2415197,58.0088753 76.520441,56.5273694 75.1055197,55.8440753" />
          <path d="M23.3011062,38.8767435 C23.3011062,38.8767435 12.2002522,36.1414965 10.8940949,45.0305318 C10.1327466,50.1583435 13.5064095,52.5509082 16.6625444,53.6907671 L44.9995331,44.3420612 L23.3011062,38.8767435 Z" />
          <path d="M15.247623,56.2487718 C15.247623,56.2487718 10.0229938,58.2996894 10.6765669,61.6043482 C11.3291511,64.9090071 17.3151286,64.3395953 17.3151286,64.3395953 L47.2440275,59.4395482 L66.7256455,56.2487718 C66.7256455,56.2487718 70.5353534,55.7932424 73.5817354,53.8562071 C75.9765219,52.3747012 78.3703197,49.1839247 77.5002073,45.1949365 C75.649241,36.4208188 64.7669039,39.83936 64.7669039,39.83936 L15.247623,56.2487718 Z" />
          <path d="M25.1627511,5.85976471 C24.1433354,7.52037647 22.7590657,9.65515294 21.3995152,12.1450353 C18.6814028,17.0078118 15.9138522,19.7720471 11.7224815,24.1606588 C9.0043691,27.1247059 6.05982977,29.3785412 3.11430168,30.4459294 C0.736324155,31.2762353 -0.623226407,34.1222588 0.282481458,36.7312 C0.962751121,38.7469176 2.77416685,40.0513882 4.81299831,40.0513882 C5.37956011,40.0513882 5.83340281,40.0513882 6.39897584,39.8143059 C14.2141669,36.9682824 19.7640994,31.6189176 24.1818972,25.3336471 C25.4069758,23.4680471 26.5223017,21.6024471 27.6366388,19.8486588 L27.6366388,19.8786824 L27.6366388,37.3244235 L47.7975376,42.5423059 L59.9168185,38.1536941 L59.9168185,19.7596235 C61.0489534,21.5392941 62.182077,23.4359529 63.4279197,25.3336471 C67.8447286,31.6189176 73.3946612,36.9682824 81.210841,39.8143059 C81.776414,40.0513882 82.229268,40.0513882 82.7958298,40.0513882 C84.8346612,40.0513882 86.6470657,38.7469176 87.3263466,36.7312 C88.2330433,34.1222588 86.8734927,31.2762353 84.4945264,30.4459294 C81.5499871,29.3785412 78.6054478,27.1247059 75.8873354,24.1606588 C71.6959646,19.7720471 68.928414,17.0078118 66.2093129,12.1450353 C64.8507511,9.65515294 63.4664815,7.52037647 62.4470657,5.85976471 L60.2035601,2.75595294 C59.0743916,1.09223529 57.2214478,7.31859018e-13 55.1351556,7.31859018e-13 L32.4469758,7.31859018e-13 C30.3043242,7.31859018e-13 28.406886,1.15021176 27.2866163,2.88950588 L25.1627511,5.85976471 Z" />
        </g>
      </g>
    </g>
  </svg>
);

export default Meditation;
