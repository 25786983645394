import React from 'react';

interface Props {
  circleStroke?: string;
}

export const ExclamationIcon = ({ circleStroke = '#F26B33' }: Props): React.ReactElement => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="#FFFFFF"
    stroke="#F26B33"
    strokeWidth="2.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <circle r="11" cx="12" cy="12" fill="#FFFFFF" strokeWidth="1px" stroke={circleStroke} />
    <line x1="12" y1="6" x2="12" y2="13.5" strokeWidth={2.5} />
    <line x1="12" y1="18" x2="12.01" y2="18" strokeWidth={3} />
  </svg>
);

export default ExclamationIcon;
