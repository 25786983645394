export enum DATE_FORMAT {
  NORMAL = 'MM/dd/yyyy',
  NORMAL_TWO = 'MMMM do, yyyy',
  NORMAL_THREE = 'MM/dd/yy',
  SHORT = 'MM/dd',
  SHORT_TWO = 'MMM do',
  SHORT_THREE = 'EEEE, MMMM d',
  MONTH_DAY = 'MMMM do',
  LONG = 'MM/dd/yyyy h:mm a',
  LONG_TWO = 'MM/dd/yyyy, h:mm a',
  DAY_OF_WEEK_MONTH_DAY = 'EEEE, MMMM d',
}
