import React from 'react';
import { useTranslation } from 'react-i18next';
import { RdsButton } from '@rally/energon-react/dist/es6';
import { PropsWithChildren } from '@health-activity-ui/shared';

interface Props extends PropsWithChildren {
  onClick(): void;
  ariaLabel: string;
  className?: string;
  size?: 'small' | '';
}

export const SeeLessButton = ({ onClick, className = '', ariaLabel, size = '' }: Props): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <RdsButton
      className={className}
      aria-label={ariaLabel}
      onClick={onClick}
      type="button"
      variant="tertiary"
      size={size}
    >
      {t('showLess')}
    </RdsButton>
  );
};

export default SeeLessButton;
