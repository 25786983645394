import React from 'react';

export const SyncIcon = (): React.ReactElement => (
  <svg
    width="35px"
    height="36px"
    viewBox="0 0 35 36"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className="syncIcon"
  >
    <title>Sync Icon</title>
    <g id="Flows" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="7b.-Team-Battle-Joined-Not-Started-Expanded-Challenges-2.0"
        transform="translate(-345.000000, -465.000000)"
      >
        <g id="Group-9" transform="translate(342.000000, 465.000000)">
          <g id="Group-7">
            <g id="Group-11" transform="translate(0.444444, 0.000000)">
              <g id="Sync_color-sm" transform="translate(2.555556, 0.000000)">
                <path
                  d="M13.5795414,14.8542465 L9.2238585,10.4756831 C9.31692434,10.3745282 9.40923354,10.2741338 9.50709139,10.1757676 C13.864792,5.79542958 20.9559548,5.79492254 25.3141598,10.1757676 C27.7719555,12.646331 28.8430953,15.9912887 28.5275794,19.2246972 L33.8467082,19.2241901 L34.1349853,19.2252042 C34.4394039,14.5515423 32.8186964,9.77545775 29.2655486,6.20385211 C22.7179004,-0.378063378 12.1030986,-0.377556336 5.55570259,6.20385211 C5.45784474,6.30221831 5.36679659,6.40388028 5.27196528,6.50351409 L1.2610547,2.47151409 L1.2610547,13.600331 L1.26155912,14.8537394 L6.71915165,14.8542465 L13.5795414,14.8542465 Z"
                  id="Fill-1-Copy"
                  fill="#DEAE00"
                />
                <g id="Group-5-Copy" fill="#EFBC00">
                  <path
                    d="M12.3184111,13.6319831 L7.96272814,9.25341972 C8.05579397,9.15226479 8.14810318,9.05187042 8.24596102,8.95350423 C12.6036616,4.5731662 19.6950766,4.57265915 24.0530295,8.95350423 C26.5108251,11.4240676 27.581965,14.7690254 27.2664491,18.0024338 L32.5855778,18.0019268 L32.8738549,18.0029408 C33.1782735,13.3295324 31.557566,8.55319437 28.0044183,4.98158873 C21.4567701,-1.60007324 10.8419682,-1.59981972 4.29457222,4.98158873 C4.19671438,5.07995493 4.10566623,5.1816169 4.01108713,5.2812507 L-7.5663282e-05,1.24950423 L-7.5663282e-05,12.3780676 L0.000428758598,13.6314761 L5.45802129,13.6319831 L12.3184111,13.6319831 Z"
                    id="Fill-3"
                  />
                </g>
                <path
                  d="M34.7111853,34.4999155 L34.7111853,22.4601972 L34.7111853,22.1179437 L29.0472842,22.1174366 L22.3926985,22.1174366 L26.73224,26.4795211 C26.5549357,26.6861408 26.372335,26.8887042 26.1776281,27.084169 C21.8196753,31.4647606 14.7297735,31.4647606 10.3723251,27.0846761 C7.8403795,24.539831 6.78285903,21.0678592 7.1927018,17.7464789 L2.12502738,17.7467324 L1.57041553,17.7464789 C1.17948857,22.5111549 2.7938908,27.4107042 6.42093633,31.0565915 C12.9680801,37.637493 23.5818731,37.638 30.1292691,31.0565915 C30.3232193,30.8616338 30.5098554,30.6631268 30.6927084,30.4603099 L34.7111853,34.4999155 Z"
                  id="Fill-6-Copy"
                  fill="#DEAE00"
                />
                <path
                  d="M33.7022814,33.485831 L33.7022814,21.4461127 L33.7022814,21.1038592 L28.0383804,21.1033521 L21.3837947,21.1033521 L25.7233361,25.4654366 C25.5462841,25.6720563 25.3634311,25.8746197 25.1687243,26.070338 C20.8107715,30.4506761 13.7208697,30.4506761 9.36342131,26.0705915 C6.83147568,23.5257465 5.77395521,20.0540282 6.18379799,16.7323944 L1.11637578,16.7326479 L0.561511711,16.7323944 C0.170836965,21.4970704 1.78498698,26.3966197 5.41203251,30.042507 C11.9591763,36.623662 22.5732215,36.6239155 29.1203653,30.042507 C29.3143155,29.8475493 29.5009516,29.6490423 29.6838045,29.4462254 L33.7022814,33.485831 Z"
                  id="Fill-8-Copy"
                  fill="#EFBC00"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SyncIcon;
