import { SESSION, localWindow } from '@health-activity-ui/shared';

import { Config } from 'amplitude-js';
import Cookies from 'js-cookie';

export const amplitudeBaseConfig: Config = {
  deviceId: Cookies.get(SESSION.COOKIE.AMPLITUDE.DEVICE_ID),
  deviceIdFromUrlParam: false,
  domain: localWindow.location?.hostname,
  forceHttps: true,
  includeGclid: true,
  includeReferrer: true,
  includeUtm: true,
};
