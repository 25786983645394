import { FeatureData, Status } from '@health-activity-ui/shared';

export interface EngageUserProfileState {
  displayName: string;
  roles: string[];
  permissions: string[];
  status: Status;
  featureData?: FeatureData;
}

export enum ENGAGE_USER_PROFILE_ACTION_TYPES {
  ENGAGE_USER_PROFILE_FETCH_FAILED = 'ENGAGE_USER_PROFILE_FETCH_FAILED',
  ENGAGE_USER_PROFILE_FETCH_LOADING = 'ENGAGE_USER_PROFILE_FETCH_LOADING',
  ENGAGE_USER_PROFILE_FETCH_LOADED = 'ENGAGE_USER_PROFILE_FETCH_LOADED',
}
