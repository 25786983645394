// routes serve by health-activity-ui

export const ACTIVITIES_BASE_ROUTE = '/activities';
export const APPOINTMENT = '/appointment';
export const CHALLENGES_2_BASE_ROUTE = '/v2/challenges';
export const CHALLENGES_BASE_ROUTE = '/challenges';
export const COMMUNITIES_BASE_ROUTE = '/communities';
export const COMMUNITIES_GALLERY_BASE_ROUTE = '/communities/gallery';
export const CREATE_PROFILE = '/createProfile';
export const CREATE_PROFILE_WIZARD = '/createProfile/wizard';
export const DASHBOARD_BASE_ROUTE = '/dashboard';
export const GOAL_SETTINGS_BASE_ROUTE = '/rallyage/readiness/v2';
export const GOAL_SETTINGS_CONFIRMATION_ROUTE = '/rallyage/readiness/v2/summary';
export const GOAL_SETTINGS_SELECT_GOALS_ROUTE = '/rallyage/readiness/v2/precontemplation';
export const GOAL_SETTINGS_SELECT_PLAN_ROUTE = '/rallyage/readiness/v2/programs';
export const GOAL_SETTINGS_2_BASE_ROUTE = '/goals';
export const MISSIONS_BASE_ROUTE = '/missions';
export const MISSIONS_DETAILS_BASE_ROUTE = '/missions/details';
export const MESSAGES_BASE_ROUTE = '/messages';
export const MESSAGES_CONVERSATION_BASE_ROUTE = '/messages/conversation';
export const NOTFOUND_ROUTE = '/notfound';
export const STAYING_WELL_BASE_ROUTE = '/staying-well-covid19';
export const GET_THE_APP_ROUTE = '/gettheapp';

// routes serve by other SPAs
export const DEVICES_MANAGE_ACTIVITY_EXTERNAL_ROUTE = '/devices/manageData/activity';

export enum WellnessProducts {
  WELLNESS_ACTIVITY_DASHBOARD = 'wellness_activity_dashboard',
  WELLNESS_CHALLENGES = 'wellness_challenges',
  WELLNESS_COACHING_DASHBOARD = 'wellness_coaching_dashboard',
  WELLNESS_COMMUNITIES = 'wellness_communities',
  WELLNESS_MISSIONS = 'wellness_missions',
  WELLNESS_STAYING_WELL = 'wellness_staying_well',
  WELLNESS = 'wellness',
}

export const ACTIVE_SUBNAV_OPTIONS = {
  [ACTIVITIES_BASE_ROUTE]: WellnessProducts.WELLNESS_CHALLENGES,
  [STAYING_WELL_BASE_ROUTE]: WellnessProducts.WELLNESS_STAYING_WELL,
  [CHALLENGES_2_BASE_ROUTE]: WellnessProducts.WELLNESS_CHALLENGES,
  [MISSIONS_BASE_ROUTE]: WellnessProducts.WELLNESS_MISSIONS,
};

// Routes that render the HeaderFooter as `ac-chrome-ua`
export const CHROME_UNAUTHORIZED_ROUTES = [CREATE_PROFILE, CREATE_PROFILE_WIZARD];
