import {
  ACTIVITIES_BASE_ROUTE,
  CHALLENGES_2_BASE_ROUTE,
  CHALLENGES_BASE_ROUTE,
  COMMUNITIES_BASE_ROUTE,
  CREATE_PROFILE_WIZARD,
  DASHBOARD_BASE_ROUTE,
  GET_THE_APP_ROUTE,
  GOAL_SETTINGS_BASE_ROUTE,
  MISSIONS_BASE_ROUTE,
  STAYING_WELL_BASE_ROUTE,
} from '@health-activity-ui/shared';
import { FourZeroFour } from '../errors/four-zero-four.component';
import { AcLoader } from '../loader/ac-loader.component';
import { IS_LOCAL } from '@health-activity-ui/utils';
import React from 'react';
import { useLocation } from 'react-router-dom';

export const NoMatch = (): React.ReactElement => {
  const location = useLocation();

  const hauiAppRoutes = [
    ACTIVITIES_BASE_ROUTE,
    CHALLENGES_2_BASE_ROUTE,
    CHALLENGES_BASE_ROUTE,
    COMMUNITIES_BASE_ROUTE,
    CREATE_PROFILE_WIZARD,
    DASHBOARD_BASE_ROUTE,
    GET_THE_APP_ROUTE,
    GOAL_SETTINGS_BASE_ROUTE,
    MISSIONS_BASE_ROUTE,
    STAYING_WELL_BASE_ROUTE,
  ];

  // If invalid HAUI route (e.g., "/activities/invalidPath") then show 404 page
  if (IS_LOCAL() || hauiAppRoutes.filter((route) => location.pathname.includes(route)).length > 0) {
    return <FourZeroFour />;
  }

  // Else redirect to pink-panther
  window.location.href = location.pathname;

  return <AcLoader />;
};

export default NoMatch;
