import {
  RDS_BASE_OUTLINE_COLOR,
  RDS_BASE_OUTLINE_STYLE,
  RDS_COLOR_NEUTRAL_95,
  RDS_SPACING_SIZE_16,
  RDS_SPACING_SIZE_8,
  RDS_UNIT_SIZE_3,
} from '@rally/ui-themes/dist/web/rally';
import { isDefined } from '@health-activity-ui/utils';

import Popup from 'reactjs-popup';
import { PopupPosition } from 'reactjs-popup/dist/types';
import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { PropsWithChildren } from '@health-activity-ui/shared';

const DropDownGlobalStyles = createGlobalStyle`
  .at-drop-down-menu {
    width: 165px;
  }

  .at-drop-down-menu-item {
    /* We can't use RDS class names here because these are children fed into the component */
    button, a {
      box-sizing: border-box;
      cursor: pointer;
      display: block;
      height: 100%;
      max-height: 32px;
      padding: ${RDS_SPACING_SIZE_8} ${RDS_SPACING_SIZE_16};
      text-align: left;
      width: 100%;
    }
  }

  .at-drop-down-menu-item:hover, .at-drop-down-menu-item:focus {
    background-color: ${RDS_COLOR_NEUTRAL_95};
  }

  button:focus, a:focus {
    outline: ${RDS_UNIT_SIZE_3} ${RDS_BASE_OUTLINE_STYLE} ${RDS_BASE_OUTLINE_COLOR};
    outline-offset: -${RDS_UNIT_SIZE_3};
  }

  .at-drop-down-trigger {
    width: 100%;
  }
`;

interface State {
  buttonRef: React.MutableRefObject<HTMLButtonElement>;
  setButtonRef(buttonRef: React.MutableRefObject<HTMLButtonElement>): void;
}

interface Props {
  children: React.ReactElement | React.ReactElement[];
  closeOnDocumentClick?: boolean;
  position?: PopupPosition;
}

// Create a DropDownContext with React.createContext
const DropDownContext = React.createContext<State>({
  buttonRef: null,
  setButtonRef: null,
});
// Context object accepts a displayName string property.
// React DevTools uses this string to determine what to display
// for the context.
DropDownContext.displayName = 'DropDown';
/**
 * @name DropDown
 * @author Alexi Taylor
 *
 * @desc DropDown uses the flexible compound component pattern with React's Context API.
 * A simple dropdown component. The end-user (other engineers) of the component have the
 * ability to define the dropdown menu items and also add their own custom styles. This
 * component utilizes the `reactjs-popup` library to help with the positioning of the dropdown
 * menu and triggering the popup menu.
 *
 * The `DropDown` component is composed of two sub-components:
 *
 * 1. `DropDown.Menu`: A container to house the drop-down items defined by the end-user.
 * By default, if no styles are applied to the menu item then the `DropDown`
 * component will apply its default styles.
 *
 * 2.  `DropDown.Button`: A button to trigger the dropdown menu. The end-user can
 * pass any content to be rendered inside of the button.
 * CodeSandBox example: https://codesandbox.io/s/react-dropdown-fby6r.
 *
 * @example:
 <DropDown>
  <DropDown.Button>
    Button
  </DropDown.Button>
  <DropDown.Menu>
    <div className="some-class">Item One</div>
    <div className="some-class">Item Two</div>
    <div className="some-class">Item Three</div>
    <div>Item Four</div>
  </DropDown.Menu>
 </DropDown>
 * */
export const DropDown = ({
  children,
  closeOnDocumentClick = true,
  position = 'bottom right',
}: Props): React.ReactElement => {
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [state, setState] = React.useState<State>({
    buttonRef: buttonRef,
    setButtonRef: null,
  });
  const [buttonEl, setButtonEl] = React.useState<React.ReactElement>(null);
  const [menuEl, setMenuEl] = React.useState<React.ReactElement>(null);
  const [buttonWidth, setButtonWidth] = React.useState<string>('100px');

  // Set Popup trigger button width so dropdown menu
  // is position exactly below the button.
  const setButtonTriggerWidth = (state: State): void => {
    const offsetWidthValue = state?.buttonRef?.current?.offsetWidth;
    if (isDefined(offsetWidthValue) && offsetWidthValue > 0) {
      setButtonWidth(`${offsetWidthValue}px`);
    }
  };

  // Add DropDown components default styles to menu items
  const setDropDownItemClassName = (children: React.ReactElement): React.ReactNode => {
    if (Array.isArray(children)) {
      return children.map((child: React.ReactElement, index: number) => {
        if (Array.isArray(child)) {
          return setDropDownItemClassName(child);
        }
        return React.cloneElement(child, {
          key: index,
          className: `at-drop-down-menu-item has-rds-border-none has-rds-text-charcoal is-rds-body-3 ${
            child.props.className || ''
          }`,
        });
      });
    } else {
      return React.cloneElement(children, {
        className: `at-drop-down-menu-item has-rds-border-none has-rds-text-charcoal is-rds-body-3 ${
          children.props.className || ''
        }`,
      });
    }
  };

  const setPopupElements = (children: React.ReactElement | React.ReactElement[]): void => {
    if (Array.isArray(children)) {
      children.forEach((child) => {
        // Without the DisplayName Decorator then use child.type.name
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (child.type.displayName === 'DropDownButton') {
          setButtonEl(child);
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
        } else if (child.type.displayName === 'DropDownMenu') {
          const newChild = React.cloneElement(child, {
            children: setDropDownItemClassName(child.props.children),
          });
          setMenuEl(newChild);
        }
      });
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (children.type.displayName === 'DropDownButton') {
        setButtonEl(children);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
      } else if (children.type.displayName === 'DropDownMenu') {
        const newChildren = React.cloneElement(children, {
          children: setDropDownItemClassName(children.props.children),
        });
        setMenuEl(newChildren);
      }
    }
  };

  React.useEffect(() => {
    setButtonTriggerWidth(state);
    setPopupElements(children);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children, state]);

  React.useEffect(() => {
    const removeUnwantedAttr = () => {
      containerRef.current?.firstElementChild?.removeAttribute('aria-describedby');
    };
    removeUnwantedAttr();
  }, [containerRef]);

  // memoized so that providerState isn't recreated on each render
  const providerState = React.useMemo(
    () => ({
      ...state,
      setButtonRef: (buttonRef: React.MutableRefObject<HTMLButtonElement>): void => {
        setState({
          ...state,
          buttonRef,
        });
      },
    }),
    [state]
  );

  return (
    <div className="at-drop-down" ref={containerRef}>
      <DropDownContext.Provider value={providerState}>
        <DropDownGlobalStyles />
        <Popup
          trigger={
            <div
              className="at-drop-down-trigger"
              style={{
                width: buttonWidth,
              }}
            >
              {buttonEl}
            </div>
          }
          on="click"
          position={position}
          closeOnDocumentClick={closeOnDocumentClick}
          closeOnEscape={true}
          mouseLeaveDelay={300}
          mouseEnterDelay={0}
          contentStyle={{ padding: '0px', border: 'none' }}
          arrow={false}
        >
          <div data-testid="dropdown">{menuEl}</div>
        </Popup>
      </DropDownContext.Provider>
    </div>
  );
};

interface ButtonProps extends PropsWithChildren {
  id?: string;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  onClick?: (e?: React.MouseEvent<HTMLButtonElement>) => unknown;
  onBlur?: (e?: React.MouseEvent<HTMLButtonElement>) => unknown;
  buttonRef?: React.LegacyRef<HTMLButtonElement>;
  ariaLabel?: string;
}

const Button = ({
  children,
  ariaLabel = 'Toggle dropdown',
  className = '',
  ...props
}: ButtonProps): React.ReactElement => {
  const { buttonRef, setButtonRef } = React.useContext(DropDownContext);

  React.useEffect(() => {
    setButtonRef(buttonRef);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <button
      {...props}
      ref={buttonRef}
      className={`at-drop-down-button has-rds-bg-none has-rds-border-none has-rds-p-0 has-rds-m-0 ${className}`}
      aria-label={ariaLabel}
    >
      {children}
    </button>
  );
};
Button.displayName = 'DropDownButton';

interface MenuProps extends PropsWithChildren {
  className?: string;
}

const Menu = ({ children, className = '' }: MenuProps): React.ReactElement => (
  <div
    className={`at-drop-down-menu has-rds-bg-white has-rds-radius-4 has-rds-box-shadow-neutral-spread-6 has-rds-pv-8 has-rds-m-8 is-flex is-justify-content-start is-flex-direction-column ${className}`}
  >
    {children}
  </div>
);
Menu.displayName = 'DropDownMenu';

DropDown.Button = Button;
DropDown.Menu = Menu;

export default DropDown;
