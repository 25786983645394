import { PropsWithChildren } from '@health-activity-ui/shared';
import classnames from 'classnames';
import React from 'react';
import { Element, ElementProps } from '../../element/element';

export interface SectionProps extends ElementProps, PropsWithChildren {
  // effects the amount of padding a section has on desktop and larger
  size?: 'medium' | 'large';

  // if the section is the first section on the page - increases top padding to 40px
  isFirst?: boolean;

  // if the section is the last section on the page - increases bottom padding to 64px
  isLast?: boolean;
}

// Generates a 'section' element that acts as a 'row' which should be a direct descendant of body / main.
export const Section = ({
  className = undefined,
  renderAs = 'section',
  domRef = undefined,
  children = null,
  size = undefined,
  isFirst = false,
  isLast = false,
  ...allProps
}: SectionProps) => (
  <Element
    {...allProps}
    renderAs={renderAs}
    className={classnames('section', className, {
      [`is-${size}`]: size,
      [`is-first`]: isFirst,
      [`is-last`]: isLast,
    })}
  >
    {children}
  </Element>
);

export default Section;
