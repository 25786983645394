import classnames from 'classnames';

// white ==> white ==> #ffffff
// white-bis ==> 98 ==> #fafafa
// grey-lightest ==> 95 ==> #f3f3f3
// grey-lighter ==> 90 ==> #e5e5e5
// grey-light ==> 80 ==> #cccccc
// grey ==> 50 ==> #808080
// grey-dark ==> 30 ==> #4d4d4d
// grey-darker ==> 20 ==> #333333
// black ==> black ==> #000000
export type NeutralColors =
  | 'white'
  | 'white-bis'
  | 'grey-lightest'
  | 'grey-lighter'
  | 'grey-light'
  | 'grey'
  | 'grey-dark'
  | 'grey-darker'
  | 'black';

// brand ==> rally brand orange ==> #f26c32
// primary ==> cerulean ==> #007897
// charcoal ==>  charcoal ==> #4d4d4d

// CURRENTLY MISSING FROM RDS: 'charcoal-light, 'charcoal-dark'
export type ThemeColors =
  | 'brand'
  | 'primary'
  | 'primary-light'
  | 'primary-dark'
  | 'charcoal'
  | 'charcoal-light'
  | 'charcoal-dark';

// success => camarone => #2c8652
// warning => dandelion => #ffcb61
// info => turqouise => #208476
// danger => grenadier => #cd3c3c

// CURRENTLY MISSING FROM RDS: all 'light' and 'dark' variants of 'success', 'warning', 'info' and 'danger'
export type FeedbackColors =
  | 'success'
  | 'success-light'
  | 'success-dark'
  | 'warning'
  | 'warning-light'
  | 'warning-dark'
  | 'info'
  | 'info-light'
  | 'info-dark'
  | 'danger'
  | 'danger-light'
  | 'danger-dark'
  | 'brown';

// blue-data => peacock => #008cb9
// turquoise-data => riviera => #22A191
// green-data => jade => #37a466
// orange-data => tangelo => #f2662c
// red-data => vermillion => #ef533e
export type DataVisualizationColors = 'blue-data' | 'green-data' | 'orange-data' | 'red-data' | 'turquoise-data';

export type AllColors = NeutralColors | ThemeColors | FeedbackColors | DataVisualizationColors;

export interface ColorProps {
  // the color to use for the color property
  color?: AllColors;

  // the color to use for the background-color property
  bgColor?: AllColors;

  // the color to use for the border-color property
  borderColor?: AllColors;

  // the color to use for the outline-color property
  outlineColor?: AllColors;
}

export default {
  defaultProps: {
    color: undefined,
    bgColor: undefined,
    borderColor: undefined,
    outlineColor: 'grey',
  },
  classnames: (props: ColorProps): string => {
    return classnames({
      [`has-rds-text-${props.color}`]: props.color,
      [`has-rds-bg-${props.bgColor}`]: props.bgColor,
      [`has-rds-border-${props.borderColor}`]: props.borderColor,
      [`has-rds-outline-${props.outlineColor}`]: props.outlineColor,
    });
  },
  /* eslint-disable-next-line */
  base: ({ color, bgColor, borderColor, outlineColor, ...props }) => props,
};
