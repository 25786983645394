import { AcuityState } from '@health-activity-ui/shared';
import {
  RDS_COLOR_SYSTEM_GRENADIER,
  RDS_COLOR_SYSTEM_JADE,
  RDS_COLOR_SYSTEM_PEACOCK,
} from '@rally/ui-themes/dist/web/rally';
import { ProgressBar } from '../progress-bar/progress-bar';
import React, { ReactElement } from 'react';

interface Props {
  acuityState: AcuityState;
}

export const AcuityMeter = ({ acuityState = AcuityState.Unknown }: Props): ReactElement => {
  const stateMap = {
    [AcuityState.High]: {
      value: 33,
      color: RDS_COLOR_SYSTEM_GRENADIER,
      description: 'needsWork',
    },
    [AcuityState.Medium]: {
      value: 66,
      color: RDS_COLOR_SYSTEM_PEACOCK,
      description: 'almostThere',
    },
    [AcuityState.Low]: {
      value: 100,
      color: RDS_COLOR_SYSTEM_JADE,
      description: 'doingGreat',
    },
    [AcuityState.Unknown]: {
      value: 0,
      color: null,
      description: '',
    },
  };

  const normalizedAcuityState = [AcuityState.Uncategorized, AcuityState.NotApplicable].includes(acuityState)
    ? AcuityState.Unknown
    : acuityState;

  return (
    <div className="progress">
      <small className="is-rds-sm">{stateMap[normalizedAcuityState].description}</small>
      <ProgressBar color={stateMap[normalizedAcuityState].color} percent={stateMap[normalizedAcuityState].value} />
    </div>
  );
};
