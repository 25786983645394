import { useActiveItem } from '@health-activity-ui/core';
import { setPreferredLocale, useProfile, useUiConfig } from '@health-activity-ui/data-access';
import { AdvantageConfig, appConfig, localWindow } from '@health-activity-ui/shared';
import { detectLanguage, getReactRoute, isReactRoute } from '@health-activity-ui/utils';
import { TriggerElementProperties } from '@rally/analytics/dist/models/triggerElement';
import { TriggerPageProperties } from '@rally/analytics/dist/models/triggerPage';
import i18n from 'i18next';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AcChromeProps, HeaderFooterProps, LocaleSelectedFromChrome } from './header-footer.models';

export interface AdvantageTriggers extends TriggerElementProperties, TriggerPageProperties {
  ['Trigger Summary']: string;
  ['Trigger Summary Extended']: string;
  ['Trigger URI']: string;
}

export interface AdvantageTrackingWithTriggers {
  href: string;
  trigger: AdvantageTriggers;
}

/**
 * A React wrapper component around the ac-chrome / ac-chrome-ua web component.
 * https://github.com/AudaxHealthInc/advantage-components/tree/master/src/components/chrome
 * https://github.com/AudaxHealthInc/advantage-components/tree/master/src/components/unauthorized/chrome
 *
 * @param {boolean} hasNotification Triggers a notification effect for `ac-chrome` / `ac-chrome-ua`
 * @param {boolean} chromeUa optional Renders `ac-chrome-ua` when `true`, defaults as `false`
 */
export const HeaderFooter = ({ children, hasNotification, chromeUa = false }: HeaderFooterProps) => {
  const acChromeRef = useRef<HTMLAcChromeElement>(null);
  const acChromeUaRef = useRef<HTMLAcChromeUaElement>(null);
  const navigate = useNavigate();
  const { state: profile } = useProfile();
  const { dispatch: dispatchUiConfig, state: uiConfig } = useUiConfig();
  const activeItem = useActiveItem();
  const { avatar, coins, isImpersonator, messageCount, profileName } = profile.data;
  const hasAdvantageAuth = uiConfig.hasAdvantageAuth;
  const [currentLocale, setCurrentLocale] = useState(() => detectLanguage());

  useEffect(() => {
    // if reference to ac-chrome is acquired, then set ac-chrome props
    if (acChromeRef.current) {
      const currentElRef = acChromeRef.current as AcChromeProps;
      currentElRef.activeItem = activeItem;
      currentElRef.avatarSrc = avatar ?? '';
      currentElRef.coins = coins;
      currentElRef.config = uiConfig.hasSubnav
        ? (uiConfig.data as AdvantageConfig)
        : (uiConfig.goalSettingsUiConfig as AdvantageConfig);
      currentElRef.displayName = profileName;
      currentElRef.environment =
        appConfig.HAUI_APP_HA_ENVIRONMENT === 'integration' ? 'int' : appConfig.HAUI_APP_HA_ENVIRONMENT;
      currentElRef.hasAmplitude = true;
      currentElRef.hasNotification = hasNotification;
      currentElRef.localeValue = currentLocale;
      currentElRef.shouldDoSmartRedirect = false;
      currentElRef.trackingProp = '{}';
      currentElRef.unreadCount = messageCount ?? 0;
      currentElRef.isAuthorizedForPage = hasAdvantageAuth;
    }
  }, [
    acChromeRef,
    activeItem,
    avatar,
    coins,
    hasAdvantageAuth,
    hasNotification,
    messageCount,
    profileName,
    uiConfig.data,
    uiConfig.goalSettingsUiConfig,
    uiConfig.hasSubnav,
    chromeUa,
    currentLocale,
  ]);

  useEffect(() => {
    // if we have impersonation, show the error component
    if (profile.data.isImpersonator) {
      setTimeout(() => {
        window.dispatchEvent(new CustomEvent('impersonationInterceptError'));
      }, 500);
    }
  }, [profile.data.isImpersonator]);

  useEffect(() => {
    const changeLocale = async (e: Event): Promise<void> => {
      const { detail } = e as CustomEvent<LocaleSelectedFromChrome>;
      setCurrentLocale(detail.locale);
      await setPreferredLocale(detail.locale, dispatchUiConfig);
      i18n.changeLanguage(detail.locale);
    };

    // AdvantageInteractions are clicks on the header/footer/subnav links, usually internal Rally navigations
    const handleAdvantageInteraction = (e: Event): void => {
      const { detail } = e as CustomEvent<AdvantageTrackingWithTriggers>;
      const { href } = detail;

      // Advantage doesn't currently support disabling the logo url
      // Created a ticket https://jira.rallyhealth.com/browse/FURY-1199
      // Disable the link clicked on the ac-chrome-ua logo
      // Importing rally-analytics `TriggerElementKeys.TriggerUIElementName` enum causes a build failure,
      // so using its string value 'Trigger UI Element Name'
      if (detail?.trigger['Trigger UI Element Name'] === 'logo-ua') {
        return;
      }

      const url = new URL(href);
      const { pathname } = url;

      e.preventDefault();

      // Check if pathname (subnav link) is one of our react routes if so then use React Router to navigate to avoid ful page reload.
      const reactRoute = getReactRoute(pathname, isReactRoute);

      if (reactRoute) {
        // react router handles the navigation - no full page reload
        navigate(reactRoute);
      } else {
        // external to the app - full page reload
        localWindow.location.href = href;
      }
    };

    document.addEventListener('localeSelectedFromChrome', changeLocale);
    document.addEventListener('languageSelectedFromChrome', changeLocale);
    document.addEventListener('chromeTrackingEvent', handleAdvantageInteraction);

    return (): void => {
      document.removeEventListener('localeSelectedFromChrome', changeLocale);
      document.addEventListener('languageSelectedFromChrome', changeLocale);
      document.removeEventListener('chromeTrackingEvent', handleAdvantageInteraction);
    };
  }, [dispatchUiConfig, navigate]);

  useEffect(() => {
    if (acChromeRef.current) {
      const acAccountBar = acChromeRef.current.querySelector('ac-account-bar');
      const skipNav = acAccountBar ? acAccountBar.shadowRoot.querySelector('.skip-nav') : null;

      if (!skipNav) {
        return;
      }

      const handleSkipNav = (e: Event): void => {
        const { hash } = e.currentTarget as HTMLAnchorElement;

        e.preventDefault();
        e.stopPropagation();

        document.getElementById(hash.replace('#', '')).focus();
      };

      skipNav.addEventListener('click', handleSkipNav);
      return (): void => {
        skipNav.removeEventListener('click', handleSkipNav);
      };
    }
  });

  return chromeUa ? (
    <ac-chrome-ua
      className="ac-chrome ua"
      data-testid="ac-chrome-ua"
      ref={acChromeUaRef}
      has-center-slot="false"
      has-amplitude="true"
    >
      {children}
    </ac-chrome-ua>
  ) : (
    <ac-chrome className="ac-chrome" data-testid="ac-chrome" ref={acChromeRef}>
      <ac-impersonation slot="impersonation" active={isImpersonator} />
      {children}
      <impersonation-alert
        msg="Function is not available to you as an advisor, but available to member"
        duration="5000"
      />
    </ac-chrome>
  );
};

export default HeaderFooter;
