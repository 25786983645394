import { appConfig, ASSETS_PATH, LOCALE } from '@health-activity-ui/shared';
import format from 'date-fns/format';
import { InitOptions, InterpolationOptions, ReactOptions } from 'i18next';
import { DetectorOptions } from 'i18next-browser-languagedetector';
import { BackendOptions } from 'i18next-http-backend';

export const backend: BackendOptions = {
  // load path needs to be publicly accessible since we are making an http request for this json
  // only items in the public folder are publicly accessibly unaltered after webpack minifies and compilies
  loadPath: `${ASSETS_PATH}/locales/{{lng}}/{{ns}}.json`,
};

export const detection: DetectorOptions = {
  // we already set up a 'cache' by using the 'x-rally-locale' cookie - dont need to store the locale value elsewhere
  caches: [],
  cookieDomain: appConfig.HAUI_APP_HA_BASE_DOMAIN,
  lookupCookie: LOCALE.COOKIE,
  order: ['cookie'],
};

export const interpolation: InterpolationOptions = {
  skipOnVariables: false,
  escapeValue: false,
  format: (value, formatString) => {
    switch (true) {
      case value instanceof Date:
        return format(value, formatString as string);
      default:
        return value;
    }
  },
};

export const react: ReactOptions = {
  bindI18n: 'languageChanged loaded',
  bindI18nStore: 'added removed',
  nsMode: 'default',
  useSuspense: process.env['NODE_ENV'] !== 'test',
};

export const i18nConfig: InitOptions = {
  backend,
  defaultNS: 'index',
  detection,
  fallbackLng: false,
  interpolation,
  keySeparator: '.',
  lng: process.env['NODE_ENV'] === 'test' ? 'cimode' : undefined, // cimode forces the t function to return the key passed as an argument - makes testing more predictable
  load: 'currentOnly',
  ns: [],
  partialBundledLanguages: true,
  react,
  supportedLngs: [LOCALE.EN_US, LOCALE.ES_US],
};
