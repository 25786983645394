import React from 'react';

interface Props {
  width?: number;
  height?: number;
}

export const FallbackIcon = ({ width = 119, height = 122 }: Props): React.ReactElement => (
  <svg width={width} height={height} viewBox="0 0 119 122">
    <defs>
      <path id="FallbackIcon__a" d="M0 .169h23.83V23.71H0z" />
      <path id="FallbackIcon__c" d="M0 23.831h23.83V.29H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        d="M85.46 16.851H31.938c-3.245 0-5.875 2.836-5.875 6.335v78.047c-.003 1.68.616 3.294 1.718 4.483 1.102 1.189 2.598 1.857 4.157 1.855h53.52c3.241.003 5.871-2.827 5.875-6.321V23.186c0-3.495-2.625-6.33-5.867-6.335h-.008z"
        fill="#B89471"
        fillRule="nonzero"
      />
      <path d="M74.611 16.851s-1.856 4.908-4.56 4.908h-5.12V16.85h9.68z" fill="#906232" fillRule="nonzero" />
      <path fill="#FFF" fillRule="nonzero" d="M29.725 20.293h57.702v82.363H29.725z" />
      <path
        d="M72.604 20.293c-.733.863-1.747 1.388-2.83 1.466h-4.842v-1.466h7.672z"
        fill="#B1B1B1"
        fillRule="nonzero"
      />
      <path
        d="M66.98 7.063a6.91 6.91 0 00-1.484.171C65.301 3.34 62.315.29 58.697.29c-3.618 0-6.604 3.05-6.8 6.945a6.658 6.658 0 00-1.482-.171c-3.7.096-6.653 3.358-6.653 7.348 0 3.99 2.953 7.251 6.653 7.348h16.564c3.76 0 6.81-3.287 6.81-7.342 0-4.054-3.05-7.341-6.81-7.341v-.013zm-8.281 3.707c-1.618.014-2.94-1.388-2.953-3.133-.013-1.744 1.287-3.17 2.905-3.184 1.618-.014 2.94 1.388 2.954 3.133v.025c0 1.735-1.297 3.145-2.906 3.159z"
        fill="#CAC9C8"
        fillRule="nonzero"
      />
      <path
        fill="#989898"
        fillRule="nonzero"
        d="M50.467 51.223h29.694v4.839H50.467zm0 18.746h31.932v4.839H50.467zm0 17.58h10.109v4.839H50.467zm12.056 0h21.251v4.839H62.523z"
      />
      <path
        d="M44.484 49.714v8.28h-7.68v-8.28h7.68zm2.711-2.923H34.077v14.122h13.118V46.79zm-2.711 21.455v8.284h-7.68v-8.284h7.68zm2.711-2.919H34.077V79.45h13.118V65.327zM44.484 87v8.284h-7.68V87h7.68zm2.711-2.919H34.077v14.122h13.118V84.077v.004z"
        fill="#CBCBCB"
        fillRule="nonzero"
      />
      <g transform="translate(47 23)">
        <g transform="translate(0 .12)">
          <mask id="FallbackIcon__b" fill="#fff">
            <use xlinkHref="#FallbackIcon__a" />
          </mask>
          <path
            d="M23.528 11.221L12.642.466a1.037 1.037 0 00-1.454 0L.3 11.221a1.007 1.007 0 000 1.437l10.887 10.755a1.037 1.037 0 001.454 0l10.886-10.755a1.008 1.008 0 000-1.437"
            fill="#EE5C4B"
            mask="url(#FallbackIcon__b)"
          />
        </g>
        <mask id="FallbackIcon__d" fill="#fff">
          <use xlinkHref="#FallbackIcon__c" />
        </mask>
        <path
          fill="#FFF"
          mask="url(#FallbackIcon__d)"
          d="M9.856 18.181h4.117v-2.034H9.856zm4.117-2.708H9.856v-9.52l4.117-.014z"
        />
      </g>
      <path
        d="M118.77 119.856c0 1.019-26.591 1.85-59.396 1.85-32.804 0-59.374-.831-59.374-1.85C0 118.836 26.59 118 59.388 118s59.395.83 59.395 1.856"
        fillOpacity={0.11}
        fill="#000"
      />
    </g>
  </svg>
);

export default FallbackIcon;
