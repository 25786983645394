import { abbreviateNumber, round } from '@health-activity-ui/utils';
import React from 'react';

interface DistanceStatProps {
  distance?: number;
  unit: string;
  singular: string;
  distanceStatStyle?: unknown;
}

export const DistanceStat = ({
  distance = 0,
  distanceStatStyle = { width: '90px' },
  unit,
  singular,
}: DistanceStatProps): React.ReactElement => {
  const score = abbreviateNumber(round(distance, 2)) as number;
  return (
    <div className="stat" style={distanceStatStyle}>
      <span className="distance">{score}</span>
      <span aria-hidden="true"> {score === 1 ? singular?.toLowerCase() : unit.toLowerCase()}</span>
      <span className="is-sr-only"> {score === 1 ? singular?.toLowerCase() : unit.toLowerCase()}</span>
    </div>
  );
};

export default DistanceStat;
