import { css } from '@emotion/react';
import { PropsWithChildren } from '@health-activity-ui/shared';
import { noop } from '@health-activity-ui/utils';
import classnames from 'classnames';
import React from 'react';

const psuedoSelectorStyles = `
  background-color: currentColor;
  content: "";
  display: block;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
  transform-origin: center center;
`;

const cssStyles = `
  background-color: var(--rds-color-neutral-90);
  border-radius: var(--rds-border-radius-4);
  color: var(--rds-color-neutral-20);
  white-space: nowrap;

  .delete {
    height: 16px;
    margin-left: calc(var(--rds-spacing-size-8) / 2);
    margin-right: calc(var(--rds-spacing-size-8) / -2);
    position: relative;
    width: 16px;
    cursor: pointer;

    &:before {
      height: 1px;
      width: 50%;
      ${psuedoSelectorStyles}
    }

    &:after {
      height: 50%;
      width: 1px;
      ${psuedoSelectorStyles}
    }
  }
`;

export interface TagProps extends PropsWithChildren {
  hasDelete?: boolean;
  onClick?: () => void;
  className?: string;
}

export const Tag = ({ children, onClick, hasDelete = true, className = '' }: TagProps) => {
  return (
    <span
      className={classnames(
        'tag is-inline-flex is-align-items-center is-justify-content-center has-rds-p-8 is-rds-micro',
        className
      )}
      css={css`
        ${cssStyles}
      `}
    >
      {children}
      {hasDelete ? (
        <button
          className="delete"
          aria-label="delete"
          onClick={(e) => {
            e.preventDefault();
            (onClick || noop)();
          }}
        />
      ) : null}
    </span>
  );
};

export default Tag;
