import { round } from '@health-activity-ui/utils';
import { RDS_COLOR_PRIMARY } from '@rally/ui-themes/dist/web/rally';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import ProgressBarGauge from './parts/progress-bar-gauge';
import ProgressBarValue from './parts/progress-bar-value';
import { LineAxis, LineStyle, ProgressBarProps } from './progress-bar.models';

/**
 * @title <ProgressBar />
 * @description <ProgressBar /> is a reusable vertical or horizontal progress
 * bar component that shows the progress status for a task that take a long time or consists of several steps,
 * for example, time. See (progressbar)[https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Techniques/Using_the_progressbar_role].
 *
 * It is made up of two sub-parts:
 * - The `ProgressBarGauge` which represents the background of the meter.
 * - The `ProgressBarValue` which is the colored in bit that represent the value of th meter.
 *
 * @property {number} percent - The value of the progress
 *
 * @property {LineAxis} [lineAxis] - Represents the direction that "ProgressBarValue" fills from.
 * Defaults to LineAxis.FromLeft
 * @property {LineStyle} [lineStyle] - Describes the styling of then ends (rounded or square) of the "ProgressBarValue".
 * Defaults LineStyle.RoundedStartEnd
 * @property {number | '100%'} [length] - The color of the meter itself. Defaults to 100%.
 * @property {string} [color] - The color of the meter itself. Defaults to RDS Blue.
 * @property {string} [padding] - Padding between the "Gauge Bar and the "ProgressBarValue". Defaults to 0
 * @property {string} [thickness] - Thickness of the "ProgressBarValue". Defaults to RDS 16px
 *
 * @example EXAMPLE 1 - Will render a `ProgressBarValue` at 100%.
 *    <ProgressBar percent={100} />
 *
 * @example EXAMPLE 2 - Will render a green `ProgressBarValue` bar with rounded edges.
 *    <ProgressBar percent={75} lineStyle={LineStyle.RoundedStartEnd} color="green"/>
 *
 * @example EXAMPLE 3 - Will render with a `ProgressBarValue` that has square ends.
 *    <ProgressBar percent={50} lineStyle={LineStyle.Square}/>
 *
 * @example EXAMPLE 4 - Will render a vertical progress bar with the `ProgressBarValue` extending from the bottom.
 *    <ProgressBar percent={25} lineAxis={LineAxis.FromBottom} />
 */

export const ProgressBar = ({
  className = '',
  color = RDS_COLOR_PRIMARY,
  length = '100%',
  lineAxis = LineAxis.FromLeft,
  lineStyle = LineStyle.RoundedStartEnd,
  padding = 'none',
  percent,
  thickness = '7',
}: ProgressBarProps): ReactElement => {
  const value = percent > 100 ? 100 : percent;
  const { t } = useTranslation();

  return (
    <ProgressBarGauge
      aria-valuemax={100}
      aria-valuemin={0}
      aria-valuenow={round(value, 2)}
      aria-valuetext={`${round(value, 2)}% ${t('complete')}`}
      className={`${className} progress-gauge`}
      length={length}
      lineAxis={lineAxis}
      lineStyle={lineStyle}
      padding={padding}
      role="meter"
      aria-label={t('progressMeter')}
    >
      <ProgressBarValue
        className="bar-value"
        color={color}
        lineAxis={lineAxis}
        lineStyle={lineStyle}
        percent={value}
        thickness={thickness}
      />
    </ProgressBarGauge>
  );
};

export default ProgressBar;
