import { SETTING_BASE_URI } from '@health-activity-ui/shared';
import { AxiosResponse } from 'axios';
import apiClient from './api-client';

/**
 * @function updatePreferredLocale
 *
 * @description Updates a users preferred locale setting. Only works if the user is logged in
 *
 * @param {String} locale
 *
 * @returns {Promise<AxiosResponse>}
 */
export async function updatePreferredLocale(locale: string): Promise<AxiosResponse> {
  const response = await apiClient(`${SETTING_BASE_URI}/user/locale`, {
    method: 'PUT',
    data: { locale },
  });

  return response;
}
