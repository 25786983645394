import { AuthorizedFeature, useAuthorization } from '@health-activity-ui/data-access';
import { ReactElement } from 'react';
export interface AuthorizationProps {
  children: ReactElement;
  ability: AuthorizedFeature;
}
export const AuthorizationComponent = ({ children, ability }: AuthorizationProps): ReactElement => {
  const { state } = useAuthorization();
  return state[ability] ? children : null;
};
