import React from 'react';

interface Props {
  width?: number;
  height?: number;
}

// CONVERT FILL TO USE RDS COLORS - TODO: https://jira.rallyhealth.com/browse/WHAT-1084
export const SafetybehaviorsIcon = ({ width = 200, height = 165 }: Props): React.ReactElement => (
  <svg viewBox="0 0 200 165" width={width} height={height}>
    <defs>
      <clipPath id="SafetybehaviorsIcon__a">
        <path
          className="SafetybehaviorsIcon__a"
          d="M178.8 58.67c.2-1.21-1.05-2.42-4.4-1.37a44.64 44.64 0 00-13.49 7.7c-.86.75-2.27 0-.51-2 6.58-7.53 20.44-9.5 19.49-13.44-.23-1.12-3.33-2-5.22-2.69-4.18-1.47-14.67-4-27.43-4a97.74 97.74 0 00-13.11.88 39.64 39.64 0 00-24.28 12.69 42.06 42.06 0 00-10.6 24.15v.25c.08.42 1.15 4.11 13.28 7.06a20.54 20.54 0 0010.17 2.87 16.07 16.07 0 006.38-1.3c1.88-.81 3.4-1.63 4.74-2.34 3-1.61 5.16-2.76 9-2.76a35.55 35.55 0 017.71 1.06 14.44 14.44 0 003.21.37c6.33 0 11.9-4.11 15.45-7.56s6.43-7.61 6.61-8.94a2.22 2.22 0 00-.35-1.72 1.18 1.18 0 00-1.08-.49c-5.91 1.18-14.93 10.1-16.09 10.1s-1.09-1.57-.05-2.92c3.32-4.36 10.05-8.48 15-11.5 2.91-1.77 5.4-3.08 5.57-4.1z"
        />
      </clipPath>
      <clipPath id="SafetybehaviorsIcon__b">
        <path
          className="SafetybehaviorsIcon__a"
          d="M44.77 12.05L74 74.5a2.08 2.08 0 01-.76 1.37L51.1 98a1.58 1.58 0 01-1.55.18c-.3-1.49-35.26-84.87-35.26-84.87z"
        />
      </clipPath>
      <style>
        {
          '.SafetybehaviorsIcon__a{fill:none}.SafetybehaviorsIcon__b{fill:#4d4d4d}.SafetybehaviorsIcon__c{fill:#666}.SafetybehaviorsIcon__d{fill:#333}.SafetybehaviorsIcon__y{fill:#231f20}.SafetybehaviorsIcon__p{fill:#1e1e1e}.SafetybehaviorsIcon__u{fill:#0083a6}.SafetybehaviorsIcon__x{opacity:.82}.SafetybehaviorsIcon__z{fill:#c1c1c1}'
        }
      </style>
    </defs>
    <path
      className="SafetybehaviorsIcon__b"
      d="M134.18 101.3h7.12v27.94a2.47 2.47 0 01-2.47 2.47h-2.57a2.08 2.08 0 01-2.08-2.08V101.3z"
    />
    <path className="SafetybehaviorsIcon__b" d="M115.296 86.104l3.713-3.713 21.503 21.504-3.712 3.712z" />
    <path className="SafetybehaviorsIcon__b" d="M138.924 107.986l-3.712-3.712 21.503-21.503 3.712 3.712z" />
    <path
      className="SafetybehaviorsIcon__b"
      d="M133.61 103.79l3.07-2.79a1.62 1.62 0 012.28.09l3.07 3.3a2.06 2.06 0 01.56 1.42 1.79 1.79 0 01-1.79 1.79h-6.07a1.78 1.78 0 01-1.78-1.72v-.47a2 2 0 01.66-1.62z"
    />
    <path
      className="SafetybehaviorsIcon__c"
      d="M137.26 102.29h7.12v25.12a2.21 2.21 0 01-2.21 2.21h-2.64a2.27 2.27 0 01-2.27-2.27v-25.06z"
    />
    <path className="SafetybehaviorsIcon__c" d="M118.391 87.089l3.713-3.713 21.503 21.504-3.712 3.712z" />
    <path className="SafetybehaviorsIcon__c" d="M142.006 108.978l-3.712-3.712 21.503-21.503 3.712 3.712z" />
    <path
      className="SafetybehaviorsIcon__b"
      d="M136.69 104.78l3.07-2.79a1.62 1.62 0 012.28.09l3.07 3.3a2.08 2.08 0 01.56 1.42 1.79 1.79 0 01-1.79 1.79h-6.06a1.79 1.79 0 01-1.79-1.72v-.47a2.1 2.1 0 01.66-1.62z"
    />
    <rect className="SafetybehaviorsIcon__d" x={133.68} y={126.5} width={9.33} height={5.53} rx={0.71} />
    <path
      className="SafetybehaviorsIcon__b"
      d="M160.76 78.17c.26-.19 4.73-1.47 5.76-2.1C177 68.69 179 61.69 179 59.11c0-.4 0-.54-1-.94-.19-.13-.24.89-.67.89-.85 0-3.32 2-4.74 3-1.84 1.29-10.63 8.75-19.21 13.24l22.57-17s3.41-6.31 4-7.83a4.6 4.6 0 00-2.52-1.85c-4.19-1.48-16.11-1.49-28.87-1.49a96.12 96.12 0 00-13.11.89 39.63 39.63 0 00-24.28 12.68c-6 6.57-10 8.19-10.87 17.2 0 0-1 2.86-.7 3.73l1 3.48c.08.41 1.15 4.1 13.29 7.06A20.59 20.59 0 00123.93 95a16 16 0 006.39-1.3c1.87-.81 3.39-1.62 4.73-2.34 3-1.6 5.16-2.76 9-2.76a35.55 35.55 0 017.71 1.06 13.87 13.87 0 003.21.37c6.34 0 11.9-4.11 15.45-7.56 7.19-5.53 5.8-15.45 5-14.88-.44.33-1 4-1.81 4.6-.39.29-1 .77-1.73 1.38-3 2.46-10.14 8.22-12.57 8.22a.39.39 0 01-.22 0 1.58 1.58 0 01-.64-1.14c.12-.92 1.65-2.01 2.31-2.48z"
    />
    <path
      d="M178.8 58.67c.2-1.21-1.05-2.42-4.4-1.37a44.64 44.64 0 00-13.49 7.7c-.86.75-2.27 0-.51-2 6.58-7.53 20.44-9.5 19.49-13.44-.23-1.12-3.33-2-5.22-2.69-4.18-1.47-14.67-4-27.43-4a97.74 97.74 0 00-13.11.88 39.64 39.64 0 00-24.28 12.69 42.06 42.06 0 00-10.6 24.15v.25c.08.42 1.15 4.11 13.28 7.06a20.54 20.54 0 0010.17 2.87 16.07 16.07 0 006.38-1.3c1.88-.81 3.4-1.63 4.74-2.34 3-1.61 5.16-2.76 9-2.76a35.55 35.55 0 017.71 1.06 14.44 14.44 0 003.21.37c6.33 0 11.9-4.11 15.45-7.56s6.43-7.61 6.61-8.94a2.22 2.22 0 00-.35-1.72 1.18 1.18 0 00-1.08-.49c-5.91 1.18-14.93 10.1-16.09 10.1s-1.09-1.57-.05-2.92c3.32-4.36 10.05-8.48 15-11.5 2.91-1.77 5.4-3.08 5.57-4.1z"
      fill="#f26c32"
    />
    <path
      className="SafetybehaviorsIcon__b"
      d="M135 48.8c-1 .46-3 4.09-.37 3.25 12.74-4.11 30.34-3.2 30.34-3.2 1.65-.08.74-1.14 0-1.23.03 0-18.54-4.08-29.97 1.18zm24.41 5.2c-1.58-1.1-16.09-.22-23.62 2-6.49 1.94-11.54 5.3-12.34 6.18-1.75 1.88 1.23 2.56 4 2.56 3.12 0 6-2.73 10.9-4.89 11.83-5.24 23.33-4.31 21.06-5.85zm-1.48 6.74c-3.06-.83-11.87 2.45-20 6.68-4.27 2.22-7.32 4.92-7.7 6.09-.34.81-.36 3.45 2.94 3.45 2.37 0 4.06-3.72 8.8-6.45 7.45-4.3 19.75-9.02 15.96-9.77zm-26.47 7.31c-9.91-2.71-33.09 12.88-24 14.46 3.41.59 4.48-2.12 8.25-5.55 5.92-5.37 18.47-7.81 15.75-8.91zm-.94-17.14c2.78-.77 1.22-1.77-.45-1.77-12.48 1.76-18.71 9.72-20.13 12.19s.49 4.85 3 2.32 8.29-9.87 17.58-12.74z"
    />
    <rect className="SafetybehaviorsIcon__b" x={136.46} y={125.97} width={8.65} height={5.2} rx={0.82} />
    <rect className="SafetybehaviorsIcon__b" x={137.66} y={128.73} width={6.24} height={7.51} rx={1.36} />
    <path opacity={0.49} fill="#333" d="M137.66 131.17h6.24v1.31h-6.24z" />
    <path
      d="M148.07 52.18c-2.45.5-4.86 1-7.24 1.55s-4.75 1.14-7.06 1.86A65.12 65.12 0 00127 58.1a47.05 47.05 0 00-6.38 3.59 20.49 20.49 0 015.66-5 33.72 33.72 0 016.94-3 41.59 41.59 0 017.38-1.48 31.89 31.89 0 017.47-.03z"
      fill="#f9bcb6"
    />
    <path
      d="M122.3 66.94c-1.77.74-3.46 1.48-5.12 2.28s-3.26 1.67-4.82 2.61a42.62 42.62 0 00-4.5 3.08c-1.48 1.09-2.79 2.37-4.26 3.63a16.52 16.52 0 013.21-4.78 22.18 22.18 0 012.12-2 24.31 24.31 0 012.4-1.65 23 23 0 015.29-2.35 17.42 17.42 0 015.68-.82zM175.93 50a66.2 66.2 0 00-7.38 3.35 75.5 75.5 0 00-7 4.11h-.22c-2.16.52-4.32 1.12-6.45 1.77s-4.24 1.39-6.33 2.1-4.23 1.37-6.31 2.12-4.17 1.52-6.23 2.45l-.11-.17a27.47 27.47 0 015.71-3.75 45.26 45.26 0 016.28-2.62c2.15-.71 4.36-1.23 6.55-1.76s4.39-1 6.62-1.42l-.21.08a65.13 65.13 0 017.3-3.73 67 67 0 017.67-2.81zm-.86 9a61.33 61.33 0 00-7.52 4.58 93.547 93.547 0 00-7 5.28c-1.11 1-2.24 1.89-3.29 2.91s-2.17 2-3.29 2.89-2.23 1.9-3.4 2.8-2.29 1.86-3.52 2.72l-.15-.13a42.41 42.41 0 015.72-6.87 56.68 56.68 0 017-5.59c2.42-1.72 4.9-3.36 7.47-4.86a62 62 0 018-3.91zm-60.22 26.15a21.94 21.94 0 006.11-.56 42.24 42.24 0 005.83-2 35.12 35.12 0 016.14-2.15 17.67 17.67 0 016.48-.17v.2a18.18 18.18 0 00-6.2.93 33.8 33.8 0 00-5.71 2.64c-1.88 1-3.82 2.2-6.13 2.49a10.24 10.24 0 01-6.55-1.2zm45.43-3.53a13.56 13.56 0 012-3.84 18.74 18.74 0 012.88-3.22 16.25 16.25 0 013.59-2.44 11.35 11.35 0 014.17-1.23c-1.15.91-2.27 1.68-3.33 2.54s-2.1 1.72-3.1 2.63l-3 2.74c-1.08.94-2.09 1.88-3.21 2.82zm-56.54-11.23a39.71 39.71 0 0110.94-17 28.9 28.9 0 018.83-5.44 66 66 0 019.84-2.95 64.56 64.56 0 0110.16-1.26c1.71-.1 3.41-.08 5.12-.08s3.4.15 5.09.32a103.63 103.63 0 0119.89 3.94 154.12 154.12 0 00-20-2.46 77.65 77.65 0 00-19.86 1.47 75.72 75.72 0 00-9.56 2.78 29.61 29.61 0 00-8.43 4.9 41.13 41.13 0 00-6.76 7.2 50.5 50.5 0 00-5.26 8.58z"
      fill="#fce2d6"
    />
    <g clipPath="url(#SafetybehaviorsIcon__a)">
      <path
        d="M164.73 47s-6.37 0-21.66 1.28c-1.41.12-2.73.29-4 .48-4 .54-8.74 1.53-12.25 3.37a36.06 36.06 0 00-3.95 2 43.2 43.2 0 00-10.19 1 17 17 0 00-3.48-1.57c-2.81-1.39-7.31-1.66-11.2-1.56-1.23 0-2.52 0-3.93.15-15.26 1.14-21.85 2.15-21.85 2.15L71 57.47s1.84-.08 2 .46 1.45 1.85 2 2.7v.15c-.08.32-1.87 7.93 3.26 11.61 2.15 1.55 5.19 2.18 9 1.87 13-1 21.49-6.22 24.2-14.68a8.31 8.31 0 002.43-2.77c4.12-.85 6-.71 6.67-.56a3.45 3.45 0 001.29 2.44c-1 8.37 5.44 12.41 18.45 11.38a21 21 0 0010.19-3.39 21.28 21.28 0 008.6-12.57v-.13c.92-1 2.93-2.64 3.25-3.11s2.27-.8 2.27-.8zM87.62 72.46c-3.1.24-5.53-.26-7.24-1.48-4.47-3.2-2.81-10.31-2.8-10.37a5.2 5.2 0 01.71-1.51c1.6-1.87 5.95-4.4 17.76-5.09 12.34-.71 13.08 3.65 12.65 6-1.86 5.36-7.13 11.34-21.08 12.45zm61.21-6.9a16.85 16.85 0 01-8.17 2.7c-14.69 1.16-17-4.82-16.11-10.38.82-2.28 4-5.86 15.66-7.36 10.56-1.35 14.43 0 15.76 1.59a3.15 3.15 0 01.4 2.26c-.01.07-1.47 7.15-7.54 11.19z"
        opacity={0.21}
        fill="#231f20"
      />
    </g>
    <path className="SafetybehaviorsIcon__b" d="M102.7 145.85l15.72-15.72 25.39 26.02-30.03.77-11.08-11.07z" />
    <path className="SafetybehaviorsIcon__d" d="M48.97 93.36l-3.96 3.04-32.99-12.8-.25-29.58 27.42 8.73 9.78 30.61z" />
    <path
      d="M119.3 131c-.32.3-1.29 0-1.8.53s-13.31 13.14-13.73 13.57-.07 1.17-.39 1.47c-2 .71-3.93 0-5.76-1-9.3-5.38-16.78-12.81-24-20.61a2 2 0 01-.46-1.95 27.91 27.91 0 011-3.18s3.53 3.34 5.22 5 3 1.6 5.43-2.09a38 38 0 0110.87-10.92c3.49-2.26 4-3.53 2.21-5.23s-3.35-3.43-5-5.16l2-1a2.07 2.07 0 012.36.35c15.63 14.92 19.29 20.89 21.89 25 .6.95.11 3.97.16 5.22z"
      fill="#353535"
    />
    <path
      d="M77 79.68a45.8 45.8 0 01-1.47 5.13c-1.94 4.19-1.91 4.69 1.22 7.83 1.85 1.84 3.72 3.66 5.52 5.54s1.85 3.59.06 5.47c-1.52 1.6-3.09 3.15-4.68 4.68-2.35 2.27-4.14 2.28-6.47 0-1.81-1.74-3.55-3.54-5.32-5.31-3.21-3.2-3.19-3.16-7.38-1.12-1.53.74-4 1.29-5.61 1.87zm.25 20.62l-5.17-5.18a1 1 0 00-1.35 0l-2.34 2.34a.63.63 0 000 .9l5.66 5.64a.62.62 0 00.89 0l2.31-2.31a1 1 0 000-1.39z"
      fill="#b2b2b2"
    />
    <path
      d="M77.07 79.68a27 27 0 01-1.22 4.11c-1.94 4.19-1.91 4.69 1.23 7.83 1.84 1.84 3.71 3.66 5.52 5.53s1.84 3.6.05 5.48c-1.52 1.6-3.09 3.15-4.68 4.68-2.35 2.27-4.13 2.28-6.47 0-1.8-1.74-3.55-3.54-5.32-5.31-3.18-3.17-3.18-3.12-7.38-1.08a49.29 49.29 0 01-4.8 1.77zm.67 19.76L72.26 94a.75.75 0 00-1.07 0l-2.37 2.36a.81.81 0 000 1.12l5.43 5.42a.79.79 0 001.13 0l2.36-2.35a.76.76 0 000-1.11z"
      fill="#ccc"
    />
    <path d="M71 99.64l-2.13-2.14a1 1 0 010-1.38L71 94V83.53l-4 4.11V103l4 4z" fill="#f2f2f2" />
    <path
      d="M92.93 101.42c1.66 1.73 3.28 3.5 5 5.16 1.27 1.22 1.38 2.21.07 3.5q-7.49 7.36-14.85 14.85c-1.41 1.44-2.39 1.19-3.73-.11l-5.22-5a7.94 7.94 0 011.29-2.49q7.27-7.47 14.76-14.75a8.7 8.7 0 012.68-1.16z"
      fill="#ef5b4b"
    />
    <path
      className="SafetybehaviorsIcon__p"
      d="M52.93 103.85c-1.17 1.16-2.73.86-3.87-.26-1.93-1.9-2-2-3.89-3.9-.92-.91-1.46-2.07.21-3.71l3.52-3.56c.31 1.48.68 3.92 1 5.4-.19.22-.56.64-.55.64l1.56 1.54a9.09 9.09 0 002-1.15q10.53-10.47 21-21a9.51 9.51 0 001.27-2.23L74 74.5l-2.48-4.7c1.44-1.44 2.62-1.15 3.63-.14.54.55 4.39 4.32 4.7 4.71a2 2 0 01-.25 2.75z"
    />
    <path d="M74 74.5l.42-5.41s-1.08-1.11-2.9.71S74 74.5 74 74.5z" fill="#1a1a1a" opacity={0.33} />
    <path className="SafetybehaviorsIcon__p" d="M53.66 103.15l.43-.4zm23.88-23.84l.42-.45z" />
    <path d="M50.91 100l24.24-24.41s.39.56-.36 1.31L52.1 99.6a1.65 1.65 0 01-1.19.4z" fill="#121324" />
    <path
      className="SafetybehaviorsIcon__b"
      d="M44.3 11L74 74.5a2.08 2.08 0 01-.76 1.37L51.1 98a1.58 1.58 0 01-1.55.18c-.3-1.49-37.78-88.14-37.78-88.14z"
    />
    <g clipPath="url(#SafetybehaviorsIcon__b)">
      <path
        d="M160.32 33.87s-8.16-.93-27.41-.81a48.5 48.5 0 00-5 .3c-4.93.42-10.78 1.59-14.76 4.55a28.39 28.39 0 00-4.5 3.13 35.59 35.59 0 00-12.8.35A28.09 28.09 0 0091 37.91c-4-3-9.83-4.13-14.75-4.55a48.7 48.7 0 00-5-.3c-19.25-.12-27.41.81-27.41.81l-.7 5.84s2.33.11 2.68 1.16c.28.84 2.39 3.72 3.3 5.4a2.46 2.46 0 010 .28c0 .6-.14 14.74 7.48 22.49a16.37 16.37 0 0012.11 4.89c16.34 0 25.79-8.57 26.87-24.19a10.93 10.93 0 002.34-4.87c5-1 7.44-.46 8.4-.07a10.13 10.13 0 002.34 4.82c1 15.7 10.49 24.31 26.88 24.31A16.36 16.36 0 00147.58 69c7.62-7.75 7.49-21.89 7.48-22.52v-.25c.92-1.68 3-4.56 3.3-5.4.35-1 2.68-1.16 2.68-1.16zm-91.67 36.7a13.13 13.13 0 01-9.71-3.88c-6.63-6.69-6.52-19.95-6.52-20.06a10.39 10.39 0 01.48-2.76c1.52-3.3 6.38-7.44 21.34-7 15.62.49 17.81 8.87 17.9 13.15-.85 9.98-5.91 20.55-23.49 20.55zm76.54-3.89a13.11 13.11 0 01-9.71 3.89c-18.51 0-23.14-11.68-23.59-22.08.4-4.18 3.46-10.5 18-11.58 13.16-1 18.52 2.21 20.66 5.37a9.92 9.92 0 011.16 4.33c-.01.13.12 13.32-6.52 20.07z"
        opacity={0.37}
        fill="#231f20"
      />
    </g>
    <path
      className="SafetybehaviorsIcon__u"
      d="M73.28 37c-1.59-.59-3.07-1-2.3-2.49 8.41-16.71 16.55-18.46 25.82-16.8l52.51 9.42a3.07 3.07 0 11-1.08 6l-52.51-9.38c-6.82-1.22-11.86-1-18.86 12.94-.54 1.07-1.26 1.14-3.58.31z"
    />
    <path
      d="M150.9 27.94a3.31 3.31 0 01-.05.62 3.06 3.06 0 01-3.56 2.44l-52.51-9.37c-6.82-1.22-11.32-2-18.32 11.86a3.06 3.06 0 01-4.12 1.36 3.31 3.31 0 01-.74-.53 3.06 3.06 0 005.8 1.3c7-13.9 11.5-13.09 18.32-11.87l52.51 9.42a3.07 3.07 0 002.67-5.23z"
      fill="#007797"
    />
    <path
      d="M110 40.53c-6-14.34-10.57-13.85-17.45-13.1l-53 5.77a3.07 3.07 0 11-.66-6.1l53-5.77c9.36-1 16.82-.52 23.78 16.83 1.24 3.09 1.24 3.09-1.64 4-2.57.84-3.03.51-4.03-1.63z"
      fill="#008eb5"
    />
    <path
      className="SafetybehaviorsIcon__u"
      d="M114 40.08a3 3 0 01-1.19.25 3.07 3.07 0 01-2.81-1.89c-6-14.34-10.57-13.85-17.45-13.1l-53 5.77a3.08 3.08 0 01-3.21-2 3.12 3.12 0 00-.17 1.37 3.06 3.06 0 003.38 2.72l53-5.77c6.88-.75 11.43-1.24 17.45 13.1a3.07 3.07 0 002.83 1.89 3 3 0 001.19-.25 3.07 3.07 0 001.69-3.87 3.05 3.05 0 01-1.71 1.78z"
    />
    <g className="SafetybehaviorsIcon__x">
      <path
        className="SafetybehaviorsIcon__d"
        d="M145.27 39.34S145.69 65 127.37 65s-25.19-11.08-25.31-25.19 26.13-12.13 26.13-12.13 16.67-1.18 17.08 11.66z"
      />
    </g>
    <path d="M127.37 66.69c-17.26 0-26.85-9.54-27-26.86a10.71 10.71 0 013.17-7.83c5.21-5.26 15.66-6 21.42-6 1.69 0 2.87.07 3.24.09h1.08c16 0 17.57 10.18 17.67 13.31 0 .62.15 14.76-7.47 22.51a16.35 16.35 0 01-12.11 4.78zM125 29.27c-4.22 0-14.51.49-19 5.05a7.31 7.31 0 00-2.19 5.48c.09 10.74 4.26 23.53 23.63 23.53a13.1 13.1 0 009.71-3.89c6.65-6.75 6.52-19.94 6.51-20.07-.09-3-1.68-10-14.31-10h-1a1 1 0 01-.25 0s-1.27-.1-3.1-.1z" />
    <path
      className="SafetybehaviorsIcon__y"
      d="M98 36.43s7.58-10.49 26.83-10.61 27.41.82 27.41.82l.7 5.83s-2.34.11-2.68 1.16-3.62 5.37-3.74 6.42-2.76.4-2.76.4 2.52-12.6-22-10.78C99.73 31.31 104.08 45 104.08 45A10.17 10.17 0 0198 36.43z"
    />
    <path
      className="SafetybehaviorsIcon__y"
      d="M98.68 37.83s-2.1-1.75-9.92 0c0 0-3.5-3.15-3-3.15s5.83-2.37 16.33-.66z"
    />
    <path
      className="SafetybehaviorsIcon__z"
      d="M147.51 28.6a4.39 4.39 0 011.28 2.66 4.42 4.42 0 01-1.07 2.74 4.45 4.45 0 01-1.28-2.66 4.42 4.42 0 011.07-2.74z"
    />
    <g className="SafetybehaviorsIcon__x">
      <path
        className="SafetybehaviorsIcon__d"
        d="M42.64 39.34S42.23 65 60.54 65s25.2-11.08 25.31-25.19-26.12-12.12-26.12-12.12-16.68-1.19-17.09 11.65z"
      />
    </g>
    <path d="M60.54 66.69a16.31 16.31 0 01-12.1-4.89C40.81 54.05 41 39.91 41 39.31 41.06 36.16 42.63 26 58.64 26h1.08c.36 0 1.55-.09 3.24-.09 5.76 0 16.2.78 21.41 6a10.63 10.63 0 013.16 7.88c-.14 17.36-9.73 26.9-26.99 26.9zm-1.9-37.35c-12.63 0-14.22 7-14.32 10.05 0 .11-.12 13.33 6.52 20.06a13.08 13.08 0 009.7 3.88c19.37 0 23.54-12.79 23.63-23.53A7.3 7.3 0 0082 34.32c-4.52-4.56-14.82-5.05-19-5.05-1.88 0-3.1.09-3.11.09a1 1 0 01-.24 0s-.43-.02-1.01-.02z" />
    <path
      className="SafetybehaviorsIcon__y"
      d="M89.94 36.43s-7.59-10.49-26.83-10.61-27.41.82-27.41.82l-.7 5.83s2.33.11 2.68 1.16 3.62 5.37 3.73 6.42 2.81.47 2.81.47-2.62-11.63 21.92-10.85c21 .66 17.71 15.63 17.71 15.63a10.9 10.9 0 006.09-8.87z"
    />
    <path
      className="SafetybehaviorsIcon__z"
      d="M40.4 28.6a4.45 4.45 0 00-1.28 2.66A4.42 4.42 0 0040.19 34a4.39 4.39 0 001.28-2.66 4.42 4.42 0 00-1.07-2.74z"
    />
    <path
      d="M152.22 26.64s-8.17-.94-27.41-.82a48.7 48.7 0 00-5 .3c-4.93.42-10.77 1.6-14.75 4.55a28.32 28.32 0 00-4.51 3.14 35.38 35.38 0 00-12.79.34 28.63 28.63 0 00-4.9-3.48c-4-3-9.83-4.13-14.76-4.55a48.68 48.68 0 00-5-.3c-19.25-.12-27.41.82-27.41.82L35 32.47s2.33.11 2.68 1.16c.28.84 2.38 3.72 3.3 5.4v.28c0 .6-.15 14.74 7.48 22.49a16.31 16.31 0 0012.1 4.89c16.35 0 25.8-8.57 26.88-24.19a10.87 10.87 0 002.33-4.87c5-1 7.45-.46 8.4-.07a10.41 10.41 0 002.34 4.83c1 15.69 10.49 24.3 26.88 24.3a16.35 16.35 0 0012.11-4.89c7.6-7.75 7.5-21.89 7.5-22.51v-.26c.91-1.68 3-4.56 3.3-5.4.34-1.05 2.68-1.16 2.68-1.16zM60.54 63.33a13.08 13.08 0 01-9.7-3.88c-6.64-6.73-6.52-20-6.52-20.06a10 10 0 01.48-2.76c1.52-3.3 6.38-7.43 21.34-7 15.62.49 17.8 8.87 17.89 13.15-.85 10.01-5.91 20.55-23.49 20.55zm76.54-3.89a13.1 13.1 0 01-9.71 3.89c-18.5 0-23.13-11.68-23.59-22.08.4-4.18 3.46-10.5 18-11.58 13.17-1 18.52 2.21 20.66 5.37a9.65 9.65 0 011.16 4.33c0 .13.13 13.32-6.52 20.07z"
      fill="#009ac4"
    />
    <path
      d="M37.5 30.57a2.36 2.36 0 012.15-2.08c3.43-.26 11.55-.72 25.49-.64a48.68 48.68 0 015 .3c4.93.42 10.78 1.6 14.76 4.55a14.43 14.43 0 013.56 2.71 34.86 34.86 0 0112.71-.41 42.43 42.43 0 015.33-3.33c4-3 10.41-3.12 15.34-3.54a48.7 48.7 0 015-.3c14.13-.09 22.26.39 25.6.65l-.22-1.86s-8.17-.94-27.41-.82a48.7 48.7 0 00-5 .3c-4.93.42-10.77 1.6-14.75 4.55a28.32 28.32 0 00-4.51 3.14 35.38 35.38 0 00-12.79.34 28.63 28.63 0 00-4.9-3.48c-4-3-9.83-4.13-14.76-4.55a48.68 48.68 0 00-5-.3c-19.25-.12-27.41.82-27.41.82L35 32.47a5.35 5.35 0 012.2.61z"
      fill="#6bcce9"
    />
  </svg>
);

export default SafetybehaviorsIcon;
