import React from 'react';

interface Props {
  width?: number;
  height?: number;
}

export const UnemploymentIcon = ({ width = 200, height = 165 }: Props): React.ReactElement => (
  <svg id="UnemploymentIconLayer_1" x={0} y={0} viewBox="0 0 200 165" xmlSpace="preserve" width={width} height={height}>
    <style>
      {'.UnemploymentIconst0{fill:#fff}.UnemploymentIconst4{fill:#9cdaee}.UnemploymentIconst7{fill:#4d4d4d}'}
    </style>
    <path
      className="UnemploymentIconst0"
      d="M131.237 116.858H61.56c-3.347 0-6.085-2.738-6.085-6.085V68.176c0-3.347 2.738-6.085 6.085-6.085h69.677c3.347 0 6.085 2.738 6.085 6.085v42.597c0 3.347-2.738 6.085-6.085 6.085z"
    />
    <path
      d="M131.237 117.011H61.56a6.244 6.244 0 01-6.237-6.237V68.176a6.244 6.244 0 016.237-6.237h69.677a6.244 6.244 0 016.237 6.237v42.597a6.244 6.244 0 01-6.237 6.238zM61.56 62.242a5.94 5.94 0 00-5.933 5.933v42.597a5.94 5.94 0 005.933 5.933h69.677a5.94 5.94 0 005.933-5.933V68.176a5.94 5.94 0 00-5.933-5.933H61.56z"
      fill="#b5b5b5"
      stroke="#b5b5b5"
      strokeWidth={0.834}
      strokeMiterlimit={10}
    />
    <path
      className="UnemploymentIconst0"
      d="M105.681 68.176h-15.62a1.932 1.932 0 01-1.927-1.927c0-1.06.867-1.927 1.927-1.927h15.619c1.06 0 1.927.867 1.927 1.927a1.932 1.932 0 01-1.926 1.927z"
    />
    <path
      d="M105.68 68.328H90.061a2.082 2.082 0 01-2.079-2.079c0-1.146.933-2.079 2.079-2.079h15.619c1.147 0 2.079.933 2.079 2.079a2.08 2.08 0 01-2.079 2.079zm-15.619-3.854c-.979 0-1.775.796-1.775 1.775s.796 1.775 1.775 1.775h15.619c.979 0 1.775-.796 1.775-1.775s-.796-1.775-1.775-1.775H90.061z"
      fill="#b5b5b5"
      stroke="#b5b5b5"
      strokeWidth={0.556}
      strokeMiterlimit={10}
    />
    <path fill="#00b2e2" d="M61.235 73.652H92.3v36.131H61.235z" />
    <path
      className="UnemploymentIconst4"
      d="M87.425 99.588c0-3.295-2.696-5.992-5.992-5.992h-9.187c-3.295 0-5.992 2.696-5.992 5.992v10.186h21.17V99.588z"
    />
    <circle className="UnemploymentIconst4" cx={76.84} cy={85.708} r={6.691} />
    <path
      d="M97.544 80.093h34.281v4.868H97.544zm0 8.957h34.281v2.402H97.544zm0 6.288h34.281v2.402H97.544zm0 6.085h34.281v2.402H97.544z"
      fill="#ccc"
    />
    <path
      d="M36.493 84.276c0 8.11 1.591 15.984 4.729 23.402A59.889 59.889 0 0054.1 126.782a59.9 59.9 0 0019.104 12.878c7.419 3.138 15.292 4.729 23.402 4.729s15.984-1.591 23.402-4.729a59.889 59.889 0 0019.104-12.878 59.889 59.889 0 0012.878-19.104c3.138-7.419 4.729-15.292 4.729-23.402s-1.591-15.984-4.729-23.402c-3.028-7.16-7.361-13.587-12.878-19.104s-11.945-9.85-19.104-12.878c-7.419-3.138-15.292-4.729-23.402-4.729s-15.984 1.591-23.402 4.729A59.889 59.889 0 0054.1 41.77a59.889 59.889 0 00-12.878 19.104c-3.138 7.418-4.729 15.292-4.729 23.402zm29.779 37.834l4.713-4.676 18.793-18.646 15.972-15.847 18.971-18.823 9.958-9.88A48.288 48.288 0 01145.1 84.275c0 26.783-21.712 48.495-48.495 48.495a48.275 48.275 0 01-30.333-10.66zM48.111 84.276c0-26.783 21.712-48.495 48.495-48.495a48.286 48.286 0 0129.836 10.263l-9.936 9.859-18.972 18.823-7.756 7.696-31.415 31.168-.221.219a48.28 48.28 0 01-10.031-29.533z"
      fill="#ef5b4b"
    />
    <path
      className="UnemploymentIconst7"
      d="M101.693 52.024H94.28a4.248 4.248 0 01-4.243-4.242 4.247 4.247 0 014.243-4.242h7.412a4.247 4.247 0 014.242 4.242 4.245 4.245 0 01-4.241 4.242zm-7.413-6.73a2.49 2.49 0 00-2.487 2.487 2.49 2.49 0 002.487 2.487h7.412a2.49 2.49 0 002.487-2.487 2.49 2.49 0 00-2.487-2.487H94.28z"
    />
    <path fill="#bcccce" d="M92.563 47.955h10.848V65.57H92.563z" />
    <circle cx={97.987} cy={54.573} r={2.833} fill="none" stroke="#fff" strokeWidth={0.258} strokeMiterlimit={10} />
    <path
      className="UnemploymentIconst7"
      d="M95.457 39.58l-1.39.551a.454.454 0 01-.334-.842l1.39-.551a.454.454 0 01.334.842z"
    />
    <path
      className="UnemploymentIconst7"
      d="M102.09 34.37c-.25-2.127-1.626-4.629-5.129-4.379 0 0-1.595.188-2.721 2.565 0 0-.183.565-.294 1.373h1.398c.325-1.544 1.354-2.674 2.576-2.674 1.483 0 2.684 1.661 2.684 3.709s-1.202 3.709-2.684 3.709c-1.483 0-2.684-1.661-2.684-3.709 0-.12.005-.239.013-.356h-1.37c-.041.67-.006 1.425.205 2.138 0 0 1.376 4.379 1.501 6.005v1.282l4.624.045s.024-.847.005-.827c-.001.002 2.126-6.754 1.876-8.881z"
    />
  </svg>
);

export default UnemploymentIcon;
