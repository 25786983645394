import styled from '@emotion/styled';
import { stringToKebab } from '@health-activity-ui/utils';
import { RDS_COLOR_SYSTEM_SUNSET_ORANGE, RDS_UNIT_SIZE_4 } from '@rally/ui-themes/dist/web/rally';
import React, { ForwardedRef, KeyboardEvent, useCallback } from 'react';
import TabButton from './tab-button.component';
import { useTabs } from './tabs.provider';

export const TabItem = styled.li`
  button {
    border: none;
    box-shadow: none;
    &.is-active {
      position: relative;
      &:before {
        border-bottom: ${RDS_UNIT_SIZE_4} solid ${RDS_COLOR_SYSTEM_SUNSET_ORANGE};
        bottom: -${RDS_UNIT_SIZE_4};
        content: '';
        left: 0;
        position: absolute;
        width: 100%;
      }
    }
    &:focus {
      box-shadow: none;
    }
  }
`;

export interface TabProps {
  tabTitle: string;
  index: number;
  tabCount: number;
  tabRef: ForwardedRef<HTMLButtonElement>;
  tabRefs: HTMLButtonElement[];
}

export const Tab = ({ tabTitle, index, tabCount, tabRef, tabRefs }: TabProps) => {
  const { activeTab, setActiveTab } = useTabs();

  const handleClick = useCallback(() => {
    setActiveTab(index);
  }, [index, setActiveTab]);

  const handleKeyUp = useCallback(
    (e: KeyboardEvent<HTMLButtonElement>) => {
      e.preventDefault();
      let nextTab, prevTab, firstTab, lastTab, currentTab;

      if (e.key === 'Enter' || e.key === ' ' || e.key === 'Space') {
        setActiveTab(index);
      }

      if ((e.key === 'ArrowRight' || e.key === 'ArrowDown') && !e.ctrlKey) {
        setActiveTab((prev) => {
          nextTab = prev + 1;
          firstTab = 0;
          lastTab = prev === tabCount - 1;
          currentTab = nextTab < tabCount;

          if (currentTab) {
            tabRefs[nextTab].focus();
            return nextTab;
          } else if (lastTab) {
            tabRefs[firstTab].focus();
            return firstTab;
          }
        });
      }

      if ((e.key === 'ArrowLeft' || e.key === 'ArrowUp') && !e.ctrlKey) {
        setActiveTab((prev) => {
          prevTab = prev - 1;
          lastTab = tabCount - 1;
          firstTab = prev === 0;
          currentTab = prev > 0;

          if (currentTab) {
            tabRefs[prevTab].focus();
            return prevTab;
          } else if (firstTab) {
            tabRefs[lastTab].focus();
            return lastTab;
          }
        });
      }
    },
    [index, setActiveTab, tabCount, tabRefs]
  );

  return (
    <TabItem role="presentation" className="tab is-pulled-left">
      <TabButton
        tabTitle={tabTitle}
        isActive={activeTab === index}
        role="tab"
        aria-controls={stringToKebab(tabTitle)}
        aria-selected={activeTab === index}
        onClick={handleClick}
        onKeyUp={handleKeyUp}
        ref={tabRef}
      />
    </TabItem>
  );
};

export default Tab;
