import React from 'react';

interface Props {
  className?: string;
  color: string;
  size?: string;
}

export const MilestoneIcon = ({ className = '', color = 'currentColor', size = '32' }: Props): React.ReactElement => (
  <svg
    aria-hidden="true"
    className={className}
    height={size}
    viewBox="0 0 20 20"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs />
    <circle cx="9.98" cy="9.98" r="9.23" fill={color} />
    <g fill="#ffffff" className="mountain">
      <path d="M12.59 18.68c2.94-1 3.58-2.05 3.58-2.05L12.58 12 8 18.88s1.59.67 4.59-.2z" opacity=".71" />
      <path d="M8.65 4.52A.24.24 0 019 4.3l3.12 1.58a.23.23 0 010 .41L9.78 7.47a.22.22 0 00-.13.21v2.19l5.6 7.59a9.85 9.85 0 01-5.52 1.62 9.31 9.31 0 01-6-2.51l4.94-6.7-.02-5.35z" />
    </g>
    <circle cx="9.98" cy="9.98" r="9.23" fill="none" stroke={color} strokeWidth="1" />
    <path
      fill="#ffffff"
      className="stars"
      d="M13.62 4.69l.53-.2a.89.89 0 00.48-.49l.21-.53L15 4a.87.87 0 00.49.49l.52.2-.52.21a.86.86 0 00-.49.48l-.2.53-.21-.53a.87.87 0 00-.48-.48l-.49-.21zM13.18 10.12l.33-.13a.51.51 0 00.31-.3l.13-.33.13.33a.54.54 0 00.31.3l.33.13-.33.13a.59.59 0 00-.31.31l-.13.32-.13-.32a.56.56 0 00-.31-.31l-.33-.13zM3.46 8l.45-.18a.74.74 0 00.41-.42l.18-.45.18.45a.74.74 0 00.41.42l.45.18-.45.17a.74.74 0 00-.41.42L4.5 9l-.18-.45a.74.74 0 00-.41-.42L3.46 8z"
    />
  </svg>
);

export default MilestoneIcon;
