import React from 'react';

interface Props {
  width?: number;
  height?: number;
}

export const DoctorIcon = ({ width = 200, height = 165 }: Props): React.ReactElement => (
  <svg viewBox="0 0 200 165" width={width} height={height}>
    <ellipse fill="#E5E5E4" cx={100} cy={82.5} rx={31.6} ry={3.3} />
    <path fill="#E4BA90" d="M95.5 41.4h10.3v5.7H95.5z" />
    <circle fill="#EADBB7" cx={107.5} cy={119.3} r={5.9} />
    <path fill="#EADBB7" d="M101.6 119.3h11.8v24.1h-11.8z" />
    <circle fill="#EADBB7" cx={107.5} cy={95.2} r={5.9} />
    <circle fill="#EADBB7" cx={107.5} cy={119.3} r={5.9} />
    <path fill="#EADBB7" d="M101.6 95.2h11.8v24.1h-11.8z" />
    <circle fill="#EADBB7" cx={93.8} cy={119.3} r={5.9} />
    <path fill="#EADBB7" d="M87.9 119.3h11.8v24.1H87.9z" />
    <circle fill="#EADBB7" cx={93.8} cy={95.2} r={5.9} />
    <circle fill="#EADBB7" cx={93.8} cy={119.3} r={5.9} />
    <path fill="#EADBB7" d="M87.9 95.2h11.8v24.1H87.9z" />
    <path fill="#8DD1CB" d="M115.4 56.4h8.8v15.7h-8.8z" />
    <circle fill="#8DD1CB" cx={119.8} cy={56.4} r={4.4} />
    <path fill="#8DD1CB" d="M124.2 56.4c0-6-4.8-10.8-10.8-10.8v10.8h10.8z" />
    <path
      fill="#8DD1CB"
      d="M87.9 45.6h25.6v49.6H87.9zM77.1 56.4c0-6 4.8-10.8 10.8-10.8v10.8H77.1zm0 0h8.8v15.7h-8.8z"
    />
    <circle fill="#8DD1CB" cx={81.5} cy={56.4} r={4.4} />
    <circle fill="#E4BA90" cx={100.7} cy={32.8} r={10.3} />
    <path fill="#989898" d="M101.6 143.4h11.8v1.2h-11.8zm-13.7 0h11.8v1.2H87.9z" />
    <path
      fill="#BA6E33"
      d="M113.4 143.4c0-3.3-2.6-5.9-5.9-5.9-3.3 0-5.9 2.6-5.9 5.9h11.8zm-13.7 0c0-3.3-2.6-5.9-5.9-5.9-3.3 0-5.9 2.6-5.9 5.9h11.8z"
    />
    <path fill="#8DD1CB" d="M87.9 95.2h25.6v10.3H87.9z" />
    <path fill="#BABABA" stroke="#36C8B5" strokeWidth={1.265} strokeMiterlimit={10} d="M100.7 105.5V45.6" />
    <path fill="#9CDAEE" d="M107.9 45.6l-7.2 7.2-7.2-7.2z" />
    <path
      fill="#008EB5"
      d="M96.4 45.6c-.5 1.8-1.1 5.3-1.3 12.1 1.2.6 2 1.7 2 3.1 0 1.9-1.5 3.4-3.4 3.4s-3.4-1.5-3.4-3.4c0-1.4.8-2.6 2-3.1.1-6 .6-9.7 1.2-12.1h2.9z"
    />
    <circle fill="#999" cx={93.7} cy={60.8} r={3.4} />
    <path fill="none" d="M105.8 54.1c-2.1 0-2.3 2.1-2.4 2.7h4.7c.1-.5-.2-2.7-2.3-2.7z" />
    <path
      fill="#008EB5"
      d="M105.8 54.1c2.1 0 2.3 2.2 2.4 2.7h2.9c0-1.9-1.1-4.7-3.8-5.4-.3-2.7-.7-4.5-1.2-5.8H103c.3.5 1 2 1.4 5.8-2.6.7-3.7 3.4-3.8 5.4h2.9c0-.6.2-2.7 2.3-2.7z"
    />
    <path
      fill="#999"
      d="M108.2 56.8v7.1s0 .8-.4 1.2c-.5.6-.5 1.5 0 2 .3.3.6.4 1 .4s.8-.1 1-.4c1.3-1.3 1.2-3.2 1.2-3.3v-7.1l-2.8.1zm-7.6 6.9c0 .2-.1 2 1.2 3.4.5.6 1.5.6 2 0 .6-.5.6-1.5 0-2-.4-.4-.4-1-.4-1.3v-7.1h-2.9l.1 7z"
    />
    <path
      fill="#C9936F"
      d="M103.4 77.4L92.9 66.8c-.6-.6-1.7-.6-2.3 0L76.2 81.1c-.6.6-.6 1.7 0 2.3L86.8 94c.6.6 1.7.6 2.3 0l14.3-14.3c.7-.6.7-1.7 0-2.3z"
    />
    <path fill="#EEE" d="M92.367 67.6l10.253 10.253-15.061 15.061-10.253-10.253z" />
    <path
      fill="#CCCBCB"
      d="M101.6 72l-.3-.3c.7-.7.7-1.9-.1-2.6-.7-.7-1.9-.7-2.6-.1l-.3-.3c-.7-.7-1.9-.7-2.7 0-.7.7-.7 1.9 0 2.7l3.3 3.3c.7.7 1.9.7 2.7 0 .7-.8.7-2 0-2.7zm-2.3-1c-.3-.3-.3-.8 0-1.2.3-.3.8-.3 1.2 0 .3.3.3.8 0 1.2-.4.3-.9.3-1.2 0z"
    />
    <path
      fill="#E4BA90"
      d="M114.2 85.6c-.5-.5-.5-1.2 0-1.7l2.2-2.2c.5-.5 1.2-.5 1.7 0s.5 1.2 0 1.7l-2.2 2.2c-.5.5-1.2.5-1.7 0z"
    />
    <circle fill="#E4BA90" cx={119.8} cy={87.9} r={4.4} />
    <path fill="#8DD1CB" d="M115.4 72.1h8.8v10h-8.8z" />
    <circle fill="#8DD1CB" cx={119.8} cy={72.1} r={4.4} />
    <path
      fill="#E4BA90"
      d="M115.4 82.2h8.8v5.7h-8.8zM95 77.7c0-.7-.5-1.2-1.2-1.2h-3.1c-.7 0-1.2.5-1.2 1.2s.5 1.2 1.2 1.2h3.1c.7 0 1.2-.6 1.2-1.2z"
    />
    <circle transform="rotate(-45.001 92.6 83.246)" fill="#E4BA90" cx={92.6} cy={83.2} r={4.4} />
    <path fill="#8DD1CB" d="M78.379 75.29l6.222-6.222 7.071 7.071-6.222 6.223z" />
    <circle transform="rotate(-45.001 81.48 72.125)" fill="#8DD1CB" cx={81.5} cy={72.1} r={4.4} />
    <path fill="#E4BA90" d="M85.48 82.336l6.223-6.223 4.03 4.03-6.222 6.223z" />
    <path
      fill="#796041"
      d="M106.9 25.4s-1.6 4.4-14.3 4.1c0 0-2.1 1.3-1.2 4.1l-1.4-.8S87.3 29 92.2 24c6.1-6.2 14.5-2.3 14.5-2.3s6.8.6 5 10.4l-1.3 1.6c0-.1-1.5-7.1-3.5-8.3z"
    />
  </svg>
);

export default DoctorIcon;
