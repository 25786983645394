import { PropsWithChildren } from '@health-activity-ui/shared';
import classnames from 'classnames';
import React from 'react';
import { Element, ElementProps } from '../../../../element/element';

export interface LevelItemProps extends ElementProps, PropsWithChildren {
  // to create an html element to attach classes to
  renderAs?: React.ElementType;
}

// Generates a 'level-item' class on a div for each element that needs to be horizontally centered
// https://bulma.io/documentation/layout/level
export const LevelItem = ({
  className = undefined,
  children = null,
  renderAs = 'div',
  ...allProps
}: LevelItemProps) => (
  <Element {...allProps} renderAs={renderAs} className={classnames('level-item', className, {})}>
    {children}
  </Element>
);

export default LevelItem;
