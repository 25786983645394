import { NotificationStatus } from '@health-activity-ui/shared';
import { generateUniqueKey } from '@health-activity-ui/utils';
import React, { createContext, Dispatch, useContext, useReducer, useRef } from 'react';
import { AnyAction } from 'redux';
import { CommonState } from './common.models';
import { CommonReducer } from './common.reducer';

const CommonContext = createContext<{ state: CommonState; dispatch: Dispatch<AnyAction> }>(undefined);
CommonContext.displayName = 'CommonProvider';

export const initialCommonState: CommonState = {
  responseBanners: [],
  allFetchHasCompleted: false,
  bannerInstance: null,
};

function useCommon(bannerId: string = null) {
  const context = useContext(CommonContext);
  const curBannerId = useRef<number | string>(bannerId);
  if (!context) {
    throw new Error(`useCommon must be used within the CommonProvider`);
  }

  return { ...context, curBannerId };
}

function CommonProvider({ children, commonState = initialCommonState }) {
  const [state, dispatch] = useReducer(CommonReducer, commonState);
  const value = { state, dispatch };

  return <CommonContext.Provider value={value}>{children}</CommonContext.Provider>;
}

const errorNotification = (message: string, { autoDismiss = false, id = generateUniqueKey() } = {}) => ({
  id,
  type: NotificationStatus.ERROR,
  message,
  autoDismiss,
});

const successNotification = (message: string, { autoDismiss = false, id = generateUniqueKey() } = {}) => ({
  id,
  type: NotificationStatus.SUCCESS,
  message,
  autoDismiss,
});

const infoNotification = (message: string, { autoDismiss = false, id = generateUniqueKey() } = {}) => ({
  id,
  type: NotificationStatus.INFO,
  message,
  autoDismiss,
});

const warningNotification = (message: string, { autoDismiss = false, id = generateUniqueKey() } = {}) => ({
  id,
  type: NotificationStatus.WARNING,
  message,
  autoDismiss,
});

export { CommonProvider, useCommon, errorNotification, successNotification, infoNotification, warningNotification };
