import { PaddingSizes } from '@health-activity-ui/shared';

export enum LineAxis {
  FromTop,
  FromBottom,
  FromLeft,
  FromRight,
}

export enum LineStyle {
  Square,
  RoundedStartEnd,
  RoundEnd,
}

interface BarBaseProps {
  lineAxis: LineAxis;
  lineStyle: LineStyle;
}

export interface ProgressBarProps extends Partial<BarBaseProps> {
  percent: number;
  className?: string;
  color?: string;
  length?: number | '100%';
  padding?: PaddingSizes;
  thickness?: string;
}

export interface BarGaugeProps extends BarBaseProps {
  className: string;
  length: number | '100%';
  padding: PaddingSizes;
}

export interface BarValueProps extends BarBaseProps {
  color: string;
  percent: number;
  thickness: string;
}
