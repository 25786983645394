import React from 'react';

interface Props {
  width?: number;
  height?: number;
}

export const BackPainIcon = ({ width = 200, height = 165 }: Props): React.ReactElement => (
  <svg viewBox="0 0 200 165" width={width} height={height}>
    <ellipse fill="#E5E5E4" cx={137.1} cy={143.4} rx={23.1} ry={2.6} />
    <defs>
      <path id="BackPainIcon__a" d="M35.8 19h124v123.7h-124z" />
    </defs>
    <clipPath id="BackPainIcon__b">
      <use xlinkHref="#BackPainIcon__a" overflow="visible" />
    </clipPath>
    <g clipPath="url(#BackPainIcon__b)">
      <path
        fill="#D6A46C"
        d="M124.9 72.9c1.5 4 6 6 9.9 4.4 4-1.5 6-6 4.4-9.9l-5.6-14.7c-1.5-4-6-6-9.9-4.4-4 1.5-6 6-4.4 9.9l5.6 14.7z"
      />
      <path
        fill="#BF9361"
        d="M120.9 27.9c4.9.3 9.5 3.2 11.8 8l3.6 7.3c3.4 7.1.5 15.6-6.6 19-.9.5-1.9.8-2.9 1 2.4.2 4.9-.3 7.2-1.4 7.1-3.4 10-11.9 6.6-19l-3.6-7.3c-2.9-6.1-9.7-9.1-16.1-7.6z"
      />
      <path
        fill="#D6A46C"
        d="M136.3 43.2l-3.6-7.3c-2.3-4.7-6.9-7.6-11.8-8-1 .2-1.9.6-2.9 1-7.1 3.4-10 11.9-6.6 19l3.6 7.3c2.3 4.7 6.9 7.6 11.8 8 1-.2 1.9-.6 2.9-1 7.1-3.4 10-11.9 6.6-19z"
      />
      <path
        fill="#D6A46C"
        d="M133.2 46.1l4.6-.1c1.4 0 2.1-1.8 1.1-2.8l-4.8-4.5c-1-1-2.8-.2-2.7 1.2l.1 4.6c.1.9.8 1.6 1.7 1.6zm-16.9 2.7c.1 1.7 1.5 3.1 3.2 3.1l-.3-6.5c-1.7.2-3 1.7-2.9 3.4z"
      />
      <circle transform="rotate(-32.451 113.207 123.918)" fill="#D6A46C" cx={113.2} cy={123.9} r={7.9} />
      <path fill="#D6A46C" d="M88.36 99.596l13.419-8.532 18.19 28.608-13.418 8.532z" />
      <path
        fill="#9CDAEE"
        d="M136.9 64.6L121.1 66c-4.4.4-7.6 4.2-7.2 8.6l6.6 73.2c.4 4.4 4.2 7.6 8.6 7.2l15.8-1.4c4.4-.4 7.6-4.2 7.2-8.6l-6-66.6c-.7-7.3-4.9-14.2-9.2-13.8z"
      />
      <circle transform="rotate(-32.451 95.028 95.327)" fill="#D6A46C" cx={95} cy={95.3} r={7.9} />
      <circle transform="rotate(-39.164 121.316 73.94)" fill="#9CDAEE" cx={121.3} cy={73.9} r={7.9} />
      <path fill="#D6A46C" d="M89.974 89.136l16.747-13.64 10.04 12.327-16.746 13.64z" />
      <path fill="#9CDAEE" d="M102.617 78.844l13.645-11.114 10.041 12.327-13.645 11.115z" />
      <path
        fill="#886846"
        d="M135.9 29.9s-9.1 7.1-13 7.1-6.6-1.4-5 1.5c1.6 2.9 1.6 5.3 1.6 5.3l-1.2 2.1s1.1 9.8-2.7 11.6c0 0-4.1.8-7-6.7s-3.9-15.1 2-19.3c-.1-.1 9.7-12.9 25.3-1.6z"
      />
      <path
        fill="#EF5B4B"
        d="M87.9 47.1l-6.8 5.4-4.7-13 .5 21.9 6.9-5.4L85 71.7l6.9-5.4 4.7 13-.6-21.9-6.8 5.4zM61 125.7l5.5 6.8-13 4.7 21.8-.5-5.4-6.9 15.7-1.2-5.4-6.9 13-4.7-21.9.6 5.4 6.8zm-6.8-45l-1 8.7-12.5-5.9 15.8 15.1 1-8.7 12 10.2 1-8.7L83 97.3 67.2 82.2l-1 8.7z"
      />
    </g>
    <path
      clipPath="url(#BackPainIcon__b)"
      fill="#D6A46C"
      d="M116 112.8c-1.2-.5-2.6.1-3.1 1.3s.1 2.6 1.3 3.1l4.8 1.9c1.2.5 2.6-.1 3.1-1.3s-.1-2.6-1.3-3.1l-4.8-1.9z"
    />
  </svg>
);

export default BackPainIcon;
