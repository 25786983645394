import { analyticsService, AuthenticationProvider } from '@health-activity-ui/data-access';
import { NodeEnvironments, ROUTE_PRODUCT_NAME, ROUTE_PRODUCT_PATH } from '@health-activity-ui/shared';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AcLoader, FiveZeroZero } from '@health-activity-ui/ui-react';
import { initDatadog } from '@health-activity-ui/utils';
import { defineCustomElements as advantageComponents } from '@rally/advantage-components/dist/loader';
import { defineCustomElements as impersonationTools } from '@rally/impersonation-tools/dist/loader';
import { StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { environment } from '../../../environments/environment';
import App from './app/app';
import AppContextProvider from './context/app-context-provider';

// This may need to be reworked in the future by instead using an authorization check from advantage
// but this is good for now, especially since it's no longer a hard requirement for Domain Unification
const brandedSlug = window.location.pathname.slice(0, window.location.pathname.indexOf(ROUTE_PRODUCT_NAME));
/**
 * To turn on mock api's handled by msw for local development
 * make sure to pass the 'NX_MOCK_API' as 'true' or run `npm run start:msw`
 */
if (process.env.NODE_ENV === NodeEnvironments.Development && process.env.NX_MOCK_API === 'true') {
  require('test/server');
}

// Datadog logging
if (environment.production) initDatadog();

// initialize enabled analytics
analyticsService.initAppAnalytics();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <StrictMode>
    <BrowserRouter
      basename={
        window.location.pathname.includes(ROUTE_PRODUCT_PATH)
          ? `${brandedSlug}${ROUTE_PRODUCT_NAME}`
          : ROUTE_PRODUCT_NAME
      }
    >
      <Suspense fallback={<AcLoader />}>
        <AuthenticationProvider loaderComponent={<AcLoader />} errorComponent={<FiveZeroZero />}>
          <AppContextProvider>
            <App />
          </AppContextProvider>
        </AuthenticationProvider>
      </Suspense>
    </BrowserRouter>
  </StrictMode>
);

impersonationTools(window);
advantageComponents(window);
