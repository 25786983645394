import { RDS_BOX_SHADOW_NEUTRAL_WITH_SPREAD_6, RDS_COLOR_SYSTEM_CHARCOAL_LIGHT } from '@rally/ui-themes/dist/web/rally';
import styled, { createGlobalStyle } from 'styled-components';

import DotsHorizontalIcon from '../icons/dots-horizontal-icon';
import React from 'react';

const DotsDropDownGlobalStyles = createGlobalStyle`
  .drop-down-button:hover > .dots-drop-down,
  .drop-down-button:active > .dots-drop-down,
  .drop-down-button:focus > .dots-drop-down {
    border: 1px solid ${RDS_COLOR_SYSTEM_CHARCOAL_LIGHT};
    box-shadow: ${RDS_BOX_SHADOW_NEUTRAL_WITH_SPREAD_6}
  }
`;

const DotsDropDownStyled = styled.div`
  height: 32px;
  width: 32px;
`;

export const DotsDropDown = (): React.ReactElement => (
  <DotsDropDownStyled className="dots-drop-down has-rds-bg-white has-rds-radius-circle is-flex is-align-items-center is-justify-content-center has-text-centered">
    <DotsDropDownGlobalStyles />
    <DotsHorizontalIcon stroke={RDS_COLOR_SYSTEM_CHARCOAL_LIGHT} />
  </DotsDropDownStyled>
);

export default DotsDropDown;
