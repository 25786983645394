export enum Target {
  _blank = '_blank',
  _self = '_self',
  _parent = '_parent',
  _top = '_top',
}

export interface AdvantageConfig {
  footer: Footer;
  header: Header;
  help: AdvantageLink;
  notifications: AdvantageLink;
  whiteLabel?: {
    primaryColor: string;
    primaryAccentColor: string;
    fill: string;
    outlineColor: string;
    secondaryColor?: string;
    secondaryAccentColor?: string;
  };
}

export interface AdvantageLink {
  text: DisplayText;
  rel?: string;
  target: Target;
  url: string;
  meta?: Meta;
  hideSubnavigationMenu?: boolean;
  id: string;
  productId?: string;
}

export interface NavSection {
  productId: string;
  link: AdvantageLink;
  subnavs: SubnavSection[];
}

export interface SubnavSection {
  productId: string;
  link: AdvantageLink;
}

export interface DisplayText {
  short: string;
  long: string;
}

export interface Footer {
  corporate: AdvantageLink[];
  customNotes?: string;
  footerSections:
    | {
        [x: string]: FooterSection;
      }
    | [FooterSection];
}

export interface FooterSection {
  id: string;
  heading: DisplayText;
  links:
    | {
        [x: string]: AdvantageLink;
      }
    | AdvantageLink[];
  productId?: string;
}

export interface Header {
  logo: ImageLink;
  accountMenu: AdvantageLink[];
  nav:
    | {
        [x: string]: AdvantageLink;
      }
    | NavSection[];
}

export interface ImageLink {
  link?: AdvantageLink;
  imageSrc?: string;
  imageAltText: string;
}

export interface Meta {
  name: string;
  section?: string;
  isIntermediary?: boolean;
  icon?: string;
}

export const EMPTY_ADVANTAGE_LINK: AdvantageLink = {
  text: {
    short: '',
    long: '',
  },
  target: Target._blank,
  url: '',
  id: '',
};

export interface MemberInfo {
  isEfs: boolean;
  memberId: string;
  dob: Date;
}
