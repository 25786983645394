import React, { MouseEvent } from 'react';
import { HandHoldXIcon } from '../icons/hand-hold-x-icon';
import { RdsIcon, RdsLink } from '@rally/energon-react/dist/es6';
import { useTranslation } from 'react-i18next';

interface Props {
  click(event?: MouseEvent<HTMLAnchorElement>): void;
}

export const ErrorReload = ({ click }: Props) => {
  const { t } = useTranslation();
  return (
    <div className="is-flex is-flex-direction-column is-align-items-center">
      <div className="is-w-full has-text-right has-rds-pb-32">
        <RdsLink className="refresh-link" typographySize="body-3" variant="inline" onClick={click}>
          {t('reload')} <RdsIcon name="button-start-over" size="16" />
        </RdsLink>
      </div>
      <HandHoldXIcon />
      <p className="is-rds-h5 has-rds-pt-16 has-rds-pb-32 error-message">{t('looksLikeSomethingWent')}</p>
    </div>
  );
};
