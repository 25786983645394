import React from 'react';

interface Props {
  color?: string;
  height?: string;
  width?: string;
}

export const SwimmingIcon = ({ color = '#fff', width = '27px', height = '27px' }: Props): React.ReactElement => (
  <svg viewBox="0 0 99 45" height={height} width={width} aria-hidden="true" data-testid="swimming-icon">
    <g fill={color}>
      <path d="M84 9H69L57 20l10 10a4 4 0 011 3v2a7 7 0 01-2 1l-6-2c-3-2-6-4-11-4s-7 2-11 4l-5 2a7 7 0 01-3-1L64 1a4 4 0 013-1h17a4 4 0 010 9z" />
      <path d="M68 28a8 8 0 1111-5 8 8 0 01-11 5zM0 45c4 0 7-1 10-3l7-2 6 2c3 2 6 3 10 3s7-1 10-3l6-2 7 2c3 2 6 3 10 3s7-1 10-3l6-2 7 2c3 2 6 3 10 3v-7l-7-2-9-3c-3 0-7 1-10 3l-7 2-7-2-9-3c-3 0-7 1-10 3l-7 2-7-2-9-3c-3 0-7 1-10 3l-7 2z" />
    </g>
  </svg>
);

export default SwimmingIcon;
