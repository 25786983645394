import React from 'react';

interface Props {
  width?: number;
  height?: number;
}

export const AbsenteeismIcon = ({ width = 181, height = 151 }: Props): React.ReactElement => (
  <svg viewBox="0 0 200 165" height={height} width={width}>
    <defs>
      <clipPath id="AbsenteeismIcon__a">
        <path className="AbsenteeismIcon__a" d="M37.2 22.1h149.4v106.6H37.2z" />
      </clipPath>
      <clipPath id="AbsenteeismIcon__b">
        <path
          className="AbsenteeismIcon__a"
          d="M106.6 139.5h-2.8l.1-4.4 2.8.1a2.3 2.3 0 10.2-4.6l-7.9-.4a2.28 2.28 0 00-1.3-4.1L95 126l.3-4.4 2.8.3a2.27 2.27 0 002.5-2 2.74 2.74 0 00-.2-1.3l4.7.5h.2a2.69 2.69 0 001.5-.5 2.09 2.09 0 00.8-1.6l1.1-13.4a2.72 2.72 0 00-.6-1.7 2.09 2.09 0 00-1.6-.8l-7.7-.4.6-7.6-2.4-.3.5-6.2-70-5.6-.6 7.5 2.4.2-.7 8.2-9.7-.5a2.21 2.21 0 00-2.4 2.2 2.27 2.27 0 002.2 2.4l2.8.2-.4 4.4-2.8-.3a2.27 2.27 0 00-2.5 2 2.23 2.23 0 00.3 1.4l-4.8-.5a2.16 2.16 0 00-1.7.5 2.09 2.09 0 00-.8 1.6l-1.1 13.4a2.72 2.72 0 00.6 1.7 2.47 2.47 0 001.6.8l7.8.4a2 2 0 00-.7 1.6l-.4 13.4a2.34 2.34 0 00.7 1.7 2.13 2.13 0 001.6.7l87.7.1a2.3 2.3 0 000-4.6z"
        />
      </clipPath>
      <style>
        {
          '.AbsenteeismIcon__a,.AbsenteeismIcon__m{fill:none}.AbsenteeismIcon__b{opacity:.1;isolation:isolate}.AbsenteeismIcon__d{opacity:.4}.AbsenteeismIcon__e{fill:#fff}.AbsenteeismIcon__k{fill:#ccc}.AbsenteeismIcon__l{fill:#b2b2b2}.AbsenteeismIcon__m{stroke:#008eb5;stroke-linecap:round;stroke-linejoin:round;stroke-width:4.61px}'
        }
      </style>
    </defs>
    <ellipse className="AbsenteeismIcon__b" cx={65.3} cy={147.9} rx={53.7} ry={1.7} />
    <path className="AbsenteeismIcon__b" d="M188.5 130.5H39.1V23.9l147.6-1.8 1.8 108.4z" />
    <path fill="#9cdaee" d="M37.2 22.1h149.4v106.6H37.2z" />
    <g className="AbsenteeismIcon__d">
      <path
        className="AbsenteeismIcon__e"
        d="M81.6 43.4h18.3v14.1H81.6zm20.6 0h18.3v14.1h-18.3zm20.7 0h18.3v14.1h-18.3zm20.6 0h18.3v14.1h-18.3zm20.6 0h18.3v14.1h-18.3z"
      />
    </g>
    <g className="AbsenteeismIcon__d">
      <path
        className="AbsenteeismIcon__e"
        d="M40.3 60.3h18.3v14.1H40.3zm20.7 0h18.3v14.1H61zm20.6 0h18.3v14.1H81.6zm20.6 0h18.3v14.1h-18.3zm20.7 0h18.3v14.1h-18.3zm20.6 0h18.3v14.1h-18.3zm20.6 0h18.3v14.1h-18.3z"
      />
    </g>
    <g className="AbsenteeismIcon__d">
      <path
        className="AbsenteeismIcon__e"
        d="M40.3 77.3h18.3v14.1H40.3zm20.7 0h18.3v14.1H61zm20.6 0h18.3v14.1H81.6zm20.6 0h18.3v14.1h-18.3zm20.7 0h18.3v14.1h-18.3zm20.6 0h18.3v14.1h-18.3zm20.6 0h18.3v14.1h-18.3z"
      />
    </g>
    <g className="AbsenteeismIcon__d">
      <path
        className="AbsenteeismIcon__e"
        d="M40.3 94.2h18.3v14.1H40.3zm20.7 0h18.3v14.1H61zm20.6 0h18.3v14.1H81.6zm20.6 0h18.3v14.1h-18.3zm20.7 0h18.3v14.1h-18.3zm20.6 0h18.3v14.1h-18.3zm20.6 0h18.3v14.1h-18.3z"
      />
    </g>
    <g className="AbsenteeismIcon__d">
      <path
        className="AbsenteeismIcon__e"
        d="M40.3 111.2h18.3v14.1H40.3zm20.7 0h18.3v14.1H61zm20.6 0h18.3v14.1H81.6zm20.6 0h18.3v14.1h-18.3z"
      />
    </g>
    <path
      d="M82.5 59.2l16.4 16.4m0-16.4L82.5 75.6m21.3-16.4l16.3 16.4m0-16.4l-16.3 16.4m-.9 17.8l16.4 16.4m0-16.4l-16.4 16.4m41.8-33.4l16.4 16.4m0-16.4l-16.4 16.4m-.2-50.6l16.3 16.4m0-16.4l-16.3 16.4"
      stroke="#ef5b4b"
      strokeMiterlimit={10}
      strokeWidth={5.48}
      fill="none"
    />
    <circle className="AbsenteeismIcon__e" cx={64.6} cy={27.5} r={2.7} />
    <circle className="AbsenteeismIcon__e" cx={155.3} cy={27.5} r={2.7} />
    <g clipPath="url(#AbsenteeismIcon__a)" opacity={0.26}>
      <path d="M109.1 146.5h-2.8l.1-4.4 2.8.1a2.3 2.3 0 10.2-4.6l-7.9-.4a2.28 2.28 0 00-1.3-4.1l-2.8-.1.3-4.4 2.8.3a2.27 2.27 0 002.5-2 2.74 2.74 0 00-.2-1.3l4.7.5h.2a2.69 2.69 0 001.5-.5 2.09 2.09 0 00.8-1.6l1.1-13.4a2.72 2.72 0 00-.6-1.7 2.09 2.09 0 00-1.6-.8l-7.7-.4.6-7.6-2.4-.2.5-6.2L30 88l-.6 7.5 2.4.2-.7 8.2-9.7-.5a2.21 2.21 0 00-2.4 2.2 2.27 2.27 0 002.2 2.4l2.8.2-.4 4.4-2.8-.3a2.27 2.27 0 00-2.5 2 2.23 2.23 0 00.3 1.4l-4.8-.5a2.16 2.16 0 00-1.7.5 2.09 2.09 0 00-.8 1.6l-1.1 13.4a2.72 2.72 0 00.6 1.7 2.47 2.47 0 001.6.8l7.8.4a2 2 0 00-.7 1.6l-.4 13.4a2.34 2.34 0 00.7 1.7 2.13 2.13 0 001.6.7l87.7.1a2.26 2.26 0 002.3-2.3 2.33 2.33 0 00-2.3-2.3z" />
    </g>
    <path fill="#fddd7f" d="M29.273 101.76l.868-10.765 70.073 5.65-.869 10.765z" />
    <path fill="#fde7a8" d="M27.612 90.66l.603-7.476 70.073 5.65-.603 7.476z" />
    <path className="AbsenteeismIcon__k" d="M19.668 144.037l.302-11.096 84.868 2.312-.302 11.095z" />
    <path className="AbsenteeismIcon__l" d="M24.8 136.8l79.8 4.5.2-4.4-83.1-5.7-2 13.3h4.6l.5-7.7z" />
    <path className="AbsenteeismIcon__m" d="M107.4 145.3H19.7l.3-13.5 87.6 4.7" />
    <path className="AbsenteeismIcon__k" d="M10.9 126.245l.872-11.065 84.638 6.676-.873 11.065z" />
    <path className="AbsenteeismIcon__l" d="M16 119.7l80 7.6.3-4.4-83.5-8.8-2 13.3h4.7l.5-7.7z" />
    <path
      stroke="#dc823d"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={4.61}
      fill="none"
      d="M98.4 132l-87.6-4.6 1.1-13.4 87.2 9.2"
    />
    <path className="AbsenteeismIcon__k" d="M21.576 112.391l.892-11.064 84.625 6.824-.892 11.064z" />
    <path className="AbsenteeismIcon__l" d="M22.3 103.4v3.2c0-.1 80.7 4.7 80.7 4.7l-.7 8.3 3.9-.4v-13z" />
    <path className="AbsenteeismIcon__m" d="M18.9 111l87.2 9.4 1.1-13.4-87.6-4.8" />
    <path className="AbsenteeismIcon__b" d="M30.1 93.7l.1-2.7 70 5.6-.2 2.6-69.9-5.5z" />
    <g clipPath="url(#AbsenteeismIcon__b)" opacity={0.22}>
      <path d="M170.6 107.3H169V106a1.69 1.69 0 00-1.7-1.7h-33.5a1.69 1.69 0 00-1.7 1.7v44.3a2.26 2.26 0 002.3 2.3h32.3a2.26 2.26 0 002.3-2.3v-3.7h1.7a14.47 14.47 0 0014.4-14.4v-10.5a14.49 14.49 0 00-14.5-14.4zm7 24.9a7 7 0 01-7 7H169v-24.5h1.7a7 7 0 017 7v10.5z" />
    </g>
  </svg>
);

export default AbsenteeismIcon;
