import React from 'react';

interface Props {
  circleFillColor?: string;
  checkMarkFillColor?: string;
  alt?: string;
}

export const CheckMarkGreenIcon = ({
  circleFillColor = '#2C8652',
  checkMarkFillColor = '#FFF',
  alt,
}: Props): React.ReactElement => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" className="check-mark-svg" role={alt ? 'img' : ''} aria-label={alt}>
      {alt && <title>{alt}</title>}
      <g fill="none" fillRule="evenodd">
        <circle fill={circleFillColor} cx={12} cy={12} r={12} />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          className="checkmark-path"
          d="M5 13l4 4L19 7"
          stroke={checkMarkFillColor}
        />
      </g>
    </svg>
  );
};

export default CheckMarkGreenIcon;
