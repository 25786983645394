import {
  DevicePartner,
  DevicePartnerDisplayName,
  DeviceUserSettings,
  IMissionHistoryResponse,
  IMissionResponse,
} from '@health-activity-ui/shared';
import { isDefined } from './utils';

/**
 * @description takes in mission.category and returns the Amplitude friendly version of it
 */

export const getMissionCategoryAsAmplitudeString = (missionCategory: string): string => {
  const missionCategories = {
    lifestyle: 'Lifestyle',
    clinical: 'Clinical',
    financial: 'Financial',
    nutrition: 'Nutrition',
    dental: 'Dental',
    diabetes: 'DiabetesExercise',
    chf: 'HeartFailure',
    hypertension: 'HighBloodPressure',
    weight: 'Weight',
    stress: 'Stress',
    chd: 'CoronaryHeartDisease',
    copd: 'COPD',
    hyperlipidemia: 'HighCholesterol',
    tobacco: 'TobaccoUse',
    asthma: 'Asthma',
    alcohol: 'Alcohol',
    sleep: 'Sleep',
    mood: 'Mood',
  };

  return missionCategories[missionCategory];
};

export const transformMissionResponse = (mission: IMissionResponse): IMissionResponse => ({
  ...mission,
  ...(isDefined(mission?.instance) && {
    instance: {
      ...mission?.instance,
      history: mission.instance.history.map((h) => ({
        ...h,
        startDate: h.startDate,
        endDate: h.endDate,
        dailyProgress: h.dailyProgress.map((d) => ({ ...d, date: d.date })),
      })),
      stats: {
        ...mission.instance.stats,
        missionStartDate: mission.instance.stats.missionStartDate,
        currentInterval: {
          ...mission.instance.stats.currentInterval,
          startDate: mission.instance.stats.currentInterval.startDate,
          endDate: mission.instance.stats.currentInterval.endDate,
        },
        currentDay: {
          ...mission.instance.stats.currentDay,
          date: mission.instance.stats.currentDay.date,
        },
        ...(isDefined(mission.instance.stats.lastCheckinDate) && {
          lastCheckinDate: mission.instance.stats.lastCheckinDate,
        }),
        ...(isDefined(mission.instance.stats.completedDate) && {
          completedDate: mission.instance.stats.completedDate,
        }),
        ...(isDefined(mission.instance.stats.lastDataUpdate) && {
          lastDataUpdate: mission.instance.stats.lastDataUpdate,
        }),
      },
    },
  }),
});

export const transformMissionHistoryResponse = (missionHistory: IMissionHistoryResponse): IMissionHistoryResponse => ({
  ...missionHistory,
  ...(isDefined(missionHistory.startDay) && {
    startDay: missionHistory.startDay,
  }),
  ...(isDefined(missionHistory.endDay) && {
    endDay: missionHistory.endDay,
  }),
  history: missionHistory.history.map((h) => ({ ...h, date: h.date })),
});

export const determinePartnerOrManual = (deviceSettingsResp: DeviceUserSettings, missionId: string): DevicePartner => {
  const keys = [missionId, 'health-activity-ui'];
  if (deviceSettingsResp?.manualClients?.some((item) => keys.indexOf(item) > -1)) {
    return DevicePartner.Manual;
  } else if (deviceSettingsResp?.dataTypeSettings?.length) {
    const partner = deviceSettingsResp.dataTypeSettings.find(({ dataType }) => dataType === 'Activity')?.partner;
    return DevicePartner[partner] ?? DevicePartner.NotProvided;
  }

  return DevicePartner.NotProvided;
};

// Map device partner to the device display name
export const DevicePartnerToDisplayName: Record<DevicePartner, DevicePartnerDisplayName> = {
  [DevicePartner.fatsecret]: DevicePartnerDisplayName.FatSecret,
  [DevicePartner.fitbit]: DevicePartnerDisplayName.Fitbit,
  [DevicePartner.garmin]: DevicePartnerDisplayName.Garmin,
  [DevicePartner.hattrick]: DevicePartnerDisplayName.Hattrick,
  [DevicePartner.jawbone]: DevicePartnerDisplayName.Jawbone,
  [DevicePartner.mapmyfitness]: DevicePartnerDisplayName.MapMyRun,
  [DevicePartner.misfit]: DevicePartnerDisplayName.Misfit,
  [DevicePartner.moves]: DevicePartnerDisplayName.Moves,
  [DevicePartner.myfitnesspal]: DevicePartnerDisplayName.MyFitnessPal,
  [DevicePartner.rally]: DevicePartnerDisplayName['My Mobile Device'],
  [DevicePartner.runkeeper]: DevicePartnerDisplayName.RunKeeper,
  [DevicePartner.strava]: DevicePartnerDisplayName.Strava,
  [DevicePartner.striiv]: DevicePartnerDisplayName.Striiv,
  [DevicePartner.uarecord]: DevicePartnerDisplayName.MapMyFitness,
  [DevicePartner.withings]: DevicePartnerDisplayName.Withings,
  [DevicePartner.mobileInterim]: DevicePartnerDisplayName.mobileInterim,
  [DevicePartner.Manual]: DevicePartnerDisplayName.Manual,
  [DevicePartner.NotProvided]: DevicePartnerDisplayName.NotProvided,
  [DevicePartner.NotSupported]: DevicePartnerDisplayName.NotSupported,
};
