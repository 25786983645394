import React from 'react';

export const MobileIcon = (): React.ReactElement => (
  <svg width="35" height="41" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <path fill="#F36C33" d="M9.336 31.499h17.33V4.517H9.336z" />
      <path
        d="M9.63 31.137h16.716V4.912H9.631v26.225zM25.11.172H10.931A2.93 2.93 0 008 3.101v29.847a2.93 2.93 0 002.932 2.93h14.113a2.93 2.93 0 002.932-2.93V3.036A2.865 2.865 0 0025.11.172z"
        fill="#5C5B5E"
      />
      <path
        d="M18.543 14.601l3.35 5.702a.426.426 0 00.368.214h.492c.307 0 .522-.366.369-.671l-4.579-8.05a.406.406 0 00-.707 0l-4.609 8.08c-.153.305.03.671.369.671h.522c.154 0 .277-.091.369-.213l3.35-5.702c.153-.305.522-.305.706-.03"
        fill="#FFF"
      />
      <path
        d="M18.583 19.356l.522.977a.425.425 0 00.368.214h.522c.306 0 .521-.366.368-.672l-1.75-3.084a.405.405 0 00-.705 0l-1.75 3.084c-.153.306.03.672.369.672h.521c.154 0 .276-.092.369-.214l.521-.977c.093-.275.46-.275.645 0"
        fill="#FFF"
      />
      <path
        d="M34.543 39.896c0 .495-7.732.897-17.271.897C7.732 40.793 0 40.39 0 39.896S7.733 39 17.272 39c9.539 0 17.271.401 17.271.896"
        fillOpacity=".2"
        fill="#020202"
      />
    </g>
  </svg>
);

export default MobileIcon;
