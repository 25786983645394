import classnames from 'classnames';

export type MarginSizes = PaddingSizes | 'auto';
export type PaddingSizes = '8' | '16' | '24' | '32' | '40' | '48' | '64' | 'none';

export interface SpacingProps {
  // the padding to apply to all sides of an html element
  padding?: PaddingSizes;

  // the margin size to apply to all sides of an html element
  margin?: MarginSizes;
}

export default {
  defaultProps: {
    padding: undefined,
    margin: undefined,
  },
  classnames: (props: SpacingProps): string => {
    return classnames({
      [`has-rds-p-${props.padding}`]: props.padding,
      [`has-rds-m-${props.margin}`]: props.margin,
    });
  },
  /* eslint-disable-next-line */
  base: ({ padding, margin, ...props }) => props,
};
