export type ReadinessGoalType = 'unknown' | 'missionPack' | 'programs';
export const ReadinessGoalType = {
  Default: 'unknown' as ReadinessGoalType,
  MissionPack: 'missionPack' as ReadinessGoalType,
  Programs: 'programs' as ReadinessGoalType,
};

export type ReadinessGoalName = 'Mission' | 'Program';
export const ReadinessGoalName = {
  Mission: 'Mission' as ReadinessGoalName,
  Program: 'Program' as ReadinessGoalName,
};

export type PlanStatus = 'aborted' | 'completed' | 'unknown' | 'inProgress';
export const PlanStatus = {
  Aborted: 'aborted' as PlanStatus,
  Completed: 'completed' as PlanStatus,
  Default: 'unknown' as PlanStatus,
  InProgress: 'inProgress' as PlanStatus,
};

export type AcuityState = 'high' | 'low' | 'medium' | 'notApplicable' | 'uncategorized' | 'unknown';
export const AcuityState = {
  High: 'high' as AcuityState,
  Low: 'low' as AcuityState,
  Medium: 'medium' as AcuityState,
  NotApplicable: 'notApplicable' as AcuityState,
  Uncategorized: 'uncategorized' as AcuityState,
  Unknown: 'unknown' as AcuityState,
};

export type R2CTopics =
  | 'alcohol'
  | 'exercise'
  | 'mood'
  | 'nicotineUse'
  | 'nutrition'
  | 'sleep'
  | 'stress'
  | 'underweight'
  | 'weight';
export const R2CTopics = {
  Alcohol: 'alcohol' as R2CTopics,
  Exercise: 'exercise' as R2CTopics,
  Mood: 'mood' as R2CTopics,
  NicotineUse: 'nicotineUse' as R2CTopics,
  Nutrition: 'nutrition' as R2CTopics,
  Sleep: 'sleep' as R2CTopics,
  Stress: 'stress' as R2CTopics,
  Underweight: 'underweight' as R2CTopics,
  Weight: 'weight' as R2CTopics,
};

export type UserReadinessState = 'notReady' | 'now' | 'soon';
export const UserReadinessState = {
  NotReady: 'notReady' as UserReadinessState,
  Now: 'now' as UserReadinessState,
  Soon: 'soon' as UserReadinessState,
};

export interface IUserReadiness {
  id: string;
  topics: IUserTopicReadiness[];
  userPlan?: IUserPlan;
}

export interface IR2CUserInput {
  nowTopics: R2CTopics[];
  soonTopics: R2CTopics[];
  userPlan?: IR2CUserPlan;
}

export interface IR2CUserPlan {
  goalType?: ReadinessGoalType;
  planId?: string;
  completionDate?: string;
  status?: string;
}

/**
 * @param goalType type of goal the user selected
 * @param planId ID of this plan. For MissionPack, this ID will be None until the pack is completed
 * @param creationDate created timestamp
 * @param lastModifiedDate last updated
 * @param completionDate goal completed timestamp
 * @param status indicates the current status of the plan
 */
export interface IUserPlan {
  goalType: ReadinessGoalType;
  planId?: string;
  creationDate: string;
  lastModifiedDate: string;
  completionDate?: string;
  status: PlanStatus;
}

export interface IUserTopicReadiness {
  headlineCopy: string;
  readinessState: UserReadinessState;
  topic: R2CTopics;
  acuityState?: AcuityState;
  infoCopy?: string;
  subHeadlineCopy?: string;
}
