import classnames from 'classnames';
import React, { forwardRef } from 'react';
import { InputProps } from './forms.interface';
import useErrorStyles from './use-error-styles.hook';
import useRegister from './use-register.hook';

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      name,
      /**
       * If an `id` prop is passed to `<Input>`, you must also pass the same unique value
       * to the `<Label>`'s htmlFor prop. Else defaults to `name`.
       * */
      id,
      // for useErrorStyles
      errorClassName,
      errorStyle,
      className: propClassName,
      style: propStyle,
      // for useRegister
      validation,
      onBlur,
      onChange,
      type = 'text',
      sizing = 'default',
      autoComplete = 'off',
      ...rest
    },
    ref
  ) => {
    const { style, className } = useErrorStyles({
      name,
      errorClassName,
      errorStyle,
      className: propClassName,
      style: propStyle,
    });

    const useRegisterReturn = useRegister(
      {
        name,
        validation,
        onBlur,
        onChange,
        type,
      },
      ref
    );

    return (
      <input
        id={id || name}
        {...rest}
        type={type}
        style={style}
        className={classnames(
          'rds-input has-rds-ph-8',
          {
            'is-auto': sizing === 'auto',
            'is-rds-fullwidth': sizing === 'full',
          },
          className
        )}
        autoComplete={autoComplete}
        {...useRegisterReturn}
      />
    );
  }
);
