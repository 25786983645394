import React from 'react';

interface Props {
  width?: number;
  height?: number;
}

export const PregnantIcon = ({ width = 200, height = 165 }: Props): React.ReactElement => (
  <svg viewBox="0 0 200 165" width={width} height={height}>
    <ellipse fill="#E5E5E4" cx={106.4} cy={149.6} rx={20.3} ry={2.8} />
    <path
      fill="#EAC2A7"
      d="M94.4 43.8c-.1 2.3 1.5 4.1 3.5 4.2 2 0 3.7-1.8 3.7-4l.2-8.4c.1-2.3-1.5-4.1-3.5-4.2-2 0-3.7 1.8-3.7 4l-.2 8.4z"
    />
    <path
      fill="#CCAB96"
      d="M99.7 18.3c2.8.9 5 3.2 5.6 6.3l1 4.7c.9 4.5-2 9-6.5 9.9-.6.1-1.2.2-1.8.2 1.3.4 2.8.5 4.3.2 4.5-.9 7.4-5.4 6.5-9.9l-1-4.7c-.8-3.9-4.3-6.6-8.1-6.7z"
    />
    <path
      fill="#EAC2A7"
      d="M107 29.2l-1-4.7c-.6-3-2.9-5.4-5.6-6.3-.6 0-1.2.1-1.8.2-4.5.9-7.4 5.4-6.5 9.9l1 4.7c.6 3 2.9 5.4 5.6 6.3.6 0 1.2-.1 1.8-.2 4.6-1 7.5-5.4 6.5-9.9z"
    />
    <path
      fill="#EAC2A7"
      d="M104.6 31.7l2.5 1c.8.3 1.6-.5 1.2-1.2l-1.5-3.6c-.3-.8-1.4-.8-1.8 0l-1 2.5c-.1.6.1 1.1.6 1.3z"
    />
    <path
      fill="#333"
      d="M91.2 23.1s0-.1-.1-.2-.3-.4-.6-.5c-.3-.2-.7-.3-1.1-.4-.4-.1-.9-.1-1.5-.1-1.1.1-2.3.4-3.4 1.1-1.1.7-2.2 1.9-2.8 3.4-.3.7-.5 1.6-.5 2.4 0 .8.1 1.6.3 2.3.2.7.6 1.3.9 1.8.4.5.8.9 1.2 1.2.4.3.8.6 1.2.7.4.2.7.3 1 .4.3.1.5.1.7.1h.3v-3.1c0-.8.1-1.5.2-2.1.1-.6.3-1 .5-1.5.1-.2.2-.5.4-.8.1-.3.3-.6.5-.9.4-.6.8-1.3 1.2-1.9.4-.6.8-1.1 1.2-1.5.2-.1.3-.3.5-.4-.2.1-.1 0-.1 0z"
    />
    <ellipse transform="rotate(-50.454 91.156 23.494)" fill="#2BA091" cx={91.2} cy={23.5} rx={2.5} ry={1.5} />
    <path
      fill="#333"
      d="M90.7 30.9c-1.9-5.5.6-11.5 5.5-13.2 4.5-1.6 9.8-1.7 12.5 6.2l-10.2 2.3c-1 .3-2 1-2.7 2.6 0 0-2.9 4.6-1.5 7.4 0 0-1.8 0-3.6-5.3z"
    />
    <path fill="#EAC2A7" d="M94.1 30.3c-.2 1 .4 2 1.4 2.2l.8-3.8c-1.1-.1-2 .6-2.2 1.6z" />
    <path fill="#2BA091" d="M122.4 76.1c0 9.1-7.3 16.4-16.4 16.4s-9.1-32.8 0-32.8 16.4 7.3 16.4 16.4z" />
    <path fill="#EAC2A7" d="M89.2 86.4c0 3.3 2.7 6 6 6v50.3c0 3.3 2.7 6 6 6s6-2.7 6-6V81.2h-18v5.2z" />
    <path
      fill="#2BA091"
      d="M107.2 47.4v-.1c-.1-3.2-2.7-5.8-6-5.8h-6c-3.3 0-5.9 2.6-6 5.8v33.9h18V47.4zm-18 33.8l-6.8 19.2h32.3l-7.5-19.2z"
    />
    <path
      fill="#EAC2A7"
      d="M77.4 66.9c-1.1 0-2.1-.4-3-1.2-1.6-1.6-1.6-4.3 0-5.9L91.2 43c1.6-1.6 4.3-1.6 5.9 0 1.6 1.6 1.6 4.3 0 5.9L80.4 65.7c-.8.8-1.9 1.2-3 1.2z"
    />
    <path
      fill="#EAC2A7"
      d="M94.1 83.6c-1.1 0-2.1-.4-3-1.2L74.5 65.7c-1.6-1.6-1.6-4.3 0-5.9 1.6-1.6 4.3-1.6 5.9 0l16.7 16.7c1.6 1.6 1.6 4.3 0 5.9-.8.8-1.9 1.2-3 1.2z"
    />
    <path fill="#2BA091" d="M86.2 48l5-5c1.6-1.6 4.3-1.6 5.9 0 1.6 1.6 1.6 4.3 0 5.9l-5 5-5.9-5.9z" />
    <path
      fill="#EAC2A7"
      d="M96.9 73.6c0 .6-.5 1.1-1.1 1.1h-2.9c-.6 0-1.1-.5-1.1-1.1 0-.6.5-1.1 1.1-1.1h2.9c.6 0 1.1.5 1.1 1.1z"
    />
    <path
      fill="#2BA091"
      d="M103.6 56.1c-.3 1.9-.1 5.3 1.1 5.5 1.2.2 5.1-3.3 5.1-5.2 0-2.1-2.6-10.8-3.9-11.1-1.1-.2-1.9 8.9-2.3 10.8z"
    />
    <path fill="#4D4D4D" d="M95.1 148.2h25v1.2h-25z" />
    <path fill="#EAC2A7" d="M107.4 143.1h-.3v1.2s6 .9 7.9.2c.2-.1.3-.1.5-.2-2.2-.7-5.1-1.1-8.1-1.2z" />
    <path
      fill="#F17C6F"
      d="M120.1 148.2c-.4-1.5-2.1-2.9-4.7-3.8-.1.1-.3.2-.5.2-1.9.7-7.9-.2-7.9-.2v-1.2H95v5.1l25.1-.1z"
    />
  </svg>
);

export default PregnantIcon;
