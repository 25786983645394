import React from 'react';

export const MagnifyingGlassNotePadIcon = (): React.ReactElement => (
  <svg width={76} height={76} aria-hidden="true">
    <defs>
      <filter x="-4.2%" y="-4.2%" width="108.3%" height="108.3%" filterUnits="objectBoundingBox" id="magnifyglass__a">
        <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation={1} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix
          values="0 0 0 0 0.949019608 0 0 0 0 0.949019608 0 0 0 0 0.949019608 0 0 0 0.5 0"
          in="shadowBlurOuter1"
        />
      </filter>
      <circle id="magnifyglass__b" cx={36} cy={36} r={36} />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(2 2)">
        <use fill="#000" filter="url(#magnifyglass__a)" xlinkHref="#magnifyglass__b" />
        <use fill="#FFF" xlinkHref="#magnifyglass__b" />
      </g>
      <path
        d="M15 61.96c0 .23 10.251.417 22.897.417 12.645 0 22.896-.187 22.896-.417 0-.23-10.251-.416-22.896-.416-12.646 0-22.897.186-22.897.416"
        fill="#E4E4E3"
      />
      <path
        d="M41.897 20.964H17.658A2.659 2.659 0 0015 23.624v32.782c0 1.47 1.19 2.66 2.658 2.66h24.239a2.66 2.66 0 002.66-2.66V23.625a2.66 2.66 0 00-2.66-2.66"
        fill="#C6906E"
      />
      <path d="M36.985 20.964s-.841 2.055-2.065 2.055H32.6v-2.055h4.385z" fill="#A05D2C" />
      <path fill="#ECEBEC" d="M17.976 57h23.492V22.409H17.976z" />
      <path d="M36.075 22.409c-.32.342-.83.61-1.272.61H32.6v-.61h3.475z" fill="#B1B1B1" />
      <path fill="#C6906E" d="M45.08 40.506l-6.269 3.917-1.048-17.005h8.588l1.307 5.427z" />
      <path fill="#ECEBEC" d="M44.65 40.499l-5.844 3.536-5.357-5.415V25.537l5.06-2.804 6.142 2.804z" />
      <path
        d="M29.777 18.408a1.325 1.325 0 11.002-2.65 1.325 1.325 0 01-.002 2.65m3.752-1.556c-.23 0-.454.027-.673.074a3.083 3.083 0 00-6.159 0 3.083 3.083 0 10-.67 6.093h7.502a3.084 3.084 0 000-6.167"
        fill="#CAC9C8"
      />
      <path
        fill="#4AC47D"
        d="M40.396 28.32l-5.613 5.612-2.58-2.58-2.885 2.884 2.58 2.581-.003.003 2.885 2.884.003-.002.004.002 2.885-2.884-.004-.003 5.612-5.613z"
      />
      <path
        fill="#EDBA28"
        d="M37.852 49.264l-1.446 1.445-1.446-1.445-1.35 1.35 1.446 1.445-1.445 1.445 1.35 1.35 1.445-1.446 1.446 1.446 1.349-1.35-1.446-1.445 1.446-1.446z"
      />
      <path
        fill="#EE5C4B"
        d="M36.406 43.722l-2.795-.097 1.445 1.446-1.445 1.446 1.35 1.349 1.445-1.446 1.446 1.446 1.349-1.35-1.446-1.445 1.446-1.446z"
      />
      <path
        fill="#CAC9C8"
        d="M26.461 28.31h-6.468v-2.255h7.776zm-6.468 3.985h5.603V30.04h-5.603zm0 3.985h5.603v-2.254h-5.603zm7.66 3.986h-7.66v-2.255h6.624zm2.217 3.986h-9.877v-2.255h9.73zm-9.877 3.301h9.877v-2.255h-9.877zm.073 5.633h9.73v-2.254h-9.73z"
      />
      <path fill="#666" d="M49.528 48.646l2.012-2.012-6.538-6.538-2.012 2.012z" />
      <path
        d="M59.829 56.867a2.647 2.647 0 000-3.744l-9.789-9.789a2.648 2.648 0 00-3.744 3.745l9.789 9.788a2.647 2.647 0 003.744 0"
        fill="#666"
      />
      <path
        d="M44.077 41.02l2.48 2.482c0 .27.102.54.308.746l12.05 12.05c.409.41 1.073.41 1.482 0l-1.7 1.7c-.408.41-1.072.41-1.482 0l-12.05-12.05a1.049 1.049 0 010-1.483l.091-.091-2.266-2.266 1.087-1.087z"
        fill="#4D4D4D"
      />
      <path
        d="M28.449 41.194c4.305 4.305 11.285 4.305 15.59 0 4.306-4.305 4.306-11.286 0-15.591-4.305-4.305-11.285-4.305-15.59 0s-4.305 11.286 0 15.59"
        fill="#9BD9ED"
      />
      <path
        d="M28.911 26.065c-4.043 4.044-4.043 10.623 0 14.667 4.043 4.043 10.623 4.043 14.666 0 4.044-4.044 4.044-10.623 0-14.667a10.337 10.337 0 00-7.333-3.032 10.34 10.34 0 00-7.333 3.032m-.925 15.591c-4.552-4.553-4.552-11.962 0-16.515 4.554-4.553 11.963-4.553 16.516 0 4.553 4.553 4.553 11.962 0 16.515a11.643 11.643 0 01-8.258 3.415c-2.99 0-5.98-1.138-8.258-3.415"
        fill="#666"
      />
      <path
        d="M43.802 40.499c3.817-4.06 3.742-10.467-.225-14.434a10.338 10.338 0 00-7.333-3.032c-2.555 0-5.11.936-7.1 2.807a10.339 10.339 0 017.558-3.265c2.656 0 5.311 1.01 7.333 3.033 4.043 4.043 4.043 10.622 0 14.666a9.86 9.86 0 01-.233.225"
        fill="#4D4D4D"
      />
      <path
        d="M27.987 25.14c.076-.076.154-.151.232-.225-4.327 4.57-4.251 11.806.225 16.283a11.641 11.641 0 008.258 3.415c2.89 0 5.78-1.063 8.026-3.19a11.643 11.643 0 01-8.483 3.647 11.638 11.638 0 01-8.258-3.414c-4.554-4.553-4.554-11.962 0-16.515"
        fill="#4D4D4D"
      />
      <path d="M39.57 24.768a9.212 9.212 0 00-12.194 10.724A18.113 18.113 0 0139.57 24.768" fill="#FFF" />
    </g>
  </svg>
);

export default MagnifyingGlassNotePadIcon;
