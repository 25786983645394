import {
  RDS_BREAKPOINTS_DESKTOP,
  RDS_BREAKPOINTS_TABLET_LANDSCAPE,
  RDS_BREAKPOINTS_TABLET_PORTRAIT,
  RDS_BREAKPOINTS_WIDESCREEN,
} from '@rally/ui-themes/dist/web/rally';
import { css } from 'styled-components';

export interface MediaTargets {
  mobile: string;
  tabletPortrait: string;
  tabletPortraitOnly: string;
  tabletBelow: string;
  tablet: string;
  tabletOnly: string;
  touch: string;
  desktop: string;
  desktopOnly: string;
  widescreen: string;
}

export const mediaTargets: MediaTargets = {
  mobile: `(max-width: ${parseInt(RDS_BREAKPOINTS_TABLET_PORTRAIT, 10) - 1}px)`,
  tabletPortrait: `(min-width: ${RDS_BREAKPOINTS_TABLET_PORTRAIT})`,
  tabletPortraitOnly: `(min-width: ${RDS_BREAKPOINTS_TABLET_PORTRAIT}) and (max-width: ${
    parseInt(RDS_BREAKPOINTS_TABLET_LANDSCAPE, 10) - 1
  }px)`,
  tabletBelow: `(max-width: ${parseInt(RDS_BREAKPOINTS_TABLET_LANDSCAPE, 10) - 1}px)`,
  tablet: `(min-width: ${RDS_BREAKPOINTS_TABLET_LANDSCAPE})`,
  tabletOnly: `(min-width: ${RDS_BREAKPOINTS_TABLET_LANDSCAPE}) and (max-width: ${
    parseInt(RDS_BREAKPOINTS_DESKTOP, 10) - 1
  }px)`,
  touch: `(max-width: ${parseInt(RDS_BREAKPOINTS_DESKTOP, 10) - 1}px)`,
  desktop: `(min-width: ${RDS_BREAKPOINTS_DESKTOP})`,
  desktopOnly: `(min-width: ${RDS_BREAKPOINTS_DESKTOP}) and (max-width: ${
    parseInt(RDS_BREAKPOINTS_WIDESCREEN, 10) - 1
  }px)`,
  widescreen: `(min-width: ${RDS_BREAKPOINTS_WIDESCREEN})`,
};

/* Generates a media query mixin with the following values:
  mobile: '(max-width: 539px)',
  tabletPortrait: '(min-width: 540px)',
  tabletPortraitOnly: '(min-width: 540px) and (max-width: 767px)',
  tabletBelow: '(max-width: 768px)',
  tablet: '(min-width: 768px)',
  tabletOnly: '(min-width: 768px) and (max-width: 1047px)',
  touch: '(max-width: 1047px)',
  desktop: '(min-width: 1048px)',
  desktopOnly: '(min-width: 1048px) and (max-width: 1335px)',
  widescreen: '(min-width: 1336px)'
*/
export const respondTo = Object.keys(mediaTargets).reduce(
  (accumulator, label) => {
    // eslint-disable-next-line
    accumulator[label] = (literals: TemplateStringsArray, ...placeholders: any[]) => `
      @media screen and (${mediaTargets[label]}) {
        ${css(literals, ...placeholders)};
      }
    `;
    return accumulator;
  },
  // eslint-disable-next-line
  {} as Record<keyof typeof mediaTargets, (l: TemplateStringsArray, ...p: any[]) => string>
);
