//We have a special case when we need to set the language cookie only to the root domain, where the cookie
//is shared between Accounts and Dev environments. To use, pass in a parameter with {rootOnly: true}
//If no params are passed in then, by default, it will return root domain or up to 3 levels of subdomains.
/* eslint-disable-next-line */
const maxThreeLevelRegex = /((?:\.|^)\w*(?:\-|)\w*(?:\.|^)werally\.in|(?:\.|^)werally\.com|(?:\.|^)engage\.rally)/i;
const rootRegex = /((?:\.|^)werally\.(?:in|com)|(?:\.|^)engage\.rally)/i;

/* eslint-disable-next-line */
function current(params): string {
  const regexToUse = params && params.rootOnly ? rootRegex : maxThreeLevelRegex;
  const tokens = window.location.host.match(regexToUse) || [];
  return tokens[0];
}

export const Domain = {
  current: current,
};
