import { stringToKebab } from '@health-activity-ui/utils';
import React, { PropsWithChildren } from 'react';
import { Container, Section, SectionProps } from '../layout';
export interface TabContentProps extends SectionProps {
  tabTitle: string;
}

export const TabContent = ({ children, tabTitle, ...allSectionProps }: PropsWithChildren<TabContentProps>) => {
  return (
    <Section {...allSectionProps}>
      <Container aria-live="polite" role="region">
        <div role="tabpanel" id={stringToKebab(tabTitle)} aria-labelledby={tabTitle} tabIndex={0}>
          {children}
        </div>
      </Container>
    </Section>
  );
};

export default TabContent;
