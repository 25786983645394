import React from 'react';

interface Props {
  strokeWidth?: string;
  strokeColor?: string;
}

export const CircleEmptyIcon = ({ strokeWidth = '1px', strokeColor = '#666666' }: Props): React.ReactElement => (
  <svg width={24} height={24} viewBox="0 0 24 24" className="circle-empty-svg">
    <circle cx="12" cy="12" r="11" fill="none" stroke={strokeColor} strokeWidth={strokeWidth} />
  </svg>
);

export default CircleEmptyIcon;
