import { RdsButton } from '@rally/energon-react/dist/es6';
import Section from '../layout/section/section';

interface TryAgainProps {
  refreshPage: () => void;
}

export const TryAgain = ({ refreshPage }: TryAgainProps) => (
  <main className="is-paddingless has-text-centered">
    <Section isFirst>
      <h1 className="is-rds-h2">
        We're sorry, something went wrong loading your experience. Please try again momentarily.
      </h1>
      <RdsButton variant="primary" onClick={refreshPage}>
        Try Again
      </RdsButton>
    </Section>
  </main>
);

export default TryAgain;
