import React from 'react';
import { ASSETS_PATH } from '@health-activity-ui/shared';

interface Props {
  style?: any /* eslint-disable-line */;
}

export const SinCityStrutIcon = ({ style }: Props): React.ReactElement => (
  <img
    src={`${ASSETS_PATH}/img/challengesV2/cityChallenges/SinCityStrut.png`}
    alt={'Sin City Strut Icon'}
    style={style}
  />
);

export default SinCityStrutIcon;
