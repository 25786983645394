import React from 'react';

export const HowItWorksIcon = (): React.ReactElement => (
  <svg width="48px" height="38px" viewBox="0 0 48 38" aria-hidden="true">
    <title>How it Works Icon</title>
    <defs>
      <polygon
        id="path-1"
        points="0.263453892 0.161282443 22.0074539 0.161282443 22.0074539 23.6298504 0.263453892 23.6298504"
      />
      <polygon
        id="path-3"
        points="0.0554155689 0.161282443 2.02485269 0.161282443 2.02485269 3.07080611 0.0554155689 3.07080611"
      />
    </defs>
    <g id="Tasks-/-Flows-/-UI" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="3.-Team-Battle-About/v4_Default" transform="translate(-237.000000, -817.000000)">
        <g id="Group-27" transform="translate(237.000000, 817.000000)">
          <path
            d="M18.9565796,26.5865405 C16.800891,28.762113 13.3049389,28.762113 11.1492503,26.5865405 C8.9938491,24.4109679 8.9938491,20.8827695 11.1501126,18.707487 C13.3055138,16.5319145 16.800891,16.5319145 18.9565796,18.707487 C21.1128431,20.8827695 21.1128431,24.4109679 18.9565796,26.5865405 L18.9565796,26.5865405 Z M27.130376,13.3518076 L24.263885,10.4585863 L20.813921,12.1123115 C20.0286754,11.6754565 19.2057772,11.3340366 18.3604599,11.0886321 L17.0802683,7.45513588 L13.0255617,7.45513588 L11.7453701,11.0886321 C10.900915,11.3340366 10.0774419,11.6754565 9.29190898,12.1123115 L5.84251976,10.457426 L2.97545389,13.3518076 L4.61521437,16.8327237 C4.18177725,17.624342 3.84405269,18.456571 3.60002874,19.3088153 L2.8742515e-05,20.6008153 L2.8742515e-05,24.6929221 L3.60002874,25.9852122 C3.84347784,26.8374565 4.18177725,27.6693954 4.61521437,28.4613038 L2.97602874,31.9422198 L5.84251976,34.8354412 L9.29248383,33.1802656 C10.0768671,33.618571 10.9003401,33.9599908 11.7453701,34.2053954 L13.0255617,37.8386015 L17.0802683,37.8386015 L18.3604599,34.2053954 C19.2057772,33.9599908 20.0286754,33.618571 20.813921,33.1802656 L24.2633102,34.8354412 L27.130376,31.9422198 L25.4906156,28.4613038 C25.924915,27.6682351 26.2623521,26.8374565 26.5058012,25.9852122 L30.1058012,24.6929221 L30.1058012,20.6008153 L26.5058012,19.3088153 C26.2632144,18.456571 25.9243401,17.624342 25.4906156,16.8327237 L27.130376,13.3518076 Z"
            id="Fill-1"
            fill="#989898"
          />
          <path
            d="M25.9561293,19.1116794 L26.5056862,19.3089313 C26.281782,18.6394351 25.8348359,17.4614351 25.490788,16.8328397 L27.1305485,13.3519237 L26.4160096,12.6307939 L24.7762491,16.1117099 C25.2096862,16.9036183 25.7135425,18.2594351 25.9561293,19.1116794"
            id="Fill-3"
            fill="#7F7F7F"
          />
          <path
            d="M17.9054084,10.9881206 C18.079588,11.038884 18.2523305,11.096029 18.4247856,11.1546244 L17.0802108,7.45499084 L16.6197557,7.45499084 L17.9054084,10.9881206 Z"
            id="Fill-5"
            fill="#7F7F7F"
          />
          <path
            d="M30.1058299,24.6928061 L30.1058299,20.6009893 L29.3915784,20.3445618 L29.3915784,23.9719664 L25.791291,25.2639664 C25.5481293,26.1162107 25.2106922,26.9469893 24.7761054,27.740058 L26.4158659,31.2212641 L23.5488,34.1141954 L20.0996982,32.4590198 C19.3144527,32.8976153 18.4912671,33.238745 17.6459497,33.4844397 L16.3657581,37.1176458 L12.7715066,37.1176458 L13.0255904,37.8384855 L17.080297,37.8384855 L18.3604886,34.2052794 C19.205806,33.9598748 20.0287042,33.618745 20.8139497,33.1804397 L24.2633389,34.8353252 L27.1304048,31.9421038 L25.4906443,28.4611878 C25.9249437,27.6681191 26.2623808,26.8376305 26.5058299,25.9850962 L30.1058299,24.6928061 Z"
            id="Fill-7"
            fill="#7F7F7F"
          />
          <path
            d="M11.5632575,33.6472305 C10.7182275,33.4015359 9.36273054,32.8976733 8.57805988,32.4590779 L5.12809581,34.1139634 L5.84263473,34.8353832 L9.2925988,33.1804977 C9.9154491,33.5282992 11.0953293,33.9753069 11.7587066,34.2015664 L11.5632575,33.6472305 Z"
            id="Fill-9"
            fill="#7F7F7F"
          />
          <path
            d="M2.88577725,25.2639084 L2.8742515e-05,24.2283359 L2.8742515e-05,24.6927481 L3.05075928,25.7880763 C2.9926994,25.6140305 2.93607665,25.4396947 2.88577725,25.2639084"
            id="Fill-11"
            fill="#989898"
          />
          <path
            d="M11.8644216,19.4283847 C13.899679,17.3746443 17.1283257,17.2606443 19.2969485,19.0852244 C19.1900263,18.9555603 19.0770683,18.8293771 18.9563497,18.707545 C16.8009485,16.5319725 13.3058587,16.5319725 11.1501701,18.707545 C8.99390659,20.8828275 8.99390659,24.411026 11.1495952,26.5865985 C11.2700263,26.7081405 11.3950563,26.8218504 11.5235353,26.9297588 C9.71591856,24.7411328 9.82887665,21.4821252 11.8644216,19.4283847"
            id="Fill-13"
            fill="#7F7F7F"
          />
          <g id="Group-17" transform="translate(25.868263, 0.128794)">
            <g id="Clip-16" />
            <path
              d="M14.2326036,15.0209649 C12.522424,16.7472092 9.74819641,16.7472092 8.03801677,15.0209649 C6.32783713,13.2950107 6.32783713,10.495484 8.03887904,8.76952977 C9.74905868,7.04357557 12.522424,7.04357557 14.2326036,8.76952977 C15.9433581,10.495484 15.9433581,13.2950107 14.2326036,15.0209649 M20.378903,10.2054076 L22.0074539,7.4134229 L20.4202922,4.64203359 L17.2031425,4.66204885 C16.3253461,3.91075115 15.3443641,3.35177405 14.3104958,2.97786565 L12.7227593,0.160934351 L9.54843593,0.160934351 L7.9618491,2.97757557 C6.92740599,3.35177405 5.9458491,3.91075115 5.06776527,4.66262901 L1.85090299,4.64261374 L0.264028743,7.41371298 L1.89229222,10.2059878 C1.69281916,11.3230718 1.69281916,12.467713 1.89200479,13.585087 L0.263453892,16.3767817 L1.85061557,19.1478809 L5.06517844,19.1275756 C5.94383713,19.8800336 6.86733413,20.3731634 7.9618491,20.8137893 L9.54843593,23.6298504 L12.7227593,23.6298504 L14.3093461,20.8132092 C15.3437892,20.4390107 16.3253461,19.8800336 17.2040048,19.1281557 L20.4197174,19.1484611 L22.0071665,16.3767817 L20.3791904,13.585087 C20.5780886,12.467713 20.5780886,11.3224916 20.378903,10.2054076"
              id="Fill-15"
              fill="#989898"
            />
          </g>
          <g id="Group-20" transform="translate(38.227545, 0.128794)">
            <g id="Clip-19" />
            <path
              d="M1.63079281,2.91822595 C1.76300838,2.96608855 1.8946491,3.01714198 2.02485269,3.07080611 L0.363535329,0.161050382 L0.0554155689,0.161050382 L1.63079281,2.91822595 Z"
              id="Fill-18"
              fill="#7F7F7F"
            />
          </g>
          <path
            d="M46.247109,10.3343176 L47.8756599,7.54233282 L46.2887856,4.77065344 L45.9884263,4.77239389 L47.1614084,6.82120305 L45.5325701,9.61318779 C45.7320431,10.7302718 45.7320431,11.8752031 45.5328575,12.9925771 L47.161121,15.7845618 L45.5736719,18.5562412 L42.3576719,18.5356458 C41.4793006,19.2878137 40.4977437,19.8467908 39.4630132,20.2209893 L37.8764263,23.0373405 L35.0105102,23.0373405 L35.4166419,23.7584702 L38.5909653,23.7584702 L40.1775521,20.941829 C41.2119952,20.5676305 42.1938395,20.0089435 43.0722108,19.2570656 L46.2882108,19.2770809 L47.8753725,16.5054015 L46.2473964,13.7137069 C46.4462946,12.5966229 46.4462946,11.4514015 46.247109,10.3343176"
            id="Fill-21"
            fill="#7F7F7F"
          />
          <path
            d="M33.5100072,20.3742366 C32.4744144,20.0000382 31.0976479,19.2879008 30.2192766,18.5351527 L27.3047856,18.5537176 L27.7189653,19.2765878 L30.9335281,19.2562824 C31.8843305,20.1079466 32.9397557,20.5743893 33.8299114,20.9424962 L33.5100072,20.3742366 Z"
            id="Fill-23"
            fill="#7F7F7F"
          />
          <path
            d="M34.6215377,9.6193374 C36.2107114,8.01550534 38.7170587,7.90440611 40.4361485,9.28139847 C40.3321006,9.14941374 40.2217293,9.02061985 40.1007234,8.89849771 C38.3905437,7.17225344 35.6174659,7.17225344 33.9072862,8.89849771 C32.1962443,10.6244519 32.1962443,13.4236885 33.906424,15.1496427 C34.0274299,15.272055 34.1550467,15.3831542 34.2858251,15.4878718 C32.9217054,13.7529252 33.0317892,11.2234595 34.6215377,9.6193374"
            id="Fill-25"
            fill="#7F7F7F"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default HowItWorksIcon;
