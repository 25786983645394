import { Status } from '@health-activity-ui/shared';
export type Feature =
  | 'advantage.wellness_missions'
  | 'advantage.wellness_challenges'
  | 'advantage.wellness_communities'
  | 'advantage.wellness_activity_dashboard';
export const Features = {
  Missions: 'advantage.wellness_missions' as Feature,
  Challenges: 'advantage.wellness_challenges' as Feature,
  Communities: 'advantage.wellness_communities' as Feature,
  ActivityDashboard: 'advantage.wellness_activity_dashboard' as Feature,
};

export type AuthorizedFeature =
  | 'canViewWellnessMissions'
  | 'canViewWellnessChallenges'
  | 'canViewWellnessCommunities'
  | 'canViewWellnessActivityDashboard';
export const AuthorizedFeatures = {
  Missions: 'canViewWellnessMissions' as AuthorizedFeature,
  Challenges: 'canViewWellnessChallenges' as AuthorizedFeature,
  Communities: 'canViewWellnessCommunities' as AuthorizedFeature,
  ActivityDashboard: 'canViewWellnessActivityDashboard' as AuthorizedFeature,
};

export interface AuthorizationState {
  canViewWellnessMissions: boolean;
  canViewWellnessChallenges: boolean;
  canViewWellnessCommunities: boolean;
  canViewWellnessActivityDashboard: boolean;
  status: Status;
}
