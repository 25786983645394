import React from 'react';

export const StarFlagIcon = (): React.ReactElement => (
  <svg width={76} height={76} aria-hidden="true">
    <defs>
      <path id="starflag__c" d="M0 .072h2.49v46.64H0z" />
      <path id="starflag__e" d="M0 0h23.083v20.706H0z" />
      <filter x="-4.2%" y="-4.2%" width="108.3%" height="108.3%" filterUnits="objectBoundingBox" id="starflag__a">
        <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation={1} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix
          values="0 0 0 0 0.980392157 0 0 0 0 0.980392157 0 0 0 0 0.980392157 0 0 0 0.5 0"
          in="shadowBlurOuter1"
        />
      </filter>
      <circle id="starflag__b" cx={36} cy={36} r={36} />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(2 2)">
        <use fill="#000" filter="url(#starflag__a)" xlinkHref="#starflag__b" />
        <use fill="#FFF" xlinkHref="#starflag__b" />
      </g>
      <path fill="#FDD054" fillRule="nonzero" d="M40.825 34.084l20.08-5.57-19.803-4.979z" />
      <g transform="translate(21 14.048)">
        <mask id="starflag__d" fill="#fff">
          <use xlinkHref="#starflag__c" />
        </mask>
        <path
          d="M.975 45.133c.54 0 .976-.542.976-1.21V1.282c0-.668-.437-1.21-.976-1.21C.437.072 0 .614 0 1.282v42.64c0 .67.437 1.21.975 1.21"
          fill="#DA803D"
          fillRule="nonzero"
          mask="url(#starflag__d)"
        />
      </g>
      <path fill="#DBAB25" fillRule="nonzero" d="M44.083 30.494l-3.258-1.179v4.769z" />
      <g transform="translate(21 15.59)">
        <mask id="starflag__f" fill="#fff">
          <use xlinkHref="#starflag__e" />
        </mask>
        <path fill="#FDD054" fillRule="nonzero" mask="url(#starflag__f)" d="M23.083 14.903L0 20.706V0l23.083 4.947z" />
      </g>
      <path fill="#DA803D" fillRule="nonzero" d="M21 36.297l1.951 2.48v-2.48z" />
      <path fill="#FDD054" fillRule="nonzero" d="M21.001 14.736v21.56l23.081-5.802v-9.956z" />
      <path
        d="M34.728 24.357h-2.762l-.853-2.64a.28.28 0 00-.533 0l-.854 2.64h-2.761c-.272 0-.385.35-.165.51l2.234 1.632-.853 2.642c-.084.26.211.475.43.315l2.235-1.633 2.234 1.633c.22.16.516-.055.432-.315l-.854-2.642 2.235-1.632c.22-.16.106-.51-.165-.51"
        fill="#FC9447"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default StarFlagIcon;
