import React from 'react';

export const GameRulesIcon = (): React.ReactElement => (
  <svg width="48px" height="48px" viewBox="0 0 32 48" aria-hidden="true">
    <title>Game Rules Icon</title>
    <defs>
      <polygon id="path-1" points="0 0.0888 31.8203796 0.0888 31.8203796 40.6153385 0 40.6153385" />
      <polygon id="path-3" points="0 47.934 31.8203796 47.934 31.8203796 0 0 0" />
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="3.-Team-Battle-About/v4_Default" transform="translate(-245.000000, -893.000000)">
        <g id="Group-21" transform="translate(245.000000, 893.000000)">
          <g id="Group-3" transform="translate(0.000000, 7.318615)">
            <g id="Clip-2" />
            <path
              d="M28.9562161,0.0888 L2.86220146,0.0888 C1.28205547,0.0888 -4.67153285e-05,1.35410769 -4.67153285e-05,2.91872308 L-4.67153285e-05,37.7856462 C-4.67153285e-05,39.3498 1.28205547,40.6153385 2.86220146,40.6153385 L28.9562161,40.6153385 C30.5377635,40.6153385 31.8205664,39.3498 31.8205664,37.7856462 L31.8205664,2.91872308 C31.8193985,1.35410769 30.5377635,0.0888 28.9562161,0.0888"
              id="Fill-1"
              fill="#C6906E"
            />
          </g>
          <path
            d="M23.6690453,7.40741538 C23.6690453,7.40741538 22.7627679,9.59326154 21.4453956,9.59326154 L18.9479942,9.59326154 L18.9479942,7.40741538 L23.6690453,7.40741538 Z"
            id="Fill-4"
            fill="#A05D2C"
          />
          <g id="Clip-7" />
          <polygon
            id="Fill-6"
            fill="#ECEBEC"
            points="1.78475912 45.7368462 29.9146277 45.7368462 29.9146277 8.94346154 1.78475912 8.94346154"
          />
          <path
            d="M6.58405839,19.0943077 L11.8631241,19.0943077 L11.8631241,13.8786923 L6.58405839,13.8786923 L6.58405839,19.0943077 Z M4.88338686,20.7747692 L13.563562,20.7747692 L13.563562,12.1986923 L4.88338686,12.1986923 L4.88338686,20.7747692 Z"
            id="Fill-8"
            fill="#B1B1B1"
            mask="url(#mask-4)"
          />
          <path
            d="M22.6888876,8.94346154 C22.3434277,9.30784615 21.7949898,9.59307692 21.3191942,9.59307692 L18.9479241,9.59307692 L18.9479241,8.94323077 L22.6888876,8.94346154 Z"
            id="Fill-9"
            fill="#B1B1B1"
            mask="url(#mask-4)"
          />
          <path
            d="M15.9092321,4.68865385 C15.1218453,4.68865385 14.4832467,4.05703846 14.4832467,3.27980769 C14.4832467,2.50073077 15.1218453,1.86888462 15.9092321,1.86888462 C16.6977869,1.86888462 17.3361518,2.50073077 17.3361518,3.27980769 C17.3361518,4.05703846 16.6977869,4.68865385 15.9092321,4.68865385 M19.9480058,3.03357692 C19.6999474,3.03357692 19.4586628,3.06173077 19.2236847,3.11226923 C19.1367942,1.3785 17.6874511,0.000115384615 15.9080642,0.000115384615 C14.1319474,0.000115384615 12.6826044,1.3785 12.5936117,3.11226923 C12.3607358,3.06173077 12.1215533,3.03357692 11.8713927,3.03357692 C10.0366482,3.03357692 8.55156788,4.50288462 8.55156788,6.3135 C8.55156788,8.12388462 10.0366482,9.59319231 11.8713927,9.59319231 L19.9480058,9.59319231 C21.7806482,9.59319231 23.2668964,8.12388462 23.2668964,6.3135 C23.2668964,4.50288462 21.7806482,3.03357692 19.9480058,3.03357692"
            id="Fill-10"
            fill="#CAC9C8"
            mask="url(#mask-4)"
          />
          <polygon
            id="Fill-11"
            fill="#000000"
            mask="url(#mask-4)"
            points="14.0951358 11.8037308 8.9088 16.9277308 6.52468321 14.5718077 3.85910657 17.2053462 6.24345693 19.5610385 6.24018686 19.5635769 8.9057635 22.1971154 8.9088 22.1945769 8.91230365 22.1971154 11.5781139 19.5635769 11.5743766 19.5610385 16.7607124 14.4372692"
          />
          <polygon
            id="Fill-12"
            fill="#4AC47D"
            mask="url(#mask-4)"
            points="14.0951358 10.8937385 8.9088 16.0175077 6.52468321 13.6618154 3.85910657 16.2951231 6.24345693 18.6510462 6.24018686 18.6535846 8.9057635 21.2868923 8.9088 21.2845846 8.91230365 21.2868923 11.5781139 18.6535846 11.5743766 18.6510462 16.7607124 13.5270462"
          />
          <polygon
            id="Fill-13"
            fill="#B1B1B1"
            mask="url(#mask-4)"
            points="16.8588613 15.0242308 27.6295474 15.0242308 27.6295474 12.453 16.8588613 12.453"
          />
          <polygon
            id="Fill-14"
            fill="#B1B1B1"
            mask="url(#mask-4)"
            points="16.8588613 19.0576154 26.5256642 19.0576154 26.5256642 16.4863846 16.8588613 16.4863846"
          />
          <path
            d="M6.58405839,29.9478462 L11.8631241,29.9478462 L11.8631241,24.7322308 L6.58405839,24.7322308 L6.58405839,29.9478462 Z M4.88338686,31.6280769 L13.563562,31.6280769 L13.563562,23.052 L4.88338686,23.052 L4.88338686,31.6280769 Z"
            id="Fill-15"
            fill="#B1B1B1"
            mask="url(#mask-4)"
          />
          <polygon
            id="Fill-16"
            fill="#B1B1B1"
            mask="url(#mask-4)"
            points="16.8588613 25.8777692 27.6295474 25.8777692 27.6295474 23.3065385 16.8588613 23.3065385"
          />
          <polygon
            id="Fill-17"
            fill="#B1B1B1"
            mask="url(#mask-4)"
            points="16.8588613 29.9109231 25.1606423 29.9109231 25.1606423 27.3399231 16.8588613 27.3399231"
          />
          <path
            d="M6.58405839,40.8013846 L11.8631241,40.8013846 L11.8631241,35.5855385 L6.58405839,35.5855385 L6.58405839,40.8013846 Z M4.88338686,42.4813846 L13.563562,42.4813846 L13.563562,33.9053077 L4.88338686,33.9053077 L4.88338686,42.4813846 Z"
            id="Fill-18"
            fill="#B1B1B1"
            mask="url(#mask-4)"
          />
          <polygon
            id="Fill-19"
            fill="#B1B1B1"
            mask="url(#mask-4)"
            points="16.8588613 36.7310769 27.6295474 36.7310769 27.6295474 34.1598462 16.8588613 34.1598462"
          />
          <polygon
            id="Fill-20"
            fill="#B1B1B1"
            mask="url(#mask-4)"
            points="16.8588613 40.7646923 26.5256642 40.7646923 26.5256642 38.1934615 16.8588613 38.1934615"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default GameRulesIcon;
