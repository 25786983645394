import { useStorageState } from './use-storage.hook';
import { useProfile } from '@health-activity-ui/data-access';

export function useGinaConsent() {
  const { state } = useProfile();
  const [hasConsentedToGINA, setHasConsentedToGINA] = useStorageState(
    `user_${state?.data?.rallyId}_GINAConsent`,
    false
  );
  const REQUIRES_GINA = 'requiresGINA';

  return [hasConsentedToGINA, setHasConsentedToGINA, REQUIRES_GINA];
}
