import React from 'react';

interface Props {
  width?: number;
  height?: number;
}

export const HypertensionIcon = ({ width = 200, height = 165 }: Props): React.ReactElement => (
  <svg viewBox="0 0 200 165" width={width} height={height}>
    <path
      fill="#E5E5E4"
      d="M163.4 119.4l-48.2-83.5c-7-12.2-18.5-12.2-25.5 0l-48.2 83.5c-7 12.2-1.3 22.1 12.8 22.1h96.4c14 0 19.7-9.9 12.7-22.1z"
    />
    <path
      fill="#FFC82B"
      d="M158.5 115.5L110.3 32c-7-12.2-18.5-12.2-25.5 0l-48.2 83.5c-7 12.2-1.3 22.1 12.8 22.1h96.4c14 0 19.8-9.9 12.7-22.1z"
    />
    <path
      fill="#DDAD25"
      d="M125.5 91.1l-23.3-46.6-23.3 46.6c-1.8 3.5-2.8 7.5-2.8 11.7 0 14.4 11.7 26 26 26 14.4 0 26-11.7 26-26 .2-4.3-.8-8.2-2.6-11.7z"
    />
    <path
      fill="#CF4F41"
      d="M122.7 89.4L99.4 42.6l-.1.2L114 81.5c1.4 3.7 2 7.7 1.6 11.9-1.4 14.3-14.2 24.8-28.6 23.4-3.9-.4-7.5-1.6-10.6-3.5 4.4 8.2 13.1 13.8 23 13.8 14.4 0 26.1-11.7 26.1-26.1 0-4.2-1-8.1-2.8-11.6z"
    />
    <path
      fill="#EF5B4B"
      d="M115.6 93.4c.4-4.2-.2-8.2-1.6-11.9L99.3 42.8 76 89.4c-1.8 3.5-2.8 7.5-2.8 11.7 0 4.4 1.1 8.6 3.1 12.3 3.2 1.9 6.8 3.1 10.6 3.5 14.5 1.3 27.3-9.1 28.7-23.5z"
    />
    <path
      fill="#CF4F41"
      d="M113.5 90.5c.6-1 .9-1.8.6-2-.2-.2-.9 0-1.9.6.4.4.9.9 1.3 1.4zM108 92c-4.4 3.3-9.7 7.9-10.3 8.5-1.1 1.1-1.2 3.2 0 4.4 1.2 1.2 3.4 1.1 4.4 0 .6-.6 5-5.7 8.4-10.1-.7-1-1.6-2-2.5-2.8z"
    />
    <path
      fill="#FFF"
      d="M99.5 83.8c-10 0-18.1 8.1-18.1 18.1 0 1.4 1.1 2.5 2.5 2.5s2.5-1.1 2.5-2.5c0-7.2 5.9-13.1 13.1-13.1 3.2 0 6.2 1.2 8.5 3.1 1.6-1.2 3.1-2.3 4.2-2.9-3.3-3.2-7.8-5.2-12.7-5.2zm14 6.7c-.7 1.2-1.8 2.7-3 4.4 1.3 2 2.1 4.5 2.1 7.1 0 1.4 1.1 2.5 2.5 2.5s2.5-1.1 2.5-2.5c-.1-4.4-1.6-8.4-4.1-11.5z"
    />
    <path
      fill="#CCC"
      d="M112.2 89.1c-1.1.6-2.6 1.7-4.2 2.9 1 .8 1.8 1.8 2.5 2.9 1.2-1.6 2.3-3.2 3-4.4-.4-.5-.9-1-1.3-1.4z"
    />
    <path
      fill="#FFF"
      d="M98.2 102.5c-1.2-1.2-1.1-3.4 0-4.4 1.1-1.1 15.2-13.2 16.4-12 1.2 1.2-10.9 15.4-12 16.4-1 1.1-3.2 1.3-4.4 0z"
    />
  </svg>
);

export default HypertensionIcon;
