import { PaddingSizes } from '@health-activity-ui/shared';
import { RDS_BORDER_RADIUS_ROUNDED } from '@rally/ui-themes/dist/web/rally';
import { CSSObject } from 'styled-components';
import { LineAxis, LineStyle } from '../progress-bar.models';

export const cssBorderRadius = (
  lineDirection: LineAxis = LineAxis.FromLeft,
  lineStyle: LineStyle = LineStyle.RoundedStartEnd
): CSSObject => {
  switch (true) {
    case lineStyle === LineStyle.Square:
      return {
        borderRadius: 0,
      };

    case lineStyle === LineStyle.RoundedStartEnd:
      return { borderRadius: RDS_BORDER_RADIUS_ROUNDED };

    case lineStyle === LineStyle.RoundEnd && lineDirection === LineAxis.FromBottom:
      return {
        borderRadius: `${RDS_BORDER_RADIUS_ROUNDED} ${RDS_BORDER_RADIUS_ROUNDED} 0 0`,
      };

    case lineStyle === LineStyle.RoundEnd && lineDirection === LineAxis.FromTop:
      return {
        borderRadius: `0 0 ${RDS_BORDER_RADIUS_ROUNDED} ${RDS_BORDER_RADIUS_ROUNDED}`,
      };

    case lineStyle === LineStyle.RoundEnd && lineDirection === LineAxis.FromLeft:
      return {
        borderRadius: `0 ${RDS_BORDER_RADIUS_ROUNDED} ${RDS_BORDER_RADIUS_ROUNDED} 0`,
      };

    case lineStyle === LineStyle.RoundEnd && lineDirection === LineAxis.FromRight:
      return {
        borderRadius: `${RDS_BORDER_RADIUS_ROUNDED} 0 0 ${RDS_BORDER_RADIUS_ROUNDED}`,
      };
  }
};

export const barGaugePadding = (lineDirection: LineAxis, lineStyle: LineStyle, padding: PaddingSizes): CSSObject => {
  const paddingInPx = padding === 'none' ? '0' : `${padding}px`;
  switch (true) {
    case [LineStyle.Square, LineStyle.RoundEnd].includes(lineStyle) &&
      [LineAxis.FromLeft, LineAxis.FromRight].includes(lineDirection):
      return {
        padding: `${paddingInPx} 0 ${paddingInPx} 0`,
      };
    case [LineStyle.Square, LineStyle.RoundEnd].includes(lineStyle) &&
      [LineAxis.FromTop, LineAxis.FromBottom].includes(lineDirection):
      return {
        padding: `0 ${paddingInPx} 0 ${paddingInPx}`,
      };
    default:
      return {
        padding: paddingInPx,
      };
  }
};

export const barGaugeAttributes = (
  lineDirection: LineAxis = LineAxis.FromLeft,
  length: number | '100%' = '100%'
): CSSObject => {
  const lengthWithUnits = length === '100%' ? '100%' : `${length}px`;

  switch (lineDirection) {
    case LineAxis.FromBottom:
      return {
        flexDirection: 'column',
        height: lengthWithUnits,
        justifyContent: 'flex-end',
      };

    case LineAxis.FromTop:
      return {
        flexDirection: 'column',
        height: lengthWithUnits,
        justifyContent: 'flex-start',
      };

    case LineAxis.FromLeft:
      return {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        width: lengthWithUnits,
      };

    case LineAxis.FromRight:
      return {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        width: lengthWithUnits,
      };
  }
};
