import { PropsWithChildren } from '@health-activity-ui/shared';

export interface AvatarGroupProps extends PropsWithChildren {
  avatars: string[];
  avatarSize?: AvatarSize;
}

export enum AvatarSize {
  Base = 'avatar',
  Xs = 'avatar-xs',
  Sm = 'avatar-sm',
  Lg = 'avatar-lg',
  Xl = 'avatar-xl',
  Xxl = 'avatar-xxl',
}
