import styled, { css } from 'styled-components';
import { BarValueProps, LineAxis } from '../progress-bar.models';
import { cssBorderRadius } from './progress-bar-helpers';

const ProgressBarValue = styled.div<BarValueProps>`
  background-color: ${({ color }) => color};
  display: inline-block;

  ${({ lineAxis, lineStyle }) => cssBorderRadius(lineAxis, lineStyle)};
  ${({ lineAxis, percent, thickness }) =>
    [LineAxis.FromLeft, LineAxis.FromRight].includes(lineAxis)
      ? css`
          height: ${() => thickness}px;
          width: ${() => percent}%;
        `
      : css`
          height: ${() => percent}%;
          width: ${() => thickness}px;
        `}
`;

export default ProgressBarValue;
