import React from 'react';
import { ordinalSuffix } from '@health-activity-ui/utils';

interface PositionStatProps {
  rank: number;
  className?: string;
}

export const PositionStat = ({ rank, className = '' }: PositionStatProps): React.ReactElement => (
  <span className={`position ${className}`}>{ordinalSuffix(rank)}</span>
);

export default PositionStat;
