import { getUserLocale } from '@health-activity-ui/utils';
import { LOCALE } from '@health-activity-ui/shared';
import Cookies from 'js-cookie';
import { Domain } from './domain';
import { tokenizeLocale } from './i18n-helpers';

class LocaleService {
  public locale = 'en-US';
  public language: string;
  public country: string;

  constructor() {
    this.init();
    this.setLocale(this.locale);
  }

  private init(): void {
    // First, look if the user has preferred locales, and if so, select the highest preference.
    // This is necessary because Chrome does not set the navigator locale to the user's preference.
    // If no preferred locales, then select the locale or user locale from the navigator object.
    const localeCookie = Cookies.get(LOCALE.COOKIE) || Cookies.get(LOCALE.USER_LOCALE);

    //We will remove any old cookies, since the get will always grab the most specific cookie
    //This prevents the old cookie from overriding the new one.
    //Fixes issue https://jira.audaxhealth.com/browse/NINJA-3690
    try {
      const host = window.location.host.split('.');
      host.push('');

      for (let i = host.length - 1, str = ''; i >= 0; --i) {
        str = host[i] + (str.length ? '.' : '') + str;
        Cookies.remove(LOCALE.USER_LOCALE, { path: '', domain: str });
      }
    } catch (e) {
      console.error(e);
    }

    // If there is no locale cookie, make another cookie to show this.
    // This cookie is deleted upon logging in or changing the language
    // It will solve issues regarding clearing the locale cookie and logging in on different browsers
    // Sets the cookie only to the root domain
    if (!localeCookie) {
      const domain = Domain.current({ rootOnly: true });
      Cookies.set('initialLocale', 'true', { path: '/', domain });
    }

    // Sets locale value
    if (localeCookie) {
      this.locale = localeCookie;
      // the below two conditions account for finding language settings in different browsers
    } else if (window.navigator) {
      this.locale = getUserLocale();
    } else {
      this.locale = 'en-US';
    }
  }

  /**
   * @method setLocale
   *
   * @description Set the currently stored locale and updates the corresponding language and country fields
   *
   * @param {Object|String} localeObjOrString
   * @param {String} localeObjOrString.locale - a locale string
   */
  setLocale(localeObjOrString: { locale: string } | string): string {
    const languageCountry = tokenizeLocale(
      typeof localeObjOrString === 'string' ? localeObjOrString : localeObjOrString.locale
    );

    this.language = languageCountry.language;
    this.country = languageCountry.country;
    this.locale = this.language + '-' + this.country;

    // Sets the cookie only to the root domain
    const domain = Domain.current({ rootOnly: true });
    Cookies.set(LOCALE.COOKIE, this.locale, { path: '/', domain });
    return this.locale;
  }

  /**
   * @method getLocale
   *
   * @description Gets the current locale string
   *
   * @returns {String} the current locale string
   */
  getLocale(): string {
    return this.locale;
  }
}
export const LocalizationService = new LocaleService();
