import { appConfig } from '../configs/app.config';
import { CookieAttributes } from 'js-cookie';

/**
 * Main Session Cookies:
 *
 * RS ("Rally Session"): This is the main cookie, that contains RallyId, SessionId, eligible products for the user
 * (audience), and information about expiration. The RS cookie is a JWT, signed by AuthN using RS256.
 * ---
 * RH ("Rally Heartbeat"): This cookie simply exists as a rolling timestamp. It serves to inform the services about
 * the most recent moment in time when AuthN refreshed the session. The RH cookie is a JWT, signed by AuthN using RS256.
 * ---
 * FS ("Frontend Session"): This cookie acts as a "shadow" of the RS cookie. It simply contains the SessionId, and
 * is readable by frontend javascript, so the javascript can detect if the session changes. This cookie does not contain
 * a JWT-- it simply contains the sessionId.
 * ---
 * FH ("Frontend Heartbeat): Similar to the FS cookies, this acts as a "shadow" of the RH cookie, so that our
 * javascript layer can make informed decisions about how often to attempt to refresh ("heartbeat") the session.
 * This cookie is not a JWT either, it's an exclamation-point-delimited string containing some information
 * about session expiration. The structure is as follows: SessionId!unixTimestampOfLastHeartbeat(in UTC seconds)!maxAllowedInactivityInSeconds.
 *
 * ------
 *
 * Session Context Cookies:
 * UE ("User Experience"): This cookie provides information to our services about the kind of experience the user is using across
 * our services-- notably, it contains a flag that informs if the user is an Advantage user or not.
 *
 * Source: https://wiki.audaxhealth.com/display/ENG/Arachne+Session+Management
 * */
export const SESSION = {
  COOKIE: {
    AMPLITUDE: {
      DEVICE_ID: 'amplitudeDeviceId',
      SESSION_ID: 'amplitudeSessionId',
    },
    FS: 'FS',
    FH: 'FH_AD',
    UE: 'UE',
    OS: 'OS_AD',
    RS: 'RS_AD',
    RH: 'RH',
    XSRF_FH_AD: 'XSRF_FH_AD',
  },
  RALLY_ID: 'rallyId',
  SESSION_ID: 'sessionId',
  IDLE_TIMEOUT: 30, // seconds,
  IDLE_TIMEOUT_LOCAL: 60000 * 25,
  RP_TOKEN: 'AD',
  RP_CLIENT_ID: 'advantage_web_rp_client',
};

export const IDLE_TIMEOUT_MS = SESSION.IDLE_TIMEOUT * 1000;

export const cookieDefaultOptions: CookieAttributes = {
  domain: `.${appConfig?.HAUI_APP_HA_BASE_DOMAIN}`,
};
