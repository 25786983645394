export enum ConnectionStatus {
  initial = '',
  pendingOutgoing = 'pendingOutgoing',
  pendingIncoming = 'pendingIncoming',
  accepted = 'accepted',
}

export interface IAllCommunitiesRes {
  all: ICommunityItem[];
  recommended: ICommunityItem[];
}

export interface ICommunityItem {
  communityId: string;
  description: string;
  displayName: string;
  imgUrl: string;
  joined: boolean;
  recommended: boolean;
  isPrivate: boolean;
}

export interface ICommunity {
  id: string;
  name: string;
  title: string;
  description: string;
  img: string;
  isPrivate: boolean;
  hero?: string;
  logoUrl?: string;
  joined: boolean;
  numFollowers: number;
  numDiscussions: number;
  topContributor?: IContributorItem;
  advancedStats?: IAdvancedStats;
  moderators: string[];
}

export interface IContributorItem {
  userId: string;
  userTitle?: string;
  displayName: string;
  gender: string;
  location: string;
  age: string;
  isExpert: boolean;
}

export interface IAdvancedStats {
  topContributors: IContributorItem[];
  topExperts: IContributorItem[];
  topDiscussions: IDiscussionItem[];
  topDiscussion: IDiscussionItem[];
}

export interface IDiscussionItem {
  id: string;
  category?: string;
  dTitle?: string;
  communityId: string;
  description: string;
  dislikeCount: number;
  displayName: string;
  edited?: boolean;
  featured?: boolean;
  flags: IDiscussionFlag[];
  following: string[];
  lastmodifiedTS: number;
  likeCount: number;
  popularity: number;
  replies: IDiscussionReplyItem[];
  replyCount: number;
  typeOf: string;
  userId: string;
  ownerTitle?: string;
  articleDescription?: string;
  articleSource?: string;
  articleUrl?: string;
  title?: string;
  image?: string;
  votes: IDiscussionVote[];
  deleted: boolean;
  isPrivate: boolean;
}

export interface IDiscussionReplyItem {
  id: string;
  body: string;
  createdDate: number;
  deleted: boolean;
  dislikeCount: number;
  displayName: string;
  likeCount: number;
  popularity: number;
  userId: string;
  ownerTitle?: string;
  flags: IDiscussionFlag[];
  votes: IDiscussionVote[];
  discussionId: string;
}

export interface IDiscussionFlag {
  timestamp: number;
  userId: string;
}

export interface IDiscussionVote {
  timestamp: number;
  userId: string;
  value: number;
}

export interface CreateDiscussionArgs {
  communityId: string;
  title: string;
  discussionText: string;
}

export interface LikeResponse {
  success: boolean;
}

export interface IDiscussionReply {
  authorId: string;
  body: string;
  discussionId: string;
  replyId: string;
}

export interface Connection {
  avatar: string;
  displayName: string;
  groups: [];
  incomingRequest: boolean;
  isCoach: boolean;
  isConnectable: boolean;
  status: string;
  userId: string;
}

export interface ConnectionMeta {
  connectionStatus: ConnectionStatus;
  isDisconnect?: boolean;
  userId?: string;
}

export interface ButtonGroupHandlers {
  onAccept: () => Promise<boolean>;
  onDeny: () => Promise<boolean>;
  onCancelRequest: () => Promise<boolean>;
  onConnect: () => Promise<boolean>;
  onDisconnect: () => Promise<boolean>;
  onUpdateUserConnectionStatus: (userId: string, connectionStatus: ConnectionStatus) => void;
}

export interface ConnectionButton {
  onUpdateConnectionStatus: (connectionStatus: ConnectionStatus) => void;
}
