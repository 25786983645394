import styled from '@emotion/styled';
import { RDS_COLOR_NEUTRAL_90 } from '@rally/ui-themes/dist/web/rally';
import { BarGaugeProps } from './../progress-bar.models';
import { barGaugeAttributes, barGaugePadding, cssBorderRadius } from './progress-bar-helpers';

const ProgressBarGauge = styled.div<BarGaugeProps>`
  background-color: ${RDS_COLOR_NEUTRAL_90};
  display: inline-flex;
  ${({ lineAxis, length }) => barGaugeAttributes(lineAxis, length)};
  ${({ lineAxis, lineStyle }) => cssBorderRadius(lineAxis, lineStyle)};
  ${({ lineAxis, lineStyle, padding }) => barGaugePadding(lineAxis, lineStyle, padding)};
`;

export default ProgressBarGauge;
