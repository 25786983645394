import { PropsWithChildren } from '@health-activity-ui/shared';
import React from 'react';

interface Props extends PropsWithChildren {
  normalColor: string;
  teamName: string;
  height?: string;
  width?: string;
}

// CONVERT TO RDS - TODO: https://jira.rallyhealth.com/browse/WHAT-1084
export const TeamBadgeIcon = ({
  normalColor,
  teamName,
  height = '88px',
  width = '82px',
}: Props): React.ReactElement => {
  const BadgeColorSets = [
    { normalColor: '#007797', brightColor: '#00B2E2', darkColor: '#004E62' },
    { normalColor: '#289385', brightColor: '#36C8B5', darkColor: '#17574E' },
    { normalColor: '#AF4337', brightColor: '#EF5B4B', darkColor: '#672720' },
    { normalColor: '#B14F25', brightColor: '#F26C32', darkColor: '#743418' },
    { normalColor: '#BA6E33', brightColor: '#FE9646', darkColor: '#6C3F1D' },
    { normalColor: '#BB9320', brightColor: '#FFC82B', darkColor: '#655012' },
    { normalColor: '#36905C', brightColor: '#49C57E', darkColor: '#205636' },
    { normalColor: '#666767', brightColor: '#7F8180', darkColor: '#333333' },
  ];
  const correspondingBadgeColors = BadgeColorSets.filter((colorSet) => colorSet.normalColor === normalColor);
  if (correspondingBadgeColors.length === 0) {
    correspondingBadgeColors.push(BadgeColorSets[0]);
  }
  const darkerColor = correspondingBadgeColors[0].darkColor;
  const darkColor = correspondingBadgeColors[0].brightColor;
  normalColor = correspondingBadgeColors[0].normalColor;
  teamName = teamName?.slice(0, 1);

  return (
    <svg width={width} height={height} viewBox="0 0 82 88" aria-hidden="true" data-testid="team-badge-icon">
      {/* TODO translate*/}
      <title id={'team-desc'}>Team: </title>
      <defs>
        <polygon id="path-1" points="0 0.366285885 81.9995684 0.366285885 81.9995684 87.0807955 0 87.0807955" />
        <polygon id="path-3" points="0 0.0108851674 42.2364737 0.0108851674 42.2364737 87.0813397 0 87.0813397" />
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-335.000000, -347.000000)">
          <g id="Group-10-Copy-3" transform="translate(335.000000, 347.000000)">
            <g id="Group-3" transform="translate(0.000000, 0.044719)">
              <g id="Clip-2" />
              <path
                d="M3.84277895,20.1223206 L38.5287789,1.04062201 C40.1364105,0.141507177 42.3655158,0.141507177 43.9731474,1.04062201 L78.6828842,20.1223206 C80.3789895,21.0715072 81.9995684,22.8740909 81.9995684,24.829067 L81.9995684,62.6180144 C81.9995684,64.5729904 80.5149368,66.3755742 78.8188316,67.3247608 L44.4629895,86.4064593 C42.8553579,87.3055742 40.7924105,87.3055742 39.1847789,86.4064593 L3.89672632,67.3247608 C2.20062105,66.3755742 -0.000431578947,64.5729904 -0.000431578947,62.6180144 L-0.000431578947,24.829067 C-0.000431578947,22.8740909 2.14667368,21.0715072 3.84277895,20.1223206"
                id="Fill-1"
                fill={normalColor}
              />
            </g>
            <g id="Group-6" transform="translate(0.000000, 0.044719)">
              <g id="Clip-5" />
              <path
                d="M41,1.01449761 L42.2364737,1.01449761 C41.1596842,-1.16253589 40.0548421,0.798971292 39.0751579,1.3454067 L3.84321053,20.2747129 C2.14710526,21.2238995 -2.13162821e-14,22.8740909 -2.13162821e-14,24.829067 L-2.13162821e-14,62.6180144 C-2.13162821e-14,64.5729904 2.14710526,66.3755742 3.84321053,67.3247608 L38.1990526,86.4064593 C39.0017895,86.8571053 41,87.0813397 41,87.0813397 L41,1.01449761 Z"
                id="Fill-4"
                fill={darkColor}
              />
            </g>

            {teamName && (
              <path
                d="M60.0037158,43.7682592 C60.0037158,53.9872544 51.7907684,62.2730439 41.6616105,62.2730439 C31.5302947,62.2730439 23.3195053,53.9872544 23.3195053,43.7682592 C23.3195053,33.549264 31.5302947,25.2634745 41.6616105,25.2634745 C51.7907684,25.2634745 60.0037158,33.549264 60.0037158,43.7682592"
                id="Fill-7"
                fill={darkerColor}
              />
            )}
            <text
              id="H"
              x="33"
              y="52.5"
              fill="#FFFFFF"
              fontSize="24"
              fontWeight="bold"
              fontFamily="monospace"
              aria-hidden={true}
            >
              {teamName}
            </text>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default TeamBadgeIcon;
