import { css } from '@emotion/react';
import { PropsWithChildren } from '@health-activity-ui/shared';
import { RDS_COLOR_NEUTRAL_95, RDS_COLOR_OUTLINE, RDS_OUTLINE_WIDTH } from '@rally/ui-themes/dist/web/rally';
import { Accordion as ReachUiAccordion, AccordionItem, AccordionPanel } from '@reach/accordion';
import '@reach/accordion/styles.css';
import React, { ElementType, ReactNode } from 'react';
import { AccordionHeader } from './components/header';

const cssStyle = `
  @keyframes slide-down {
    0% {
      opacity: 0;
      transform: translateY(-10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @media (prefers-reduced-motion: no-preference) {
    .slide-down[data-reach-accordion-panel] {
      animation: slide-down 0.4s ease;
    }
  }

  [data-reach-accordion-button] {
    cursor: pointer;
    padding: 0;
    width: 100%;

    &:focus {
      outline: ${RDS_COLOR_OUTLINE} auto ${RDS_OUTLINE_WIDTH};
    }

    &:hover {
      background-color: ${RDS_COLOR_NEUTRAL_95};
    }
  }

  [data-reach-accordion-item] {
    background-color: white;
    transition: all 0.6s ease;

    .accordion-header {
      transition: background-color 0.6s ease;
    }

    .accordion-icon {
      transition: transform 0.6s ease;
    }
  }

  [data-reach-accordion-item][data-state='open'] {
    .accordion-icon {
      transform: rotate(90deg);
    }
  }

  [data-reach-accordion-panel][data-state='open'] {
    max-height: 350px;
    overflow: scroll;
    visibility: visible;
  }
`;

interface AccordionProps extends PropsWithChildren {
  accordionHeader: ReactNode;
  headingLevel?: ElementType;
}

export const Accordion = ({ accordionHeader, headingLevel, children }: AccordionProps) => {
  return (
    <ReachUiAccordion
      collapsible
      multiple
      css={css`
        ${cssStyle}
      `}
    >
      <AccordionItem>
        <AccordionHeader headingLevel={headingLevel}>{accordionHeader}</AccordionHeader>
        <AccordionPanel className="slide-down">{children}</AccordionPanel>
      </AccordionItem>
    </ReachUiAccordion>
  );
};
