import React, { ReactElement } from 'react';

interface Props {
  width?: string;
  height?: string;
}

export const MindBody = ({ width = '50px', height = '56px' }: Props): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 50 56"
    aria-hidden="true"
    data-testid="meditation-icon"
  >
    <defs />
    <g fill="#FFF" fillRule="evenodd">
      <circle cx="24.964" cy="6" r="6" />
      <path d="M32.65 12a8 8 0 015.657 2.343l7.16 7.038c4.098 3.937 5.057 6.81 2.877 8.619-2.18 1.81-4.314 1.064-6.402-2.235L33.964 20v13l6.987 18.156c.085 3.23-1.005 4.844-3.27 4.844s-3.942-1.615-5.03-4.844L24.963 33l-7 18.156c-.51 3.23-2.252 4.844-5.228 4.844-2.975 0-3.88-1.872-2.716-5.617L15.964 33V20l-8 7.765C4.839 31.403 2.444 32.148.778 30c-1.666-2.148-.656-4.668 3.03-7.56l7.813-8.097A8 8 0 0117.278 12zm-3.397 7.674a2.52 2.52 0 00-3.431 0l-.858.814-.858-.814a2.52 2.52 0 00-3.431 0 2.22 2.22 0 000 3.256L24.964 27l4.29-4.07a2.22 2.22 0 000-3.256z" />
    </g>
  </svg>
);

export default MindBody;
