import React from 'react';

interface Props {
  width?: number;
  height?: number;
}

export const BloodSugarIcon = ({ width = 200, height = 165 }: Props): React.ReactElement => (
  <svg viewBox="0 0 200 165" width={width} height={height}>
    <ellipse fill="#E5E5E4" cx={104.1} cy={141.1} rx={28.8} ry={2.6} />
    <path
      fill="#DF5546"
      d="M136 88.8l-32.1-64.3-32.1 64.3h.1c-2.4 4.8-3.8 10.3-3.8 16.1 0 19.8 16.1 35.9 35.9 35.9 19.8 0 35.9-16.1 35.9-35.9-.1-5.8-1.5-11.3-3.9-16.1z"
    />
    <path
      fill="#CF4F41"
      d="M136.3 89.1l-32.1-64.4-.2.3 20.3 53.3h-.1c1.9 5.1 2.8 10.6 2.2 16.4-2 19.8-19.6 34.2-39.4 32.2-5.4-.5-10.3-2.3-14.7-4.8 6.1 11.4 18 19.1 31.8 19.1 19.9 0 36-16.1 36-36 .1-5.8-1.4-11.2-3.8-16.1z"
    />
    <path
      fill="#EF5B4B"
      d="M126.5 94.8c.6-5.8-.3-11.3-2.2-16.4h.1L104 25 72 89.1c-2.4 4.8-3.8 10.3-3.8 16.1 0 6.1 1.5 11.9 4.2 16.9 4.4 2.6 9.3 4.3 14.7 4.8 19.8 2 37.4-12.4 39.4-32.1z"
    />
    <path fill="#FFF" d="M87.9 107.2h31.9V118H87.9zm32-17.1h-10.6v10.5H98.5V90.1H87.9V79.3h10.6V68.7h10.8v10.6h10.6z" />
  </svg>
);

export default BloodSugarIcon;
