import React from 'react';

interface Props {
  width?: number;
  height?: number;
}

export const BloodPressureIcon = ({ width = 200, height = 165 }: Props): React.ReactElement => (
  <svg viewBox="0 0 200 165" width={width} height={height}>
    <ellipse fill="#E5E5E4" cx={101.4} cy={141.1} rx={28.8} ry={2.6} />
    <path
      fill="#CF4F41"
      d="M131.6 90L99.7 26.2l-.1.3 20.1 52.9h-.1c1.9 5 2.8 10.5 2.2 16.3-2 19.6-19.5 33.9-39.1 31.9-5.3-.5-10.2-2.2-14.5-4.8 6 11.3 17.8 18.9 31.5 18.9 19.7 0 35.7-16 35.7-35.7 0-5.8-1.4-11.2-3.8-16z"
    />
    <path
      fill="#EF5B4B"
      d="M122.4 95.6c.6-5.7-.3-11.2-2.2-16.3h.1l-20.1-52.9L68.4 90h.1c-2.4 4.8-3.8 10.2-3.8 16 0 6.1 1.5 11.8 4.2 16.7 4.3 2.6 9.2 4.3 14.5 4.8 19.6 2 37.1-12.3 39-31.9z"
    />
    <path
      fill="#CF4F41"
      d="M118.8 82.8c.7-1.3 1.1-2.2.8-2.5-.3-.3-1.2 0-2.4.7.5.6 1.1 1.2 1.6 1.8zm-6.9 1.9c-5.5 4.1-12 9.8-12.8 10.6-1.3 1.3-1.5 4 0 5.5s4.2 1.3 5.5 0c.8-.8 6.3-7.1 10.4-12.6-.8-1.3-1.9-2.5-3.1-3.5z"
    />
    <path
      fill="#FFF"
      d="M101.4 74.6C89 74.6 78.9 84.7 78.9 97c0 1.7 1.4 3.1 3.1 3.1 1.7 0 3.1-1.4 3.1-3.1 0-9 7.3-16.2 16.2-16.2 4 0 7.7 1.5 10.5 3.9 2-1.5 3.9-2.8 5.3-3.6-4-4-9.6-6.5-15.7-6.5zm17.4 8.2c-.8 1.4-2.2 3.4-3.8 5.4 1.6 2.5 2.6 5.5 2.6 8.8 0 1.7 1.4 3.1 3.1 3.1 1.7 0 3.1-1.4 3.1-3.1.1-5.3-1.9-10.3-5-14.2z"
    />
    <path
      fill="#CCC"
      d="M117.2 81.1c-1.4.8-3.3 2.1-5.3 3.6 1.2 1 2.3 2.2 3.1 3.6 1.6-2.1 2.9-4 3.8-5.4-.5-.7-1.1-1.3-1.6-1.8z"
    />
    <path
      fill="#FFF"
      d="M98.3 99.3c-1.5-1.5-1.3-4.2 0-5.5 1.3-1.3 18.9-16.5 20.5-14.9 1.5 1.5-13.6 19.2-14.9 20.5-1.4 1.2-4 1.4-5.6-.1z"
    />
  </svg>
);

export default BloodPressureIcon;
