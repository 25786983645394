import React from 'react';

interface Props {
  numberValue?: number;
  circleColor?: string;
  textColor?: string;
  className?: string;
}

export const CircleNumberIcon = ({
  numberValue = 1,
  circleColor = '#f97243',
  textColor = '#ffffff',
  className = '',
}: Props): React.ReactElement => (
  <svg height={24} width={24} viewBox={`0 0 24 24`} className={className}>
    <circle cx={12} cy={12} r={12} fill={circleColor} />
    <text x="12" y="16" textAnchor="middle" fill={textColor} fontSize="0.79rem" fontWeight="700">
      {numberValue}
    </text>
  </svg>
);

export default CircleNumberIcon;
