import React, { ComponentProps, FunctionComponent, PropsWithChildren, ReactElement } from 'react';

export const combineContexts = (
  ...contexts: FunctionComponent<PropsWithChildren<unknown>>[]
): FunctionComponent<PropsWithChildren<unknown>> => {
  return contexts.reduce(
    (AccumulatedContexts, CurrentContext) => {
      return ({ children }: ComponentProps<FunctionComponent<PropsWithChildren<unknown>>>): ReactElement => {
        return (
          <AccumulatedContexts>
            <CurrentContext>{children}</CurrentContext>
          </AccumulatedContexts>
        );
      };
    },
    ({ children }) => <>{children}</>
  );
};
