import { EnvironmentType } from '@rally/analytics';
import TENANT from '../../../../tools/tenant';
import { localWindow } from '../configs/app.config.local';
import { AppConfig } from '../constants/app-config';

export enum NodeEnvironments {
  Test = 'test',
  Development = 'development',
  Production = 'production',
}

export const getTarget = (subdomain: string): string => {
  return TENANT === 'int' ? `https://${subdomain}.int.werally.in` : `https://${subdomain}.${TENANT}.rally-dev.com`;
};

/**
 * Conditional Type
 * StringOrBoolean conditionally determines if the type is a string or boolean based on the input.
 * The return type of `readConfig` function is based on the type of the passed in `defaultValue` argument.
 * If the `defaultValue` is a boolean then the expected return type will be a boolean.
 * If the `defaultValue` is a string then the expected return type will be a string.
 * No need for type casting the return value (e.g., `readConfig(value, 'string') as string`).
 * */
type StringOrBoolean<T> = T extends boolean ? boolean : T extends string ? string : unknown;

export const readConfig = <T extends boolean | string>(
  maybeConfig: string | boolean | undefined,
  defaultValue: T
): StringOrBoolean<T> => {
  if (typeof maybeConfig === 'undefined' || maybeConfig === null) {
    return defaultValue as StringOrBoolean<T>;
  }

  // If config should be a boolean, but env config is a string convert to boolean
  if (typeof defaultValue === 'boolean' && typeof maybeConfig === 'string') {
    maybeConfig = maybeConfig === 'true';
  }

  return maybeConfig as StringOrBoolean<T>;
};

export const ROUTING = {
  LOCAL_PORT: '5678',
  PROXY: {
    DOMAIN: 'localhost.gateway.dev.werally.in',
  },
};

export const LOCAL_URL = `http://${ROUTING.PROXY.DOMAIN}:${ROUTING.LOCAL_PORT}`;

export const createAppConfig = (config: Partial<AppConfig>): AppConfig => ({
  // Service URLS
  HAUI_APP_HA_ACCOUNTS_URL: readConfig(config.HAUI_APP_HA_ACCOUNTS_URL, getTarget('accounts')),
  HAUI_APP_HA_AUTHN_URL: readConfig(config.HAUI_APP_HA_AUTHN_URL, LOCAL_URL),
  HAUI_APP_HA_ADOBE_ANALYTICS_URL: readConfig(config.HAUI_APP_HA_ADOBE_ANALYTICS_URL, ''),
  HAUI_APP_HA_ADOBE_BCBSNC_ANALYTICS_URL: readConfig(config.HAUI_APP_HA_ADOBE_BCBSNC_ANALYTICS_URL, ''),
  HAUI_APP_HA_CDC_URL: readConfig(config.HAUI_APP_HA_CDC_URL, 'https://www.cdc.gov/coronavirus/2019-ncov/index.html'),
  HAUI_APP_HA_CHAT_URL: readConfig(config.HAUI_APP_HA_CHAT_URL, `${LOCAL_URL}/rest/chat`),
  HAUI_APP_HA_CONTENT_ARTICLES_BASE_URL: readConfig(config.HAUI_APP_HA_CONTENT_ARTICLES_BASE_URL, getTarget('content')),
  HAUI_APP_HA_CONTENT_BASE_URL: readConfig(config.HAUI_APP_HA_CONTENT_BASE_URL, getTarget('navigation-ichabod')),
  HAUI_APP_HA_FAQ_COVID_19_URL: readConfig(
    config.HAUI_APP_HA_FAQ_COVID_19_URL,
    'https://home.werally.com/en-US/faq/covid19'
  ),
  HAUI_APP_HA_HEALTH_BASE_URL: readConfig(config.HAUI_APP_HA_HEALTH_BASE_URL, LOCAL_URL),
  HAUI_APP_HA_HUGINN_SCRIPT_URL: readConfig(config.HAUI_APP_HA_HUGINN_SCRIPT_URL, `${LOCAL_URL}/huginn`),
  HAUI_APP_HA_NOW_BASE_URL: readConfig(config.HAUI_APP_HA_NOW_BASE_URL, `${LOCAL_URL}/rest/now`),
  HAUI_APP_HA_RESOURCES_URL: readConfig(config.HAUI_APP_HA_RESOURCES_URL, getTarget('resources')),
  HAUI_APP_HA_REWARDS_URL: readConfig(config.HAUI_APP_HA_REWARDS_URL, getTarget('rewards')),
  HAUI_APP_HA_STARSHIP_CDN_URL: readConfig(
    config.HAUI_APP_HA_STARSHIP_CDN_URL,
    'https://rally-non-prod-cdn-int.werally.in/starship/dev'
  ),
  HAUI_APP_HA_SURVEY_URL: readConfig(config.HAUI_APP_HA_SURVEY_URL, getTarget('survey')),
  HAUI_APP_HA_UI_UNIFIED_URL: readConfig(config.HAUI_APP_HA_UI_UNIFIED_URL, `${LOCAL_URL}/health`),
  HAUI_APP_HA_UNIFIED_DOMAIN_API_URL: readConfig(config.HAUI_APP_HA_UNIFIED_DOMAIN_API_URL, LOCAL_URL),

  // Other
  HAUI_APP_HA_BASE_DOMAIN: readConfig(config.HAUI_APP_HA_BASE_DOMAIN, ROUTING.PROXY.DOMAIN),
  HAUI_APP_HA_ENVIRONMENT: readConfig(config.HAUI_APP_HA_ENVIRONMENT, NodeEnvironments.Development) as EnvironmentType,
  HAUI_APP_HA_ROUTE_PRODUCT_NAME: readConfig(config.HAUI_APP_HA_ROUTE_PRODUCT_NAME, 'health'),
  HAUI_APP_HA_VERSION: readConfig(config.HAUI_APP_HA_VERSION, ''),
  HAUI_APP_HA_AMPLITUDE_KEY: readConfig(
    config.HAUI_APP_HA_AMPLITUDE_KEY,
    // If in development environment try to use local (throwaway) advantage project API key.
    // If API key is not set in '.env.local', default to empty string to prevent amplitude init.
    // If the neptune variable is not injected in other NodeJS environments ('test', 'production'),
    // set to empty string to prevent amplitude init.
    process.env.NODE_ENV === NodeEnvironments.Development ? process.env.REACT_APP_AMPLITUDE_KEY_LOCAL ?? '' : ''
  ),
  HAUI_APP_HA_DATADOG_APP_ID: readConfig(config.HAUI_APP_HA_DATADOG_APP_ID, ''),
  HAUI_APP_HA_DATADOG_CLIENT_TOKEN: readConfig(config.HAUI_APP_HA_DATADOG_CLIENT_TOKEN, ''),
  HAUI_APP_HA_RALLY_CALLER: readConfig(config.HAUI_APP_HA_RALLY_CALLER, 'development/healthactivity/ui'),
});

export const appConfig = createAppConfig(localWindow.HAUI_APP_HA_ENV_CONFIG || {});
