import { IR2CUserInput, IUserReadiness, R2C_BASE_V1_URI, R2C_BASE_V2_URI } from '@health-activity-ui/shared';
import { AxiosResponse } from 'axios';
import apiClient from './api-client';

/**
 * @method getReadinessShort
 *
 * @description Gets the latest minimized readiness data that does not contain any PHR information
 *
 * @param {string} userId
 *
 * @return {IUserReadiness}
 * */
export const getReadinessShort = async (userId: string): Promise<IUserReadiness> => {
  const response: AxiosResponse<IUserReadiness> = await apiClient<IUserReadiness>(`${R2C_BASE_V2_URI}/${userId}/short`);

  return response.data;
};

/**
 * @method getUserReadiness
 *
 * @description Gets the latest readiness data for the user and includes the acuityState information.
 *
 * @param {string} userId
 *
 * @return {IUserReadiness}
 * */
export const getUserReadiness = async (userId: string): Promise<IUserReadiness> => {
  const response: AxiosResponse<IUserReadiness> = await apiClient<IUserReadiness>(`${R2C_BASE_V2_URI}/${userId}`);

  return response.data;
};

/**
 * @function updateUsersReadiness
 *
 * @description Update user's readiness state
 * To quit user's readiness goal pass the following:
 * {"nowTopics":[],"soonTopics":[]} to the update param.
 *
 * @param {string} rallyId
 * @param {IR2CUserInput} update
 *
 * @return {Promise<string>} Returns 204 response code if successful.
 * */
export const updateUsersReadiness = async (rallyId: string, update: IR2CUserInput): Promise<number> => {
  const response = await apiClient<AxiosResponse>(`${R2C_BASE_V1_URI}/${rallyId}/userInput`, {
    method: 'POST',
    data: update,
  });
  return response.status;
};
