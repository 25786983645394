import { PropsWithChildren } from '@health-activity-ui/shared';
import classnames from 'classnames';
import React from 'react';
import { Element, ElementProps } from '../../element/element';

export interface ContainerProps extends ElementProps, PropsWithChildren {
  // expands the container to 100% width
  isFluid?: boolean;
}

// Generates a 'container' class on  div element that centers its content
export const Container = ({
  className = undefined,
  renderAs = 'div',
  domRef = undefined,
  children = null,
  isFluid = false,
  ...allProps
}: ContainerProps) => (
  <Element
    {...allProps}
    renderAs={renderAs}
    className={classnames('container', className, {
      [`is-fluid`]: isFluid,
    })}
  >
    {children}
  </Element>
);

export default Container;
