import { useCore, useSafeState } from '@health-activity-ui/core';
import { RdsButton, RdsIcon } from '@rally/energon-react';
import React, { ReactElement } from 'react';
import { Level, LevelItem } from '../layout';
import { AcLoader } from '../loader/ac-loader.component';
import { WithShowMorePaginationProps, WithShowMorePaginationState } from './show-more-pagination.interface';

const cssStyles = `
  li:first-of-type {
    padding-top: 0 !important;
  }
`;

const WithShowMorePagination = <TData,>({
  list,
  onLoadMore,
  renderItem,
  initHasMore,
  pageSize = 5,
  listClassName = '',
}: WithShowMorePaginationProps<TData>): ReactElement => {
  const { t } = useCore();
  const [state, setState] = useSafeState<WithShowMorePaginationState>({
    isLoading: false,
    hasMoreData: initHasMore,
    showCount: Math.min(pageSize, list.length),
  });
  const displayedList = list.slice(0, Math.max(state.showCount, pageSize));
  const displayShowMore = state.hasMoreData || displayedList.length < list.length;

  const handleShowMore = async () => {
    const estNextShowCount = displayedList.length + pageSize;
    if (!state.hasMoreData || estNextShowCount <= list.length) {
      const nextShowCount = Math.min(list.length, estNextShowCount);
      setState({ ...state, showCount: nextShowCount });
    } else {
      setState({ ...state, isLoading: true });
      const hasMore = await onLoadMore(pageSize);
      setState({ ...state, isLoading: false, showCount: estNextShowCount, hasMoreData: hasMore });
    }
  };

  return (
    <>
      <ul css={cssStyles} data-testid="pagination-list" className={listClassName}>
        {displayedList?.map(renderItem)}
      </ul>
      {state.isLoading && <AcLoader />}
      <Level>
        <LevelItem>
          {displayShowMore && (
            <RdsButton disabled={state.isLoading} type="button" variant="secondary" onClick={() => handleShowMore()}>
              {t('showMore')} <RdsIcon name="system-arrow-down" size="16" className="has-rds-ml-8" />
            </RdsButton>
          )}
        </LevelItem>
      </Level>
    </>
  );
};

export { WithShowMorePagination };
