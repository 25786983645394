import { SESSION } from '../constants/session';
import { appConfig } from './app.config';

export const HUGINN_PUBLIC_URL = `${appConfig.HAUI_APP_HA_UNIFIED_DOMAIN_API_URL}/rest/advantage/public`;
export const HUGINN_SESSION_ENDPOINT = `${HUGINN_PUBLIC_URL}/session`;
export const HUGINN_TOKEN_ENDPOINT = `${HUGINN_PUBLIC_URL}/token`;

export const initHuginn = {
  productName: appConfig.HAUI_APP_HA_ROUTE_PRODUCT_NAME,
  provision: {
    sessionUri: HUGINN_SESSION_ENDPOINT,
    tokenUri: HUGINN_TOKEN_ENDPOINT,
    rpTokenSuffix: SESSION.RP_TOKEN,
    productPath: window.location?.href || appConfig.HAUI_APP_HA_UI_UNIFIED_URL,
  },
};
