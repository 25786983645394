import { HelperProps, helpers, PropsWithChildren } from '@health-activity-ui/shared';
import classnames from 'classnames';
import React from 'react';

export interface ElementProps extends HelperProps, PropsWithChildren {
  // to reference a dom element
  domRef?: Record<string, unknown>;
  // custom classes to pass into the component other than rds classes
  className?: string;
  // DOMString representing the id of the element
  id?: string;
  // to create an html element to attach classes to
  renderAs?: React.ElementType;
  // WAI-ARIA
  role?: string;
  // custom styles
  style?: React.CSSProperties;
}

export const Element = ({
  className = undefined,
  renderAs = 'div',
  domRef = undefined,
  id = undefined,
  role = undefined,
  style = undefined,
  ...allProps
}: ElementProps) => {
  const RenderAs = renderAs;
  const props = helpers.base(allProps);

  return (
    <RenderAs
      ref={domRef}
      role={role}
      id={id}
      style={style}
      className={classnames(className, helpers.classnames(allProps)) || undefined}
      {...props}
    />
  );
};

export default Element;
