import {
  AppliedCheckin,
  ChallengeCity,
  ChallengeConfig,
  ChallengeMember,
  ChallengeMemberStats,
  ChallengeMultipleStats,
  ChallengeMultipleTeamStats,
  ChallengeStatus,
  ChallengeTeam,
  CHALLENGE_V2_BASE_URI,
  CheckinResult,
  ERROR_CHALLENGE_COMPLETED_JOINED,
  ERROR_CHALLENGE_REGISTRATION_ENDED,
  ERROR_INVALID_INVITE_CODE,
  ERROR_JOINING_CHALLENGE,
  ERROR_MAX_CHALLENGES,
  GetTeamLeaderboardParams,
  GetTeamsParams,
  IncentiveInfo,
  JoinableInstancesParams,
  JoinedInstancesParams,
  LeaderboardData,
  LeaderboardMember,
  MemberLeaderboard,
  TeamLeaderboard,
  UniversalChallengeInstance,
} from '@health-activity-ui/shared';
import { getChallengeState, isDefined, promiseAllSettled } from '@health-activity-ui/utils';
import { AxiosResponse } from 'axios';
import apiClient from './api-client';

interface ErrorMessage {
  errorMessage: string;
}

/**
 * @method joinUserToChallengeInstance
 *
 * @description Request for user to join a specific challenge
 *
 * @param {string} challengeId of challenge to join.
 *
 * @return {ChallengeMember}
 * */
export const joinUserToChallengeInstance = async (challengeId: string): Promise<ChallengeMember | ErrorMessage> => {
  try {
    const response: AxiosResponse<ChallengeMember> = await apiClient<ChallengeMember>(
      `${CHALLENGE_V2_BASE_URI}/v1/instances/${challengeId}/join`,
      {
        method: 'POST',
      }
    );

    return response.data;
  } catch (e) {
    const error = {
      errorMessage: '',
    };

    if (e.status === 403) {
      if (e.data.includes('because challenge lastJoinableDate or openDate not valid')) {
        error.errorMessage = ERROR_CHALLENGE_REGISTRATION_ENDED;
      } else {
        error.errorMessage = ERROR_MAX_CHALLENGES;
      }
    } else {
      error.errorMessage = ERROR_JOINING_CHALLENGE;
    }

    console.error('Error: Challenges V2 API, joinUserToChallengeInstance: ', e.data);

    return error;
  }
};

/*
 * Retrieves checkin milestone completed and progress data
 */
export const postCheckIn = async (
  challengeId: string,
  manualCheckinAmount = 0,
  isManual?: boolean
): Promise<CheckinResult> => {
  const response: AxiosResponse<CheckinResult> = await apiClient<CheckinResult>(
    `${CHALLENGE_V2_BASE_URI}/v1/instances/${challengeId}/members/checkins`,
    {
      method: 'POST',
      data: {
        isManual,
        manualCheckinAmount,
      },
    }
  );

  return response.data;
};

/**
 * @method getInstanceByInviteCode
 *
 * @param {string} inviteCode
 *
 * @return {UniversalChallengeInstance}
 * */
export const getInstanceByInviteCode = async (
  inviteCode: string
): Promise<UniversalChallengeInstance | ErrorMessage> => {
  try {
    const response: AxiosResponse<UniversalChallengeInstance> = await apiClient(
      `${CHALLENGE_V2_BASE_URI}/v1/instances/inviteCode/${inviteCode}`
    );

    return response.data;
  } catch (e) {
    const error = {
      errorMessage: '',
    };

    if (e.status === 403) {
      error.errorMessage = ERROR_CHALLENGE_COMPLETED_JOINED;
    } else if (e.status === 404) {
      error.errorMessage = ERROR_INVALID_INVITE_CODE;
    } else {
      error.errorMessage = 'Sorry, there was an error submitting your invite code.';
    }

    console.error('Error: Challenges V2 API, getInstanceByInviteCode: ', e.data);

    return error;
  }
};

/**
 * @method getJoinableInstances
 *
 * @param {number} [skip] number of results to skip for pagination. Defaults to 0.
 * @param {number} [limit] number of results to return for pagination. Defaults to 10.
 * @param {boolean} [isFeatured] to return joinable instances that have a status of featured
 * @param {string} [sortBy] to sort joinable Instance
 *
 * @return {UniversalChallengeInstance[]}
 * */
export const getJoinableInstances = async ({
  skip = 0,
  limit = 10,
  isFeatured = false,
  sortBy,
}: JoinableInstancesParams): Promise<UniversalChallengeInstance[]> => {
  let params: JoinableInstancesParams = { skip, limit, isFeatured };
  params = isDefined(sortBy) ? { ...params, sortBy } : params;
  const response: AxiosResponse<UniversalChallengeInstance[]> = await apiClient(
    `${CHALLENGE_V2_BASE_URI}/v1/instances/joinable`,
    {
      params,
    }
  );

  return response.data;
};

/**
 * @method getJoinedInstances
 *
 * @description Fetches the joined challenges for the current user
 *
 * @param {number} [skip] number of results to skip for pagination. Defaults to 0.
 * @param {number} [limit] number of results to return for pagination. Defaults to 10.
 * @param {boolean} [isCompleted] param to return joined instances that have a status of completed. Defaults to false.
 * @param {boolean} [isWaiting] param to return joined instances that have a status of waiting. Defaults to false.
 * @param {boolean} [isActive] param to return joined instances that have a status of active. Defaults to false.
 * @param {string} [endDateGreaterThan] Exclusive ISO-8601 timestamp. Only considered if isCompleted is true,
 * will only get challenges with an end date greater than this
 * @param {boolean} [isInviteOnly] only get private challenges (including UCC)
 *
 * @return {UniversalChallengeInstance[]}
 * */
export const getJoinedInstances = async ({
  skip = 0,
  limit = 25,
  isCompleted = false,
  isWaiting = false,
  isActive = false,
  endDateGreaterThan,
  isInviteOnly,
}: JoinedInstancesParams): Promise<UniversalChallengeInstance[]> => {
  let params: JoinedInstancesParams = { skip, limit, isCompleted, isWaiting, isActive };
  params = isDefined(endDateGreaterThan) ? { ...params, endDateGreaterThan } : params;
  params = isDefined(isInviteOnly) ? { ...params, isInviteOnly } : params;

  const response: AxiosResponse<UniversalChallengeInstance[]> = await apiClient(
    `${CHALLENGE_V2_BASE_URI}/v1/instances/joined`,
    {
      params,
    }
  );

  return response.data;
};

/**
 * @method getV2Configs
 *
 * @description returns 5 items:
 * 1. branchIoInviteUrl, the link to invite user to download RallyApp.
 * 2. showUserCreatedChallenges, whether mobile / web should display user created challenges
 * 3. useReactRouting, whether react routes should be active
 * 4. showViewPrivateChallenges
 * 5. showUCCWeb
 *
 * @return {ChallengeConfig}
 * */
export const getV2Configs = async (): Promise<ChallengeConfig> => {
  const response: AxiosResponse<ChallengeConfig> = await apiClient(`${CHALLENGE_V2_BASE_URI}/v1/config`, {
    useCache: true,
  });

  return response.data;
};

/**
 * @method getUserLeaderboard
 *
 * @description Get totals/averages of checkin data for a given program and group, grouped by challengeId and rallyId
 *
 * @param {string} challengeId the challengeId is corresponding to programId
 * @param {string} [teamId] the team user is is associated with
 * @param {string} [sort] average or total. defaults to total.
 * @param {number} [skip] number of results to skip for pagination. BE defaults to 0.
 * @param {number} [limit] number of results to return for pagination. BE defaults to 10.
 *
 * @return {MemberLeaderboard} For pagination if skip and limit params are undefined BE will return everything.
 * */
export const getUserLeaderboard = async (
  challengeId: string,
  skip: number,
  limit: number,
  teamId = undefined,
  sort = 'total'
): Promise<MemberLeaderboard> => {
  const params = { skip, sort, limit, teamId };
  const response: AxiosResponse<MemberLeaderboard> = await apiClient(
    `${CHALLENGE_V2_BASE_URI}/v1/instances/${challengeId}/leaderboard`,
    {
      params,
    }
  );
  return response.data;
};

/**
 * @method getTeamLeaderboard
 *
 * @description Get totals/averages of checkin data for a given program and group, grouped by challengeId and rallyId
 *
 * @param {string} challengeId the challengeId is corresponding to programId
 * @param {string} [sort] average or total. defaults to total.
 * @param {number} [skip] number of results to skip for pagination. BE defaults to 0.
 * @param {number} [limit] number of results to return for pagination. BE defaults to 10.
 *
 * @return {TeamLeaderboard} For pagination if skip and limit params are undefined BE will return everything.
 * */
export const getTeamLeaderboard = async (challengeId, skip, limit, sort = 'total'): Promise<TeamLeaderboard> => {
  const response: AxiosResponse<TeamLeaderboard> = await apiClient(
    `${CHALLENGE_V2_BASE_URI}/v1/instances/${challengeId}/teams/leaderboard`,
    {
      params: {
        sort,
        skip,
        limit,
      },
    }
  );

  return response.data;
};

/**
 * @name getInstance
 *
 * @description gets challenge by challengeId
 *
 * @param {string} challengeId of challenge to get
 *
 * @return {UniversalChallengeInstance}
 * */
export const getInstance = async (challengeId: string): Promise<UniversalChallengeInstance> => {
  const response: AxiosResponse<UniversalChallengeInstance> = await apiClient(
    `${CHALLENGE_V2_BASE_URI}/v1/instances/${challengeId}`
  );

  return response.data;
};

/**
 * @name getStatsByUser
 *
 * @description gets a user's stats for a specific challenge
 *
 * @param {string} challengeId of challenge to get
 *
 * @return {UniversalChallengeInstance}
 * */
export const getStatsByUser = async (challengeId: string): Promise<ChallengeMemberStats> => {
  const response: AxiosResponse<ChallengeMemberStats> = await apiClient(
    `${CHALLENGE_V2_BASE_URI}/v1/instances/${challengeId}/stats`
  );
  return response.data;
};

/**
 * @name getCheckins
 *
 * @description AppliedCheckins is the list of checkins for a date range
 * Represents a check in that has already happened.
 *
 * @param {string} challengeId
 *
 * @return {AppliedCheckin[]} returns a list of user's checkin data
 * */
export const getCheckins = async (challengeId: string): Promise<AppliedCheckin[]> => {
  const response: AxiosResponse<AppliedCheckin[]> = await apiClient(
    `${CHALLENGE_V2_BASE_URI}/v1/instances/${challengeId}/members/checkins`
  );

  return Array.isArray(response.data)
    ? response.data.map((checkin) => {
        if (typeof checkin.checkinDate === 'string') {
          const [year, month, day] = checkin.checkinDate.split('-');
          checkin.checkinDate = `${month}/${day}/${year}`;
        }
        return checkin;
      })
    : response.data;
};

/**
 * @name getMembers
 *
 * @description gets all challenge members of a challenge instance
 *
 * @param {string} challengeId
 *
 * @return {AppliedCheckin[]} returns a list of user's checkin data
 * */
export const getMembers = async (challengeId: string): Promise<ChallengeMember[]> => {
  const response: AxiosResponse<ChallengeMember[]> = await apiClient(
    `${CHALLENGE_V2_BASE_URI}/v1/instances/${challengeId}/members`
  );
  return response.data;
};

/**
 * @name getTotalStatsByUser
 *
 * @description retrieves the incentive info for the user
 *
 * @return {IncentiveInfo} returns incentive info for the users
 * */
export const getTotalStatsByUser = async (): Promise<IncentiveInfo> => {
  const response: AxiosResponse<IncentiveInfo> = await apiClient(`${CHALLENGE_V2_BASE_URI}/v1/stats`);
  return response.data;
};

/**
 * @name getChallengeMemberSummary
 *
 * @description Get Challenge summary information for totals/averages of checkin data for a given challengeId
 *
 * @param {string} challengeId
 * @param {string} [sort] average or total
 * @param {number} [skip] of results to skip for pagination
 * @param {number} [limit] number of results to return for pagination
 *
 * @return {LeaderboardMember[]} returns a member response in the leaderboard
 * */
export const getChallengeMemberSummary = async ({
  challengeId,
  sort = 'total',
  skip,
  limit = 100,
  teamId,
}: GetTeamLeaderboardParams): Promise<LeaderboardMember[]> => {
  const response: AxiosResponse<LeaderboardMember[]> = await apiClient(
    `${CHALLENGE_V2_BASE_URI}/v1/instances/${challengeId}/summary`,
    {
      params: {
        sort,
        skip,
        limit,
        teamId,
      },
    }
  );

  return response.data;
};

/**
 * @name getTeams
 *
 * @description Get teams for a challenge
 *
 * @param {string} challengeId of challenge to get Teams
 * @param {number} [skip] number of results to skip for pagination
 * @param {number} [limit] number of results to return for pagination
 *
 * @return {ChallengeTeam[]}
 * */
export const getTeams = async ({ challengeId, skip, limit, sort }: GetTeamsParams): Promise<ChallengeTeam[]> => {
  const response: AxiosResponse<ChallengeTeam[]> = await apiClient(
    `${CHALLENGE_V2_BASE_URI}/v1/instances/${challengeId}/teams`,
    {
      params: {
        skip,
        limit,
        sort,
      },
    }
  );

  return response.data;
};

/**
 * @name joinUserToTeam
 *
 * @description Joins user to team and challenge
 *
 * @param {string} challengeId
 * @param {string} teamId
 *
 * @return {ChallengeMember}
 * */
export const joinUserToTeam = async (challengeId: string, teamId: string): Promise<ChallengeMember> => {
  const response: AxiosResponse<ChallengeMember> = await apiClient(
    `${CHALLENGE_V2_BASE_URI}/v1/instances/${challengeId}/teamId/${teamId}/join`,
    {
      method: 'POST',
    }
  );

  return response.data;
};

/**
 * @name moveTeams
 *
 * @description move user to a different team
 *
 * @param {string} challengeId
 * @param {string} teamId
 *
 * @return {ChallengeMember}
 * */
export const moveTeams = async (challengeId: string, teamId: string): Promise<ChallengeMember> => {
  const response: AxiosResponse<ChallengeMember> = await apiClient(
    `${CHALLENGE_V2_BASE_URI}/v1/instances/${challengeId}/teamId/${teamId}/move`,
    {
      method: 'POST',
    }
  );

  return response.data;
};

/**
 * @name fetchLeaderboardData
 *
 * @description returns an object with the proper leaderboard response. The object
 * returned has property that matches the type of leaderboard requested for. This is used
 * inside of ChallengeLeaderBoardModel so it can process the reponse and generate a leaderboard
 *
 * @param {string} challengeId
 * @param {string} isJoined
 * @param {string} isTeam
 * @param {string} limit
 * @param {string} teamId
 * @param {string} skip
 *
 * @return {LeaderboardData}
 * */
export const fetchLeaderboardData = async (
  challengeId: string,
  isJoined: boolean,
  isTeam: boolean,
  sort: string,
  challengeStatus: ChallengeStatus,
  teamId?: string,
  limit?: number,
  skip?: number
): Promise<LeaderboardData> => {
  try {
    let leaderboardData;
    const challengeState = getChallengeState(challengeStatus, isJoined, isTeam);

    if (challengeState === 'WAITING_TEAM_JOINED') {
      // Why 4 different calls? If no user's are joined to other teams then getTeamLeaderboard will return only teams with joined users.
      // So we have to call getTeams to get all the teams including teams with no users.
      // here we also call getTeamLeaderboard because we need
      // the user's current team + hasNextPage. getTeams only returns an array of teams
      const [
        waitingJoinedTeamLeaderboardAllTeams,
        waitingJoinedTeamLeaderboardWithSkipAndLimit,
        joinedTeamLeaderboard,
        joinedUserLeaderboard,
      ] = await promiseAllSettled([
        // get all the teams so we can mark the current team banner,
        // and show all the teams that are avaiable to join in the waiting state
        getTeams({ challengeId, limit: 200 }),
        getTeams({ challengeId, skip, sort, limit }),
        getTeamLeaderboard(challengeId, skip, limit, sort),
        getUserLeaderboard(challengeId, skip, limit),
      ]);

      leaderboardData = {
        waitingJoinedTeamLeaderboardAllTeams,
        waitingJoinedTeamLeaderboardWithSkipAndLimit,
        joinedTeamLeaderboard,
        joinedUserLeaderboard,
      };
    } else if (challengeState === 'WAITING_TEAM_UNJOINED' || challengeState === 'ACTIVE_TEAM_UNJOINED') {
      const unjoinedTeamLeaderboard = await getTeams({ challengeId, limit: 200 });

      leaderboardData = { unjoinedTeamLeaderboard };
    } else if (challengeState === 'ACTIVE_TEAM_JOINED' || challengeState === 'COMPLETE_TEAM') {
      // here we want to grab the top user checkin, sorted by total (which is the default in
      // getUserLeaderboard), so we can set the max intervalbound that a team challenge that
      // scoringMethod = average.
      const [joinedUserLeaderboard, joinedTeamLeaderboard] = await promiseAllSettled([
        getUserLeaderboard(challengeId, skip, limit),
        getTeamLeaderboard(challengeId, skip, limit, sort),
      ]);

      leaderboardData = { joinedTeamLeaderboard, joinedUserLeaderboard };
    } else if (challengeState === 'WAITING_USER_JOINED') {
      // check if we want to filter the user leaderboard based on team
      const waitingJoinedUserLeaderboard = teamId
        ? await getUserLeaderboard(challengeId, skip, limit, teamId)
        : await getUserLeaderboard(challengeId, skip, limit);

      leaderboardData = { waitingJoinedUserLeaderboard };
    } else if (challengeState === 'WAITING_USER_UNJOINED' || challengeState === 'ACTIVE_USER_UNJOINED') {
      let unjoinedUserLeaderboard = [];

      // check if we want to filter the leaderboard based on team for the teamLeaderBoard
      if (teamId) {
        // we limit the amount of users we show in the unjoined state of a team challenge
        // for performance reasons
        unjoinedUserLeaderboard = await getChallengeMemberSummary({
          challengeId,
          teamId,
          skip,
          limit,
        });
      } else {
        unjoinedUserLeaderboard = await getChallengeMemberSummary({ challengeId, skip, limit });
      }

      leaderboardData = { unjoinedUserLeaderboard };
    } else if (challengeState === 'ACTIVE_USER_JOINED' || challengeState === 'COMPLETE_USER') {
      // check if we want to filter the leaderboard based on team for the teamLeaderBoard
      const joinedUserLeaderboard = teamId
        ? await getUserLeaderboard(challengeId, skip, limit, teamId)
        : await getUserLeaderboard(challengeId, skip, limit);

      leaderboardData = { joinedUserLeaderboard };
    }

    return { ...leaderboardData, skip: limit };
  } catch (e) {
    console.error('Error. Unable to fetch leader board data.', e);
  }
};

/**
 * @name getCities
 *
 * @description Retireve a list of all cities' info
 *
 * @return {ChallengeTeam[]}
 * */
export const getCities = async (): Promise<ChallengeCity[]> => {
  const response: AxiosResponse<ChallengeCity[]> = await apiClient(`${CHALLENGE_V2_BASE_URI}/v1/city/getCities`);

  return response.data;
};

/**
 * @method getCity
 *
 * @description retrieve city data for city walk challenge
 *
 * @param {string} cityId ID of target city
 *
 * @return {Promise<ChallengeCity>} Requested city data
 * */
export const getCity = async (cityId: string): Promise<ChallengeCity> => {
  const response: AxiosResponse<ChallengeCity> = await apiClient(`${CHALLENGE_V2_BASE_URI}/v1/city/${cityId}/getCity`);

  return response.data;
};

// TODO when quiting challenge user is still in ChallengeMember array
export const getIsUserJoinedToChallenge = async (challengeId: string): Promise<boolean> => {
  try {
    const joinedChallenges: UniversalChallengeInstance[] = await getJoinedInstances({
      limit: 0,
      isWaiting: true,
      isActive: true,
      isCompleted: true,
    });

    return joinedChallenges.reduce((isJoined: boolean, challenge: UniversalChallengeInstance) => {
      if (challengeId === challenge.challengeId) {
        return true;
      }
      return isJoined;
    }, false);
  } catch (error) {
    console.error('Error: Challenges V2 API, getIsUserJoinedToChallenge: ', error);
  }
};

/**
 * @method quitChallengeInstance
 *
 * @description Request for user to quit a specific challenge
 *
 * @param {string} challengeId of challenge to join.
 *
 * @return {number} on success
 * */
export const quitChallengeInstance = async (challengeId: string): Promise<number> => {
  const response: AxiosResponse<void> = await apiClient(
    `${CHALLENGE_V2_BASE_URI}/v1/instances/${challengeId}/members`,
    {
      method: 'DELETE',
    }
  );

  return response.status;
};

export const getMemberCountInChallenge = async (challengeId: string, teamId?: string): Promise<number> => {
  const response: AxiosResponse<number> = await apiClient(
    `${CHALLENGE_V2_BASE_URI}/v1/instances/${challengeId}/memberCount`,
    {
      params: {
        teamId,
      },
    }
  );

  return response.data;
};

export const getMultipleStats = async (challengeIds: string[]): Promise<ChallengeMultipleStats[]> => {
  const response: AxiosResponse<ChallengeMultipleStats[]> = await apiClient(
    `${CHALLENGE_V2_BASE_URI}/v1/instances/multipleStats`,
    {
      params: {
        challengeIds: challengeIds.toString(),
      },
    }
  );

  return response.data;
};

export const getMultipleTeamStats = async (challengeIds: string[]): Promise<ChallengeMultipleTeamStats[]> => {
  const response: AxiosResponse<ChallengeMultipleTeamStats[]> = await apiClient(
    `${CHALLENGE_V2_BASE_URI}/v1/instances/multipleTeamStats?${challengeIds.map((c) => `challengeIds=${c}`).join('&')}`
  );

  return response.data;
};
