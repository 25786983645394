import React from 'react';

interface Props {
  width?: number;
  height?: number;
}

export const MoodIcon = ({ width = 200, height = 165 }: Props): React.ReactElement => (
  <svg viewBox="0 0 200 165" width={width} height={height}>
    <ellipse fill="#E5E5E4" cx={60.4} cy={128.7} rx={31.1} ry={3.3} />
    <circle fill="#EF5B4B" cx={62.1} cy={80.2} r={48.5} />
    <path
      fill="#CF4F41"
      d="M92.2 42.1c6.5 8.3 10.4 18.7 10.4 30.1 0 26.8-21.7 48.5-48.5 48.5-11.4 0-21.8-3.9-30.1-10.4 8.9 11.2 22.6 18.4 38.1 18.4 26.8 0 48.5-21.7 48.5-48.5 0-15.5-7.2-29.2-18.4-38.1z"
    />
    <path
      fill="#4D4D4D"
      d="M87.4 73.2c-.2 0-.4 0-.7-.1l-14.7-3c-1.4-.3-2.5-1.5-2.6-3-.1-1.5.7-2.8 2.1-3.4l15.2-5.8c1.7-.7 3.6.2 4.3 1.9.7 1.7-.2 3.6-1.9 4.3l-4.6 1.8 3.7.8c1.8.4 2.9 2.1 2.6 3.9-.4 1.5-1.8 2.6-3.4 2.6zm-51.2 0c-1.5 0-2.9-1.1-3.2-2.6-.4-1.8.8-3.6 2.6-3.9l3.7-.8-4.6-1.8c-1.7-.7-2.6-2.6-1.9-4.3.7-1.7 2.6-2.6 4.3-1.9l15.2 5.8c1.4.5 2.2 1.9 2.1 3.4-.1 1.5-1.2 2.7-2.6 3l-14.7 3.1h-.9z"
    />
    <path
      fill="#FFF"
      d="M83 90.5H39.7c-2.6 4-4.1 8.8-4.1 13.9l.1-.2c7.6-4.3 16.3-6.8 25.6-6.8s18.1 2.5 25.7 6.8l.1.1c0-5-1.5-9.8-4.1-13.8zM61.4 78.7c-8.4 0-15.8 4-20.5 10.2H82c-4.8-6.2-12.2-10.2-20.6-10.2z"
    />
    <path fill="#4D4D4D" d="M81.9 88.9H40.8c-.4.5-.8 1.1-1.1 1.6H83c-.3-.5-.7-1.1-1.1-1.6z" />
    <ellipse fill="#E5E5E4" cx={132.4} cy={136.6} rx={41.1} ry={4.4} />
    <circle fill="#FFC82B" cx={133.1} cy={83.2} r={53.4} />
    <path
      fill="#EEBB28"
      d="M166.2 41.4c7.2 9.1 11.5 20.6 11.5 33.1 0 29.5-23.9 53.4-53.4 53.4-12.5 0-24-4.3-33.1-11.5 9.8 12.4 24.9 20.3 41.9 20.3 29.5 0 53.4-23.9 53.4-53.4 0-17-8-32.2-20.3-41.9z"
    />
    <path
      fill="#4D4D4D"
      d="M146.1 73.4c-1.2 0-2.3-.4-3.2-1.3-1.8-1.8-1.8-4.7 0-6.5l.1-.1c5.5-5.5 14.5-5.5 20 0 1.8 1.8 1.8 4.7 0 6.5-1.8 1.8-4.7 1.8-6.5 0-1.9-1.9-5.1-1.9-7.1 0l-.1.1c-.9.8-2.1 1.3-3.2 1.3zm-39.6 0c-1.2 0-2.3-.4-3.2-1.3-1.8-1.8-1.8-4.7 0-6.5l.1-.1c5.5-5.5 14.5-5.5 20 0 1.8 1.8 1.8 4.7 0 6.5-1.8 1.8-4.7 1.8-6.5 0-1.9-1.9-5.1-1.9-7.1 0l-.1.1c-.9.8-2 1.3-3.2 1.3zm-1.6 12.5c8.3 4.8 17.9 7.5 28.2 7.5s19.9-2.7 28.2-7.5l.1-.2c0 15.6-12.7 28.3-28.3 28.3s-28.3-12.7-28.3-28.3l.1.2z"
    />
    <path
      fill="#EF5B4B"
      d="M147.8 110c-4.3-2.6-9.3-4.1-14.7-4.1s-10.4 1.5-14.7 4.1c4.3 2.6 9.3 4.1 14.7 4.1s10.4-1.5 14.7-4.1z"
    />
    <path
      fill="#FFF"
      d="M104.8 85.9c6.1 7.7 16.5 12.8 28.3 12.8s22.2-5.1 28.3-12.8c-8.6 3.3-18.2 5.2-28.3 5.2-10.1-.1-19.7-1.9-28.3-5.2z"
    />
  </svg>
);

export default MoodIcon;
