import { ActionEvent } from '../types';
import { DomainValidationResponse } from './domain.models';

export enum DOMAIN_ACTION_TYPES {
  DOMAIN_FETCH_FAILED = 'DOMAIN_FETCH_FAILED',
  DOMAIN_FETCH_LOADING = 'DOMAIN_FETCH_LOADING',
  DOMAIN_FETCH_VALIDATED = 'DOMAIN_FETCH_VALIDATED',
  DOMAIN_FETCH_CORRECTED = 'DOMAIN_FETCH_CORRECTED',
}

export type DomainFetchLoadingAction = ActionEvent<DOMAIN_ACTION_TYPES.DOMAIN_FETCH_LOADING>;

export type DomainFetchCorrectedAction = Required<
  ActionEvent<DOMAIN_ACTION_TYPES.DOMAIN_FETCH_CORRECTED, DomainValidationResponse>
>;

export type DomainFetchValidatedAction = Required<ActionEvent<DOMAIN_ACTION_TYPES.DOMAIN_FETCH_VALIDATED>>;

export type DomainFetchFailedAction = Required<ActionEvent<DOMAIN_ACTION_TYPES.DOMAIN_FETCH_FAILED, { error: Error }>>;

export type DomainActions =
  | DomainFetchLoadingAction
  | DomainFetchCorrectedAction
  | DomainFetchValidatedAction
  | DomainFetchFailedAction;
