import { useNps, useSafeDispatch, useScrollToTop, i18nConfig } from '@health-activity-ui/core';
import {
  AdobeAppDataKeys,
  callGetProductEligibility,
  determineSubnav,
  resetBanners,
  updateEngageUserProfile,
  updateProfile,
  updateUiConfig,
  useAuthentication,
  useAuthorization,
  useCommon,
  useDomain,
  useEngageUserProfile,
  useProfile,
  UserPropertyKeys,
  useUiConfig,
  validateDomain,
} from '@health-activity-ui/data-access';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AcLoader, AsyncComponent } from '@health-activity-ui/ui-react';
import {
  appConfig,
  CHROME_UNAUTHORIZED_ROUTES,
  LOCALE,
  Status,
  CDN_RALLY_PROD_URL,
  CDN_RALLY_NON_PROD_URL,
} from '@health-activity-ui/shared';
import { loadScriptToHead, IS_BS, IS_PROD } from '@health-activity-ui/utils';
import { AppDataKeys, setAppData } from '@rally/analytics';
import { amplitude } from 'amplitude-js';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import Cookies from 'js-cookie';
import { lazy, ReactElement, Suspense, useEffect, useLayoutEffect } from 'react';
import { initReactI18next } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const TryAgain = lazy(
  () =>
    // eslint-disable-next-line @nx/enforce-module-boundaries
    import(
      /*
        webpackChunkName: 'TryAgain',
        webpackPrefetch: true
      */ '../../../../libs/ui-react/src/errors/try-again.component'
    )
);

const AppRoutes = lazy(
  () =>
    // eslint-disable-next-line @nx/enforce-module-boundaries
    import(
      /*
        webpackChunkName: 'AppRoutes',
        webpackPrefetch: true
      */ './app-routes'
    )
);

const ChatBox = lazy(
  () =>
    // eslint-disable-next-line @nx/enforce-module-boundaries
    import(
      /*
        webpackChunkName: 'Chatbox',
        webpackPrefetch: true
      */ '../../../../libs/ui-react/src/chat-box/chat-box'
    )
);

const HeaderFooter = lazy(
  () =>
    // eslint-disable-next-line @nx/enforce-module-boundaries
    import(
      /*
        webpackChunkName: 'HeaderFooter',
        webpackPrefetch: true
      */ '../../../../libs/ui-react/src/header-footer/header-footer'
    )
);

const Banners = lazy(
  () =>
    // eslint-disable-next-line @nx/enforce-module-boundaries
    import(
      /*
        webpackChunkName: 'Banners',
        webpackPrefetch: true
      */ '../../../../libs/ui-react/src/banners/banners.component'
    )
);

const refreshPage = (): void => {
  window.location.reload();
};

/**
 * On `onUnload` flush storage to reset the UI experience of the current user.
 * If a second user logs in with the same tab, this guarantees that the second
 * user will see the UI experience intended for them, and the UI experience from the first user.
 */
const onUnload = (): void => {
  // DO NOT preventDefault otherwise IE11 and Firefox will prompt the user if they attempt to leave the page
  sessionStorage.removeItem('whiteLabel');
};

export const App = (): ReactElement => {
  const location = useLocation();
  const { isAuthenticated } = useAuthentication();
  const { dispatch: dispatchDomain } = useDomain();
  const { dispatch: dispatchProfile, state: profile } = useProfile();
  const { dispatch: dispatchUiConfig, state: uiConfig } = useUiConfig();
  const { dispatch: dispatchAuthorization } = useAuthorization();
  const { dispatch: commonDispatch } = useCommon();
  const { dispatch: dispatchEngageUserProfile } = useEngageUserProfile();
  const { npsStatus, loadFeedbackComponent } = useNps();
  const safeDispatchAuthorization = useSafeDispatch(dispatchAuthorization);
  useScrollToTop(location);
  const isBcbsnc = profile?.data?.partner?.toLowerCase() === 'bcbs_north_carolina';

  useEffect(() => {
    if (profile?.status === Status.loaded) {
      const srcUrl = isBcbsnc
        ? appConfig.HAUI_APP_HA_ADOBE_BCBSNC_ANALYTICS_URL
        : appConfig.HAUI_APP_HA_ADOBE_ANALYTICS_URL;
      loadScriptToHead({ src: srcUrl, id: 'adobe' });
      return () => document.querySelector(`script[src="${srcUrl}"]`)?.remove();
    }
  }, [profile.status, isBcbsnc]);

  useEffect(() => {
    (async () => {
      const locale = Cookies.get(LOCALE.COOKIE);
      const i18nPromise = i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init(i18nConfig);

      if (isAuthenticated) {
        const domainPromise = validateDomain(dispatchDomain);
        const engageUserProfilePromise = updateEngageUserProfile(dispatchEngageUserProfile);
        const profile = await updateProfile(dispatchProfile);

        await Promise.all([
          i18nPromise,
          domainPromise,
          updateUiConfig(dispatchUiConfig, locale || LOCALE.EN_US),
          callGetProductEligibility(safeDispatchAuthorization, profile.rallyId, profile.partner, profile.client),
          engageUserProfilePromise,
          loadScriptToHead({
            src: `${appConfig.HAUI_APP_HA_UNIFIED_DOMAIN_API_URL}/chat/chatter-box/chatter-box.esm.js`,
            id: 'chatterbox-ui',
            type: 'module',
          }),
        ]);
      }
    })();
  }, [
    dispatchDomain,
    dispatchEngageUserProfile,
    dispatchProfile,
    dispatchUiConfig,
    isAuthenticated,
    safeDispatchAuthorization,
  ]);

  useEffect(() => {
    if (profile?.data.rallyId) {
      // set appData for analytics
      const appData = {
        [AppDataKeys.AppUserId]: profile?.data.rallyId,
        [AdobeAppDataKeys.division]: 'optum',
        [AdobeAppDataKeys.entity]: 'rally',
        [AdobeAppDataKeys.digitalAsset]: 'werally',
        [AdobeAppDataKeys.siteSectionL1]: 'secure',
        [AdobeAppDataKeys.siteSectionL2]: 'healthactivity',
        // set userProperties for Adobe
        user: {
          rallyClient: profile?.data.client,
          rallyPartner: profile?.data.partner,
          rallyId: profile.data.rallyId,
          loginStatus: 'logged in',
        },
      };

      setAppData(appData);

      // set userProperties for Amplitude
      const amplitudeClient = amplitude?.getInstance();
      amplitudeClient?.setUserProperties({
        [UserPropertyKeys.Product]: 'WRLY',
        [UserPropertyKeys.Authenticated]: profile.status === Status.loaded,
        [UserPropertyKeys.RallyPartner]: profile?.data.partner,
        [UserPropertyKeys.ClientName]: profile?.data.client,
      });
    }
  }, [profile?.data.client, profile?.data.partner, profile?.data.rallyId, profile.status]);

  // We want to update the dom with the subnav, before its actually painted to the screen, which is what useLayoutEffect provides
  // See React Hook Flow: https://raw.githubusercontent.com/donavon/hook-flow/master/hook-flow.png
  // this should allow that there aren't any flickering or movement of the subnav area when the subnav is finally painted
  // a good rule of thumb is to use useLayoutEffect if a side effect makes an observable change to the dom or need to perform measurements i.e. scrolling, loading more
  useLayoutEffect(() => {
    if (uiConfig.status === Status.loaded) {
      determineSubnav(dispatchUiConfig, location.pathname);
      commonDispatch(resetBanners);
      // Preload image for LCP
      new Image().src = `${
        IS_BS() || IS_PROD() ? CDN_RALLY_PROD_URL : CDN_RALLY_NON_PROD_URL
      }/campaign/engage/pod/hero-21.png`;
    }
  }, [location.pathname, dispatchUiConfig, commonDispatch, uiConfig.status]);

  useEffect(() => {
    window.addEventListener('beforeunload', onUnload);

    return (): void => {
      window.removeEventListener('beforeunload', onUnload);
    };
  }, []);

  return (
    <AsyncComponent
      status={uiConfig.status || profile.status}
      shouldShowError
      errorComponent={<TryAgain refreshPage={refreshPage} />}
    >
      <HeaderFooter hasNotification={false} chromeUa={CHROME_UNAUTHORIZED_ROUTES.includes(location.pathname)}>
        <Banners />
        <main id="main-content" role="main">
          {/*For keyboard navigation nav bar skipping only*/}
          <div id="main-content-key-nav" tabIndex={-1} aria-hidden="true" style={{ height: '1px' }}></div>
          {/*
            Add React Suspense so loading state only renders for the content/bundle
            that needs to be fetched. Helps avoid flickering of the entire page.
          */}
          <Suspense fallback={<AcLoader />}>
            <AppRoutes />
          </Suspense>
        </main>
      </HeaderFooter>
      <ChatBox baseUrl={appConfig.HAUI_APP_HA_CHAT_URL} />
      {npsStatus === Status.loaded && loadFeedbackComponent}
    </AsyncComponent>
  );
};

export default App;
