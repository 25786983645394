import styled from '@emotion/styled';
import { debounce } from '@health-activity-ui/utils';
import { RdsIcon } from '@rally/energon-react';
import {
  RDS_BORDER_RADIUS_4,
  RDS_COLOR_SYSTEM_GRENADIER_DARK,
  RDS_COLOR_SYSTEM_GRENADIER_LIGHT,
  RDS_SPACING_SIZE_16,
  RDS_SPACING_SIZE_8,
} from '@rally/ui-themes/dist/web/rally';
import classnames from 'classnames';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { get, useFormContext } from 'react-hook-form';
import { ErrorMessageProps } from './forms.interface';

interface ErrorStyledProps {
  errorWidgetTop: number;
}

const ErrorStyled = styled.div<ErrorStyledProps>`
  .error-message {
    color: ${RDS_COLOR_SYSTEM_GRENADIER_DARK} !important;
    min-width: 200px;
  }

  .error-widget {
    border-radius: ${RDS_BORDER_RADIUS_4};
    margin-top: ${RDS_SPACING_SIZE_8};
    max-width: 384px;
    opacity: 1;
    padding: ${RDS_SPACING_SIZE_8} ${RDS_SPACING_SIZE_16};
    pointer-events: none;
    position: absolute;
    top: ${({ errorWidgetTop }) => errorWidgetTop}px;
    transition: opacity 750ms ease-out;
    z-index: 3;

    &.is-hidden {
      opacity: 0;
      visibility: hidden;
    }

    &:before {
      border-bottom: ${RDS_SPACING_SIZE_8} solid ${RDS_COLOR_SYSTEM_GRENADIER_LIGHT};
      border-left: ${RDS_SPACING_SIZE_8} solid transparent;
      border-right: ${RDS_SPACING_SIZE_8} solid transparent;
      content: '';
      left: ${RDS_SPACING_SIZE_16};
      margin-left: -${RDS_SPACING_SIZE_8};
      position: absolute;
      top: -${RDS_SPACING_SIZE_8};
      z-index: 0;
    }
  }
`;

const defaultErrorMessages = {
  required: 'is required.',
  pattern: 'is not formatted correctly.',
  minLength: 'is too short.',
  maxLength: 'is too long.',
  min: 'is too low.',
  max: 'is too high.',
  validate: 'is not valid.',
};

const isHidden: React.CSSProperties = {
  opacity: 0,
  visibility: 'hidden',
};

export const ErrorMessage = ({
  name,
  style: propStyle,
  errorDisplayType = 'inline',
  className = '',
  ...rest
}: ErrorMessageProps) => {
  const {
    formState: { errors },
  } = useFormContext();

  const ref = useRef(null);
  const [errorWidgetTop, setErrorWidgetTop] = useState(0);
  const offsetTop = ref?.current?.offsetHeight + ref?.current?.offsetTop;
  const errorWidgetId = `error-widget-${name}`;

  const validationError = get(errors, name);
  const errorMessage =
    validationError && (validationError.message || `${name} ${defaultErrorMessages[validationError.type]}`);

  const style = validationError ? propStyle : { ...propStyle, ...isHidden };

  useLayoutEffect(() => {
    const updateSize = (): void => {
      setErrorWidgetTop(offsetTop);
    };

    window.addEventListener('resize', debounce(updateSize, 250));
    return (): void => window.removeEventListener('resize', updateSize);
  }, [offsetTop]);

  useEffect(() => {
    setErrorWidgetTop(offsetTop);
  }, [offsetTop]);

  return (
    <ErrorStyled errorWidgetTop={errorWidgetTop ?? 0} ref={ref}>
      {errorDisplayType === 'inline' && (
        <span
          style={style}
          className={classnames(className, 'is-rds-error error-message is-rds-body-1 is-flex is-align-items-center')}
          aria-hidden={!validationError}
          {...rest}
        >
          {validationError ? (
            <>
              <RdsIcon name="system-info-circle" size="16" className="stat-icon has-rds-mr-8 is-fill" />
              {errorMessage}
            </>
          ) : (
            'Error'
          )}
        </span>
      )}

      {errorDisplayType === 'tooltip' && (
        <div
          style={style}
          data-testid="errorWidget"
          {...rest}
          id={errorWidgetId}
          className={classnames(
            'error-widget is-rds-body-2 has-rds-text-danger has-rds-bg-danger has-rds-radius-4 has-rds-p-8 is-flex is-justify-content-center',
            className,
            {
              'is-hidden': !validationError,
            }
          )}
        >
          {errorMessage}
        </div>
      )}
    </ErrorStyled>
  );
};
