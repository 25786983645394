import React, {
  createContext,
  Dispatch,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useReducer,
} from 'react';
import { AnyAction } from 'redux';
import { useAsync } from '../hooks/use-async';
import { intersectionOptions } from '@health-activity-ui/utils';
import { TrackingData, TrackingState } from './tracking.models';
import trackingStateReducer from './tracking.reducer';
import * as trackingActions from './tracking.actions';
import { ICampaignPlacement } from '@health-activity-ui/shared';
import { sendTrackEvent, useProfile, ProfileState } from '@health-activity-ui/data-access';

const TrackingContext = createContext<{ state: TrackingState; dispatch: Dispatch<AnyAction>; profile: ProfileState }>(
  undefined
);
TrackingContext.displayName = 'TrackingContext';

export const initialTrackingState = {
  clicks: [],
};

function useTracking(elementRef?: RefObject<Element>, campaign?: ICampaignPlacement, options = intersectionOptions) {
  const context = useContext(TrackingContext);
  const observer = useRef(null);

  if (!context) {
    throw new Error(`useTracking must be used within the TrackingProvider`);
  }
  const { run } = useAsync();

  const updateEntry = useCallback(
    (entries) => {
      if (!entries || !campaign) return;

      const trackingData = {
        event: 'impression',
        campaign,
        rallyId: context.profile?.data?.rallyId,
        sessionId: context.profile?.data?.sessionId,
      };

      const [entry] = entries;
      if (entry.isIntersecting) {
        sendTracking(trackingData, run, context.dispatch);
        observer.current.unobserve(elementRef?.current);
      }
    },
    [campaign, context.dispatch, context.profile?.data?.rallyId, context.profile?.data?.sessionId, elementRef, run]
  );

  useEffect(() => {
    observer.current = new IntersectionObserver((entries) => {
      updateEntry(entries);
    }, options);

    const { current: currentObserver } = observer;

    if (elementRef?.current) {
      currentObserver.observe(elementRef?.current);
    }

    return (): void => currentObserver.disconnect();
  }, [elementRef, options, updateEntry]);

  return context;
}

function TrackingProvider({ children }) {
  const { state: profile } = useProfile();
  const [state, dispatch] = useReducer(trackingStateReducer, initialTrackingState);

  const value = { state, dispatch, profile };

  return <TrackingContext.Provider value={value}>{children}</TrackingContext.Provider>;
}

/**
 * @method sendTracking
 * @description sends tracking info for campaign clicks and impressions
 * @param {string} event click or impression
 * @param {ICampaignPlacement} campaign campaign response
 * @param {TrackingState} state campaign response
 * @return {Promise<boolean>} whether tracking was sent or not
 */
async function sendTracking(
  trackingData: TrackingData,
  run: (promise: Promise<void>) => void,
  dispatch: Dispatch<AnyAction>
): Promise<void> {
  if (trackingData.event === 'click') {
    run(
      sendTrackEvent(
        trackingData.campaign.campaignId,
        trackingData.event,
        trackingData.campaign.placement.placementType,
        trackingData.campaign,
        trackingData.rallyId,
        trackingData.sessionId
      )
        .then(() => {
          dispatch(trackingActions.trackClick);
        })
        .catch((e) => console.error(e))
    );
  } else if (trackingData.event === 'impression') {
    run(
      sendTrackEvent(
        trackingData.campaign.campaignId,
        trackingData.event,
        trackingData.campaign.placement.placementType,
        trackingData.campaign,
        trackingData.rallyId,
        trackingData.sessionId
      )
        .then(() => null)
        .catch((e) => console.error(e))
    );
  }
}

export { TrackingProvider, useTracking, sendTracking };
