import {
  ActivityChallenge,
  ActivityMission,
  appConfig,
  IChallengeDetails,
  IGalleryChallenge,
  SESSION,
} from '@health-activity-ui/shared';
import { getChallengeType, getMissionCategoryAsAmplitudeString } from '@health-activity-ui/utils';
import {
  AppDataKeys,
  AppPlatform,
  captureEventTrigger,
  ConfigurationKeys,
  initAnalytics,
  logCustomUserEvent,
  logExternalPageNav,
  logModalPageNav,
  logPageNav,
  SupportedDestinationKeys,
  TriggerType,
} from '@rally/analytics';
import { AdditionalData, PageArgs } from '@rally/analytics/dist/models/events';
import amplitude, { Callback as AmplitudeCallback } from 'amplitude-js';
import Cookies from 'js-cookie';
import { initAmplitude } from './amplitude';
import { Environments } from './analytics.models';

const baseDestinationConfig = {
  // Logs events to console
  [ConfigurationKeys.Debug]: true,
  [ConfigurationKeys.Include]: true,
};

const amplitudeDestination = {
  [SupportedDestinationKeys.Amplitude]: {
    ...baseDestinationConfig,
    [ConfigurationKeys.Sdk]: amplitude,
  },
};

const adobeDestination = {
  [SupportedDestinationKeys.Adobe]: {
    ...baseDestinationConfig,
    [ConfigurationKeys.Sdk]: {},
  },
};

const supportedDestinations = { ...amplitudeDestination, ...adobeDestination };

function createCampaignClickData(campaignName: string) {
  return { 'Campaign Name': campaignName };
}

function createChallengeJoinData(
  challenge: IChallengeDetails | IGalleryChallenge | ActivityChallenge,
  challengeType?: string
) {
  challengeType = challengeType
    ? challengeType
    : getChallengeType(challenge.isTeam, challenge.isUserCreated, challenge.isInviteOnly);
  return {
    'Challenge Type': challengeType,
    'Challenge Category': challenge.category,
    'Challenge Activity': challenge.activityType,
    'Challenge SubType': challenge.isCityWalk ? 'CityWalk' : 'none',
  };
}

function createMissionJoinData(activity: ActivityMission) {
  return {
    'Mission Category': activity.filters.map((filter) => getMissionCategoryAsAmplitudeString(filter)),
    'Mission Checkin Type': activity.checkInType,
    'Mission Pack': activity.isFocused,
  };
}

function initAppAnalytics(amplitudeKey = appConfig.HAUI_APP_HA_AMPLITUDE_KEY): void {
  const sessionId = Cookies.get(SESSION.COOKIE.FS);

  try {
    initAmplitude(amplitudeKey);
    initAnalytics(
      {
        ...supportedDestinations,
        [ConfigurationKeys.GlobalBlocklist]: ['secret', 'token'],
        [ConfigurationKeys.Pillar]: 'Engagement',
      },
      {
        [AppDataKeys.AppName]: 'health-activity-ui',
        [AppDataKeys.AppVersion]: appConfig.HAUI_APP_HA_VERSION || '',
        [AppDataKeys.AppPlatform]: AppPlatform.Web,
        [AppDataKeys.AppSessionId]: sessionId || '',
        [AppDataKeys.AppSessionIdIssuer]: 'lwr',
        [AppDataKeys.AppUserIdAuthority]: 'rally',
        [AppDataKeys.AppSessionType]: sessionId ? 'Member' : '',
        [AppDataKeys.AppUserIdType]: 'rallyId',
        [AppDataKeys.Environment]: Environments[appConfig.HAUI_APP_HA_ENVIRONMENT],
        [AppDataKeys.Product]: 'Engage',
      }
    );
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Error Initializing Analytics: ', err);
  }
}

// Use this event ONLY for click events that do not trigger full page reloads.
function setEventTriggerProperties(
  event: Event,
  explicitTriggerProps?: { elementName: string; sectionName: string[] }
): void {
  captureEventTrigger(event, explicitTriggerProps);
}

function trackCustomEvent(
  actionName: string,
  additionalData?: AdditionalData,
  explicitTriggerType?: TriggerType,
  cb?: AmplitudeCallback
): void {
  logCustomUserEvent(actionName, additionalData, explicitTriggerType, cb);
}

function trackExternalNav(externalUrl: string, additionalData?: AdditionalData, cb?: AmplitudeCallback): void {
  logExternalPageNav(externalUrl, additionalData, cb);
}

function trackModalPageNav(
  pageName: string,
  pageTags?: string[],
  pageArgs?: PageArgs,
  additionalData?: AdditionalData,
  cb?: AmplitudeCallback
): void {
  logModalPageNav(pageName, pageTags, pageArgs, additionalData, cb);
}

function trackPageNav(
  pageName: string,
  pageTags?: string[],
  pageArgs?: PageArgs,
  additionalData?: AdditionalData,
  cb?: AmplitudeCallback
): void {
  logPageNav(pageName, pageTags, pageArgs, additionalData, cb);
}

export {
  createCampaignClickData,
  createChallengeJoinData,
  createMissionJoinData,
  initAppAnalytics,
  setEventTriggerProperties,
  trackCustomEvent,
  trackExternalNav,
  trackModalPageNav,
  trackPageNav,
};
