import React from 'react';

export const PrivateChallengeLockCircleIcon = (): React.ReactElement => (
  <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
    <g fill="none" fillRule="evenodd">
      <circle fill="#7F7F7F" cx="8" cy="8" r="8" />
      <g fill="#FFF">
        <path d="M7.812 3.989c-1.044 0-1.893.725-1.893 1.617v2.61c0 .893.849 1.618 1.893 1.618s1.894-.725 1.894-1.617V5.606c0-.892-.85-1.617-1.894-1.617m0 6.834c-1.682 0-3.051-1.17-3.051-2.606V5.606C4.761 4.169 6.13 3 7.812 3c1.683 0 3.051 1.17 3.051 2.606v2.61c0 1.438-1.368 2.607-3.05 2.607" />
        <path d="M9.812 3.64c.466.458.75 1.055.75 1.708v2.61c0 1.438-1.37 2.607-3.052 2.607a3.353 3.353 0 01-2-.64c.56.55 1.384.898 2.302.898 1.683 0 3.051-1.17 3.051-2.606V5.606c0-.784-.408-1.488-1.051-1.966" />
        <path d="M9.706 8.217c0 .892-.85 1.617-1.894 1.617S5.92 9.11 5.92 8.217v-2.61c0-.893.849-1.618 1.893-1.618s1.894.725 1.894 1.617v2.61zM7.812 3.679c-1.244 0-2.256.865-2.256 1.927v2.61c0 1.063 1.012 1.928 2.256 1.928 1.244 0 1.904-.865 1.904-1.927v-2.61c0-1.063-.66-1.928-1.904-1.928z" />
        <path d="M11.024 11.976H4.587c-.324 0-.587-.224-.587-.5v-4.12c0-.276.263-.5.587-.5h6.437c.324 0 .586.224.586.5v4.12c0 .276-.262.5-.586.5" />
        <path d="M11.61 11.729c0 .136-.13.247-.29.247H4.364l6.217-.232c.29-.022.524-.213.564-.46l.466-3.902v4.347z" />
        <path d="M4 7.69h7.61v-.309H4zM4 8.337h7.61v-.31H4zM4 8.983h7.61v-.31H4zM4 9.63h7.61v-.31H4zM4 10.275h7.61v-.309H4zM4 10.921h7.61v-.309H4zM4 11.568h7.61v-.31H4z" />
      </g>
    </g>
  </svg>
);

export default PrivateChallengeLockCircleIcon;
