import {
  COMMUNITY_BASE_URI,
  CONNECTION_BASE_URI,
  CreateDiscussionArgs,
  IAllCommunitiesRes,
  ICommunity,
  IDiscussionItem,
  IDiscussionReply,
  LikeResponse,
  Connection,
  Thread,
  MESSAGES_BASE_URI,
} from '@health-activity-ui/shared';
import apiClient from './api-client';

/**
 * @method getCommunities
 *
 * @description Returns all communities that the current user (identified by the X-Rally-Session-Token header)
 * has access to, broken up into recommended communities and all others.
 *
 * @return {IAllCommunitiesRes[]}
 * */
export const getCommunities = async (): Promise<IAllCommunitiesRes> => {
  const response = await apiClient<IAllCommunitiesRes>(`${COMMUNITY_BASE_URI}/community`);

  return response.data;
};

export const getCommunity = async (communityId: string): Promise<ICommunity> => {
  const response = await apiClient<ICommunity>(`${COMMUNITY_BASE_URI}/community/${communityId}`);

  return response.data;
};

export const followCommunity = async (
  communityId: string
): Promise<{
  success: boolean;
}> => {
  const response = await apiClient<{
    success: boolean;
  }>(`${COMMUNITY_BASE_URI}/community/${communityId}/follow`, {
    method: 'POST',
    data: {}, // Pass empty object to data, else the request will fail and return "415 Unsupported Media Type client error".
  });

  return response.data;
};

export const unfollowCommunity = async (
  communityId: string
): Promise<{
  success: boolean;
}> => {
  const response = await apiClient<{
    success: boolean;
  }>(`${COMMUNITY_BASE_URI}/community/${communityId}/unfollow`, {
    method: 'POST',
  });

  return response.data;
};

export const getCommunityDiscussions = async (
  communityId: string,
  limit = 10,
  skip = 0
): Promise<IDiscussionItem[]> => {
  const response = await apiClient<IDiscussionItem[]>(
    `${COMMUNITY_BASE_URI}/community/${communityId}/discussion?limit=${limit}&skip=${skip}`
  );

  return response.data;
};

export const getCommunityDiscussion = async (discussionId: string): Promise<IDiscussionItem> => {
  const response = await apiClient<IDiscussionItem>(`${COMMUNITY_BASE_URI}/community/discussion/${discussionId}`);

  return response.data;
};

export const createDiscussion = async ({
  communityId,
  title,
  discussionText,
}: CreateDiscussionArgs): Promise<IDiscussionItem> => {
  const response = await apiClient<IDiscussionItem>(`${COMMUNITY_BASE_URI}/community/${communityId}/discussion`, {
    method: 'POST',
    data: {
      communityId,
      title,
      discussionText,
    },
  });

  return response.data;
};

export const likeDiscussion = async (discussionId: string): Promise<LikeResponse> => {
  const response = await apiClient<LikeResponse>(`${COMMUNITY_BASE_URI}/community/discussion/${discussionId}/like`, {
    method: 'POST',
    data: {},
  });

  return response.data;
};

export const likeReply = async (discussionId: string, replyId: string): Promise<LikeResponse> => {
  const response = await apiClient<LikeResponse>(
    `${COMMUNITY_BASE_URI}/community/discussion/${discussionId}/reply/${replyId}/like`,
    {
      method: 'POST',
      data: {},
    }
  );

  return response.data;
};

export const replyToDiscussion = async (discussionId: string, replyText: string): Promise<IDiscussionReply> => {
  const response = await apiClient<IDiscussionReply>(
    `${COMMUNITY_BASE_URI}/community/discussion/${discussionId}/reply`,
    {
      method: 'POST',
      data: {
        replyText,
      },
    }
  );

  return response.data;
};

export const deleteDiscussion = async (
  discussionId: string
): Promise<{
  success: boolean;
}> => {
  const response = await apiClient<{
    success: boolean;
  }>(`${COMMUNITY_BASE_URI}/community/discussion/${discussionId}/remove`, {
    method: 'POST',
  });

  return response.data;
};

export const deleteReply = async (
  discussionId: string,
  commentId: string
): Promise<{
  success: boolean;
}> => {
  const response = await apiClient<{
    success: boolean;
  }>(`${COMMUNITY_BASE_URI}/community/discussion/${discussionId}/reply/${commentId}/remove`, {
    method: 'POST',
  });

  return response.data;
};

export const flagDiscussion = async (
  discussionId: string
): Promise<{
  success: boolean;
}> => {
  const response = await apiClient<{
    success: boolean;
  }>(`${COMMUNITY_BASE_URI}/community/discussion/${discussionId}/flag`, {
    method: 'POST',
  });

  return response.data;
};

export const flagReply = async (
  discussionId: string,
  commentId: string
): Promise<{
  success: boolean;
}> => {
  const response = await apiClient<{
    success: boolean;
  }>(`${COMMUNITY_BASE_URI}/community/discussion/${discussionId}/reply/${commentId}/flag`, {
    method: 'POST',
  });

  return response.data;
};

/**
 * @method getConnections
 *
 * @description Returns all Connections that the current user
 *
 * @return {Connection[]}
 * */
export const getConnections = async (): Promise<Connection[]> => {
  const response = await apiClient<Connection[]>(`${CONNECTION_BASE_URI}`);
  return response.data;
};

/**
 * @method getConnectionsByUserId
 *
 * @description Returns all Connections that the current user
 *
 * @return {Connection[]}
 * */
export const getConnectionsByUserId = async (userId): Promise<Connection[]> => {
  const response = await apiClient<Connection[]>(`${CONNECTION_BASE_URI}/${userId}`);

  return response.data;
};

/**
 * @method getSharedConnections
 *
 * @description Returns all Connections that the current user
 *
 * @return {Connection[]}
 * */
export const getSharedConnections = async (userId): Promise<Connection[]> => {
  const response = await apiClient<Connection[]>(`${CONNECTION_BASE_URI}/shared/${userId}`);

  return response.data;
};

export const connect = async (userId: string): Promise<boolean> => {
  const response = await apiClient<{
    success: boolean;
  }>(`${CONNECTION_BASE_URI}/connect/${userId}`, {
    method: 'POST',
  });

  const { success } = response.data;
  return success;
};

export const cancel = async (userId: string): Promise<boolean> => {
  const response = await apiClient<{
    success: boolean;
  }>(`${CONNECTION_BASE_URI}/cancel/${userId}`, {
    method: 'POST',
  });

  const { success } = response.data;
  return success;
};

export const disconnect = async (userId: string): Promise<boolean> => {
  const response = await apiClient<{
    success: boolean;
  }>(`${CONNECTION_BASE_URI}/disconnect/${userId}`, {
    method: 'POST',
  });

  const { success } = response.data;
  return success;
};

export const accept = async (userId: string): Promise<boolean> => {
  const response = await apiClient<{
    success: boolean;
  }>(`${CONNECTION_BASE_URI}/accept/${userId}`, {
    method: 'POST',
  });

  const { success } = response.data;
  return success;
};

export const deny = async (userId: string): Promise<boolean> => {
  const response = await apiClient<{
    success: boolean;
  }>(`${CONNECTION_BASE_URI}/deny/${userId}`, {
    method: 'POST',
  });

  const { success } = response.data;
  return success;
};

/*
 * Messages
 */

/*
 * Because of how this API is setup we can not pass 'params' how axios expects in a params object:
 * apiClient('url', { params })
 * @param {number} skip optional query param for number to skip
 * @param {number} limit optional query param to limit the responses
 */
export const getAllThreads = async (limit = 10, offset = 0): Promise<Thread[]> => {
  const response = await apiClient<Thread[]>(
    `${MESSAGES_BASE_URI}/threads/?threadType=all&offset=${offset}&limit=${limit}`
  );
  return response.data;
};

/*
 * Because of how this API is setup we can not pass 'params' how axios expects in a params object:
 * apiClient('url', { params })
 * @param {number} skip optional query param for number to skip
 * @param {number} limit optional query param to limit the responses
 */
export const getArchivedThreads = async (limit = 10, offset = 0): Promise<Thread[]> => {
  const response = await apiClient<Thread[]>(
    `${MESSAGES_BASE_URI}/threads/?threadType=archived&offset=${offset}&limit=${limit}`
  );
  return response.data;
};

/*
 * Because of how this API is setup we can not pass 'params' how axios expects in a params object:
 * apiClient('url', { params })
 * @param {string} id of the thread
 * @param {number} skip optional query param for number of messages in the thread to skip
 * @param {number} limit optional query param to limit the messages in the thread
 */
export const getThread = async (threadId: string, limit = 10, offset = 0): Promise<Thread> => {
  const response = await apiClient<Thread>(`${MESSAGES_BASE_URI}/threads/${threadId}/?offset=${offset}&limit=${limit}`);
  return response.data;
};

/*
 * Because of how this API is setup we can not pass 'params' how axios expects in a params object:
 * apiClient('url', { params })
 * @param {string} id of the thread
 * @param {string} message to send
 */
export const reply = async (threadId: string, message: string): Promise<boolean> => {
  const data = { body: message };
  const response = await apiClient<{
    success: boolean;
  }>(`${MESSAGES_BASE_URI}/threads/${threadId}/`, { method: 'POST', data });

  const { success } = response.data;
  return success;
};

/*
 * Archive messages
 * @param {threadId} threadId to archive thread message
 */
export const archiveMessageThread = async (threadId: string): Promise<boolean> => {
  const response = await apiClient<{
    success: boolean;
  }>(`${MESSAGES_BASE_URI}/threads/${threadId}/archive`, {
    method: 'POST',
  });

  const { success } = response.data;
  return success;
};

/*
 * UnArchive messages
 * @param {threadId} threadId to archive thread message
 */
export const unArchiveMessageThread = async (threadId: string): Promise<boolean> => {
  const response = await apiClient<{
    success: boolean;
  }>(`${MESSAGES_BASE_URI}/threads/${threadId}/unarchive`, {
    method: 'POST',
  });

  const { success } = response.data;
  return success;
};

/*
 * Creates new thread
 * @param {string} message to send
 * @param {string[]} recipients
 */
export const createThread = async (message: string, recipients: string[]): Promise<boolean> => {
  const data = { body: message, recipients };
  const response = await apiClient<{
    success: boolean;
  }>(`${MESSAGES_BASE_URI}/`, { method: 'POST', data });
  const { success } = response.data;
  return success;
};
