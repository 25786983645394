import { AppDataProperties, EnvironmentType } from '@rally/analytics';

// The keys in this enum are of the form injected by neptune in the appConfig.HAUI_APP_HA_ENVIRONMENT variable
export enum DomainEnvironments {
  bluesteel = 'bluesteel',
  dev = 'dev',
  int = 'int',
  integration = 'integration',
  local = 'local',
  prod = 'prod',
}

export enum PageNames {
  HealthActivityYourActivities = 'HealthActivityYourActivities',
  ChallengeDetails = 'ChallengeDetails',
  ChallengeDescription = 'ChallengeDescription',
  ChallengeGallery = 'ChallengeGallery',
  GoalSettingConfirmation = 'GoalSetting.SummaryConfirmation',
  GoalSettingSelectPlan = 'GoalSetting.SelectGoal',
  GoalSettingSelectGoal = 'GoalSetting.SetGoal.SelectInterestAreas',
  StayingWell = 'WellnessStayingWellCOVID-19',
}

export enum PageTags {
  HealthActivities = 'health-activities',
  Challenges = 'challenges',
}

export enum AnalyticsActionNames {
  Campaign = 'Campaign Name',
  ChallengeJoin = 'Challenge Join',
  ChallengeManualCheckin = 'Challenge Manual Checkin',
  ChallengeDeviceCheckin = 'Challenge Device Checkin',
  CommentReplyDiscussion = 'Comment/reply on a Discussion',
  FollowCommunity = 'Follow a Community',
  HealthActivityDetailsViewed = 'Health Activity Details Viewed',
  LikeDiscussion = 'Like a Discussion',
  LikeReply = 'Like a reply',
  MissionGoalSelected = 'Mission Goal Selected',
  ProgramGoalSelected = 'Program Goal Selected',
  StartNewDiscussion = 'Start a New Discussion',
  UnfollowCommunity = 'Community Unfollowed',
}

export enum CommunitiesEventProperties {
  CommunityId = 'Community ID',
  CommunityDisplayName = 'Community Display Name',
  CommunitiesInteractionType = 'Communities Interaction Type',
  DiscussionId = 'Discussion ID',
  ReplyId = 'Reply ID',
}

export enum AdditionalProperties {}

interface AnalyticsEnvironments {
  [DomainEnvironments.bluesteel]: EnvironmentType;
  [DomainEnvironments.dev]: EnvironmentType;
  [DomainEnvironments.int]: EnvironmentType;
  [DomainEnvironments.local]: EnvironmentType;
  [DomainEnvironments.prod]: EnvironmentType;
}

// We are mapping them to format required by rally/analytics and Amplitude.
export const Environments: AnalyticsEnvironments = {
  [DomainEnvironments.bluesteel]: EnvironmentType.Bs,
  [DomainEnvironments.dev]: EnvironmentType.Dev,
  [DomainEnvironments.int]: EnvironmentType.Int,
  [DomainEnvironments.local]: EnvironmentType.Local,
  [DomainEnvironments.prod]: EnvironmentType.Production,
};

export enum UserPropertyKeys {
  Product = 'Product',
  Authenticated = 'Authenticated',
  RallyPartner = 'Rally Partner',
  ClientName = 'Rally Client Name',
}

export interface UserProperties {
  [UserPropertyKeys.Product]: string;
  [UserPropertyKeys.Authenticated]: boolean;
  [UserPropertyKeys.RallyPartner]: string;
  [UserPropertyKeys.ClientName]: string;
}

export interface AdobeUserProperties {
  rallyClient?: string;
  rallyPartner?: string;
  rallyId?: string;
  loginStatus?: 'logged in' | 'not logged in';
}

export enum AdobeAppDataKeys {
  digitalAsset = 'digitalAsset',
  division = 'division',
  entity = 'entity',
  siteSectionL1 = 'siteSectionL1',
  siteSectionL2 = 'siteSectionL2',
  user = 'user',
}

export interface AppDataPropertiesWithAdobe extends AppDataProperties {
  [AdobeAppDataKeys.digitalAsset]: string;
  [AdobeAppDataKeys.division]: string;
  [AdobeAppDataKeys.entity]: string;
  [AdobeAppDataKeys.siteSectionL1]: string;
  [AdobeAppDataKeys.siteSectionL2]: string;
  [AdobeAppDataKeys.user]: AdobeUserProperties;
}
