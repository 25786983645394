import { useState, useEffect } from 'react';
import { Status } from '@health-activity-ui/shared';

/**
 * Dynamically load external scripts and trigger an event for when they are loaded
 * based off https://usehooks.com/useScript/
 */
export function useScript(src: string, id: string, loadScript = true): Status {
  const [status, setStatus] = useState(src ? Status.loading : Status.initial);

  useEffect(() => {
    // fail fast check for src
    if (!src) {
      setStatus(Status.initial);
      return;
    }

    let script: HTMLScriptElement;
    let setAttributeFromEvent;

    // check to see if script src already exists
    const resource: HTMLScriptElement = document.querySelector(`script[src="${src}"]`);

    if (!resource) {
      script = Object.assign(document.createElement('script'), {
        src,
        id,
        type: 'text/javascript',
        async: true,
        'data-status': Status.loading,
        onload: (e) => {
          setAttributeFromEvent(e);
        },
        onerror: (e) => {
          setAttributeFromEvent(e);
        },
      });

      // Add script to document body
      if (loadScript) {
        document.body.appendChild(script);

        // set the status onto the data-status attribute on the script tag
        setAttributeFromEvent = (event: Event) => {
          script.setAttribute('data-status', event.type === 'load' ? Status.loaded : Status.error);
        };
      }
    } else {
      // Grab existing script status from attribute and set to state.
      script = resource;
      setStatus(script.getAttribute('data-status') as Status);
    }

    // set the Status of the hook based on the event type
    const setStateFromEvent = (event: Event) => {
      setStatus(event.type === 'load' ? Status.loaded : Status.error);
    };

    // Add event listeners
    script.addEventListener('load', setStateFromEvent);
    script.addEventListener('error', setStateFromEvent);

    // Remove event listeners on cleanup
    return () => {
      if (script) {
        script.removeEventListener('load', setStateFromEvent);
        script.removeEventListener('error', setStateFromEvent);
      }
    };
  }, [id, loadScript, src]);
  return status;
}

export default useScript;
