import styled from '@emotion/styled';
import {
  RDS_COLOR_NEUTRAL_30,
  RDS_COLOR_NEUTRAL_80,
  RDS_COLOR_SYSTEM_CAMARONE,
  RDS_COLOR_SYSTEM_CAMARONE_LIGHT,
  RDS_COLOR_SYSTEM_CHARCOAL_LIGHT,
  RDS_COLOR_WHITE,
} from '@rally/ui-themes/dist/web/rally';

export const StepperStyled = styled.div`
  ol {
    align-items: center;
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .step {
    padding-right: 24px;
    position: relative;
  }

  .step-last {
    position: relative;
  }

  .stepper-circle {
    align-items: center;
    background: ${RDS_COLOR_WHITE};
    border-radius: 9999px;
    border-style: solid;
    border-width: 3px;
    display: flex;
    height: 40px;
    justify-content: center;
    position: relative;
    width: 40px;
  }

  .stepper-circle-complete {
    background: ${RDS_COLOR_SYSTEM_CAMARONE_LIGHT};
    border-color: ${RDS_COLOR_SYSTEM_CAMARONE};
  }

  .stepper-circle-current {
    background: ${RDS_COLOR_WHITE};
    border-color: ${RDS_COLOR_SYSTEM_CAMARONE};
  }

  .stepper-circle-current::after {
    border-bottom: 7px solid ${RDS_COLOR_SYSTEM_CAMARONE};
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    content: '';
    position: absolute;
    top: 40px;
  }

  .stepper-circle-upcoming {
    background: ${RDS_COLOR_WHITE};
    border-color: ${RDS_COLOR_NEUTRAL_80};
  }

  .stepper-circle-text {
    border-radius: 9999px;
  }

  .stepper-circle-text-complete,
  .stepper-circle-text-current {
    color: ${RDS_COLOR_NEUTRAL_30};
  }

  .stepper-circle-text-upcoming {
    color: ${RDS_COLOR_SYSTEM_CHARCOAL_LIGHT};
  }

  .stepper-progress-line {
    align-items: center;
    bottom: 0;
    display: flex;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  .stepper-progress-line-inner {
    height: 3px;
    width: 100%;
  }

  .stepper-progress-line-bg-complete {
    background: ${RDS_COLOR_SYSTEM_CAMARONE};
  }

  .stepper-progress-line-bg-upcoming,
  .stepper-progress-line-bg-current {
    background: ${RDS_COLOR_NEUTRAL_80};
  }
`;
