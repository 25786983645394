import { PaddingSizes } from '@health-activity-ui/shared';
import React from 'react';
import { Level, LevelItem } from '../layout';

interface Props {
  padding?: PaddingSizes;
}

export const AcLoader = ({ padding = '64' }: Props) => (
  <Level padding={padding}>
    <LevelItem>
      <ac-loading-dots data-testid="loading" />
    </LevelItem>
  </Level>
);
