import { RdsIcon } from '@rally/energon-react/dist/es6';
import classnames from 'classnames';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, NavLinkProps } from 'react-router-dom';

interface BackLinkProps extends NavLinkProps {
  onClick?: () => void;
}

export const BackLink = ({ to, onClick, className = undefined, ...rest }: BackLinkProps): ReactElement => {
  const { t } = useTranslation();
  return (
    <NavLink
      aria-label={t('back')}
      to={to}
      className={classnames('rds-link-icon-left is-rds-body-2 is-white-labeled-link', className)}
      onClick={onClick}
      {...rest}
    >
      <>
        <RdsIcon name="system-pagination-arrow-left" size="16" />
        {t('back')}
      </>
    </NavLink>
  );
};

export default BackLink;
