import React from 'react';

interface Props {
  color?: string;
  size?: string;
  className?: string;
}

export const ClockIcon = ({ color = '#000000', size = '32', className = '' }: Props): React.ReactElement => (
  <svg width={size} height={size} viewBox="0 0 20 20" className={className} color={color} fill={color}>
    <title>ClockIcon</title>
    <g>
      <circle fill={color} cx="10" cy="10" r="10" />
      <polyline
        stroke="#FFFFFF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="10 4 10 10.3111381 14 14"
      />
    </g>
  </svg>
);

export default ClockIcon;
