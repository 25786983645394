import { ENGAGE_USER_PROFILE_ACTION_TYPES, EngageUserProfileState } from './engage.models';
import { Status } from '@health-activity-ui/shared';
import { EngageUserProfileActions } from './engage.actions';

export const engageUserProfileReducer = (
  state: EngageUserProfileState,
  action: EngageUserProfileActions
): EngageUserProfileState => {
  switch (action.type) {
    case ENGAGE_USER_PROFILE_ACTION_TYPES.ENGAGE_USER_PROFILE_FETCH_LOADING: {
      return {
        ...state,
        status: Status.loading,
      };
    }

    case ENGAGE_USER_PROFILE_ACTION_TYPES.ENGAGE_USER_PROFILE_FETCH_LOADED: {
      const profile = action.payload;
      return {
        ...state,
        status: Status.loaded,
        displayName: profile.displayName,
        roles: profile.roles,
        permissions: profile.permissions,
        featureData: profile?.featureData,
      };
    }

    case ENGAGE_USER_PROFILE_ACTION_TYPES.ENGAGE_USER_PROFILE_FETCH_FAILED: {
      return {
        ...state,
        status: Status.error,
      };
    }
  }
};
