import React, { useState, useId, useEffect } from 'react';
import { useCore } from '@health-activity-ui/core';
import { RdsIcon } from '@rally/energon-react';
import './rh-tooltip.module.scss';

export interface IRHTooltipProps {
  message: string;
  ariaLabel?: string;
  position?: 'left' | 'right' | 'near';
  className?: string;
}

export function RhTooltip({ message, ariaLabel, position }: IRHTooltipProps) {
  const [isTooltip, setIsTooltip] = useState(false);
  const domId = useId();
  const { t } = useCore();

  useEffect(() => {
    function handleEscapeKeyPress(event) {
      const dialogIcon = document.getElementById(`${domId}`);
      const dialogBox = document.getElementById(`${domId}-container`);
      const closeIcon = document.getElementById(`${domId}-close-icon`);
      const dialog = [dialogIcon, dialogBox, closeIcon];

      if (event.key === 'Escape' && dialog.includes(document.activeElement as HTMLElement)) {
        setIsTooltip(false);
      }
    }

    document.addEventListener('keydown', handleEscapeKeyPress);

    return (): void => {
      document.removeEventListener('keydown', handleEscapeKeyPress);
    };
  }, [domId]);

  useEffect(() => {
    function handleClickOutsideDialog(event) {
      const dialogIcon = document.getElementById(`${domId}`);
      const dialogBox = document.getElementById(`${domId}-container`);

      if (dialogBox && !dialogBox.contains(event.target) && dialogIcon && !dialogIcon.contains(event.target)) {
        setIsTooltip(false);
      }
    }

    document.addEventListener('click', handleClickOutsideDialog);

    return (): void => {
      document.removeEventListener('click', handleClickOutsideDialog);
    };
  }, [domId]);

  return (
    <button
      id={domId}
      aria-expanded={isTooltip}
      className="rhTooltip"
      onClick={() => {
        setIsTooltip(!isTooltip);
        setTimeout(() => {
          document.getElementById(`${domId}-container`)?.focus();
        }, 0);
      }}
    >
      <RdsIcon
        data-testid="tooltip-test"
        aria-label={ariaLabel}
        className=" is-white-labeled-link"
        role="button"
        aria-haspopup="dialog"
        name="system-info-circle"
        size="16"
      />
      <div className={`${position === 'near' ? 'near' : ''} rh-tooltip__container`}>
        {isTooltip && (
          <div className={`${position ? position : 'right'} rh-tooltip__content`}>
            <div id={`${domId}-container`} tabIndex={-1} role="dialog" className="rh-tooltip__message is-rds-body-3">
              <p>{message}</p>
              <RdsIcon
                id={`${domId}-close-icon`}
                aria-label={t('close')}
                onClick={() => setIsTooltip(false)}
                tabIndex={0}
                name="system-close"
                size="16"
                role="button"
              />
            </div>
          </div>
        )}
      </div>
    </button>
  );
}

export default RhTooltip;
