import {
  AppliedCheckin,
  ChallengeCategory,
  ChallengeData,
  ChallengeDates,
  ChallengeStatus,
  ChallengeTeamType,
  CheckinDataType,
  CheckinMethods,
  DeviceUserSettings,
  Entity,
  IGalleryChallenge,
  MemberLeaderboard,
} from '@health-activity-ui/shared';
import {
  RDS_COLOR_NEUTRAL_90,
  RDS_COLOR_SYSTEM_CHARCOAL_LIGHT,
  RDS_COLOR_SYSTEM_PEACOCK,
  RDS_COLOR_SYSTEM_RIVIERA,
  RDS_COLOR_SYSTEM_TANGELO,
  RDS_COLOR_WHITE,
} from '@rally/ui-themes/dist/web/rally';
import compareAsc from 'date-fns/compareAsc';
import compareDesc from 'date-fns/compareDesc';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import isBefore from 'date-fns/isBefore';
import isSameDay from 'date-fns/isSameDay';
import { isDefined, round } from './utils';

/**
 * @method sortChallenges
 * @description takes in an array of challenges and sorts them by:
 *
 * isIncentivized = true AND status = active OR waiting
 * Active (soonest to end comes first)
 * Waiting (soonest to start comes first)
 * Completed (incentivized, then earliest ended comes first)
 *
 *
 * @param {IGalleryChallenge[]} challenges
 * @return {IGalleryChallenge[]}
 */

export const sortChallenges = (challenges: IGalleryChallenge[]): IGalleryChallenge[] => {
  /**
   * Filter out challenges where isIcentivized = true AND status = active OR waiting
   */
  const incentivizedAndActiveOrWaiting = challenges.filter(
    // eslint-disable-next-line array-callback-return
    (challenge): IGalleryChallenge => {
      if (
        challenge.isIncentivized &&
        (challenge.status === ChallengeStatus.Active || challenge.status === ChallengeStatus.Waiting)
      ) {
        return challenge;
      }
    }
  );

  /**
   * sort by active first, then waiting
   */

  const compareIncentivizedAndActiveOrWaiting = (
    challenge1: IGalleryChallenge,
    challenge2: IGalleryChallenge
  ): number => {
    if (challenge1.status === ChallengeStatus.Active && challenge2.status === ChallengeStatus.Waiting) {
      return -1;
    } else if (challenge1.status === ChallengeStatus.Waiting && challenge2.status === ChallengeStatus.Active) {
      return 1;
    } else {
      return compareAsc(new Date(challenge1.dates.startDate), new Date(challenge2.dates.startDate));
    }
  };

  const sortedIncentivizedAndActiveOrWaiting = incentivizedAndActiveOrWaiting.sort(
    compareIncentivizedAndActiveOrWaiting
  );

  /**
   * Filter out challenges where isIcentivized = false AND status = active
   *
   * then sort by endDate, ascending. (earlier endDate => later endDate)
   */
  const nonIncentivizedAndActive = challenges.filter(
    // eslint-disable-next-line array-callback-return
    (challenge): IGalleryChallenge => {
      if (!challenge.isIncentivized && challenge.status === ChallengeStatus.Active) {
        return challenge;
      }
    }
  );

  const sortedNonIncentivizedAndActive = nonIncentivizedAndActive.sort(
    (challenge1: IGalleryChallenge, challenge2: IGalleryChallenge): number => {
      return compareAsc(new Date(challenge1.dates.endDate), new Date(challenge2.dates.endDate));
    }
  );

  /**
   * Filter out challenges where isIcentivized = false AND status = waiting
   *
   * then sort by startDate, ascending. (earlier startDate => later startDate)
   */
  const nonIncentivizedAndWaiting = challenges.filter(
    // eslint-disable-next-line array-callback-return
    (challenge): IGalleryChallenge => {
      if (!challenge.isIncentivized && challenge.status === ChallengeStatus.Waiting) {
        return challenge;
      }
    }
  );

  const sortedNonIncentivizedAndWaiting = nonIncentivizedAndWaiting.sort(
    (challenge1: IGalleryChallenge, challenge2: IGalleryChallenge): number => {
      return compareAsc(new Date(challenge1.dates.startDate), new Date(challenge2.dates.startDate));
    }
  );

  /**
   * Filter out challenges where isIcentivized = true AND status = complete
   *
   * then sort by endDate, ascending. (earlier endDate => later endDate)
   */

  const incentivizedAndComplete = challenges.filter(
    // eslint-disable-next-line array-callback-return
    (challenge): IGalleryChallenge => {
      if (challenge.isIncentivized && challenge.status === ChallengeStatus.Completed) {
        return challenge;
      }
    }
  );

  const sortedIncentivizedAndComplete = incentivizedAndComplete.sort(
    (challenge1: IGalleryChallenge, challenge2: IGalleryChallenge): number => {
      return compareAsc(new Date(challenge1.dates.endDate), new Date(challenge2.dates.endDate));
    }
  );

  /**
   * Filter out challenges where isIcentivized = false AND status = complete
   *
   * then sort by endDate, ascending. (earlier endDate => later endDate)
   */

  const nonIncentivizedAndComplete = challenges.filter(
    // eslint-disable-next-line array-callback-return
    (challenge): IGalleryChallenge => {
      if (!challenge.isIncentivized && challenge.status === ChallengeStatus.Completed) {
        return challenge;
      }
    }
  );

  const sortedNonIncentivizedAndComplete = nonIncentivizedAndComplete.sort(
    (challenge1: IGalleryChallenge, challenge2: IGalleryChallenge): number => {
      return compareAsc(new Date(challenge1.dates.endDate), new Date(challenge2.dates.endDate));
    }
  );

  return [].concat(
    sortedIncentivizedAndActiveOrWaiting,
    sortedNonIncentivizedAndActive,
    sortedNonIncentivizedAndWaiting,
    sortedIncentivizedAndComplete,
    sortedNonIncentivizedAndComplete
  );
};

/**
 * @method completedChallengesComparator
 * @description takes in two completed challenges and compares them by:
 *
 * isIncentivized - (true, false)
 * Most recent to end - (earlier endDate, later endDate)
 * Alphabetical order by title - (A, Z);
 *
 * @param {CardProp} challenge1
 * @param {CardProp} challenge2
 * @return {IGalleryChallenge}
 */

export const completedChallengesComparator = (challenge1: IGalleryChallenge, challenge2: IGalleryChallenge): number => {
  // place challenges where isIncentivized = true first
  if (challenge1.isIncentivized && !challenge2.isIncentivized) {
    return -1;
  } else if (!challenge1.isIncentivized && challenge2.isIncentivized) {
    return 1;
  }

  // place challenges that end earlier frist
  else if (compareDesc(new Date(challenge1.dates.endDate), new Date(challenge2.dates.endDate)) !== 0) {
    return compareDesc(new Date(challenge1.dates.endDate), new Date(challenge2.dates.endDate));
  }

  // place challenges in alphabetical order
  else if (challenge1.title.localeCompare(challenge2.title) !== 0) {
    return challenge1.title.localeCompare(challenge2.title);
  }

  // at this point, do sort challenges as they are considered "equal"
  else {
    return 0;
  }
};

/**
 * @method sortCompletedChallenges
 * @description takes in an array of completed challenges and sorts them by:
 *
 * isIncentivized, true > false
 * Most recent to end
 * Alphabetical order by title
 *
 * @param {IGalleryChallenge[]} challenges
 * @return {IGalleryChallenge[]}
 */

export const sortCompletedChallenges = (challenges: IGalleryChallenge[]): IGalleryChallenge[] => {
  return challenges.sort(completedChallengesComparator);
};

/**
 * @method filterCompletedChallenges
 * @description returns challenges that have been completed for more than 7 days,
 * ie: > 7 days since the challenge end date
 * @param {IGalleryChallenge} challenge
 * @return {IGalleryChallenge}
 */
export const filterCompletedChallenges = (challenge: IGalleryChallenge): IGalleryChallenge => {
  if (differenceInCalendarDays(new Date(), new Date(challenge.dates.endDate)) <= 7) {
    return challenge;
  }
};

/**
 * @method getCategoryColors
 * @description gets color based on Challenge Category
 * @param {ChallengeCategory} category
 * @param {string} defaultColor
 * @return {string} return the color string
 */

// TODO: https://jira.rallyhealth.com/browse/WHAT-619
// This could be simplified by just using classnames instead of creating inline styles everywhere
// It makes it difficult to provide colors to elements that rely on currentColor to determine the color they should be.
export const getCategoryColors = (category: ChallengeCategory, defaultColor: string): string =>
  /* eslint-disable */
  category === ChallengeCategory.Exercise
    ? RDS_COLOR_SYSTEM_PEACOCK
    : category === ChallengeCategory.StateOfMind
    ? RDS_COLOR_SYSTEM_TANGELO
    : category === ChallengeCategory.Nutrition
    ? RDS_COLOR_SYSTEM_RIVIERA
    : defaultColor;
/* eslint-enable */

/**
 * @method getCategoryColors
 * @description gets class name for bg color based on Challenge Category
 * @param {ChallengeCategory} category
 * @param {string} defaultColor
 * @return {string} return the class name to be used as bg color
 */
export const getCategoryColorsByClassName = (category: ChallengeCategory, defaultColor: string): string =>
  /* eslint-disable */
  category === ChallengeCategory.Exercise
    ? 'has-rds-bg-blue-data'
    : category === ChallengeCategory.StateOfMind
    ? 'has-rds-bg-orange-data'
    : category === ChallengeCategory.Nutrition
    ? 'has-rds-bg-turquoise-data'
    : defaultColor;
/* eslint-enable */

/**
 * @method getBackgroundColor
 * @description gets background color for Challenge Gallery Card
 * @param {ChallengeStatus} status
 * @param {ChallengeCategory} category
 * @return {string} return the color string
 */
export const getBackgroundColor = (status: ChallengeStatus, category: ChallengeCategory): string =>
  /* eslint-disable */
  status === ChallengeStatus.Completed
    ? RDS_COLOR_NEUTRAL_90
    : status === ChallengeStatus.Waiting
    ? getCategoryColors(category, RDS_COLOR_SYSTEM_CHARCOAL_LIGHT)
    : status === ChallengeStatus.Active
    ? getCategoryColors(category, RDS_COLOR_SYSTEM_CHARCOAL_LIGHT)
    : RDS_COLOR_WHITE;
/* eslint-enable */

/**
 * @method getBgColorByClassName
 * @description gets the RDS classname associated with the background color to use for a Challenge Gallery Card
 * @param {isTeam} boolean
 * @param {ChallengeCategory} category
 * @return {string} return the RDS class string
 */
export const getBgColorByClassName = (isTeam: boolean, category: ChallengeCategory): string => {
  return isTeam ? 'has-rds-bg-turquoise-data' : getCategoryColorsByClassName(category, 'has-rds-bg-blue-data');
};

/**
 * @method getChallengeStatus
 * @description takes in a challenge and returns the status of the challenge based on
 *  start or end date
 * @param {ChallengeDates} dates
 * @param {string} status
 *
 * @return {string}
 */
export const getChallengeStatus = (dates: Partial<ChallengeDates>, status: ChallengeStatus): ChallengeStatus => {
  if (!isDefined(dates)) {
    return status;
  }

  if (status === ChallengeStatus.Completed || isBefore(dates.endDate, new Date())) {
    return ChallengeStatus.Completed;
  } else if (isSameDay(dates.startDate, new Date()) || isBefore(dates.startDate, new Date())) {
    return ChallengeStatus.Active;
  } else if (isBefore(new Date(), dates.startDate)) {
    return ChallengeStatus.Waiting;
  }

  return status;
};

/**
 * @method getChallengeState
 * @description takes in challenge status, joined status, and isTeam, returns state of challenge to the user
 * @param {string}
 * @return {string}
 */

export const getChallengeState = (status: ChallengeStatus, isJoined: boolean, isTeam: boolean): string => {
  if (status === ChallengeStatus.Waiting && isJoined && isTeam) {
    return 'WAITING_TEAM_JOINED';
  } else if (status === ChallengeStatus.Waiting && !isJoined && isTeam) {
    return 'WAITING_TEAM_UNJOINED';
  } else if (status === ChallengeStatus.Waiting && isJoined && !isTeam) {
    return 'WAITING_USER_JOINED';
  } else if (status === ChallengeStatus.Waiting && !isJoined && !isTeam) {
    return 'WAITING_USER_UNJOINED';
  } else if (status === ChallengeStatus.Active && isJoined && isTeam) {
    return 'ACTIVE_TEAM_JOINED';
  } else if (status === ChallengeStatus.Active && !isJoined && isTeam) {
    return 'ACTIVE_TEAM_UNJOINED';
  } else if (status === ChallengeStatus.Active && isJoined && !isTeam) {
    return 'ACTIVE_USER_JOINED';
  } else if (status === ChallengeStatus.Active && !isJoined && !isTeam) {
    return 'ACTIVE_USER_UNJOINED';
  } else if (status === ChallengeStatus.Completed && isTeam) {
    return 'COMPLETE_TEAM';
  } else if (status === ChallengeStatus.Completed && !isTeam) {
    return 'COMPLETE_USER';
  }
};

/**
 * @method getChallengeType
 * @description takes in isTeam, is UCC, and is private challenge and returns the type of challenge for Amplitude tracking
 * @param {boolean} isTeam
 * @param {boolean} isUserCreatedChallenge
 * @param {boolean} isInviteOnly
 *
 * @return {string}
 */

export const getChallengeType = (
  isTeam: boolean,
  isUserCreatedChallenge: boolean,
  isInviteOnly: boolean
): ChallengeTeamType => {
  if (isTeam) {
    return ChallengeTeamType.PrivateTeam;
  } else if (isUserCreatedChallenge) {
    return ChallengeTeamType.UserCreated;
  } else if (isInviteOnly) {
    return ChallengeTeamType.Private;
  } else {
    return ChallengeTeamType.Public;
  }
};

/**
 * @method getChallengeCategory
 * @description takes in isTeam, isCityWalk, and returns the proper category to display
 * @param {boolean} isTeam
 * @param {boolean} isCityWalk
 *
 * @return {string}
 */
export const getChallengeCategory = (isTeam: boolean, isCityWalk: boolean, defaultCategory = ''): string => {
  if (isTeam) {
    return 'teamBattle';
  } else if (isCityWalk) {
    return 'cityWalk';
  }

  return defaultCategory;
};

export function booleanToCheckinMethods(
  yesManual,
  yesDevice,
  defaultValue = CheckinMethods.None
): Array<CheckinMethods> {
  switch (true) {
    case yesManual && !yesDevice:
      return [CheckinMethods.Manual];

    case !yesManual && yesDevice:
      return [CheckinMethods.Device];

    case yesManual && yesDevice:
      return [CheckinMethods.Device, CheckinMethods.Manual];

    default:
      return [defaultValue];
  }
}

// Check if user ever selected a device from the device UI: https://member.werally.com/devices/manageData/activity
export const getIsDeviceConnected = (deviceSettings: DeviceUserSettings): boolean => {
  return deviceSettings?.dataTypeSettings?.some((dataTypeSetting) => dataTypeSetting.dataType === 'Activity') ?? false;
};

// Check if user selected manual tracking from the device UI: https://member.werally.com/devices/manageData/activity
export const getIsManualCheckinSelected = (deviceSettings: DeviceUserSettings): boolean => {
  return (
    getIsDeviceConnected(deviceSettings) &&
    (deviceSettings?.manualClients?.some((x) => x === 'health-activity-ui' || 'challenges') ?? false)
  );
};

export function getUserPreferredCheckinMethod(
  allowDeviceCheckin: boolean,
  deviceSettings: DeviceUserSettings
): CheckinMethods {
  const selectedManualCheckinsFromDeviceUI = getIsManualCheckinSelected(deviceSettings);
  const everSelectedADeviceFromDeviceUI = getIsDeviceConnected(deviceSettings);

  const getSelectedDeviceCheckinMethod = () => {
    switch (true) {
      case selectedManualCheckinsFromDeviceUI:
        return CheckinMethods.Manual;
      case everSelectedADeviceFromDeviceUI:
        return CheckinMethods.Device;
      default:
        return CheckinMethods.None;
    }
  };

  return allowDeviceCheckin ? getSelectedDeviceCheckinMethod() : CheckinMethods.None;
}

interface ShowChallengeDeviceSyncParams {
  allowManualCheckin: boolean;
  isDeviceConnected: boolean;
  isDeviceOnly: boolean;
  isDualCheckinAllowed: boolean;
  isManualCheckinSelected: boolean;
}

export const getShouldShowChallengeDeviceSync = ({
  allowManualCheckin,
  isDeviceConnected,
  isDeviceOnly,
  isDualCheckinAllowed,
  isManualCheckinSelected,
}: ShowChallengeDeviceSyncParams): boolean => {
  return (
    isDeviceOnly || (!isManualCheckinSelected && isDeviceConnected && (isDualCheckinAllowed || !allowManualCheckin))
  );
};

/**
 * @method calculateMaxTotal
 *
 * @description calculate the max total from leader board entities
 *
 * @param {ILeaderboardEntity[]} entities
 * @param {MemberLeaderboard} userLeaderboard
 *
 * @return {number}
 * */
export function calculateMaxTotal(
  entities: Partial<Entity>[],
  isAvg: boolean,
  isTeam: boolean,
  isJoined: boolean,
  userLeaderboard?: MemberLeaderboard
): number {
  try {
    if (isAvg && isTeam) {
      // calculate the top checkins across all the users
      return isJoined && userLeaderboard ? userLeaderboard.leaderboard.entities[0].total : 1;
    } else {
      // only works for a user challege.
      const greaterTotal = (acc, cur): number => (acc > cur.totalDistance ? acc : cur.totalDistance);
      return entities?.reduce(greaterTotal, 1);
    }
  } catch (e) {
    console.error(`Error. Unable to calculate max total in Challenge Leaderboard Model. ${e}`);
    throw e;
  }
}

/**
 * @method calculateInterval
 *
 * @return {number}
 * */
export function calculateInterval(maxTotal: number): number {
  try {
    return Math.ceil(maxTotal / 6);
  } catch (e) {
    console.error(`Error. Unable to calculate interval in Challenge Leaderboard Model. ${e}`);
    throw e;
  }
}

/**
 * @method calculateIntervalBounds
 *
 * @description calculate's the interval bounds to be displayed in leader board header
 *
 * @return {number[]}
 * */
export function calculateIntervalBounds(interval: number): number[] {
  try {
    return new Array(6).fill(0).map((_, i): number => interval * (i + 1));
  } catch (e) {
    console.error(`Error. Unable to calculate interval bounds in Challenge Leaderboard Model. ${e}`);
    throw e;
  }
}

/**
 * @method calculateProgress
 *
 * @description calculate a user's progress (will be used as for progress bar)
 * based off the maxIntervalBound.
 *
 * @param {number} distance
 *
 * @return {number}
 * */
export function calculateProgress(distance: number, maxIntervalBound): number {
  try {
    return maxIntervalBound === 0 ? 0 : Math.round((distance / maxIntervalBound) * 100);
  } catch (e) {
    console.error(`Error. Unable to calculate progress in Challenge Leaderboard Model. ${e}`);
    throw e;
  }
}

export function calculateBestDay(checkins: AppliedCheckin[]): AppliedCheckin {
  try {
    return checkins?.length > 0
      ? checkins.reduce(
          (bestDay, checkin): AppliedCheckin => (checkin.checkinAmount > bestDay.checkinAmount ? checkin : bestDay)
        )
      : {
          checkinAmount: 0,
          checkinDataType: CheckinDataType.Miles,
          checkinDate: null,
          milestonesCompleted: [],
          rallyId: '',
        };
  } catch (e) {
    console.error(`Error. Unable to calculate best day in Challenge Details Model. ${e}`);
    throw e;
  }
}

export function calculateCheckPointsMet(totalDistance: number, minorCheckpointAmount: number): number {
  try {
    let checkpointsMet = 0;

    if (totalDistance > 0) {
      let totalDistanceTemp = totalDistance;

      while (totalDistanceTemp >= minorCheckpointAmount) {
        totalDistanceTemp -= minorCheckpointAmount;
        checkpointsMet++;
      }
    }

    return checkpointsMet;
  } catch (e) {
    console.error(`Error. Unable to calculate checkpoints met in Challenge Details Model. ${e}`);
    throw e;
  }
}

export function calculateChallengeDetailsStatus(cd: ChallengeData) {
  const INSTANCE_DETAILS = 'instanceDetails';
  const startDate = INSTANCE_DETAILS in cd ? cd.challengeInstance.instanceDetails.dates.startDate : null;
  const endDate = INSTANCE_DETAILS in cd ? cd.challengeInstance.instanceDetails.dates.endDate : null;

  const status = cd.challengeInstance.instanceDetails?.status ?? ChallengeStatus.Default;
  const challengeStatus = getChallengeStatus({ startDate, endDate }, status);

  return { challengeStatus, startDate };
}

export function getUnit(totalDistance: number, unit: string) {
  return round(totalDistance, 2) !== 1 ? unit : unit.substring(0, unit.length - 1);
}
