import { AppConfig } from '../constants/app-config';
import { ProvisionedOIDCSession, SessionProvision } from '../models/huginn.interface';
import { SUPPORTED_LANGUAGES } from '../constants/locale';

// Follows pattern similar as employed by https://github.com/AudaxHealthInc/settings-ui/blob/master/src/app/localizeGlobalObjects.ts
// `app.config.local.ts` is responsible for typing all properties that are set on the window object and is necessary for compilation for typescript.
declare global {
  interface Window {
    adobeDataLayer: Record<string, unknown>;
    clientId: string;
    HAUI_APP_HA_ENV_CONFIG: Omit<AppConfig, 'HAUI_APP_HA_AUTHN_URL'>;
    huginn: {
      provisionOIDCSession: ({
        rpTokenSuffix,
        sessionUri,
        tokenUri,
        productPath,
      }?: SessionProvision) => Promise<ProvisionedOIDCSession>;
    };
    initHuginn: {
      productName: string;
      provision: {
        sessionUri: string;
        tokenUri: string;
        rpTokenSuffix: string;
        productPath: string;
      };
      onLoad: () => void;
      onLoadError: (e: Error) => void;
    };
    partnerId: string;
    qualtricsIetfLanguageCode: SUPPORTED_LANGUAGES;
    qualtricsSurvey: () => void;
    rallyId: string;
  }

  interface Document {
    documentMode?: string;
  }
}
// If we use these variables instead of the base 'window'
// or 'process' we can mock this file by jest.mock('app.config.local') and return mocked values
// of these objects :).
export const localWindow = window;
export const localDocument = document;
