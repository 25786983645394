import * as trackingActions from './tracking.actions';
import { TrackingState } from './tracking.models';
import { createReducer } from '@reduxjs/toolkit';

export const trackingStateReducer = createReducer({} as TrackingState, (builder) =>
  builder.addCase(trackingActions.trackClick, (state, action) => {
    const campaignId = action.payload;
    state.clicks[state.clicks.length] = `campaign_click_${campaignId}`;
  })
);

export default trackingStateReducer;
