import { Status } from '@health-activity-ui/shared';
import React, { ReactElement, ReactNode } from 'react';
import { FiveZeroZero } from '../errors/five-zero-zero.component';
import { AcLoader } from '../loader/ac-loader.component';

interface Props {
  children: ReactNode;
  status: Status;
  loaderComponent?: ReactElement;
  errorComponent?: ReactElement;
  shouldShowError?: boolean;
}

export const AsyncComponent = ({
  status,
  children,
  shouldShowError = false,
  loaderComponent,
  errorComponent,
}: Props) => {
  if (status === Status.initial || status === Status.loading) {
    return loaderComponent ?? <AcLoader />;
  }

  if (status === Status.error && shouldShowError) {
    return errorComponent ?? <FiveZeroZero />;
  }

  return <>{children}</>;
};
