import apiClient from './api-client';
import { EngageUserProfile, FUNNEL_BASE_URI } from '@health-activity-ui/shared';

export async function getEngageUserCurrent(): Promise<EngageUserProfile> {
  const { data } = await apiClient<EngageUserProfile>(`/rest/profile/current`, { useCache: true });
  return data;
}

export const getUser = async (userId: string): Promise<EngageUserProfile> => {
  const response = await apiClient<EngageUserProfile>(`/rest/profile/${userId}`);
  return response.data;
};

export const updateUserAbout = async (
  about: string
): Promise<{
  success: boolean;
}> => {
  const response = await apiClient<{
    success: boolean;
  }>(`/rest/profile/about`, {
    method: 'POST',
    data: { about, overwrite: true },
  });

  return response.data;
};

export const updateAvatar = async (
  avatar: string
): Promise<{
  avatarUrl: string;
  avatarSaved: boolean;
}> => {
  const response = await apiClient<{
    avatarUrl: string;
    avatarSaved: boolean;
  }>(`${FUNNEL_BASE_URI}/avatar/save`, {
    method: 'POST',
    data: { avatar },
  });

  return response.data;
};
