import { IClientLogo } from './client-logo.interface';
import { IFooterScaffoldLinks } from './shared.interface';

export interface ProfileWizard {
  branding: Branding;
  controls: Controls;
  errors: ProfileWizardErrors;
  footer: PageFooter;
  header: PageHeader;
  landing: Landing;
  pages: Array<Page>;
}

export interface Branding {
  alt: string;
  client: string;
  logo: IClientLogo;
  partner: string;
  path: string;
}

export interface Controls {
  next: string;
  previous: string;
  signUp: string;
  submit: string;
}

export interface PageComponent {
  components: Array<PageComponent>;
  componentType: string;
  id: string;
  model: string;
  templateUrl: string;
  validations: Array<ComponentValidations>;
  checkMarkFlag?: boolean;
  dataType?: string;
  eligibilityField?: boolean;
  errorPlacement?: string;
  imgSrc?: string;
  instruction?: string;
  legalDocument?: string;
  maxLength?: number;
  placeholder?: string;
  predefine?: string;
  version?: string;
}

export interface ProfileWizardErrors {
  usernameBlockedWord: string;
  usernameInvalidChar: string;
  usernameRequired: string;
  usernameTaken: string;
  usernameTooShort: string;
}

export interface Landing {
  enabled: boolean;
  headline: string;
  subHeadline: string;
}

export interface ComponentValidations {
  criteria?: string;
  instantError?: boolean;
  msgId?: string;
  validationType?: string;
}

export interface Page {
  components: Array<PageComponent>;
  title: string;
}

export interface PageHeader {
  buttonText: string;
  label: string;
  login: boolean;
}

export interface PageFooter {
  copy: string;
  copyright: string;
  links: Array<IFooterScaffoldLinks>;
}

export type RegistrationField<T> = { dataType: string; id: string; data: T };

export interface RegUsername extends RegistrationField<string> {
  data: string;
  dataType: 'String';
  id: 'regUsername';
}

export interface RegAvatar extends RegistrationField<string> {
  data: string;
  dataType: 'String';
  id: 'regAvatar';
}

export type ValidationCriteria = 'blockedWord' | 'isUsernameUnique';
export const ValidationCriteria = {
  blockedWord: 'blockedWord' as ValidationCriteria,
  isUsernameUnique: 'isUsernameUnique' as ValidationCriteria,
};

export type ViewType = 'create-profile';
export const ViewType = { createProfile: 'create-profile' as ViewType };
