import { IS_PROD, isDefined, round } from './utils';

export const fixBrokenCoordinates = (coordinates: Array<number[]>): Array<number[]> =>
  coordinates.map((coordinate: number[]) => [coordinate[1], coordinate[0]]);

interface RouteDistance {
  distance: number;
  coordinates: number[];
}

/**
 * @method metersToMiles
 *
 * @description Convert meters to  miles.
 *
 * @param {number} meters
 *
 * @return {number} miles
 * */
export const metersToMiles = (meters: number): number => round(meters * 0.000621371192, 2);

/**
 * @method getPointCoordinate
 *
 * @desc Gets the closest coordinate from a route based on passed in distance param
 *
 * @param {Map<number, number[]>} route Map route with coordinates and distance for each coordinate
 * @param {number} distance Distance in miles of point on the route.
 *
 * @return {number[]} coordinate of the point on the route
 * */
export const getPointCoordinate = (route: RouteDistance[], distance: number): number[] => {
  for (let i = 0; i < route.length - 1; i++) {
    // Found our coordinates
    if (route[i].distance <= distance && route[i + 1].distance > distance) {
      return route[i].coordinates;
    }
  }

  return route[route.length - 1].coordinates;
};

/**
 * @method stepsToMiles
 *
 * @description Converts steps to miles.
 *
 * @param {number} steps
 *
 * @return {number} miles
 * */
export const stepsToMiles = (steps: number): number => (isDefined(steps) ? (steps * 0.762) / 1609.344 : 0);

/**
 * @method milesToSteps
 *
 * @description Converts steps to miles.
 *
 * @param {number} miles
 *
 * @return {number} steps
 * */
export const milesToSteps = (miles: number): number => (isDefined(miles) ? (miles * 1609.344) / 0.762 : 0);

export const getMapBoxToken = (): string => {
  return IS_PROD()
    ? 'pk.eyJ1IjoicmFsbHloZWFsdGgiLCJhIjoiY2s4ZzdlcnF6MDl0ejNmcGU2bXhmZzBpeCJ9.c4e0o3tK3mG0s_J0N2k5MQ'
    : 'pk.eyJ1IjoicmFsbHloZWFsdGgiLCJhIjoiY2s4Zzc5dXB6MGJtbDNpb2kxdXhsNXZtaSJ9.z8rEsB8FPp4Q4gPOZkmZvA';
};
