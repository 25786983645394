// TODO: WHAT-768 Remove this model and all the uses of it in favor of `ICurrentLocale`.
export interface ITokenizeLocale {
  language: string;
  country: string;
  displayName: string;
  localeString: string;
}

/**
 * @function tokenizeLocale
 *
 * @description
 * Converts a locales string into an object containing its language and country acronyms
 *
 * @param {String} localeString - The locale string to split
 * @param {String} [defaultCountry=US] - optional parameter for the default country
 *
 * @returns {ITokenizeLocale} an object with the language and country fields from the Locale
 */
export function tokenizeLocale(localeString: string, defaultCountry = 'US'): ITokenizeLocale {
  const [language, country] = normalizeLocale(localeString, defaultCountry).split('-');
  const displayName = language.toUpperCase() + ' (' + country + ')';

  return {
    country,
    displayName,
    language,
    localeString: `${language}-${country}`,
  };
}

/**
 *
 * @param localeString String representation of a locale in the form of
 * @param defaultCountry
 * @return {string } Returns the locale string with language as a lowercase and country as uppercase.
 * */
export function normalizeLocale(localeString: string, defaultCountry = 'US'): string {
  let [language, country] = localeString.replace(/[-_]/, '-').split('-');
  country = (country || defaultCountry).toUpperCase();
  language = language.toLowerCase();
  return `${language}-${country}`;
}

/**
 * @description Compares two locale representations for logical equality.
 */
export function compareLocales(left: string | ITokenizeLocale, right: string | ITokenizeLocale): boolean {
  if (typeof left === 'string') {
    left = tokenizeLocale(left);
  }

  if (typeof right === 'string') {
    right = tokenizeLocale(right);
  }

  return (
    left.language.toLowerCase() === right.language.toLowerCase() &&
    left.country.toUpperCase() === right.country.toUpperCase()
  );
}
