import React from 'react';
import classnames from 'classnames';
import { ZenTimerLabel } from '../timer.interface';
import { padZeros } from '@health-activity-ui/utils';
import { useZenTimerContext } from './zen-timer.component';

export const ZenTimerMinutes = ({
  label = 'Minutes',
  ariaLabel = 'Minute',
  className = null,
}: ZenTimerLabel): React.ReactElement => {
  const { minutes } = useZenTimerContext();
  return (
    <span aria-hidden={true}>
      <span className="remaining-time-minutes">{padZeros(minutes, 2)}</span>
      <span
        className={classnames(
          'remaining-time-minutes-label timer-label',
          {
            'has-rds-mr-8': !className,
          },
          className
        )}
        aria-label={ariaLabel}
      >
        {label}
      </span>
    </span>
  );
};
