import { Status } from '@health-activity-ui/shared';
import { UiConfigActions } from './ui-config.actions';
import { UiConfigState, UI_CONFIG_ACTION_TYPES } from './ui-config.models';

export const uiConfigReducer = (state: UiConfigState, action: UiConfigActions): UiConfigState => {
  switch (action.type) {
    case UI_CONFIG_ACTION_TYPES.UI_CONFIG_FETCH_LOADING: {
      return {
        ...state,
        status: Status.loading,
      };
    }
    case UI_CONFIG_ACTION_TYPES.UI_CONFIG_FETCH_LOADED: {
      const uiConfig = action.payload;
      const header = uiConfig.header;
      const goalSettingHeader = { ...header, nav: [] };
      const goalSettingsUiConfig = { ...uiConfig, header: goalSettingHeader };
      return {
        ...state,
        status: Status.loaded,
        data: uiConfig,
        goalSettingsUiConfig,
      };
    }

    case UI_CONFIG_ACTION_TYPES.UI_CONFIG_FETCH_LOADED_WITH_WHITE_LABEL: {
      const uiConfig = action.payload;
      const header = uiConfig.header;
      const goalSettingHeader = { ...header, nav: [] };
      const goalSettingsUiConfig = { ...uiConfig, header: goalSettingHeader };
      return {
        ...state,
        status: Status.loaded,
        data: uiConfig,
        goalSettingsUiConfig,
        whiteLabelHasBeenSet: true,
      };
    }

    case UI_CONFIG_ACTION_TYPES.UI_CONFIG_FETCH_FAILED: {
      return {
        ...state,
        status: Status.error,
        hasAdvantageAuth: false,
      };
    }

    case UI_CONFIG_ACTION_TYPES.UI_CONFIG_DETERMINE_SUBNAV: {
      return {
        ...state,
        hasSubnav: action.payload.hasSubnav,
      };
    }
  }
};
