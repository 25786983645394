import {
  appConfig,
  Devices,
  DeviceStorage,
  DEVICES_MANAGE_ACTIVITY_EXTERNAL_ROUTE,
  localWindow,
} from '@health-activity-ui/shared';
import { deleteManualClient, updateManualClient } from '../api/device.api';

/**
 * @function goToDeviceHub
 *
 * @description Redirect user to device hub flow if a device is not selected
 *
 * @return void
 * */
export const goToDeviceHub = (activityId: string, activityRoute: string, deviceStorage: DeviceStorage): void => {
  const { setDevicesManualClient, setDevicesLaunchUrl } = deviceStorage;
  setDevicesLaunchUrl(`${appConfig.HAUI_APP_HA_UI_UNIFIED_URL}${activityRoute}/details/${activityId}`);

  setDevicesManualClient('health-activity-ui');

  localWindow.location.href = DEVICES_MANAGE_ACTIVITY_EXTERNAL_ROUTE;
};

export const getShouldShowDeviceFlow = (
  isDualCheckinAllowed: boolean,
  allowManualCheckin: boolean,
  currentDevices: Devices[]
): boolean => {
  const allowsDeviceCheckin = isDualCheckinAllowed || !allowManualCheckin;
  const hasNoActivity = currentDevices?.length === 0;
  return allowsDeviceCheckin && hasNoActivity;
};

export const refreshManualClient = async (deviceManualClients: string[], cb: () => void): Promise<void> => {
  if (deviceManualClients?.some((manual_client) => manual_client === 'challenges' || /^ind/.test(manual_client))) {
    try {
      await Promise.all([
        deviceManualClients.map(async (manual_client) => deleteManualClient(manual_client)),
        updateManualClient('health-activity-ui'),
      ]);
    } catch {
      cb();
    }
  }
};
