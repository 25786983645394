import { appConfig, AuthHeaders, Devices, DeviceUserSettings, DEVICE_BASE_URI } from '@health-activity-ui/shared';
import { AxiosResponse } from 'axios';
import apiClient from './api-client';

const headers = { [AuthHeaders.RallyCaller]: appConfig.HAUI_APP_HA_RALLY_CALLER };

export async function getDevices(): Promise<Devices[]> {
  const response = await apiClient<Devices[]>(`${DEVICE_BASE_URI}/devices?configId=Rally`, { headers });
  return response.data;
}

export async function getDeviceUserSettings(): Promise<DeviceUserSettings> {
  const response = await apiClient<DeviceUserSettings>(`${DEVICE_BASE_URI}/userSettings`, { headers });
  return response.data;
}

export const deleteManualClient = async (manualClient: string): Promise<AxiosResponse> => {
  const response = await apiClient(`${DEVICE_BASE_URI}/userSettings/manualclient/${manualClient}`, {
    method: 'DELETE',
  });
  return response;
};

export const updateManualClient = async (manualClient: string): Promise<AxiosResponse> => {
  const response = await apiClient(`${DEVICE_BASE_URI}/userSettings/manualclient/${manualClient}`, {
    method: 'PUT',
  });
  return response;
};
