import React from 'react';

interface Props {
  color?: string;
  height?: string;
  width?: string;
}

export const EllipticalIcon = ({ color = '#fff', width = '25px', height = '25px' }: Props): React.ReactElement => (
  <svg width={width} height={height} data-testid="elliptical-icon" viewBox="0 0 60 100" aria-hidden="true">
    <g fill={color} fillRule="nonzero">
      <circle cx="29.7" cy="6" r="6" />
      <path d="M14 66c-4 0-7 3-7 7s3 7 7 7 7-3 7-7-3-7-7-7z" />
      <path d="M60 79l-5-21-1-30 5-18-2-2c-1 0-2 0-2 2l-2 7h-2l-2 1v-8l-2-2-2 2v9l-2 1-9-4-3-3h-6c-3 0-5 2-5 4l-1 16v1l-1 19-4 6C8 59 2 63 0 70c-2 9 5 17 14 17h43c5 0 6-5 3-8zM48 24l3-1-1 5 1 12-3-12v-4zM14 82c-5 0-9-4-9-9s4-9 9-9 9 4 9 9-4 9-9 9zm21-15l-14-7 3-4v-2l1-9h5l6 7-1 15zm7 3V50l-1-2-8-8 1-18 7 4a3 3 0 003 0v-1 4l7 30 1 16-10-5z" />
    </g>
  </svg>
);

export default EllipticalIcon;
