// Lets move all globally shared locale definitions here? https://jira.rallyhealth.com/browse/WHAT-770
export enum LOCALE {
  COOKIE = 'x_rally_locale',
  EN_US = 'en-US',
  ES_US = 'es-US',
  ES_ONLY = 'es',
  USER_LOCALE = 'user_locale',
}

export type Locale = LOCALE.EN_US | LOCALE.ES_US;
export type SUPPORTED_LANGUAGES = 'en-US' | 'es';

export const QUALTRICS_LANGUAGE_LOOKUP = {
  'en-US': LOCALE.EN_US,

  // Qualtrics does not support `es-US`
  'es-US': LOCALE.ES_ONLY,
};
