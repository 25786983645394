import React from 'react';

export const PrivateChallengeLockIcon = (): React.ReactElement => (
  <svg width="52px" height="49px" viewBox="0 0 52 49">
    <title>Private Challenge Lock Icon</title>
    <g id="Flows" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="3.-City-Walks---Gallery-Empty" transform="translate(-114.000000, -334.000000)">
        <g id="Group-11-Copy-13" transform="translate(110.000000, 334.000000)">
          <g id="Group-12-Copy" transform="translate(0.000000, 0.145176)">
            <g id="Group-10" transform="translate(20.559211, 0.000000)">
              <path
                d="M34.5431871,47.9083246 C34.5431871,48.4032523 26.8107114,48.8046539 17.2718286,48.8046539 C7.73294584,48.8046539 -1.3500312e-13,48.4032523 -1.3500312e-13,47.9083246 C-1.3500312e-13,47.4133969 7.73294584,47.0119953 17.2718286,47.0119953 C26.8107114,47.0119953 34.5431871,47.4133969 34.5431871,47.9083246"
                id="Fill-11"
                fillOpacity="0.2"
                fill="#020202"
              />
              <g id="Group-9" transform="translate(3.102861, 0.000000)">
                <g id="Lock-Modal" transform="translate(0.000000, 0.225231)">
                  <g id="Group-18" transform="translate(0.350059, 0.000000)">
                    <path
                      d="M25.172337,20.3913515 L19.6907905,20.3913515 L19.6907905,11.610904 C19.6907905,8.38258718 17.0151905,5.75563154 13.7260982,5.75563154 C10.4370058,5.75563154 7.76099465,8.38258718 7.76099465,11.610904 L7.76099465,20.3913515 L2.27985934,20.3913515 L2.27985934,11.610904 C2.27985934,5.36716699 7.41436321,0.287593017 13.7260982,0.287593017 C20.0374219,0.287593017 25.172337,5.36716699 25.172337,11.610904 L25.172337,20.3913515 Z"
                      id="Fill-45"
                      fill="#979797"
                    />
                    <path
                      d="M19.7044419,21.4613313 C19.7044419,24.741334 17.0292532,27.4101306 13.7413944,27.4101306 C10.4531244,27.4101306 7.77793559,24.741334 7.77793559,21.4613313 L7.77793559,11.858422 C7.77793559,8.57800905 10.4531244,5.90921246 13.7413944,5.90921246 C17.0292532,5.90921246 19.7044419,8.57800905 19.7044419,11.858422 L19.7044419,21.4613313 Z M13.7413944,4.77089236 C9.8240068,4.77089236 6.63688904,7.94998452 6.63688904,11.858422 L6.63688904,21.4613313 C6.63688904,25.3689484 9.8240068,28.5484507 13.7413944,28.5484507 C17.6587819,28.5484507 19.7361034,25.3689484 19.7361034,21.4613313 L19.7361034,11.858422 C19.7361034,7.94998452 17.6587819,4.77089236 13.7413944,4.77089236 L13.7413944,4.77089236 Z"
                      id="Fill-46"
                      fill="#7F7F7F"
                    />
                    <path
                      d="M21.4776899,3.30773057 C23.1154515,5.18606129 24.1105263,7.63621838 24.1105263,10.3152701 L24.1105263,21.0281954 C24.1105263,26.9248986 19.3016833,31.7222512 13.3912678,31.7222512 C10.7053882,31.7222512 8.24936257,30.7295541 6.36653297,29.0952956 C8.33324491,31.3510153 11.228008,32.7814017 14.4529551,32.7814017 C20.3637818,32.7814017 25.1722136,27.9840491 25.1722136,22.0873459 L25.1722136,11.3744206 C25.1722136,8.15717929 23.7388124,5.2697432 21.4776899,3.30773057"
                      id="Fill-47"
                      fill="#7F7F7F"
                    />
                    <path
                      d="M0,19.7716191 L0,41.9657845 C0,42.6766705 0.577307874,43.252599 1.28989658,43.252599 L26.1166023,43.252599 C26.829191,43.252599 27.4064989,42.6766705 27.4064989,41.9657845 L27.4064989,19.7716191 C27.4064989,19.0611433 26.829191,18.4848047 26.1166023,18.4848047 L1.28989658,18.4848047 C0.577307874,18.4848047 0,19.0611433 0,19.7716191"
                      id="Fill-48"
                      fill="#EEBB28"
                    />
                    <polygon
                      id="Fill-49"
                      fill="#FFFFFF"
                      opacity="0.7"
                      points="0.0225608841 21.2758364 27.4290598 21.2758364 27.4290598 20.3914335 0.0225608841 20.3914335"
                    />
                    <polygon
                      id="Fill-50"
                      fill="#FFFFFF"
                      opacity="0.7"
                      points="0.0225608841 41.1383941 27.4290598 41.1383941 27.4290598 40.2539911 0.0225608841 40.2539911"
                    />
                    <path
                      d="M4.29110294,13.717473 L4.29110294,11.4937493 C4.29110294,6.35961803 8.52346045,2.18249603 13.7262215,2.18249603"
                      id="Stroke-51"
                      stroke="#B2B2B2"
                      strokeWidth="0.5"
                      strokeLinecap="round"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g id="Group-36" transform="translate(30.838816, 26.289500)" fill="#F9EABE" fillRule="nonzero">
              <path
                d="M7.5680048,7.10761132 L6.63806781,7.10761132 C6.63806781,7.10761132 5.70813082,10.002008 4.31556186,10.5808874 L9.89051075,10.5808874 C8.49794179,9.99967385 7.5680048,7.10761132 7.5680048,7.10761132 Z"
                id="Path"
              />
              <path
                d="M11.0984942,0.886992545 L11.0727924,0.9500157 C11.0891481,0.646570882 11.0984942,0.331455109 11.0984942,2.95585778e-12 L3.10757838,2.95585778e-12 C3.10757838,0.331455109 3.11692448,0.646570882 3.13328016,0.9500157 L3.10757838,0.886992545 L0,0.886992545 C0,0.886992545 0.887879538,5.45733834 5.771217,7.23132344 L8.43485561,7.23132344 C13.3181931,5.45733834 14.2060726,0.886992545 14.2060726,0.886992545 L11.0984942,0.886992545 Z M4.1052746,5.32195527 C2.38559213,4.00313741 1.53743352,2.91307025 1.15657992,1.77398509 L3.20103939,1.77398509 C3.36225962,3.2608647 3.71974796,4.37193957 4.11695723,5.18890639 L4.1052746,5.32195527 L4.1052746,5.32195527 Z M10.100798,5.32195527 L10.0891154,5.18890639 C10.4863246,4.37193957 10.843813,3.2608647 11.0050332,1.77398509 L13.0518292,1.77398509 C12.6686391,2.91307025 11.822817,4.00313741 10.100798,5.32195527 L10.100798,5.32195527 Z"
                id="Shape"
              />
            </g>
            <g
              id="key"
              transform="translate(18.769417, 25.438236) rotate(46.000000) translate(-18.769417, -25.438236) translate(0.929349, 17.050958)"
            >
              <path
                d="M4.77585314,10.2815402 C3.55176793,10.2815402 2.56117442,9.33094742 2.56117442,8.1593866 C2.56117442,6.98758664 3.55176793,6.03699382 4.77585314,6.03699382 C5.9961965,6.03699382 6.98878567,6.98758664 6.98878567,8.1593866 C6.98878567,9.33094742 5.9961965,10.2815402 4.77585314,10.2815402 L4.77585314,10.2815402 Z M32.2919516,5.40302613 L16.4484423,5.40302613 C15.2725023,2.29129938 12.163271,0.0679883119 8.51022356,0.0679883119 C3.8508668,0.0679883119 0.0708457714,3.6900459 0.0708457714,8.1593866 C0.0708457714,12.6284882 3.8508668,16.2505458 8.51022356,16.2505458 C12.1208633,16.2505458 15.1996609,14.077933 16.4057852,11.0226439 L18.76465,10.0804211 L21.4829823,11.1297799 L24.0825731,10.0804211 L26.9176513,11.1297799 L29.517242,10.0804211 L32.2919516,11.1297799 L35.1287759,8.41718259 L32.2919516,5.40302613 Z"
                fill="#E2E4E9"
              />
              <g id="Group-19" transform="translate(1.746199, 1.969915)" fill="#BBC0C7">
                <path
                  d="M30.545778,3.43313474 L30.4851599,3.43313474 L32.7634502,5.85373865 L29.9266258,8.56633599 L27.1519163,7.51697717 L24.552076,8.56633599 L21.7172473,7.51697717 L19.1174071,8.56633599 L16.3993243,7.51697717 L14.0404594,8.45919999 C12.8343351,11.514489 9.75553755,13.6868627 6.14489778,13.6868627 C3.97262674,13.6868627 1.99193862,12.8991261 0.495945345,11.6058416 C2.04083218,13.2480355 4.27671475,14.2806544 6.76404991,14.2806544 C10.3746897,14.2806544 13.4534873,12.1080416 14.6596116,9.05275254 L17.0184764,8.11052972 L19.7365592,9.15988854 L22.3363995,8.11052972 L25.1714776,9.15988854 L27.7710684,8.11052972 L30.545778,9.15988854 L33.3826023,6.44729121 L30.545778,3.43313474 Z"
                  id="Fill-7"
                />
                <path
                  d="M30.5458029,3.43313474 L30.4851849,3.43313474 L32.7634751,5.85373865 L29.9266508,8.56633599 L27.1519412,7.51697717 L24.552101,8.56633599 L21.7172723,7.51697717 L19.1174321,8.56633599 L16.3993492,7.51697717 L14.0404844,8.45919999 C12.8343601,11.514489 9.7555625,13.6868627 6.14492273,13.6868627 C3.97265168,13.6868627 1.99196357,12.8991261 0.495970291,11.6058416 C2.04060767,13.2480355 4.27673969,14.2806544 6.76407486,14.2806544 C10.3747146,14.2806544 13.4532627,12.1080416 14.6596365,9.05275254 L17.0185013,8.11052972 L19.7365842,9.15988854 L22.3364244,8.11052972 L25.1712531,9.15988854 L27.7710933,8.11052972 L30.5458029,9.15988854 L33.3826272,6.44729121 L30.5458029,3.43313474 Z"
                  id="Fill-9"
                />
                <path
                  d="M14.2762461,3.43313474 L14.7020691,3.43313474 C14.2261053,2.06882479 13.286401,0.948440539 12.4487246,0.215228564 C13.1549372,0.975224538 13.9075488,2.45719277 14.2762461,3.43313474"
                  id="Fill-11"
                />
                <path
                  d="M12.4487246,0.215348135 C13.1549372,0.975344109 13.9075488,2.4570732 14.2762461,3.43325431 L14.7020691,3.43325431 C14.2261053,2.06894436 13.286401,0.948320967 12.4487246,0.215348135"
                  id="Fill-13"
                />
                <path
                  d="M2.41047748,3.47352597 C1.18639227,3.47352597 0.195798753,4.42411879 0.195798753,5.59567961 C0.195798753,6.3334353 0.588693436,6.98270813 1.18489553,7.3631844 C0.951403829,7.02694956 0.814950882,6.62351557 0.814950882,6.1894713 C0.814950882,5.01767134 1.8055444,4.06707852 3.02962961,4.06707852 C3.48189504,4.06707852 3.90222999,4.1981288 4.25296645,4.4219665 C3.85608045,3.85065424 3.17880486,3.47352597 2.41047748,3.47352597"
                  id="Fill-15"
                />
                <path
                  d="M2.41050242,3.47352597 C1.18641721,3.47352597 0.195823699,4.42411879 0.195823699,5.59567961 C0.195823699,6.3334353 0.588718382,6.98270813 1.18492047,7.3631844 C0.951428774,7.02694956 0.814975827,6.62351557 0.814975827,6.1894713 C0.814975827,5.01767134 1.80556934,4.06707852 3.02965455,4.06707852 C3.48167053,4.06707852 3.90225493,4.1981288 4.25299139,4.4219665 C3.8561054,3.85065424 3.1788298,3.47352597 2.41050242,3.47352597"
                  id="Fill-17"
                />
              </g>
              <path
                d="M34.5095989,7.8002897 L16.7300543,7.8002897 C16.4356951,7.8002897 16.1947197,7.56927771 16.1947197,7.28708915 L16.1947197,7.28708915 C16.1947197,7.00490059 16.4356951,6.77388859 16.7300543,6.77388859 L33.582118,6.77388859 L34.0650666,7.28708915 L34.5095989,7.8002897 Z"
                id="Fill-20"
                fill="#989898"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default PrivateChallengeLockIcon;
