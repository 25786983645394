export type RecommendationCategory =
  | 'ALCOHOL'
  | 'ANXIETY'
  | 'ALCOHOL'
  | 'ANXIETY'
  | 'ARTICLE'
  | 'BACK'
  | 'BEHAVIORAL.HEALTH'
  | 'BIOMETRIC'
  | 'SPECIFIC.BIOMETRIC'
  | 'BLOOD.PRESSURE'
  | 'BMI'
  | 'CHOLESTEROL'
  | 'DIABETES.SCREENING'
  | 'BIOMETRIC.TARGET'
  | 'CANCER'
  | 'COACHING.PROGRAM'
  | 'COLORECTAL'
  | 'CONDITION.PROGRAM'
  | 'COPD'
  | 'COVID'
  | 'DENTAL'
  | 'DIABETES'
  | 'EAP'
  | 'ER'
  | 'FITNESS'
  | 'FINANCIAL.STRESS'
  | 'HEART'
  | 'HEALTH.LITERACY'
  | 'MENS'
  | 'MISSION'
  | 'MOOD'
  | 'NICOTINE'
  | 'NUTRITION'
  | 'PCP'
  | 'PHS.PROGRAM'
  | 'PHYSICAL.THERAPY'
  | 'PREGNANCY'
  | 'PREVENTATIVE.CARE'
  | 'PROVIDER.CHOICE'
  | 'RX'
  | 'SEXUAL.HEALTH'
  | 'SLEEP'
  | 'STATE.OF.MIND'
  | 'STRESS'
  | 'SURVEY'
  | 'UNIQUENESS.DIABETES.COACHING'
  | 'UNIQUENESS.FITNESS.COACHING'
  | 'UNIQUENESS.MOOD.COACHING'
  | 'UNIQUENESS.NICOTINE.COACHING'
  | 'UNIQUENESS.NUTRITION.COACHING'
  | 'UNIQUENESS.SLEEP.COACHING'
  | 'UNIQUENESS.STRESS.COACHING'
  | 'UNIQUENESS.WEIGHT.MANAGEMENT.COACHING'
  | 'URGENT.CARE'
  | 'VIRTUAL.CARE'
  | 'VISION'
  | 'WEIGHT.MANAGEMENT'
  | 'WOMENS';

export const RecommendationsCategories = {
  Alcohol: 'ALCOHOL' as RecommendationCategory,
  Anxiety: 'ANXIETY' as RecommendationCategory,
  Article: 'ARTICLE' as RecommendationCategory,
  Back: 'BACK' as RecommendationCategory,
  BehavioralHealth: 'BEHAVIORAL.HEALTH' as RecommendationCategory,
  Biometric: 'BIOMETRIC' as RecommendationCategory,
  BiometricBloodPressure: 'BLOOD.PRESSURE' as RecommendationCategory,
  BiometricBmi: 'BMI' as RecommendationCategory,
  BiometricCholesterol: 'CHOLESTEROL' as RecommendationCategory,
  BiometricDiabetesScreening: 'DIABETES.SCREENING' as RecommendationCategory,
  BiometricTarget: 'BIOMETRIC.TARGET' as RecommendationCategory,
  Cancer: 'CANCER' as RecommendationCategory,
  CoachingProgram: 'COACHING.PROGRAM' as RecommendationCategory,
  Colorectal: 'COLORECTAL' as RecommendationCategory,
  ConditionProgram: 'CONDITION.PROGRAM' as RecommendationCategory,
  Copd: 'COPD' as RecommendationCategory,
  Covid: 'COVID' as RecommendationCategory,
  Dental: 'DENTAL' as RecommendationCategory,
  Diabetes: 'DIABETES' as RecommendationCategory,
  Eap: 'EAP' as RecommendationCategory,
  Er: 'ER' as RecommendationCategory,
  Fitness: 'FITNESS' as RecommendationCategory,
  FinancialStress: 'FINANCIAL.STRESS' as RecommendationCategory,
  Heart: 'HEART' as RecommendationCategory,
  HealthLiteracy: 'HEALTH.LITERACY' as RecommendationCategory,
  Mens: 'MENS' as RecommendationCategory,
  Mission: 'MISSION' as RecommendationCategory,
  Mood: 'MOOD' as RecommendationCategory,
  Nicotine: 'NICOTINE' as RecommendationCategory,
  Nutrition: 'NUTRITION' as RecommendationCategory,
  Pcp: 'PCP' as RecommendationCategory,
  PhsProgram: 'PHS.PROGRAM' as RecommendationCategory,
  PhyscialTherapy: 'PHYSICAL.THERAPY' as RecommendationCategory,
  Pregnancy: 'PREGNANCY' as RecommendationCategory,
  PreventativeCare: 'PREVENTATIVE.CARE' as RecommendationCategory,
  ProviderChoice: 'PROVIDER.CHOICE' as RecommendationCategory,
  Rx: 'RX' as RecommendationCategory,
  SexualHealth: 'SEXUAL.HEALTH' as RecommendationCategory,
  Sleep: 'SLEEP' as RecommendationCategory,
  StateOfMind: 'STATE.OF.MIND' as RecommendationCategory,
  Stress: 'STRESS' as RecommendationCategory,
  Survey: 'SURVEY' as RecommendationCategory,
  UniqunessDiabetesCoaching: 'UNIQUENESS.DIABETES.COACHING' as RecommendationCategory,
  UniqunessFitnessCoaching: 'UNIQUENESS.FITNESS.COACHING' as RecommendationCategory,
  UniqunessMoodCoaching: 'UNIQUENESS.MOOD.COACHING' as RecommendationCategory,
  UniqunessNicotineCoaching: 'UNIQUENESS.NICOTINE.COACHING' as RecommendationCategory,
  UniqunessNutritionCoaching: 'UNIQUENESS.NUTRITION.COACHING' as RecommendationCategory,
  UniqunessSleepCoaching: 'UNIQUENESS.SLEEP.COACHING' as RecommendationCategory,
  UniqunessStressCoaching: 'UNIQUENESS.STRESS.COACHING' as RecommendationCategory,
  UniqunessWeightManagementCoaching: 'UNIQUENESS.WEIGHT.MANAGEMENT.COACHING' as RecommendationCategory,
  UrgentCare: 'URGENT.CARE' as RecommendationCategory,
  VirtualCare: 'VIRTUAL.CARE' as RecommendationCategory,
  Vision: 'VISION' as RecommendationCategory,
  WeightManagement: 'WEIGHT.MANAGEMENT' as RecommendationCategory,
  Womens: 'WOMENS' as RecommendationCategory,
};

export type CtaType = 'Web' | 'Event' | 'Phone' | 'Token' | 'SSO';
export const CtaTypes = {
  Web: 'Web' as CtaType,
  Event: 'Event' as CtaType,
  Phone: 'Phone' as CtaType,
  Token: 'Token' as CtaType,
  SSO: 'SSO' as CtaType,
};

export type EntityType = 'Article' | 'Mission' | 'Program';
export const EntityTypes = {
  Article: 'Article' as EntityType,
  Mission: 'Mission' as EntityType,
  Program: 'Program' as EntityType,
};
export interface Location {
  latitude: number;
  longitude: number;
}
export interface Network {
  networkType: string;
  id: string;
  planCode: string;
}
export interface QueryOptions {
  searchQuery?: string;
  queryCategory?: string;
  specialtyNames?: string[];
  location: Location;
  network: Network;
}
export interface RecommendationsQueryParams {
  limit?: number;
  applicable?: boolean;
  satisfied?: boolean;
  suppression?: string;
  incentives?: boolean;
}
export interface RecommendationsReqBody {
  categories: RecommendationCategory[];
  rallyId: string;
  partner: string;
  product: string;
  platform: string;
  placementId: string;
  eligibilityId?: string;
  searchOptions?: QueryOptions;
}
export interface CompletionInfo {
  source: string;
  completionDate: Date;
  testType?: string;
  rewardCopyOption?: string;
}

export interface SuppressionInfo {
  suppressionReason: string;
  snoozeUntilDate?: Date;
}

export interface OrigSuppressionInfo {
  suppressionInfo: SuppressionInfo;
}

export interface DisplayTestType {
  name: string;
  displayName: string;
}

export interface RewardInfo {
  copy: string;
}

export interface ContentInfo {
  headline: string;
  body: string;
  ctaType: CtaType;
  ctaText: string;
  ctaValue: string;
  ctaCustomEvent: [string, string];
  primaryImage: string;
  secondaryImage: string;
}
export interface ProbabilityToEngageMetadata {
  computedTime: Date;
  modelName: string;
  modelVersion: string;
  source: string;
}
export interface ProbabilityToEngage {
  probability: number;
  metadata: ProbabilityToEngageMetadata;
}
export interface RankingMetaData {
  configRankingFactor: number;
  configRankingFactorSource: unknown;
  probabilityToEngage?: ProbabilityToEngage;
}

export interface RecommendationItem {
  rallyId: string;
  recommendationId: string;
  applicable: boolean;
  origApplicability: boolean;
  satisfied: boolean;
  rank: number;
  rankingMetaData: RankingMetaData;
  score: number;
  categories: RecommendationCategory[];
  contentInfo: ContentInfo;
  completionInfo?: CompletionInfo;
  suppressionInfo?: SuppressionInfo;
  origSuppressionInfo: OrigSuppressionInfo;
  applicableTestTypes: DisplayTestType[];
  asOfDate: Date;
  rewardInfo?: RewardInfo;
  configHash: number;
  entityType?: EntityType;
  entityId?: string;
}

export type RankingScheme = 'single' | 'a_b_hash';
export const RankingShemes = {
  single: 'Single' as RankingScheme,
  a_b_hash: 'a_b_hash' as RankingScheme,
};

export type RankingAlgo = 'unifiedRankWithPropensity';
export const RankingAlgos = {
  unifiedRankWithPropensity: 'unifiedRankWithPropensity' as RankingAlgo,
};
export interface RecommendationResponse {
  rallyId: string;
  hashId: string;
  items: RecommendationItem[];
  asOfDate: string;
  rankingScheme: RankingScheme;
  rankingAlgo: RankingAlgo;
}
