import React from 'react';
import { useFormContext } from 'react-hook-form';
import { UseRegisterProps } from './forms.interface';

const useRegister = (props: UseRegisterProps, ref?: React.ForwardedRef<HTMLInputElement | HTMLTextAreaElement>) => {
  const { register } = useFormContext();

  const validation = props.validation || { required: false };

  const { ref: _ref, onBlur: handleBlur, onChange: handleChange, ...rest } = register(props.name, validation);

  const onBlur: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    handleBlur(event);
    props.onBlur?.(event);
  };

  const onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    handleChange(event);
    props.onChange?.(event);
  };

  return {
    ...rest,
    ref: (element: HTMLInputElement | HTMLTextAreaElement) => {
      _ref(element);
      if (typeof ref === 'function') {
        ref(element);
      } else if (ref) {
        ref.current = element;
      }
    },
    onBlur,
    onChange,
  };
};

export default useRegister;
