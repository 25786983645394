/**
 * @class BaseModel
 *
 * @description Base Model is a design pattern to handle and normalize the transfer
 * of data between the BE to FE. This will allow users to sanitize their API data
 * by providing default values to our application data. This helps with avoiding
 * unnecessary conditional logic to check for undefined values. Its better to be
 * proactive than reactive.
 *
 * As well we can cherry pick properties from nested API response data in order to provide
 * a flatten object with only the necessary properties and values our application needs.
 * This allows us to provide our consumer components with the precise data they need
 * to work with. See example below.
 *
 * By being able to conform API response data to our FE models, FE devs do not need to
 * worry about refactoring their code when response data changes (unless the property
 * names changes, but still this can be overridden in the update() method).
 *
 * CodeSandBox: https://codesandbox.io/s/basemodel-dtv5o
 *
 * Inspired from this article: https://medium.com/swlh/protect-your-javascript-applications-from-api-data-401a73c7c80b
 * and this Github repo: https://github.com/codeBelt/sjs-base-model
 * sjs-base-model is missing the ability to cherry pick properties in nested objects.
 *
 * @author Alexi Taylor
 *
 * @example
 *
 * const apiResponseData = {
 *   breed: 'Border Collie',
 *   name: 'Collins',
 *   dob: '01/26/2014',
 *   info: {
 *     unnecessaryPropA: 'something',
 *     eyeColor: 'blue',
 *     unnecessaryPropB: 'something'
 *   }
 *   humanFriend: {
 *     firstName: 'Alexi',
 *     lastName: 'Taylor'
 *   },
 * };
 *
 * class Human extends BaseModel<Human> {
 *   public readonly firstName = '';
 *   public readonly lastName = '';
 *
 *   // Client side props (not from API request data)
 *   public fullName: string = '';
 *
 *   constructor(data) {
 *     super(data);
 *
 *     this.update(data);
 *   }
 *
 *   // @overridden BaseModel.update
 *   update(data) {
 *     super.update(data);
 *
 *     this.fullName = `${this.firstName} ${this.lastName}`;
 *  }
 * }
 *
 * class Dog extends BaseModel<Dog> {
 *   public readonly breed = '';
 *   public readonly name = '';
 *   public readonly dob = '';
 *   // Since apiResponseData does not contain weight property, it will default to 0.
 *   public readonly weight = 0;
 *   // BaseModel will cherry pick the nested eyeColor prop from the response data.
 *   public readonly eyeColor = '';
 *   // Assigning reference of the models, not instantiating them:
 *   public readonly humanFriend = Human as any;
 *
 *   constructor(data) {
 *     super(data);
 *
 *     this.update(data);
 *   }
 *
 *   // @overridden BaseModel.update
 *   update(data) {
 *     super.update(data);
 *   }
 * }
 *
 * const dog = new Dog(apiResponseData);
 * // dog => {
 *   breed: 'Border Collie',
 *   name: 'Collins',
 *   dob: '01/26/2014',
 *   weight: 0,
 *   breed: 'Border Collie',
 *   eyeColor: 'blue',
 *   humanFriend: {
 *     fullName: 'Alexi Taylor',
 *     firstName: 'Alexi',
 *     lastName: 'Taylor'
 *   }
 * }
 * */
/* eslint-disable */
export class BaseModel<T> {
  /**
   * @property IS_BASE_MODEL
   * @description This property helps distinguish a BaseModel from other functions.
   */
  public static readonly IS_BASE_MODEL: boolean = true;
  /**
   * @property store
   * @description Temporary storage to quickly access pre-visited prop/value data
   * when searching for nested items. Used only when constructing
   */
  private store: any = {};

  public update(data: any = {}): any {
    const dataToUse: { [propertyName: string]: any } = data || {};

    Object.keys(this).forEach((propertyName: string) => {
      if (propertyName !== 'store') {
        const currentPropertyData: any = this[propertyName];
        let passedInDataForProperty: any = dataToUse[propertyName];
        let propertyValue: any;

        // Handle user defined data models that extend BaseModel
        if (this.isBaseClassInstance(currentPropertyData)) {
          propertyValue = new currentPropertyData(passedInDataForProperty);
        } else {
          // Handle array data structures
          if (Array.isArray(currentPropertyData)) {
            propertyValue = this.getArrayPropertyData(currentPropertyData, passedInDataForProperty);
          } else if (!dataToUse.hasOwnProperty(propertyName)) {
            passedInDataForProperty = this.getPropertyData(dataToUse, propertyName);
          }

          propertyValue = propertyValue || passedInDataForProperty || currentPropertyData;
        }

        this[propertyName] = propertyValue;
      }
    });
    delete this.store;
    return this;
  }

  /**
   * @method getClassName
   * @description Gets the class/model name.
   * @returns {string}
   */
  public getClassName(): string {
    return (this as any).constructor.name;
  }

  /**
   * @method toJSONString
   * @desc Converts a  Base Model to a JSON string,
   * @returns {string}
   */
  public toJSONString(): string {
    return JSON.stringify(this);
  }

  /**
   * @method fromJSON
   * @desc Converts json string and updates model
   * @param json {string}
   */
  public fromJSON(json: string): any {
    const parsedData: any = JSON.parse(json);

    this.update(parsedData);

    return parsedData;
  }

  /**
   *
   * @method getPropertyData
   * @description Gets property value from store or dataToUse (API response data)
   * @param {any} dataToUse API Response data. Data to santize.
   * @param {string} propertyName Prop to search for and return value
   * @returns {any}
   */
  private getPropertyData(dataToUse: any, propertyName: string): any {
    // If property does not exist on response data, first check if the store
    // has it else search for it in nested response data.
    return this.isDefined(this.store) && this.store.hasOwnProperty(propertyName)
      ? this.store[propertyName]
      : this.getObjectPropertyData(dataToUse, propertyName);
  }

  /**
   *
   * @method getObjectPropertyData
   * @description finds the value of a given prop name in API response data.
   * @param {any} obj API Response data. Data to santize.
   * @param {string} propertyName Prop to search for and return value
   * @returns {any}
   */
  private getObjectPropertyData(obj: any, propertyName: string): any {
    for (let key in obj) {
      let data = obj[key];

      if (this.isObject(data)) {
        data = this.getPropertyData(data, propertyName);
        if (this.isDefined(data)) {
          return data;
        }
      }

      if (this.isDefined(this.store)) {
        this.store[key] = data;
      }

      if (key === propertyName) {
        return data;
      }
    }
  }

  /**
   *
   * @method getArrayPropertyData
   * @description Handle array data structures
   * @param {any[]} currentPropertyData
   * @param {any[] | undefined} passedInDataForProperty
   * @returns {any[]}
   */
  private getArrayPropertyData(currentPropertyData: any[], passedInDataForProperty: any[] | undefined): any[] {
    const firstItemInArray: any = currentPropertyData[0];
    let propertyValue: any[];
    // If array data is undefined or empty, returns empty array
    if (
      !this.isDefined(passedInDataForProperty) ||
      !this.isDefined(firstItemInArray) ||
      passedInDataForProperty.length === 0
    ) {
      propertyValue = [];
    } else if (
      this.isBaseClassInstance(firstItemInArray) &&
      this.isDefined(passedInDataForProperty) &&
      passedInDataForProperty.length > 0
    ) {
      propertyValue = passedInDataForProperty.map((data: object) => new firstItemInArray(data));
    }

    return propertyValue || passedInDataForProperty;
  }

  /**
   * @method isObject
   * @description Checks if passed in prop is an object or not
   * @param {any} obj
   * @returns {boolean}
   */
  private isObject(object: any): boolean {
    return object != null && object.constructor.name === 'Object';
  }

  /**
   * @method isBaseClassInstance
   * @description Checks if property data is instance of BaseModel
   * @param {any} currentPropertyData
   * @returns {boolean}
   */
  private isBaseClassInstance(currentPropertyData: any): boolean {
    return typeof currentPropertyData === 'function' && currentPropertyData.IS_BASE_MODEL;
  }

  /**
   * @method isDefined
   * @description returns whether or not a value is defined (not null or undefined)
   * @param {any} value
   * @return {boolean}
   */
  private isDefined(value: any): boolean {
    return typeof value !== 'undefined' && value !== null;
  }
}

export default BaseModel;
