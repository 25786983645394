import { Profile } from './profile.models';
import { Status } from '@health-activity-ui/shared';

export interface ProfileState {
  data: Profile;
  status: Status;
}

export enum PROFILE_ACTION_TYPES {
  PROFILE_FETCH_FAILED = 'PROFILE_FETCH_FAILED',
  PROFILE_FETCH_LOADING = 'PROFILE_FETCH_LOADING',
  PROFILE_FETCH_LOADED = 'PROFILE_FETCH_LOADED',
  PROFILE_UPDATE_AVATAR_LOADED = 'PROFILE_UPDATE_AVATAR_LOADED',
}
