import React from 'react';
import { IStepper, StepperStatus } from './stepper.models';
import { StepperStyled } from './stepper.styled';
import classnames from 'classnames';

interface Props {
  steps: IStepper[];
}

export const Stepper = ({ steps }: Props) => {
  return (
    <StepperStyled>
      <ol>
        {steps.map((step, idx) => (
          <li key={idx} className={classnames(idx !== steps.length - 1 ? 'step' : 'step-last')}>
            <div className="stepper-progress-line" aria-hidden="true">
              <div
                className={classnames('stepper-progress-line-inner', {
                  'stepper-progress-line-bg-complete': step.status === StepperStatus.Complete,
                  'stepper-progress-line-bg-current': step.status === StepperStatus.Current,
                  'stepper-progress-line-bg-upcoming': step.status === StepperStatus.Incomplete,
                })}
              />
            </div>
            <div
              className={classnames('stepper-circle', {
                'stepper-circle-complete': step.status === StepperStatus.Complete,
                'stepper-circle-current': step.status === StepperStatus.Current,
                'stepper-circle-upcoming': step.status === StepperStatus.Incomplete,
              })}
            >
              <span
                className={classnames('is-rds-sm stepper-circle-text', {
                  'stepper-circle-text-complete': step.status === StepperStatus.Complete,
                  'stepper-circle-text-current': step.status === StepperStatus.Current,
                  'stepper-circle-text-upcoming': step.status === StepperStatus.Incomplete,
                })}
                aria-hidden="true"
              >
                {step.content}
              </span>
              <span className="is-sr-only">{step?.srText ?? `Step ${idx + 1} ${step.status}`}</span>
            </div>
          </li>
        ))}
      </ol>
    </StepperStyled>
  );
};

export default Stepper;
