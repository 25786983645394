import { isDefined } from '@health-activity-ui/utils';
import { RdsButton } from '@rally/energon-react/dist/es6';
import { RDS_COLOR_BLACK } from '@rally/ui-themes/dist/web/rally';
import React, { useContext } from 'react';
import { FocusTrap } from '../focus-trap/focus-trap';
import { Level } from '../layout/level/level';
import { LevelSide } from '../layout/level/components/level-side/level-side';
import { Link } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import { PropsWithChildren } from '@health-activity-ui/shared';

/* .lock-scroll => Lock the scroll position by adding this class to the `<html>` element. */
const ModalGlobalStyles = createGlobalStyle`
  .at-rally-modal {
    background-color: ${RDS_COLOR_BLACK}70;
    height: 100%;
    left: 0;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    top: 0;
    z-index: 1000;

    .at-rally-modal-content {
      max-height: 100%;
      overflow-y: auto;
      z-index: 9999;
    }
  }

  .at-rally-modal-body {
    overflow: auto;
  }

  .at-rally-modal-close {
    border: 0;
    box-shadow: none;
    cursor: pointer;
    line-height: 1;
  }

  .at-rally-modal-close-icon {
    fill: transparent;
    height: 45px;
    stroke: #b2b2b2;
    stroke-linecap: round;
    stroke-width: 1;
    width: 45px;
  }

  .lock-scroll {
    overflow: hidden !important;
  }
`;

interface State {
  isOpen: boolean;
  openButtonRef: React.MutableRefObject<HTMLButtonElement>;
  closeButtonRef: React.MutableRefObject<HTMLButtonElement>;
  onClose(): void;
}

// Create a ModalContext with React.createContext
const ModalContext = React.createContext<State>({
  isOpen: false,
  openButtonRef: null,
  closeButtonRef: null,
  onClose: () => {},
});

export const useModalContext = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error(`useModalContext must be used within the ModalProvider`);
  }

  return context;
};

// Context object accepts a displayName string property.
// React DevTools uses this string to determine what to display
// for the context.
ModalContext.displayName = 'Modal';

interface Props {
  buttonAriaLabel?: string;
  openButton?: React.ReactElement | ((isOpen: boolean) => React.ReactElement);
  children: React.ReactElement | React.ReactElement[];
  className?: string;
  onCloseCb?(): void;
  isOpen?: boolean;
  maxWidth?: string;
}
/**
 * @name RallyModal
 * @author Alexi Taylor
 *
 * @desc RallyModal uses the flexible compound component pattern with React's Context API.
 * A Rally, re-usable modal component. The modal component adheres to a11y best standards.
 * The component has it's own default styling, but can easily be overridden by passing
 * `className` props to the desired section of the component.
 *
 * The `RallyModal component has five sub-components allowing for granularity over the
 * layout, styling, and sub-components of the modal.
 * 1. `RallyModal.Body`: Houses the main content (including the header) of the modal.
 * 2. `RallyModal.Footer`: This should be used to layout the below sub-component buttons.
 * 3. `RallyModal.CloseButton`: Closes the modal. Accepts an optional`onClick` prop
 * before closing the modal.
 * 4. `RallyModal.ActionButton`: Action button that accepts an optional `onClick` prop. Upon
 * a user action, it will first invoke the `onClick` function then close the modal.
 * 5. `RallyModal.ActionLink`:  It uses the react-router's `Link` component so on user
 * action it will navigate to another page and close the modal.
 *
 * CodeSandBox example: https://codesandbox.io/s/modal-two-custom-focus-trap-1ihok)
 *
 * @example:
 <Modal openButton={<button>Open Modal</button>}>
 <Modal.Body>
 <h1>Body</h1>
 <div>
 <p style={{ marginBottom: 0 }}>Content</p>
 </div>
 </Modal.Body>
 <Modal.Footer>
 <Modal.ActionButton>Action</Modal.ActionButton>
 <Modal.CloseButton>Close</Modal.CloseButton>
 </Modal.Footer>
 </Modal>
 * */
export const RallyModal = ({
  children,
  buttonAriaLabel = 'close modal',
  openButton,
  className = '',
  onCloseCb = (): void => {},
  isOpen = false,
  maxWidth = '624px',
}: Props): React.ReactElement => {
  const openButtonRef = React.useRef<HTMLButtonElement>(null);
  const closeButtonRef = React.useRef<HTMLButtonElement>(null);
  const modalRef = React.useRef<HTMLDivElement>(null);
  const [state, setState] = React.useState<State>({
    isOpen: false,
    openButtonRef,
    closeButtonRef,
    onClose: () => {},
  });

  const toggleScrollLock = (): void => {
    document.querySelector('html').classList.toggle('lock-scroll');
  };

  const onOpen = (): void => {
    setState({
      ...state,
      isOpen: true,
    });
  };

  const onClose = (): void => {
    onCloseCb();
    setState({
      ...state,
      isOpen: false,
    });
    openButtonRef?.current?.focus();
    toggleScrollLock();
  };

  const renderOpenButton = (): React.ReactNode => {
    if (typeof openButton === 'function') {
      const comp = openButton(state.isOpen);
      const triggerProps = {
        ref: openButtonRef,
        onClick: (): void => {
          comp.props?.onClick();
          onOpen();
        },
      };
      return !!openButton && React.cloneElement(comp, triggerProps);
    } else {
      const triggerProps = {
        ref: openButtonRef,
        /* eslint-disable-next-line */
        onClick: (): void => {
          openButton.props?.onClick();
          onOpen();
        },
      };
      return !!openButton && React.cloneElement(openButton, triggerProps);
    }
  };

  // memoized so that providerState isn't recreated on each render
  const providerState = React.useMemo(
    () => ({
      ...state,
      onClose,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  );
  /* eslint-disable-next-line */
  React.useEffect(() => {
    if (state.isOpen) {
      closeButtonRef?.current?.focus();
      toggleScrollLock();
    }
  }, [state]);

  React.useEffect(() => {
    if (isDefined(isOpen)) {
      setState({
        ...state,
        isOpen,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <React.Fragment>
      <ModalGlobalStyles />
      {renderOpenButton()}
      {state.isOpen && (
        <FocusTrap onClose={onClose}>
          <aside
            className={`at-rally-modal has-rds-text-charcoal is-align-items-center is-flex is-justify-content-center is-overlay ${className}`}
            role="dialog"
            aria-modal="true"
            ref={modalRef}
            data-testid="at-rally-modal"
          >
            <div
              style={{ maxWidth }}
              className="at-rally-modal-content has-rds-bg-white has-rds-radius-4 has-rds-box-shadow-neutral-spread-6 has-rds-text-charcoal is-rds-body-3 has-rds-m-0 has-rds-p-0 is-relative has-text-left"
            >
              <Level className="has-rds-mb-none" isMobile>
                <LevelSide side="left" />
                <LevelSide side="right">
                  <button
                    className="at-rally-modal-close has-rds-p-16 has-rds-pb-none"
                    aria-label={buttonAriaLabel}
                    onClick={onClose}
                    ref={closeButtonRef}
                    data-testid="modal-close-btn"
                  >
                    <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" className="at-rally-modal-close-icon">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1}
                        d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </button>
                </LevelSide>
              </Level>

              <ModalContext.Provider value={providerState}>{children}</ModalContext.Provider>
            </div>
          </aside>
        </FocusTrap>
      )}
    </React.Fragment>
  );
};

const ModalBody = ({ children }): React.ReactElement => {
  return (
    <div className="at-rally-modal-body is-rds-body-1 has-rds-ph-24 has-rds-pb-16 has-rds-pt-none">{children}</div>
  );
};

const ModalFooter = ({ children }): React.ReactElement => (
  <div className="has-rds-p-24 has-rds-bg-white-bis">{children}</div>
);

interface ButtonProps extends PropsWithChildren {
  id?: string;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  onClickCb?: (e?: React.MouseEvent<HTMLButtonElement>) => unknown;
  onBlurCb?: (e?: React.MouseEvent<HTMLButtonElement>) => unknown;
  buttonRef?: React.LegacyRef<HTMLButtonElement>;
  ariaLabel?: string;
}

const CloseButton = ({ children, className = '', ariaLabel, onClickCb = (): void => {}, ...props }: ButtonProps) => {
  const { onClose } = React.useContext(ModalContext);
  return (
    <RdsButton
      className={`at-rally-modal-close-button ${className}`}
      variant="secondary"
      aria-label={ariaLabel}
      data-testid="modal-close-btn-two"
      onClick={(): void => {
        onClickCb();
        onClose();
      }}
      {...props}
    >
      {children}
    </RdsButton>
  );
};

const ActionButton = ({ children, className = '', ariaLabel, onClickCb = (): void => {}, ...props }: ButtonProps) => {
  const { onClose } = React.useContext(ModalContext);
  return (
    <RdsButton
      className={`at-rally-modal-action-button has-rds-mr-16 ${className}`}
      variant="primary"
      data-testid="modal-action-btn"
      aria-label={ariaLabel}
      onClick={(): void => {
        onClickCb();
        onClose();
      }}
      {...props}
    >
      {children}
    </RdsButton>
  );
};

interface LinkProps extends PropsWithChildren {
  to: string;
  id?: string;
  className?: string;
  disabled?: boolean;
  buttonRef?: React.LegacyRef<HTMLButtonElement>;
  ariaLabel?: string;
}

const ActionLink = ({ to, children, className = '', ariaLabel, ...props }: LinkProps) => {
  const { onClose } = React.useContext(ModalContext);
  return (
    <Link
      className={`at-rally-modal-action-button rds-primary-button is-white-labeled-btn has-rds-mr-16 ${className}`}
      aria-label={ariaLabel}
      to={to}
      onClick={onClose}
      {...props}
    >
      {children}
    </Link>
  );
};

// Modal.Header = ModalHeader;
RallyModal.Body = ModalBody;
RallyModal.Footer = ModalFooter;
RallyModal.CloseButton = CloseButton;
RallyModal.ActionButton = ActionButton;
RallyModal.ActionLink = ActionLink;

export default RallyModal;
