import styled from '@emotion/styled';
import {
  AllColors,
  BorderRadiusProps,
  BoxShadowProps,
  PaddingSizes,
  PropsWithChildren,
} from '@health-activity-ui/shared';
import { RdsIcon } from '@rally/energon-react/dist/es6';
import { RDS_SPACING_SIZE_16, RDS_SPACING_SIZE_8 } from '@rally/ui-themes/dist/web/rally';
import classnames from 'classnames';
import React, { useState } from 'react';
import { RdsCard } from '../card/card';
import { useTranslation } from 'react-i18next';

const LayeredCardContainer = styled(RdsCard)`
  .check-circle {
    right: -${RDS_SPACING_SIZE_8};
    top: -${RDS_SPACING_SIZE_16};
  }
`;
export interface LayeredCardProps extends PropsWithChildren {
  bgColor?: AllColors;
  hasCheck?: boolean;
  highlightColor?: AllColors;
  headerAccentSize?: PaddingSizes;
  hasRandomBgColor?: boolean;
  height?: string;
  highlighted?: boolean;
  radius?: BorderRadiusProps;
  shadow?: BoxShadowProps;
}

export const LayeredCard = ({
  children,
  bgColor = 'grey-lightest',
  highlightColor = 'info-light',
  hasRandomBgColor = false,
  hasCheck = false,
  headerAccentSize = '64',
  height = '332px',
  highlighted = false,
  radius = '8',
  shadow = 'spread-6',
}: LayeredCardProps) => {
  const { t } = useTranslation();
  // use lazy initialization so that randomize isn't ran every time
  const [colorValue] = useState(() => (hasRandomBgColor ? Math.floor(Math.random() * 5) + 1 : undefined));

  // this is the distance from the top of card to the top of the 'foreground' element;
  // half the headerAccentSize defaults to -32px
  const [marginTop] = useState(() => `${-1 * (parseInt(headerAccentSize, 10) / 2)}px`);

  return (
    <LayeredCardContainer
      className={classnames('background is-relative', {
        [`has-rds-bg-${bgColor}`]: bgColor && !hasRandomBgColor,
        [`has-rds-bg-${highlightColor}`]: highlightColor && highlighted && !hasRandomBgColor,
        'has-rds-bg-danger': colorValue === 1 && hasRandomBgColor,
        'has-rds-bg-info': colorValue === 2 && hasRandomBgColor,
        'has-rds-bg-sunset-orange-light': colorValue === 3 && hasRandomBgColor,
        'has-rds-bg-success': colorValue === 4 && hasRandomBgColor,
        'has-rds-bg-warning': colorValue === 5 && hasRandomBgColor,
        [`has-rds-pt-${headerAccentSize}`]: headerAccentSize,
      })}
      radius={radius}
      shadow={shadow}
    >
      <RdsCard
        renderAs="div"
        className="foreground has-index-1 is-overlay"
        shadow="none"
        bgColor="white"
        radius={radius}
        style={{ top: `${headerAccentSize}px` }}
      />
      <div className="has-index-2 is-relative" style={{ marginTop, height }}>
        {hasCheck ? (
          <RdsIcon
            className="has-rds-text-primary-dark check-circle is-absolute"
            data-testid="selected"
            name="indicator-yes-filled"
            title={t('communitiesCheckmark')}
            size="32"
          />
        ) : (
          <span title={t('communitiesNoCheckmark')} />
        )}
        {children}
      </div>
    </LayeredCardContainer>
  );
};
