import { NowStep, NOW_BASE_URI } from '@health-activity-ui/shared';
import apiClient from './api-client';

/**
 * @param stepName
 * @returns On success, returns the URL where the Now service wants the user to be redirected to after the user has
 * completed the given step.
 */
export const postCompleteStepWithoutRedirect = async (stepName: NowStep): Promise<string> => {
  const data = { stepName };
  const response = await apiClient<{ stepName: NowStep }>(`${NOW_BASE_URI}/step/complete`, { method: 'POST', data });
  return response.headers['rally_now_redirect'];
};
