import React from 'react';
import { PropsWithChildren } from '@health-activity-ui/shared';

interface Props extends PropsWithChildren {
  width?: string;
  height?: string;
}

export const KindnessIcon = ({ width, height }: Props): React.ReactElement => (
  <svg
    width={width ? width : '72px'}
    height={height ? height : '81px'}
    viewBox="0 0 72 81"
    aria-hidden="true"
    data-testid="kindness-icon"
  >
    <title>Kindess Icon</title>
    <defs>
      <g id="mask-2">
        <polygon id="path-1" fill="#fff" fillRule="evenodd" points="0 0 33.36 0 33.36 80.19 0 80.19 0 0" />
      </g>
      <g id="mask-4">
        <polygon id="path-3" fill="#fff" fillRule="evenodd" points="38 0 71.36 0 71.36 80.19 38 80.19 38 0" />
      </g>
    </defs>
    <g id="Group-7">
      <g id="Group-3">
        <g>
          <path
            id="Fill-1"
            fill="#fff"
            fillRule="evenodd"
            d="M16.91,25a5.5,5.5,0,0,0-7.78,7.78L24.59,48.24l-1.42,1.41L7.72,34.2a7.5,7.5,0,0,1,0-10.61A7.42,7.42,0,0,1,11,21.68V5.5a5.5,5.5,0,0,0-11,0v36H0l0,0L15.36,56.91V80.19h18V41.46Z"
          />
        </g>
      </g>
      <g id="Group-6">
        <g>
          <path
            id="Fill-4"
            fill="#fff"
            fillRule="evenodd"
            d="M54.46,25a5.5,5.5,0,0,1,7.77,7.78L46.78,48.24l1.41,1.41L63.65,34.2a7.52,7.52,0,0,0,0-10.61,7.42,7.42,0,0,0-3.28-1.91V5.5a5.5,5.5,0,1,1,11,0v36h0l0,0L56,56.91V80.19H38V41.46Z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default KindnessIcon;
