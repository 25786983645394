import {
  analyticsService,
  errorNotification,
  infoNotification,
  successNotification,
  useCommon,
  useProfile,
  warningNotification,
} from '@health-activity-ui/data-access';
import { generateUniqueKey, getUserLocale } from '@health-activity-ui/utils';
import { AdditionalData, PageArgs } from '@rally/analytics/dist/models/events';
import { MutableRefObject, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { sendTracking, useTracking } from '../tracking/tracking-provider';
import { useDocumentTitle } from './use-document-title.hook';
import { useSafeDispatch } from './use-safe-dispatch.hook';

interface TitleTranslationOptions {
  title: string;
}
interface UseCoreProps {
  pageTitle?: string;
  pageTrackingRef?: MutableRefObject<{
    pageName: string;
    pageTags?: string[];
    pageArgs?: PageArgs;
    additionalData?: AdditionalData;
  }>;
  titleTranslationOptions?: TitleTranslationOptions;
  trackManually?: boolean; // use when async data is needed before sending a track event
}

export function useCore(
  { pageTitle = '', pageTrackingRef = null, titleTranslationOptions = {}, trackManually = false } = {} as UseCoreProps
) {
  const { t } = useTranslation();
  const userLocale = getUserLocale();
  const location = useLocation();
  const navigate = useNavigate();
  const { state: commonState, dispatch: commonUnsafeDispatch, curBannerId } = useCommon(generateUniqueKey());
  const commonDispatch = useSafeDispatch(commonUnsafeDispatch);
  const { state: trackingState, dispatch: trackingDispatch } = useTracking();
  const { state: profile } = useProfile();
  const { rallyId, client, partner, sessionId, profileName, avatar } = profile.data;

  useDocumentTitle(t(pageTitle, titleTranslationOptions));

  const updatePageNav = useCallback(() => {
    if (pageTrackingRef && pageTrackingRef.current) {
      analyticsService.trackPageNav(
        pageTrackingRef.current.pageName,
        pageTrackingRef.current.pageTags,
        pageTrackingRef.current.pageArgs,
        pageTrackingRef.current.additionalData
      );
    }
  }, [pageTrackingRef]);

  useEffect(() => {
    // by default useCore will send a page nav event automatically,
    // however, when async data is needed before sending a page nav event
    // we can call `updatePagenav` directly, trackManually allows us to do so.
    if (!trackManually) {
      updatePageNav();
    }
  }, [trackManually, updatePageNav]);

  return {
    avatarUrl: avatar,
    userLocale,
    t,
    rallyId,
    sessionId,
    profileName,
    partner,
    client,
    location,
    commonDispatch,
    commonState,
    curBannerId,
    trackingState,
    trackingDispatch,
    sendTracking,
    successNotification,
    errorNotification,
    infoNotification,
    warningNotification,
    navigate,
    updatePageNav,
  };
}
