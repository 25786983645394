import { RdsIcon } from '@rally/energon-react';
import classnames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CarouselItem, Props } from './carousel-selector-input.models';
import { CarouselSelectorInputStyled } from './carousel-selector-input.styled';

export const CarouselSelectorInput = ({
  carouselItems,
  carouselName,
  onChange = (selected: CarouselItem) => null,
  initialSelection,
}: Props) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<CarouselItem>(null);
  const slider = useRef<HTMLInputElement>();

  useEffect(() => {
    if (initialSelection) {
      const currentPos = carouselItems.findIndex(({ id }) => id === initialSelection?.id);
      setSelected(carouselItems[currentPos]);
    } else {
      setSelected(carouselItems[0]);
    }
  }, [initialSelection, carouselItems, setSelected]);

  const getIndexById = (itemId: string): number => carouselItems.findIndex(({ id }) => id === itemId);

  const onSelectHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    // Target value is actually CarouselItem.id
    const updatedIndex = carouselItems.findIndex(({ id }) => id === e.target.value);
    slider.current.value = updatedIndex.toString();
    setSelected(carouselItems[updatedIndex]);
  };

  const onSliderChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const sliderIndexValue = Number.parseInt(e.target.value, 10);
    setSelected(carouselItems[sliderIndexValue]);
  };

  const handleArrowClick = (e: React.MouseEvent, direction: 'back' | 'next'): void => {
    e.preventDefault();
    const d = direction === 'back' ? -1 : 1;
    const updatedIndex = getIndexById(selected?.id) + d;
    slider.current.value = updatedIndex.toString();
    setSelected(carouselItems[updatedIndex]);
  };

  const setClass = (ordinalIndex: number): string => {
    const place = ordinalIndex - getIndexById(selected?.id);
    switch (true) {
      case place < -2:
        return 'left-side size-three';
      case place < -1:
        return 'left-side size-two';
      case place < 0:
        return 'left-side size-one';
      case place === 0:
        return 'center-zero';
      case place < 2:
        return 'right-side size-one';
      case place < 3:
        return 'right-side size-two';
      default:
        return 'right-side size-three';
    }
  };

  useEffect(() => {
    if (onChange) {
      onChange(selected);
    }
  }, [selected, onChange]);

  return (
    <CarouselSelectorInputStyled className="carousel-input">
      <div className="carousel" role="radiogroup">
        {carouselItems.map(({ id, imgSrc, label }, index) => (
          <label className={classnames(`carousel-item ${setClass(index)}`, { selected: id === selected?.id })} key={id}>
            <span className="is-sr-only">{label ?? `Option #${index + 1}`}</span>
            <input
              className="carousel-item-input"
              checked={id === selected?.id}
              value={id}
              id={`carousel-radio-input-${id}`}
              name={carouselName}
              onChange={onSelectHandler}
              type="radio"
            />
            <img alt="" src={imgSrc} className="carousel-item-image" />
          </label>
        ))}
      </div>

      <div className="controls">
        <button
          aria-label={t('previous')}
          className="previous-item"
          disabled={getIndexById(selected?.id) === 0}
          onClick={(e) => handleArrowClick(e, 'back')}
        >
          <RdsIcon name="system-pagination-arrow-left" />
        </button>
        <input
          className="slider"
          value={getIndexById(selected?.id)}
          max={carouselItems.length - 1}
          min={0}
          onChange={onSliderChange}
          ref={slider}
          type="range"
        />
        <button
          aria-label={t('next')}
          className="next-item"
          disabled={getIndexById(selected?.id) === carouselItems.length - 1}
          onClick={(e) => handleArrowClick(e, 'next')}
        >
          <RdsIcon name="system-pagination-arrow-right" />
        </button>
      </div>
    </CarouselSelectorInputStyled>
  );
};

export default CarouselSelectorInput;
