import { RegistrationField, ValidationCriteria, ViewType } from '@health-activity-ui/shared';
import { registrationFieldValidate } from '../api/funnel.api';

export const validateRegistrationFieldOn = async <T>(
  field: RegistrationField<T>,
  viewType: ViewType,
  validationCriteria: Array<ValidationCriteria>,
  disableCache = false
): Promise<Array<ValidationCriteria>> => {
  const validationPromises: Array<Promise<ValidationCriteria | null>> = validationCriteria.map((criteria) =>
    registrationFieldValidate(field, criteria, viewType, disableCache)
      .then(({ valid }) => {
        if (valid) {
          return null;
        } else {
          return criteria;
        }
      })
      .catch(() => criteria)
  );

  return Promise.all(validationPromises);
};
