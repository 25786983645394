import {
  FocusedMissionAdd,
  FocusedMissionRemove,
  IMissionDeviceCheckInResponse,
  IMissionHistoryResponse,
  IMissionResponse,
  IMissionsResponse,
  IShowMobileDeviceResponse,
  MissionJoinInfo,
  MISSIONS_BASE_URI,
} from '@health-activity-ui/shared';
import { transformMissionHistoryResponse, transformMissionResponse } from '@health-activity-ui/utils';
import { AxiosResponse } from 'axios';
import { formatISO } from 'date-fns';
import apiClient from './api-client';

/**
 * @method getMissions
 *
 * @description If the user is joined to any missions, will return
 * those missions instances along with the mission objects representing the
 * unjoined missions. There are 3 groups of missions returned: Recommended (unjoined),
 * Joined, Unjoined. The 3 lists are completely disjoint.
 *
 * @return {IMissionsResponse}
 * */
export const getMissions = async (): Promise<IMissionsResponse> => {
  const response: AxiosResponse<IMissionsResponse> = await apiClient<IMissionsResponse>(`${MISSIONS_BASE_URI}`);

  return response.data;
};

/**
 * @method getMission
 *
 * @description Gets a specific mission for a user.  If the user has joined the mission, the mission instance
 * is returned as part of the JSON payload in addition to the mission template. If the user has not joined
 * the mission, the base mission template is all that is returned.
 *
 * @param {string} missionId
 *
 * @return {IMissionResponse}
 * */
export const getMission = async (missionId: string): Promise<IMissionResponse> => {
  const response: AxiosResponse<IMissionResponse> = await apiClient<IMissionResponse>(
    `${MISSIONS_BASE_URI}/${missionId}`
  );

  return transformMissionResponse(response.data);
};

/**
 * @method getJoineMission
 *
 * @description Retrieves a mission instance for the user, but only if a valid instance Id is provided.
 * An invalid instance ID will result in an API error response (rather than
 * just returning a mission template in the response)
 *
 * @param {string} instanceId
 *
 * @return {IMissionResponse}
 * */
export const getJoinedMission = async (instancedId: string): Promise<IMissionResponse> => {
  const response: AxiosResponse<IMissionResponse> = await apiClient<IMissionResponse>(
    `${MISSIONS_BASE_URI}/joined/${instancedId}`
  );
  return response.data;
};

/**
 * @method getJoinedMissions
 *
 * @description Gets joined missions for current user.
 *
 * @return {IMissionResponse[]}
 * */
export const getJoinedMissions = async (category?: string): Promise<IMissionResponse[]> => {
  const response: AxiosResponse<IMissionResponse[]> = await apiClient<IMissionResponse[]>(
    `${MISSIONS_BASE_URI}/joined`,
    {
      params: {
        category,
      },
    }
  );

  return response.data;
};

/**
 * @method getFocusedMissions
 *
 * @description Gets focused missions
 *
 * @param {string} focusArea
 *
 * @return {IMissionResponse[]}
 * */
export const getFocusedMissions = async (focusArea: string): Promise<IMissionResponse[]> => {
  const response: AxiosResponse<IMissionResponse[]> = await apiClient<IMissionResponse[]>(
    `${MISSIONS_BASE_URI}/focused/${focusArea}`
  );

  return response.data;
};

/**
 * Can only join a Mission that is quit (aka pending).
 */
export const joinMission = async (missionId: string, missionJoinInfo: MissionJoinInfo): Promise<IMissionResponse> => {
  const response: AxiosResponse<IMissionResponse> = await apiClient<IMissionResponse>(
    `${MISSIONS_BASE_URI}/${missionId}/join`,
    {
      data: missionJoinInfo,
      method: 'POST',
    }
  );

  return transformMissionResponse(response.data);
};

/**
 * Can only quit or "place in pending" a Mission that has been previously joined.
 */
export const quitMission = async (instanceId: string): Promise<IMissionResponse> => {
  const response: AxiosResponse<IMissionResponse> = await apiClient<IMissionResponse>(
    `${MISSIONS_BASE_URI}/${instanceId}`,
    {
      method: 'DELETE',
    }
  );

  return transformMissionResponse(response.data);
};

/**
 * Can only add focus to a joined Mission.
 */
export const addFocusedMissions = async (missionInfo: FocusedMissionAdd): Promise<boolean> => {
  const response: AxiosResponse<boolean> = await apiClient<boolean>(`${MISSIONS_BASE_URI}/focused/add`, {
    data: missionInfo,
    method: 'POST',
  });

  return response.data;
};

/**
 * Can only remove focus from a joined Mission.
 */
export const removeFocusedMissions = async (missionToRemoveInfo: FocusedMissionRemove): Promise<boolean> => {
  const response: AxiosResponse<boolean> = await apiClient<boolean>(`${MISSIONS_BASE_URI}/focused/remove`, {
    data: missionToRemoveInfo,
    method: 'POST',
  });

  return response.data;
};

export const checkInMission = async (
  instanceId: string,
  amount: number,
  isMobileDevice = false,
  checkInDate: Date
): Promise<IMissionResponse> => {
  // Get the user's time zone then remove it from the date
  const date = new Date(checkInDate.getTime() - checkInDate.getTimezoneOffset() * 60 * 1000);
  const response: AxiosResponse<IMissionResponse> = await apiClient<IMissionResponse>(
    `${MISSIONS_BASE_URI}/${instanceId}/checkin/manual`,
    {
      data: {
        amount,
        isMobileDevice,
        checkInDate: date.toISOString().split('T')[0],
      },
      method: 'POST',
    }
  );

  return response.data;
};

export const deviceCheckInMission = async (instanceId: string): Promise<IMissionDeviceCheckInResponse> => {
  const response: AxiosResponse<IMissionDeviceCheckInResponse> = await apiClient<IMissionDeviceCheckInResponse>(
    `${MISSIONS_BASE_URI}/${instanceId}/checkin/device`,
    {
      data: { isMobileDevice: false },
      method: 'POST',
    }
  );

  return response.data;
};

export const getAllMissionsInCategory = async (category: string): Promise<IMissionsResponse> => {
  const response: AxiosResponse<IMissionsResponse> = await apiClient<IMissionsResponse>(
    `${MISSIONS_BASE_URI}/category/${category}`
  );

  return response.data;
};

export const getMissionHistory = async (
  missionId: string,
  startDate?: Date,
  endDate?: Date
): Promise<IMissionHistoryResponse> => {
  const params = {};
  if (startDate) {
    params['startDate'] = formatISO(startDate);
  }
  if (endDate) {
    params['endDate'] = formatISO(endDate);
  }

  const response: AxiosResponse<IMissionHistoryResponse> = await apiClient<IMissionHistoryResponse>(
    `${MISSIONS_BASE_URI}/${missionId}/history`,
    {
      params,
    }
  );
  return transformMissionHistoryResponse(response.data);
};

export const removeDevice = async (instanceId: string): Promise<AxiosResponse> => {
  return await apiClient<void>(`${MISSIONS_BASE_URI}/${instanceId}/device`, { method: 'DELETE' });
};

export const changeActiveDevice = async (device: string): Promise<AxiosResponse> => {
  return await apiClient<void>(`${MISSIONS_BASE_URI}/device/change/to/${device}`, { method: 'POST' });
};

export const showMobileDevice = async (): Promise<IShowMobileDeviceResponse> => {
  const response: AxiosResponse<IShowMobileDeviceResponse> = await apiClient<IShowMobileDeviceResponse>(
    `${MISSIONS_BASE_URI}/mobile/featureFlag`
  );

  return response.data;
};
