import { Devices, DeviceUserSettings } from './devices.interface';
import { ActivityType, ChallengeCategory } from './shared.interface';

export interface Checkpoint {
  coinValue: number;
  interval: number;
}

export interface MilestoneDetails {
  milestoneAmount: Checkpoint;
  majorCheckpoints?: Checkpoint;
  minorCheckpoints?: Checkpoint;
}

export interface Activity {
  activityLabel: string;
  activityType: ActivityType;
  unit: string;
  abbrev?: string;
  category?: ChallengeCategory;
  milestoneScoring?: ActivityScoring;
  singular?: string;
  subcategory?: string;
}

export enum ActivityScoring {
  Cumulative = 'cumulative',
  Daily = 'daily',
}

export interface ChallengeCheckinDetails {
  activityInfo: Activity;
  allowManualCheckin: boolean;
  checkinMethods: string[];
  dailyEntryCap: number;
  isDualCheckinAllowed: boolean;
  scoringMethod: string;
}

export interface ChallengeRepeatDetails {
  numRepeat: number;
  repeatGapInterval: number;
  repeatUnit: string;
}

export interface PaginationParams {
  limit?: number;
  skip?: number;
  sort?: string;
}

export interface GetTeamsParams extends PaginationParams {
  challengeId: string;
}

export interface JoinedInstancesParams extends PaginationParams {
  endDateGreaterThan?: string;
  isActive?: boolean;
  isCompleted?: boolean;
  isInviteOnly?: boolean;
  isWaiting?: boolean;
}

export interface JoinableInstancesParams extends PaginationParams {
  isFeatured?: boolean;
  sortBy?: string;
}

export interface GetUserLeaderboardParams extends PaginationParams {
  challengeId: string;
  sort?: string;
  teamId?: string;
}

/**
 * Member response in a leaderboard, used by [[UserLeaderboard]]
 *
 * @property rallyId members' rallyId
 * @property total member's total distance, meters, etc. logged to a challenge
 * @property rank user's rank in challenge
 * @property position corresponding position in challenge
 * @property displayName user's display name
 * @property icon link to user's icon
 */
export interface LeaderboardMember {
  displayName: string;
  icon: string;
  rallyId: string;
  total: number;
  position?: number;
  rank?: number;
}

export interface UserLeaderboard {
  entities: LeaderboardMember[];
  hasNextPage: boolean;
  page: number;
  total: number;
}

export interface MemberLeaderboard {
  average: number;
  displayName: string;
  icon: string;
  leaderboard: UserLeaderboard;
  total: number;
  rank?: number;
}

export interface GetTeamLeaderboardParams extends PaginationParams {
  challengeId: string;
  sort?: string;
  teamId?: string;
}

export interface LeaderboardEntity {
  averageDistance: number;
  id: string;
  name: string;
  progress: number;
  totalDistance: number;
  icon?: string;
  normalColor?: string;
  rank?: number;
}

export interface SingleTeamLeaderboard {
  average: number;
  name: string;
  normalColor: string;
  position: number;
  teamName: string;
  total: number;
  rank?: number;
  teamId?: string;
}

export interface TotalTeamsLeaderboard {
  entities: SingleTeamLeaderboard[];
  hasNextPage: boolean;
  page: number;
  total: number;
}

export interface TeamLeaderboard {
  average: number;
  leaderboard: TotalTeamsLeaderboard;
  normalColor: string;
  teamName: string;
  total: number;
  rank?: number;
  teamId?: string;
}

export interface CheckinResult {
  checkedInAmount: number;
  currentDayTotal: number;
  milestoneProgress: CheckinMilestoneProgress[];
  milestonesCompleted: CheckinMilestoneCompleted[];
  overallTotal: number;
}

export interface CheckinMilestoneCompleted {
  amount: number;
  clientId: string;
  completionDate: string | number;
  externalId: string;
  milestoneAmount: number;
  milestoneName: string;
  milestoneType: string;
  rallyId: string;
  trackingId: string;
}

export interface CheckinMilestoneProgress {
  milestoneAmount: number;
  milestoneName: string;
  progressAmount: number;
  progressDate: number;
}

export type ChallengeStatus = 'active' | 'complete' | 'deleted' | 'draft' | 'unknown' | 'waiting';
export const ChallengeStatus = {
  Active: 'active' as ChallengeStatus,
  Completed: 'complete' as ChallengeStatus,
  Default: 'unknown' as ChallengeStatus,
  Deleted: 'deleted' as ChallengeStatus,
  Draft: 'draft' as ChallengeStatus,
  Waiting: 'waiting' as ChallengeStatus,
};

export interface ChallengeMemberStats {
  average: number;
  challengeId: string;
  checkinDataType: string;
  currentDayTotal: number;
  teamId: string;
  total: number;
  totalCoins: number;
  lastSyncDate?: Date | string;
  lastSyncTimestamp?: Date;
  rank?: number;
}

export interface ChallengeMember {
  challengeEndDate: Date;
  challengeId: string;
  joinDate: Date;
  rallyId: string;
  status: string;
  trackingId: string;
  lastCheckinTimestamp?: Date;
  teamId?: string;
}

export interface ChallengeConfig {
  branchIoInviteUrl?: string;
  mapToken?: string;
  showUCCWeb?: boolean;
  showUserCreatedChallenges?: boolean;
  showViewPrivateChallenges?: boolean;
  showViewPublicChallenges?: boolean;
  useReactRouting?: boolean;
  viewChallengesV2?: boolean;
}

export interface ChallengeNameResponse {
  isValid: boolean;
  errorMessage?: string;
}

export enum CheckinDataType {
  Miles = 'miles',
  Steps = 'steps',
}

export interface AppliedCheckin {
  checkinAmount: number;
  checkinDate: Date | string;
  checkinDataType?: CheckinDataType;
  milestonesCompleted?: CheckinMilestoneCompleted[];
  rallyId?: string;
}

export interface InstanceDetails {
  colorCode: string;
  creatorId: string;
  dates: ChallengeDates;
  description: string;
  inviteCode: string;
  isIncentivized: boolean;
  isInviteOnly: boolean;
  isTeam: boolean;
  isUserCreated: boolean;
  logoUrl: string;
  name: string;
  sponsorship: Sponsorship;
  status: ChallengeStatus;
  cityId?: string;
  incentiveLabel?: string;
  isCityWalk?: boolean;
  isDraft?: boolean;
  maxInstanceCoins?: number;
  startEmailConfig?: ChallengeEmailConfig;
}

export interface ChallengeDates {
  endDate: Date;
  lastJoinableDate: Date;
  openDate: Date;
  startDate: Date;
  repeatDetails?: ChallengeRepeatDetails;
}

export interface Sponsorship {
  primaryAffiliation: string;
  primaryClient: string;
  primaryPartner: string;
}

export interface ChallengeEmailConfig {
  hoursAfter: number;
  shouldSend: boolean;
  additionalText?: string;
}

export interface UniversalChallengeInstance {
  active?: boolean;
  challengeId?: string;
  challengeMemberStats?: ChallengeMemberStats;
  checkinDetails?: ChallengeCheckinDetails;
  creatorTimeZone?: string;
  instanceDetails?: InstanceDetails;
  isJoined?: boolean;
  memberCount?: number;
  milestoneDetails?: MilestoneDetails;
  now?: number;
}

export interface IncentiveInfo {
  hasIncentive: boolean;
  isAchieved: boolean;
  isAvailable: boolean;
  rewardsInfo: string;
  incentiveLabel?: string;
}

export interface ChallengeTeam {
  action: string;
  brightColor: string;
  challengeId: string;
  createTimestamp: number;
  darkColor: string;
  description: string;
  editor: string;
  id: string;
  normalColor: string;
  teamId: string;
  teamName: string;
  version: number;
}

export interface ChallengeCity {
  city: string;
  cityId: string;
  description?: string;
  route?: string;
}

export interface LeaderboardData {
  joinedTeamLeaderboard?: TeamLeaderboard;
  joinedUserLeaderboard?: MemberLeaderboard;
  unjoinedTeamLeaderboard?: ChallengeTeam[];
  unjoinedUserLeaderboard?: LeaderboardMember[];
  waitingJoinedTeamLeaderboardAllTeams?: ChallengeTeam[];
  waitingJoinedTeamLeaderboardWithSkipAndLimit?: ChallengeTeam[];
  waitingJoinedUserLeaderboard?: MemberLeaderboard;
}

export interface LeaderboardMetaData {
  isTeam: boolean;
  isJoined: boolean;
  totalEntities: number;
  name?: string;
  id?: string;
  entities: any;
  hasNextPage: boolean;
  page: number;
  rank?: number;
  totalDistance?: number;
  averageDistance?: number;
  normalColor?: string;
  icon?: string;
}

export interface ChallengeMultipleStats {
  challengeId: string;
  totalCoins: number;
  checkinDataType: string;
  total: number;
  average: number;
  teamId: string;
  rank: number;
  currentDayTotal: number;
  lastSyncDate: string;
  lastSyncTimestamp: string;
}

export interface ChallengeMultipleTeamStats {
  challengeId: string;
  teamName: string;
  total: number;
  average: number;
  rank: number;
  teamCount: number;
  lastSyncDate: string;
  lastSyncTimestamp: string;
}

export interface ChallengeData {
  challengeInstance: UniversalChallengeInstance;
  currentDevices: Devices[];
  isJoined: boolean;
  challengeStats: ChallengeMemberStats;
  checkins: AppliedCheckin[];
  deviceUserSettings: DeviceUserSettings;
  incentiveInfo: IncentiveInfo;
}
