import React, { forwardRef, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormProps } from './forms.interface';

export const Form = forwardRef<HTMLFormElement, FormProps>(
  ({ config, formMethods: propFormMethods, onSubmit, onInvalid, defaultValues, children, ...rest }, ref) => {
    const hookFormMethods = useForm(config);
    const formMethods = propFormMethods || hookFormMethods;
    const { handleSubmit, reset } = formMethods;
    const { isSubmitSuccessful } = formMethods.formState;

    useEffect(() => {
      if (isSubmitSuccessful) {
        /**
         * Only call reset if `defaultValues` prop is passed in otherwise things
         * start to break if `defaultValues` is not passed to reset() method.
         *
         * See https://react-hook-form.com/api/useform/reset docs about the "Rules" around reset method.
         * */
        defaultValues && reset(defaultValues);
      }
    }, [isSubmitSuccessful, defaultValues, reset]);

    return (
      <FormProvider {...formMethods}>
        <form ref={ref} {...rest} onSubmit={handleSubmit((data, event) => onSubmit?.(data, event), onInvalid)}>
          {children}
        </form>
      </FormProvider>
    );
  }
);
