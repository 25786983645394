import { PropsWithChildren } from '@health-activity-ui/shared';
import classnames from 'classnames';
import React from 'react';
import { Element, ElementProps } from '../../../../element/element';

export interface LevelSideProps extends ElementProps, PropsWithChildren {
  // by default a level stacks verticall on mobile, if you want it to remain horizontal on mobile then set this to true
  side?: 'left' | 'right';
}

// Generates a 'level-side' class on an element to render a group of elements to the left or right side of a level
// https://bulma.io/documentation/layout/level
export const LevelSide = ({
  className = undefined,
  children = null,
  side = 'left',
  renderAs = 'div',
  ...allProps
}: LevelSideProps) => (
  <Element
    {...allProps}
    renderAs={renderAs}
    className={classnames(className, {
      [`level-${side}`]: side,
    })}
  >
    {children}
  </Element>
);

export default LevelSide;
