import styled from '@emotion/styled';
import classnames from 'classnames';
import React, { forwardRef } from 'react';
import { TextAreaProps } from './forms.interface';
import useErrorStyles from './use-error-styles.hook';
import useRegister from './use-register.hook';

const TextAreaStyled = styled.textarea`
  &.has-autogrow {
    height: 40px;
    max-height: 100px;
    min-height: 40px;
    overflow: hidden;
    resize: none;
  }
`;

const handleAutoGrow = (element) => {
  if (element.target !== null) {
    const textarea = element.target;
    textarea.style.height = '40px';
    textarea.style.height = `${textarea.scrollHeight}px`;
    textarea.style.overflow = textarea.scrollHeight > 100 ? 'hidden scroll' : 'hidden';
  }
};

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      name,
      /**
       * If an `id` prop is passed to `<TextArea>`, you must also pass the same unique value
       * to the `<Label>`'s htmlFor prop. Else defaults to `name`.
       * */
      id,
      // for useErrorStyles
      errorClassName,
      errorStyle,
      className: propClassName,
      style: propStyle,
      // for useRegister
      validation,
      onBlur,
      onChange,
      rows,
      autoComplete = 'off',
      disableResize = true,
      sizing = 'default',
      hasAutoGrow = false,
      ...rest
    },
    ref
  ) => {
    const { style, className } = useErrorStyles({
      name,
      errorClassName,
      errorStyle,
      className: propClassName,
      style: propStyle,
    });

    const useRegisterReturn = useRegister(
      {
        name,
        validation,
        onBlur,
        onChange,
      },
      ref
    );

    return (
      <TextAreaStyled
        id={id || name}
        {...rest}
        rows={rows}
        style={style}
        className={classnames(
          'rds-textarea has-rds-ph-16 has-rds-pv-8',
          {
            'is-resize-none': disableResize,
            'is-auto': sizing === 'auto',
            'is-rds-fullwidth': sizing === 'full',
            'has-autogrow': hasAutoGrow,
          },
          className
        )}
        onInput={(e) => {
          if (hasAutoGrow) {
            handleAutoGrow(e);
          }
        }}
        autoComplete={autoComplete}
        {...useRegisterReturn}
      />
    );
  }
);
