export enum AuthHeaders {
  ApplicationJson = 'application/json',
  ContentType = 'Content-Type',
  Locale = 'X-Rally-Locale',
  NoCache = 'no-cache',
  RallyCaller = 'Rally-Caller',
  RPTokenSuffix = 'RP-Token-Suffix',
  SessionId = 'X-Rally-Session-Token',
  SessionToken = 'X-Rally-SessionToken',
  Unauthenticated = 'X-Rally-Unauthenticated-Request',
  UserId = 'X-Rally-UserId',
  Web = 'Web',
  XRallyUserTimezone = 'X-Rally-User-Timezone',
  XSRFToken = 'XSRF-TOKEN',
}

export enum AnalyticsHeaders {
  RallyClient = 'Rally-Client',
  RallyReferer = 'Rally-Referer',
}
