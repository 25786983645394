import '@rally/ui-themes/dist/web/rally/tokens.css';

const theme = {
  /**
   * TODO: https://jira.rallyhealth.com/browse/WHAT-2071
   * Key Specifications: https://styled-system.com/css#theme-keys
   * Css Variables Example without a Provider: https://epicreact.dev/css-variables/
   */
  colors: {
    brandOrange: 'var(--rds-color-system-rally-brand-orange)',
    orangeLight: 'var(--rds-color-system-sunset-orange-light)',
  },
};

export { theme };
