import { css } from '@emotion/react';
import React, { Children, isValidElement, ReactElement } from 'react';
import Tag from './components/tag/tag';

const allowedTypes = [Tag.name];

const cssStyles = `
  &:last-child {
    margin-bottom: calc(var(--rds-spacing-size-8) * -1);
  }

  .tag {
    margin-bottom: var(--rds-spacing-size-8);

    &:not(:last-child) {
      margin-right: var(--rds-spacing-size-8);
    }
  }

`;

export const Tags = ({ children }: { children?: ReactElement[] | ReactElement }) => {
  return (
    <div
      data-testid="tags"
      className="tags is-flex is-align-items-center is-align-items-flex-start is-flex-wrap"
      css={css`
        ${cssStyles}
      `}
    >
      {Children.map(children, (child) => {
        if (typeof child.type === 'string') {
          throw new Error('<Tags> children can only be a <Tag> - Child is a string');
        }

        if (isValidElement(child) && !allowedTypes.includes(child.type.name)) {
          throw new Error(
            `<Tags> children components must be included in 'allowedTypes: ${allowedTypes}' - Child is a: <${child.type.name} /> `
          );
        }

        return child;
      })}
    </div>
  );
};

export default Tags;
