import React from 'react';

interface Props {
  color?: string;
  height?: string;
  width?: string;
}

export const CyclingIcon = ({ color = '#fff', width = '25px', height = '20px' }: Props): React.ReactElement => (
  <svg viewBox="0 0 103.5 89" width={width} height={height} aria-hidden="true" data-testid="cycling-icon">
    <g fill={color}>
      <g>
        <path d="M82 46a22 22 0 1021 21 22 22 0 00-21-21zm0 34a13 13 0 1113-13 13 13 0 01-13 13zM22 46a22 22 0 1021 21 22 22 0 00-21-21zm0 34a13 13 0 1113-13 13 13 0 01-13 13zM60 16a8 8 0 10-4-11 8 8 0 004 11z" />
        <path d="M75 44a4 4 0 000-8H65V25a4 4 0 00-1-3l-4-3-4-5a4 4 0 00-6 0L31 33a4 4 0 000 6l16 17v16a4 4 0 109 0V54a4 4 0 00-1-3L44 40l12-12v12a4 4 0 005 4z" />
      </g>
    </g>
  </svg>
);

export default CyclingIcon;
