import { DomainState, DomainValidationResponse } from './domain.models';
import { ERROR, localWindow } from '@health-activity-ui/shared';

import { DOMAIN_ACTION_TYPES } from './domain.actions';
import { IS_LOCAL } from '@health-activity-ui/utils';
import { domainReducer } from './domain.reducer';
import { getUnifiedDomain } from '../../api/domain.api';
import { withProvider } from '../with-provider';

export const initialDomainState: DomainState = {
  status: null,
};

async function validateDomain(dispatch) {
  dispatch({ type: DOMAIN_ACTION_TYPES.DOMAIN_FETCH_LOADING });
  try {
    const response = await getUnifiedDomain();
    switch (response.status) {
      case 204:
        // Current domain and path are valid, no redirection needed.
        dispatch({ type: DOMAIN_ACTION_TYPES.DOMAIN_FETCH_VALIDATED });
        return;
      case 200:
        if (response.data) {
          // Current domain and/or path are not unified, need to redirect
          // we don't really need this payload because the app is just going to redirect anyways
          // However, keeping it because withProvider expects it
          dispatch({ type: DOMAIN_ACTION_TYPES.DOMAIN_FETCH_CORRECTED, payload: response.data });
          IS_LOCAL()
            ? // eslint-disable-next-line no-console
              console.log('THIS IS LOCAL, DO NOT REDIRECT TO', `${response.data.correctedDomain}`)
            : localWindow.location.assign(response.data.correctedDomain);
        } else {
          // error parsing response body json
          const error = new Error(ERROR.FETCH.BODY_PARSING);
          dispatch({
            type: DOMAIN_ACTION_TYPES.DOMAIN_FETCH_FAILED,
            payload: { error },
          });
          // eslint-disable-next-line no-console
          console.warn(`failed to parse the following JSON: ${response.data}`);
        }
        return;
    }
  } catch (error) {
    /*
     * This should dispatch an action that the Auth Provider can react to - 401, 403.
     * TODO: https://jira.rallyhealth.com/browse/WHAT-1058
     */
    dispatch({
      type: DOMAIN_ACTION_TYPES.DOMAIN_FETCH_FAILED,
      payload: { error },
    });
  }
}

const [DomainProvider, useDomain] = withProvider<DomainState, DomainValidationResponse | Record<string, unknown>>({
  reducer: domainReducer,
  displayName: 'DomainProvider',
  initialState: initialDomainState,
});

export { DomainProvider, useDomain, validateDomain };
