import styled from '@emotion/styled';
import { ACTIVITIES_BASE_ROUTE, ASSETS_PATH } from '@health-activity-ui/shared';
import { respondTo } from '@health-activity-ui/utils';
// RDS Components
import { RdsIcon, RdsLink } from '@rally/energon-react/dist/es6';
import React from 'react';
import { Container, Section } from '../layout';
import { RdsRouterLink } from '../rds-router-link/rds-router-link.component';

const FourZeroFourStyled = styled.div`
  height: 75vh;

  .cta {
    text-decoration: none;
  }

  .laptop {
    bottom: 10px;
    display: inline-block;
    height: 250px;
    position: absolute;
    right: 300px;
    width: 300px;
  }

  ${respondTo.tabletBelow`
    height: 90vh;

    .laptop {
      bottom: 110px;
      left: 0px;
      right: 0px;
    }
  `}
`;

export const FourZeroFour = (): React.ReactElement => (
  <Section className="has-text-centered has-rds-pb-none" isFirst>
    <Container>
      <FourZeroFourStyled>
        <div className="is-flex is-flex-direction-row is-flex-direction-column-tablet-below is-justify-content-space-around">
          <div className="is-flex is-flex-direction-column is-align-items-flex-start has-rds-mr-64 has-rds-pr-64 has-rds-mr-0-tablet-below has-rds-pr-0-tablet-below">
            <div className="warning has-rds-mb-16">
              <RdsIcon name="indicator-alert" size="64" />
              <p className="is-rds-body-1">404</p>
            </div>
            <h1 className="is-rds-h1 has-rds-mb-32 has-text-left">
              For some reason we can't find what you're looking for right meow.
            </h1>
            <RdsRouterLink
              to={ACTIVITIES_BASE_ROUTE}
              className="rds-primary-button is-white-labeled-btn cta has-rds-mb-16"
            >
              Back to Dashboard
            </RdsRouterLink>
            <div className="help">
              <RdsLink href="https://helpcenter.werally.com/rally/s/" variant="inline">
                Help Centers & FAQs
              </RdsLink>
            </div>
          </div>
          <div className="is-flex is-justify-content-flex-end has-rds-mt-32">
            <img className="cat" width="350px" src={`${ASSETS_PATH}/img/errors/404-cat.svg`} alt="" />
          </div>
        </div>
        <img className="laptop" src={`${ASSETS_PATH}/img/errors/404-laptop.svg`} alt="" />
      </FourZeroFourStyled>
    </Container>
  </Section>
);
