import apiClient from './api-client';
import { GEOLOCATION_BASE_URI, Geolocation } from '@health-activity-ui/shared';

/**
 * @description Gets current user's location
 *
 * @return {Geolocation}
 * */
export const getUserLocation = async (): Promise<Geolocation> => {
  const response = await apiClient<Geolocation>(`${GEOLOCATION_BASE_URI}/user/current/location`, { useCache: true });
  return response.data;
};
