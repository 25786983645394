import * as authenticationActions from './authentication.actions';
import { AuthenticationState } from './authentication.models';
import { createReducer } from '@reduxjs/toolkit';
import { Status } from '@health-activity-ui/shared';

export const authenticationReducer = createReducer({} as AuthenticationState, (builder) =>
  builder
    .addCase(authenticationActions.authenticationLoading, (state) => {
      state.status = Status.loading;
    })
    .addCase(authenticationActions.authenticationLoaded, (state, action) => {
      state.status = Status.loaded;
      state.data = action.payload;
    })
    .addCase(authenticationActions.authenticationFailed, (state, action) => {
      const error =
        typeof action.payload === 'object'
          ? JSON.parse(JSON.stringify(action.payload, Object.getOwnPropertyNames(action.payload)))
          : action.payload;
      state.status = Status.error;
      state.error = error;
    })
);
