import { debounce } from '@health-activity-ui/utils';
import { RDS_BREAKPOINTS_DESKTOP, RDS_BREAKPOINTS_TABLET_PORTRAIT } from '@rally/ui-themes/dist/web/rally';
import { useCallback, useLayoutEffect } from 'react';
import { useSafeState } from './use-safe-state.hook';

const pxRegex = /px$/;
const portrait = Number(RDS_BREAKPOINTS_TABLET_PORTRAIT.replace(pxRegex, '')); // under 540px
const desktop = Number(RDS_BREAKPOINTS_DESKTOP.replace(pxRegex, '')); // 1048px +

export const useVisibility = () => {
  const [isMobile, setIsMobile] = useSafeState<boolean>(window.innerWidth < portrait);
  // touch is all mobile and tablet sizes
  const [isTouch, setIsTouch] = useSafeState<boolean>(window.innerWidth < desktop);
  const [isDesktop, setIsDesktop] = useSafeState<boolean>(window.innerWidth >= desktop);

  const updateSize = useCallback(() => {
    setIsMobile(window.innerWidth < portrait);
    setIsTouch(window.innerWidth < desktop);
    setIsDesktop(window.innerWidth >= desktop);
  }, [setIsDesktop, setIsMobile, setIsTouch]);

  useLayoutEffect(() => {
    window.addEventListener('resize', debounce(updateSize, 250));
    return (): void => window.removeEventListener('resize', updateSize);
  }, [updateSize]);

  return { isMobile, isTouch, isDesktop, updateSize };
};

export default useVisibility;
