import {
  ACTIVITIES_BASE_ROUTE,
  CHALLENGES_2_BASE_ROUTE,
  CHALLENGES_BASE_ROUTE,
  COMMUNITIES_BASE_ROUTE,
  DASHBOARD_BASE_ROUTE,
  MESSAGES_BASE_ROUTE,
  MISSIONS_BASE_ROUTE,
  STAYING_WELL_BASE_ROUTE,
  WellnessProducts,
} from '@health-activity-ui/shared';
import { useLayoutEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useActiveItem = (): WellnessProducts => {
  const location = useLocation();
  const [activeItem, setIsActiveItem] = useState(WellnessProducts.WELLNESS_ACTIVITY_DASHBOARD);

  useLayoutEffect(() => {
    switch (true) {
      case location.pathname.includes(DASHBOARD_BASE_ROUTE):
      case location.pathname.includes(ACTIVITIES_BASE_ROUTE): {
        setIsActiveItem(WellnessProducts.WELLNESS_ACTIVITY_DASHBOARD);
        break;
      }

      case location.pathname.includes(STAYING_WELL_BASE_ROUTE): {
        setIsActiveItem(WellnessProducts.WELLNESS_STAYING_WELL);
        break;
      }

      case location.pathname.includes(CHALLENGES_2_BASE_ROUTE):
      case location.pathname.includes(CHALLENGES_BASE_ROUTE): {
        setIsActiveItem(WellnessProducts.WELLNESS_CHALLENGES);
        break;
      }

      case location.pathname.includes(MISSIONS_BASE_ROUTE): {
        setIsActiveItem(WellnessProducts.WELLNESS_MISSIONS);
        break;
      }

      case location.pathname.includes(COMMUNITIES_BASE_ROUTE): {
        setIsActiveItem(WellnessProducts.WELLNESS_COMMUNITIES);
        break;
      }

      case location.pathname.includes(MESSAGES_BASE_ROUTE): {
        setIsActiveItem(WellnessProducts.WELLNESS);
        break;
      }
    }
  }, [location.pathname]);

  return activeItem;
};

export default useActiveItem;
