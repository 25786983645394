import styled from '@emotion/styled';
import { RDS_COLOR_SYSTEM_SUNSET_ORANGE, RDS_UNIT_SIZE_4 } from '@rally/ui-themes/dist/web/rally';
import classnames from 'classnames';
import React, { forwardRef, HTMLAttributes, ReactElement } from 'react';

const TabButtonStyled = styled.button`
  border: none;
  box-shadow: none;

  &.is-active {
    position: relative;
    &:before {
      border-bottom: ${RDS_UNIT_SIZE_4} solid ${RDS_COLOR_SYSTEM_SUNSET_ORANGE};
      bottom: -${RDS_UNIT_SIZE_4};
      content: '';
      left: 0;
      position: absolute;
      width: 100%;
    }
  }
  &:focus {
    box-shadow: none;
  }
`;

export interface TabButtonProps extends HTMLAttributes<HTMLButtonElement> {
  tabTitle: string;
  isActive: boolean;
}

export const TabButton = forwardRef<HTMLButtonElement, TabButtonProps>(
  ({ tabTitle, isActive, ...allProps }: TabButtonProps, ref): ReactElement => {
    return (
      <TabButtonStyled
        {...allProps}
        className={classnames(
          'rds-tertiary-button has-rds-pv-none has-rds-neutral-border-bottom has-rds-border-width-4',
          {
            'is-active has-rds-text-orange': isActive,
          }
        )}
        ref={ref}
      >
        {tabTitle}
      </TabButtonStyled>
    );
  }
);

export default TabButton;
