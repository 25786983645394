import {
  CONTENT_ARTICLES_BASE_URI,
  IArticleResponse,
  IContentArticleCategory,
  IContentArticleCategoryParams,
  IContentArticleEntryParam,
  IContentArticleOrderParams,
  IContentArticleParams,
  IContentArticleSearchParams,
  OrderBy,
} from '@health-activity-ui/shared';
import { isDefined } from '@health-activity-ui/utils';
import apiClient from './api-client';

/**
 * @method getContentArticle
 *
 * @description this is for getting an individual article from content subdomain
 *
 * @param {string} articleId the id of the article
 *
 * @return {IArticleResponse} the article
 * */
export const getContentArticle = async (articleId: string): Promise<IArticleResponse> => {
  const { data } = await apiClient<IArticleResponse>(`${CONTENT_ARTICLES_BASE_URI}/articles/id/${articleId}`);

  return data;
};

/**
 * @method getContentArticleCategories
 *
 * @description this is for the article categories from content subdomain
 *
 * @param {number} skip optional query param for number to skip
 * @param {number} limit optional query param to limit the responses
 *
 * @return {IContentArticleCategory[]} the list of different article categories
 * */
export const getContentArticleCategories = async (skip = 0, limit = 12): Promise<IContentArticleCategory[]> => {
  const params: IContentArticleParams = { skip, limit };

  const { data } = await apiClient<IContentArticleCategory[]>(`${CONTENT_ARTICLES_BASE_URI}/articles/categories`, {
    params,
  });

  return data;
};

/**
 * @method getContentArticlesByCategory
 *
 * @description this is for getting an articles by a category from content subdomain
 *
 * @param {string} category the category of articles you want to get
 * @param {number} skip optional query param for skipping
 * @param {number} limit optional query param for limiting results
 * @param {string} categoryTitleStyle optional query param for how the title is returned 'titlecase-space' or 'lowercase-hyphen'
 * @param {string} categoryScope optional query param for the category scope 'primary-secondary' or 'primary'
 *
 * @return {IArticleResponse} list of articles for the category listed
 * */
export const getContentArticlesByCategory = async (
  category: string,
  {
    skip = 0,
    limit = 12,
    categoryTitleStyle = 'titlecase-space',
    categoryScope = 'primary-secondary',
  }: IContentArticleCategoryParams
): Promise<IArticleResponse> => {
  const params: IContentArticleCategoryParams = { skip, limit, categoryTitleStyle, categoryScope };

  const { data } = await apiClient<IArticleResponse>(`${CONTENT_ARTICLES_BASE_URI}/articles/category/${category}`, {
    params,
  });

  return data;
};

/**
 * @method getContentArticlesByLocation
 *
 * @description this is for getting an articles by a location from content subdomain
 *
 * @param {string} locationPrefix the location for the articles
 * @param {number} skip optional query param for skipping
 * @param {number} limit optional query param for limiting results
 *
 * @return {IArticleResponse} list of articles by location
 * */
export const getContentArticlesByLocation = async (
  locationPrefix: string,
  skip = 0,
  limit = 12
): Promise<IArticleResponse> => {
  const params: IContentArticleParams = { skip, limit };
  const { data } = await apiClient<IArticleResponse>(
    `${CONTENT_ARTICLES_BASE_URI}/articles/locationid/prefix/${locationPrefix}`,
    {
      params,
    }
  );

  return data;
};

/**
 * @method getContentArticlesByOrder
 *
 * @description this is for getting articles by order from content subdomain
 *
 * @param {number} skip optional query param for skipping
 * @param {number} limit optional query param for limiting results
 * @param {string} orderBy optional query param for how to order 'latest' or 'popular'
 *
 * @return {IArticleResponse} list of articles by order
 * */
export const getContentArticlesByOrder = async (
  skip = 0,
  limit = 12,
  orderBy = OrderBy.latest
): Promise<IArticleResponse> => {
  const params: IContentArticleOrderParams = { skip, limit, orderBy };
  const { data } = await apiClient<IArticleResponse>(`${CONTENT_ARTICLES_BASE_URI}/articles/ordered`, {
    params,
  });

  return data;
};

/**
 * @method getContentArticleRaw
 *
 * @description this is for getting an raw article from content subdomain
 *
 * @param {string} articleId id of the article
 * @param {number} entryLevel the entry level
 *
 * @return {IArticleResponse} the raw article
 * */
export const getContentArticleRaw = async (articleId: string, entryLevel?: number): Promise<IArticleResponse> => {
  let params: IContentArticleEntryParam = {};
  params = isDefined(entryLevel) ? { ...params, entryLevel } : params;

  const { data } = await apiClient<IArticleResponse>(`${CONTENT_ARTICLES_BASE_URI}/articles/raw/${articleId}`, {
    params,
  });

  return data;
};

/**
 * @method getContentArticleSearch
 *
 * @description this is for searching articles from content subdomain
 *
 * @param {string} search the search query
 * @param {number} skip optional query param for skipping
 * @param {number} limit optional query param for limiting results
 * @param {string} categoryTitle the title of the category you want to search within
 * @param {string} categoryTitleStyle optional query param for how the title is returned 'titlecase-space' or 'lowercase-hyphen'
 * @param {string} categoryScope optional query param for the category scope 'primary-secondary' or 'primary'
 * @param {string} orderBy optional query param for how to order 'latest' or 'popular'
 *
 * @return {IArticleResponse} list of articles returned from the search
 * */
export const getContentArticleSearch = async ({
  search,
  skip = 0,
  limit = 12,
  categoryTitle,
  categoryTitleStyle = 'titlecase-space',
  categoryScope = 'primary-secondary',
  orderBy = OrderBy.latest,
}: IContentArticleSearchParams): Promise<IArticleResponse> => {
  let params: IContentArticleSearchParams = { search, skip, limit, categoryTitleStyle, categoryScope, orderBy };

  params = isDefined(categoryTitle) ? { ...params, categoryTitle } : params;

  const { data } = await apiClient<IArticleResponse>(`${CONTENT_ARTICLES_BASE_URI}/articles/search`, {
    params,
  });

  return data;
};
