import { Status } from '@health-activity-ui/shared';
import React, { createContext, Dispatch, useContext, useReducer } from 'react';
import { AnyAction } from 'redux';
import { getEngageUserCurrent } from '../../api/engage.api';
import { ENGAGE_USER_PROFILE_ACTION_TYPES, EngageUserProfileState } from './engage.models';
import { engageUserProfileReducer } from './engage.reducer';

const EngageUserProfileContext = createContext<{ state: EngageUserProfileState; dispatch: Dispatch<AnyAction> }>(
  undefined
);
EngageUserProfileContext.displayName = 'EngageProvider';

export const initialEngageUserProfileState: EngageUserProfileState = {
  displayName: null,
  roles: [],
  permissions: [],
  status: Status.initial,
  featureData: null,
};

function useEngageUserProfile() {
  const context = useContext(EngageUserProfileContext);
  const isAdmin = context?.state?.permissions?.includes('Admin');

  if (!context) {
    throw new Error(`useEngageUserProfile must be used within the EngageUserProfileProvider`);
  }

  return {
    ...context,
    isAdmin,
  };
}

function EngageUserProfileProvider({ children, EngageUserProfileState = initialEngageUserProfileState }) {
  const [state, dispatch] = useReducer(engageUserProfileReducer, EngageUserProfileState);
  const value = { state, dispatch };

  return <EngageUserProfileContext.Provider value={value}>{children}</EngageUserProfileContext.Provider>;
}

async function updateEngageUserProfile(dispatch) {
  dispatch({ type: ENGAGE_USER_PROFILE_ACTION_TYPES.ENGAGE_USER_PROFILE_FETCH_LOADING });
  try {
    const profile = await getEngageUserCurrent();
    dispatch({ type: ENGAGE_USER_PROFILE_ACTION_TYPES.ENGAGE_USER_PROFILE_FETCH_LOADED, payload: profile });
  } catch (error) {
    dispatch({
      type: ENGAGE_USER_PROFILE_ACTION_TYPES.ENGAGE_USER_PROFILE_FETCH_FAILED,
      payload: { error },
    });
  }
}

function setEngageUserProfile(data, dispatch) {
  dispatch({ type: ENGAGE_USER_PROFILE_ACTION_TYPES.ENGAGE_USER_PROFILE_FETCH_LOADED, payload: data });
}

export { useEngageUserProfile, EngageUserProfileProvider, updateEngageUserProfile, setEngageUserProfile };
