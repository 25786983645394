export const PencilSpeechBubbleIcon = () => (
  <svg viewBox="0 0 96 97" xmlns="http://www.w3.org/2000/svg" width="192" height="192">
    <path
      d="m72.12 24c6.7047 0.1166 13.892 4.5345 13.892 14.083v23.222c-0.0816 6.3863-3.7045 13.288-12.012 14.085v13.61s-19.864-13.062-20.707-13.61h-28.388c-6.7191-0.0891-13.905-4.5346-13.905-14.085v-23.237c0.0944-6.801 4.4874-14.069 13.905-14.069h47.214z"
      fill="#42B876"
    />
    <path
      d="m72.12 24c6.7047 0.1166 13.892 4.5345 13.892 14.083v23.222c-0.0816 6.3863-3.7045 13.398-12.012 14.195v13.5s-19.864-13.062-20.707-13.61h-28.388c-6.7191-0.0891-13.905-4.5346-13.905-14.085v-23.237c0.0944-6.801 4.4874-14.069 13.905-14.069h47.214z"
      fill="#42B876"
    />
    <path
      d="m81 27c-1.3595-0.9765-1.5345-1.064-2.4063-1.4999-1.0001-0.5-2.0937-1.0002-4.0937-1.5002l-11.619 13.855-1.8193 7.152 9.2948-3.4472 10.644-14.559z"
      fill="#249054"
    />
    <path
      d="m60.708 50.114c0-1.5774-1.2788-2.8562-2.8562-2.8562h-31.835c-1.5774 0-2.8561 1.2788-2.8561 2.8562s1.2787 2.8562 2.8561 2.8562h31.835c1.5774 0 2.8562-1.2788 2.8562-2.8562z"
      fill="#fff"
    />
    <path
      d="m70.095 59.498h-44.078c-1.5778 0-2.8569 1.2788-2.8569 2.8562s1.2791 2.8562 2.8569 2.8562h44.078c1.5778 0 2.8568-1.2788 2.8568-2.8562s-1.279-2.8562-2.8568-2.8562z"
      fill="#fff"
    />
    <path
      d="m36.221 35.017h-10.203c-1.5779 0-2.8569 1.2788-2.8569 2.8562s1.279 2.8562 2.8569 2.8562h10.203c1.5779 0 2.8569-1.2788 2.8569-2.8562s-1.279-2.8562-2.8569-2.8562z"
      fill="#fff"
    />
    <path d="m62.675 40.319-6.322 3.0744-1.1759-0.8137 3.7789-4.996 2.2072 0.3809 1.5118 2.3544z" fill="#FFF4D5" />
    <path
      d="m55.621 34.986 0.0071 0.0072 2.4973 0.6751 0.9604 1.9382-3.7791 4.9956-1.01-0.7638 1.3243-6.8523z"
      fill="#FAE5A6"
    />
    <path
      d="m80.058 17.431-12.061 15.83-5.3267 7.0426-0.0051 0.`0071-1.501-2.3472 16.81-22.109 2.0846 1.576z"
      fill="#F9A96C"
    />
    <path d="m84.035 8.0362-22.73 30.108-2.2182-0.5382-1.0244-2.0732 22.748-29.921 3.2246 2.424z" fill="#FBBD8D" />
    <path
      d="m55.623 34.978-0.0041-7e-3 4.3334-5.7297 0.9932-1.3135 7e-3 0.0067 12.058-15.829 1.9242 1.4542-16.81 22.109-2.5044-0.6823 0.0017-0.0082z"
      fill="#F5934B"
    />
    <path d="m85.891 9.7199-5.8331 7.7114-3.5509-2.6849 5.8339-7.7106 3.5501 2.6841z" fill="#FAE5A6" />
    <path d="m78.845 4.3928 3.4958 2.6429-5.8338 7.711-3.4953-2.643 5.8333-7.7109z" fill="#FFC82E" />
    <path
      d="m85.891 9.72-3.5502-2.6846 3.2081-4.2412 1.8714 1.415c0.927 0.70104 1.1106 2.0211 0.409 2.9484l-1.9383 2.5624z"
      fill="#FAC4AD"
    />
    <path
      d="m85.549 2.7941-3.2086 4.2414-3.4953-2.643 1.9383-2.5624c0.7011-0.92722 2.0216-1.1103 2.949-0.40935l1.8166 1.3734z"
      fill="#F5A581"
    />
    <path d="m56.353 43.393-2.6494 1.3256 0.5929-2.8805 2.0565 1.5549z" fill="#5C5B5E" />
    <ellipse cx="49" cy="95" rx="38" ry="2" fillOpacity=".1" />
  </svg>
);

export default PencilSpeechBubbleIcon;
