import { AdvantageConfig, AdvantageLink, Footer, ImageLink, NavSection, Status } from '@health-activity-ui/shared';

export interface GoalSettingsUiConfig {
  footer: Footer;
  header: {
    accountMenu: AdvantageLink[];
    logo: ImageLink;
    nav:
      | {
          [x: string]: AdvantageLink;
        }
      | NavSection[];
  };
  help: AdvantageLink;
  notifications: AdvantageLink;
  whiteLabel?: {
    primaryColor: string;
    primaryAccentColor: string;
    fill: string;
    outlineColor: string;
    secondaryColor?: string;
    secondaryAccentColor?: string;
  };
}

export interface UiConfigState {
  data: AdvantageConfig;
  goalSettingsUiConfig: GoalSettingsUiConfig;
  status: Status;
  hasAdvantageAuth: boolean;
  whiteLabelHasBeenSet: boolean;
  hasSubnav: boolean;
}

export enum UI_CONFIG_ACTION_TYPES {
  UI_CONFIG_DETERMINE_SUBNAV = 'UI_CONFIG_DETERMINE_SUBNAV',
  UI_CONFIG_FETCH_FAILED = 'UI_CONFIG_FETCH_FAILED',
  UI_CONFIG_SET_GOAL_SETTINGS = 'UI_CONFIG_SET_GOAL_SETTINGS',
  UI_CONFIG_FETCH_LOADING = 'UI_CONFIG_FETCH_LOADING',
  UI_CONFIG_FETCH_LOADED = 'UI_CONFIG_FETCH_LOADED',
  UI_CONFIG_FETCH_LOADED_WITH_WHITE_LABEL = 'UI_CONFIG_FETCH_LOADED_WITH_WHITE_LABEL',
}
