import React from 'react';

interface Props {
  width?: number;
  height?: number;
}

export const SmokingIcon = ({ width = 200, height = 165 }: Props): React.ReactElement => (
  <svg viewBox="0 0 200 165" width={width} height={height}>
    <ellipse fill="#E5E5E4" cx={67} cy={134} rx={43.5} ry={3} />
    <ellipse fill="#E5E5E4" cx={156.8} cy={141} rx={14.3} ry={1.7} />
    <path
      fill="#EEEDED"
      d="M45.9 45.3h8.4v41.9h-8.4zm8.4 18.8h8.4V106h-8.4zm8.4 0h8.4V106h-8.4zM71.1 64.1h8.4V106h-8.4zm8.4 0h8.4V106h-8.4z"
    />
    <path fill="#F69349" d="M45.9 26.6h8.4v19.7h-8.4z" />
    <path fill="#EEEDED" d="M37.5 64.1h7.4V106h-7.4z" />
    <path
      fill="#F69349"
      d="M37.5 51.1h8.4v19.7h-8.4zm16.8 0h8.4v19.7h-8.4zm8.4 0h8.4v19.7h-8.4zM71.1 51.1h8.4v19.7h-8.4zm8.4 0h8.4v19.7h-8.4z"
    />
    <path fill="#D6D6D6" d="M41.3 70.8h4.6v46.6h-4.6zm8.9-24.6h4v46.6h-4z" />
    <path fill="#D87E40" d="M50.2 26.6h4v19.7h-4z" />
    <path fill="#D6D6D6" d="M58.6 70.8h4v46.6h-4z" />
    <path fill="#D87E40" d="M58.6 51.1h4v19.7h-4z" />
    <path fill="#D6D6D6" d="M66.9 70.8h4v46.6h-4z" />
    <path fill="#D87E40" d="M66.9 51.1h4v19.7h-4z" />
    <path fill="#D6D6D6" d="M75.5 70.8h4v46.6h-4z" />
    <path fill="#D87E40" d="M75.5 51.1h4v19.7h-4z" />
    <path fill="#D6D6D6" d="M83.9 70.8h4v46.6h-4z" />
    <path fill="#D87E40" d="M83.9 51.1h4v19.7h-4z" />
    <path fill="#EEEDED" d="M87.9 64.1h8.4V106h-8.4z" />
    <path fill="#F69349" d="M87.9 51.1h8.4v19.7h-8.4z" />
    <path fill="#D6D6D6" d="M92.3 70.8h4v46.6h-4z" />
    <path fill="#D87E40" d="M92.3 51.1h4v19.7h-4z" />
    <path fill="#D6D6D6" d="M93.2 70.8h3.5v46.6h-3.5z" />
    <path fill="#D87E40" d="M41.8 51.1h4v19.7h-4z" />
    <path fill="#EF5B4B" d="M66.1 111.3L36.4 81.6v52.1h61.3v-54z" />
    <path
      fill="#F2F2F2"
      d="M97.7 79.7V60.5h-6.8V68c0 4.3-3.5 7.7-7.7 7.7H50.9c-4.3 0-7.7-3.5-7.7-7.7v-7.5h-6.8v21.1l29.7 29.7 31.6-31.6z"
    />
    <path d="M56.4 89.4c.3-.7.9-1.6 1.6-2 .7-.4 1.3-.9 1.8-.6.3.2.3.9.3.9s18.9-1.5 26.5.8c7.5 2.3 10.8 1.5 31.2 18.3-.5 1.1-1.2 2.4-1.9 3.5-9.9-7.3-21.1-15.4-27-17.9-11.7-5.1-27.3-2.3-28.7-2.2-1.4.1-2.1-.3-2.1-.3-.2 1.1 1.4.7 1.4.7.5 0 6.5-.4 6.5-.4 15.5-1 23.8 4.9 26.5 6.4 1.8 1 13 9.3 21.8 15.8 0 .1-.1.1-.1.2-1.7 1.7-3.3 3.5-5.1 5.4l-12.3-9.7s-.4-.5.1-1.8c.6-1.3 0-2.7-2.5-4.7s-10.8-9.5-34.7-9.4c0 0-.6 1.3-1.2 1.2 0 0-1.8-1-2.4-2-.4-1.2.1-1.5.3-2.2z" />
    <path
      fill="#7C4018"
      d="M114.4 112.5c4.5 3.3 8.4 6.2 10 7.5 4.9 3.6 7.9 6.4 12.2 5.6s5-2.6 5-2.6c-3.8 1.6-4.7 1.1-6.3 1.2-.8.1-9.4-6.5-19.3-13.8.7-1.2 1.4-2.5 1.9-3.5.1.1.2.2.4.3l9.7 7.5s6.4 6.1 10.3 5c0 0 4.6-1.1 5.5-7.5.9-6.4 2-10.8 2-11 0-.2 3.1-3.1 15.1-2.8 12.1.3 14.2 2 14.4 3.2 0 0 .9 12.5.9 12.6 0 .2 2.6 23.6-13.8 26-16.3 2.4-28.7-2.6-52.9-21.8l-.5-.4c1.7-1.8 3.4-3.7 5.1-5.4.2 0 .3-.1.3-.1z"
    />
    <path
      fill="#4D4D4D"
      d="M92.6 96.7c-2.7-1.5-11-7.4-26.5-6.4 0 0-6 .4-6.5.4 0 0-1.7.4-1.4-.7 0 0 .8.4 2.1.3 1.4-.1 17-2.9 28.7 2.2 5.9 2.6 17.1 10.6 27.1 17.9-.5.8-1.1 1.6-1.6 2.2-8.9-6.7-20.1-15-21.9-15.9z"
    />
    <path
      fill="#B58564"
      d="M141.6 122.9s-.7 1.7-5 2.6-7.3-1.9-12.2-5.6c-1.6-1.2-5.5-4.1-10-7.5.5-.5 1-1.3 1.6-2.2 9.9 7.3 18.5 13.9 19.3 13.8 1.6 0 2.5.5 6.3-1.1z"
    />
    <ellipse fill="#B58564" cx={160.6} cy={101.4} rx={14.8} ry={3.3} />
    <ellipse fill="#333" cx={160.6} cy={101.6} rx={9.4} ry={2} />
    <ellipse fill="#E5E5E4" cx={99.2} cy={144.2} rx={14.3} ry={1.7} />
    <g>
      <circle transform="rotate(-45.001 99.007 123.918)" fill="#E5E5E5" cx={99} cy={123.9} r={19.5} />
      <circle
        transform="rotate(-45.001 99.007 123.918)"
        fill="none"
        stroke="#CCC"
        strokeWidth={2.213}
        strokeMiterlimit={10}
        cx={99}
        cy={123.9}
        r={19.5}
      />
      <path
        fill="none"
        stroke="#CCC"
        strokeWidth={2.767}
        strokeMiterlimit={10}
        d="M107.8 130.9c-7.5-7.4-10-9-10-9-1.5-1.2-1.8-.9-.7.7 0 0 1.7 2.5 9.1 10.1-2.4 1.3-7.6 5-12.2.3-6-6-2.9-17-2.9-17s11-3.1 16.9 2.8c4.6 4.6 1.1 9.7-.2 12.1z"
      />
      <path
        fill="#3A9E65"
        d="M107.8 130.9c-7.5-7.4-10-9-10-9-1.5-1.2-1.8-.9-.7.7 0 0 1.7 2.5 9.1 10.1-2.4 1.3-7.6 5-12.2.3-6-6-2.9-17-2.9-17s11-3.1 16.9 2.8c4.6 4.6 1.1 9.7-.2 12.1z"
      />
    </g>
  </svg>
);

export default SmokingIcon;
