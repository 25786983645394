import React from 'react';

export const HandHoldXIcon = (): React.ReactElement => (
  <svg width="96" height="96" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="a" maskUnits="userSpaceOnUse" x="0" y="0" width="96" height="96">
      <path fill="#fff" d="M0 0h96v96H0z" />
    </mask>
    <g mask="url(#a)">
      <circle cx="48.44" cy="43.64" r="43.64" fill="#F26C32" />
      <mask id="b" maskUnits="userSpaceOnUse" x="4" y="0" width="89" height="88">
        <circle cx="48.44" cy="43.64" r="43.64" fill="#fff" />
      </mask>
      <g mask="url(#b)">
        <circle cx="49.7" cy="33.75" r="20.17" fill="#fff" />
        <path
          d="m59.64 38.64-4.91-4.92 4.91-4.91-5.05-5.05-4.91 4.91-4.92-4.91-5.05 5.05 4.92 4.92-4.92 4.91 5.05 5.05 4.92-4.91 4.91 4.91 5.05-5.05Z"
          fill="#F26C32"
        />
        <path
          d="M55.92 61.41c2.53 0 3 .24 4.04 1.07l8.53-8.53c1.66-1.66 4.83-3.95 7.15-3.53 4.46.82 5.17 6.4 2.12 9.45L56.95 80.64c-.4.4-.92.61-1.47.61l-16.43.05a2.4 2.4 0 0 0-1.69.7L25 94.36a1.1 1.1 0 0 1-1.55 0l-9.34-9.34a2 2 0 0 1-.58-1.41v-.37c0-.57.24-1.12.66-1.5l22.36-20.32h19.37Z"
          fill="#B59887"
        />
        <path
          d="M55.92 59.32a4.75 4.75 0 0 1 4.82 4.48 4.62 4.62 0 0 1-4.62 4.76H38.58a.84.84 0 0 0 0 1.69h17.54a6.31 6.31 0 0 0 5.49-9.4l9.5-9.5a4.73 4.73 0 0 1 6.2-.51c2.25 1.74 2.4 4.99.45 6.93L57.04 78.45c-.45.45-1.06.7-1.7.7l-15.77.05c-.97 0-1.89.39-2.57 1.07L25.22 92.05a1.4 1.4 0 0 1-1.98 0l-8.77-8.77a1.34 1.34 0 0 1 0-1.89L35.5 60.37a3.58 3.58 0 0 1 2.53-1.05h17.9Z"
          fill="#FBD7C3"
        />
      </g>
      <ellipse cx="48.44" cy="93.82" rx="43.64" ry="2.18" fill="#000" />
    </g>
  </svg>
);
