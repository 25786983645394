import { ASSETS_PATH } from '@health-activity-ui/shared';
import { RdsButton, RdsLink } from '@rally/energon-react/dist/es6';
import React, { ReactElement, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Level } from '../layout/level/level';
import { LevelItem } from '../layout/level/components/level-item/level-item';
import { RallyModal } from './rally-modal';

interface GinaConsentModalProps {
  openButton: ReactElement;
  continueHandler?: () => Promise<void>;
}

const GinaConsentModal = ({ openButton, continueHandler }: GinaConsentModalProps) => {
  const { t } = useTranslation();
  const [isShowingMore, setIsShowingMore] = useState(false);
  const [readMoreContent] = useState(() => {
    return (
      <div className="read-more-content is-rds-body-3">
        <strong>{t('whyAreWeCollecting')}</strong>
        <p>{t('weCollectThisInformation')}</p>
        <strong>{t('howDoWeProtect')}</strong>
        <p>{t('weUseAdministrativeTechnical')}</p>
        <strong>{t('withWhomDoWe')}</strong>
        <p className="has-rds-mb-16">{t('weDontShareMedical')}</p>
      </div>
    );
  });

  return (
    <RallyModal buttonAriaLabel={t('dismiss')} openButton={openButton} maxWidth="886px">
      <RallyModal.Body>
        <Level className="has-rds-mb-24">
          <LevelItem>
            <img alt="" aria-hidden={true} src={`${ASSETS_PATH}/img/missions/details/gina-modal.svg`} />
          </LevelItem>
        </Level>
        <h1 className="is-rds-h2">{t('readyToStartThis')}</h1>
        <p className="is-rds-h6">{t('beforeYouContinue')}</p>
        <p className="is-rds-body-3">{t('toHelpPeopleLearn')}</p>
        <p className="is-rds-body-3 has-rds-mb-16">
          <strong>{t('learnMoreAboutWhy')}</strong>
        </p>
        {isShowingMore ? readMoreContent : null}
        <RdsButton
          variant="primary-alt"
          className="rds-pseudolink-button is-white-labeled-link"
          onClick={() => setIsShowingMore(!isShowingMore)}
        >
          {isShowingMore ? t('readLess') : t('readMore')}
        </RdsButton>
      </RallyModal.Body>
      <RallyModal.Footer>
        <Level className="has-text-centered">
          <LevelItem className="has-text-centered">
            <RallyModal.ActionButton onClickCb={continueHandler}>{t('continue')}</RallyModal.ActionButton>
          </LevelItem>
        </Level>
        <small>
          <Trans
            i18nKey="userConsentRequiredTo"
            t={t}
            components={[
              <RdsLink href="https://www.rallyhealth.com/corporate/privacy/en-US" target="_blank" variant="inline" />,
            ]}
          />
        </small>
      </RallyModal.Footer>
    </RallyModal>
  );
};

export default GinaConsentModal;
