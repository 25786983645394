import { Dispatch, SetStateAction, useCallback, useEffect, useReducer, useRef } from 'react';

/**
 * The `useSafeState` hook acts the same as React's `useState`,
 * where we can set the initial state, and it returns 1. the current state
 * and 2. the state setter.
 *
 * But with `useSafeState`, in order to prevent memory leaks and avoid warnings
 * (e.g., "Warning: Can’t perform a React state update on an unmounted component."),
 * it will only set the state when the component is mounted.
 * When a component is unmounted, we don't want to set the state.
 *
 * This helps when we have async behavior where we call an async API call,
 * then based on the async response, we set the state. But if the component
 * unmounts before the API call resolves, we don't want to set the state.
 *
 * Inspired by Kent C. Dodds:
 * - https://github.com/kentcdodds/react-github-profile/blob/egghead-2018/refactor-04/src/screens/user/components/query.js
 * - https://egghead.io/lessons/react-safely-setstate-on-a-mounted-react-component-through-the-useeffect-hook
 * */

function useSafeState<S>(initialState?: S): [S, Dispatch<SetStateAction<S>>] {
  const mountedRef = useRef(false);
  const [state, setState] = useReducer((state, newState) => {
    if (typeof newState === 'function') {
      return newState(state);
    }

    return Array.isArray(state)
      ? [...newState]
      : typeof state === 'object'
      ? {
          ...state,
          ...newState,
        }
      : newState;
  }, initialState);

  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const setSafeState = useCallback((args: unknown) => (mountedRef.current ? setState(args) : void 0), []);

  return [state, setSafeState];
}

export { useSafeState };
