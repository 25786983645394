// https://wiki.audaxhealth.com/pages/viewpage.action?spaceKey=ADVO&title=Rally+Now+API+contract
export type NowStep =
  | 'advantage_goal_setup'
  | 'advantage_health_profile'
  | 'advantage_survey'
  | 'advantage_wellness_profile';
export const NowStep = {
  advantageGoalSetup: 'advantage_goal_setup' as NowStep,
  advantageHealthProfile: 'advantage_health_profile' as NowStep,
  advantageSurvey: 'advantage_survey' as NowStep,
  advantageWellnessProfile: 'advantage_wellness_profile' as NowStep,
};
