import { get, useFormContext } from 'react-hook-form';
import { UseErrorStylesProps } from './forms.interface';

const useErrorStyles = ({ name, errorClassName, errorStyle, className, style }: UseErrorStylesProps) => {
  const {
    formState: { errors },
  } = useFormContext();

  const validationError = name ? get(errors, name) : undefined;

  if (validationError) {
    if (errorClassName) {
      className = `${className || ''} ${errorClassName}`.trim();
    }

    if (errorStyle) {
      style = { ...style, ...errorStyle };
    }
  }

  return { className, style };
};

export default useErrorStyles;
