import styled from '@emotion/styled';
import { RDS_SPACING_SIZE_8 } from '@rally/ui-themes/dist/web/rally';

export const Nav = styled.nav`
  .carousel-slide-info {
    border-right: 1px solid;
  }

  .carousel-title {
    margin-top: -${RDS_SPACING_SIZE_8};
  }

  button.previous,
  button.next {
    cursor: pointer;
    &[disabled] {
      cursor: not-allowed;
    }
  }
`;
