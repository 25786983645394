import { ALANUI_BASE_URI, appConfig } from '@health-activity-ui/shared';
import { AxiosResponse } from 'axios';
import { DomainValidationRequest, DomainValidationResponse } from '../contexts/domain/domain.models';
import apiClient from './api-client';

export async function getUnifiedDomain(
  deeplink = window.location.toString(),
  routeProductName = appConfig.HAUI_APP_HA_ROUTE_PRODUCT_NAME
): Promise<AxiosResponse<DomainValidationResponse>> {
  const data: DomainValidationRequest = {
    deeplink,
    routeProductName,
  };

  const response = await apiClient<DomainValidationResponse>(`${ALANUI_BASE_URI}/v2/domain/validate`, {
    data,
    method: 'PUT',
  });

  return response;
}
