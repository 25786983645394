import React from 'react';

interface Props {
  width?: number;
  height?: number;
}

export const BloodCholesterolIcon = ({ width = 200, height = 165 }: Props): React.ReactElement => (
  <svg viewBox="0 0 200 165" width={width} height={height}>
    <ellipse fill="#E5E5E4" cx={100.3} cy={138.4} rx={36.1} ry={3.3} />
    <path
      fill="#CF4F41"
      d="M149.3 40.1c-2-2-4.3-3.8-6.6-5.2 11.5 13.7 10.8 34.1-2.1 47L92 130.5l8.1 8.1 49.3-49.3c13.5-13.6 13.5-35.6-.1-49.2z"
    />
    <path
      fill="#EF5B4B"
      d="M142.6 34.9c-13.4-8.1-31-6.4-42.6 5.2-13.6-13.6-35.7-13.6-49.3 0-13.6 13.6-13.6 35.7 0 49.3l41.2 41.2L140.5 82c12.9-13 13.6-33.5 2.1-47.1z"
    />
    <path
      fill="#CF4F41"
      d="M114.9 92.2L101.1 106h-.1L87.2 92.2 74.8 79.8l13.9-13.9 12.4 12.4L128 51.4l13.8 13.8-26.9 27z"
    />
    <path fill="#FFF" d="M112.9 86.2L99.1 100H99L85.2 86.2 72.8 73.8l13.9-13.9 12.4 12.4L126 45.4l13.8 13.8-26.9 27z" />
  </svg>
);

export default BloodCholesterolIcon;
