import { KAISER_CLIENT, KAISER_PARTNER, Status } from '@health-activity-ui/shared';
import { createReducer } from '@reduxjs/toolkit';
import * as authorizationActions from './authorization.actions';
import { AuthorizationState, Features } from './authorization.models';

export const AuthorizationReducer = createReducer({} as AuthorizationState, (builder) =>
  builder
    .addCase(authorizationActions.productEligibilityFetchLoading, (state) => {
      state.status = Status.loading;
    })
    .addCase(authorizationActions.productEligibilityFetchLoaded, (state, action) => {
      state.status = Status.loaded;
      if (action.payload.partner === KAISER_PARTNER && action.payload.client === KAISER_CLIENT) {
        // kaiser user
        state.canViewWellnessMissions = true;
        return;
      }
      state.canViewWellnessMissions = action.payload.featureEligibility[Features.Missions];
      state.canViewWellnessChallenges = action.payload.featureEligibility[Features.Challenges];
      state.canViewWellnessActivityDashboard = action.payload.featureEligibility[Features.ActivityDashboard];
      state.canViewWellnessCommunities = action.payload.featureEligibility[Features.Communities];
    })
    .addCase(authorizationActions.productEligibilityFetchFailed, (state) => {
      state.status = Status.error;
    })
);
