import {
  AdvantageConfig,
  APPOINTMENT,
  EMPTY_ADVANTAGE_LINK,
  GOAL_SETTINGS_BASE_ROUTE,
} from '@health-activity-ui/shared';
import { setWhiteLabel } from '@rally/energon-utilities';
import { getUiConfig } from '../../api/advantage.api';
import { withProvider } from '../with-provider';
import { UiConfigState, UI_CONFIG_ACTION_TYPES } from './ui-config.models';
import { uiConfigReducer } from './ui-config.reducer';

export const initialUiConfigState: UiConfigState = {
  data: {
    footer: {
      corporate: [],
      footerSections: {},
    },
    header: {
      accountMenu: [],
      logo: {
        link: EMPTY_ADVANTAGE_LINK,
        imageAltText: '',
      },
      nav: {},
    },
    help: EMPTY_ADVANTAGE_LINK,
    notifications: EMPTY_ADVANTAGE_LINK,
  },
  goalSettingsUiConfig: null,
  // initialize as true - will be set to false by an 403 from an adv-edge endpoint call
  // this flag will determine if the unauthorized component will be shown to the user
  hasAdvantageAuth: true,
  hasSubnav: true,
  status: null,
  whiteLabelHasBeenSet: false,
};

async function updateUiConfig(dispatch, locale: string) {
  dispatch({ type: UI_CONFIG_ACTION_TYPES.UI_CONFIG_FETCH_LOADING });
  try {
    const uiConfig = await getUiConfig(locale);
    if (uiConfig?.whiteLabel) {
      setWhiteLabel(uiConfig?.whiteLabel);
      dispatch({ type: UI_CONFIG_ACTION_TYPES.UI_CONFIG_FETCH_LOADED_WITH_WHITE_LABEL, payload: uiConfig });
    } else {
      dispatch({ type: UI_CONFIG_ACTION_TYPES.UI_CONFIG_FETCH_LOADED, payload: uiConfig });
    }
  } catch (error) {
    dispatch({
      type: UI_CONFIG_ACTION_TYPES.UI_CONFIG_FETCH_FAILED,
      payload: { error },
    });
  }
}

function determineSubnav(dispatch, pathname: string) {
  const hiddenSubNavRoutes = [GOAL_SETTINGS_BASE_ROUTE, APPOINTMENT];
  dispatch({
    type: UI_CONFIG_ACTION_TYPES.UI_CONFIG_DETERMINE_SUBNAV,
    payload: { hasSubnav: !hiddenSubNavRoutes.some((r) => pathname.toLowerCase().includes(r)) },
  });
}

const [UiConfigProvider, useUiConfig] = withProvider<UiConfigState, AdvantageConfig | Record<string, unknown>>({
  reducer: uiConfigReducer,
  displayName: 'UiConfigProvider',
  initialState: initialUiConfigState,
});

export { UiConfigProvider, useUiConfig, updateUiConfig, determineSubnav };
