import { AnalyticsHeaders, appConfig, AuthHeaders, SESSION } from '@health-activity-ui/shared';
import { detectLanguage, handleDates, handleErrors } from '@health-activity-ui/utils';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { cacheAdapterEnhancer, throttleAdapterEnhancer } from 'axios-extensions';

async function apiClient<T>(
  endpoint: string,
  { data, headers: customHeaders, method = 'GET', params, ...customConfig }: AxiosRequestConfig = {}
): Promise<AxiosResponse<T>> {
  const config: AxiosRequestConfig = {
    adapter: throttleAdapterEnhancer(
      cacheAdapterEnhancer(axios.defaults.adapter, {
        enabledByDefault: false,
        cacheFlag: 'useCache',
      })
    ),
    baseURL: appConfig.HAUI_APP_HA_UNIFIED_DOMAIN_API_URL,
    data: data ? JSON.stringify(data) : undefined,
    headers: {
      Accept: AuthHeaders.ApplicationJson,
      [AuthHeaders.ContentType]: data ? AuthHeaders.ApplicationJson : undefined,
      [AuthHeaders.Locale]: detectLanguage(),
      Pragma: AuthHeaders.NoCache,
      [AuthHeaders.RPTokenSuffix]: SESSION.RP_TOKEN,
      [AnalyticsHeaders.RallyReferer]: location.pathname,
      [AnalyticsHeaders.RallyClient]: AuthHeaders.Web,
      ...customHeaders,
    },
    method,
    url: endpoint,
    withCredentials: true,
    xsrfHeaderName: AuthHeaders.XSRFToken,
    xsrfCookieName: SESSION.COOKIE.XSRF_FH_AD,
    params,
    ...customConfig,
  };

  const axiosInstance = axios.create();

  axiosInstance.interceptors.request.use(
    (config) => {
      // Do something before request is sent
      return config;
    },
    (error): Promise<never> => {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      // Any status code from range of 2xx
      // Do something with response data
      // convert any strings that are supposed to be Date objects to a Date object
      if (response.data) {
        const transformedData = handleDates(response.data);
        response.data = transformedData;
      }

      return response;
    },
    async (error) => {
      await handleErrors(error);
    }
  );
  return await axiosInstance.request(config);
}

export default apiClient;
