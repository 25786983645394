import {
  appConfig,
  localWindow,
  ProvisionedOIDCSession,
  SESSION,
  Status,
  initHuginn,
} from '@health-activity-ui/shared';
import { loadScriptToHead } from '@health-activity-ui/utils';
import Cookies from 'js-cookie';
import { createContext, Dispatch, ReactElement, ReactNode, useContext, useEffect, useReducer } from 'react';
import { AnyAction } from 'redux';
import { provisionSession } from '../../services/huginn.service';
import * as authenticationActions from './authentication.actions';
import { AuthenticationState } from './authentication.models';
import { authenticationReducer } from './authentication.reducer';

const AuthenticationContext = createContext<{ state: AuthenticationState; dispatch: Dispatch<AnyAction> }>(undefined);
AuthenticationContext.displayName = 'AuthenticationContext';

export const initialAuthenticationState = {
  data: null,
  status: Status.initial,
  error: null,
};

function AuthenticationProvider({
  children,
  loaderComponent,
  errorComponent,
}: {
  children: ReactNode;
  loaderComponent: ReactElement;
  errorComponent: ReactElement;
}) {
  const [state, dispatch] = useReducer(authenticationReducer, initialAuthenticationState);

  useEffect(() => {
    (async () => {
      if (state.status === Status.initial) {
        await getHuginnSession(dispatch);
      }
    })();
  }, [state.status]);

  return (
    <AuthenticationContext.Provider value={{ state, dispatch }}>
      {state.status === Status.loading || state.status === Status.initial
        ? loaderComponent
        : state.status === Status.error
        ? errorComponent
        : children}
    </AuthenticationContext.Provider>
  );
}

function useAuthentication() {
  const context = useContext(AuthenticationContext);
  const isLoading = context.state.status === Status.loading;
  const isError = context.state.status === Status.error;
  const isLoaded = context.state.status === Status.loaded;
  const isAuthenticated = isLoaded && context.state.data?.meta?.success && context.state.data?.data?.rsMetadata?.sid;
  const error = isError && context.state?.error;

  if (!context) {
    throw new Error(`useAuthentication must be used within the AuthenticationProvider`);
  }

  return {
    ...context,
    isLoading,
    isError,
    isLoaded,
    isAuthenticated,
    error,
  };
}

async function getHuginnSession(dispatch: Dispatch<AnyAction>) {
  dispatch(authenticationActions.authenticationLoading());

  await loadScriptToHead({
    src: appConfig.HAUI_APP_HA_HUGINN_SCRIPT_URL,
    id: 'huginn',
    windowConfigName: 'initHuginn',
    windowConfig: {
      ...initHuginn,
      onLoad: async () => await handleProvisionSession(dispatch),
    },
  });
}

async function handleProvisionSession(dispatch: Dispatch<AnyAction>) {
  try {
    const provisionedSession = await provisionSession();
    if (provisionedSession?.meta?.success) {
      handleOIDCSessionSuccess(provisionedSession);
      dispatch(authenticationActions.authenticationLoaded(provisionedSession));
    } else {
      const error = new Error(`Errored: ${provisionedSession}`);
      dispatch(authenticationActions.authenticationFailed(error));
    }
    return provisionedSession;
  } catch (error) {
    dispatch(authenticationActions.authenticationFailed(error));
    return Promise.reject(error);
  }
}

function handleOIDCSessionSuccess(rsp: ProvisionedOIDCSession) {
  const xsrfToken = rsp?.data?.rsMetadata?.sid;
  if (xsrfToken) {
    Cookies.set(SESSION.COOKIE.XSRF_FH_AD, xsrfToken);
  }
}

function endSession(): void {
  localWindow.location.replace(`${appConfig.HAUI_APP_HA_ACCOUNTS_URL}/logout`);
}

function goToLogin(): void {
  localWindow.location.replace(
    `${appConfig.HAUI_APP_HA_ACCOUNTS_URL}/authenticate?redirect_uri=${appConfig.HAUI_APP_HA_UI_UNIFIED_URL}&client_id=${SESSION.RP_CLIENT_ID}`
  );
}

export { AuthenticationProvider, endSession, goToLogin, useAuthentication };
