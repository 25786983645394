import classnames from 'classnames';

export type TypescaleProps = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'body-1' | 'body-2' | 'body-3' | 'sm' | 'micro';
export type LineHeightProps = '16' | '20' | '24' | '28' | '32' | '36' | '40' | '44';

export interface TypographyProps {
  // the rds typescale class to generate
  typescale?: TypescaleProps;

  // applies 'touch' styles to the given typescale. 'touch' affects everything lower than desktop.
  isTypeTouch?: boolean;

  // the line-height to use for the line-height property
  lineHeight?: LineHeightProps;
}

export default {
  defaultProps: {
    typescale: undefined,
    isTypeTouch: false,
    lineHeight: undefined,
  },
  classnames: (props: TypographyProps): string => {
    return classnames({
      [`is-rds-${props.typescale}`]: props.typescale,
      [`is-rds-${props.typescale}-touch`]: props.typescale && props.isTypeTouch,
      [`is-rds-lh-${props.lineHeight}`]: props.lineHeight,
    });
  },
  /* eslint-disable-next-line */
  base: ({ typescale, isTypeTouch, lineHeight, ...props }) => props,
};
