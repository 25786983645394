import { useProfile } from '@health-activity-ui/data-access';
import Cookies from 'js-cookie';
import { ROUTE_PRODUCT_NAME, QUALTRICS_LANGUAGE_LOOKUP, LOCALE } from '@health-activity-ui/shared';
import useScript from './use-script.hook';

export function useNps() {
  const {
    state: {
      data: { rallyId, partner, client },
    },
  } = useProfile();

  let loadFeedbackComponent = false;
  loadFeedbackComponent = client !== 'geha' && partner === 'optum' ? true : false;

  if (loadFeedbackComponent) {
    window.rallyId = rallyId;
    window.clientId = client;
    window.partnerId = partner;
  }

  window.qualtricsIetfLanguageCode = QUALTRICS_LANGUAGE_LOOKUP[Cookies.get(LOCALE.COOKIE)] || LOCALE.EN_US;
  const npsStatus = useScript(`/${ROUTE_PRODUCT_NAME}/haui/vendor/qualtrics/nps.js`, 'nps', loadFeedbackComponent);
  return {
    npsStatus,
    loadFeedbackComponent,
  };
}
