import React from 'react';

export const FlagsTeamBattleIcon = (): React.ReactElement => (
  <svg width="48px" height="31px" viewBox="0 0 48 31" aria-hidden="true">
    <title>A Few Things About Team Battle Icon</title>
    <defs>
      <polygon
        id="path-1"
        points="0.125791476 0.0487836735 10.6912061 0.0487836735 10.6912061 10.1323345 0.125791476 10.1323345"
      />
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-237.000000, -740.000000)">
        <g id="Group-4" transform="translate(237.000000, 731.000000)">
          <g id="Group-32-Copy" transform="translate(0.000000, 9.000000)">
            <g id="Group-3" transform="translate(18.416327, 0.147135)">
              <path
                d="M10.3349094,3.62793796 L6.82973388,3.62793796 L5.74669714,0.294386939 C5.64031347,-0.0331885714 5.17677061,-0.0331885714 5.07038694,0.294386939 L3.98715429,3.62793796 L0.482174694,3.62793796 C0.137554286,3.62793796 -0.00566204082,4.06875429 0.273129796,4.27133388 L3.10865633,6.33161143 L2.02561959,9.66496653 C1.91904,9.99273796 2.29422367,10.2652604 2.57281959,10.0628767 L5.40854204,8.00240327 L8.24406857,10.0628767 C8.52286041,10.2652604 8.89784816,9.99273796 8.79146449,9.66496653 L7.70842776,6.33161143 L10.5439543,4.27133388 C10.8225502,4.06875429 10.6795298,3.62793796 10.3349094,3.62793796"
                id="Fill-1"
                fill="#FD9547"
              />
            </g>
            <path
              d="M15.456529,26.3793502 L15.456529,26.3793502 C15.7670596,26.6898808 16.2705698,26.6898808 16.5811004,26.3793502 L36.3968718,6.56338286 C36.7075984,6.25285224 36.7075984,5.74934204 36.3968718,5.43881143 C36.0863412,5.12828082 35.5830269,5.12828082 35.2723004,5.43881143 L15.456529,25.2547788 C15.1459984,25.5653094 15.1459984,26.0688196 15.456529,26.3793502"
              id="Fill-4"
              fill="#DB813D"
            />
            <path
              d="M41.2557649,17.048542 C41.1615282,16.9497992 41.0672914,16.8510563 40.9730547,16.7521176 C41.0672914,16.8510563 41.1615282,16.9497992 41.2557649,17.048542"
              id="Fill-6"
              fill="#FE9A0B"
            />
            <polygon
              id="Fill-8"
              fill="#FEC62C"
              points="47.8947918 21.2179396 43.9805388 17.3036865 34.6840163 26.6004049 38.5982694 30.514462"
            />
            <polygon
              id="Fill-10"
              fill="#DCAC25"
              points="46.9408261 17.3037845 43.9806955 17.3037845 34.6839771 26.6003069 37.6443037 26.6003069"
            />
            <polygon
              id="Fill-12"
              fill="#FED154"
              points="37.6442841 26.6003265 25.8776229 14.8334694 35.1743412 5.53694694 46.9408065 17.3038041"
            />
            <polygon
              id="Fill-14"
              fill="#DB813D"
              points="25.8777012 14.8335673 25.8777012 17.0597878 26.9909094 15.9467755"
            />
            <path
              d="M40.2897502,17.8808229 L37.8113829,15.4024555 L39.4026318,12.2793208 C39.5589747,11.9723167 39.2312033,11.6445453 38.9241992,11.8010841 L35.8012604,13.3923331 L33.3226971,10.9137698 C33.0791706,10.6700473 32.6661747,10.8806596 32.7198563,11.2209698 L33.2682318,14.6828473 L30.1452931,16.2740963 C29.838289,16.4306351 29.9107788,16.8884963 30.251089,16.942178 L33.7129665,17.4905535 L34.2611461,20.952431 C34.3152196,21.2927412 34.7730808,21.365231 34.9294237,21.0584229 L36.5208686,17.9350922 L39.9827461,18.4834678 C40.3228604,18.5375412 40.5332767,18.1243494 40.2897502,17.8808229"
              id="Fill-16"
              fill="#FD9547"
            />
            <path
              d="M32.4382824,26.3793502 L32.4382824,26.3793502 C32.1277518,26.6898808 31.6242416,26.6898808 31.313711,26.3793502 L11.4979396,6.56338286 C11.1872131,6.25285224 11.1872131,5.74934204 11.4979396,5.43881143 C11.8084702,5.12828082 12.3117845,5.12828082 12.622511,5.43881143 L32.4382824,25.2547788 C32.7488131,25.5653094 32.7488131,26.0688196 32.4382824,26.3793502"
              id="Fill-18"
              fill="#DB813D"
            />
            <path
              d="M6.63904653,17.048542 C6.73328327,16.9497992 6.82752,16.8510563 6.92175673,16.7521176 C6.82752,16.8510563 6.73328327,16.9497992 6.63904653,17.048542"
              id="Fill-20"
              fill="#FE9A0B"
            />
            <polygon
              id="Fill-22"
              fill="#FEC62C"
              points="1.95918367e-05 21.2179396 3.91427265 17.3036865 13.2107951 26.6004049 9.29654204 30.514462"
            />
            <polygon
              id="Fill-24"
              fill="#DCAC25"
              points="0.953965714 17.3037845 3.91409633 17.3037845 13.2108147 26.6003069 10.2504882 26.6003069"
            />
            <polygon
              id="Fill-26"
              fill="#FED154"
              points="10.2505273 26.6003265 22.0171886 14.8334694 12.7206661 5.53694694 0.954004898 17.3038041"
            />
            <polygon
              id="Fill-28"
              fill="#DB813D"
              points="22.0171102 14.8335673 22.0171102 17.0597878 20.903902 15.9467755"
            />
            <path
              d="M7.60506122,17.8808229 L10.0834286,15.4024555 L8.49217959,12.2793208 C8.33583673,11.9723167 8.66360816,11.6445453 8.97061224,11.8010841 L12.093551,13.3923331 L14.5721143,10.9137698 C14.8156408,10.6700473 15.2286367,10.8806596 15.1749551,11.2209698 L14.6265796,14.6828473 L17.7495184,16.2740963 C18.0565224,16.4306351 17.9840327,16.8884963 17.6437224,16.942178 L14.1818449,17.4905535 L13.6336653,20.952431 C13.5795918,21.2927412 13.1217306,21.365231 12.9653878,21.0584229 L11.3739429,17.9350922 L7.91206531,18.4834678 C7.57195102,18.5375412 7.36153469,18.1243494 7.60506122,17.8808229"
              id="Fill-30"
              fill="#FD9547"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default FlagsTeamBattleIcon;
