import { useState, useRef } from 'react';

interface ToggleApi {
  on: () => void;
  off: () => void;
  toggle: () => void;
}

export const useToggleState = (initialState = false): [boolean, ToggleApi] => {
  const [state, setState] = useState(initialState);
  // This serves as a very cheap way to memoize the custom hook API
  const api = useRef({
    on: () => setState(true),
    off: () => setState(false),
    toggle: () => setState((current) => !current),
  });

  return [state, api.current];
};
