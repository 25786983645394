import { Status } from '@health-activity-ui/shared';
import React, { createContext, Dispatch, useContext, useReducer } from 'react';
import { AnyAction } from 'redux';
import { getSimpleProductEligibility } from '../../api/advantage.api';
import * as authorizationActions from './authorization.actions';
import { AuthorizationState } from './authorization.models';
import { AuthorizationReducer } from './authorization.reducer';

const AuthorizationContext = createContext<{ state: AuthorizationState; dispatch: Dispatch<AnyAction> }>(undefined);
AuthorizationContext.displayName = 'AuthorizationProvider';

export const initialAuthorizationState: AuthorizationState = {
  canViewWellnessMissions: false,
  canViewWellnessChallenges: false,
  canViewWellnessCommunities: false,
  canViewWellnessActivityDashboard: false,
  status: Status.initial,
};

function useAuthorization() {
  const context = useContext(AuthorizationContext);
  if (!context) {
    throw new Error(`useAuthorization must be used within the AuthorizationProvider`);
  }

  return context;
}

function AuthorizationProvider({ children, AuthorizationState = initialAuthorizationState }) {
  const [state, dispatch] = useReducer(AuthorizationReducer, AuthorizationState);
  const value = { state, dispatch };

  return <AuthorizationContext.Provider value={value}>{children}</AuthorizationContext.Provider>;
}

async function callGetProductEligibility(
  dispatch: Dispatch<AnyAction>,
  rallyId: string,
  partner: string,
  client: string
): Promise<Record<string, boolean>> {
  dispatch(authorizationActions.productEligibilityFetchLoading());
  try {
    const featureEligibility = await getSimpleProductEligibility(rallyId);
    dispatch(authorizationActions.productEligibilityFetchLoaded({ featureEligibility, partner, client }));
    return featureEligibility;
  } catch (error) {
    dispatch(authorizationActions.productEligibilityFetchFailed());
    return Promise.reject(error);
  }
}
export { AuthorizationProvider, useAuthorization, callGetProductEligibility };
