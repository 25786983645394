import React from 'react';

interface Props {
  width?: number;
  height?: number;
}

export const BiologicalSexIcon = ({ width = 200, height = 165 }: Props): React.ReactElement => (
  <svg
    id="BiologicalSexIconLayer_1"
    x={0}
    y={0}
    viewBox="0 0 200 165"
    xmlSpace="preserve"
    width={width}
    height={height}
  >
    <style>
      {
        '.BiologicalSexIconst0{fill:#e5e5e4}.BiologicalSexIconst2{fill:#eadbb7}.BiologicalSexIconst3{fill:#008eb5}.BiologicalSexIconst4{fill:#f4bea0}.BiologicalSexIconst6{fill:#ef5b4b}.BiologicalSexIconst8{fill:#7f7f7f}.BiologicalSexIconst9{fill:#f2f2f2}.BiologicalSexIconst13{fill:#af6140}'
      }
    </style>
    <ellipse className="BiologicalSexIconst0" cx={138} cy={134} rx={21} ry={2.2} />
    <path fill="#dcaa8f" d="M133.9 48.5h8.5v4.7h-8.5z" />
    <circle className="BiologicalSexIconst2" cx={143.9} cy={113} r={4.9} />
    <path className="BiologicalSexIconst3" d="M139 113h9.8v19.9H139z" />
    <circle className="BiologicalSexIconst2" cx={143.9} cy={90.6} r={4.9} />
    <circle className="BiologicalSexIconst3" cx={143.9} cy={113} r={4.9} />
    <path className="BiologicalSexIconst3" d="M139 90.6h9.8V113H139z" />
    <circle className="BiologicalSexIconst2" cx={132.5} cy={113} r={4.9} />
    <path className="BiologicalSexIconst3" d="M127.6 113h9.8v19.9h-9.8z" />
    <circle className="BiologicalSexIconst2" cx={132.5} cy={90.6} r={4.9} />
    <circle className="BiologicalSexIconst3" cx={132.5} cy={113} r={4.9} />
    <path className="BiologicalSexIconst3" d="M127.6 90.6h9.8V113h-9.8z" />
    <path className="BiologicalSexIconst4" d="M150.4 61h7.3v13h-7.3z" />
    <circle cx={154} cy={61} r={3.6} fill="#8dd1cb" />
    <path className="BiologicalSexIconst6" d="M157.7 61c0-4.9-4-8.9-8.9-8.9V61h8.9zm-30.1-9h21.1v38.6h-21.1z" />
    <path className="BiologicalSexIconst6" d="M118.7 61c0-4.9 4-8.9 8.9-8.9V61h-8.9z" />
    <path className="BiologicalSexIconst4" d="M118.7 61h7.3v13h-7.3z" />
    <circle className="BiologicalSexIconst6" cx={122.3} cy={61} r={3.6} />
    <circle className="BiologicalSexIconst4" cx={138.2} cy={41.5} id="BiologicalSexIconhead_6_" r={8.5} />
    <path className="BiologicalSexIconst8" d="M139 132.9h9.8v1H139zm-11.4 0h9.8v1h-9.8z" />
    <path
      className="BiologicalSexIconst9"
      d="M148.7 132.9c0-2.7-2.2-4.9-4.9-4.9s-4.9 2.2-4.9 4.9h9.8zm-11.3 0c0-2.7-2.2-4.9-4.9-4.9s-4.9 2.2-4.9 4.9h9.8z"
    />
    <path d="M142.5 59c-1.7 0-1.9 1.8-1.9 2.2h3.9c-.1-.3-.3-2.2-2-2.2z" fill="none" />
    <path
      className="BiologicalSexIconst4"
      d="M149.4 85.1c-.4-.4-.4-1 0-1.4l1.8-1.8c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-1.8 1.8c-.4.4-1 .4-1.4 0z"
    />
    <circle className="BiologicalSexIconst4" cx={154} cy={87} r={3.6} />
    <path className="BiologicalSexIconst4" d="M150.4 74h7.3v8.3h-7.3z" />
    <circle className="BiologicalSexIconst4" cx={154} cy={74} r={3.6} />
    <path
      className="BiologicalSexIconst4"
      d="M150.4 82.3h7.3V87h-7.3zM127 85.1c.4-.4.4-1 0-1.4l-1.8-1.8c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l1.8 1.8c.4.4 1 .4 1.4 0z"
    />
    <circle className="BiologicalSexIconst4" cx={122.3} cy={87} r={3.6} />
    <path className="BiologicalSexIconst4" d="M118.7 74h7.3v8.3h-7.3z" />
    <circle className="BiologicalSexIconst4" cx={122.3} cy={74} r={3.6} />
    <path className="BiologicalSexIconst4" d="M118.7 82.3h7.3V87h-7.3z" />
    <path
      d="M143.3 35.4s-1.3 3.6-11.9 3.4c0 0-1.8 1-1 3.4l-1.1-.8s-2.2-3.1 1.8-7.3c5-5.1 12-1.9 12-1.9s5.7.5 4.1 8.6l-1.1 1.3c.2.1-1.1-5.7-2.8-6.7z"
      fill="#7f511f"
    />
    <g>
      <path className="BiologicalSexIconst6" d="M150.4 61h7.3v7.2h-7.3z" />
    </g>
    <g>
      <path className="BiologicalSexIconst6" d="M118.7 61h7.3v7.2h-7.3z" />
    </g>
    <g>
      <ellipse className="BiologicalSexIconst0" cx={66.1} cy={134} rx={21} ry={2.2} />
      <g id="BiologicalSexIconwoman_3_">
        <path d="M61.1 62.6c0 5.1 9.1 5.1 9.1 0V40.3h-9.1v22.3z" fill="#96523b" />
        <path
          className="BiologicalSexIconst13"
          d="M65.7 56.6c3.1 0 5.8-2.1 6.6-5H59.2c.7 2.9 3.4 5 6.5 5zm16.2 30.5c1.6 5 8.1 2.9 6.7-2.3l-6.5-25.6c-.1-.4-.3-.8-.5-1.2l-5.5 5.5 5.8 23.6z"
        />
        <path className="BiologicalSexIconst3" d="M75.1 96.7h-9l1.2 18h7.8zm-9.8 0h-9v18h7.8z" />
        <path className="BiologicalSexIconst3" d="M56.3 77.3h18.9v20.4H56.3z" />
        <path
          className="BiologicalSexIconst13"
          d="M74.5 129.7l.6-15h-7.8l.6 15c0 5.2 6.6 5.2 6.6 0zm-11 0l.6-15h-7.9l.6 15c.1 5.2 6.7 5.2 6.7 0z"
        />
        <path
          className="BiologicalSexIconst9"
          d="M64.4 132.1c0-2.3-1.9-4.2-4.2-4.2-2.3 0-4.2 1.9-4.2 4.2v1.5h8.5l-.1-1.5zm11 0c0-2.3-1.9-4.2-4.2-4.2-2.3 0-4.2 1.9-4.2 4.2v1.5h8.5l-.1-1.5z"
        />
        <path
          className="BiologicalSexIconst13"
          d="M80.2 85.9c-.4-.2-.5-.7-.3-1.1l1.1-1.9c.2-.4.7-.5 1.1-.3.4.2.5.7.3 1.1l-1.1 1.9c-.2.4-.7.5-1.1.3z"
        />
        <circle className="BiologicalSexIconst13" cx={65.7} cy={41.3} r={8.5} />
        <path
          d="M62.4 36.3s-.1 3.4 7.8 2.6c5.9-.6 2.3 8.6 0 9.6s0 3.1 0 3.1 13.9-9.9 1.1-19.6c-1.8-1.3-8.8-.4-8.8-.4s-15.7 4.3-1.3 20c0 0 1.4-1.4 0-3.1-3.5-4.2-3.5-11.5 1.2-12.2z"
          fill="#541f1b"
        />
        <path
          className="BiologicalSexIconst6"
          d="M74.2 63.4h1.9l5.5-5.5c-1.2-2.7-3.7-6.3-8.8-6.3h-.6c-.8 2.9-3.4 5-6.6 5s-5.8-2.1-6.6-5h-.7c-5.1 0-7.6 3.6-8.7 6.3l5.5 5.5H57l-5 28.1s.1.6 13.7.6 13.6-.6 13.6-.6l-5.1-28.1z"
        />
        <path
          className="BiologicalSexIconst13"
          d="M48.7 87.1c-1.6 5-8.1 2.9-6.7-2.3l7.3-25.6c.1-.4.3-.8.5-1.2l5.5 5.5-6.6 23.6z"
        />
        <path
          className="BiologicalSexIconst13"
          d="M50.4 85.9c.4-.2.5-.7.3-1.1l-1.1-1.9c-.2-.4-.7-.5-1.1-.3-.4.2-.5.7-.3 1.1l1.1 1.9c.2.4.7.5 1.1.3z"
        />
      </g>
      <path className="BiologicalSexIconst8" d="M66.9 132.9h8.5v1h-8.5zm-10.9 0h8.5v1H56z" />
    </g>
    <g>
      <path fill="#4d4d4d" d="M101.4 19.2h.5v127h-.5z" />
    </g>
  </svg>
);

export default BiologicalSexIcon;
