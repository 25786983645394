import { useCallback, useEffect, useRef } from 'react';

/*
 This is just a wrapper function over the dispatch,
 we want to pass any arguments back to the actual dispatch and
 When a component un-mounts we should no longer dispatch.
 This tells a component that it is ok to still dispatch and prevents
 memory leaks when the component un-mounts.

 If not you get an error:
 Warning: Can’t perform a React state update on an unmounted component.
 This is a no-op, but it indicates a memory leak in your application.
 To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function.
*/

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function useSafeDispatch(dispatch: (...args: any[]) => unknown) {
  const mountedRef = useRef(false);

  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return useCallback((...args) => (mountedRef.current ? dispatch(...args) : void 0), [dispatch]);
}

export { useSafeDispatch };
