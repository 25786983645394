import { ButtonProps, RdsButton } from '@rally/energon-react/dist/es6';
import React, { forwardRef, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { SpinnerLoader } from '../loader/spinner-loader.component';

interface SubmitButtonProps extends ButtonProps {
  validateWithErrors?: boolean;
}

export const SubmitButton = forwardRef<HTMLButtonElement, SubmitButtonProps>(
  ({ children, size, variant, width, icon, validateWithErrors = false, ...rest }, ref) => {
    const {
      formState: { isDirty, isValid, isSubmitting, errors },
    } = useFormContext();
    const [disabled, setDisabled] = useState(true);
    const hasErrors = Object.keys(errors || {}).length > 0;

    useEffect(() => {
      if (validateWithErrors) {
        setDisabled(hasErrors || isSubmitting);
      } else {
        setDisabled(!isDirty || !isValid || isSubmitting);
      }
    }, [isDirty, isValid, isSubmitting, validateWithErrors, hasErrors]);

    return (
      <RdsButton
        ref={ref}
        type="submit"
        size={size}
        variant={variant}
        width={width}
        icon={icon}
        disabled={disabled}
        {...rest}
      >
        {isSubmitting ? <SpinnerLoader /> : children}
      </RdsButton>
    );
  }
);
