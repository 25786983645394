import { IconProps, RdsIcon } from '@rally/energon-react/dist/es6';
import React from 'react';

interface TextWithCaretIconProps extends IconProps {
  text: string;
}

export const TextWithCaretIcon = ({ text, ...rest }: TextWithCaretIconProps) => {
  return (
    <>
      {text} <RdsIcon name="system-arrow-md" {...rest} />
    </>
  );
};
