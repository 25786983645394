import { useCallback, useEffect, useRef, useState } from 'react';

export type Storage = 'localStorage' | 'sessionStorage';
export const StorageTypes = {
  Local: 'localStorage' as Storage,
  Session: 'sessionStorage' as Storage,
};

export function useStorageState<T>(key: string, defaultValue: T | (() => T), storageType = StorageTypes.Local) {
  const [state, setState] = useState(
    // lazy initialization
    // read value one time only the first time the component mounts and not on every rerender
    // reading from local or session storage is an expensive process and we shouldn't need to
    // constantly read on every rerender.
    () => {
      let valueInStorage = window[storageType].getItem(key);

      // we do not want to store `null` as a string in storage, if it's `null` set to empty string;
      valueInStorage =
        !(typeof valueInStorage === 'string' && valueInStorage === 'null') && valueInStorage !== null
          ? valueInStorage
          : '';

      // return the valueIn Storage if its an empty string, and not a boolean type, we want to convert booleans to string
      if (valueInStorage === '' && typeof defaultValue !== 'boolean') {
        return valueInStorage;
      }

      if (valueInStorage) {
        try {
          return JSON.parse(valueInStorage);
        } catch (e) {
          return valueInStorage;
        }
      }

      // if you have some complex logic necessary to create the default value then pass a function otherwise just the default value
      return defaultValue instanceof Function ? defaultValue() : defaultValue;
    }
  );

  // if there is a need to rename the key we can remove the key, by checking its previous value
  const prevKeyRef = useRef(key);

  useEffect(() => {
    const prevKey = prevKeyRef.current;
    if (prevKey !== key) {
      window[storageType].removeItem(prevKey);
      storageType === StorageTypes.Local
        ? window.dispatchEvent(new Event(StorageTypes.Local))
        : window.dispatchEvent(new Event(StorageTypes.Session));
    }
    prevKeyRef.current = key;

    // We can't expect external applications to JSON.parse the values we set in storage
    // if it's a string no need to stringify as it will return wrapped in quotes
    // which an external application will then need to JSON.parse
    window[storageType].setItem(key, typeof state === 'string' ? state : JSON.stringify(state));

    storageType === StorageTypes.Local
      ? window.dispatchEvent(new Event(StorageTypes.Local))
      : window.dispatchEvent(new Event(StorageTypes.Session));
  }, [key, state, storageType]);

  // Inspired from https://usehooks-ts.com/react-hook/use-local-storage
  const readState = useCallback(() => {
    // Prevent build error "window is undefined"
    if (typeof window === 'undefined') {
      return defaultValue;
    }

    const item = window[storageType].getItem(key);
    let parsedItem;

    try {
      parsedItem = JSON.parse(item);
    } catch (error) {
      // if the value in storage is not JSON.parse-able then stringify it first so that it is
      // otherwise just return the defaultValue.
      parsedItem = item ? JSON.parse(JSON.stringify(item)) : defaultValue;
    }
    return parsedItem;
  }, [defaultValue, key, storageType]);

  const handleStorageChange = useCallback(() => {
    setState(readState());
  }, [readState]);

  // It is not enough to simply `setState`, as that only updates the `state` value, but not the value
  // in the storage or dispatch an application wide event that other component can be aware of
  const setValue = useCallback(
    (value) => {
      try {
        const newValue = value instanceof Function ? value(state) : value;
        window[storageType].setItem(key, typeof value === 'string' ? value : JSON.stringify(value));

        // Save newValue to state
        setState(newValue);

        storageType === StorageTypes.Local
          ? window.dispatchEvent(new Event(StorageTypes.Local))
          : window.dispatchEvent(new Event(StorageTypes.Session));
      } catch (error) {
        console.warn(`Error setting ${storageType} key “${key}”:`, error);
      }
    },
    [key, state, storageType]
  );

  useEffect(() => {
    // this only works for other documents, not the current one
    window.addEventListener('storage', handleStorageChange);

    // this is a custom event of 'localStorage' or 'sessionStorage'
    window.addEventListener(
      storageType === StorageTypes.Local ? StorageTypes.Local : StorageTypes.Session,
      handleStorageChange
    );

    return () => {
      window.removeEventListener('storage', handleStorageChange);
      window.removeEventListener(
        storageType === StorageTypes.Local ? StorageTypes.Local : StorageTypes.Session,
        handleStorageChange
      );
    };
  }, [handleStorageChange, storageType]);

  return [state, setValue];
}
