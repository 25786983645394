import React from 'react';

interface Props {
  width?: number;
  height?: number;
}

export const CholesterolIcon = ({ width = 200, height = 165 }: Props): React.ReactElement => (
  <svg viewBox="0 0 200 165" width={width} height={height}>
    <path
      fill="#E5E5E4"
      d="M163.4 119.4l-48.2-83.5c-7-12.2-18.5-12.2-25.5 0l-48.2 83.5c-7 12.2-1.3 22.1 12.8 22.1h96.4c14 0 19.7-9.9 12.7-22.1z"
    />
    <path
      fill="#FFC82B"
      d="M158.5 115.5L110.3 32c-7-12.2-18.5-12.2-25.5 0l-48.2 83.5c-7 12.2-1.3 22.1 12.8 22.1h96.4c14 0 19.8-9.9 12.7-22.1z"
    />
    <path
      fill="#DDAD25"
      d="M126.1 74c-1.1-1.1-2.3-2-3.5-2.8-7.1-4.3-16.5-3.4-22.6 2.8-7.2-7.2-18.9-7.2-26.1 0-7.2 7.2-7.2 18.9 0 26.1l21.8 21.8 4.3 4.3 26.1-26.1c7.2-7.2 7.2-18.9 0-26.1z"
    />
    <path
      fill="#CF4F41"
      d="M124.1 70c-1.1-1.1-2.3-2-3.5-2.8 6.1 7.3 5.7 18.1-1.1 24.9l-25.8 25.8 4.3 4.3 26.1-26.1c7.2-7.2 7.2-18.9 0-26.1z"
    />
    <path
      fill="#EF5B4B"
      d="M120.6 67.2c-7.1-4.3-16.5-3.4-22.6 2.8-7.2-7.2-18.9-7.2-26.1 0-7.2 7.2-7.2 18.9 0 26.1l21.8 21.8 25.8-25.8c6.8-6.8 7.2-17.6 1.1-24.9z"
    />
    <path
      fill="#CF4F41"
      d="M110.2 83.6c.5-.9.8-1.6.6-1.8-.2-.2-.8 0-1.7.5.4.5.8.9 1.1 1.3zm-4.9 1.4c-3.9 3-8.6 7-9.2 7.6-.9.9-1.1 2.9 0 3.9 1.1 1.1 3 .9 3.9 0 .5-.5 4.5-5.1 7.4-9-.5-.9-1.2-1.8-2.1-2.5z"
    />
    <path
      fill="#FFF"
      d="M97.8 77.7c-8.9 0-16.1 7.2-16.1 16.1 0 1.2 1 2.2 2.2 2.2s2.2-1 2.2-2.2c0-6.4 5.2-11.6 11.6-11.6 2.9 0 5.5 1.1 7.5 2.8 1.4-1.1 2.8-2 3.8-2.6-2.8-2.9-6.8-4.7-11.2-4.7zm12.4 5.9c-.6 1-1.6 2.4-2.7 3.9 1.2 1.8 1.9 4 1.9 6.3 0 1.2 1 2.2 2.2 2.2 1.2 0 2.2-1 2.2-2.2 0-3.9-1.3-7.4-3.6-10.2z"
    />
    <path
      fill="#CCC"
      d="M109.1 82.4c-1 .6-2.3 1.5-3.8 2.6.9.7 1.6 1.6 2.2 2.5 1.1-1.5 2.1-2.8 2.7-3.9-.3-.4-.7-.8-1.1-1.2z"
    />
    <path
      fill="#FFF"
      d="M96.6 94.4c-1.1-1.1-.9-3 0-3.9.9-.9 13.5-11.8 14.6-10.7 1.1 1.1-9.7 13.7-10.7 14.6-.9.9-2.8 1.1-3.9 0z"
    />
  </svg>
);

export default CholesterolIcon;
