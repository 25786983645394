import { ReactNode } from 'react';

export type StepperStatus = 'complete' | 'current' | 'incomplete';
export const StepperStatus = {
  Complete: 'complete' as StepperStatus,
  Current: 'current' as StepperStatus,
  Incomplete: 'incomplete' as StepperStatus,
};

export interface IStepper {
  content: ReactNode;
  status: StepperStatus;
  srText?: string;
}
