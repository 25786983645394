import {
  FUNNEL_BASE_URI,
  ProfileWizard,
  RegistrationField,
  ValidationCriteria,
  ViewType,
} from '@health-activity-ui/shared';
import { AxiosRequestConfig } from 'axios';
import { format } from 'date-fns';
import apiClient from './api-client';

export const getAvatarDefault = async (): Promise<{ list: Array<string> }> => {
  const response = await apiClient<{ list: Array<string> }>(`${FUNNEL_BASE_URI}/avatar/default`);
  return response.data;
};

export const getAvatarPath = async (): Promise<{ value: string }> => {
  const response = await apiClient<{ value: string }>(`${FUNNEL_BASE_URI}/avatar/path`);
  return response.data;
};

export const getProfileWizard = async (): Promise<ProfileWizard> => {
  const response = await apiClient<ProfileWizard>(`${FUNNEL_BASE_URI}/registration/profileWizard`);
  return response.data;
};

/**
 * This is is a vestigial end-point from the days that pink-panther handled registration flow that has been
 * taken over by other teams at Rally. We use this end-point to set the displayName and avatar for the user.
 * Although this end-point can take several parameters, we won't include them since they deal with registration
 * flow that we won't use.
 */
export const postCreateProfile = async (avatarFileName: string, displayName: string): Promise<unknown> => {
  const data = { avatar: avatarFileName, displayName };
  const response = await apiClient<unknown>(`${FUNNEL_BASE_URI}/registration/profile`, { method: 'POST', data });
  return response.data;
};

/**
 * Validates a registration field based on the clause. If valid, null returned for value.
 */
export const registrationFieldValidate = async <T>(
  field: RegistrationField<T>,
  validationCriteria: ValidationCriteria,
  viewType: ViewType,
  disableCache = false
): Promise<{ valid: boolean }> => {
  const data = {
    fields: [{ id: field.id, data: field.data, dataType: field.dataType }],
    validationCriteria,
  };

  const params = {
    viewType,
    disableCache: disableCache ? Date.now() : format(new Date(), 'DDDHHmm'), // Cached up to the minute 3652459
  } as AxiosRequestConfig;

  const response = await apiClient<{ valid: boolean }>(`${FUNNEL_BASE_URI}/registration/validate`, {
    method: 'POST',
    data,
    params,
  });

  return response.data;
};
