import React, { ReactElement } from 'react';
import { PropsWithChildren } from '@health-activity-ui/shared';

interface Props extends PropsWithChildren {
  width?: string;
  height?: string;
}

export const TrophyIcon = ({ width = '80px', height = '80px' }: Props): ReactElement => (
  <svg width={width} height={height} aria-hidden="true" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <circle cx="40" cy="40" r="40" fill="#FFF" />
      <path fill="#FD9547" d="M29 61.837h22.548V46.804H29z" />
      <path fill="#FEC62C" d="M51.817 61.697H28.73a2.147 2.147 0 00-2.148 2.147h27.381a2.148 2.148 0 00-2.147-2.147" />
      <path fill="#FD9547" d="M26.583 65.723h27.381v-2.147h-27.38z" />
      <path fill="#FEC62C" d="M32.292 58.029h15.963V50.6H32.292z" />
      <path fill="#EDBA28" d="M38.097 40.556h4.745v-4.063h-4.745z" />
      <path
        fill="#DCAC25"
        d="M57.207 18.331a1.08 1.08 0 00-1.08-1.079H40.47v2.158h13.954c.31 0 .549.274.504.58-.921 6.301-7.027 11.208-14.458 11.365v2.161c9.262-.174 16.737-6.918 16.737-15.185"
      />
      <path
        fill="#EDBA28"
        d="M40.103 31.362c-7.596 0-13.888-4.967-14.825-11.373a.508.508 0 01.504-.58H40.47v-2.156H24.079a1.08 1.08 0 00-1.08 1.077c0 8.375 7.673 15.19 17.104 15.19.123 0 .245-.003.367-.005v-2.16c-.122.001-.244.006-.367.006"
      />
      <path fill="#EDBA28" d="M51.71 15.603c0 11.537-5.033 20.89-11.24 20.89s-11.24-9.353-11.24-20.89h22.48z" />
      <path fill="#DCAC25" d="M43.025 37.226h-5.11a.825.825 0 010-1.649h5.11a.825.825 0 010 1.65" />
      <path
        fill="#DCAC25"
        d="M47.128 15.603c0 9.44-2.895 17.524-7 20.88a5.9 5.9 0 00.342.01c6.207 0 11.24-9.353 11.24-20.89h-4.582z"
      />
      <path
        fill="#DCAC25"
        d="M47.515 17.252h-18.25l-.026-.825-.009-.824 1.942.183h16.343zM48.935 26.153h-18.15l-.248-.825-.195-.713 2.25.072h16.343z"
      />
      <path
        fill="#FEC62C"
        d="M51.465 15.786h-21.99a.825.825 0 110-1.65h21.99a.825.825 0 010 1.65M50.885 24.673h-20.83a.825.825 0 110-1.649h20.83a.825.825 0 110 1.65M46 41.089a1.422 1.422 0 00-1.422-1.403H35.97c-.778 0-1.411.625-1.422 1.403l-.023.727h11.497L46 41.089zM31.547 44.798v1.866H49v-1.866a1.42 1.42 0 00-1.486-1.42h-14.48a1.422 1.422 0 00-1.487 1.42"
      />
      <path
        fill="#EDBA28"
        d="M46.027 41.976l-.005-.16H34.525l-.005.16a1.422 1.422 0 01-1.486 1.401h14.48a1.422 1.422 0 01-1.487-1.4M31.547 46.664H49v-1.866H31.547z"
      />
    </g>
  </svg>
);

export default TrophyIcon;
