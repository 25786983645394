import React from 'react';

interface Props {
  width?: number;
  height?: number;
}

export const DepressionIcon = ({ width = 200, height = 165 }: Props): React.ReactElement => (
  <svg viewBox="0 0 200 165" width={width} height={height}>
    <path
      fill="#B2B2B2"
      d="M157.9 74.9c.1-.6.1-1.3.1-1.9 0-12.8-11.3-23.3-25.3-23.3-1 0-2 .1-3 .2-4.5-15.1-18.5-26-35-26-19.5 0-35.4 15.2-36.5 34.4-1.4-.3-2.9-.5-4.4-.5-11 0-19.8 8.9-19.8 19.8 0 11 8.9 19.8 19.8 19.8h102.8c6.3 0 11.3-5.1 11.3-11.3 0-5.8-4.4-10.6-10-11.2z"
    />
    <path
      fill="#9CDAEE"
      d="M52.4 118.8c0 2.3 1.8 4.1 4.1 4.1s4.1-1.8 4.1-4.1-4.1-11.4-4.1-11.4-4.1 9.1-4.1 11.4zm87.3 19.7c0 2.3 1.8 4.1 4.1 4.1s4.1-1.8 4.1-4.1-4.1-11.4-4.1-11.4-4.1 9.1-4.1 11.4zm-18.6-24.9c0 2.3 1.8 4.1 4.1 4.1s4.1-1.8 4.1-4.1-4.1-11.4-4.1-11.4-4.1 9.2-4.1 11.4zm-29.6 16.8c0 2.3 1.8 4.1 4.1 4.1s4.1-1.8 4.1-4.1-4.1-11.4-4.1-11.4-4.1 9.2-4.1 11.4z"
    />
    <path
      fill="#C6D2D6"
      d="M156.4 73.4c.1-.6.1-1.3.1-1.9 0-12.8-11.3-23.3-25.3-23.3-1 0-2 .1-3 .2-4.5-15.1-18.5-26-35-26-19.5 0-35.4 15.2-36.5 34.4-1.4-.3-2.9-.5-4.4-.5-11 0-19.8 8.9-19.8 19.8 0 11 8.9 19.8 19.8 19.8h102.8c6.3 0 11.3-5.1 11.3-11.3.1-5.7-4.3-10.5-10-11.2z"
    />
    <path
      fill="#E5E5E4"
      d="M103.5 56.2c5.4 0 10.3 2.1 14 5.5-3.8-4.1-9.1-6.6-15.1-6.6-11.4 0-20.6 9.2-20.6 20.6 0 6 2.5 11.3 6.6 15.1-3.4-3.7-5.5-8.6-5.5-14 0-11.3 9.2-20.6 20.6-20.6zm-8.2-31.8c9.7 0 18.5 3.7 25 9.8-6.7-7.3-16.3-11.8-27-11.8-19.4 0-35.3 15-36.7 34l2.1.5c2.2-18.4 17.7-32.5 36.6-32.5z"
    />
    <path
      fill="#E5E5E4"
      d="M53.3 56.5c5.3 0 10.1 2 13.7 5.4-3.7-4-8.9-6.5-14.8-6.5-11.1 0-20.2 9-20.2 20.2 0 5.8 2.5 11.1 6.5 14.8-3.3-3.6-5.4-8.4-5.4-13.7.1-11.2 9.1-20.2 20.2-20.2zm79.8-6.9c6.6 0 12.6 2.5 17 6.7-4.6-4.9-11.1-8-18.4-8-1.2 0-2.2 0-3.4.2l.5 1.5c1.5-.3 2.8-.4 4.3-.4z"
    />
    <path
      fill="#E5E5E4"
      d="M133.2 49.6c6.5 0 12.4 2.5 16.9 6.6-4.5-4.9-11-8-18.2-8-7.9 0-15 3.7-19.5 9.5l1.6 1c4.6-5.6 11.5-9.1 19.2-9.1z"
    />
  </svg>
);

export default DepressionIcon;
