// types for commonProvider
export enum NotificationStatus {
  ERROR = 'error',
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
}

export interface BannerResponse {
  id: number | string;
  type?: NotificationStatus;
  message?: string;
  autoDismiss?: boolean;
}
