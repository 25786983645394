import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import { PropsWithChildren } from '@health-activity-ui/shared';

interface Props extends PropsWithChildren {
  onClose?(...args: unknown[]): unknown;
  isPortal?: boolean;
}

/**
 * @component FocusTrap
 *
 * @description A React component that traps focus within a DOM node. When a user hits
 * Tab or Tab + shift, they won't be able to escape from the focusable elements within the
 * component wrapped by the FocusTrap component. It will cycle through the focus trap's
 * tabbable elements but will not leave the focus trap. FocusTrap helps adhere to accessibility
 * standards for modals or popups where the user will not be able to tab to elements in the background.
 * */
export const FocusTrap = ({ children, onClose, isPortal = true }: Props) => {
  const focusTrapRef = useRef<HTMLDivElement>(null);

  const handleTabKey = (e): unknown => {
    const focusableTrapElements = focusTrapRef.current.querySelectorAll(
      'a[href], [href], button, textarea, input, select, [tabindex="0"]'
    );
    const firstElement = focusableTrapElements[0] as HTMLElement;
    const lastElement = focusableTrapElements[focusableTrapElements.length - 1] as HTMLElement;

    const isFocusableTrapElements = (): boolean =>
      Array.from(focusableTrapElements).reduce((flag, el) => el === document.activeElement || flag, false);

    //  If on the last element and user hits tab then focus on first element and start cycle over
    if (!e.shiftKey && document.activeElement === lastElement) {
      firstElement.focus();
      return e.preventDefault();
    }

    // If user tabs and focus element is in focus trap then allow to tab to next element
    if (!e.shiftKey && isFocusableTrapElements()) {
      return;
    }

    // Focus first element on intial load of focus trap
    if (!e.shiftKey && document.activeElement !== firstElement) {
      firstElement.focus();
      return e.preventDefault();
    }

    // If user shift + tabs while on first element then focus on last element
    if (e.shiftKey && document.activeElement === firstElement) {
      lastElement.focus();
      return e.preventDefault();
    }
  };

  React.useEffect(() => {
    // Key code 27 = escape
    // Key code 9 = tab
    // Key code 39 = RightArrow
    // Key code 17 = Control
    // Key code 9 = Alt
    // Key code 9 = Caps
    const keyListenersMap = new Map([
      [27, onClose],
      [9, handleTabKey],
      [39, handleTabKey],
      [18, handleTabKey],
      [17, handleTabKey],
      [20, handleTabKey],
    ]);

    function keyListener(e): unknown {
      const listener = keyListenersMap.get(e.keyCode);
      return listener?.(e);
    }
    document.addEventListener('keydown', keyListener);

    return () => {
      document.removeEventListener('keydown', keyListener);
    };
  }, [onClose]);

  /**
   * React Portal is an API in ReactDOM.
   * ReactDOM.createPortal() takes two parameters:
   * 1. a React component and
   * 2. a DOM node we want to attach the React component to.
   * Here we are creating a new <aside/> element with its content at the end of the
   * document.body (rather than wherever it is consumed) when a user opens the modal.
   */
  return isPortal ? (
    ReactDOM.createPortal(
      <div ref={focusTrapRef} id="focus-trap">
        {children}
      </div>,
      document.body
    )
  ) : (
    <div id="focus-trap" ref={focusTrapRef}>
      {children}
    </div>
  );
};

export default FocusTrap;
