export const TryANewGroupActivityIcon = () => (
  <svg width="192" height="192" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
    <path
      opacity=".1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M82 86c0 1.105-17.013 2-38 2s-38-.895-38-2 17.013-2 38-2 38 .895 38 2Z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.712 46.148c0 2.742 4.665 2.742 4.655 0V34.181h-4.655v11.967Z"
      fill="#8A6746"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M44.054 42.949c1.605 0 2.952-1.14 3.355-2.69h-6.71c.403 1.55 1.75 2.69 3.355 2.69Z"
      fill="#9D7352"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M48.717 53.669h-9.653l.001 10.938h9.657l-.005-10.938Z"
      fill="#4D4E4E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m48.408 83.387.312-8.057h-4.004l.312 8.057c0 2.78 3.38 2.794 3.38 0Zm-5.634-.022.311-8.035h-4.02l.313 8.035c0 2.816 3.396 2.816 3.396 0Z"
      fill="#9D7352"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M43.246 84.882c0-1.257-.971-2.278-2.171-2.278-1.199 0-2.17 1.02-2.17 2.278v.824h4.34v-.824Zm5.639 0c0-1.257-.972-2.278-2.17-2.278-1.201 0-2.173 1.02-2.173 2.278v.824h4.343v-.824Z"
      fill="#676767"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M44.054 39.298c2.403 0 4.35-2.043 4.35-4.564 0-2.52-1.947-4.565-4.35-4.565-2.403 0-4.35 2.045-4.35 4.565 0 2.52 1.947 4.564 4.35 4.564Z"
      fill="#9D7352"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m49.55 60.217-1.04-14-.103-1.602.47-1.532c-.597-1.464.231-3.262-1.17-3.207h-.296c-.403 1.55-1.75 2.69-3.355 2.69-1.604 0-2.952-1.14-3.355-2.69h-.338c-.295 0-1.037-.82-1.3-.774.662.656.555 7.115.539 7.115h.016L38.616 60.26s-1.694.122 5.263.122c6.958 0 5.672-.164 5.672-.164Z"
      fill="#DE6627"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.528 40.33c-2.071.357-.73-.032-1.227 1.273l-1.274 2.404H38.2c.773 0 1.4.657 1.4 1.468V46.6c.757-.72.59-5.612-.072-6.268Z"
      fill="#9D7352"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M48.851 40.481s-.603-.168-2.48-.168c-.536 0-1.489-.609-.45-2.026.834-1.136 1.429-3.166 1.206-4.622-2.79.421-3.883.354-6.557 0-.189 1.573.176 3.02 1.174 4.329.188.246.363.531.46.804.244.692.08 1.585-1.011 1.585-1.739 0-2.19.082-2.19.082s-.067-5.884.349-8.584c.3-1.952 1.49-3.455 3.74-3.805 2.125-.332 3.905.528 4.782 2.41.4.857.752 1.805.798 2.726.113 2.277.179 7.27.179 7.27Z"
      fill="#333"
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M43.706 64.064h-4.642v1.507h4.56l.082-1.507Z" fill="#4D4E4E" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m43.082 75.616.543-10.33h-4.561v10.257l4.018.073Zm5.638-11.552h-4.628l.081 1.507h4.547v-1.507Z"
      fill="#4D4E4E"
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M48.72 75.543V65.287h-4.547l.545 10.33 4.002-.074Z" fill="#4D4E4E" />
    <path fillRule="evenodd" clipRule="evenodd" d="M33.934 33.489h3.427v-6.394h-3.427v6.394Z" fill="#9D7352" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M37.351 27.095c0-.987-.763-1.787-1.704-1.787-.94 0-1.703.8-1.703 1.787 0 .987.762 1.788 1.703 1.788.941 0 1.704-.8 1.704-1.788Z"
      fill="#D6A66B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.135 33.93c.434 0 .847.152 1.229.428v-1.115c.002-2.523-3.437-2.523-3.43 0l.002 2.19c.596-.939 1.362-1.504 2.2-1.504Z"
      fill="#9D7352"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.568 40.378h-.836c-.215-.024-1.429-.23-1.356-1.893-.012-.176-.013-2.641-.012-4.127-.382-.276-.796-.429-1.229-.429-.837 0-1.603.565-2.199 1.503l.004 3.103.035 1.724c.088 2.889 2.33 3.748 3.052 3.748H38.2c1.033-1.117 1.368-1.198 1.368-3.604v-.025Z"
      fill="#9D7352"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.917 85.706h4.329v-.538h-4.33v.538Zm5.633 0h4.328v-.538h-4.329v.538Z"
      fill="#E3E3E2"
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M13.155 56.86h12.253V36.172H13.155V56.86Z" fill="#333" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m13.498 48.196.001-.025a.587.587 0 0 0-.539-.627l-1.526-.082c-.313-.017-.582.238-.598.566v.026a.588.588 0 0 0 .539.627l1.526.082c.312.016.581-.239.597-.567Z"
      fill="#E0B68B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.299 48.897c-.906-.768-2.234-.62-2.965.33-.732.95-.59 2.343.315 3.11.905.767 2.233.62 2.965-.33.731-.95.59-2.343-.316-3.11Z"
      fill="#E0B68B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m7.44 43.916 3.747 3.175-2.665 3.46-3.746-3.174 2.665-3.461Z"
      fill="#333"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.433 43.926c-.906-.768-2.233-.62-2.965.33-.731.95-.59 2.343.316 3.11.905.768 2.232.62 2.964-.33.732-.95.59-2.343-.315-3.11Z"
      fill="#333"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m8.522 50.551 2.12 1.796 2.664-3.46-2.12-1.797-2.664 3.461Z"
      fill="#E0B68B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.17 38.194c1.798-2.336 5.062-2.7 7.29-.813l-3.258 4.23-4.033-3.417Z"
      fill="#333"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.122 36.26c2.817.532 4.69 3.361 4.181 6.317l-5.101-.965.92-5.352Zm-6.689 7.666c-.906-.768-2.233-.62-2.965.33-.731.95-.59 2.343.316 3.11.905.768 2.232.62 2.964-.33.732-.95.59-2.343-.315-3.11Z"
      fill="#333"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.468 40.99 7.73 47.143l-3.3-2.796 4.74-6.153 3.298 2.796Z"
      fill="#333"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.459 40.982c.731-.95.59-2.343-.316-3.11-.905-.768-2.233-.62-2.964.33-.732.95-.591 2.343.315 3.11.905.768 2.233.62 2.965-.33Z"
      fill="#333"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m32.895 25.736.025.002c.313.015.557.296.542.625l-.072 1.6a.582.582 0 0 1-.595.569l-.025-.001a.59.59 0 0 1-.542-.625l.072-1.601a.582.582 0 0 1 .595-.569Z"
      fill="#E0B68B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M37.013 29.03a2.04 2.04 0 0 1-2.982.032 2.29 2.29 0 0 1-.03-3.128 2.04 2.04 0 0 1 2.981-.032 2.29 2.29 0 0 1 .03 3.128Z"
      fill="#E0B68B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m31.745 34.69 3.37-3.61-3.03-3.113-3.37 3.609 3.03 3.113Z"
      fill="#333"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.202 34.183a2.039 2.039 0 0 1-2.98.032 2.29 2.29 0 0 1-.032-3.127 2.04 2.04 0 0 1 2.982-.033 2.29 2.29 0 0 1 .03 3.128Z"
      fill="#333"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m32.085 27.967 1.907-2.042 3.03 3.113-1.907 2.042-3.03-3.113Z"
      fill="#E0B68B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.328 36.173c-2.863.032-5.16 2.491-5.13 5.494l5.184-.056-.054-5.438Zm6.874-1.99a2.039 2.039 0 0 1-2.98.032 2.29 2.29 0 0 1-.032-3.127 2.04 2.04 0 0 1 2.982-.033 2.29 2.29 0 0 1 .03 3.128Z"
      fill="#333"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m26.887 39.872 5.286-5.642-3.03-3.113-5.276 5.65 3.02 3.105Z"
      fill="#333"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.887 39.872a2.039 2.039 0 0 1-2.98.032 2.29 2.29 0 0 1-.032-3.127 2.04 2.04 0 0 1 2.982-.033 2.29 2.29 0 0 1 .03 3.128Z"
      fill="#333"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.408 73.3c0-1.638-1.265-2.966-2.827-2.966s-2.828 1.328-2.828 2.966c0 1.639 1.266 2.967 2.828 2.967 1.562 0 2.827-1.328 2.827-2.967Z"
      fill="#4D4E4E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.753 85.413h5.655V73.3h-5.655v12.113Zm0 .587h5.655v-.587h-5.655V86Z"
      fill="#4D4E4E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.408 85.413c0-1.639-1.265-2.966-2.827-2.966s-2.828 1.327-2.828 2.966h5.655Z"
      fill="#333"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.408 61.187c0-1.638-1.265-2.966-2.827-2.966s-2.828 1.328-2.828 2.966c0 1.639 1.266 2.966 2.828 2.966 1.562 0 2.827-1.327 2.827-2.966Zm0 12.113c0-1.638-1.265-2.966-2.827-2.966s-2.828 1.328-2.828 2.966c0 1.639 1.266 2.967 2.828 2.967 1.562 0 2.827-1.328 2.827-2.967Z"
      fill="#4D4E4E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.753 73.3h5.655V61.188h-5.655V73.3Zm-.943 0c0-1.638-1.265-2.966-2.827-2.966s-2.828 1.328-2.828 2.966c0 1.639 1.266 2.967 2.828 2.967 1.562 0 2.828-1.328 2.828-2.967Z"
      fill="#4D4E4E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.155 85.413h5.656V73.3h-5.656v12.113Zm0 .587h5.656v-.587h-5.656V86Z"
      fill="#4D4E4E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.81 85.413c0-1.639-1.265-2.966-2.827-2.966s-2.828 1.327-2.828 2.966h5.656Z"
      fill="#333"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.81 61.187c0-1.638-1.265-2.966-2.827-2.966s-2.828 1.328-2.828 2.966c0 1.639 1.266 2.966 2.828 2.966 1.562 0 2.828-1.327 2.828-2.966Zm0 12.113c0-1.638-1.265-2.966-2.827-2.966s-2.828 1.328-2.828 2.966c0 1.639 1.266 2.967 2.828 2.967 1.562 0 2.828-1.328 2.828-2.967Z"
      fill="#4D4E4E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.155 73.3h5.656V61.188h-5.656V73.3Zm0-12.113h12.253V56.86H13.155v4.327Z"
      fill="#4D4E4E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m22.722 36.171-3.462 3.114-3.463-3.113h.99l2.464-.523 2.482.522h.99Z"
      fill="#D6AD88"
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M16.786 36.948h4.947V34.09h-4.947v2.858Z" fill="#D6AD88" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.26 24.601c2.731 0 4.946 2.325 4.946 5.192s-2.215 5.19-4.947 5.19c-2.731 0-4.945-2.323-4.945-5.19 0-2.867 2.214-5.192 4.945-5.192Z"
      fill="#E0B68B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.699 32.367c-.194-1.138-.38-2.249-.572-3.359-.103-.597-.493-.874-1.023-.806-.792.103-1.591.236-2.357.464-1.252.374-2.458.555-3.644-.217-.488-.317-.663-.085-.72.482-.111 1.116-.302 2.222-.488 3.528-.733-2.006-1.305-3.836-.948-5.818.171-.954.771-1.604 1.541-2.104 1.318-.854 2.775-1.06 4.26-.859 1.089.146 2.212.355 3.209.805 1.033.465 1.516 1.513 1.584 2.72.092 1.613-.04 3.193-.61 4.711-.048.128-.092.257-.143.383-.011.025-.048.039-.09.07Z"
      fill="#683D1D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M48.593 40.33c2.07.357.73-.032 1.227 1.273l1.274 2.404h-1.173c-.773 0-1.4.657-1.4 1.468V46.6c-.757-.72-.59-5.612.072-6.268Zm2.165-5.868h3.427v-6.394h-3.427v6.394Z"
      fill="#9D7352"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M50.768 28.068c0-.987.763-1.787 1.704-1.787.94 0 1.703.8 1.703 1.787 0 .987-.762 1.788-1.703 1.788-.941 0-1.704-.8-1.704-1.788Zm1.218 5.862c-.434 0-.847.152-1.23.428v-1.115c0-2.523 3.438-2.523 3.431 0l-.002 2.19c-.596-.939-1.363-1.504-2.2-1.504Z"
      fill="#9D7352"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M48.553 40.378h.836c.214-.024 1.428-.23 1.356-1.893.012-.176.013-2.641.012-4.127.381-.276.796-.429 1.228-.429.837 0 1.604.565 2.2 1.503l-.004 3.103-.035 1.724c-.088 2.889-2.33 3.748-3.052 3.748h-1.173c-1.033-1.117-1.369-1.198-1.369-3.604v-.025Z"
      fill="#9D7352"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M68.526 82.704c0-1.27-1.131-2.3-2.526-2.3-1.395 0-2.526 1.03-2.526 2.3 0 1 .235 2.555 1.27 3.171.28.166 2.232.166 2.511 0 1.036-.615 1.27-2.171 1.27-3.171Zm6.5-.001c0-1.27-1.13-2.3-2.526-2.3-1.395 0-2.526 1.03-2.526 2.3 0 1.001.173 2.557 1.232 3.173.285.165 2.302.165 2.587 0 1.06-.614 1.233-2.172 1.233-3.173Z"
      fill="#333"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M64.098 82.191h3.825l.915-19.28h-5.07l.33 19.28Zm6.495 0h3.825l.34-19.28h-5.051l.886 19.28Z"
      fill="#81472E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M67.923 82.191c0-.401-.856-.727-1.913-.727-1.056 0-1.912.326-1.912.727 0 .403 0 2.672 1.912 2.672 1.913 0 1.913-2.27 1.913-2.672Zm6.495 0c0-.401-.856-.727-1.912-.727-1.057 0-1.913.326-1.913.727 0 .403 0 2.672 1.913 2.672 1.912 0 1.912-2.27 1.912-2.672Zm4.604-26.395a.607.607 0 0 1-.229-.8l.689-1.342a.55.55 0 0 1 .761-.24.607.607 0 0 1 .23.799l-.69 1.343a.55.55 0 0 1-.761.24Z"
      fill="#81472E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M83.773 55.435c-.323-1.13-1.46-1.772-2.538-1.432-1.078.34-1.69 1.532-1.365 2.663.323 1.13 1.46 1.772 2.538 1.433 1.078-.34 1.69-1.533 1.365-2.664Z"
      fill="#81472E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m83.027 52.785.758 2.647-3.927 1.237-.758-2.646 3.927-1.238Z"
      fill="#81472E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m79.1 54.023 3.927-1.238-2.72-9.365-4.012.77 2.805 9.833Z"
      fill="#333"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M69.858 38.448c1.249.013 2.281-1.049 2.294-2.36l.023-2.486c.013-1.31-.999-2.394-2.248-2.407-1.25-.013-2.282 1.048-2.295 2.36l-.023 2.486c-.013 1.31 1 2.394 2.249 2.407Z"
      fill="#6B3C24"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M64.657 31.732c.094.493.551.812 1.02.713l-.34-1.784c-.47.1-.775.578-.68 1.071Zm10.463-.112c-.094.494-.551.812-1.02.713l.34-1.783c.47.099.774.578.68 1.07Z"
      fill="#81472E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M69.85 24.458c-2.744 0-4.969 2.328-4.969 5.199 0 2.87 2.225 5.197 4.97 5.197 2.745 0 4.971-2.327 4.971-5.197 0-2.871-2.226-5.199-4.971-5.199Z"
      fill="#81472E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M69.865 26.276c2.362 0 4.35 1.672 4.952 3.948.121-.46.188-.943.188-1.443 0-2.978-2.301-5.391-5.14-5.391-2.838 0-5.139 2.413-5.139 5.391 0 .5.067.984.188 1.443.602-2.276 2.59-3.948 4.951-3.948Z"
      fill="#191818"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M72.357 26.085c-.803 0-1.453-.683-1.453-1.525 0-.842.65-1.524 1.453-1.524s1.454.682 1.454 1.524-.651 1.525-1.454 1.525Z"
      fill="#191818"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M72.618 23.806a1.406 1.406 0 0 1-2.055 0 1.578 1.578 0 0 1 0-2.156 1.406 1.406 0 0 1 2.055 0c.568.595.568 1.56 0 2.156Z"
      fill="#191818"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M71.267 22c0 .843-.65 1.526-1.453 1.526S68.36 22.843 68.36 22c0-.842.65-1.525 1.454-1.525.802 0 1.453.683 1.453 1.525Z"
      fill="#191818"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M69.094 21.727a1.579 1.579 0 0 1 0 2.156 1.406 1.406 0 0 1-2.055 0 1.578 1.578 0 0 1 0-2.156 1.406 1.406 0 0 1 2.055 0Z"
      fill="#191818"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M67.374 23.144c.803 0 1.453.683 1.453 1.525 0 .843-.65 1.525-1.453 1.525s-1.454-.682-1.454-1.525c0-.842.65-1.525 1.454-1.525Z"
      fill="#191818"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M74.874 43.105h1.112l.31 1.085 4.011-.77-.864-2.897c-.523-1.647-1.925-4.635-5.381-4.635l-1.869-.03-2.273 2.619-2.257-2.544v-.045h-1.96c-3.487 0-1.876 7.217-1.876 7.217h1.028l-.1 1.085.16 6.97 9.977.27.087-7.24-.105-1.085Z"
      fill="#333"
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M74.203 38.598h-8.359l4.237 18.071 4.121-18.071Z" fill="#E3E3E2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M65.67 35.935v-.006h1.506v.025L70 51.299l2.576-15.44h.136l1.4.067v.01c0 2.745.76 3.913 2.023 5.872l-.59 9.623 1.188 4.41-6.233.216-.478-.007-.494.03-6.473-.239 1.184-4.41-.6-9.61c1.267-1.962 2.03-3.133 2.03-5.886Z"
      fill="#333"
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M75.825 69.848H63.989l-.35-15.007h12.535l-.35 15.007Z" fill="#333" />
    <path fillRule="evenodd" clipRule="evenodd" d="M72.136 38.728h-4.523l-.495-2.87h5.512l-.494 2.87Z" fill="#6B3C24" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M55.873 25.663h-.024c-.314 0-.57.27-.57.598v1.603c0 .328.256.597.57.597h.024c.314 0 .57-.27.57-.597V26.26c0-.329-.256-.598-.57-.598Z"
      fill="#81472E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M52.187 29.82c.9.775 2.228.637 2.967-.307.738-.944.607-2.338-.293-3.113-.9-.774-2.229-.637-2.968.308-.738.944-.607 2.338.294 3.112Z"
      fill="#81472E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m58.008 34.847-3.723-3.204 2.69-3.44 3.723 3.204-2.69 3.44Z"
      fill="#333"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M58.016 34.837c.9.775 2.229.637 2.967-.307.739-.945.608-2.338-.292-3.113-.9-.774-2.23-.637-2.968.308-.738.944-.607 2.338.293 3.112Z"
      fill="#333"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m56.975 28.203-2.106-1.813-2.69 3.44 2.106 1.813 2.69-3.44Z"
      fill="#81472E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M58.016 34.837c.9.775 2.229.637 2.967-.307.739-.945.608-2.338-.292-3.113-.9-.774-2.23-.637-2.968.308-.738.944-.607 2.338.293 3.112Z"
      fill="#333"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m63.872 39.82-5.863-4.973 2.69-3.44 5.863 4.974-2.69 3.44Z"
      fill="#333"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M63.88 39.81c.9.775 2.229.638 2.967-.307.738-.944.607-2.337-.293-3.112-.9-.775-2.229-.637-2.967.307s-.607 2.338.293 3.113Z"
      fill="#333"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M50.916 29.648a.546.546 0 0 0-.003.746.486.486 0 0 0 .71.003l.789-.812a.546.546 0 0 0 .003-.745.486.486 0 0 0-.71-.004l-.788.812Zm-13.723-.218a.545.545 0 0 1 .004.746.486.486 0 0 1-.711.003l-.788-.812a.545.545 0 0 1-.003-.745.486.486 0 0 1 .71-.004l.788.812Z"
      fill="#9D7352"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m31.634 34.806-5.208 5.546a3.808 3.808 0 0 0-1.018 2.605v3.681l-1.541-3.802v-4.25l7.767-3.78Z"
      fill="#333"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M37.617 3.684h-12.29c-1.745.031-3.616 1.223-3.616 3.798v6.262c.021 1.722 1.049 3.542 3.211 3.757v3.74s5.086-3.552 5.305-3.699h7.39c1.75-.024 3.62-1.223 3.62-3.798V7.478c-.025-1.834-1.168-3.794-3.62-3.794Z"
      fill="#0098C3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.27 10.621c0-1.017-.786-1.842-1.756-1.842s-1.755.825-1.755 1.842c0 1.017.785 1.842 1.755 1.842.97 0 1.756-.825 1.756-1.842Zm-4.757 0c0-1.017-.786-1.842-1.755-1.842-.97 0-1.756.825-1.756 1.842 0 1.017.786 1.842 1.756 1.842s1.755-.825 1.755-1.842Zm9.513 0c0-1.017-.786-1.842-1.756-1.842s-1.755.825-1.755 1.842c0 1.017.786 1.842 1.755 1.842.97 0 1.756-.825 1.756-1.842Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M52.367 7.75h-12.29c-1.746.031-3.617 1.223-3.617 3.798v6.262c.021 1.722 1.05 3.542 3.212 3.757h1.25l4.055 3.666v-3.625h7.39c1.749-.024 3.62-1.223 3.62-3.798v-6.266c-.025-1.834-1.168-3.794-3.62-3.794Z"
      fill="#F5934B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M48.696 14.305c0-1.018-.786-1.842-1.756-1.842s-1.755.824-1.755 1.842c0 1.017.786 1.841 1.755 1.841.97 0 1.756-.824 1.756-1.841Zm-4.756 0c0-1.018-.786-1.842-1.756-1.842s-1.756.824-1.756 1.842c0 1.017.786 1.841 1.756 1.841s1.756-.824 1.756-1.841Zm9.512 0c0-1.018-.786-1.842-1.755-1.842-.97 0-1.756.824-1.756 1.842 0 1.017.786 1.841 1.755 1.841.97 0 1.756-.824 1.756-1.841Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M49.86 3.794v6.266c0 2.576 1.871 3.774 3.62 3.798h7.39c.22.148 5.305 3.7 5.305 3.7v-3.74c2.163-.216 3.191-2.036 3.212-3.758V3.798c0-2.575-1.87-3.766-3.616-3.798H53.48c-2.452 0-3.596 1.96-3.62 3.794Z"
      fill="#43B475"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M57.828 6.937c0-1.017.786-1.841 1.756-1.841s1.755.824 1.755 1.841c0 1.018-.786 1.842-1.755 1.842-.97 0-1.756-.824-1.756-1.842Zm4.756 0c0-1.017.786-1.841 1.756-1.841s1.755.824 1.755 1.841c0 1.018-.785 1.842-1.755 1.842-.97 0-1.756-.824-1.756-1.842Zm-9.512 0c0-1.017.785-1.841 1.755-1.841.97 0 1.756.824 1.756 1.841 0 1.018-.786 1.842-1.756 1.842s-1.755-.824-1.755-1.842Z"
      fill="#fff"
    />
  </svg>
);

export default TryANewGroupActivityIcon;
