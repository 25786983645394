import { ChallengeTeamType } from './challenge-team.models';
import { AcuityState } from './readiness-2-change.interface';

// Communities
export interface IShowCommunitiesUIRes {
  enableCommunityUI: boolean;
  hasPublic: boolean;
  hasPrivate: boolean;
}

export interface ICoinDetails {
  coins: number;
  earned: number;
  owner: string;
  spent: number;
}

export interface IFooterScaffoldLinks {
  event: {
    category: string;
    label: string;
  };
  category: string;
  label: string;
  name: string;
  url: string;
}

export interface IFooterScaffold {
  branding: {
    client: string;
    partner: string;
    logo: {
      alt: string;
      path: string;
    };
  };
  copyright: string;
  links: IFooterScaffoldLinks[];
}

/**
 * Wraps a T in an object that has a property "selectable". This is useful when we to make some object back a multi-select
 * checkbox or other input.
 */
export interface Selectable<T> {
  data: T;
  selected: boolean;
  displayName?: string;
  userFactAcuityState?: AcuityState;
}

export interface SelectableUi<T> extends Selectable<T> {
  onClick: (event) => void;
}

export type ActivityType =
  | 'actsOfKindness'
  | 'cycling'
  | 'elliptical'
  | 'healthySnacking'
  | 'hydration'
  | 'meditation'
  | 'runningWalking'
  | 'runningWalkingSteps'
  | 'soloMission'
  | 'swimming'
  | 'yoga';
export const ActivityType = {
  ActsOfKindness: 'actsOfKindness' as ActivityType,
  Cycling: 'cycling' as ActivityType,
  Elliptical: 'elliptical' as ActivityType,
  HealthySnacking: 'healthySnacking' as ActivityType,
  Hydration: 'hydration' as ActivityType,
  Meditation: 'meditation' as ActivityType,
  RunningWalking: 'runningWalking' as ActivityType,
  RunningWalkingSteps: 'runningWalkingSteps' as ActivityType,
  SoloMission: 'soloMission' as ActivityType,
  Swimming: 'swimming' as ActivityType,
  Yoga: 'yoga' as ActivityType,
};

export type ChallengeCategory = 'State of Mind' | 'Nutrition' | 'Exercise' | 'Sleep';
export const ChallengeCategory = {
  StateOfMind: 'State of Mind' as ChallengeCategory,
  Nutrition: 'Nutrition' as ChallengeCategory,
  Exercise: 'Exercise' as ChallengeCategory,
  Sleep: 'Sleep' as ChallengeCategory,
};

export interface ChallengeEventTrackingData {
  challengeType: ChallengeTeamType;
  category: ChallengeCategory;
  activityType: ActivityType;
  isCityWalk: boolean;
}

export type FlexDirection = 'row' | 'column';
export const FlexDirectionTypes = {
  Row: 'row' as FlexDirection,
  Column: 'column' as FlexDirection,
};
