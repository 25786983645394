import { RdsIcon } from '@rally/energon-react/dist/es6';
import { css } from '@emotion/react';
import classnames from 'classnames';

export interface SearchFilterProps {
  label: string;
  handleFilter: (filterValue: string) => void;
  name?: string;
  placeholder?: string;
  labelIsSrOnly?: boolean;
}

const cssStyles = `
  width: 100%;
  .rds-input {
    max-width: 100%;
  }
  .rds-icon.button-search {
    margin-left: calc(var(--rds-spacing-size-32) * -1);
  }

  // remove the default browser handling for 'search' input types
  [type="search"]::-webkit-search-cancel-button,
  [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
    appearance: none;
  }
`;

export const SearchFilter = ({
  label,
  handleFilter,
  name = 'input-search-filter',
  placeholder = '',
  labelIsSrOnly = true,
}: SearchFilterProps) => {
  const onChange = (e) => {
    e.preventDefault();
    handleFilter(e.target.value);
  };

  return (
    <div
      css={css`
        ${cssStyles}
      `}
      date-testid={`${name}-container`}
    >
      <label htmlFor={name} className={classnames({ 'is-sr-only': labelIsSrOnly })}>
        {label}
      </label>
      <input
        id={name}
        name={name}
        type="search"
        placeholder={placeholder}
        className="rds-input has-rds-pv-8 has-rds-pl-16 has-rds-pr-40"
        onChange={onChange}
      />
      <RdsIcon size="24" name="button-search" className="is-vertical-align-middle" />
    </div>
  );
};
