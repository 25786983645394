import { ISyllabusOverview, PROGRAM_BASE_URI } from '@health-activity-ui/shared';
import apiClient from './api-client';

/**
 * @method getProgramOverview
 *
 * @description Get card for user's state in Program.
 * Gets the program card for dashboard. Includes the user's program state.
 *
 * @param {string} syllabusId
 *
 * @return {ISyllabusOverview}
 * */
export const getProgramOverview = async (syllabusId: string): Promise<ISyllabusOverview> => {
  const params = { withSurvey: true };
  const response = await apiClient<ISyllabusOverview>(`${PROGRAM_BASE_URI}/${syllabusId}/overview`, {
    params,
  });
  return response.data;
};

/**
 * @description Get syllabus goal summary.
 * Gets the program card for the FTUE SUMMARY.
 * */
export const getProgramSummary = async (syllabusId: string, disableCache = false): Promise<ISyllabusOverview> => {
  const params = {};
  if (disableCache) {
    params['disableCache'] = Date.now();
  }
  const response = await apiClient<ISyllabusOverview>(`${PROGRAM_BASE_URI}/${syllabusId}/summary`, { params });

  return response.data;
};
