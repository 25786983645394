import React from 'react';

export const ChatBox = ({ baseUrl }: { baseUrl: string }) => {
  return (
    <>
      <chatter-box base-url={baseUrl} z-index={1234} />
    </>
  );
};

export default ChatBox;
