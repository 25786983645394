import React from 'react';

interface Props {
  width?: number;
  height?: number;
}

export const PrediabetesIcon = ({ width = 200, height = 165 }: Props): React.ReactElement => (
  <svg viewBox="0 0 200 165" width={width} height={height}>
    <path
      fill="#E5E5E4"
      d="M163.4 119.4l-48.2-83.5c-7-12.2-18.5-12.2-25.5 0l-48.2 83.5c-7 12.2-1.3 22.1 12.8 22.1h96.4c14 0 19.7-9.9 12.7-22.1z"
    />
    <path
      fill="#FFC82B"
      d="M158.5 115.5L110.3 32c-7-12.2-18.5-12.2-25.5 0l-48.2 83.5c-7 12.2-1.3 22.1 12.8 22.1h96.4c14 0 19.8-9.9 12.7-22.1z"
    />
    <path
      fill="#DDAD25"
      d="M125.5 91.1l-23.3-46.6-23.3 46.6c-1.8 3.5-2.8 7.5-2.8 11.7 0 14.4 11.7 26 26 26 14.4 0 26-11.7 26-26 .2-4.3-.8-8.2-2.6-11.7z"
    />
    <path
      fill="#DF5546"
      d="M121.6 89.1L98.4 42.5 75 89.1c-1.8 3.5-2.8 7.5-2.8 11.7 0 14.4 11.7 26 26 26 14.4 0 26-11.7 26-26 .2-4.2-.8-8.2-2.6-11.7z"
    />
    <path
      fill="#CF4F41"
      d="M121.9 89.4L98.6 42.6l-.1.2 14.7 38.7c1.4 3.7 2 7.7 1.6 11.9-1.4 14.3-14.2 24.8-28.6 23.4-3.9-.4-7.5-1.6-10.6-3.5 4.4 8.2 13.1 13.8 23 13.8 14.4 0 26.1-11.7 26.1-26.1 0-4.2-1.1-8.1-2.8-11.6z"
    />
    <path
      fill="#EF5B4B"
      d="M114.7 93.4c.4-4.2-.2-8.2-1.6-11.9L98.5 42.8 75.2 89.4c-1.8 3.5-2.8 7.5-2.8 11.7 0 4.4 1.1 8.6 3.1 12.3 3.2 1.9 6.8 3.1 10.6 3.5 14.4 1.3 27.2-9.1 28.6-23.5z"
    />
    <path fill="#FFF" d="M86.8 102.5H110v7.9H86.8zm23.1-12.4h-7.6v7.6h-7.9v-7.6h-7.6v-7.9h7.6v-7.7h7.9v7.7h7.6z" />
  </svg>
);

export default PrediabetesIcon;
