import React, {
  createContext,
  Dispatch,
  FunctionComponent,
  ReactElement,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { ActionEvent, ProviderContext, Reducer } from './types';

interface WithProviderProps<T, P = void> {
  reducer?: Reducer<T, P>;
  displayName: string;
  initialState: T;
  BaseComponent?: ({ children, context }: { children: ReactNode; context: ProviderContext<T, P> }) => ReactElement;
}

/**
 * @function withProvider
 *
 * @description Higher order component. Help provide consistency and speed up development when
 * creating Data Provider components via React Context.
 *
 * @param {WithProviderProps} props
 * @param {(state: T, action: ActionEvent<string, T>): T} [props.reducer] You have an option to use `withProvider`
 * with `useReducer` or `useState`. If no `reducer` props is passed in, then `withProvider will use
 * `useState` else `useReducer`.
 * @param {string} props.displayName Required prop. Ensure displayName is unique to other
 * Provider `displayName`.
 * Helps with debugging in React DevTools.
 * @param {T} props.initialState
 * @param {({ children: any; context: ProviderContext<T> }) => ReactElement} [props.BaseComponent] BaseComponent is responsible for fetching the initial data for
 * the Provider's state. It can also handle the business logic of normalizing and data massaging
 * the response data, so that the child components consistently receive the same model of state.
 *
 * @return {[FunctionComponent, (() => ProviderContext<T>)]} Returns
 * 1. The Data Provider component to be used in the root level of your app.
 * 2. A custom consumer hook that provides the context state from the Context Provider.
 * When called it will return the context state plus the reducer OR useState hook.
 */
const withProvider = <T, P = void>({
  reducer,
  displayName,
  initialState,
  BaseComponent = ({ children }) => <>{children}</>,
}: WithProviderProps<T, P>): [FunctionComponent, () => ProviderContext<T, P>] => {
  /**
   * IMPORTANT to pass undefined to createContext() and DO NOT pass
   * default values.
   */
  const ProviderContext = createContext<ProviderContext<T, P> | undefined>(undefined);
  /***
   * The Custom Consumer Hook: useState
   * The custom hook uses useContext to get the provided context value, and
   * it will return the context state when we call it.
   * By exposing the custom hook, the consumer components can subscribe to the state
   * managed in the provider data component. Also, we have added error handling if the
   * hook is called in a component that is not a descendant of the data provider component.
   * This will ensure if misused that it will fail fast and provide a valuable error message.
   */
  function useProviderState() {
    const context = useContext(ProviderContext);

    if (context === undefined) {
      throw new Error(`use of "useState" hook must be used within "${displayName}"`);
    }

    return context;
  }

  return [
    ({ children }: { children: ReactNode }) => {
      let state,
        dispatch = () => {
          console.error(`You're using dispatch, when you meant to use setState`);
        },
        setState = () => {
          console.error(`You're using setState, when you meant to use dispatch`);
        };
      const shouldUseState = reducer === undefined;
      if (shouldUseState) {
        [state, setState as Dispatch<SetStateAction<T>>] = useState(initialState);
      } else {
        [state, dispatch as Dispatch<ActionEvent<string, T | P>>] = useReducer(reducer, initialState);
      }

      useEffect(() => {
        // Context object accepts a displayName string property.
        // React DevTools uses this string to determine what to display
        // for the context.
        ProviderContext.displayName = displayName;
      }, [displayName]);

      // memoized so that providerState isn't recreated on each render
      const [memoState, memoDispatch, memoSetState] = useMemo(
        () => [state, dispatch as Dispatch<ActionEvent<string, T | P>>, setState as Dispatch<SetStateAction<T>>],
        [state]
      );

      return (
        <ProviderContext.Provider
          value={{
            state: memoState,
            dispatch: memoDispatch,
            setState: memoSetState,
          }}
        >
          <BaseComponent
            context={{
              state: memoState,
              dispatch: memoDispatch,
              setState: memoSetState,
            }}
          >
            {children}
          </BaseComponent>
        </ProviderContext.Provider>
      );
    },
    useProviderState,
  ];
};

export { withProvider };
