import { debounce, generateUniqueKey } from '@health-activity-ui/utils';
import { RdsIcon } from '@rally/energon-react';
import classnames from 'classnames';
import React, { ReactElement, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { FormField } from './form-field.styled';
import { InputProps } from './input-props.interface';

export const InputForm = ({
  autocomplete = '',
  className = '',
  disabled,
  error = '',
  id,
  inputClassName = '',
  labelText,
  onChange,
  onKeyPress,
  placeholder = '',
  type = 'text',
  errorDisplayType = 'inline',
  value,
  sizing = 'default',
  inputRef,
  ...props
}: InputProps): ReactElement => {
  const uniqueId = id ?? generateUniqueKey();
  const errorWidgetId = `error-widget-${uniqueId}`;
  const ref = useRef(null);
  const offsetTop = ref?.current?.offsetHeight + ref?.current?.offsetTop;
  const [errorWidgetTop, setErrorWidgetTop] = useState(0);

  useLayoutEffect(() => {
    const updateSize = (): void => {
      setErrorWidgetTop(offsetTop);
    };

    window.addEventListener('resize', debounce(updateSize, 250));
    return (): void => window.removeEventListener('resize', updateSize);
  }, [offsetTop]);

  useEffect(() => {
    setErrorWidgetTop(offsetTop);
  }, [offsetTop]);

  return (
    <FormField
      className={classnames(className, `form-field field-${type}`, {
        'is-w-full': sizing === 'full' && !props.size,
        'is-relative': errorDisplayType === 'inline',
      })}
      errorWidgetTop={errorWidgetTop ?? 0}
      ref={ref}
    >
      {labelText && (
        <div className="is-relative">
          <label
            htmlFor={id}
            className={classnames('rds-label', {
              'is-rds-error': error,
            })}
          >
            {labelText}
          </label>
        </div>
      )}
      <input
        autoComplete={autocomplete}
        className={classnames(
          'rds-input has-rds-ph-8',
          {
            'is-auto': sizing === 'auto',
            'is-rds-error': error,
            'is-rds-fullwidth': sizing === 'full',
          },
          inputClassName
        )}
        disabled={disabled}
        id={uniqueId}
        onChange={onChange}
        onKeyPress={onKeyPress}
        placeholder={placeholder}
        type={type}
        value={value}
        {...props}
        ref={inputRef}
      />

      {error && errorDisplayType === 'inline' && (
        <div className="error-messages is-relative">
          <p className="error-message is-rds-error">
            <RdsIcon name="system-info-circle" size="16" className="stat-icon has-rds-mr-8 is-fill" />
            {error}
          </p>
        </div>
      )}

      {errorDisplayType === 'tooltip' && (
        <div
          className={classnames(
            'error-widget is-rds-body-2 has-rds-text-danger has-rds-bg-danger has-rds-radius-4 has-rds-p-8 is-flex is-justify-content-center',
            {
              'is-hidden': !error,
            }
          )}
          data-testid="errorWidget"
          id={errorWidgetId}
        >
          {error}
        </div>
      )}
    </FormField>
  );
};

export default InputForm;
