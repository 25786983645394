import React from 'react';
import { RDS_COLOR_SYSTEM_JADE } from '@rally/ui-themes/dist/web/rally';

export const StartDirectionalArrowIcon = (): React.ReactElement => {
  return (
    <svg width={18} height={18}>
      <g fill="none">
        <circle fill={RDS_COLOR_SYSTEM_JADE} cx={9} cy={9} r={9} />
        <path d="M5 9l.705.705L8.5 6.915V13h1V6.915l2.795 2.79L13 9 9 5z" fill="#FBFBFB" stroke="#FFF" />
      </g>
    </svg>
  );
};

export default StartDirectionalArrowIcon;
