import { LegalAgreement, SSO_BASE_URI } from '@health-activity-ui/shared';
import apiClient from './api-client';
import { AxiosResponse } from 'axios';

export const ssoAgreements = async (agreement: LegalAgreement): Promise<AxiosResponse> => {
  const response = await apiClient<AxiosResponse>(`${SSO_BASE_URI}/v1/agreements`, {
    method: 'POST',
    data: { agreement },
  });
  return response;
};
