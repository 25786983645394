import React, { Children, ReactElement, useRef } from 'react';
import { Container, Level, LevelSide, Section, SectionProps } from '../layout';
import { Tab } from './tab.component';
import { useTabs } from './tabs.provider';

export interface TabsProps extends SectionProps {
  children: ReactElement | ReactElement[];
  isFluid?: boolean;
  side?: 'left' | 'right';
}

// Rename to `Tabs` after deprecating ./lib/Tabs/Tabs
export const Tabs = ({ children, isFluid = false, side = 'left', ...allSectionProps }: TabsProps) => {
  const { activeTab } = useTabs();
  const tabRefs = useRef<HTMLButtonElement[]>([]);
  const tabItems = Children.toArray(children);
  const tabCount = Children.count(children);

  return (
    <>
      <Section
        {...allSectionProps}
        className="tab-list has-rds-pv-none has-rds-neutral-border-bottom has-rds-border-width-4"
      >
        <Container isFluid={isFluid}>
          <Level isMobile>
            {side === 'right' ? <LevelSide side="left" /> : null}
            <LevelSide side={side}>
              <ul role="tablist" className="tabs has-rds-negative-mb-4">
                {tabItems.map((child, index) => {
                  const item = child as ReactElement;
                  return (
                    <Tab
                      key={index}
                      tabRef={(element) => {
                        tabRefs.current[index] = element;
                      }}
                      tabRefs={tabRefs?.current}
                      tabTitle={item?.props.tabTitle}
                      index={index}
                      tabCount={tabCount}
                    />
                  );
                })}
              </ul>
            </LevelSide>
          </Level>
        </Container>
      </Section>

      {tabItems[activeTab]}
    </>
  );
};

export default Tabs;
