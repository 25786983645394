import { RDS_COLOR_SYSTEM_JADE } from '@rally/ui-themes/dist/web/rally';
import React from 'react';

interface Props {
  color?: string;
  isSmall?: boolean;
  size?: string;
  className?: string;
}

export const CoinIcon = ({ color = RDS_COLOR_SYSTEM_JADE, size = '32', className = '' }: Props): React.ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 58 58" className={className}>
    <g>
      <circle fill="#ffffff" cx="29" cy="29" r="27" />
      <path
        fill={color}
        d="M29,0C13,0,0,13,0,29s13,29,29,29s29-13,29-29S45,0,29,0z M45.6,26.1l-8.7,6.3l3.3,10.3c0.2,0.6,0,1.2-0.5,1.6
c-0.2,0.2-0.5,0.3-0.8,0.3c-0.3,0-0.6-0.1-0.8-0.3l-8.7-6.4l-8.7,6.3c-0.5,0.4-1.2,0.4-1.6,0c-0.5-0.4-0.7-1-0.5-1.6l3.3-10.3
L13,26.1c-0.5-0.4-0.7-0.7-0.5-1.3c0.2-0.6,0.7-0.7,1.3-0.7h10.8L28,13.6c0.2-0.6,0.7-1.1,1.3-1.1c0.6,0,1.1,0.6,1.3,1.2L34,24.2
h10.8c0.6,0,1.1,0.1,1.3,0.7C46.3,25.4,46.1,25.8,45.6,26.1z"
      />
    </g>
  </svg>
);

export default CoinIcon;
