import { useCore } from '@health-activity-ui/core';
import { addBannerResponse, removeBannerResponse } from '@health-activity-ui/data-access';
import { ERROR_API, PropsWithChildren, Status } from '@health-activity-ui/shared';
import { respondTo } from '@health-activity-ui/utils';
import { RDS_SPACING_SIZE_16, RDS_SPACING_SIZE_24 } from '@rally/ui-themes/dist/web/rally';
import React, { ReactElement, useEffect } from 'react';
import styled from '@emotion/styled';
import { Container, Level, LevelItem, Section } from '../layout';
import { AcLoader } from '../loader/ac-loader.component';

const StyledPageWizardLayout = styled.div`
  .back-link {
    left: 0;
    position: absolute;
    top: -${RDS_SPACING_SIZE_16};
  }
  legend {
    margin: 0 auto;
  }
  .image-plan {
    height: 104px;
    width: 104px;
  }
  ${respondTo.mobile`
    .back-link {
      top: -${RDS_SPACING_SIZE_24};
    }
  `}
`;

interface PageWizardProps extends PropsWithChildren {
  header: string;
  status: Status;
  actionBarItems?: ReactElement;
  backLink?: ReactElement;
  className?: string;
}

export const PageWizardLayout = ({
  header,
  children,
  actionBarItems,
  backLink,
  className = '',
  status,
}: PageWizardProps): ReactElement => {
  const { commonDispatch, curBannerId, errorNotification } = useCore();

  // This useEffect is responsible for handling general api failures
  useEffect(() => {
    (() => {
      if (status === Status.error) {
        commonDispatch(addBannerResponse(errorNotification(ERROR_API)));
      } else if ((status === Status.loaded || status === Status.loading) && curBannerId.current) {
        commonDispatch(removeBannerResponse(curBannerId.current));
      }
    })();
  }, [status, curBannerId, errorNotification, commonDispatch]);

  return (
    <StyledPageWizardLayout data-testid="page-wizard-layout" className={className}>
      {status === Status.loading ? (
        <AcLoader />
      ) : (
        <>
          <Section isFirst className="is-relative">
            <Container>
              {backLink}
              <h1 data-testid="header-text" className="is-rds-h1 has-text-centered">
                {header}
              </h1>
              {children}
            </Container>
          </Section>

          {actionBarItems && (
            <Section isLast renderAs="footer" className="actions-bar">
              <Container>
                <Level className="actions-bar">
                  <LevelItem>{actionBarItems}</LevelItem>
                </Level>
              </Container>
            </Section>
          )}
        </>
      )}
    </StyledPageWizardLayout>
  );
};

export default PageWizardLayout;
