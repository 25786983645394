import React from 'react';

interface Props {
  width?: number;
  height?: number;
}

export const DiabetesIcon = ({ width = 200, height = 165 }: Props): React.ReactElement => (
  <svg viewBox="0 0 200 165" width={width} height={height}>
    <path
      fill="#C6A17F"
      d="M183.7 76.3c-2.2-21.9-19.8-39.5-41.7-41.7-1.4-.1-2.7-.2-4-.2-.5-.1-1-.2-1.6-.2h-27.5c-4.3 0-7.7 3.5-7.7 7.7v8.4H87.1v2.9H37.2c-4.6 0-8.3 3.7-8.3 8.3 0 4.6 3.7 8.3 8.3 8.3h47l3 2.9c-4.6 0-8.2 3.7-8.2 8.3 0 4.6 3.7 8.3 8.2 8.3V92c-4.6 0-8.2 3.7-8.2 8.3s3.7 8.6 8.2 8.6v2.9c-4.6 0-8.2 3.7-8.2 8.3 0 4.6 3.7 8.3 8.2 8.3H138c26.9-.4 48.5-24.3 45.7-52.1z"
    />
    <path
      fill="#E4BA90"
      d="M118.8 105.7H84.5c-4.6 0-8.3-3.7-8.3-8.3s3.7-8.3 8.3-8.3h34.2c4.6 0 8.3 3.7 8.3 8.3s-3.7 8.3-8.2 8.3zm0 19.8H84.5c-4.6 0-8.3-3.7-8.3-8.3 0-4.6 3.7-8.3 8.3-8.3h34.2c4.6 0 8.3 3.7 8.3 8.3 0 4.6-3.7 8.3-8.2 8.3zM96.3 63.4v-13H34.5c-4.6 0-8.3 3.7-8.3 8.3s3.7 8.3 8.3 8.3H97c-.4-1.1-.7-2.3-.7-3.6zm22.5 6.5h-5c-1.8 2.1-4.5 3.4-7.5 3.4s-5.6-1.3-7.5-3.4H84.5c-4.6 0-8.3 3.7-8.3 8.3 0 4.6 3.7 8.3 8.3 8.3h34.2c4.6 0 8.3-3.7 8.3-8.3 0-4.6-3.7-8.3-8.2-8.3z"
    />
    <path
      fill="#E4BA90"
      d="M181.1 73.5c-2.2-21.9-19.8-39.5-41.7-41.7-1.4-.1-2.7-.2-4-.2-.5-.1-1-.2-1.6-.2h-27.5c-4.3 0-7.7 3.5-7.7 7.7v24.3c0 4.3 3.5 7.7 7.7 7.7 4.3 0 7.7-3.5 7.7-7.7V46.9h20.5v2.3h-18.3V67s5.4 0 7.6 1.5c3.5 1.8 6 5.5 6 9.7 0 4.1-2.3 7.7-5.7 9.6 3.4 1.9 5.7 5.5 5.7 9.6 0 4.4-2.6 8.1-6.2 9.9 3.7 1.8 6.2 5.5 6.2 9.9 0 2.7-1 5.2-2.7 7.1.9.1 1.8.3 2.7.4 29.6 2.9 54.2-21.7 51.3-51.2z"
    />
    <path
      fill="#DF5546"
      d="M53.4 95.1L35.8 59.8 18.1 95.1c-1.3 2.7-2.1 5.6-2.1 8.8 0 10.9 8.8 19.7 19.7 19.7 10.9 0 19.7-8.8 19.7-19.7.1-3.1-.7-6.1-2-8.8z"
    />
    <path
      fill="#CF4F41"
      d="M53.6 95.3L35.9 59.9l-.1.2L47 89.4c1.1 2.8 1.5 5.8 1.2 9-1.1 10.9-10.8 18.8-21.6 17.7-2.9-.3-5.7-1.2-8.1-2.7 3.3 6.2 9.9 10.5 17.4 10.5 10.9 0 19.8-8.8 19.8-19.8 0-3.1-.8-6.1-2.1-8.8z"
    />
    <path
      fill="#EF5B4B"
      d="M48.2 98.4c.3-3.2-.1-6.2-1.2-9L35.9 60.1 18.2 95.3c-1.3 2.7-2.1 5.7-2.1 8.8 0 3.4.8 6.5 2.3 9.3 2.4 1.4 5.1 2.4 8.1 2.7 10.9 1.1 20.6-6.8 21.7-17.7z"
    />
    <path fill="#FFF" d="M27 105.2h17.5v6H27zm17.5-9.4h-5.7v5.8h-6v-5.8H27v-5.9h5.8v-5.8h6v5.8h5.7z" />
  </svg>
);

export default DiabetesIcon;
