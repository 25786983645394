interface PointsOnCircleParams {
  radius: number;
  angle: number;
  cx: number;
  cy: number;
}
/**
 * @function findPointsOnCircle
 *
 * @description Calculate x and y points on a circle's circumference.
 *
 * @param {Object} input - The input parameters
 * @param {number} input.radius - The circle's radius
 * @param {number} input.angle - The angle in degrees
 * @param {number} input.cx - The circle's origin x
 * @param {number} input.cy - The circle's origin y
 *
 * @returns {Array[number,number]} The calculated x and y points to place point on circumference
 */
export const findPointsOnCircle = ({ radius, angle, cx, cy }: PointsOnCircleParams): [number, number] => {
  angle = angle * (Math.PI / 180); // Convert from Degrees to Radians
  const x = cx + radius * Math.cos(angle);
  const y = cy + radius * Math.sin(angle);
  return [x, y];
};

/**
 * @function abbreviateNumber
 *
 * @description Converts a long number into abbreviated string
 *
 * @param {number} number - Number to abbreviate
 * @param {number} [decPlaces]
 *
 * @returns {string | number} Abbreviated string if value is over 1000 else return value
 */
export const abbreviateNumber = (number: number, decPlaces = 1): string | number => {
  let result: string | number = number;
  // 2 decimal places => 100, 3 => 1000, etc
  decPlaces = Math.pow(10, decPlaces);
  // Enumerate number abbreviations
  const abbrev = ['K', 'M', 'B', 'T'];
  const isNeg = number < 0;

  if (isNeg) {
    number = number * -1;
  }

  // Go through the array backwards, so we do the largest first
  for (let i = abbrev.length - 1; i >= 0; i--) {
    // Convert array index to "1000", "1000000", etc
    const size = Math.pow(10, (i + 1) * 3);

    // If the number is bigger or equal do the abbreviation
    if (size <= number) {
      // Here, we multiply by decPlaces, round, and then divide by decPlaces.
      // This gives us nice rounding to a particular decimal place.
      number = Math.round((number * decPlaces) / size) / decPlaces;

      // Handle special case where we round up to the next abbreviation
      if (number === 1000 && i < abbrev.length - 1) {
        number = 1;
        i++;
      }

      // Add the letter for the abbreviation
      result = `${number}${abbrev[i]}`;

      // We are done... stop
      break;
    }
  }

  return isNeg ? `-${result}` : result;
};

/**
 * @function log10
 *
 * @description Calculates if a number is tens, hundreds, thousands and etc by creating
 * a base 10 log to calculate the common logarithm of x. For example, the log base 10 of
 * 10 is 1, the log base 10 of 100 is 2 and the log base 10 of 1000 is 3.
 *
 * @param {number} x - Calculate the log base 10 of a number x
 *
 * @returns {number}
 */
export const log10 = (x: number): number => Math.round((100 * Math.log(x)) / Math.log(10)) / 100;
