import {
  ACTIVITIES_BASE_ROUTE,
  appConfig,
  CHALLENGES_2_BASE_ROUTE,
  CHALLENGES_BASE_ROUTE,
  COMMUNITIES_BASE_ROUTE,
  DASHBOARD_BASE_ROUTE,
  INVITE_CODE_PARAM,
  MISSIONS_BASE_ROUTE,
  STAYING_WELL_BASE_ROUTE,
} from '@health-activity-ui/shared';
import { getUrlQueryParams, isDefined } from './utils';

export interface IQueryParams {
  [key: string]: string;
}

/**
 * @function parseQueryString
 *
 * @description It will return the query params of a queryString. If the
 * `queryString` param is undefined, then it will retrieve the search property
 * on the `window.location` object.
 *
 * @parm {string} [queryString]
 *
 * @return {IQueryParams} The queryParams object is unknown since the queryString can be different
 * each time.
 * */
export const parseQueryString = (queryString?: string): IQueryParams => {
  //window.location is an object, but ie8 incorrectly interprets it as a string. toString solves for all browsers
  if (!isDefined(queryString)) {
    queryString = 'search' in window.location ? window.location.search : JSON.stringify(window.location);
  }

  const queryParams = {};
  const initParams = queryString.split('?');

  if (initParams[1]) {
    const params = initParams[1].split('&');
    params.forEach((param) => {
      const keyValuePair = param.split('=');
      if (keyValuePair.length > 1) {
        queryParams[keyValuePair[0]] = keyValuePair[1];
      }
    });
  }

  return queryParams;
};

export const isReactRoute = [
  DASHBOARD_BASE_ROUTE,
  STAYING_WELL_BASE_ROUTE,
  ACTIVITIES_BASE_ROUTE,
  CHALLENGES_2_BASE_ROUTE,
  CHALLENGES_BASE_ROUTE,
  MISSIONS_BASE_ROUTE,
  COMMUNITIES_BASE_ROUTE,
].filter(Boolean);

/**
 * @description Checks if the pathname/route is one of our app's react routes (not an external link), if it is then
 * return the pathname and use React Router to navigate to avoid a full page reload.
 * */
export const getReactRoute = (pathname: string, reactRoutes: string[]) =>
  reactRoutes.reduce(
    (route, nextRoute) => (nextRoute.includes(pathname.replace('/health', '')) ? nextRoute : route),
    ''
  );

export const getChallengesV2Params = (): string => {
  const urlParams = getUrlQueryParams();
  // eslint-disable-next-line no-prototype-builtins
  const inviteCode = urlParams.hasOwnProperty(INVITE_CODE_PARAM) && urlParams.inviteCode;
  return inviteCode ? `?${INVITE_CODE_PARAM}=${inviteCode}` : '';
};

export const getCommunityImage = (communityId: string, type: 'banner' | 'pod' = 'banner'): string => {
  return type === 'banner'
    ? `${appConfig.HAUI_APP_HA_STARSHIP_CDN_URL}/communitymanager/banners/${communityId}.png`
    : `${appConfig.HAUI_APP_HA_STARSHIP_CDN_URL}/communitymanager/pod/${communityId}.png`;
};
