import React from 'react';
import { ASSETS_PATH, PropsWithChildren } from '@health-activity-ui/shared';

interface Props extends PropsWithChildren {
  style?: any /* eslint-disable-line */;
}

export const ChiTownShuffleIcon = ({ style }: Props): React.ReactElement => (
  <img
    src={`${ASSETS_PATH}/img/challengesV2/cityChallenges/ChiTownShuffle.png`}
    alt={'Chi Town Shuffle Icon'}
    style={style}
  />
);

export default ChiTownShuffleIcon;
