import { appConfig } from '@health-activity-ui/shared';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

export const initDatadog = (): void => {
  const datadogConfig = {
    clientToken: appConfig.HAUI_APP_HA_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'health-activity-ui',
    env: appConfig.HAUI_APP_HA_ENVIRONMENT,
    sampleRate: 100,
    useSecureSessionCookie: true,
    trackInteractions: true,
    version: appConfig.HAUI_APP_HA_VERSION,
  };
  datadogRum.init({ applicationId: appConfig.HAUI_APP_HA_DATADOG_APP_ID, ...datadogConfig });
  datadogLogs.init({ forwardErrorsToLogs: true, ...datadogConfig });
  datadogRum.startSessionReplayRecording();
};
