import classnames from 'classnames';

export type BorderRadiusProps = '0' | '4' | '8' | '12' | '20' | 'circle' | 'rounded';
export type BorderWidthProps = '1' | '3' | '4' | '6' | '7' | '10' | '16';
export type BoxShadowProps = 'spread-6' | 'spread-10' | 'none';
export type OutlineWidthProps = '3' | '4';

export interface EffectsProps {
  // the type of border radius to use for the border-radius property
  borderRadius?: BorderRadiusProps;

  // the border width size for the border-width property
  borderWidth?: BorderWidthProps;

  // the box shadow spread to use for the box-shadow property
  boxShadow?: BoxShadowProps;

  // the outline width to use for the outline-width property
  outlineWidth?: OutlineWidthProps;
}

export default {
  defaultProps: {
    borderRadius: undefined,
    borderWidth: undefined,
    boxShadow: undefined,
    outlineWidth: undefined,
  },
  classnames: (props: EffectsProps): string => {
    return classnames({
      [`has-rds-radius-${props.borderRadius}`]: props.borderRadius,
      [`has-rds-border-width-${props.borderWidth}`]: props.borderWidth,
      'has-rds-border-solid': props.borderWidth,
      [`has-rds-outline-width-${props.outlineWidth}`]: props.outlineWidth,
      [`has-rds-box-shadow-neutral-${props.boxShadow}`]: props.boxShadow,
    });
  },
  /* eslint-disable-next-line */
  base: ({ borderRadius, borderWidth, outlineWidth, boxShadow, ...props }) => props,
};
