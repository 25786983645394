import { checkInMission, deviceCheckInMission } from '@health-activity-ui/data-access';
import { IMissionResponse, INFO_NO_NEW_DATA, NotificationStatus } from '@health-activity-ui/shared';
import { logCustomUserEvent, TriggerType } from '@rally/analytics';
import { isPast } from 'date-fns';

interface IUseMissionCheckin {
  manualCheckIn(instanceId: string, amount: number, checkInDate: Date): Promise<boolean>;
  deviceCheckIn(instanceId: string): Promise<boolean>;
}

interface IUseMissionCheckinConfigs {
  updateCb: (rsp?: IMissionResponse) => Promise<void> | void;
  errorMessage: string;
  showNotification(type: NotificationStatus, message: string): void;
}

export const useMissionCheckin = ({
  updateCb,
  errorMessage,
  showNotification,
}: IUseMissionCheckinConfigs): IUseMissionCheckin => {
  const manualCheckIn = async (instanceId: string, amount: number, checkInDate: Date): Promise<boolean> => {
    try {
      const rsp = await checkInMission(instanceId, amount, false, checkInDate);
      const eventTrackingData = {
        'Mission Category': rsp.mission.category,
        'Mission Checkin Type': rsp.mission.kind,
        'Mission Pack': !!rsp.instance?.stats.isFocused,
        'Mission Past Checkin': isPast(checkInDate),
        'Mission Daily Success': rsp.instance?.stats.currentDay.amount
          ? rsp.instance?.stats.currentDay.amount >= rsp.mission.successCriteria.daily
          : null,
        'Mission Daily Count': rsp.instance?.stats.currentDay.amount,
      };
      logCustomUserEvent('Mission Manual Check-in', eventTrackingData, TriggerType.Click);
      await updateCb(rsp);
      return true;
    } catch (e) {
      console.error(`Error, unable to check in for mission ${instanceId}`, e);

      showNotification(NotificationStatus.ERROR, errorMessage);

      return false;
    }
  };

  const deviceCheckIn = async (instanceId: string): Promise<boolean> => {
    try {
      const { message, missionResponse, status } = await deviceCheckInMission(instanceId);
      if (status === 'success') {
        await updateCb(missionResponse);
        const eventTrackingData = {
          'Mission Category': missionResponse.mission.category,
          'Mission Checkin Type': missionResponse.mission.kind,
          'Mission Pack': !!missionResponse.instance?.stats.isFocused,
          'Mission Daily Count': missionResponse.instance?.stats.currentDay.amount,
        };
        logCustomUserEvent('Mission Device Check-in', eventTrackingData, TriggerType.Click);
        showNotification(NotificationStatus.SUCCESS, message);
      } else if (status === 'noDeviceData') {
        showNotification(NotificationStatus.INFO, INFO_NO_NEW_DATA);
      } else {
        showNotification(NotificationStatus.INFO, message);
      }

      return true;
    } catch (e) {
      console.error(`Error, unable to check in for mission ${instanceId}`, e);

      showNotification(NotificationStatus.ERROR, errorMessage);

      return false;
    }
  };

  return {
    manualCheckIn,
    deviceCheckIn,
  };
};
