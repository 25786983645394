import React, { SVGProps } from 'react';

export const FeedbackIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={192} height={192} fill="none" aria-hidden={true} {...props}>
    <path
      d="M121.273 41.774c13.198.23 27.346 8.928 27.346 27.728v45.721c-.16 12.574-7.935 25.862-24.288 27.432v27.31s-38.455-25.932-40.115-27.011H28.338C15.112 142.779.967 134.027.967 115.223v-45.75c.186-13.39 8.833-27.699 27.37-27.699h92.936Z"
      fill="#42B876"
    />
    <path
      d="M121.273 41.774c13.198.23 27.346 8.928 27.346 27.728v45.721c-.16 12.574-7.935 25.862-24.288 27.432v27.31s-38.455-25.932-40.115-27.011H28.338C15.112 142.779.967 134.027.967 115.223v-45.75c.186-13.39 8.833-27.699 27.37-27.699h92.936Z"
      fill="#42B876"
    />
    <path
      d="M148.359 64.915c0-.345-.296-1.708-.889-4.088-.592-2.38-1.947-5.156-4.066-8.328l-30.475 32.303-3.581 14.082 18.296-6.787 20.715-27.182Z"
      fill="#249054"
    />
    <path
      fill="#fff"
      d="M98.811 87.565H24.903v11.247h73.909zm18.477 24.1H30.527a5.624 5.624 0 0 0 0 11.247h86.761a5.624 5.624 0 1 0 0-11.247ZM50.61 63.464H30.528a5.623 5.623 0 0 0 0 11.247h20.084a5.623 5.623 0 0 0 0-11.247Z"
    />
    <path d="m122.41 85.2-13.114 6.379-2.439-1.688 7.839-10.366 4.578.79 3.136 4.885Z" fill="#FFF4D5" />
    <path d="m107.779 74.135.015.015 5.18 1.4 1.992 4.022-7.839 10.365-2.095-1.585 2.747-14.217Z" fill="#FAE5A6" />
    <path
      d="m158.467 37.713-25.019 32.844-11.049 14.612-.011.015-3.113-4.87 34.868-45.871 4.324 3.27Z"
      fill="#F9A96C"
    />
    <path d="m166.716 18.22-47.149 62.468-4.601-1.117-2.125-4.301 47.186-62.08 6.689 5.03Z" fill="#FBBD8D" />
    <path
      d="m107.783 74.118-.009-.014 8.989-11.888 2.06-2.726.015.014 25.012-32.842 3.992 3.017-34.868 45.872-5.195-1.416.004-.017Z"
      fill="#F5934B"
    />
    <path d="m170.567 21.713-12.1 16-7.365-5.57 12.101-15.999 7.364 5.57Z" fill="#FAE5A6" />
    <path d="m155.951 10.66 7.252 5.484-12.101 15.999-7.251-5.484 12.1-15.999Z" fill="#FFC82E" />
    <path
      d="m170.567 21.713-7.365-5.57 6.655-8.8 3.882 2.936a4.367 4.367 0 0 1 .848 6.118l-4.02 5.316Z"
      fill="#FAC4AD"
    />
    <path d="m169.857 7.343-6.655 8.8-7.251-5.483 4.021-5.317a4.367 4.367 0 0 1 6.117-.85l3.768 2.85Z" fill="#F5A581" />
    <path d="m109.297 91.578-5.496 2.75 1.23-5.975 4.266 3.225Z" fill="#5C5B5E" />
    <ellipse cx={85.958} cy={186.785} rx={84.352} ry={4.82} fill="#000" fillOpacity={0.1} />
  </svg>
);
