import React from 'react';
import { ASSETS_PATH } from '@health-activity-ui/shared';

interface Props {
  style?: any /* eslint-disable-line */;
}

export const TorontoTrekIcon = ({ style }: Props): React.ReactElement => (
  <img src={`${ASSETS_PATH}/img/challengesV2/cityChallenges/TorontoTrek.png`} alt={'Toronto Trek Icon'} style={style} />
);

export default TorontoTrekIcon;
