import { SETTING_BASE_URI } from '@health-activity-ui/shared';
import { Appointment } from '@health-activity-ui/shared';
import apiClient from './api-client';

export async function submitAppointment(appointment: Appointment): Promise<number> {
  const response = await apiClient(`${SETTING_BASE_URI}user/appointment`, {
    method: 'POST',
    data: { ...appointment },
  });

  return response.status;
}
