export enum Status {
  initial = 'INITIAL',
  loading = 'LOADING',
  loaded = 'LOADED',
  error = 'ERROR',
}

export const ERROR = {
  FETCH: {
    BODY_PARSING: 'Error parsing response body',
    NOT_FOUND_ERROR: 'notfound',
    OOPS: 'oops',
    SERVER_ERROR: 'server error',
  },
};
