import React from 'react';

interface Props {
  alt?: string;
}

export const DashCircleIcon = ({ alt }: Props): React.ReactElement => {
  return (
    <svg width="24" height="24" className="dash-mark-svg" role="img" aria-label={alt}>
      {alt && <title>{alt}</title>}
      <g fill="none" fillRule="evenodd">
        <circle fill="#E5E5E5" cx="12" cy="12" r="12" />
        <rect fill="#666" x="4.8" y="10.8" width="14.4" height="2.4" rx="1" />
      </g>
    </svg>
  );
};

export default DashCircleIcon;
