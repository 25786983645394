import styled from '@emotion/styled';
import {
  RDS_BORDER_RADIUS_4,
  RDS_COLOR_SYSTEM_GRENADIER_DARK,
  RDS_COLOR_SYSTEM_GRENADIER_LIGHT,
  RDS_SPACING_SIZE_16,
  RDS_SPACING_SIZE_8,
} from '@rally/ui-themes/dist/web/rally';

interface FormFieldProps {
  errorWidgetTop: number;
}

export const FormField = styled.div<FormFieldProps>`
  .rds-input.is-auto {
    width: auto !important;
  }

  .error-message {
    color: ${RDS_COLOR_SYSTEM_GRENADIER_DARK} !important;
    min-width: 200px;
    position: absolute;
  }

  .error-widget {
    border-radius: ${RDS_BORDER_RADIUS_4};
    margin-top: ${RDS_SPACING_SIZE_8};
    max-width: 384px;
    opacity: 1;
    padding: ${RDS_SPACING_SIZE_8} ${RDS_SPACING_SIZE_16};
    pointer-events: none;
    position: absolute;
    top: ${({ errorWidgetTop }) => errorWidgetTop}px;
    transition: opacity 750ms ease-out;
    z-index: 3;

    &.is-hidden {
      opacity: 0;
      visibility: hidden;
    }

    &:before {
      border-bottom: ${RDS_SPACING_SIZE_8} solid ${RDS_COLOR_SYSTEM_GRENADIER_LIGHT};
      border-left: ${RDS_SPACING_SIZE_8} solid transparent;
      border-right: ${RDS_SPACING_SIZE_8} solid transparent;
      content: '';
      left: ${RDS_SPACING_SIZE_16};
      margin-left: -${RDS_SPACING_SIZE_8};
      position: absolute;
      top: -${RDS_SPACING_SIZE_8};
      z-index: 0;
    }
  }
`;

export default FormField;
