import { respondTo } from '@health-activity-ui/utils';
import { RDS_BORDER_RADIUS_8, RDS_SPACING_SIZE_8 } from '@rally/ui-themes/dist/web/rally';
import styled, { css, FlattenSimpleInterpolation, keyframes } from 'styled-components';
import { InputForm } from './input-form.component';
import { InputProps } from './input-props.interface';

/**
 * These box shadows are not approved by RDS.
 */
const inputPulse = keyframes`
  0% { box-shadow: 0 0 0 0 rgba(73, 197, 126, 0.5);}
  100% { box-shadow: 0 0 0 35px rgba(73, 197, 126, 0);}
`;

export const InputWithRipple = styled(InputForm)<{ $showRipple } & InputProps>`
  &&& {
    border-radius: ${RDS_BORDER_RADIUS_8};
    ${respondTo.mobile`
      width: 150px;
    `}
    padding-left: ${RDS_SPACING_SIZE_8};
    padding-right: ${RDS_SPACING_SIZE_8};
    width: 225px;

    ${(props): FlattenSimpleInterpolation =>
      props.$showRipple
        ? css`
            animation: ${inputPulse} 2s;
            animation-delay: 2s;
            animation-iteration-count: 4 !important;
          `
        : css``}
  }
`;

export default InputWithRipple;
