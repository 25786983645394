import { DOMAIN_ACTION_TYPES, DomainActions } from './domain.actions';

import { DomainState } from './domain.models';
import { Status } from '@health-activity-ui/shared';

export const domainReducer = (state: DomainState, action: DomainActions): DomainState => {
  switch (action.type) {
    case DOMAIN_ACTION_TYPES.DOMAIN_FETCH_LOADING: {
      return {
        ...state,
        status: Status.loading,
      };
    }

    case DOMAIN_ACTION_TYPES.DOMAIN_FETCH_CORRECTED: {
      const domain = action.payload;
      return {
        ...state,
        status: Status.loaded,
        data: domain,
      };
    }

    case DOMAIN_ACTION_TYPES.DOMAIN_FETCH_VALIDATED: {
      return {
        ...state,
        status: Status.loaded,
      };
    }

    case DOMAIN_ACTION_TYPES.DOMAIN_FETCH_FAILED: {
      return {
        ...state,
        status: Status.error,
      };
    }
  }
};
