import { BaseModel } from '../base-model';
import { R2CTopics } from './readiness-2-change.interface';

export interface IMissionsResponse {
  recommendedMissions: IMissionResponse[];
  unjoinedMissions: IMissionResponse[];
  joinedMissions: IMissionResponse[];
}

export interface IMissionResponse {
  mission: IMission;
  instance?: IJoinedMissionInstanceResponse;
}

export interface MissionJoinInfo {
  timeZoneId: string;
  isFocused?: boolean;
  focusArea?: R2CTopics;
}

export interface FocusedMissionAdd {
  missionIds: Array<string>;
  focusArea: R2CTopics;
}

export interface FocusedMissionRemove {
  missionIds: Array<string>;
}
export class MissionResponseModel extends BaseModel<IMissionResponse> {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  public mission = new MissionModel();
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  public instance = new JoinedMissionInstanceResponseModel();

  constructor(data?: Partial<IMissionResponse>) {
    super();
    this.update(data);
  }

  public update(data: Partial<IMissionResponse>): IMissionResponse {
    return super.update(data);
  }
}

export interface IMission {
  articleTag: MissionArticleTag;
  backgroundColor: IMissionsBackgroundColor;
  category: MissionCategory;
  communityId: string;
  content: string;
  description: string;
  details: IMissionDetails;
  difficulty: MissionDifficulty;
  filters: string[];
  healthImpact: MissionHealthImpact;
  isSensitive: boolean;
  kind: MissionType;
  missionId: string;
  mobileFilters: string[];
  name: string;
  promotionDates: number[];
  successCriteria: ISuccessCriteria;
  emailData?: IMissionEmailData;
  question?: string;
  restrictions?: IMissionRestrictions;
}

export class MissionModel extends BaseModel<IMission> {
  public missionId = '';
  public name = '';
  public description = '';
  public question = '';
  public kind: MissionType;
  public filters = [];
  public category: MissionCategory;
  public healthImpact: MissionHealthImpact.Medium;
  public difficulty: MissionDifficulty.Medium;
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  public details = new MissionDetailsModel();
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  public successCriteria = new SuccessCriteriaModel();
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  public emailData = new MissionEmailDataModel();
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  public restrictions = new MissionRestrictionsModel();
  public promotionDates = [];
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  public backgroundColor = new MissionsBackgroundColorModel();
  public content = '';
  public mobileFilters = [];
  public isSensitive = false;
  public communityId = '';
  public Tag: MissionArticleTag.Running;

  constructor(data?: Partial<IMission>) {
    super();
    this.update(data);
  }

  public update(data: Partial<IMission>): IMission {
    return super.update(data);
  }
}

export interface IJoinedMissionInstanceResponse {
  instanceId: string;
  userData: IMissionUserDataResponse;
  stats: IMissionStatsResponse;
  history: IAggregatedIntervalResponse[];
}

export class JoinedMissionInstanceResponseModel extends BaseModel<IJoinedMissionInstanceResponse> {
  public instanceId = '';
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  public userData = new MissionUserDataResponseModel();
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  public stats = new MissionStatsResponseModel();
  public history = [AggregatedIntervalResponseModel as any]; /* eslint-disable-line */

  constructor(data?: Partial<IJoinedMissionInstanceResponse>) {
    super();
    this.update(data);
  }

  public update(data: Partial<IJoinedMissionInstanceResponse>): IJoinedMissionInstanceResponse {
    return super.update(data);
  }
}

export interface IMissionUserDataResponse {
  userId: string;
  displayName: string;
  timeZone: string;
}

export class MissionUserDataResponseModel extends BaseModel<IMissionUserDataResponse> {
  public userId = '';
  public displayName = '';
  public timeZone = '';

  constructor(data?: Partial<IMissionUserDataResponse>) {
    super();
    this.update(data);
  }

  public update(data: Partial<IMissionUserDataResponse>): IMissionUserDataResponse {
    return super.update(data);
  }
}

export interface IMissionStatsResponse {
  completedIntervals: number;
  currentDay: IMissionDay;
  currentInterval: ICurrentIntervalStats;
  lastDataAmount: number;
  missionCompletions?: number;
  missionStartDate: Date | string;
  missionStatus: MissionStatus;
  completedDate?: Date | string;
  dataSource?: DevicePartner;
  focusArea?: string;
  isFocused?: boolean;
  lastCheckinDate?: Date | string;
  lastDataUpdate?: Date | string;
}

export class MissionStatsResponseModel extends BaseModel<IMissionStatsResponse> {
  public missionStatus = MissionStatus.Incomplete;
  public dataSource = 'rally';
  public completedIntervals = 0;
  public missionCompletions = 0;
  public lastDataAmount = 0;
  public lastCheckinDate = null;
  public missionStartDate = null;
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  public currentInterval = new CurrentIntervalStatsModel();
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  public currentDay = new MissionDayModel();
  public completedDate = null;
  public isFocused = false;
  public focusArea = '';
  public lastDataUpdate = null;

  constructor(data?: Partial<IMissionStatsResponse>) {
    super();
    this.update(data);
  }

  public update(data: Partial<IMissionStatsResponse>): IMissionStatsResponse {
    return super.update(data);
  }
}

export interface ICurrentIntervalStats {
  startDate: Date | string;
  endDate: Date | string;
  completedDays: number;
}

export class CurrentIntervalStatsModel extends BaseModel<ICurrentIntervalStats> {
  public startDate = null;
  public endDate = null;
  public completedDays = 0;

  constructor(data?: Partial<ICurrentIntervalStats>) {
    super();
    this.update(data);
  }

  public update(data: Partial<ICurrentIntervalStats>): ICurrentIntervalStats {
    return super.update(data);
  }
}

export interface IAggregatedIntervalResponse {
  startDate: Date | string;
  endDate: Date | string;
  completedDays: number;
  dailyProgress: IMissionDay[];
}

export class AggregatedIntervalResponseModel extends BaseModel<IAggregatedIntervalResponse> {
  public startDate = '';
  public endDate = '';
  public completedDays = 0;
  public dailyProgress = [MissionDayModel as any]; /* eslint-disable-line */

  constructor(data?: Partial<IAggregatedIntervalResponse>) {
    super();
    this.update(data);
  }

  public update(data: Partial<IAggregatedIntervalResponse>): IAggregatedIntervalResponse {
    return super.update(data);
  }
}

export interface IMissionDay {
  date: Date | string;
  numberCheckIns: number;
  amount: number;
}

export class MissionDayModel extends BaseModel<IMissionDay> {
  public date = null;
  public numberCheckIns = 0;
  public amount = 0;

  constructor(data?: Partial<IMissionDay>) {
    super();
    this.update(data);
  }

  public update(data: Partial<IMissionDay>): IMissionDay {
    return super.update(data);
  }
}

export interface IMissionDetails {
  unit: MissionUnits;
  dailyMin: number;
  dailyMax: number;
  deviceEnabled: boolean;
  criteriaToDisplay: string;
  criteriaUnitToDisplay: string;
  potentialCoinYield: number;
}

export class MissionDetailsModel extends BaseModel<IMissionDetails> {
  public unit: MissionUnits;
  public dailyMin = 0;
  public dailyMax = 0;
  public deviceEnabled = false;
  public criteriaToDisplay = '';
  public criteriaUnitToDisplay = '';
  public potentialCoinYield = 0;

  constructor(data?: Partial<IMissionDetails>) {
    super();
    this.update(data);
  }

  public update(data: Partial<IMissionDetails>): IMissionDetails {
    return super.update(data);
  }
}

export interface ISuccessCriteria {
  daily: number;
  weekly: number;
  numWeeks: number;
}

export class SuccessCriteriaModel extends BaseModel<ISuccessCriteria> {
  public daily = 0;
  public weekly = 0;
  public numWeeks = 0;

  constructor(data?: Partial<ISuccessCriteria>) {
    super();
    this.update(data);
  }

  public update(data: Partial<ISuccessCriteria>): ISuccessCriteria {
    return super.update(data);
  }
}

export interface IMissionEmailData {
  friendlyName: string;
  reinforceCopy: string;
  subjectVariable: string;
  successVariable: string;
}

export class MissionEmailDataModel extends BaseModel<IMissionEmailData> {
  public friendlyName = '';
  public reinforceCopy = '';
  public subjectVariable = '';
  public successVariable = '';

  constructor(data?: Partial<IMissionEmailData>) {
    super();
    this.update(data);
  }

  public update(data: Partial<IMissionEmailData>): IMissionEmailData {
    return super.update(data);
  }
}

export interface IMissionRestrictions {
  clients: string[];
  affiliations: string[];
  restrictionType: MissionRestrictionType;
}

export class MissionRestrictionsModel extends BaseModel<IMissionRestrictions> {
  public clients = [];
  public affiliations = [];
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  public restrictionType = MissionRestrictionType.Whitelist;

  constructor(data?: Partial<IMissionRestrictions>) {
    super();
    this.update(data);
  }

  public update(data: Partial<IMissionRestrictions>): IMissionRestrictions {
    return super.update(data);
  }
}

export interface IMissionsBackgroundColor {
  dark: string;
  normal: string;
}

export class MissionsBackgroundColorModel extends BaseModel<IMissionsBackgroundColor> {
  public dark = '';
  public normal = '';

  constructor(data?: Partial<IMissionsBackgroundColor>) {
    super();
    this.update(data);
  }

  public update(data: Partial<IMissionsBackgroundColor>): IMissionsBackgroundColor {
    return super.update(data);
  }
}

export enum MissionRestrictionType {
  Blacklist = 'blacklist',
  Whitelist = 'whitelist',
}

export type MissionCategory = 'move' | 'care' | 'eat' | 'feel';
export const MissionCategory = {
  Move: 'move' as MissionCategory,
  Care: 'care' as MissionCategory,
  Eat: 'eat' as MissionCategory,
  Feel: 'feel' as MissionCategory,
};

export type MissionType = 'integer' | 'boolean' | '';
export const MissionType = {
  Integer: 'integer' as MissionType,
  Boolean: 'boolean' as MissionType,
  None: '' as MissionType,
};

export enum MissionHealthImpact {
  High = 'high',
  Medium = 'medium',
  Low = 'low',
}

export enum MissionDifficulty {
  Easy = 'easy',
  Medium = 'medium',
  Hard = 'hard',
}
export type MissionUnits = 'boolean' | 'hour' | 'mile' | 'minute' | 'serving' | 'step' | '';
export const MissionUnits = {
  Boolean: 'boolean' as MissionUnits,
  Hour: 'hour' as MissionUnits,
  Mile: 'mile' as MissionUnits,
  Minute: 'minute' as MissionUnits,
  Serving: 'serving' as MissionUnits,
  Step: 'step' as MissionUnits,
  None: '' as MissionUnits,
};

export enum MissionArticleTag {
  Biking = 'biking',
  Depression = 'depression',
  Fitness = 'fitness',
  Food = 'food',
  Medicines = 'medicines',
  Mindful = 'mindful',
  Recipes = 'recipes',
  Running = 'running',
  Sleep = 'sleep',
  Smoking = 'smoking',
  Strength = 'strength',
  Stress = 'stress',
  Swimming = 'swimming',
  Walking = 'walking',
  Weightloss = 'weightloss',
}

export type MissionStatus = 'success' | 'incomplete' | 'quitted' | 'success_open' | 'incomplete_waiting' | 'dormant';
export const MissionStatus = {
  Success: 'success' as MissionStatus,
  Incomplete: 'incomplete' as MissionStatus,
  Quitted: 'quitted' as MissionStatus,
  SuccessOpen: 'success_open' as MissionStatus,
  IncompleteWaiting: 'incomplete_waiting' as MissionStatus,
  Dormant: 'dormant' as MissionStatus,
};

export enum DevicePartner {
  fatsecret = 'fatsecret',
  fitbit = 'fitbit',
  garmin = 'garmin',
  //Hattrick is no longer supported. This enum type is needed only for UnlinkHattrickUsersMigratorTest.
  hattrick = 'hattrick',
  jawbone = 'jawbone',
  mapmyfitness = 'mapmyfitness',
  misfit = 'misfit',
  moves = 'moves',
  myfitnesspal = 'myfitnesspal',
  rally = 'rally',
  runkeeper = 'runkeeper',
  strava = 'strava',
  striiv = 'striiv',
  uarecord = 'uarecord',
  withings = 'withings',
  mobileInterim = 'mobileInterim',
  Manual = 'manual',
  NotProvided = 'notProvided',
  NotSupported = 'notSupported',
}

export enum DevicePartnerDisplayName {
  FatSecret = 'FatSecret',
  Fitbit = 'Fitbit',
  Garmin = 'Garmin',
  //Hattrick is no longer supported. This enum type is needed only for UnlinkHattrickUsersMigratorTest.
  Hattrick = 'Hattrick',
  Jawbone = 'Jawbone',
  MapMyFitness = 'MapMyFitness',
  MapMyRun = 'MapMyRun',
  Misfit = 'Misfit',
  Moves = 'Moves',
  MyFitnessPal = 'MyFitnessPal',
  'My Mobile Device' = 'My Mobile Device',
  RunKeeper = 'RunKeeper',
  Strava = 'Strava',
  Striiv = 'Striiv',
  Withings = 'Withings',
  mobileInterim = 'mobileInterim',
  Manual = 'manual',
  NotProvided = 'notProvided',
  NotSupported = 'notSupported',
}

export interface IMissionHistoryResponse {
  missionId: string;
  userId: string;
  startDay?: Date | string;
  endDay?: Date | string;
  history: IMissionDay[];
}

export interface IShowMobileDeviceResponse {
  enabled: boolean;
}

export interface IMissionDeviceCheckInResponse {
  message: string;
  missionResponse: IMissionResponse;
  status: string;
}
