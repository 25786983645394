import React from 'react';

interface Props {
  width?: number;
  height?: number;
}

export const AgeIcon = ({ width = 200, height = 165 }: Props): React.ReactElement => (
  <svg viewBox="0 0 200 165" width={width} height={height}>
    <ellipse fill="#E5E5E4" cx={100} cy={129} rx={69.2} ry={7.3} />
    <path fill="#EF5B4B" d="M69.8 52.3h6.9V78h-6.9zm26.1 0h6.9V78h-6.9zm26.2 0h6.9V78h-6.9z" />
    <ellipse fill="#FE9646" cx={73.1} cy={45.2} rx={5.1} ry={5.4} />
    <path
      fill="#FFC82B"
      d="M71.6 50.5c.3-.3-1.4-1.5-1.4-3.2 0-.8.4-1.7 1.2-2.1.7-.4 2.6-1.5 1.8-4.1-.3-1.1 3.8 4.1 3.6 6.3-.2 2.2-2.4 2.9-2.3 3.2.1.4 2.1-.4 3.8-1.8 1.2-.9 2.1-2.2 2-4.1-.1-2.1-1.5-4.9-5.4-8.7-1.1-1-3.2-3.8-3.7-3.2-.6.5.3 1.3.2 3-.2 1.6-.4 3.4-2.4 5.2-2.1 1.8-3.1 5.5-1 7.6.7.9 3.3 2.2 3.6 1.9z"
    />
    <ellipse fill="#FE9646" cx={99.5} cy={45.2} rx={5.1} ry={5.4} />
    <path
      fill="#FFC82B"
      d="M98 50.5c.3-.3-1.4-1.5-1.4-3.2 0-.8.4-1.7 1.2-2.1.7-.4 2.6-1.5 1.8-4.1-.3-1.1 3.8 4.1 3.6 6.3-.2 2.2-2.4 2.9-2.3 3.2.1.4 2.1-.4 3.8-1.8 1.2-.9 2.1-2.2 2-4.1-.1-2.1-1.5-4.9-5.4-8.7-1.1-1-3.2-3.8-3.7-3.2-.6.5.3 1.3.2 3-.1 1.7-.3 3.4-2.4 5.3-2.1 1.8-3.1 5.5-1 7.6.7.8 3.2 2.1 3.6 1.8z"
    />
    <ellipse fill="#FE9646" cx={125.3} cy={45.2} rx={5.1} ry={5.4} />
    <path
      fill="#FFC82B"
      d="M123.8 50.5c.3-.3-1.4-1.5-1.4-3.2 0-.8.4-1.7 1.2-2.1.7-.4 2.6-1.5 1.8-4.1-.3-1.1 3.8 4.1 3.6 6.3-.2 2.2-2.4 2.9-2.3 3.2.1.4 2.1-.4 3.8-1.8 1.2-.9 2.1-2.2 2-4.1-.1-2.1-1.5-4.9-5.4-8.7-1.1-1-3.2-3.8-3.7-3.2-.6.5.3 1.3.2 3-.1 1.7-.3 3.4-2.4 5.3-2.1 1.8-3.1 5.5-1 7.6.7.8 3.3 2.1 3.6 1.8z"
    />
    <path
      fill="#F9BCB6"
      d="M80.9 95.7c6.3 4.3 12.8 8.6 19.5 8.6 6.7 0 13.2-4.4 19.5-8.6 5.9-4 11.9-8.1 17.6-8.1 4.1 0 8.5 2.2 12.8 4.9v-11c0-2.4-2-4.4-4.4-4.4H54.7c-2.4 0-4.4 2-4.4 4.4v11.2c4.4-2.8 8.8-5.1 13-5.1 5.7 0 11.7 4.1 17.6 8.1z"
    />
    <path
      fill="#F9BCB6"
      d="M137.4 94.1c-3.7 0-9.1 3.7-13.9 6.9-7.1 4.8-14.4 9.8-23.1 9.8-8.7 0-16-5-23.1-9.7-4.8-3.3-10.3-7-13.9-7-3.4 0-8.4 3.2-13 6.3v20.2h99.9v-20.3c-4.6-3-9.5-6.2-12.9-6.2z"
    />
    <path
      fill="#32BBA9"
      d="M159.3 120.6h-118c-1 0-1.8.8-1.8 1.8v4.6c0 1 .8 1.8 1.8 1.8h118c1 0 1.8-.8 1.8-1.8v-4.6c-.1-1-.8-1.8-1.8-1.8z"
    />
    <path
      fill="#FFF"
      d="M77.3 101.1c7.1 4.8 14.4 9.7 23.1 9.7 8.7 0 16-5 23.1-9.8 4.8-3.2 10.3-6.9 13.9-6.9 3.4 0 8.3 3.1 12.8 6.2v-7.8c-4.3-2.7-8.7-4.9-12.8-4.9-5.6 0-11.7 4.1-17.6 8.1-6.3 4.2-12.8 8.6-19.5 8.6-6.7 0-13.2-4.4-19.5-8.6-5.9-4-11.9-8.1-17.6-8.1-4.2 0-8.6 2.3-13 5.1v7.8c4.6-3.1 9.6-6.3 13-6.3 3.8-.1 9.2 3.6 14.1 6.9z"
    />
  </svg>
);

export default AgeIcon;
