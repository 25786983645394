import { isDefined } from '@health-activity-ui/utils';
import classnames from 'classnames';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Timer } from '../atoms';
import { ZenTimerProps, ZenTimerState } from '../timer.interface';
/**
 * @name ZenTimer
 *
 * @description A reusable component that displays the current days/hours/minutes/seconds left
 * from a specific date. ZenTimer uses compound component and provider pattern allowing for the user of the
 * component granularity over what values to display. Each compound component accepts an optional
 * label prop otherwise displays a default label value (e.g. <ZenTimerDays label={'D'} />).
 *
 * @property {Date | string | number} endDate The date/time ZenTimer will tick down to.
 * @property {boolean} isDarkFont Defaults to a light font color otherwise dark font color.
 *
 * @example
 * <ZenTimer endDate={'september 10, 2020 21:00:00'}>
 *   <ZenTimerDays />
 *   <ZenTimerHours />
 *   <ZenTimerMinutes />
 *   <ZenTimerSeconds />
 * </ZenTimer>
 * */

export const ZenTimerContext = createContext<ZenTimerState>({
  days: 0,
  hours: 0,
  minutes: 0,
  seconds: 0,
});

ZenTimerContext.displayName = 'ZenTimerContext';

export const useZenTimerContext = () => {
  const context = useContext(ZenTimerContext);
  if (!context) {
    throw new Error(`useZenTimerContext must be used within the ZenTimerProvider`);
  }

  return context;
};

export const ZenTimer = (props: ZenTimerProps) => {
  const { t } = useTranslation();
  const [timeLeft, setTimeLeft] = useState<ZenTimerState>({ days: 0, hours: 0, minutes: 0, seconds: 0 });
  const { children, isDarkFont = true, className = '' } = props;
  const { days, hours, minutes, seconds } = timeLeft;
  const calculateTimeLeft = useCallback((): void => {
    const now: number = new Date().getTime();
    const timeDiff: number = new Date(props?.endDate).getTime() - now;

    if (timeDiff < 0 && timeDiff > -9000) {
      const { cbUpdate } = props;
      if (isDefined(cbUpdate) && typeof cbUpdate === 'function') {
        cbUpdate();
      }
    }

    if (timeDiff < 0) {
      setTimeLeft({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      });
    } else {
      const days: number = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
      const hours: number = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes: number = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds: number = Math.floor((timeDiff % (1000 * 60)) / 1000);

      setTimeLeft({
        days,
        hours,
        minutes,
        seconds,
      });
    }
  }, [props]);

  useEffect(() => {
    const timer = setInterval(calculateTimeLeft, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [calculateTimeLeft]);

  return (
    <ZenTimerContext.Provider value={timeLeft}>
      <Timer
        as="span"
        isDarkFont={isDarkFont}
        className={classnames(
          'is-inline-flex is-align-items-baseline',
          {
            'has-rds-text-charcoal-light': isDarkFont,
            'has-rds-text-white': !isDarkFont,
          },
          className
        )}
      >
        <span className="is-sr-only">
          {t('day', { count: days })} {t('hour', { count: hours })} {t('minute', { count: minutes })}{' '}
          {t('second', { count: seconds })}
        </span>
        {children}
      </Timer>
    </ZenTimerContext.Provider>
  );
};
