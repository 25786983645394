import React from 'react';

interface Props {
  width?: number;
  height?: number;
}

export const SleepIcon = ({ width = 200, height = 165 }: Props): React.ReactElement => (
  <svg viewBox="0 0 200 165" width={width} height={height}>
    <path fill="#E5E5E4" d="M179.8 133c0 1.3-25.3 2.3-73.1 2.3s-86.6-1-86.6-2.3 38.8-2.3 86.6-2.3 73.1 1.1 73.1 2.3z" />
    <path fill="#7F7F7F" d="M159.4 101.4h12.5v31.1h-12.5zm-131.3 0h12.3v31.1H28.1z" />
    <path fill="#999" d="M171.9 107.4H28.1V57.5h7.7v42.2h128.3V76.1h7.8z" />
    <path fill="#E5E5E5" d="M35.8 86.4h126.8v13.8H35.8z" />
    <path
      fill="#E4BA90"
      d="M70 82.8c2.3.5 4.5-.9 5-3.2.5-2.3-.9-4.5-3.2-5l-8.4-1.9c-2.3-.5-4.5.9-5 3.2-.5 2.3.9 4.5 3.2 5l8.4 1.9z"
    />
    <path
      fill="#E4BA90"
      d="M49.6 75.8c.7 5.1 5.4 8.6 10.5 7.9 5.1-.7 8.6-5.5 7.9-10.5-.7-5.1-5.4-8.6-10.5-7.9-5.2.7-8.7 5.4-7.9 10.5z"
    />
    <path
      fill="#D6A56C"
      d="M57.4 65.2c-4.3.6-7.5 4.2-7.9 8.4 1.2-3 3.9-5.4 7.3-5.9 5.1-.7 9.8 2.8 10.5 7.9 0 .2 0 .4.1.6l3.9.9c1.8.4 3.1 2 3.3 3.8.2-.4.4-.8.5-1.2.5-2.3-.9-4.5-3.2-5l-3.9-1c0-.2 0-.4-.1-.6-.7-5.1-5.4-8.6-10.5-7.9z"
    />
    <path
      fill="#796041"
      d="M55 64.8s.5 5.7-1 7.4c-1.5 1.7-2.4 1.6-.5 2.2 7.4 2.3 10.5 6.8 8.9 10.3-.7 1.6-12.8 3.7-15.8-7.4 0 0-2.6-10.5 8.4-12.5z"
    />
    <path
      fill="#F2F2F2"
      d="M69.4 85c-1.5 1-3.2 1.5-5 1.5h-19c-1.6 0-3.2-.4-4.6-1.3l-3.9-2.3 3.7-2.2c1.4-.9 3.1-1.3 4.7-1.3h19.1c1.8 0 3.5.5 5 1.5l3.2 2.1-3.2 2z"
    />
    <path
      fill="#EF5B4B"
      d="M67.3 76.7v6.1c0 2.4 2 4.4 4.4 4.4h40.9c2.4 0 4.4-2 4.4-4.4V74c0-2.4-2-4.4-4.4-4.4H83.3c-2.4 0-16 3.4-16 7.1z"
    />
    <path fill="#EF5B4B" d="M71.7 78.6h6.9v8.9h-6.9z" />
    <path fill="#8DD1CB" d="M147.2 69.6H83.8v42.1h80.3V86.5c0-9.3-7.6-16.9-16.9-16.9z" />
    <circle transform="rotate(-40.205 104.921 70.9)" fill="#D6A56C" cx={104.9} cy={70.9} r={4.4} />
    <circle transform="rotate(-40.205 90.525 83.068)" fill="#D6A56C" cx={90.5} cy={83.1} r={4.4} />
    <path fill="#D6A56C" d="M87.627 79.639l14.358-12.136 5.745 6.797-14.358 12.136z" />
    <path
      fill="#D6A56C"
      d="M98.6 70.2c-.2.7.3 1.4 1 1.6.7.2 1.4-.3 1.6-1l.7-2.8c.2-.7-.3-1.4-1-1.6-.7-.2-1.4.3-1.6 1l-.7 2.8z"
    />
    <path
      fill="#E4BA90"
      d="M61.6 70.9l1.6-2.2c.5-.7-.1-1.6-.9-1.5l-3.8.6c-.8.1-1.1 1.2-.4 1.7l2.2 1.6c.4.4 1 .3 1.3-.2z"
    />
    <path fill="#D6A56C" d="M78.5 78.6h12v8.9h-12z" />
    <circle fill="#EF5B4B" cx={71.7} cy={83.1} r={4.4} />
  </svg>
);

export default SleepIcon;
