import styled from '@emotion/styled';
import {
  RDS_BORDER_RADIUS_8,
  RDS_COLOR_NEUTRAL_30,
  RDS_COLOR_NEUTRAL_80,
  RDS_COLOR_SYSTEM_CERULEAN,
  RDS_SPACING_SIZE_16,
  RDS_SPACING_SIZE_32,
  RDS_UNIT_SIZE_1,
  RDS_UNIT_SIZE_3,
} from '@rally/ui-themes/dist/web/rally';

export const CarouselSelectorInputStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .carousel {
    height: 100px;
    position: relative;
  }

  .controls {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 8px;
    position: relative;
  }

  .carousel-item {
    border: 1px solid #ffffff;
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    height: 100px;
    overflow: hidden;
    position: absolute;
    transition: all 500ms ease;
    width: 100px;

    &.selected {
      border: 6px solid ${RDS_COLOR_SYSTEM_CERULEAN};
    }

    /* HIDE RADIO */
    [type='radio'] {
      height: 0;
      opacity: 0;
      position: absolute;
      width: 0;
    }
  }

  /* IMAGE STYLES */
  .carousel-item-image {
    background-color: #ffffff;
    object-fit: cover;
    position: relative;
  }

  .size-three {
    transform: scale(0.01);
    z-index: 1;
    & .carousel-item-image {
      opacity: 0.001;
    }
  }

  .size-two {
    transform: scale(0.4);
    z-index: 2;
    & .carousel-item-image {
      opacity: 0.55;
    }
  }

  .size-one {
    transform: scale(0.7);
    z-index: 3;
    & .carousel-item-image {
      opacity: 0.8;
    }
  }

  .center-zero {
    left: calc(50% - 50px);
    right: unset;
    transform: scale(1);
    z-index: 4;
    & .carousel-item-image {
      opacity: 1;
    }
  }

  .left-side.size-three {
    left: calc(1% - 50px);
  }

  .left-side.size-two {
    left: calc(15% - 50px);
  }

  .left-side.size-one {
    left: calc(33% - 50px);
  }

  .right-side.size-one {
    left: calc(67% - 50px);
  }

  .right-side.size-two {
    left: calc(85% - 50px);
  }

  .right-side.size-three {
    left: calc(99% - 50px);
  }

  .slider {
    appearance: none;
    background-color: ${RDS_COLOR_NEUTRAL_80};
    border: 0;
    height: ${RDS_UNIT_SIZE_1};
    outline: none;
    width: 100%;

    &::-webkit-slider-runnable-track {
      appearance: none;
      border: 0;
      outline: none;
    }

    &::-moz-range-track {
      appearance: none;
      border: 0;
      outline: none;
    }

    &::-moz-range-thumb {
      appearance: none;
      background-color: #ffffff;
      border: ${RDS_UNIT_SIZE_3} solid ${RDS_COLOR_NEUTRAL_30};
      border-radius: ${RDS_BORDER_RADIUS_8};
      height: ${RDS_SPACING_SIZE_16};
      outline: none;
      width: ${RDS_SPACING_SIZE_32};
    }

    &::-webkit-slider-thumb {
      appearance: none;
      background-color: #ffffff;
      border: ${RDS_UNIT_SIZE_3} solid ${RDS_COLOR_NEUTRAL_30};
      border-radius: ${RDS_BORDER_RADIUS_8};
      height: ${RDS_SPACING_SIZE_16};
      outline: none;
      width: ${RDS_SPACING_SIZE_32};
    }

    &:focus {
      appearance: none;
      background-color: ${RDS_COLOR_SYSTEM_CERULEAN};
      border: 0;
      outline: none;

      &::-webkit-slider-thumb {
        background-color: ${RDS_COLOR_SYSTEM_CERULEAN};
        border: 0;
        outline: 0;
      }

      &::-moz-range-thumb {
        background-color: ${RDS_COLOR_SYSTEM_CERULEAN};
        border: 0;
        outline: 0;
      }

      &::-webkit-slider-runnable-track {
        appearance: none;
      }
      &::-moz-range-track {
        appearance: none;
      }
    }
  }
`;
