/**
 * Dynamically load external scripts and trigger an event for when they are loaded
 */

interface loadScriptToHeadProps {
  src: string;
  id: string;
  async?: boolean;
  type?: 'text/javascript' | 'module';
  onLoadHandler?: () => void;
  onErrorHandler?: () => void;
  windowConfigName?: string;
  windowConfig?: Record<string, unknown>;
}

function loadScriptToHead({
  src,
  id,
  type = 'text/javascript',
  async = true,
  onLoadHandler,
  onErrorHandler,
  windowConfigName,
  windowConfig,
}: loadScriptToHeadProps): Promise<void> {
  if (!src) {
    return;
  }

  return new Promise((resolve, reject) => {
    if (windowConfigName) {
      window[windowConfigName] = windowConfig;
    }
    // check to see if script src already exists
    const resource: HTMLScriptElement = document.querySelector(`script[src="${src}"]`);
    if (!resource) {
      // Create script
      const script: HTMLScriptElement = Object.assign(document.createElement('script'), {
        src,
        id,
        type,
        async,
        onload: () => {
          resolve(window[`${id}`]);
          if (onLoadHandler) {
            onLoadHandler();
          }
        },
        onerror: () => {
          reject(new Error(`Script: ${src} not loaded`));
          if (onErrorHandler) {
            onErrorHandler();
          }
        },
      });

      // Add script to head -  If you require a script to be added to body use a hook
      // since that indicates your script only needs to load for a specific component within React.
      // this is specifically for loading an external script before react.
      document.head.appendChild(script);
    }
  });
}

export default loadScriptToHead;
