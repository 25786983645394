import React from 'react';

interface Props {
  width?: number;
  height?: number;
}

export const ArthritisIcon = ({ width = 200, height = 165 }: Props): React.ReactElement => (
  <svg viewBox="0 0 200 165" width={width} height={height}>
    <ellipse fill="#E5E5E4" cx={78.6} cy={141} rx={40.2} ry={2.6} />
    <path
      fill="#D6A56C"
      d="M55.5 51.2c-.2 2.8 2 5.1 4.7 5.3 2.8.2 5.1-2 5.3-4.7l.6-10.2c.2-2.8-2-5.1-4.7-5.3-2.8-.2-5.1 2-5.3 4.7l-.6 10.2z"
    />
    <path
      fill="#C19562"
      d="M65.1 23.6c2.8 1.5 4.8 4.4 4.9 7.8l.2 5.3c.2 5.1-3.8 9.3-8.9 9.5-.7 0-1.3 0-2-.1 1.4.7 3 1.1 4.6 1.1 5.1-.2 9.1-4.4 8.9-9.5l-.2-5.3c-.1-4.5-3.3-8-7.5-8.8z"
    />
    <path
      fill="#D6A56C"
      d="M70.2 36.7l-.2-5.3c-.1-3.4-2.1-6.3-4.9-7.8-.6-.1-1.3-.2-2-.1-5.1.2-9.1 4.4-8.9 9.5l.2 5.3c.1 3.4 2.1 6.3 4.9 7.8.6.1 1.3.2 2 .1 5-.1 9-4.4 8.9-9.5z"
    />
    <path
      fill="#D6A56C"
      d="M67.6 37.6l2.8 1.1c.9.4 1.7-.5 1.4-1.4l-1.6-3.9c-.4-.9-1.6-.9-1.9 0L67 36.2c-.2.6.1 1.2.6 1.4z"
    />
    <path
      fill="#999"
      d="M68.1 24.7s-1.6.8-3.8 0c-2.3-.8-5.9 2.5-5.6 4.5.3 2 1.2 3.8 1.2 3.8l-1.1 1s-1.4 6-4.1 6.2c0 0-2.6-.4-2.7-5.4-.1-5 .9-9.7 5.3-10.9 0 0 6-6.1 10.8.8z"
    />
    <path fill="#D6A56C" d="M56.8 34.7c-.4 1.1.1 2.2 1.1 2.7l1.5-3.9c-1-.4-2.1.2-2.6 1.2z" />
    <circle cx={91} cy={106.2} r={6.9} />
    <path fill="#D6A56C" d="M84.1 106.2h13.8v28.5H84.1z" />
    <circle cx={62.8} cy={106.2} r={6.9} />
    <circle fill="#D6A56C" cx={91} cy={106.2} r={6.9} />
    <path fill="#D6A56C" d="M62.8 99.3H91v13.8H62.8z" />
    <path fill="#C19562" d="M33.919 130.343l4.693-12.978 26.52 9.59-4.694 12.978z" />
    <circle fill="#47BEB0" cx={62.8} cy={106.2} r={6.9} />
    <circle transform="rotate(-70.117 62.792 133.396)" fill="#C19562" cx={62.8} cy={133.4} r={6.9} />
    <path fill="#C19562" d="M55.9 105.2h13.8v28.2H55.9z" />
    <path fill="#666" d="M55.9 112.1h13.8v8.9H55.9z" />
    <path
      fill="#00B2E2"
      d="M64.4 48.3H54.1c-2.8 0-5.1 2.3-5.1 5.1v47.7c0 2.8 2.3 5.1 5.1 5.1h10.3c2.8 0 5.1-2.3 5.1-5.1V53.5c.1-2.9-2.2-5.2-5.1-5.2z"
    />
    <circle fill="#36C8B5" cx={128.6} cy={82.6} r={47.5} />
    <path
      fill="#FFF"
      d="M130.4 57.6H88.3l-2.6 4.6c-.9 1.7-1.8 4.1-2.3 5.9l-1.2 4.5h26.1c3.8 0 7.1 2.4 8.6 5.8 2.3 5.4 7.6 9.2 13.8 9.2 8.5 0 15.3-7 15-15.6-.2-8.1-7.2-14.4-15.3-14.4z"
    />
    <path
      fill="#FFF"
      d="M146.3 91.4l-.2 35.4-3.2 1.2c-2.6.9-5.2 1.3-7.9 1.7l-3.9.3.1-16.6c0-3.8-2.3-7.1-5.8-8.6-5.4-2.3-9.1-7.7-9.1-13.9 0-7.4 6.3-1.3 14.9-1.3 8.4-.1 15.1-6.4 15.1 1.8z"
    />
    <path fill="#289384" d="M34.6 129.8c2.3-6.4 2-12.4-.5-14.2l-8.9 24.7c3 .4 7-4.1 9.4-10.5z" />
    <path fill="#289384" d="M29.624 128.123l4.489-12.414 4.984 1.803-4.49 12.413z" />
    <path fill="#FFF" d="M23.971 139.914l8.91-24.639 1.13.408-8.912 24.639z" />
    <path fill="#7F7F7F" d="M55.9 99.3h23.5v13.8H55.9z" />
    <circle fill="#7F7F7F" cx={55.9} cy={106.2} r={6.9} />
    <path fill="#7F7F7F" d="M49 99.3h7.2v7H49z" />
    <path fill="#289384" d="M97.2 134.4c-6.8 0-12.4 2.3-13.1 5.3h26.3c-.8-3-6.4-5.3-13.2-5.3z" />
    <path fill="#289384" d="M84.1 134.4h13.2v5.3H84.1z" />
    <path fill="#FFF" d="M84.1 139.7h26.2v1.2H84.1z" />
    <circle transform="rotate(-45.001 53.822 84.57)" fill="#D6A56C" cx={53.8} cy={84.6} r={5.1} />
    <circle transform="rotate(-45.001 38.27 69.017)" fill="#D6A56C" cx={38.3} cy={69} r={5.1} />
    <circle transform="rotate(-45.001 53.822 53.465)" fill="#00B2E2" cx={53.8} cy={53.5} r={5.1} />
    <path fill="#D6A56C" d="M34.634 72.659l7.283-7.283 15.556 15.556-7.283 7.283z" />
    <path fill="#D6A56C" d="M34.62 65.385l9.97-9.97 7.283 7.283-9.97 9.97z" />
    <path fill="#00B2E2" d="M44.494 55.444l5.656-5.657 7.284 7.283-5.657 5.657z" />
    <path
      fill="#D6A56C"
      d="M54 77.1c-.8-.1-1.6.4-1.8 1.3-.1.8.4 1.6 1.3 1.8l3.3.6c.8.1 1.6-.4 1.8-1.3.1-.8-.4-1.6-1.3-1.8l-3.3-.6z"
    />
  </svg>
);

export default ArthritisIcon;
