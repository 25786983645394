import styled from '@emotion/styled';
import { RDS_COLOR_NEUTRAL_80, RDS_COLOR_NEUTRAL_98 } from '@rally/ui-themes/dist/web/rally';
import React from 'react';

interface StyledProps {
  borderSize?: number;
  height?: number;
  width?: number;
  borderColorPrimary?: string;
  borderColorSecondary?: string;
}

interface Props extends StyledProps {
  show?: boolean;
}

const SpinnerLoaderStyled = styled.div<StyledProps>`
  animation: spin 1s linear infinite;
  border: ${({ borderSize }) => borderSize}px solid ${({ borderColorSecondary }) => borderColorSecondary};
  border-radius: 50%;
  border-top: ${({ borderSize }) => borderSize}px solid ${({ borderColorPrimary }) => borderColorPrimary};
  height: ${({ height }) => height}px;
  width: ${({ width }) => width}px;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const SpinnerLoader = ({
  show = true,
  height = 27,
  width = 27,
  borderSize = 4,
  borderColorPrimary = RDS_COLOR_NEUTRAL_98,
  borderColorSecondary = RDS_COLOR_NEUTRAL_80,
}: Props) =>
  show ? (
    <SpinnerLoaderStyled
      height={height}
      width={width}
      borderSize={borderSize}
      borderColorPrimary={borderColorPrimary}
      borderColorSecondary={borderColorSecondary}
      aria-disabled={true}
    />
  ) : null;
