import styled from '@emotion/styled';

// Variables
const avatarSizeBase = 3;
const avatarSizeXs = 1.625;
const avatarSizeSm = 2.5;
const avatarSizeLg = 4;
const avatarSizeXl = 5.125;
const avatarSizeXxl = 8;
const AvatarGroupContainer = styled.div`
  .avatar {
    font-size: ${avatarSizeBase / 3}rem;
    position: relative;

    &:nth-child(1) {
      z-index: 1;
    }

    &:nth-child(2) {
      z-index: 2;
    }

    &:nth-child(3) {
      z-index: 3;
    }

    &:nth-child(4) {
      z-index: 4;
    }

    &:hover {
      mask-image: none;
      z-index: 5;
    }
  }

  .avatar-image {
    height: 100%;
    width: 100%;
  }

  .avatar-xs {
    font-size: ${avatarSizeXs / 3}rem;
    height: ${avatarSizeXs}rem;
    width: ${avatarSizeXs}rem;

    img {
      height: ${avatarSizeXs}rem;
      width: ${avatarSizeXs}rem;
    }
  }

  .avatar-sm {
    font-size: ${avatarSizeSm / 3}rem;
    height: ${avatarSizeSm}rem;
    width: ${avatarSizeSm}rem;

    img {
      height: ${avatarSizeSm}rem;
      width: ${avatarSizeSm}rem;
    }
  }

  .avatar-lg {
    font-size: ${avatarSizeLg / 3}rem;
    height: ${avatarSizeLg}rem;
    width: ${avatarSizeLg}rem;

    img {
      height: ${avatarSizeLg}rem;
      width: ${avatarSizeLg}rem;
    }
  }

  .avatar-xl {
    font-size: ${avatarSizeXl / 3}rem;
    height: ${avatarSizeXl}rem;
    width: ${avatarSizeXl}rem;

    img {
      height: ${avatarSizeXl}rem;
      width: ${avatarSizeXl}rem;
    }
  }

  .avatar-xxl {
    font-size: ${avatarSizeXxl / 3}rem;

    img {
      height: ${avatarSizeXxl}rem;
      width: ${avatarSizeXxl}rem;
    }
  }

  .avatar-group {
    .avatar-xs + .avatar-xs {
      margin-left: -${avatarSizeXs / 4}rem;
    }

    .avatar-sm + .avatar-sm {
      margin-left: -${avatarSizeSm / 4}rem;
    }

    .avatar-lg + .avatar-lg {
      margin-left: -${avatarSizeLg / 4}rem;
    }

    .avatar-xl + .avatar-xl {
      margin-left: -${avatarSizeXl / 4}rem;
    }

    .avatar-xxl + .avatar-xxl {
      margin-left: -${avatarSizeXxl / 4}rem;
    }
  }
`;

export default AvatarGroupContainer;
