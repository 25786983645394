import { Status } from '@health-activity-ui/shared';
import { useCallback, useReducer, useRef } from 'react';
import { AsyncState } from './use-async.models';
import asyncReducer from './use-async.reducer';
import { useSafeDispatch } from '../use-safe-dispatch.hook';

/*
 A custom hook for consuming api's that do not need to be shared via context that has been converted to Typescript.

 More info:
 - https://headbutt.io/picking-apart-kents-useasync/
 - https://github.com/kentcdodds/react-performance/blob/main/src/utils.js#L22
 */

function useAsync<D>(initialState: AsyncState<D> = { status: Status.initial, data: null, error: null }) {
  const initialStateRef = useRef({ ...initialState });
  const [state, unsafeDispatch] = useReducer(asyncReducer, initialState);
  const { data, error, status } = state as AsyncState<D>;
  const dispatch = useSafeDispatch(unsafeDispatch);

  const run = useCallback(
    (promise: Promise<D>) => {
      if (!promise || !promise.then) {
        throw new Error(`The argument supplied must be a promise`);
      }
      dispatch({ type: Status.loading });
      promise.then(
        (data: D) => {
          dispatch({ type: Status.loaded, data });
        },
        (error: unknown) => {
          dispatch({ type: Status.error, error });
        }
      );
    },
    [dispatch]
  );

  const setData = useCallback((data) => dispatch({ type: Status.loaded, data }), [dispatch]);
  const setError = useCallback((error) => dispatch({ type: Status.error, error }), [dispatch]);
  const reset = useCallback(() => dispatch(initialStateRef?.current), [dispatch]);

  return {
    isInitial: status === Status.initial,
    isLoading: status === Status.loading,
    isError: status === Status.error,
    isLoaded: status === Status.loaded,
    setData,
    setError,
    error,
    status,
    data,
    run,
    reset,
  };
}

export default useAsync;
