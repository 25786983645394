import { useSafeDispatch } from '@health-activity-ui/core';
import { IGalleryChallenge } from '@health-activity-ui/shared';
import { isDefined } from '@health-activity-ui/utils';
import { RdsIcon } from '@rally/energon-react/dist/es6';
import classnames from 'classnames';
import React, {
  createContext,
  Dispatch,
  ReactElement,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { animated, useSpring } from 'react-spring';
import { Col, Columns } from '../grid';
import { Level, LevelSide } from '../layout';
import { Nav } from './atoms';
import {
  CarouselColumnSizes,
  CarouselContentProps,
  CarouselProps,
  CarouselState,
  ContentProps,
} from './carousel.interface';

/**
 * @name Carousel
 *
 * @desc Carousel uses the provider and child component. The component exposes
 * 2 child components: 1. Nav and 2. Content. The HOC component accepts a React component passed as a prop in the
 * Content component and statically creates new components based on the itemsPerPage and current location of
 * the carousel navigation.
 *
 * 1. Nav Component: Navigation and Title for carousel
 *
 * 2. Content Component:
 * @property {ComponentType} component Custom component passed in from the user to be rendered in the content section
 * @property {any} componentProps props to be passed to the custom component
 * @property {string} title Carousel title
 *
 *
 * https://codesandbox.io/s/react-carousel-e6i7x
 *
 * @property {any[]} data The data to be consumed by the custom React Component passed to the Content component
 * @property {number} itemsPerPage The number of slides (components) to be shown in the Content component
 *
 * @example
 * <Carousel data={data} itemsPerPage={3} title="Title">
 *   <CarouselNav />
 *   <CarouselContent component={Card} />
 *</Carousel>
 * */

const setColumnSize = (itemsPerPage: number): CarouselColumnSizes => {
  let columnSize: CarouselColumnSizes;
  switch (itemsPerPage) {
    case 1:
      columnSize = '12';
      break;
    case 2:
      columnSize = '6';
      break;
    case 3:
      columnSize = '4';
      break;
    case 4:
      columnSize = '3';
      break;
    default:
      columnSize = '4';
  }
  return columnSize;
};
const config = {
  mass: 1.3,
  tension: 55,
  friction: 12,
};

const CarouselAnimatedContent = ({ children, currentStart, isNext }: CarouselContentProps): React.ReactElement => {
  const isInitRenderRef = useRef(true);

  useEffect(() => {
    isInitRenderRef.current = false;
    return () => {
      isInitRenderRef.current = true;
    };
  });

  const [props, unsafeSetProps] = useSpring(() => ({
    to: { opacity: 1, transform: 'translate3d(0,0,0)' },
    from: {},
    delay: 500,
    reset: true,
    config,
  }));

  const setProps = useSafeDispatch(unsafeSetProps);

  useEffect(() => {
    const to = isInitRenderRef.current ? {} : { opacity: 1, transform: 'translate3d(0,0,0)' };
    const from = isInitRenderRef.current
      ? {}
      : isNext
      ? { opacity: 0, transform: 'translate3d(50%,0,0)' }
      : { opacity: 0, transform: 'translate3d(-50%,0,0)' };

    setProps({
      to: to,
      from,
      reset: true,
      config,
    });
  }, [currentStart, isInitRenderRef, isNext, setProps]);

  return (
    <animated.div style={props} id="carousel" className="has-rds-mb-24">
      {children}
    </animated.div>
  );
};

const initialState = {
  currentStart: 0,
  currentEnd: 0,
  isNext: false,
};

const CarouselContext = createContext<{
  state: CarouselState;
  setState: Dispatch<SetStateAction<CarouselState>>;
  data: IGalleryChallenge[];
  itemsPerPage: number;
}>(undefined);

CarouselContext.displayName = 'Carousel';

export const useCarouselContext = () => {
  const context = useContext(CarouselContext);
  if (!context) {
    throw new Error(`CarouselContext must be used within the CarouselProvider`);
  }

  return context;
};

export const CarouselContent = ({
  component,
  componentProps,
  ComponentLoader,
  title,
}: ContentProps): React.ReactElement => {
  const { state, itemsPerPage, data } = useCarouselContext();
  const { currentStart, isNext, currentEnd } = state;
  const [newData, setNewData] = useState<IGalleryChallenge[]>(data.slice(0, itemsPerPage));

  useEffect(() => {
    setNewData(data.slice(currentStart, currentEnd));
  }, [currentEnd, currentStart, data, setNewData]);

  const Component = component;
  return (
    <CarouselAnimatedContent currentStart={currentStart} isNext={isNext}>
      <Columns
        id={`carousel-content-${title?.split(' ').join('-')}`}
        className={classnames({
          'is-justify-content-center': itemsPerPage === 1,
          'is-justify-content-start': itemsPerPage !== 1,
        })}
      >
        {isDefined(newData[0])
          ? newData.map(
              (item): React.ReactElement => (
                <Col key={item?.challengeId} size={setColumnSize(itemsPerPage)}>
                  <Component {...item} {...componentProps} />
                </Col>
              )
            )
          : isDefined(ComponentLoader) && ComponentLoader}
      </Columns>
    </CarouselAnimatedContent>
  );
};

export const CarouselNav = (props): ReactElement => {
  const { state, setState, data, itemsPerPage } = useCarouselContext();
  const { title, isSectionTitle } = props;
  const { currentStart, currentEnd } = state;
  const total = data.length;
  const { t } = useTranslation();

  const navigateNext = useCallback(() => {
    let { currentStart, currentEnd } = state;
    currentStart = currentEnd;
    currentEnd = currentEnd + itemsPerPage;

    setState({
      ...state,
      currentStart,
      currentEnd,
      isNext: true,
    });
  }, [itemsPerPage, setState, state]);

  const navigatePrevious = useCallback(() => {
    let { currentStart, currentEnd } = state;
    currentEnd = currentStart;
    currentStart = currentStart - itemsPerPage;

    setState({
      ...state,
      currentStart,
      currentEnd,
      isNext: false,
    });
  }, [itemsPerPage, setState, state]);

  return (
    <Nav className="is-flex is-justify-content-space-between is-align-items-center has-rds-mb-16">
      <h2
        className={classnames('carousel-title has-rds-mb-none', {
          'is-rds-body-1': !isSectionTitle,
          'is-rds-h6': isSectionTitle,
        })}
      >
        {title}
      </h2>
      <Level className="is-align-items-center is-rds-body-2 is-rds-lh-24" isMobile>
        <LevelSide
          side="left"
          className="carousel-slide-info has-rds-pr-16 has-rds-border-neutral-80"
          aria-label={t('carouselSlideInfo')}
        >
          {currentStart + 1} - {currentEnd > total ? total : currentEnd} {t('of')} {total}
        </LevelSide>
        <LevelSide
          className="button-group has-rds-ml-16"
          side="right"
          aria-label={t('carouselButtons')}
          aria-controls="carousel"
        >
          <button
            className="previous btn-no-style has-rds-mr-16 is-flex is-align-items-center"
            aria-label={t('previous')}
            disabled={!currentStart}
            onClick={navigatePrevious}
          >
            <RdsIcon name="system-pagination-arrow-left" size="16" />
          </button>
          <button
            className="next btn-no-style is-flex is-align-items-center"
            aria-label={t('next')}
            disabled={currentEnd >= total}
            onClick={navigateNext}
          >
            <RdsIcon name="system-pagination-arrow-right" size="16" />
          </button>
        </LevelSide>
      </Level>
    </Nav>
  );
};

export const Carousel = (props: CarouselProps) => {
  const [state, unsafeSetState] = useState(initialState);
  const setState = useSafeDispatch(unsafeSetState);
  const { data, itemsPerPage } = props;

  useEffect(() => {
    setState({
      ...initialState,
      currentEnd: itemsPerPage,
    });
  }, [itemsPerPage, setState]);

  const { children } = props;

  const value = useMemo(
    () => ({
      state,
      setState,
      data,
      itemsPerPage,
    }),
    [state, setState, data, itemsPerPage]
  );

  return <CarouselContext.Provider value={value}>{children}</CarouselContext.Provider>;
};

export default Carousel;
