import { css } from '@emotion/react';
import { createAvatarSrc, getFirstLetter, mediaTargets } from '@health-activity-ui/utils';
import classnames from 'classnames';
import React, { useState } from 'react';

interface Props {
  userId: string;
  displayName: string;
  size?: '32' | '40' | '64' | '88' | '144';
  className?: string;
  url?: string;
}

const cssStyles = `
  display: block;

  &.is-32x32,
  &.is-32x32-mobile,
  .is-32x32 {
    height: 32px;
    line-height: 32px;
    width: 32px;
  }

  &.is-40x40,
  &.is-40x40-mobile,
  .is-40x40 {
    height: 40px;
    line-height: 40px;
    width: 40px;
  }

  &.is-64x64,
  .is-64x64 {
    height: 64px;
    line-height: 64px;
    width: 64px;

    @media ${mediaTargets.touch} {
      &.touch {
        height: 40px !important;
        line-height: 40px !important;
        width: 40px !important;
      }
    }
  }

  &.is-88x88,
  .is-88x88 {
    height: 88px;
    line-height: 88px;
    width: 88px;
    font-size: var(--rds-font-size-36);

    @media ${mediaTargets.touch} {
      &.touch {
        height: 64px !important;
        line-height: 64px !important;
        width: 64px !important;
        font-size: var(--rds-font-size-36) !important;
      }
    }
  }

  &.is-144x144,
  .is-144x144 {
    height: 144px;
    line-height: 144px;
    width: 144px;
    font-size: 104px;

    @media ${mediaTargets.touch} {
      &.touch {
        height: 120px !important;
        line-height: 120px !important;
        width: 120px !important;
        font-size: 80px !important;
      }
    }
  }
`;

export const Avatar = ({ userId, displayName, size = '40', className = '', url }: Props) => {
  const [errorLoadingAvatar, setErrorLoadingAvatar] = useState(false);
  const src = url ? url : createAvatarSrc(userId);
  return (
    <div
      className={classnames(className ? className : 'is-inline-block is-relative', {
        'is-32x32': size === '32',
        'is-40x40': size === '40',
        'is-64x64': size === '64',
        'is-88x88': size === '88',
        'is-144x144': size === '144',
        touch: size === '64' || size === '88' || size === '144',
      })}
      css={css`
        ${cssStyles}
      `}
    >
      {src && !errorLoadingAvatar ? (
        <img src={src} alt="" data-testid="avatar-image" onError={() => setErrorLoadingAvatar(true)} />
      ) : (
        <span
          className={classnames(
            'default-avatar has-rds-radius-circle is-rds-h5 has-rds-text-white has-rds-bg-grey is-inline-block has-text-centered',
            {
              'is-32x32': size === '32',
              'is-40x40': size === '40',
              'is-64x64': size === '64',
              'is-88x88': size === '88',
              'is-144x144': size === '144',
              touch: size === '64' || size === '88' || size === '144',
            }
          )}
        >
          {getFirstLetter(displayName)}
        </span>
      )}
    </div>
  );
};

export default Avatar;
