import {
  BorderRadiusProps,
  BoxShadowProps,
  ClassNames,
  ColorProps,
  PropsWithChildren,
  SpacingProps,
  TypographyProps,
} from '@health-activity-ui/shared';
import classnames from 'classnames';
import React from 'react';
import { Element } from '../element/element';

export interface CardProps extends ColorProps, TypographyProps, SpacingProps, ClassNames, PropsWithChildren {
  // to reference a dom element
  domRef?: Record<string, unknown>;

  // custom classes to pass into the component other than rds classes
  className?: string;

  // to create an html element to attach classes to
  renderAs?: React.ElementType;

  // WAI-ARIA
  role?: string;

  // border radius for the card
  radius?: BorderRadiusProps;

  // box shadow for the card
  shadow?: BoxShadowProps;

  // inline style
  style?: React.CSSProperties;
}

// Generates a default 'article', element with box shadow, border radius and default padding
export const RdsCard = ({
  className = undefined,
  renderAs = 'article',
  domRef = undefined,
  children = null,
  padding = '24',
  radius = '4',
  shadow = 'spread-6',
  style = undefined,
  ...allProps
}: CardProps) => (
  <Element
    {...allProps}
    renderAs={renderAs}
    className={classnames('rds-card', className, {
      [`has-rds-radius-${radius}`]: radius,
      [`has-rds-box-shadow-neutral-${shadow}`]: shadow !== 'none',
      [`has-rds-p-${padding}`]: padding,
    })}
    style={style}
  >
    {children}
  </Element>
);

export default RdsCard;
