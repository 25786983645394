import React, { createContext, useContext, useState, useEffect } from 'react';

const TabsContext = createContext(undefined);
TabsContext.displayName = 'TabsContext';

function TabsProvider({ children, defaultActiveTab = 0 }) {
  const [activeTab, setActiveTab] = useState<number>(defaultActiveTab);

  useEffect(() => {
    setActiveTab(defaultActiveTab);
  }, [defaultActiveTab]);

  return <TabsContext.Provider value={{ activeTab, setActiveTab }}>{children}</TabsContext.Provider>;
}

function useTabs() {
  return useContext(TabsContext);
}

export { TabsProvider, useTabs };
