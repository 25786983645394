import { PROFILE_ACTION_TYPES, ProfileState } from './profile.types';
import { Status } from '@health-activity-ui/shared';
import { ProfileActions } from './profile.actions';

export const profileReducer = (state: ProfileState, action: ProfileActions): ProfileState => {
  switch (action.type) {
    case PROFILE_ACTION_TYPES.PROFILE_FETCH_LOADING: {
      return {
        ...state,
        status: Status.loading,
      };
    }

    case PROFILE_ACTION_TYPES.PROFILE_FETCH_LOADED: {
      const profile = action.payload;
      const avatar =
        profile.avatar && !profile.avatar.includes('?v=') ? profile.avatar + `?v=${Date.now()}` : profile.avatar;
      return {
        ...state,
        status: Status.loaded,
        data: { ...profile, avatar },
      };
    }

    case PROFILE_ACTION_TYPES.PROFILE_UPDATE_AVATAR_LOADED: {
      return {
        ...state,
        status: Status.loaded,
        data: { ...state.data, avatar: action.payload?.avatar },
      };
    }

    case PROFILE_ACTION_TYPES.PROFILE_FETCH_FAILED: {
      return {
        ...state,
        status: Status.error,
      };
    }
  }
};
