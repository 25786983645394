import React from 'react';

interface Props {
  width?: string;
  height?: string;
}

export const FoodIcon = ({ width, height }: Props): React.ReactElement => (
  <svg
    width={width ? width : '38px'}
    height={height ? height : '42px'}
    viewBox="0 0 38 42"
    aria-hidden="true"
    data-testid="food-icon"
  >
    <title>Food Icon</title>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g fill="#FFFFFF">
        <path d="M20.9823,7.9589 C22.1723,4.8179 24.4093,2.6479 26.9133,2.6479 L26.9133,-0.0001 C22.6063,-0.0001 19.0253,3.4159 17.6183,8.3419 C-6.6607,1.8609 -0.0947,24.3289 4.0233,30.3529 C6.7213,34.3019 4.7693,41.9439 14.7583,41.8539 C16.2603,41.8409 17.5083,41.9609 18.5273,40.7249 C19.5453,41.9609 20.7953,41.8409 22.2963,41.8539 C32.2853,41.9439 30.3333,34.3019 33.0333,30.3529 C37.0603,24.4589 43.4283,2.8349 20.9823,7.9589" />
      </g>
    </g>
  </svg>
);

export default FoodIcon;
