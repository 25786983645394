export const onTouchStart = (e, touchStartRef) => (touchStartRef.current = [e.targetTouches[0].clientX, null]);

export const onTouchMove = (e, touchStartRef) => (touchStartRef.current[1] = e.targetTouches[0].clientX);

export const onTouchEnd = (touchStartRef, MIN_SWIPE_DISTANCE): boolean => {
  if (!touchStartRef.current) return;
  const distance = touchStartRef.current[0] - touchStartRef.current[1];
  const isLeftSwipe = distance > MIN_SWIPE_DISTANCE;
  if (isLeftSwipe) {
    return true;
  }
  return false;
};
