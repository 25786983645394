import React from 'react';

interface Props {
  stroke?: string;
  width?: number;
  height?: number;
}

export const DotsHorizontalIcon = ({ stroke = 'currentColor', height = 24, width = 24 }: Props): React.ReactElement => (
  <svg fill="none" height={height} width={width} viewBox="0 0 24 24" stroke={stroke}>
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
    />
  </svg>
);

export default DotsHorizontalIcon;
