import {
  addBannerResponse,
  allFetchHasCompleted,
  findBannerInstance,
  removeBannerResponse,
  resetBanners,
} from './common.actions';

import { CommonState } from './common.models';
import { createReducer } from '@reduxjs/toolkit';

export const CommonReducer = createReducer({} as CommonState, (builder) =>
  builder
    .addCase(allFetchHasCompleted, (state) => {
      state.allFetchHasCompleted = true;
    })
    .addCase(addBannerResponse, (state, action) => {
      const { id, type, message, autoDismiss } = action.payload;
      state.responseBanners = [{ ...state.responseBanners, id, type, message, autoDismiss }];
    })
    .addCase(findBannerInstance, (state, action) => {
      state.bannerInstance = state.responseBanners.find((banner) => banner.id === action.payload);
    })
    .addCase(removeBannerResponse, (state, action) => {
      state.responseBanners = state.responseBanners.filter((banner) => banner.id !== action.payload);
    })
    .addCase(resetBanners, (state) => {
      state.responseBanners = [];
    })
);
