import React, { ReactElement } from 'react';

export const ChallengesCupIcon = (): ReactElement => (
  <svg aria-hidden="true" className="challengesCupIcon" viewBox="0 0 132 139">
    <defs />
    <g fill="none" transform="translate(0 -1)">
      <ellipse
        fillOpacity="0.1"
        fillRule="nonzero"
        cx="68.1690397"
        cy="135.812155"
        fill="#000"
        rx="40.7912619"
        ry="3.64432298"
      />
      <path
        fill="#DDAD25"
        d="M50.0729874 117.473851c-.7501075 0-1.4723621-.418893-1.8258058-1.141121-.4955896-1.009195-.0787565-2.231205.9277898-2.728099 8.5191463-4.192777 40.49812-22.1598987 48.1096459-27.6873548 4.2528497-2.9216554 7.8228237-6.5616875 10.6522947-10.8478735 2.830431-4.2861859 4.779174-9.0028201 5.789562-14.0160495 1.047845-5.1894532 1.040161-10.4771294-.02113-15.7128052-1.062252-5.2356758-3.113763-10.1044595-6.099786-14.474424-2.884216-4.2197409-6.513737-7.7981428-10.7886774-10.6360197-4.2759007-2.8398028-8.9801599-4.7927089-13.9792757-5.8057549-1.0997094-.2224464-1.811399-1.2980855-1.5895363-2.4026138.2218628-1.1026023 1.2956402-1.8161641 2.3953496-1.5937177 5.5148747 1.1180098 10.7031981 3.271214 15.4189826 6.4008712 4.7138632 3.1296573 8.7169982 7.0778402 11.8989522 11.7338072 3.294326 4.8206351 5.559055 10.1920899 6.728877 15.9651038 1.170782 5.7739768 1.178466 11.6066949.02305 17.3344491-1.116037 5.5303451-3.264552 10.7323169-6.385998 15.4585808-3.120485 4.7262639-7.056388 8.7399289-11.7001396 11.9292904-7.8862132 5.7267912-39.8210063 23.6640606-48.6590204 28.0147656-.2881335.14252-.5945154.208965-.8951346.208965"
      />
      <path
        fill="#EEBB28"
        d="M76.8378017 99.6456861s15.5909021-15.9188811 31.4680173-.2869655c0 0-15.591863 15.9188814-31.4680173.2869655"
      />
      <path
        fill="#EEBB28"
        d="M123.926879 79.7533922s-8.747732 19.2642439-27.9614328 10.492537c0 0 8.7477318-19.263281 27.9614328-10.492537"
      />
      <path
        fill="#EEBB28"
        d="M21.6076093.44296687S20.0478468 20.4053651.13782384 18.8405365c0 0 1.55976253-19.96143527 21.46978546-18.39756963"
        mask="url(#mask-2)"
        transform="translate(109.490719 56.806649)"
      />
      <path
        fill="#EEBB28"
        d="M128.256372 36.2448011s5.038494 18.1924567-13.10527 23.244205c0 0-5.039455-18.1924567 13.10527-23.244205"
      />
      <path
        fill="#EEBB28"
        d="M118.574992 19.253555s10.277721 14.435905-4.120309 24.7406626c0 0-10.277721-14.4359051 4.120309-24.7406626"
      />
      <path
        fill="#EEBB28"
        d="M104.202126 7.62172647s13.642159 9.39474953 4.273019 23.07279603c0 0-13.6421594-9.3937865-4.273019-23.07279603"
      />
      <path
        fill="#EEBB28"
        d="M1.33693931.00847415S16.2564905 3.87191563 12.4031855 18.8307142c0 0-14.91955116-3.8634415-11.06624619-18.82224005"
        mask="url(#mask-4)"
        transform="translate(86.440042 .954305)"
      />
      <path
        fill="#EEBB28"
        d="M86.6214698 14.3926677S72.4193709 15.7697168 71.0459347 1.52929497c0 0 14.2030593-1.37608621 15.5755351 12.86337273"
      />
      <path
        fill="#DDAD25"
        d="M81.0247652 117.473851c-.3006193 0-.6060408-.066445-.8951347-.208965-8.8360931-4.349742-40.7708863-22.2870114-48.7022404-28.0446177-4.5995706-3.1595094-8.5354739-7.1731744-11.6569198-11.8994383-3.1204855-4.7253009-5.2699612-9.9272727-6.3859982-15.4585808-1.1554152-5.7287172-1.1477317-11.5614353.0240111-17.3344491 1.1698219-5.7730139 3.434551-11.1444687 6.7279166-15.9651038 3.181954-4.655967 7.1860488-8.6041499 11.898952-11.7338072 4.7157845-3.1296572 9.9041079-5.2828614 15.4189825-6.4008712 1.0997094-.2234094 2.1734868.4911154 2.3963101 1.5937177.2218627 1.1045283-.4898269 2.1801674-1.5904968 2.4026138-4.9991157 1.013046-9.7024145 2.9659521-13.9792756 5.8057549-4.2749403 2.8378769-7.9044616 6.4162788-10.7886777 10.6360197-2.9860232 4.3699645-5.0365731 9.2387482-6.0988252 14.474424-1.062252 5.2347128-1.0689751 10.5223891-.0220902 15.7128052 1.0113485 5.0141924 2.9600912 9.7308266 5.7895619 14.0160495 2.8304312 4.286186 6.3994444 7.9262181 10.6090745 10.8180214 7.6566668 5.5582712 39.63468 23.5253929 48.1528659 27.7172069 1.0075067.496894 1.4233793 1.718904.9287502 2.728099-.3534437.722228-1.0756983 1.141121-1.8267662 1.141121"
      />
      <path
        fill="#EEBB28"
        d="M54.2598548 99.6456861s-15.5909021-15.9188811-31.4680169-.2869655c0 0 15.5918626 15.9188814 31.4680169.2869655"
      />
      <path
        fill="#EEBB28"
        d="M7.17183422 79.7533922s8.74773218 19.2642439 27.96143258 10.492537c0 0-8.7477322-19.263281-27.96143258-10.492537"
      />
      <path
        fill="#EEBB28"
        d="M.00038418 57.249616S1.5601467 77.2120142 21.4701696 75.6471856c0 0-1.5607229-19.9614353-21.46978542-18.3975696"
      />
      <path
        fill="#EEBB28"
        d="M2.84234066 36.2448011s-5.03849399 18.1924567 13.10527074 23.244205c0 0 5.038494-18.1924567-13.10527074-23.244205M12.5236253 19.253555S2.24590437 33.68946 16.643934 43.9942176c0 0 10.2777209-14.4359051-4.1203087-24.7406626"
      />
      <path
        fill="#EEBB28"
        d="M26.8965873 7.62172647S13.2534674 17.016476 22.6235679 30.6945225c0 0 13.6421595-9.3937865 4.2730194-23.07279603"
      />
      <path
        fill="#EEBB28"
        d="M12.5874949.00847415S-2.33205628 3.87191563 1.52124869 18.8307142c0 0 14.91955121-3.8634415 11.06624621-18.82224005"
        mask="url(#mask-6)"
        transform="translate(30.734237 .954305)"
      />
      <path
        fill="#EEBB28"
        d="M44.4762828 14.3926677s14.2030593 1.3770491 15.5755351-12.86337273c0 0-14.2020989-1.37608621-15.5755351 12.86337273"
      />
      <path
        fill="#DDAD25"
        d="M94.0686635 36.923696c0-.8695632-.7030456-1.5744584-1.5703274-1.5744584H69.714662v3.1489167h20.3047658c.4494882 0 .7981297.3996332.7337799.8454889-1.3417415 9.1944515-10.2258569 16.3522183-21.0385457 16.5804426v3.1537315c13.4779234-.2532615 24.3540015-10.0938668 24.3540015-22.1541213"
      />
      <path
        fill="#EEBB28"
        d="M63.4141434 55.935834c-11.0547209 0-20.2096818-7.2473232-21.5725531-16.5919982-.0643498-.4458557.2842917-.8454889.7337799-.8454889h21.3718201v-3.1489167H40.0964619c-.8672817 0-1.5703274.7048951-1.5703274 1.5744584 0 12.2181818 11.1642116 22.1608621 24.8880089 22.1608621.1786428 0 .3553646-.002889.5330469-.0067408v-3.1537316c-.1776823.0038519-.3544041.0115557-.5330469.0115557M60.6722653 70.2381749h10.9308235v-9.3831938H60.6722653z"
      />
      <path
        fill="#EEBB28"
        d="M83.1914329 31.6509458c0 16.1288089-7.6345766 29.2040353-17.0536598 29.2040353-9.4181228 0-17.0536598-13.0752264-17.0536598-29.2040353h34.1073196z"
      />
      <path
        fill="#DDAD25"
        d="M72.0243399 62.5487516H60.2512062c-1.0488058 0-1.8987996-.8531927-1.8987996-1.9057205 0-1.0506019.8499938-1.9037946 1.8987996-1.9037946h11.7731337c1.0497663 0 1.89976.8531927 1.89976 1.9037946 0 1.0525278-.8499937 1.9057205-1.89976 1.9057205"
      />
      <path
        fill="#DDAD25"
        d="M76.2404049 31.6509458c0 13.1975238-4.3921145 24.4979938-10.6225207 29.1895907.1728801.0086668.3467207.0144446.5196007.0144446 9.4190832 0 17.0536598-13.0752264 17.0536598-29.2040353h-6.9507398z"
      />
      <path
        fill="#DDAD25"
        d="M76.8275249 35.2479331H49.1369378l-.0393783-1.9047576-.0134462-1.9037946 2.9476054.4227445h24.7958062z"
      />
      <path
        fill="#FFC82B"
        d="M82.5301665 31.8625106H49.7453796c-.8509542 0-1.5415141-.6923765-1.5415141-1.5455692v-.7183767c0-.8531927.6905599-1.5455692 1.5415141-1.5455692h32.7847869c.8519147 0 1.5415141.6923765 1.5415141 1.5455692v.7183767c0 .8531927-.6895994 1.5455692-1.5415141 1.5455692M78.8769222 71.4694302c-.0249715-1.7959417-1.4848478-3.2394359-3.2760775-3.2394359H55.7714991c-1.7912298 0-3.2501456 1.4434942-3.2760776 3.2394359l-.0509036 1.6794223h26.4833079l-.0509036-1.6794223zM45.5839639 80.0355428v4.3102602h40.2042239v-4.3102602c0-1.8720165-1.5588021-3.3665482-3.4249466-3.2808437H49.00795c-1.865184-.0857045-3.4239861 1.4088272-3.4239861 3.2808437"
      />
      <path
        fill="#EEBB28"
        d="M78.9387749 73.5185372l-.0115253-.369781H52.4439417l-.0115254.369781c-.0249715 1.854683-1.5760901 3.3193626-3.4239861 3.2355841H82.362761c-1.8469356.0837785-3.3980541-1.3809011-3.4239861-3.2355841"
      />
      <path fill="#FE9646" d="M45.5846362 84.3457068h40.2042239v-4.3102603H45.5846362z" />
    </g>
  </svg>
);

export default ChallengesCupIcon;
