import React from 'react';

interface Props {
  width?: number;
  height?: number;
}

export const AlcoholIcon = ({ width = 200, height = 165 }: Props): React.ReactElement => (
  <svg viewBox="0 0 200 165" width={width} height={height}>
    <ellipse fill="#CCC" cx={69.5} cy={132.9} rx={33.6} ry={3.8} />
    <ellipse fill="#CCC" cx={135.1} cy={128.5} rx={30} ry={3.9} />
    <path
      fill="#E5E5E4"
      d="M170 30.4c0-.2 0-.4-.2-.6-2.1-1.1-14.7-2-30.6-2.2h-9.8c-16.4.2-29.3 1.1-30.8 2.3-.1.2-.1.3-.1.4-.1.4 0 .8 0 1.3l12.3 93.2c.3 2.2 2.1 4 4 4h39c1.9 0 3.7-1.8 4-4L170 31.6v-1.2z"
    />
    <path
      fill="#FDDD7F"
      d="M158.5 44.7c-1.1 0-2.2-.3-3.3-.7l-7.8 61.4s-1.6 15.5-10.1 18.5h10.5c2.2 0 4.3-2.1 4.6-4.6l10.2-75.6c-1.4.6-2.7 1-4.1 1z"
    />
    <path
      fill="#FFC82B"
      d="M151.2 42.5c-1.8-1-3.5-1.5-4.8-1.5-1.4 0-3.1.5-4.8 1.5-2.2 1.3-4.6 1.6-7.2 1.6-2.6 0-5.1-.4-7.2-1.6-1.8-1-3.5-1.5-4.8-1.5-1.4 0-3.1.5-4.8 1.5-.8.5-1.7.5-2.6.9l8.1 61.4c.2 1.3-.8 2.6-2.1 2.7-1.3.2-2.5-.8-2.7-2.1l-8-60.8c-1.4 0-3.2 0-4.4-.6l10.6 75.2c.3 2.5 2.4 4.6 4.6 4.6h16.4c8.5-3 10.1-18.5 10.1-18.5l7.8-61.4c-1.6-.4-2.9-.6-4.2-1.4z"
    />
    <path
      fill="#F2F2F2"
      d="M114.2 39.3c-.2-1.3-1.4-2.3-2.7-2.1-1.3.2-2.2 1.4-2.1 2.7l.6 4.8c1.6 0 3.2-.5 4.6-1.3l-.4-4.1z"
    />
    <path
      fill="#FDE7A8"
      d="M120.8 107.6c1.3-.2 2.2-1.4 2.1-2.7l-8.1-61.4c-1.5.7-3 1.3-4.6 1.3l8 60.8c.1 1.2 1.3 2.2 2.6 2z"
    />
    <ellipse fill="#F2F2F2" cx={134} cy={31.2} rx={31.5} ry={1.8} />
    <circle fill="#FDE7A8" cx={140.4} cy={121.2} r={2.1} />
    <circle fill="#FDE7A8" cx={132.6} cy={107} r={2} />
    <circle fill="#FDE7A8" cx={139.8} cy={99.7} r={2} />
    <circle fill="#FDE7A8" cx={126.4} cy={117} r={2.1} />
    <circle fill="#FDE7A8" cx={129.2} cy={94.1} r={2} />
    <circle fill="#FDE7A8" cx={139.3} cy={81.8} r={2} />
    <circle fill="#FDE7A8" cx={129.2} cy={69} r={2} />
    <circle fill="#FDE7A8" cx={144.3} cy={52.8} r={2} />
    <path
      fill="#E5E5E5"
      d="M91.3 130L73 124.6V98.4l33.1-40.9s3-3.3-37.5-3.3S30 57.6 30 57.6l33.7 40.7v26.4l-18 5.4s-12.3 3.9 0 3.9h45.7c12-.2-.1-4-.1-4z"
    />
    <ellipse fill="#F2F2F2" cx={68.2} cy={59} rx={32.7} ry={1.9} />
    <path fill="#F49B91" d="M44.8 68.2l24 28 21-25.8s-4.3-2.5-22.5-.6-22.5-1.6-22.5-1.6z" />
    <path fill="#F2F2F2" d="M54.8 64c-.7-1.1-2.1-1.5-3.2-.9s-1.4 2.1-.8 3.2l2.4 4c1.7 0 4.3 1.2 5.3 0L54.8 64z" />
    <path
      fill="#F9BBB5"
      d="M68.5 92c1.1-.6 1.4-2.1.8-3.2L58.5 70.3c-1.2.1-3.6 0-5.4-.1l12.2 21c.7 1.1 2.2 1.5 3.2.8z"
    />
    <path fill="#C5A299" d="M98 46.8L65.9 86.3l18.8-26.2 12.4-17.3z" />
    <circle fill="#3FAB6D" cx={75} cy={72.2} r={5.9} />
    <path fill="#B14F26" d="M97.8 47L86.7 60.7h-2.4l12.8-17.9z" />
    <path fill="#35905C" d="M70.8 76.4s4.2.4 6.5-1.9 1.8-6.5 1.8-6.5c2.3 2.3 2.3 6 0 8.4-2.3 2.3-6 2.3-8.3 0z" />
    <circle fill="#AF4437" cx={72.5} cy={74.2} r={2.1} />
    <path fill="#43B675" d="M79.9 69c-1.1-1.6-2.9-2.7-5-2.7-2.3 0-4.3 1.3-5.3 3.2 4.3-.4 7.6-.5 10.3-.5z" />
  </svg>
);

export default AlcoholIcon;
