export const ERROR_ACTIVITY_MINIMUM = 'notifications.pleaseSelectAtLeast_one';
export const ERROR_API = 'notifications.oopsLooksLikeSomethingPleaseTryAgain';
export const ERROR_APPOINTMENT_API = 'notifications.appointmentRequestError';
export const ERROR_CHALLENGE_COMPLETED_JOINED = 'notifications.errorYouEitherTrying';
export const ERROR_CHALLENGE_REGISTRATION_ENDED = 'notifications.youMayNotJoin';
export const ERROR_CHECKIN = 'notifications.errorSorryWeAreNotYour';
export const ERROR_CREATE_PROFILE = 'notifications.weCouldNotCreate';
export const ERROR_DEVICE_CONNECTED = 'notifications.deviceConnectedError';
export const ERROR_FORM_INVALID = 'notifications.oopsItemsThatRequireYourAttention';
export const ERROR_GENERIC = 'notifications.oopsLooksLikeSomethingPleaseTryAgain';
export const ERROR_INVALID_INVITE_CODE = 'notifications.errorWeWereNotInviteCode';
export const ERROR_JOINING_CHALLENGE = 'notifications.errorWeWereNotTheChallenge';
export const ERROR_JOIN_TEAM_CHALLENGE = 'notifications.joinTeamError';
export const ERROR_MAX_CHALLENGES = 'notifications.youAreAbleToBe';
export const ERROR_NO_GOAL = 'notifications.pleaseSelectAGoal';
export const ERROR_SYNC_DEVICE = 'notifications.errorSorryWeWereNotDevice';
export const ERROR_TERMS_OF_SERVICE = 'notifications.youMustAcceptTermsOfService';
export const ERROR_VALIDATING_TRY_AGAIN = 'notifications.validatingTryAgain';

export const INFO_NO_NEW_DATA = 'notifications.yourDeviceHasNot';

export const SUCCESS_DEVICE_CONNECTED = 'notifications.deviceConnectedSuccess';
export const SUCCESS_FOLLOWED_COMMUNITY = 'notifications.successfullyFollowedCommunity';
export const SUCCESS_MESSAGE_SENT = 'notifications.messageSentSuccess';
export const SUCCESS_JOIN_CHALLENGE = 'notifications.joinSuccessMessage';
export const SUCCESS_SYNCED = 'notifications.successfullySynced';
export const SUCCESS_UNFOLLOWED_COMMUNITY = 'notifications.successfullyUnfollowedCommunity';
