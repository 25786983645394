import React from 'react';

export const DeviceSettingsIcon = (): React.ReactElement => (
  <svg aria-hidden={true} width="20" height="20" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.593 12.593a3.667 3.667 0 11-5.187-5.186 3.667 3.667 0 015.187 5.186zm5.431-8.712L16.12 1.977l-2.293 1.088a7.898 7.898 0 00-1.631-.673L11.346 0H8.652l-.85 2.392a7.91 7.91 0 00-1.63.673l-2.293-1.09-1.903 1.906 1.09 2.292a7.76 7.76 0 00-.675 1.63L0 8.653v2.693l2.391.851c.16.562.385 1.108.675 1.63l-1.09 2.292 1.903 1.904 2.292-1.088a7.892 7.892 0 001.631.673L8.652 20h2.694l.85-2.392a7.898 7.898 0 001.63-.673l2.293 1.088 1.905-1.904-1.09-2.292a7.838 7.838 0 00.673-1.63l2.393-.85V8.653l-2.393-.849a7.881 7.881 0 00-.673-1.63l1.09-2.292z"
      fill="#FFF"
      fillRule="nonzero"
    />
  </svg>
);

export default DeviceSettingsIcon;
