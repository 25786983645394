import styled from '@emotion/styled';
import { removeBannerResponse, useCommon } from '@health-activity-ui/data-access';
import { AllColors, localWindow, NotificationStatus, NOTIFICATION_DELAY } from '@health-activity-ui/shared';
import { respondTo } from '@health-activity-ui/utils';
import { RdsIcon } from '@rally/energon-react';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Level, LevelSide, Section } from '../layout';
import { BannerItemProps } from './banners.models';

export const BannersNotificationContainer = styled.div`
  left: 0;
  ${respondTo.mobile`
    .level-left {
      flex-shrink: 1;
    }
  `}
  position: fixed;
  right: 0;
  z-index: 100;
`;

export const BannerItem = ({ id, type, message, autoDismiss, removeBanner }: BannerItemProps) => {
  const { t } = useTranslation();
  const timeOutId = useRef(null);
  useEffect(() => {
    if (autoDismiss) {
      timeOutId.current = localWindow.setTimeout(() => {
        removeBanner(id);
      }, NOTIFICATION_DELAY);
    }
    return () => {
      localWindow.clearTimeout(timeOutId.current);
    };
  }, [autoDismiss, removeBanner, id]);

  const getNotificationDetails = (type) => {
    const notifications = {
      [NotificationStatus.SUCCESS]: {
        indicatorIcon: 'indicator-yes-filled',
        messageType: 'success',
        color: 'success',
        textClassName: 'has-rds-text-success',
      },
      [NotificationStatus.ERROR]: {
        indicatorIcon: 'indicator-alert',
        messageType: 'error',
        color: 'danger',
        textClassName: 'has-rds-text-danger',
      },
      [NotificationStatus.WARNING]: {
        indicatorIcon: 'indicator-alert-mid',
        messageType: 'warning',
        color: 'warning',
        textClassName: 'has-rds-text-warning',
      },
      [NotificationStatus.INFO]: {
        indicatorIcon: 'system-info-circle',
        messageType: 'info',
        color: 'info',
        textClassName: 'has-rds-text-info',
      },
    };

    const { indicatorIcon, messageType, color, textClassName } = notifications[type];
    return { indicatorIcon, messageType, color, textClassName };
  };
  const { indicatorIcon, messageType, color, textClassName } = getNotificationDetails(type);
  const bgColor = `${color}-light` as AllColors;
  return (
    <Section padding="8" data-testid="banner" className="is-rds-body-2 " bgColor={bgColor}>
      <Container>
        <Level isMobile>
          <LevelSide side="left" className="level-left" color={color}>
            {indicatorIcon && <RdsIcon name={indicatorIcon} className={textClassName} />}
            <p className="has-rds-ml-24 has-rds-ml-8-mobile" data-testid="banner-message" role="alert">
              <span className="is-sr-only">{t(messageType)}: </span> {t(message, message)}
            </p>
          </LevelSide>
          <LevelSide side="right">
            <button
              className="btn-no-style"
              aria-label={t('closeMessage')}
              tabIndex={0}
              onClick={() => removeBanner(id)}
            >
              <RdsIcon name="system-close" className={textClassName} size="16" />
            </button>
          </LevelSide>
        </Level>
      </Container>
    </Section>
  );
};

export const Banners = () => {
  const {
    state: { responseBanners },
    dispatch,
  } = useCommon();

  function removeBanner(id: string | number) {
    dispatch(removeBannerResponse(id));
  }

  return (
    <BannersNotificationContainer>
      {responseBanners &&
        responseBanners.map((banner) => (
          <BannerItem
            key={banner.id}
            id={banner.id}
            message={banner.message}
            type={banner.type}
            removeBanner={removeBanner}
            autoDismiss={banner.autoDismiss}
          />
        ))}
    </BannersNotificationContainer>
  );
};

export default Banners;
