import { SESSION, appConfig } from '@health-activity-ui/shared';

import Cookies from 'js-cookie';
import amplitude from 'amplitude-js';
import { amplitudeBaseConfig } from './config';
import { environment } from '../../../../../../environments/environment';

export function initAmplitude(apiKey: string): void {
  // Don't attempt to init if API key isnt available
  if (!apiKey) {
    throw new Error('Amplitude API Key Not Available!');
  }

  const amplitudeClient = amplitude.getInstance();
  const initConfig = { ...amplitudeBaseConfig };

  if (!environment.production) {
    // If true, the events will always be uploaded to HTTPS endpoint.
    // Otherwise, it will use the embedding site's protocol - local proxy uses http.
    initConfig.forceHttps = false;
  }

  amplitudeClient.init(appConfig.HAUI_APP_HA_AMPLITUDE_KEY, undefined, initConfig);
  amplitudeClient.setVersionName(appConfig.HAUI_APP_HA_VERSION || '');
  // If there is not an amplitudeDeviceId cookie, set one for the other apps to lookup and continue the amplitude session
  if (!initConfig.deviceId) {
    Cookies.set(SESSION.COOKIE.AMPLITUDE.DEVICE_ID, amplitudeClient.options.deviceId || '', {
      domain: appConfig.HAUI_APP_HA_BASE_DOMAIN,
    });
  }
}
