import { updatePreferredLocale } from '../../api/localization.api';
import { compareLocales } from './i18n-helpers';
import { LocalizationService } from './locale.service';
import { Dispatch } from 'react';
import { updateUiConfig } from '../../contexts/ui-config';
import { AnyAction } from '@reduxjs/toolkit';

/**
 * @function setPreferredLocale
 *
 * @description
 * Set the authenticated user locale preference
 * On success this will invoke a reload of the page
 *
 *
 * @param {{ locale: string }} localeObject - preferred locale
 *
 * @returns {Promise<boolean>} - resolves to true if the change was successful
 */
export const setPreferredLocale = async (locale: string, dispatch: Dispatch<AnyAction>): Promise<boolean> => {
  const localizationService = LocalizationService;
  const oldLocale = localizationService.getLocale();

  try {
    //Don't switch if it is the same value
    const isSame = compareLocales(oldLocale, locale);
    if (isSame) {
      return false;
    }

    //Optimistically set the value
    localizationService.setLocale(locale);

    await Promise.all([updatePreferredLocale(locale), updateUiConfig(dispatch, locale)]);
    return true;
  } catch (e) {
    console.error('Error: Unable to set preferred locale', e);
    localizationService.setLocale(oldLocale);
    return false;
  }
};
