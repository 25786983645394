import React from 'react';

interface Props {
  width?: number;
  height?: number;
}

export const NutritionIcon = ({ width = 181, height = 151 }: Props): React.ReactElement => (
  <svg width={width} height={height} viewBox="0 0 181 151">
    <g transform="translate(14 30)" fill="none" fillRule="evenodd">
      <ellipse fill="#CCC" fillRule="nonzero" cx={78.403} cy={116.714} rx={41.403} ry={3.714} />
      <circle fill="#FFF" fillRule="nonzero" cx={76} cy={53.5} r={48} />
      <path d="M109.7 22.7c7.2 7.8 11.6 18.2 11.6 29.6 0 24.1-19.5 43.6-43.6 43.6-11.4 0-21.8-4.4-29.6-11.6 8 8.6 19.3 13.9 31.9 13.9 24.1 0 43.6-19.5 43.6-43.6 0-12.5-5.3-23.9-13.9-31.9z" />
      <path
        d="M149.2 100c0 1-.8 1.8-1.8 1.8H145c-1 0-1.8-.8-1.8-1.8V45.2s-8.9-2.8-8.9-11.9V12.4C134.3-1 147.4.3 147.4.3c1 .1 1.8 1 1.8 2V100zM18.6 25.5c0 1-.7 1.8-1.5 1.8s-1.5-.8-1.5-1.8V2.3c0-1-.8-1.8-1.8-1.8h-2.3c-1 0-1.8.8-1.8 1.8v23.2c0 1-.7 1.8-1.5 1.8s-1.5-.8-1.5-1.8V2.3c0-1-.8-1.5-1.7-1.2C5 1.1.7 2.7.7 7.4v17.9c0 9 8.9 11.9 8.9 11.9v62.7c0 1 .8 1.8 1.8 1.8h2.3c1 0 1.8-.8 1.8-1.8V37.2s8.9-2.5 8.9-11.9V7.4C24.4 2 20.3 1 20.3 1c-1-.2-1.7.4-1.7 1.4v23.1z"
        fill="#BFBFBF"
        fillRule="nonzero"
      />
      <path
        d="M77.7 8.8c24.1 0 43.6 19.5 43.6 43.6S101.8 96 77.7 96 34.1 76.5 34.1 52.4 53.7 8.8 77.7 8.8h0zm0-8.3c-7 0-13.8 1.4-20.2 4.1-6.2 2.6-11.7 6.3-16.5 11.1-4.7 4.8-8.4 10.3-11 16.5-2.7 6.4-4.1 13.2-4.1 20.2s1.4 13.8 4.1 20.2c2.6 6.2 6.3 11.7 11.1 16.5 4.8 4.8 10.3 8.5 16.5 11.1 6.4 2.7 13.2 4.1 20.2 4.1s13.8-1.4 20.2-4.1c6.2-2.6 11.7-6.3 16.5-11.1 4.8-4.8 8.5-10.3 11.1-16.5 2.7-6.4 4.1-13.2 4.1-20.2s-1.4-13.8-4.1-20.2c-2.6-6.2-6.3-11.7-11.1-16.5-4.8-4.8-10.3-8.5-16.5-11.1C91.5 1.9 84.7.5 77.7.5z"
        stroke="#E5E5E5"
        strokeWidth={0.912}
        fill="#FFF"
        fillRule="nonzero"
      />
      <path
        d="M70.3 31.3c-.3-.3-.6-.5-1-.6-.7-.2-1.6 0-2.2.6l-2.4 2.4-.1-.2c-.1-.3-.2-.6-.4-.8l-.8-1.5c0-1.2-1-2.1-2.1-2.1-1.4-2.1-2.8-1.9-2.8-1.9h.2-4.5c-.6 0-1.1.3-1.5.7-.7.5-1.4 1.2-1.4 1.2-1.2 0-2.1 1-2.1 2.1l-.6 1.2c-.5.4-.8 1-.8 1.6v32.4l-.2.2-.3 5.2c0 1.7.8 3.3 2.1 4.6.2.2.5.4.8.6.8.6 1.8 1.1 2.9 1.4v.4c0 1.3 1 2.3 2.3 2.3H57c1.3 0 2.3-1 2.3-2.3v-.4c3.4-1 5.8-3.6 5.8-6.6v-32l5.2-5.2c.9-1 .9-2.4 0-3.3z"
        fill="#3FAB6D"
        fillRule="nonzero"
      />
      <path
        d="M71.6 32.6c-.3-.3-.6-.5-1-.6-.7-.2-1.6 0-2.2.6L66.1 35l-.1-.2c-.1-.3-.2-.6-.4-.8l-.8-1.5c0-1.2-1-2.1-2.1-2.1-1.4-2.1-2.8-1.9-2.8-1.9h.2-4.5c-.6 0-1.1.3-1.5.7-.7.5-1.4 1.2-1.4 1.2-1.2 0-2.1 1-2.1 2.1l-.6 1.3c-.5.4-.8 1-.8 1.6v32.2l-.2.2-.3 5.2c0 1.7.8 3.3 2.1 4.6.2.2.5.4.8.6.8.6 1.8 1.1 2.9 1.4v.4c0 1.3 1 2.3 2.3 2.3h1.6c1.3 0 2.3-1 2.3-2.3v-.4c3.4-1 5.8-3.6 5.8-6.6V41l5.2-5.2c.8-.9.8-2.3-.1-3.2z"
        fill="#E5E5E4"
        fillRule="nonzero"
      />
      <path
        d="M48.9 32.4s.1 0 .1-.1h.1s.1 0 .1-.1h1c1.2 0 2.1 1 2.1 2.1 0-1.2 1-2.1 2.1-2.1 1.2 0 2.1 1 2.1 2.1 0-1.2 1-2.1 2.1-2.1 1 0 1.8.7 2 1.5.3-.9 1.1-1.5 2-1.5h.7c.1 0 .1 0 .2.1.1 0 .1.1.2.1s.1.1.1.1l.1.1c.1 0 .1.1.1.1l-.8-1.5h-2.1v-2.1c-1.4-2.1-2.8-1.9-2.8-1.9h.4c.1 0 .2.1.3.1.1 0 .2.1.3.2 0 0 .1 0 .1.1 0 0 .1 0 .1.1l.1.1.1.1s0 .1.1.1l.1.1s0 .1.1.1c0 0 0 .1.1.1.1.1.1.2.2.3v.2c0 .1.1.2.1.4v.3c0 1.2-1 2.1-2.1 2.1-1.2 0-2.1-.9-2.1-2.1 0 1.2-1 2.1-2.1 2.1-1.2 0-2.1-1-2.1-2.1v-.6c0-.1 0-.1.1-.2 0-.1 0-.1.1-.2 0-.1.1-.1.1-.2.1-.1.2-.2.2-.3-.7.5-1.4 1.2-1.4 1.2v2.1H49l-.6 1.2c.3 0 .4 0 .5-.1-.1.1-.1 0 0 0z"
        fill="#FCE6A7"
        fillRule="nonzero"
      />
      <path d="M60.6 53.8h.1-.1zm.1 0v-.5.5z" fill="#FFF" fillRule="nonzero" />
      <path
        d="M50.1 41.6c1.2 0 2.1 1 2.1 2.1 0-1.2 1-2.1 2.1-2.1 1.2 0 2.1 1 2.1 2.1 0-1.2 1-2.1 2.1-2.1 1.2 0 2.1 1 2.1 2.1V39c0 1.2-1 2.1-2.1 2.1-1.2 0-2.1-1-2.1-2.1 0 1.2-1 2.1-2.1 2.1-1.2 0-2.1-1-2.1-2.1 0 1.2-1 2.1-2.1 2.1-1.2 0-2.1-1-2.1-2.1v4.8c0-1.2.9-2.2 2.1-2.2zm4.5-14.3h.3c.1 0 .1 0 .2.1.1 0 .1.1.2.1s.1.1.2.1.1.1.2.1.1.1.1.1l.1.1s0 .1.1.1c0 0 0 .1.1.1v.1s0 .1.1.1c0 0 0 .1.1.1v.4c0-.1.1-.2.1-.4 0 0 0-.1.1-.1v-.1s0-.1.1-.1V28l.1-.1v-.1l.1-.1.1-.1s.1-.1.2-.1.1-.1.2-.1.1-.1.2-.1.1 0 .2-.1h-3.6.1c.3.1.3.1.4.1z"
        fill="#FCE6A7"
        fillRule="nonzero"
      />
      <path
        d="M50.1 46.4c1.2 0 2.1 1 2.1 2.1 0-1.2 1-2.1 2.1-2.1 1.2 0 2.1 1 2.1 2.1 0-1.2 1-2.1 2.1-2.1 1.2 0 2.1 1 2.1 2.1v-4.8c0 1.2-1 2.1-2.1 2.1-1.2 0-2.1-1-2.1-2.1 0 1.2-1 2.1-2.1 2.1-1.2 0-2.1-1-2.1-2.1 0 1.2-1 2.1-2.1 2.1-1.2 0-2.1-1-2.1-2.1v4.8c0-1.1.9-2.1 2.1-2.1zm0-9.6c1.2 0 2.1 1 2.1 2.1 0-1.2 1-2.1 2.1-2.1 1.2 0 2.1 1 2.1 2.1 0-1.2 1-2.1 2.1-2.1 1.2 0 2.1 1 2.1 2.1v-.2c0-.6.2-1.2.6-1.6l.8-.8.1-.1c-.8-.2-1.4-.7-1.6-1.5-.3.9-1.1 1.5-2 1.5-1.2 0-2.1-1-2.1-2.1 0 1.2-1 2.1-2.1 2.1-1.2 0-2.1-1-2.1-2.1 0 1.2-1 2.1-2.1 2.1h-.4c-.1 0-.3-.1-.4-.1-.1 0-.1-.1-.2-.1-.6-.3-1-.9-1.1-1.7v-.2V39c0-1.2.9-2.2 2.1-2.2zm0 14.4c1.2 0 2.1 1 2.1 2.1 0-1.2 1-2.1 2.1-2.1 1.2 0 2.1 1 2.1 2.1 0-1.2 1-2.1 2.1-2.1 1.2 0 2.1 1 2.1 2.1v-4.8c0 1.2-1 2.1-2.1 2.1-1.2 0-2.1-1-2.1-2.1 0 1.2-1 2.1-2.1 2.1-1.2 0-2.1-1-2.1-2.1 0 1.2-1 2.1-2.1 2.1-1.2 0-2.1-1-2.1-2.1v4.8c0-1.1.9-2.1 2.1-2.1zm0 4.8c1.2 0 2.1 1 2.1 2.1 0-1.2 1-2.1 2.1-2.1s2 .9 2.1 2l2.6-2.6c-.2 0-.3.1-.5.1-1.2 0-2.1-1-2.1-2.1 0 1.2-1 2.1-2.1 2.1-1.2 0-2.1-1-2.1-2.1 0 1.2-1 2.1-2.1 2.1-1.2 0-2.1-1-2.1-2.1v4.8c0-1.3.9-2.2 2.1-2.2zm0 4.8c.9 0 1.7.6 2 1.5l.6-.5 1.5-1.5c-1.1-.1-1.9-1-1.9-2.1 0 1.2-1 2.1-2.1 2.1-1.2 0-2.1-1-2.1-2.1V63c-.1-1.3.8-2.2 2-2.2z"
        fill="#FCE6A7"
        fillRule="nonzero"
      />
      <circle fill="#FFC82C" fillRule="nonzero" cx={50.1} cy={43.8} r={2.1} />
      <circle fill="#FFC82C" fillRule="nonzero" cx={50.1} cy={39} r={2.1} />
      <circle fill="#FFC82C" fillRule="nonzero" cx={50.1} cy={48.5} r={2.1} />
      <circle fill="#FFC82C" fillRule="nonzero" cx={50.1} cy={58.1} r={2.1} />
      <circle fill="#FFC82C" fillRule="nonzero" cx={50.1} cy={53.3} r={2.1} />
      <path
        d="M60.7 29.2c0-.1 0-.2-.1-.4v-.2c0-.1-.1-.2-.2-.3 0 0 0-.1-.1-.1 0 0 0-.1-.1-.1l-.1-.1s0-.1-.1-.1l-.1-.1-.1-.1s-.1 0-.1-.1c0 0-.1 0-.1-.1-.1-.1-.2-.1-.3-.2-.1 0-.2-.1-.3-.1h-1c-.1 0-.1 0-.2.1-.1 0-.1.1-.2.1s-.1.1-.2.1-.1.1-.2.1-.1.1-.1.1l-.1.1v.1l-.1.1v.1s0 .1-.1.1v.1s0 .1-.1.1c0 .1-.1.2-.1.4v-.4s0-.1-.1-.1c0 0 0-.1-.1-.1v-.1s0-.1-.1-.1c0 0 0-.1-.1-.1l-.1-.1-.1-.1s-.1-.1-.2-.1-.1-.1-.2-.1-.1-.1-.2-.1-.1 0-.2-.1h-.7c-.6 0-1.1.3-1.5.7-.1.1-.2.2-.2.3 0 .1-.1.1-.1.2s-.1.1-.1.2c0 0 0 .1-.1.2v.6c0 1.2 1 2.1 2.1 2.1 1.2 0 2.1-.9 2.1-2.1 0 1.2 1 2.1 2.1 2.1 1.2 0 2.1-1 2.1-2.1-.2-.1-.2-.2-.2-.3zm-9.3 2.1v-2.1c-1.2 0-2.1 1-2.1 2.1h2.1zm12.1 0c0-1.2-1-2.1-2.1-2.1v2.1h2.1z"
        fill="#FFC82C"
        fillRule="nonzero"
      />
      <circle fill="#FFC82C" fillRule="nonzero" cx={54.3} cy={43.8} r={2.1} />
      <circle fill="#FFC82C" fillRule="nonzero" cx={54.3} cy={39} r={2.1} />
      <circle fill="#FFC82C" fillRule="nonzero" cx={54.3} cy={48.5} r={2.1} />
      <circle fill="#FFC82C" fillRule="nonzero" cx={54.3} cy={53.3} r={2.1} />
      <circle fill="#FFC82C" fillRule="nonzero" cx={58.6} cy={43.8} r={2.1} />
      <circle fill="#FFC82C" fillRule="nonzero" cx={58.6} cy={39} r={2.1} />
      <circle fill="#FFC82C" fillRule="nonzero" cx={58.6} cy={48.5} r={2.1} />
      <path
        d="M58.6 51.2c-1.2 0-2.1 1-2.1 2.1 0 1.2 1 2.1 2.1 2.1.2 0 .3 0 .5-.1L56.5 58c-.1-1.1-1-2-2.1-2-1.2 0-2.1 1-2.1 2.1s.9 2 1.9 2.1l6.5-6.4c0-.2.1-.3.1-.5-.1-1.1-1-2.1-2.2-2.1zm-9.2 13.7l-.3.3 3.5-3.5-.6.5c-.3-.9-1.1-1.5-2-1.5-1.2 0-2.1 1-2.1 2.1.1 1 .7 1.8 1.5 2.1z"
        fill="#FFC82C"
        fillRule="nonzero"
      />
      <g transform="translate(47 32)" fillRule="nonzero" fill="#FFC82C">
        <path d="M17.7 1.6c-.1-.3-.2-.6-.4-.8 0-.1-.1-.1-.1-.1l-.1-.1-.1-.1c-.1 0-.1-.1-.2-.1s-.1-.1-.2-.1h-.7c-1 0-1.8.7-2 1.5-.3-.9-1.1-1.5-2-1.5-1.2 0-2.1 1-2.1 2.1 0 1.2 1 2.1 2.1 2.1 1 0 1.8-.7 2-1.5.2.8.9 1.4 1.6 1.5L18 2l-.3-.4zM3.1.1h-1S2 .1 2 .2h-.1s-.1 0-.1.1l-.1.1C1.2.8.9 1.4.9 2v.2c.1.7.5 1.3 1.1 1.7.1 0 .1.1.2.1.1.1.3.1.4.1H3c1.2 0 2.1-1 2.1-2.1.1-1-.8-1.9-2-1.9z" />
        <circle cx={7.3} cy={2.2} r={2.1} />
      </g>
      <path d="M48 62.9v3.4l1.2-1.1.3-.3c-.9-.3-1.5-1.1-1.5-2z" fill="#FCE6A7" fillRule="nonzero" />
      <path
        d="M63.5 68.2V42.5c0-3.2.4-3.9.4-3.9l5.1-4.9c.8-.8.6-2 .4-2.9-.7-.2-1.6 0-2.2.6l-2.4 2.4-2.5 2.5-.1.1-.8.8c-.4.4-.6 1-.6 1.6v15.1l-.1.1-6.5 6.4-1.5 1.5-3.5 3.5-1.2.9-.2.2-.3 5.2c0 1.7.8 3.3 2.1 4.6.2.2.5.4.8.6 14.3 1.1 13.1-8.7 13.1-8.7z"
        fill="#75C493"
        fillRule="nonzero"
      />
      <path
        d="M70.3 31.3c-.3-.3-.6-.5-1-.6.3.9.4 2.1-.4 2.9l-5.1 4.9s-.4.7-.4 3.9v25.7s1.2 9.8-13.1 8.7c.8.6 1.8 1.1 2.9 1.4v.4c0 1.3 1 2.3 2.3 2.3h1.6c1.3 0 2.3-1 2.3-2.3v-.4c3.4-1 5.8-3.6 5.8-6.6v-32l5.2-5.2c.8-.8.8-2.2-.1-3.1z"
        fill="#3FAB6D"
        fillRule="nonzero"
      />
      <path
        d="M108.5 64.3l-.2-.2c.4-.7.6-1.5.6-2.3 0-1.7-.9-3.2-2.2-4.2-1-.7-2.1-1.1-3.5-1.1-.7 0-1.4.1-2 .3-.2-.5-.5-.9-.9-1.3-.2-.3-.5-.5-.8-.7-1-.7-2.1-1.1-3.4-1.1-2.3 0-4.2 1.3-5.1 3.1-.6-.2-1.3-.3-2-.3-3.1 0-5.6 2.4-5.6 5.3 0 .9.2 1.7.6 2.4-1.4 1-2.4 2.6-2.4 4.3 0 1.1.4 2.2 1 3 1 1.4 2.7 2.3 4.6 2.3 1.2 0 2.3-.3 3.2-.9.1-.1.2-.2.3-.2.1-.1.3-.2.4-.3.1 0 .1-.1.1-.1 1.2 5.5 1.5 11.5 1.6 14v.1c0 .3 1.5.6 3.3.6 1.8 0 3.2-.2 3.3-.6 0-.7.1-7.2 1.6-14 1 .9 2.4 1.5 3.9 1.5 3.1 0 5.6-2.4 5.6-5.3.1-1.8-.7-3.3-2-4.3z"
        fill="#E5E5E4"
        fillRule="nonzero"
      />
      <path
        d="M107.3 63.2c.6.9 1 1.9 1 3 0 2.9-2.5 5.3-5.6 5.3-1.5 0-2.9-.6-3.9-1.5-1.6 7.2-1.6 15.1-1.6 15.1h-5.5v.1h6.7s0-6.9 1.6-14.1c1 .9 2.4 1.5 3.9 1.5 3.1 0 5.6-2.4 5.6-5.3 0-1.6-.9-3.1-2.2-4.1zm-22.4 8.3c-1.3 0-2.5-.4-3.5-1.1 1 1.4 2.7 2.3 4.6 2.3 1.2 0 2.3-.3 3.2-.9-.1-.6-.2-1.2-.4-1.8-.9.9-2.3 1.5-3.9 1.5zm21-9.6c.5.3.9.7 1.2 1.1.4-.7.6-1.5.6-2.3 0-1.7-.9-3.2-2.2-4.2.6.9 1 1.9 1 3 0 .8-.2 1.6-.6 2.4z"
        fill="#3FAB6D"
        fillRule="nonzero"
      />
      <path d="M107.3 63.2l-.2-.2v.1c.1 0 .1 0 .2.1z" fill="#75C493" fillRule="nonzero" />
      <path
        d="M107.3 63.2c-.1 0-.1-.1-.2-.1V63c-.3-.4-.7-.8-1.2-1.1.4-.7.6-1.5.6-2.4 0-1.1-.4-2.2-1-3-1-.7-2.1-1.1-3.5-1.1-.7 0-1.4.1-2 .3-.2-.5-.5-.9-.9-1.3-.2-.3-.5-.5-.8-.7-1-.7-2.1-1.1-3.4-1.1-2.3 0-4.2 1.3-5.1 3.1-.6-.2-1.3-.3-2-.3-3.1 0-5.6 2.4-5.6 5.3 0 .9.2 1.7.6 2.4-1.4 1-2.4 2.6-2.4 4.3 0 1.1.4 2.2 1 3 1 .7 2.1 1.1 3.5 1.1 1.6 0 3-.6 4-1.6.1.6.2 1.2.4 1.8.3-.2.6-.4.8-.6 1.2 5.5 1.5 11.6 1.6 14.1l5.5-.1s0-7.9 1.6-15.1c1 .9 2.4 1.5 3.9 1.5 3.1 0 5.6-2.4 5.6-5.3 0-1.1-.4-2.2-1-3z"
        fill="#75C493"
        fillRule="nonzero"
      />
      <path
        d="M88.9 70l1.3-1.1s1.8 1.3 3.6.9c0 0-.8 1.8-3.7 1.4 0 0-.4.4-.8.6l-.4-1.8zm14.2-8.4s-2.6-.8-4.5 1.2c0 0-2-3.4-6.8-3.1.2 0 2.9-1.7 6.8 1.2 0-.1 2.4-1.3 4.5.7z"
        fill="#3FAB6D"
        fillRule="nonzero"
      />
      <path
        d="M104.6 43.5c4.4-4.4 5.3-10.8 2.4-15.2-.4-.6-.8-1.1-1.3-1.6-4.3-4.3-11.8-3.8-16.8 1.1-3 3-5.3 11.2-6.7 17-.2.9-.4 1.8-.6 2.6l-8 8-.1-.1c-.9-.9-2.4-1-3.3 0-.3.3-.5.7-.6 1.1-.2.8 0 1.6.6 2.3.4.4.9.6 1.4.7.6.1 1.1.6 1.2 1.2.1.5.3 1 .7 1.4.9.9 2.4 1 3.3 0 .9-.9.9-2.4 0-3.3l8-8c6-1.1 16.3-3.7 19.8-7.2z"
        fill="#E5E5E4"
        fillRule="nonzero"
      />
      <path
        d="M103.7 41.6c4.4-4.4 5.3-10.8 2.4-15.2.6 2.2.4 5.2-2.2 8.5-5.5 6.9-13 2.9-22.5 8.1-.9 3.8-1.3 6.6-1.3 6.6s18.7-3 23.6-8z"
        fill="#DB5D31"
        fillRule="nonzero"
      />
      <path
        d="M103.9 34.9c2.6-3.3 2.8-6.2 2.2-8.5-.4-.6-.8-1.1-1.3-1.6C100.5 20.5 93 21 88 25.9c-3 3-5.3 11.2-6.7 17 9.6-5.1 17.1-1.1 22.6-8z"
        fill="#F26C33"
        fillRule="nonzero"
      />
      <path
        d="M76.1 56.8l9.5-9.5-.5-.5-9.4 9.4c.9.9 1.1 2.3.3 3.1-.8.8-2.1.6-3.1-.3-.4-.4-.7-.9-.8-1.4-.1-.6-.6-1.1-1.2-1.2-.5-.1-1-.4-1.4-.8-.4-.4-.6-.8-.7-1.2-.2.8 0 1.6.6 2.3.4.4.9.6 1.4.7.6.1 1.1.6 1.2 1.2.1.5.3 1 .7 1.4.9.9 2.4 1 3.3 0 1-.7 1-2.2.1-3.2z"
        fill="#D6B176"
        fillRule="nonzero"
      />
      <path
        d="M71 56.6c.6.1 1.1.6 1.2 1.2.1.5.4 1 .8 1.4.9.9 2.3 1.1 3.1.3.8-.8.6-2.1-.3-3.1l9.4-9.4-2.8-2.8-9.5 9.5-.1-.1c-.9-.9-2.4-1-3.3 0-.3.3-.5.7-.6 1.1.1.4.4.9.7 1.2.4.3.9.6 1.4.7z"
        fill="#ECC580"
        fillRule="nonzero"
      />
      <path
        d="M82.2 43.8l-.2 2.3 1.9-.4-.2 2.3s2.4-.2 2.3-.4c0-.1-2.3-3.2-3.8-3.8z"
        fill="#DB5D31"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default NutritionIcon;
