import { appConfig, NodeEnvironments, CDN_RALLY_PROD_URL, CDN_RALLY_NON_PROD_URL } from '@health-activity-ui/shared';
import { AxiosError } from 'axios';
import { parseISO } from 'date-fns';
import Cookies from 'js-cookie';
import shortId from 'shortid';
/**
 * @function isDefined
 * @description returns whether or not a value is defined (not null or undefined)
 * @param {unknown} value
 * @return {boolean}
 */

export const isDefined = (value: unknown): boolean => typeof value !== 'undefined' && value !== null;

/**
 * @function noop
 * @description returns empty for no operation functions
 * @return {null}
 */

export const noop = () => {};
/**
 * @function generateUniqueKey()
 *
 * @desc
 *
 * Why the use of shortId to generate collection keys?
 *
 * Short answer: Index as key is an anti-pattern.
 *
 * `key` is required prop when iterating over a collection of elements because
 * it helps React to identify and reconcile (read and update) the differences
 * between the virtual and real DOM. Since the index is not unique it can cause
 * re-render issues when the collection changes. With that said there are some
 * cases when you can use `index` as `key`, such as when the collection
 * will not change.
 *
 * Source: https://medium.com/@robinpokorny/index-as-a-key-is-an-anti-pattern-e0349aece318
 *
 * @return {string} unique, non-sequential id
 *
 *  Example:
 *  <ul>
 *    list.map(item => <li key={generateUniqueKey()}>item</li>)
 *  </ul>
 * */
export const generateUniqueKey = (): string => shortId.generate();

/**
 * @function ordinalSuffix
 * @description returns the ordinal suffix of a number. 1st, 2nd, 3rd, 13th, etc
 * @param {number} number
 * @return {string}
 */
export const ordinalSuffix = (number: number): string => {
  const j = number % 10;
  const k = number % 100;

  if (j === 1 && k !== 11) {
    return number + 'st';
  }

  if (j === 2 && k !== 12) {
    return number + 'nd';
  }

  if (j === 3 && k !== 13) {
    return number + 'rd';
  }

  return number + 'th';
};

/**
 * @function lightOrDark
 *
 * @description Calculates whether a color code is dark or light.
 *
 * @param {string} colorCode user can pass either HEX or RGB color code
 *
 * @return {string} returns 'light' or 'dark'
 * */
export const lightOrDark = (colorCode: string): string => {
  let r, g, b;

  // Check the format of the color, HEX or RGB?
  if (colorCode.match(/^rgb/)) {
    const regex = /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/;
    // If HEX --> store the red, green, blue values in separate variables
    const color: RegExpMatchArray = colorCode.match(regex);

    r = color[1];
    g = color[2];
    b = color[3];
  } else {
    // If RGB --> Convert it to HEX: http://gist.github.com/983661
    const color: number = +('0x' + colorCode.slice(1).replace(colorCode.length < 5 && /./g, '$&$&'));
    // Handle unsupported color code / bad string
    if (Number.isNaN(color)) {
      return 'light';
    }

    r = color >> 16;
    g = (color >> 8) & 255;
    b = color & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  return hsp > 127.5 ? 'light' : 'dark';
};

/**
 * @function adjustFontColor
 *
 * @description Calculate if a background color is light or dark then
 * set the font color based on the background color's brightness. Darker
 * backgrounds will display a light font color and lighter backgrounds will
 * display a darker font color.
 *
 * @param {string} id Element Id to get background color and set font color
 * @param {string} [colorCode] optionally user can pass the background colorCode
 *
 * @return {string} returns 'light' or 'dark'
 * */
export const adjustFontColor = (id: string, colorCode?: string): string => {
  const element = document.getElementById(`${id}`);

  if (isDefined(element)) {
    // Get the element's background color
    const bgColor = isDefined(colorCode)
      ? colorCode
      : window.getComputedStyle(element, null).getPropertyValue('background-color');

    // Call lightOrDark function to get the brightness (light or dark)
    const brightness = lightOrDark(bgColor);

    // If the background color is dark, add the light-text class to it
    if (brightness === 'dark') {
      element.classList.add('font-light');
      return 'light';
    } else {
      element.classList.add('font-dark');
      return 'dark';
    }
  }
};

export interface IReflectResponse {
  status: string;
  data?: any /* eslint-disable-line */;
  error?: Error;
}

/**
 * @function promiseAllSettled
 *
 * @description Takes an array of promises and returns the data of the fulfilled promises
 * without short-circuiting on rejected promises. This allows the user to run async functions
 * "concurrently" (like Promise.all) while gracefully handling rejected promises. It will outperform
 * 'synchronous' async / await operations.
 *
 * Why not use Promise.all?
 * Promise.all will throw an error on rejected promises and cause components
 * not to render properly, promiseAllSettled helps avoid this.
 *
 * Currently Promise.promiseAllSettled is an ECMAScript proposal at stage 3.
 * For more info: https://github.com/tc39/proposal-promise-promiseAllSettled
 *
 * @param {Promise<any>[]} promises an array of promises
 *
 * @example https://codesandbox.io/s/allsettled-fgs3e
 *
 * @return {Promise<any[]>} an array of fulfilled promises data
 * */
export const promiseAllSettled = async (
  /* eslint-disable-next-line */
  promises: Promise<any>[]
  /* eslint-disable-next-line */
): Promise<any[]> => {
  // eslint-disable-next-line
  const reflect = (promise: Promise<any>): Promise<IReflectResponse> =>
    promise.then(
      (data) => ({ status: 'fulfilled', data }),
      (error) => ({ status: 'rejected', error })
    );

  const results = await Promise.all(promises.map(reflect));
  return results.map((p) => (p.status === 'fulfilled' ? p.data : undefined));
};

export interface UrlQueryParamResponse {
  [key: string]: string;
}

/**
 * @function getUrlQueryParams
 *
 * @description Parses a url with query params.
 *
 * @param {string} [url]
 *
 * @return {UrlQueryParamResponse} an object with query params
 * */

export const getUrlQueryParams = (url?: string): UrlQueryParamResponse => {
  url = !url ? window.location.search : url;
  const query = url.indexOf('/?') > 0 ? url.substr(url.indexOf('/?') + 2) : url.substr(1);

  const params = {};

  query.split('&').forEach((item): void => {
    const param = item.split('=');
    // decodeURIComponent, gets the unencoded version of an encoded component of a URI.
    params[param[0]] = decodeURIComponent(param[1]);
  });

  return params;
};

/**
 * @function hex2
 *
 * @description converts a number to hexidecimal
 *
 * @param {string} c
 *
 * @return {string}
 * */

export const hex2 = (c: number): string => {
  c = Math.round(c);

  if (c < 0) {
    c = 0;
  }

  if (c > 255) {
    c = 255;
  }

  let s = c.toString(16);

  if (s.length < 2) {
    s = '0' + s;
  }

  return s;
};

/**
 * @function color
 *
 * @description takes in rgb values and converts it to a hex string
 *
 * @param {string} r
 * @param {string} g
 * @param {string} b
 *
 * @return {string}
 * */

export const color = (r: number, g: number, b: number): string => {
  return '#' + hex2(r) + hex2(g) + hex2(b);
};

/**
 * @function shade
 *
 * @description takes in a hex color value and brightens or darkens the value
 * pass in negative colors to for a darker shade, positive values for a lighter
 * shade
 *
 * @param {string} col
 * @param {string} light
 *
 * @return {string}
 * */

export const shade = (col: string, light: number): string => {
  let r = parseInt(col.substr(1, 2), 16);
  let g = parseInt(col.substr(3, 2), 16);
  let b = parseInt(col.substr(5, 2), 16);

  if (light < 0) {
    r = (1 + light) * r;
    g = (1 + light) * g;
    b = (1 + light) * b;
  } else {
    r = (1 - light) * r + light * 255;
    g = (1 - light) * g + light * 255;
    b = (1 - light) * b + light * 255;
  }

  return color(r, g, b);
};

/**
 * @function padZeros
 *
 * @description Format an integer with leading zeros
 *
 * @param {number} input
 * @param {number} totalLength
 *
 * @return {string} a number string with leading zeros
 * */
export const padZeros = (input: number, totalLength: number): string => {
  let output = input.toString();
  while (output.length < totalLength) {
    output = `0${output}`;
  }
  return output;
};

/**
 * @function getStackTrace
 *
 * @description Gets and formats stack trace to the current position in the code.
 *
 * @return {string[]}
 * */
export const getStackTrace = (): string[] => {
  const targetObject: { stack?: string } = {};
  Error.captureStackTrace(targetObject, getStackTrace);
  return targetObject.stack?.split('\n').map((s) => s.trim());
};

/**
 * @function getFirstSentence
 *
 * @description Gets the first sentence from a string
 *
 * @param {string} text Block of text to extract first sentence from
 *
 * @return {string}
 * */
export const getFirstSentence = (text: string): string => {
  const regex = /^(.*?)[.?!](?=\s[A-Z])/;
  return regex.exec(text) !== null ? regex.exec(text)[0] : text;
};

/**
 * @function isWindowsMachine
 *
 * @description Detects if machine is windows
 *
 * @return {boolean}
 * */
export const isWindowsMachine = (): boolean => navigator.appVersion.indexOf('Win') !== -1;

export const detectLanguage = (): string => {
  return Cookies.get('x_rally_locale');
};

export const isDevEnv = (): boolean =>
  ['rally-dev.com', 'localhost'].filter((x) => window.location.hostname.indexOf(x) >= 0).length > 0;

export const IS_LOCAL = (): boolean => process.env.NODE_ENV === NodeEnvironments.Development;

export const IS_TEST = (): boolean => process.env.NODE_ENV === NodeEnvironments.Test;

export const IS_PRODUCTION = (): boolean => !IS_LOCAL() && !IS_TEST();

export const IS_BS = (): boolean => {
  const bsDomain = 'bluesteel.werally.in';
  const host = window?.location?.host;
  return host?.endsWith('.' + bsDomain) || host === bsDomain;
};

export const IS_PROD = (): boolean => {
  const prodDomain = 'werally.com';
  const host = window?.location?.host;
  return host?.endsWith('.' + prodDomain) || host === prodDomain;
};

export const isExternalLink = (url: string): boolean => {
  return !url.includes(appConfig.HAUI_APP_HA_BASE_DOMAIN) || url.includes('/sso/') || url.includes('//coach.');
};

export const stringToKebab = (text: string): string => {
  return text.replace(/\s/g, '-').toLowerCase();
};

export const camelToKebab = (text: string): string => {
  return camelToSeparatorCase(text, '-').toLowerCase();
};

export const camelToSnake = (text: string, useCase: 'upper' | 'lower' | 'unchanged' = 'unchanged'): string => {
  switch (useCase) {
    case 'upper':
      return camelToSeparatorCase(text, '_').toUpperCase();
    case 'lower':
      return camelToSeparatorCase(text, '_').toLowerCase();
    default:
      return camelToSeparatorCase(text, '_');
  }
};

export const camelToSeparatorCase = (camelCaseString: string, separator: string): string => {
  return camelCaseString
    .trim()
    .split(/^([a-z|A-Z][a-z]+)([A-Z][a-z]+)/g)
    .filter((i) => Boolean(i))
    .join(separator);
};

export const capitalize = (string: string) => `${string.charAt(0).toUpperCase()}${string.slice(1)}`;

export const isNumber = (value: string) => /^\d+(\.\d+)?$/.test(value);

export const isoDateFormat = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)(((-|\+)(\d{2}):(\d{2})|Z)?)$/;

export function isIsoDateString(value: unknown): boolean {
  return value && typeof value === 'string' && isoDateFormat.test(value);
}

export function handleDates(body: unknown, path = '') {
  if (body === null || body === undefined || typeof body !== 'object') {
    return body;
  }
  // iso date fields to convert with timezone (just selected fields because some of them are intentionally to ignore tz - e.g. WHAT-1799)
  const isoFieldsToParseWithTz: string[] = ['instanceDetails.dates.openDate', 'instanceDetails.dates.lastJoinableDate'];
  for (const key of Object.keys(body)) {
    const value = body[key];
    if (isIsoDateString(value)) {
      // parseISO automatically does timezone date conversions if the timezone is included, so we need to strip the timezone from the string.
      body[key] = isoFieldsToParseWithTz.some((f) => `${path}.${key}`.endsWith(f))
        ? parseISO(value)
        : parseISO(value.slice(0, 20));
    } else if (typeof value === 'object') {
      handleDates(value, `${path}.${key}`);
    }
  }
  return body;
}

/**
 * @description Returns the url source for a users avatar
 * */
export const createAvatarSrc = (rallyId: string): string => {
  if (!rallyId) return;
  return `${IS_BS() || IS_PROD() ? CDN_RALLY_PROD_URL : CDN_RALLY_NON_PROD_URL}/avatars/${rallyId}.png`;
};

/**
 * @description Returns the first letter in string and sets it to uppercase
 * primarly used for avatars
 * */
export const getFirstLetter = (str: string): string => {
  return str ? str.charAt(0).toUpperCase() : '';
};

/**
 * @description adds elipsis for multiline truncation based on character count
 * */
export const truncateTextByCount = (msg: string, count = 128) => {
  return msg.length > count ? `${msg.substr(0, count)}...` : msg;
};

export async function handleErrors(error: AxiosError) {
  // Any status codes outside range of 2xx
  // For now do nothing until we find out more from LWR
  if (error.response) {
    if (error.response.status === 401) {
      // Do something, call window.huginn.provisionOIDCSession()
      // just console.log for now until get clarification
      // if there needs to be some kind of ui change, or just call huginn silently
      // eslint-disable-next-line no-console
      console.log(401);
    }

    if (error.response.status === 403 || error.response.status === 404) {
      return Promise.reject(error.response);
    }
  }
}

export const debounce = (func, wait: number, immediate = false) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      if (!immediate) func.apply(this, args);
    }, wait);
    if (immediate && !timeout) func.apply(this, args);
  };
};

export const round = (value, precision = 0) => {
  const exponent = Math.pow(10, precision);
  return Math.round(value * exponent) / exponent;
};

export const getCharactersRemaining = (value: string | undefined, characterCount: number) => {
  return value ? characterCount - value.length : characterCount;
};

export async function tryCatchHandler<D>(promise: Promise<D>) {
  if (!promise || !promise.then) {
    throw new Error(`The argument supplied must be a promise`);
  }
  try {
    const data = await promise;
    return [data, null];
  } catch (error) {
    return [null, error];
  }
}
