import classnames from 'classnames';
import React from 'react';
import { LabelProps } from './forms.interface';
import useErrorStyles from './use-error-styles.hook';

export const Label = ({
  name,
  /**
   * The `<Input>`'s `id` field the `<Label>` is associated with.
   * If `id` is passed to `<Input>` then must pass the same unique value to `htmlFor` prop
   * so the `<Label>`'s `htmlFor` attribute can specify which input it is associated with.
   *
   * Else if no `id` is passed to `<Input>` will default to unique `name` prop passed to
   * both `<Input>` and `<Label>`.
   * */
  htmlFor,
  children,
  // for useErrorStyles
  errorClassName,
  errorStyle,
  className: propClassName,
  style: propStyle,
  ...rest
}: LabelProps) => {
  const { className, style } = useErrorStyles({
    name,
    errorClassName,
    errorStyle,
    className: propClassName,
    style: propStyle,
  });

  return (
    <label htmlFor={htmlFor || name} {...rest} style={style} className={classnames('rds-label', className)}>
      {children}
    </label>
  );
};
