import { AsyncAction, AsyncState } from './use-async.models';
import { Status } from '@health-activity-ui/shared';

function asyncReducer<D>(_state: unknown, action: AsyncAction<D>): AsyncState<D> {
  switch (action.type) {
    case Status.loading: {
      return { status: Status.loading, data: null, error: null };
    }
    case Status.loaded: {
      return { status: Status.loaded, data: action.data, error: null };
    }
    case Status.error: {
      return { status: Status.error, data: null, error: action.error };
    }
  }
}

export default asyncReducer;
