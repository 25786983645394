import React from 'react';

export const HeartBeatIcon = (): React.ReactElement => (
  <svg width={76} height={76} aria-hidden="true">
    <defs>
      <path id="heartbeaticon__c" d="M.092.015h39.123v33.37H.092z" />
      <path id="heartbeaticon__e" d="M0 .05h47.89v23.355H0z" />
      <filter x="-4.2%" y="-4.2%" width="108.3%" height="108.3%" filterUnits="objectBoundingBox" id="heartbeaticon__a">
        <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation={1} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix
          values="0 0 0 0 0.980392157 0 0 0 0 0.980392157 0 0 0 0 0.980392157 0 0 0 0.5 0"
          in="shadowBlurOuter1"
        />
      </filter>
      <circle id="heartbeaticon__b" cx={36} cy={36} r={36} />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(2 2)">
        <use fill="#000" filter="url(#heartbeaticon__a)" xlinkHref="#heartbeaticon__b" />
        <use fill="#FFF" xlinkHref="#heartbeaticon__b" />
      </g>
      <g transform="translate(17.623 24.411)">
        <mask id="heartbeaticon__d" fill="#fff">
          <use xlinkHref="#heartbeaticon__c" />
        </mask>
        <path
          d="M35.859 3.208c-4.475-4.257-11.73-4.257-16.205 0-4.475-4.257-11.73-4.257-16.206 0-4.475 4.257-4.475 11.159 0 15.416l15.03 14.298c.65.617 1.702.617 2.35 0l15.03-14.298c4.476-4.257 4.476-11.16 0-15.416"
          fill="#AD4437"
          fillRule="nonzero"
          mask="url(#heartbeaticon__d)"
        />
      </g>
      <path
        d="M51.859 26.033c-4.476-4.257-11.731-4.257-16.206 0-4.475-4.257-11.73-4.257-16.205 0-4.475 4.257-4.475 11.159 0 15.416l15.03 14.298a1.723 1.723 0 002.35 0l15.03-14.298c4.476-4.257 4.476-11.16 0-15.416"
        fill="#ED5D4B"
        fillRule="nonzero"
      />
      <path
        d="M54.398 38.296c-.04 0-.077.009-.116.01v-.01h-7.42l-6.427-18.753c-.29-.848-1.155-1.395-2.09-1.32-.935.074-1.692.75-1.83 1.631L33.24 40.733l-2.7-9.607c-.227-.809-.977-1.384-1.855-1.425-.88-.047-1.683.465-1.99 1.25l-2.882 7.345h-3.67a3.477 3.477 0 00-2.718-1.282c-1.892 0-3.425 1.458-3.425 3.258 0 1.799 1.533 3.257 3.425 3.257a3.47 3.47 0 002.801-1.388h4.99c.843 0 1.598-.498 1.893-1.25l1.16-2.958 3.581 12.74c.238.845 1.042 1.427 1.952 1.427.038 0 .076-.002.115-.004.957-.052 1.743-.734 1.885-1.635l3.446-21.977 4.227 12.33c.27.791 1.046 1.327 1.92 1.327l9.584-.034c1.116 0 6.91-.827 6.91-1.889 0-1.061-6.374-1.922-7.49-1.922"
        fill="#56B97D"
        fillRule="nonzero"
      />
      <path
        d="M54.398 40.577h-9.002c-.875 0-1.65-.536-1.921-1.327l-3.906-11.395c-.08-.233-.435-.205-.473.037l-3.294 21.005c-.142.901-.928 1.583-1.885 1.635a2.58 2.58 0 01-.115.003c-.91 0-1.714-.581-1.952-1.425l-3.36-11.96c-.068-.243-.424-.26-.517-.024l-.863 2.2c-.295.752-1.05 1.25-1.894 1.25h-4.989a3.471 3.471 0 01-2.801 1.39c-1.608 0-2.954-1.056-3.322-2.478a2.93 2.93 0 00.281 2.222c.548 1.005 1.6 1.725 2.786 1.807 1.263.088 2.392-.48 3.056-1.38h4.989c.844 0 1.599-.498 1.894-1.25l1.16-2.957 3.581 12.74c.238.845 1.042 1.426 1.952 1.426.038 0 .076 0 .115-.003.957-.052 1.743-.734 1.885-1.635l3.446-21.977 4.227 12.33c.27.791 1.046 1.327 1.921 1.327h8.925c.92 0 5.756-.825 6.594-1.186.56-.242 1.218-.376.974-.734-.315.672-6.667.359-7.49.359"
        fill="#40A96D"
        fillRule="nonzero"
      />
      <g transform="translate(14 18.165)">
        <mask id="heartbeaticon__f" fill="#fff">
          <use xlinkHref="#heartbeaticon__e" />
        </mask>
        <path
          d="M3.425 19.89c.98 0 1.863.394 2.487 1.023.168.17.407.258.653.258h2.701a.81.81 0 00.75-.482l2.679-6.343c.307-.784 1.11-1.296 1.99-1.249.878.04 1.628.616 1.856 1.425l2.457 8.745c.055.194.35.179.38-.02l3.136-19.996c.139-.882.897-1.558 1.83-1.633.936-.074 1.8.473 2.09 1.32l6.228 17.664c.12.34.455.57.832.57h6.904c1.69 0 7.491.881 7.491.881 0-1.061-6.375-1.922-7.49-1.922-.04 0-.078.009-.117.01v-.01h-7.42L26.436 1.378c-.29-.848-1.155-1.395-2.09-1.32-.934.074-1.692.75-1.83 1.631L19.24 22.568l-2.7-9.607c-.227-.809-.977-1.385-1.855-1.425-.88-.047-1.683.465-1.99 1.25L9.812 20.13H6.143c-.86-1.069-2.393-1.62-4.002-1.06-1.23.43-2.03 1.57-2.125 2.813-.087 1.124.08-2.017 3.409-1.994"
          fill="#74C391"
          fillRule="nonzero"
          mask="url(#heartbeaticon__f)"
        />
      </g>
    </g>
  </svg>
);

export default HeartBeatIcon;
