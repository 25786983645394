import React from 'react';
import { ImgProps } from './img.interface';

/**
 * <Img />
 *
 * Renders an image, enforcing the usage of the alt and src attributes
 */

export const Img = ({ className, src, alt, style, onError }: ImgProps): React.ReactElement => (
  <img className={className} src={src} alt={alt} style={style} onError={onError} />
);

export default Img;
