import { ChallengeStatus, IStartAndEndDates, ITime } from '@health-activity-ui/shared';
import { addDays, differenceInCalendarDays, isBefore } from 'date-fns';

import { TFunction } from 'i18next';

/**
 * Currently, all of these util functions assume that each startAndEndDate
 * passed in, the startDate comes before the endDate
 * /

 /**
 * @method calculateDaysBetweenDates
 * @description Returns the amount of days in between dates
 * @param {IStartAndEndDates} startAndEndDates
 * @return {number}
 */

export const calculateDaysBetweenDates = (startAndEndDates: IStartAndEndDates): number => {
  const startDate = new Date(startAndEndDates.startDate);
  const endDate = new Date(startAndEndDates.endDate);
  const millisecondsInOneDay = 24 * 60 * 60 * 1000;

  return Math.round(Math.abs((startDate.getTime() - endDate.getTime()) / millisecondsInOneDay));
};

/**
 * @method calculateTimeBetweenDates
 * @description takes in an a startAndEndDates object, and returns an object containing the days,
 *  hours, minutes, and seconds between startAndEndDate.startDate and startAndEndDate.endDate
 * @param {IStartAndEndDates} startAndEndDates
 * @return {ITime}
 */

export const calculateTimeBetweenDates = (startAndEndDates: IStartAndEndDates): ITime => {
  const startDate = new Date(startAndEndDates.startDate).getTime();
  const endDate = new Date(startAndEndDates.endDate).getTime();

  // get total seconds between the times
  let delta = Math.abs(endDate - startDate) / 1000;

  // calculate (and subtract) whole days
  const days = Math.floor(delta / 86400);
  delta -= days * 86400;

  // calculate (and subtract) whole hours
  const hours = Math.floor(delta / 3600) % 24;
  delta -= hours * 3600;

  // calculate (and subtract) whole minutes
  const minutes = Math.floor(delta / 60) % 60;
  delta -= minutes * 60;

  // what's left is seconds
  const seconds = delta % 60; // in theory the modulus is not required

  const timeBetweenDates: ITime = {
    days,
    hours,
    minutes,
    seconds,
  };

  return timeBetweenDates;
};

/**
 * @method calculateMillisecondsBetweenDates
 * @description takes in an a startAndEndDates object, and returns an integer representing
 * milliseconds between startAndEndDate.startDate and startAndEndDate.endDate
 * @param {IStartAndEndDates} startAndEndDates
 * @return {number}
 */

export const calculateMillisecondsBetweenDates = (startAndEndDates: IStartAndEndDates): number => {
  const startTime = new Date(startAndEndDates.startDate).getTime();
  const endTime = new Date(startAndEndDates.endDate).getTime();

  return endTime - startTime;
};

/**
 * @method calculatePercentageTimeElapsed
 * @description return a number representing the percentage of total time of a
 * challenge that has elapsed.
 * @param {IStartAndEndDates} startAndEndDates
 * @param {IStartAndEndDates} startAndCurrentDates
 * @return {number}
 */
export const calculatePercentageTimeElapsed = (
  startAndEndDates: IStartAndEndDates,
  startAndCurrentDates: IStartAndEndDates
): number => {
  /* For now, we are only checking if the challenge endDate has passed */
  if (isBefore(new Date(startAndEndDates.endDate), new Date())) {
    return 100;
  } else {
    const startDateToCurrentDateMilliseconds = calculateMillisecondsBetweenDates(startAndCurrentDates);

    const startDateToEndDateMilliseconds = calculateMillisecondsBetweenDates(startAndEndDates);

    return (startDateToCurrentDateMilliseconds / startDateToEndDateMilliseconds) * 100;
  }
};

/**
 * @description Calculates the number of full days between start and end date of a challenge.
 * @example: https://codesandbox.io/s/getduration-16onj
 */
export const calculateChallengePeriod = (startDate: Date, endDate: Date): number => {
  const start = new Date(
    startDate.getUTCFullYear(),
    startDate.getUTCMonth(),
    startDate.getUTCDate(),
    startDate.getUTCHours(),
    startDate.getUTCMinutes(),
    startDate.getUTCSeconds()
  );
  let end = new Date(
    endDate.getUTCFullYear(),
    endDate.getUTCMonth(),
    endDate.getUTCDate(),
    endDate.getUTCHours(),
    endDate.getUTCMinutes(),
    endDate.getUTCSeconds()
  );

  // If challenge endDate ends at 23:59 UTC we want to add 1 day.
  if (end.toString().indexOf('23:59') > 0) {
    end = addDays(end, 1);
  }

  return differenceInCalendarDays(end, start);
};

/**
 * @method getZenTimerDate
 * @description get the date for ZenTimer component if challenge is in Waiting or Active state.
 * @param {Date | string} startDate
 * @param {Date | string} endDate
 * @param {ChallengeStatus} status
 * @return {Date | string} returns startDate while in Waiting / endDate in Active / else undefined
 */
export const getZenTimerDate = (
  startDate: Date | string,
  endDate: Date | string,
  status: ChallengeStatus
): Date | string =>
  status === ChallengeStatus.Waiting ? startDate : status === ChallengeStatus.Active ? endDate : undefined;

/**
 * @description gets the date prompt to be displayed with ZenTimer for challenges
 */
export const getDatePrompt = (
  status: ChallengeStatus,
  endDate: Date,
  numberOfDays: number,
  isInviteOnly: boolean,
  t: TFunction
): string => {
  switch (true) {
    // Private
    case isInviteOnly && status === ChallengeStatus.Completed:
      return t('completedOn', { endDate });

    case isInviteOnly && status === ChallengeStatus.Waiting:
      return t('challengeStartsIn(Private)', { numberOfDays });

    case isInviteOnly && status === ChallengeStatus.Active:
      return t('challengeEndsIn(Private)', { numberOfDays });

    // Public
    case !isInviteOnly && status === ChallengeStatus.Completed:
      return t('completedOn', { endDate });

    case !isInviteOnly && status === ChallengeStatus.Waiting:
      return t('challengeStartsIn', { numberOfDays });

    case !isInviteOnly && status === ChallengeStatus.Active:
      return t('challengeEndsIn', { numberOfDays });

    default:
      return '';
  }
};
