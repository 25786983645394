import { tryCatchHandler } from '@health-activity-ui/utils';
import { getAdvantageProfile } from '../../api/advantage.api';
import { updateAvatar } from '../../api/engage.api';
import { withProvider } from '../with-provider';
import { Profile } from './profile.models';
import { profileReducer } from './profile.reducer';
import { ProfileState, PROFILE_ACTION_TYPES } from './profile.types';

export const initialState: ProfileState = {
  data: {
    affiliation: '',
    client: '',
    isImpersonator: false,
    profileName: '',
    rallyId: '',
    sessionId: '',
  },
  status: null,
};

async function updateProfile(dispatch) {
  dispatch({ type: PROFILE_ACTION_TYPES.PROFILE_FETCH_LOADING });
  try {
    const profile = await getAdvantageProfile();
    dispatch({ type: PROFILE_ACTION_TYPES.PROFILE_FETCH_LOADED, payload: profile });
    return profile;
  } catch (error) {
    dispatch({
      type: PROFILE_ACTION_TYPES.PROFILE_FETCH_FAILED,
      payload: { error },
    });
  }
}

async function updateProfileAvatar(avatar: string, dispatch): Promise<boolean> {
  dispatch({ type: PROFILE_ACTION_TYPES.PROFILE_FETCH_LOADING });
  const [data, error] = await tryCatchHandler(updateAvatar(avatar));
  if (data?.avatarSaved) {
    dispatch({ type: PROFILE_ACTION_TYPES.PROFILE_UPDATE_AVATAR_LOADED, payload: { avatar: data?.avatarUrl } });
  } else {
    dispatch({ type: PROFILE_ACTION_TYPES.PROFILE_FETCH_FAILED, payload: { error } });
  }
  return data?.avatarSaved ?? false;
}

const [ProfileProvider, useProfile] = withProvider<ProfileState, Profile | Record<string, unknown>>({
  reducer: profileReducer,
  displayName: 'ProfileProvider',
  initialState,
});

export { ProfileProvider, useProfile, updateProfile, updateProfileAvatar };
