import { isDefined } from '@health-activity-ui/utils';
import React, { MutableRefObject, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SeeLessButton from './see-less-button';
import SeeMoreButton from './see-more-button';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  allData: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cb(data: any[]): void;
  scrollDestinationRef: MutableRefObject<HTMLDivElement>;
  sliceEnd?: number;
  buttonClassName?: string;
  ariaContentLabel: string;
  buttonSize?: 'small' | '';
  handleShowMoreCb?(): void | Promise<void>;
  handleShowLessCb?(): void;
  hasMore?: boolean;
}

export const SeeButtonGroup = ({
  allData,
  cb,
  scrollDestinationRef,
  sliceEnd = 2,
  buttonClassName = '',
  ariaContentLabel,
  handleShowMoreCb,
  handleShowLessCb,
  hasMore = true,
  buttonSize = '',
}: Props): React.ReactElement => {
  const [showMore, setShowMore] = useState(hasMore);
  const [showLess, setShowLess] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setShowMore(hasMore);
  }, [hasMore]);

  const scrollToTop = (): void => {
    if (scrollDestinationRef) {
      const y = scrollDestinationRef.current.getBoundingClientRect().top + window.pageYOffset - 170;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  const handleShowMore = (): void => {
    if (isDefined(handleShowMoreCb)) {
      handleShowMoreCb();
      setShowLess(true);
    } else {
      cb(allData);
      setShowLess(true);
      setShowMore(false);
    }
  };

  const handleShowLess = (): void => {
    if (isDefined(handleShowLessCb)) {
      handleShowLessCb();
    } else {
      const next = allData.slice(0, sliceEnd);
      cb(next);
      setShowMore(true);
    }
    setShowLess(false);
    scrollToTop();
  };

  return (
    <div className="level">
      <div className="level-item">
        {showMore && (
          <SeeMoreButton
            ariaLabel={`${t('showMore')} ${ariaContentLabel}`}
            onClick={(): void => handleShowMore()}
            className={showLess ? `has-rds-mr-8 ${buttonClassName}` : buttonClassName}
            size={buttonSize}
          />
        )}

        {showLess && (
          <SeeLessButton
            ariaLabel={`${t('showLess')} ${ariaContentLabel}`}
            onClick={(): void => handleShowLess()}
            className={buttonClassName}
            size={buttonSize}
          />
        )}
      </div>
    </div>
  );
};

export default SeeButtonGroup;
