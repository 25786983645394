import { AccordionButton, AccordionButtonProps } from '@reach/accordion';
import React, { ElementType } from 'react';

interface AccordionHeaderProps extends AccordionButtonProps {
  headingLevel?: ElementType;
}

export const AccordionHeader = ({ headingLevel = 'h2', children }: AccordionHeaderProps) => {
  const HeadingLevel = headingLevel;
  return (
    <HeadingLevel className="accordion-header">
      <AccordionButton as="div" data-testid="accordion-button">
        {children}
      </AccordionButton>
    </HeadingLevel>
  );
};
