import React from 'react';

export const HandHoldStarsIcon = (): React.ReactElement => (
  <svg width={76} height={76} aria-hidden="true">
    <defs>
      <path id="hand_hold_stars_prefix__c" d="M0 .122h43.006v1.156H0z" />
      <path
        d="M5.482.362l-.948 4.034-4.057.327c-.4.032-.524.565-.183.778l3.47 2.166-.948 4.034c-.093.397.366.683.67.418L6.58 9.424l3.47 2.166c.342.213.75-.144.598-.52l-1.56-3.832 3.094-2.695c.304-.265.098-.772-.3-.74l-4.058.327L6.265.3a.404.404 0 00-.38-.26.408.408 0 00-.403.323z"
        id="hand_hold_stars_prefix__e"
      />
      <path
        d="M5.482.362l-.948 4.034-4.057.327c-.4.032-.524.565-.183.778l3.47 2.166-.948 4.034c-.093.397.366.683.67.418L6.58 9.424l3.47 2.166c.342.213.75-.144.598-.52l-1.56-3.832 3.094-2.695c.304-.265.098-.772-.3-.74l-4.058.327L6.265.3a.404.404 0 00-.38-.26.408.408 0 00-.403.323z"
        id="hand_hold_stars_prefix__g"
      />
      <path
        d="M5.482.362l-.948 4.034-4.057.327c-.4.032-.524.565-.183.778l3.47 2.166-.948 4.034c-.093.397.366.683.67.418L6.58 9.424l3.47 2.166c.342.213.75-.144.598-.52l-1.56-3.832 3.094-2.695c.304-.265.098-.772-.3-.74l-4.058.327L6.265.3a.404.404 0 00-.38-.26.408.408 0 00-.403.323z"
        id="hand_hold_stars_prefix__i"
      />
      <path
        d="M5.482.362l-.948 4.034-4.057.327c-.4.032-.524.565-.183.778l3.47 2.166-.948 4.034c-.093.397.366.683.67.418L6.58 9.424l3.47 2.166c.342.213.75-.144.598-.52l-1.56-3.832 3.094-2.695c.304-.265.098-.772-.3-.74l-4.058.327L6.265.3a.404.404 0 00-.38-.26.408.408 0 00-.403.323z"
        id="hand_hold_stars_prefix__k"
      />
      <path
        d="M5.482.362l-.948 4.034-4.057.327c-.4.032-.524.565-.183.778l3.47 2.166-.948 4.034c-.093.397.366.683.67.418L6.58 9.424l3.47 2.166c.342.213.75-.144.598-.52l-1.56-3.832 3.094-2.695c.304-.265.098-.772-.3-.74l-4.058.327L6.265.3a.404.404 0 00-.38-.26.408.408 0 00-.403.323z"
        id="hand_hold_stars_prefix__m"
      />
      <path
        d="M5.482.362l-.948 4.034-4.057.327c-.4.032-.524.565-.183.778l3.47 2.166-.948 4.034c-.093.397.366.683.67.418L6.58 9.424l3.47 2.166c.342.213.75-.144.598-.52l-1.56-3.832 3.094-2.695c.304-.265.098-.772-.3-.74l-4.058.327L6.265.3a.404.404 0 00-.38-.26.408.408 0 00-.403.323z"
        id="hand_hold_stars_prefix__o"
      />
      <path
        d="M5.482.362l-.948 4.034-4.057.327c-.4.032-.524.565-.183.778l3.47 2.166-.948 4.034c-.093.397.366.683.67.418L6.58 9.424l3.47 2.166c.342.213.75-.144.598-.52l-1.56-3.832 3.094-2.695c.304-.265.098-.772-.3-.74l-4.058.327L6.265.3a.404.404 0 00-.38-.26.408.408 0 00-.403.323z"
        id="hand_hold_stars_prefix__q"
      />
      <path
        d="M5.482.362l-.948 4.034-4.057.327c-.4.032-.524.565-.183.778l3.47 2.166-.948 4.034c-.093.397.366.683.67.418L6.58 9.424l3.47 2.166c.342.213.75-.144.598-.52l-1.56-3.832 3.094-2.695c.304-.265.098-.772-.3-.74l-4.058.327L6.265.3a.404.404 0 00-.38-.26.408.408 0 00-.403.323z"
        id="hand_hold_stars_prefix__s"
      />
      <path
        d="M5.482.362l-.948 4.034-4.057.327c-.4.032-.524.565-.183.778l3.47 2.166-.948 4.034c-.093.397.366.683.67.418L6.58 9.424l3.47 2.166c.342.213.75-.144.598-.52l-1.56-3.832 3.094-2.695c.304-.265.098-.772-.3-.74l-4.058.327L6.265.3a.404.404 0 00-.38-.26.408.408 0 00-.403.323z"
        id="hand_hold_stars_prefix__u"
      />
      <path
        d="M5.482.362l-.948 4.034-4.057.327c-.4.032-.524.565-.183.778l3.47 2.166-.948 4.034c-.093.397.366.683.67.418L6.58 9.424l3.47 2.166c.342.213.75-.144.598-.52l-1.56-3.832 3.094-2.695c.304-.265.098-.772-.3-.74l-4.058.327L6.265.3a.404.404 0 00-.38-.26.408.408 0 00-.403.323z"
        id="hand_hold_stars_prefix__w"
      />
      <path
        d="M5.482.362l-.948 4.034-4.057.327c-.4.032-.524.565-.183.778l3.47 2.166-.948 4.034c-.093.397.366.683.67.418L6.58 9.424l3.47 2.166c.342.213.75-.144.598-.52l-1.56-3.832 3.094-2.695c.304-.265.098-.772-.3-.74l-4.058.327L6.265.3a.404.404 0 00-.38-.26.408.408 0 00-.403.323z"
        id="hand_hold_stars_prefix__y"
      />
      <path
        d="M5.482.362l-.948 4.034-4.057.327c-.4.032-.524.565-.183.778l3.47 2.166-.948 4.034c-.093.397.366.683.67.418L6.58 9.424l3.47 2.166c.342.213.75-.144.598-.52l-1.56-3.832 3.094-2.695c.304-.265.098-.772-.3-.74l-4.058.327L6.265.3a.404.404 0 00-.38-.26.408.408 0 00-.403.323z"
        id="hand_hold_stars_prefix__A"
      />
      <path
        d="M5.482.362l-.948 4.034-4.057.327c-.4.032-.524.565-.183.778l3.47 2.166-.948 4.034c-.093.397.366.683.67.418L6.58 9.424l3.47 2.166c.342.213.75-.144.598-.52l-1.56-3.832 3.094-2.695c.304-.265.098-.772-.3-.74l-4.058.327L6.265.3a.404.404 0 00-.38-.26.408.408 0 00-.403.323z"
        id="hand_hold_stars_prefix__C"
      />
      <path
        d="M5.482.362l-.948 4.034-4.057.327c-.4.032-.524.565-.183.778l3.47 2.166-.948 4.034c-.093.397.366.683.67.418L6.58 9.424l3.47 2.166c.342.213.75-.144.598-.52l-1.56-3.832 3.094-2.695c.304-.265.098-.772-.3-.74l-4.058.327L6.265.3a.404.404 0 00-.38-.26.408.408 0 00-.403.323z"
        id="hand_hold_stars_prefix__E"
      />
      <path
        d="M5.482.362l-.948 4.034-4.057.327c-.4.032-.524.565-.183.778l3.47 2.166-.948 4.034c-.093.397.366.683.67.418L6.58 9.424l3.47 2.166c.342.213.75-.144.598-.52l-1.56-3.832 3.094-2.695c.304-.265.098-.772-.3-.74l-4.058.327L6.265.3a.404.404 0 00-.38-.26.408.408 0 00-.403.323z"
        id="hand_hold_stars_prefix__G"
      />
      <path
        d="M5.482.362l-.948 4.034-4.057.327c-.4.032-.524.565-.183.778l3.47 2.166-.948 4.034c-.093.397.366.683.67.418L6.58 9.424l3.47 2.166c.342.213.75-.144.598-.52l-1.56-3.832 3.094-2.695c.304-.265.098-.772-.3-.74l-4.058.327L6.265.3a.404.404 0 00-.38-.26.408.408 0 00-.403.323z"
        id="hand_hold_stars_prefix__I"
      />
      <path
        d="M5.482.362l-.948 4.034-4.057.327c-.4.032-.524.565-.183.778l3.47 2.166-.948 4.034c-.093.397.366.683.67.418L6.58 9.424l3.47 2.166c.342.213.75-.144.598-.52l-1.56-3.832 3.094-2.695c.304-.265.098-.772-.3-.74l-4.058.327L6.265.3a.404.404 0 00-.38-.26.408.408 0 00-.403.323z"
        id="hand_hold_stars_prefix__K"
      />
      <path
        d="M5.482.362l-.948 4.034-4.057.327c-.4.032-.524.565-.183.778l3.47 2.166-.948 4.034c-.093.397.366.683.67.418L6.58 9.424l3.47 2.166c.342.213.75-.144.598-.52l-1.56-3.832 3.094-2.695c.304-.265.098-.772-.3-.74l-4.058.327L6.265.3a.404.404 0 00-.38-.26.408.408 0 00-.403.323z"
        id="hand_hold_stars_prefix__M"
      />
      <path
        d="M5.482.362l-.948 4.034-4.057.327c-.4.032-.524.565-.183.778l3.47 2.166-.948 4.034c-.093.397.366.683.67.418L6.58 9.424l3.47 2.166c.342.213.75-.144.598-.52l-1.56-3.832 3.094-2.695c.304-.265.098-.772-.3-.74l-4.058.327L6.265.3a.404.404 0 00-.38-.26.408.408 0 00-.403.323z"
        id="hand_hold_stars_prefix__O"
      />
      <path
        d="M5.482.362l-.948 4.034-4.057.327c-.4.032-.524.565-.183.778l3.47 2.166-.948 4.034c-.093.397.366.683.67.418L6.58 9.424l3.47 2.166c.342.213.75-.144.598-.52l-1.56-3.832 3.094-2.695c.304-.265.098-.772-.3-.74l-4.058.327L6.265.3a.404.404 0 00-.38-.26.408.408 0 00-.403.323z"
        id="hand_hold_stars_prefix__Q"
      />
      <path
        d="M3.002.256l-.359 2.44-2.43.411a.25.25 0 00-.069.471l2.208 1.095-.358 2.438a.25.25 0 00.426.212L4.144 5.56l2.208 1.095a.25.25 0 00.332-.34L5.541 4.132 7.265 2.37a.25.25 0 00-.22-.422l-2.43.413L3.47.177a.244.244 0 00-.22-.134.248.248 0 00-.249.213z"
        id="hand_hold_stars_prefix__S"
      />
      <path
        d="M3.002.256l-.359 2.44-2.43.411a.25.25 0 00-.069.471l2.208 1.095-.358 2.438a.25.25 0 00.426.212L4.144 5.56l2.208 1.095a.25.25 0 00.332-.34L5.541 4.132 7.265 2.37a.25.25 0 00-.22-.422l-2.43.413L3.47.177a.244.244 0 00-.22-.134.248.248 0 00-.249.213z"
        id="hand_hold_stars_prefix__U"
      />
      <path
        d="M3.002.256l-.359 2.44-2.43.411a.25.25 0 00-.069.471l2.208 1.095-.358 2.438a.25.25 0 00.426.212L4.144 5.56l2.208 1.095a.25.25 0 00.332-.34L5.541 4.132 7.265 2.37a.25.25 0 00-.22-.422l-2.43.413L3.47.177a.244.244 0 00-.22-.134.248.248 0 00-.249.213z"
        id="hand_hold_stars_prefix__W"
      />
      <path
        d="M3.002.256l-.359 2.44-2.43.411a.25.25 0 00-.069.471l2.208 1.095-.358 2.438a.25.25 0 00.426.212L4.144 5.56l2.208 1.095a.25.25 0 00.332-.34L5.541 4.132 7.265 2.37a.25.25 0 00-.22-.422l-2.43.413L3.47.177a.244.244 0 00-.22-.134.248.248 0 00-.249.213z"
        id="hand_hold_stars_prefix__Y"
      />
      <path
        d="M3.002.256l-.359 2.44-2.43.411a.25.25 0 00-.069.471l2.208 1.095-.358 2.438a.25.25 0 00.426.212L4.144 5.56l2.208 1.095a.25.25 0 00.332-.34L5.541 4.132 7.265 2.37a.25.25 0 00-.22-.422l-2.43.413L3.47.177a.244.244 0 00-.22-.134.248.248 0 00-.249.213z"
        id="hand_hold_stars_prefix__aa"
      />
      <path
        d="M3.002.256l-.359 2.44-2.43.411a.25.25 0 00-.069.471l2.208 1.095-.358 2.438a.25.25 0 00.426.212L4.144 5.56l2.208 1.095a.25.25 0 00.332-.34L5.541 4.132 7.265 2.37a.25.25 0 00-.22-.422l-2.43.413L3.47.177a.244.244 0 00-.22-.134.248.248 0 00-.249.213z"
        id="hand_hold_stars_prefix__ac"
      />
      <path
        d="M3.002.256l-.359 2.44-2.43.411a.25.25 0 00-.069.471l2.208 1.095-.358 2.438a.25.25 0 00.426.212L4.144 5.56l2.208 1.095a.25.25 0 00.332-.34L5.541 4.132 7.265 2.37a.25.25 0 00-.22-.422l-2.43.413L3.47.177a.244.244 0 00-.22-.134.248.248 0 00-.249.213z"
        id="hand_hold_stars_prefix__ae"
      />
      <path
        d="M3.002.256l-.359 2.44-2.43.411a.25.25 0 00-.069.471l2.208 1.095-.358 2.438a.25.25 0 00.426.212L4.144 5.56l2.208 1.095a.25.25 0 00.332-.34L5.541 4.132 7.265 2.37a.25.25 0 00-.22-.422l-2.43.413L3.47.177a.244.244 0 00-.22-.134.248.248 0 00-.249.213z"
        id="hand_hold_stars_prefix__ag"
      />
      <path
        d="M3.002.256l-.359 2.44-2.43.411a.25.25 0 00-.069.471l2.208 1.095-.358 2.438a.25.25 0 00.426.212L4.144 5.56l2.208 1.095a.25.25 0 00.332-.34L5.541 4.132 7.265 2.37a.25.25 0 00-.22-.422l-2.43.413L3.47.177a.244.244 0 00-.22-.134.248.248 0 00-.249.213z"
        id="hand_hold_stars_prefix__ai"
      />
      <path
        d="M3.002.256l-.359 2.44-2.43.411a.25.25 0 00-.069.471l2.208 1.095-.358 2.438a.25.25 0 00.426.212L4.144 5.56l2.208 1.095a.25.25 0 00.332-.34L5.541 4.132 7.265 2.37a.25.25 0 00-.22-.422l-2.43.413L3.47.177a.244.244 0 00-.22-.134.248.248 0 00-.249.213z"
        id="hand_hold_stars_prefix__ak"
      />
      <path
        d="M3.002.256l-.359 2.44-2.43.411a.25.25 0 00-.069.471l2.208 1.095-.358 2.438a.25.25 0 00.426.212L4.144 5.56l2.208 1.095a.25.25 0 00.332-.34L5.541 4.132 7.265 2.37a.25.25 0 00-.22-.422l-2.43.413L3.47.177a.244.244 0 00-.22-.134.248.248 0 00-.249.213z"
        id="hand_hold_stars_prefix__am"
      />
      <path
        d="M3.002.256l-.359 2.44-2.43.411a.25.25 0 00-.069.471l2.208 1.095-.358 2.438a.25.25 0 00.426.212L4.144 5.56l2.208 1.095a.25.25 0 00.332-.34L5.541 4.132 7.265 2.37a.25.25 0 00-.22-.422l-2.43.413L3.47.177a.244.244 0 00-.22-.134.248.248 0 00-.249.213z"
        id="hand_hold_stars_prefix__ao"
      />
      <path
        d="M3.002.256l-.359 2.44-2.43.411a.25.25 0 00-.069.471l2.208 1.095-.358 2.438a.25.25 0 00.426.212L4.144 5.56l2.208 1.095a.25.25 0 00.332-.34L5.541 4.132 7.265 2.37a.25.25 0 00-.22-.422l-2.43.413L3.47.177a.244.244 0 00-.22-.134.248.248 0 00-.249.213z"
        id="hand_hold_stars_prefix__aq"
      />
      <path
        d="M3.002.256l-.359 2.44-2.43.411a.25.25 0 00-.069.471l2.208 1.095-.358 2.438a.25.25 0 00.426.212L4.144 5.56l2.208 1.095a.25.25 0 00.332-.34L5.541 4.132 7.265 2.37a.25.25 0 00-.22-.422l-2.43.413L3.47.177a.244.244 0 00-.22-.134.248.248 0 00-.249.213z"
        id="hand_hold_stars_prefix__as"
      />
      <path
        d="M3.002.256l-.359 2.44-2.43.411a.25.25 0 00-.069.471l2.208 1.095-.358 2.438a.25.25 0 00.426.212L4.144 5.56l2.208 1.095a.25.25 0 00.332-.34L5.541 4.132 7.265 2.37a.25.25 0 00-.22-.422l-2.43.413L3.47.177a.244.244 0 00-.22-.134.248.248 0 00-.249.213z"
        id="hand_hold_stars_prefix__au"
      />
      <path
        d="M3.002.256l-.359 2.44-2.43.411a.25.25 0 00-.069.471l2.208 1.095-.358 2.438a.25.25 0 00.426.212L4.144 5.56l2.208 1.095a.25.25 0 00.332-.34L5.541 4.132 7.265 2.37a.25.25 0 00-.22-.422l-2.43.413L3.47.177a.244.244 0 00-.22-.134.248.248 0 00-.249.213z"
        id="hand_hold_stars_prefix__aw"
      />
      <path
        d="M3.002.256l-.359 2.44-2.43.411a.25.25 0 00-.069.471l2.208 1.095-.358 2.438a.25.25 0 00.426.212L4.144 5.56l2.208 1.095a.25.25 0 00.332-.34L5.541 4.132 7.265 2.37a.25.25 0 00-.22-.422l-2.43.413L3.47.177a.244.244 0 00-.22-.134.248.248 0 00-.249.213z"
        id="hand_hold_stars_prefix__ay"
      />
      <path
        d="M3.002.256l-.359 2.44-2.43.411a.25.25 0 00-.069.471l2.208 1.095-.358 2.438a.25.25 0 00.426.212L4.144 5.56l2.208 1.095a.25.25 0 00.332-.34L5.541 4.132 7.265 2.37a.25.25 0 00-.22-.422l-2.43.413L3.47.177a.244.244 0 00-.22-.134.248.248 0 00-.249.213z"
        id="hand_hold_stars_prefix__aA"
      />
      <path
        d="M3.002.256l-.359 2.44-2.43.411a.25.25 0 00-.069.471l2.208 1.095-.358 2.438a.25.25 0 00.426.212L4.144 5.56l2.208 1.095a.25.25 0 00.332-.34L5.541 4.132 7.265 2.37a.25.25 0 00-.22-.422l-2.43.413L3.47.177a.244.244 0 00-.22-.134.248.248 0 00-.249.213z"
        id="hand_hold_stars_prefix__aC"
      />
      <path
        d="M3.002.256l-.359 2.44-2.43.411a.25.25 0 00-.069.471l2.208 1.095-.358 2.438a.25.25 0 00.426.212L4.144 5.56l2.208 1.095a.25.25 0 00.332-.34L5.541 4.132 7.265 2.37a.25.25 0 00-.22-.422l-2.43.413L3.47.177a.244.244 0 00-.22-.134.248.248 0 00-.249.213z"
        id="hand_hold_stars_prefix__aE"
      />
      <path
        d="M4.233.242L3.094 2.736.437 2.393c-.261-.034-.412.296-.22.481L2.17 4.76 1.033 7.253c-.112.245.146.494.377.363l2.346-1.329L5.71 8.172c.192.185.503.01.453-.257l-.504-2.706 2.346-1.33c.23-.13.164-.488-.097-.522L5.25 3.014 4.746.308a.27.27 0 00-.268-.227.265.265 0 00-.245.161z"
        id="hand_hold_stars_prefix__aG"
      />
      <path
        d="M4.233.242L3.094 2.736.437 2.393c-.261-.034-.412.296-.22.481L2.17 4.76 1.033 7.253c-.112.245.146.494.377.363l2.346-1.329L5.71 8.172c.192.185.503.01.453-.257l-.504-2.706 2.346-1.33c.23-.13.164-.488-.097-.522L5.25 3.014 4.746.308a.27.27 0 00-.268-.227.265.265 0 00-.245.161z"
        id="hand_hold_stars_prefix__aI"
      />
      <path
        d="M4.233.242L3.094 2.736.437 2.393c-.261-.034-.412.296-.22.481L2.17 4.76 1.033 7.253c-.112.245.146.494.377.363l2.346-1.329L5.71 8.172c.192.185.503.01.453-.257l-.504-2.706 2.346-1.33c.23-.13.164-.488-.097-.522L5.25 3.014 4.746.308a.27.27 0 00-.268-.227.265.265 0 00-.245.161z"
        id="hand_hold_stars_prefix__aK"
      />
      <path
        d="M4.233.242L3.094 2.736.437 2.393c-.261-.034-.412.296-.22.481L2.17 4.76 1.033 7.253c-.112.245.146.494.377.363l2.346-1.329L5.71 8.172c.192.185.503.01.453-.257l-.504-2.706 2.346-1.33c.23-.13.164-.488-.097-.522L5.25 3.014 4.746.308a.27.27 0 00-.268-.227.265.265 0 00-.245.161z"
        id="hand_hold_stars_prefix__aM"
      />
      <path
        d="M4.233.242L3.094 2.736.437 2.393c-.261-.034-.412.296-.22.481L2.17 4.76 1.033 7.253c-.112.245.146.494.377.363l2.346-1.329L5.71 8.172c.192.185.503.01.453-.257l-.504-2.706 2.346-1.33c.23-.13.164-.488-.097-.522L5.25 3.014 4.746.308a.27.27 0 00-.268-.227.265.265 0 00-.245.161z"
        id="hand_hold_stars_prefix__aO"
      />
      <path
        d="M4.233.242L3.094 2.736.437 2.393c-.261-.034-.412.296-.22.481L2.17 4.76 1.033 7.253c-.112.245.146.494.377.363l2.346-1.329L5.71 8.172c.192.185.503.01.453-.257l-.504-2.706 2.346-1.33c.23-.13.164-.488-.097-.522L5.25 3.014 4.746.308a.27.27 0 00-.268-.227.265.265 0 00-.245.161z"
        id="hand_hold_stars_prefix__aQ"
      />
      <path
        d="M4.233.242L3.094 2.736.437 2.393c-.261-.034-.412.296-.22.481L2.17 4.76 1.033 7.253c-.112.245.146.494.377.363l2.346-1.329L5.71 8.172c.192.185.503.01.453-.257l-.504-2.706 2.346-1.33c.23-.13.164-.488-.097-.522L5.25 3.014 4.746.308a.27.27 0 00-.268-.227.265.265 0 00-.245.161z"
        id="hand_hold_stars_prefix__aS"
      />
      <path
        d="M4.233.242L3.094 2.736.437 2.393c-.261-.034-.412.296-.22.481L2.17 4.76 1.033 7.253c-.112.245.146.494.377.363l2.346-1.329L5.71 8.172c.192.185.503.01.453-.257l-.504-2.706 2.346-1.33c.23-.13.164-.488-.097-.522L5.25 3.014 4.746.308a.27.27 0 00-.268-.227.265.265 0 00-.245.161z"
        id="hand_hold_stars_prefix__aU"
      />
      <path
        d="M4.233.242L3.094 2.736.437 2.393c-.261-.034-.412.296-.22.481L2.17 4.76 1.033 7.253c-.112.245.146.494.377.363l2.346-1.329L5.71 8.172c.192.185.503.01.453-.257l-.504-2.706 2.346-1.33c.23-.13.164-.488-.097-.522L5.25 3.014 4.746.308a.27.27 0 00-.268-.227.265.265 0 00-.245.161z"
        id="hand_hold_stars_prefix__aW"
      />
      <path
        d="M4.233.242L3.094 2.736.437 2.393c-.261-.034-.412.296-.22.481L2.17 4.76 1.033 7.253c-.112.245.146.494.377.363l2.346-1.329L5.71 8.172c.192.185.503.01.453-.257l-.504-2.706 2.346-1.33c.23-.13.164-.488-.097-.522L5.25 3.014 4.746.308a.27.27 0 00-.268-.227.265.265 0 00-.245.161z"
        id="hand_hold_stars_prefix__aY"
      />
      <path
        d="M4.233.242L3.094 2.736.437 2.393c-.261-.034-.412.296-.22.481L2.17 4.76 1.033 7.253c-.112.245.146.494.377.363l2.346-1.329L5.71 8.172c.192.185.503.01.453-.257l-.504-2.706 2.346-1.33c.23-.13.164-.488-.097-.522L5.25 3.014 4.746.308a.27.27 0 00-.268-.227.265.265 0 00-.245.161z"
        id="hand_hold_stars_prefix__ba"
      />
      <path
        d="M4.233.242L3.094 2.736.437 2.393c-.261-.034-.412.296-.22.481L2.17 4.76 1.033 7.253c-.112.245.146.494.377.363l2.346-1.329L5.71 8.172c.192.185.503.01.453-.257l-.504-2.706 2.346-1.33c.23-.13.164-.488-.097-.522L5.25 3.014 4.746.308a.27.27 0 00-.268-.227.265.265 0 00-.245.161z"
        id="hand_hold_stars_prefix__bc"
      />
      <path
        d="M4.233.242L3.094 2.736.437 2.393c-.261-.034-.412.296-.22.481L2.17 4.76 1.033 7.253c-.112.245.146.494.377.363l2.346-1.329L5.71 8.172c.192.185.503.01.453-.257l-.504-2.706 2.346-1.33c.23-.13.164-.488-.097-.522L5.25 3.014 4.746.308a.27.27 0 00-.268-.227.265.265 0 00-.245.161z"
        id="hand_hold_stars_prefix__be"
      />
      <path
        d="M4.233.242L3.094 2.736.437 2.393c-.261-.034-.412.296-.22.481L2.17 4.76 1.033 7.253c-.112.245.146.494.377.363l2.346-1.329L5.71 8.172c.192.185.503.01.453-.257l-.504-2.706 2.346-1.33c.23-.13.164-.488-.097-.522L5.25 3.014 4.746.308a.27.27 0 00-.268-.227.265.265 0 00-.245.161z"
        id="hand_hold_stars_prefix__bg"
      />
      <path
        d="M4.233.242L3.094 2.736.437 2.393c-.261-.034-.412.296-.22.481L2.17 4.76 1.033 7.253c-.112.245.146.494.377.363l2.346-1.329L5.71 8.172c.192.185.503.01.453-.257l-.504-2.706 2.346-1.33c.23-.13.164-.488-.097-.522L5.25 3.014 4.746.308a.27.27 0 00-.268-.227.265.265 0 00-.245.161z"
        id="hand_hold_stars_prefix__bi"
      />
      <path
        d="M4.233.242L3.094 2.736.437 2.393c-.261-.034-.412.296-.22.481L2.17 4.76 1.033 7.253c-.112.245.146.494.377.363l2.346-1.329L5.71 8.172c.192.185.503.01.453-.257l-.504-2.706 2.346-1.33c.23-.13.164-.488-.097-.522L5.25 3.014 4.746.308a.27.27 0 00-.268-.227.265.265 0 00-.245.161z"
        id="hand_hold_stars_prefix__bk"
      />
      <path
        d="M4.233.242L3.094 2.736.437 2.393c-.261-.034-.412.296-.22.481L2.17 4.76 1.033 7.253c-.112.245.146.494.377.363l2.346-1.329L5.71 8.172c.192.185.503.01.453-.257l-.504-2.706 2.346-1.33c.23-.13.164-.488-.097-.522L5.25 3.014 4.746.308a.27.27 0 00-.268-.227.265.265 0 00-.245.161z"
        id="hand_hold_stars_prefix__bm"
      />
      <path
        d="M4.233.242L3.094 2.736.437 2.393c-.261-.034-.412.296-.22.481L2.17 4.76 1.033 7.253c-.112.245.146.494.377.363l2.346-1.329L5.71 8.172c.192.185.503.01.453-.257l-.504-2.706 2.346-1.33c.23-.13.164-.488-.097-.522L5.25 3.014 4.746.308a.27.27 0 00-.268-.227.265.265 0 00-.245.161z"
        id="hand_hold_stars_prefix__bo"
      />
      <path
        d="M4.233.242L3.094 2.736.437 2.393c-.261-.034-.412.296-.22.481L2.17 4.76 1.033 7.253c-.112.245.146.494.377.363l2.346-1.329L5.71 8.172c.192.185.503.01.453-.257l-.504-2.706 2.346-1.33c.23-.13.164-.488-.097-.522L5.25 3.014 4.746.308a.27.27 0 00-.268-.227.265.265 0 00-.245.161z"
        id="hand_hold_stars_prefix__bq"
      />
      <path
        d="M4.233.242L3.094 2.736.437 2.393c-.261-.034-.412.296-.22.481L2.17 4.76 1.033 7.253c-.112.245.146.494.377.363l2.346-1.329L5.71 8.172c.192.185.503.01.453-.257l-.504-2.706 2.346-1.33c.23-.13.164-.488-.097-.522L5.25 3.014 4.746.308a.27.27 0 00-.268-.227.265.265 0 00-.245.161z"
        id="hand_hold_stars_prefix__bs"
      />
      <path
        d="M5.2.159L3.403 2.815.477 1.819c-.288-.098-.525.253-.347.512L1.95 4.97.154 7.624c-.176.262.064.61.351.51l2.919-1.026 1.818 2.637c.18.26.565.124.564-.198L5.79 6.276 8.71 5.25c.286-.101.285-.534-.003-.632l-2.928-.995L5.764.35a.32.32 0 00-.315-.33.298.298 0 00-.25.138z"
        id="hand_hold_stars_prefix__bu"
      />
      <path
        d="M5.2.159L3.403 2.815.477 1.819c-.288-.098-.525.253-.347.512L1.95 4.97.154 7.624c-.176.262.064.61.351.51l2.919-1.026 1.818 2.637c.18.26.565.124.564-.198L5.79 6.276 8.71 5.25c.286-.101.285-.534-.003-.632l-2.928-.995L5.764.35a.32.32 0 00-.315-.33.298.298 0 00-.25.138z"
        id="hand_hold_stars_prefix__bw"
      />
      <path
        d="M5.2.159L3.403 2.815.477 1.819c-.288-.098-.525.253-.347.512L1.95 4.97.154 7.624c-.176.262.064.61.351.51l2.919-1.026 1.818 2.637c.18.26.565.124.564-.198L5.79 6.276 8.71 5.25c.286-.101.285-.534-.003-.632l-2.928-.995L5.764.35a.32.32 0 00-.315-.33.298.298 0 00-.25.138z"
        id="hand_hold_stars_prefix__by"
      />
      <path
        d="M5.2.159L3.403 2.815.477 1.819c-.288-.098-.525.253-.347.512L1.95 4.97.154 7.624c-.176.262.064.61.351.51l2.919-1.026 1.818 2.637c.18.26.565.124.564-.198L5.79 6.276 8.71 5.25c.286-.101.285-.534-.003-.632l-2.928-.995L5.764.35a.32.32 0 00-.315-.33.298.298 0 00-.25.138z"
        id="hand_hold_stars_prefix__bA"
      />
      <path
        d="M5.2.159L3.403 2.815.477 1.819c-.288-.098-.525.253-.347.512L1.95 4.97.154 7.624c-.176.262.064.61.351.51l2.919-1.026 1.818 2.637c.18.26.565.124.564-.198L5.79 6.276 8.71 5.25c.286-.101.285-.534-.003-.632l-2.928-.995L5.764.35a.32.32 0 00-.315-.33.298.298 0 00-.25.138z"
        id="hand_hold_stars_prefix__bC"
      />
      <path
        d="M5.2.159L3.403 2.815.477 1.819c-.288-.098-.525.253-.347.512L1.95 4.97.154 7.624c-.176.262.064.61.351.51l2.919-1.026 1.818 2.637c.18.26.565.124.564-.198L5.79 6.276 8.71 5.25c.286-.101.285-.534-.003-.632l-2.928-.995L5.764.35a.32.32 0 00-.315-.33.298.298 0 00-.25.138z"
        id="hand_hold_stars_prefix__bE"
      />
      <path
        d="M5.2.159L3.403 2.815.477 1.819c-.288-.098-.525.253-.347.512L1.95 4.97.154 7.624c-.176.262.064.61.351.51l2.919-1.026 1.818 2.637c.18.26.565.124.564-.198L5.79 6.276 8.71 5.25c.286-.101.285-.534-.003-.632l-2.928-.995L5.764.35a.32.32 0 00-.315-.33.298.298 0 00-.25.138z"
        id="hand_hold_stars_prefix__bG"
      />
      <path
        d="M5.2.159L3.403 2.815.477 1.819c-.288-.098-.525.253-.347.512L1.95 4.97.154 7.624c-.176.262.064.61.351.51l2.919-1.026 1.818 2.637c.18.26.565.124.564-.198L5.79 6.276 8.71 5.25c.286-.101.285-.534-.003-.632l-2.928-.995L5.764.35a.32.32 0 00-.315-.33.298.298 0 00-.25.138z"
        id="hand_hold_stars_prefix__bI"
      />
      <path
        d="M5.2.159L3.403 2.815.477 1.819c-.288-.098-.525.253-.347.512L1.95 4.97.154 7.624c-.176.262.064.61.351.51l2.919-1.026 1.818 2.637c.18.26.565.124.564-.198L5.79 6.276 8.71 5.25c.286-.101.285-.534-.003-.632l-2.928-.995L5.764.35a.32.32 0 00-.315-.33.298.298 0 00-.25.138z"
        id="hand_hold_stars_prefix__bK"
      />
      <path
        d="M5.2.159L3.403 2.815.477 1.819c-.288-.098-.525.253-.347.512L1.95 4.97.154 7.624c-.176.262.064.61.351.51l2.919-1.026 1.818 2.637c.18.26.565.124.564-.198L5.79 6.276 8.71 5.25c.286-.101.285-.534-.003-.632l-2.928-.995L5.764.35a.32.32 0 00-.315-.33.298.298 0 00-.25.138z"
        id="hand_hold_stars_prefix__bM"
      />
      <path
        d="M5.2.159L3.403 2.815.477 1.819c-.288-.098-.525.253-.347.512L1.95 4.97.154 7.624c-.176.262.064.61.351.51l2.919-1.026 1.818 2.637c.18.26.565.124.564-.198L5.79 6.276 8.71 5.25c.286-.101.285-.534-.003-.632l-2.928-.995L5.764.35a.32.32 0 00-.315-.33.298.298 0 00-.25.138z"
        id="hand_hold_stars_prefix__bO"
      />
      <path
        d="M5.2.159L3.403 2.815.477 1.819c-.288-.098-.525.253-.347.512L1.95 4.97.154 7.624c-.176.262.064.61.351.51l2.919-1.026 1.818 2.637c.18.26.565.124.564-.198L5.79 6.276 8.71 5.25c.286-.101.285-.534-.003-.632l-2.928-.995L5.764.35a.32.32 0 00-.315-.33.298.298 0 00-.25.138z"
        id="hand_hold_stars_prefix__bQ"
      />
      <path
        d="M5.2.159L3.403 2.815.477 1.819c-.288-.098-.525.253-.347.512L1.95 4.97.154 7.624c-.176.262.064.61.351.51l2.919-1.026 1.818 2.637c.18.26.565.124.564-.198L5.79 6.276 8.71 5.25c.286-.101.285-.534-.003-.632l-2.928-.995L5.764.35a.32.32 0 00-.315-.33.298.298 0 00-.25.138z"
        id="hand_hold_stars_prefix__bS"
      />
      <path
        d="M5.2.159L3.403 2.815.477 1.819c-.288-.098-.525.253-.347.512L1.95 4.97.154 7.624c-.176.262.064.61.351.51l2.919-1.026 1.818 2.637c.18.26.565.124.564-.198L5.79 6.276 8.71 5.25c.286-.101.285-.534-.003-.632l-2.928-.995L5.764.35a.32.32 0 00-.315-.33.298.298 0 00-.25.138z"
        id="hand_hold_stars_prefix__bU"
      />
      <path
        d="M5.2.159L3.403 2.815.477 1.819c-.288-.098-.525.253-.347.512L1.95 4.97.154 7.624c-.176.262.064.61.351.51l2.919-1.026 1.818 2.637c.18.26.565.124.564-.198L5.79 6.276 8.71 5.25c.286-.101.285-.534-.003-.632l-2.928-.995L5.764.35a.32.32 0 00-.315-.33.298.298 0 00-.25.138z"
        id="hand_hold_stars_prefix__bW"
      />
      <path
        d="M5.2.159L3.403 2.815.477 1.819c-.288-.098-.525.253-.347.512L1.95 4.97.154 7.624c-.176.262.064.61.351.51l2.919-1.026 1.818 2.637c.18.26.565.124.564-.198L5.79 6.276 8.71 5.25c.286-.101.285-.534-.003-.632l-2.928-.995L5.764.35a.32.32 0 00-.315-.33.298.298 0 00-.25.138z"
        id="hand_hold_stars_prefix__bY"
      />
      <path
        d="M5.2.159L3.403 2.815.477 1.819c-.288-.098-.525.253-.347.512L1.95 4.97.154 7.624c-.176.262.064.61.351.51l2.919-1.026 1.818 2.637c.18.26.565.124.564-.198L5.79 6.276 8.71 5.25c.286-.101.285-.534-.003-.632l-2.928-.995L5.764.35a.32.32 0 00-.315-.33.298.298 0 00-.25.138z"
        id="hand_hold_stars_prefix__ca"
      />
      <path
        d="M5.2.159L3.403 2.815.477 1.819c-.288-.098-.525.253-.347.512L1.95 4.97.154 7.624c-.176.262.064.61.351.51l2.919-1.026 1.818 2.637c.18.26.565.124.564-.198L5.79 6.276 8.71 5.25c.286-.101.285-.534-.003-.632l-2.928-.995L5.764.35a.32.32 0 00-.315-.33.298.298 0 00-.25.138z"
        id="hand_hold_stars_prefix__cc"
      />
      <path
        d="M5.2.159L3.403 2.815.477 1.819c-.288-.098-.525.253-.347.512L1.95 4.97.154 7.624c-.176.262.064.61.351.51l2.919-1.026 1.818 2.637c.18.26.565.124.564-.198L5.79 6.276 8.71 5.25c.286-.101.285-.534-.003-.632l-2.928-.995L5.764.35a.32.32 0 00-.315-.33.298.298 0 00-.25.138z"
        id="hand_hold_stars_prefix__ce"
      />
      <path
        d="M5.2.159L3.403 2.815.477 1.819c-.288-.098-.525.253-.347.512L1.95 4.97.154 7.624c-.176.262.064.61.351.51l2.919-1.026 1.818 2.637c.18.26.565.124.564-.198L5.79 6.276 8.71 5.25c.286-.101.285-.534-.003-.632l-2.928-.995L5.764.35a.32.32 0 00-.315-.33.298.298 0 00-.25.138z"
        id="hand_hold_stars_prefix__cg"
      />
      <path
        d="M2.623.123L1.92 1.605.358 1.364c-.153-.024-.246.172-.135.286l1.129 1.157L.65 4.289c-.07.146.08.299.218.223l1.4-.767 1.128 1.158c.11.113.296.012.27-.148l-.264-1.631 1.4-.767c.137-.076.102-.291-.052-.315L3.188 1.8 2.924.17a.16.16 0 00-.159-.14.155.155 0 00-.142.093z"
        id="hand_hold_stars_prefix__ci"
      />
      <path
        d="M2.623.123L1.92 1.605.358 1.364c-.153-.024-.246.172-.135.286l1.129 1.157L.65 4.289c-.07.146.08.299.218.223l1.4-.767 1.128 1.158c.11.113.296.012.27-.148l-.264-1.631 1.4-.767c.137-.076.102-.291-.052-.315L3.188 1.8 2.924.17a.16.16 0 00-.159-.14.155.155 0 00-.142.093z"
        id="hand_hold_stars_prefix__ck"
      />
      <path
        d="M2.623.123L1.92 1.605.358 1.364c-.153-.024-.246.172-.135.286l1.129 1.157L.65 4.289c-.07.146.08.299.218.223l1.4-.767 1.128 1.158c.11.113.296.012.27-.148l-.264-1.631 1.4-.767c.137-.076.102-.291-.052-.315L3.188 1.8 2.924.17a.16.16 0 00-.159-.14.155.155 0 00-.142.093z"
        id="hand_hold_stars_prefix__cm"
      />
      <path
        d="M2.623.123L1.92 1.605.358 1.364c-.153-.024-.246.172-.135.286l1.129 1.157L.65 4.289c-.07.146.08.299.218.223l1.4-.767 1.128 1.158c.11.113.296.012.27-.148l-.264-1.631 1.4-.767c.137-.076.102-.291-.052-.315L3.188 1.8 2.924.17a.16.16 0 00-.159-.14.155.155 0 00-.142.093z"
        id="hand_hold_stars_prefix__co"
      />
      <path
        d="M2.623.123L1.92 1.605.358 1.364c-.153-.024-.246.172-.135.286l1.129 1.157L.65 4.289c-.07.146.08.299.218.223l1.4-.767 1.128 1.158c.11.113.296.012.27-.148l-.264-1.631 1.4-.767c.137-.076.102-.291-.052-.315L3.188 1.8 2.924.17a.16.16 0 00-.159-.14.155.155 0 00-.142.093z"
        id="hand_hold_stars_prefix__cq"
      />
      <path
        d="M2.623.123L1.92 1.605.358 1.364c-.153-.024-.246.172-.135.286l1.129 1.157L.65 4.289c-.07.146.08.299.218.223l1.4-.767 1.128 1.158c.11.113.296.012.27-.148l-.264-1.631 1.4-.767c.137-.076.102-.291-.052-.315L3.188 1.8 2.924.17a.16.16 0 00-.159-.14.155.155 0 00-.142.093z"
        id="hand_hold_stars_prefix__cs"
      />
      <path
        d="M2.623.123L1.92 1.605.358 1.364c-.153-.024-.246.172-.135.286l1.129 1.157L.65 4.289c-.07.146.08.299.218.223l1.4-.767 1.128 1.158c.11.113.296.012.27-.148l-.264-1.631 1.4-.767c.137-.076.102-.291-.052-.315L3.188 1.8 2.924.17a.16.16 0 00-.159-.14.155.155 0 00-.142.093z"
        id="hand_hold_stars_prefix__cu"
      />
      <path
        d="M2.623.123L1.92 1.605.358 1.364c-.153-.024-.246.172-.135.286l1.129 1.157L.65 4.289c-.07.146.08.299.218.223l1.4-.767 1.128 1.158c.11.113.296.012.27-.148l-.264-1.631 1.4-.767c.137-.076.102-.291-.052-.315L3.188 1.8 2.924.17a.16.16 0 00-.159-.14.155.155 0 00-.142.093z"
        id="hand_hold_stars_prefix__cw"
      />
      <path
        d="M2.623.123L1.92 1.605.358 1.364c-.153-.024-.246.172-.135.286l1.129 1.157L.65 4.289c-.07.146.08.299.218.223l1.4-.767 1.128 1.158c.11.113.296.012.27-.148l-.264-1.631 1.4-.767c.137-.076.102-.291-.052-.315L3.188 1.8 2.924.17a.16.16 0 00-.159-.14.155.155 0 00-.142.093z"
        id="hand_hold_stars_prefix__cy"
      />
      <path
        d="M2.623.123L1.92 1.605.358 1.364c-.153-.024-.246.172-.135.286l1.129 1.157L.65 4.289c-.07.146.08.299.218.223l1.4-.767 1.128 1.158c.11.113.296.012.27-.148l-.264-1.631 1.4-.767c.137-.076.102-.291-.052-.315L3.188 1.8 2.924.17a.16.16 0 00-.159-.14.155.155 0 00-.142.093z"
        id="hand_hold_stars_prefix__cA"
      />
      <path
        d="M2.623.123L1.92 1.605.358 1.364c-.153-.024-.246.172-.135.286l1.129 1.157L.65 4.289c-.07.146.08.299.218.223l1.4-.767 1.128 1.158c.11.113.296.012.27-.148l-.264-1.631 1.4-.767c.137-.076.102-.291-.052-.315L3.188 1.8 2.924.17a.16.16 0 00-.159-.14.155.155 0 00-.142.093z"
        id="hand_hold_stars_prefix__cC"
      />
      <path
        d="M2.623.123L1.92 1.605.358 1.364c-.153-.024-.246.172-.135.286l1.129 1.157L.65 4.289c-.07.146.08.299.218.223l1.4-.767 1.128 1.158c.11.113.296.012.27-.148l-.264-1.631 1.4-.767c.137-.076.102-.291-.052-.315L3.188 1.8 2.924.17a.16.16 0 00-.159-.14.155.155 0 00-.142.093z"
        id="hand_hold_stars_prefix__cE"
      />
      <path
        d="M2.623.123L1.92 1.605.358 1.364c-.153-.024-.246.172-.135.286l1.129 1.157L.65 4.289c-.07.146.08.299.218.223l1.4-.767 1.128 1.158c.11.113.296.012.27-.148l-.264-1.631 1.4-.767c.137-.076.102-.291-.052-.315L3.188 1.8 2.924.17a.16.16 0 00-.159-.14.155.155 0 00-.142.093z"
        id="hand_hold_stars_prefix__cG"
      />
      <path
        d="M2.623.123L1.92 1.605.358 1.364c-.153-.024-.246.172-.135.286l1.129 1.157L.65 4.289c-.07.146.08.299.218.223l1.4-.767 1.128 1.158c.11.113.296.012.27-.148l-.264-1.631 1.4-.767c.137-.076.102-.291-.052-.315L3.188 1.8 2.924.17a.16.16 0 00-.159-.14.155.155 0 00-.142.093z"
        id="hand_hold_stars_prefix__cI"
      />
      <path
        d="M2.623.123L1.92 1.605.358 1.364c-.153-.024-.246.172-.135.286l1.129 1.157L.65 4.289c-.07.146.08.299.218.223l1.4-.767 1.128 1.158c.11.113.296.012.27-.148l-.264-1.631 1.4-.767c.137-.076.102-.291-.052-.315L3.188 1.8 2.924.17a.16.16 0 00-.159-.14.155.155 0 00-.142.093z"
        id="hand_hold_stars_prefix__cK"
      />
      <path
        d="M2.623.123L1.92 1.605.358 1.364c-.153-.024-.246.172-.135.286l1.129 1.157L.65 4.289c-.07.146.08.299.218.223l1.4-.767 1.128 1.158c.11.113.296.012.27-.148l-.264-1.631 1.4-.767c.137-.076.102-.291-.052-.315L3.188 1.8 2.924.17a.16.16 0 00-.159-.14.155.155 0 00-.142.093z"
        id="hand_hold_stars_prefix__cM"
      />
      <path
        d="M2.623.123L1.92 1.605.358 1.364c-.153-.024-.246.172-.135.286l1.129 1.157L.65 4.289c-.07.146.08.299.218.223l1.4-.767 1.128 1.158c.11.113.296.012.27-.148l-.264-1.631 1.4-.767c.137-.076.102-.291-.052-.315L3.188 1.8 2.924.17a.16.16 0 00-.159-.14.155.155 0 00-.142.093z"
        id="hand_hold_stars_prefix__cO"
      />
      <path
        d="M2.623.123L1.92 1.605.358 1.364c-.153-.024-.246.172-.135.286l1.129 1.157L.65 4.289c-.07.146.08.299.218.223l1.4-.767 1.128 1.158c.11.113.296.012.27-.148l-.264-1.631 1.4-.767c.137-.076.102-.291-.052-.315L3.188 1.8 2.924.17a.16.16 0 00-.159-.14.155.155 0 00-.142.093z"
        id="hand_hold_stars_prefix__cQ"
      />
      <path
        d="M2.623.123L1.92 1.605.358 1.364c-.153-.024-.246.172-.135.286l1.129 1.157L.65 4.289c-.07.146.08.299.218.223l1.4-.767 1.128 1.158c.11.113.296.012.27-.148l-.264-1.631 1.4-.767c.137-.076.102-.291-.052-.315L3.188 1.8 2.924.17a.16.16 0 00-.159-.14.155.155 0 00-.142.093z"
        id="hand_hold_stars_prefix__cS"
      />
      <path
        d="M2.623.123L1.92 1.605.358 1.364c-.153-.024-.246.172-.135.286l1.129 1.157L.65 4.289c-.07.146.08.299.218.223l1.4-.767 1.128 1.158c.11.113.296.012.27-.148l-.264-1.631 1.4-.767c.137-.076.102-.291-.052-.315L3.188 1.8 2.924.17a.16.16 0 00-.159-.14.155.155 0 00-.142.093z"
        id="hand_hold_stars_prefix__cU"
      />
      <path
        d="M1.741.19L1.46 1.454l-1.29.123a.132.132 0 00-.055.245l1.117.66L.95 3.747a.131.131 0 00.215.127l.973-.858 1.117.66a.132.132 0 00.187-.167l-.516-1.19.972-.858a.132.132 0 00-.1-.23l-1.29.123L1.99.165a.129.129 0 00-.12-.079.13.13 0 00-.129.103z"
        id="hand_hold_stars_prefix__cW"
      />
      <path
        d="M1.741.19L1.46 1.454l-1.29.123a.132.132 0 00-.055.245l1.117.66L.95 3.747a.131.131 0 00.215.127l.973-.858 1.117.66a.132.132 0 00.187-.167l-.516-1.19.972-.858a.132.132 0 00-.1-.23l-1.29.123L1.99.165a.129.129 0 00-.12-.079.13.13 0 00-.129.103z"
        id="hand_hold_stars_prefix__cY"
      />
      <path
        d="M1.741.19L1.46 1.454l-1.29.123a.132.132 0 00-.055.245l1.117.66L.95 3.747a.131.131 0 00.215.127l.973-.858 1.117.66a.132.132 0 00.187-.167l-.516-1.19.972-.858a.132.132 0 00-.1-.23l-1.29.123L1.99.165a.129.129 0 00-.12-.079.13.13 0 00-.129.103z"
        id="hand_hold_stars_prefix__da"
      />
      <path
        d="M1.741.19L1.46 1.454l-1.29.123a.132.132 0 00-.055.245l1.117.66L.95 3.747a.131.131 0 00.215.127l.973-.858 1.117.66a.132.132 0 00.187-.167l-.516-1.19.972-.858a.132.132 0 00-.1-.23l-1.29.123L1.99.165a.129.129 0 00-.12-.079.13.13 0 00-.129.103z"
        id="hand_hold_stars_prefix__dc"
      />
      <path
        d="M1.741.19L1.46 1.454l-1.29.123a.132.132 0 00-.055.245l1.117.66L.95 3.747a.131.131 0 00.215.127l.973-.858 1.117.66a.132.132 0 00.187-.167l-.516-1.19.972-.858a.132.132 0 00-.1-.23l-1.29.123L1.99.165a.129.129 0 00-.12-.079.13.13 0 00-.129.103z"
        id="hand_hold_stars_prefix__de"
      />
      <path
        d="M1.741.19L1.46 1.454l-1.29.123a.132.132 0 00-.055.245l1.117.66L.95 3.747a.131.131 0 00.215.127l.973-.858 1.117.66a.132.132 0 00.187-.167l-.516-1.19.972-.858a.132.132 0 00-.1-.23l-1.29.123L1.99.165a.129.129 0 00-.12-.079.13.13 0 00-.129.103z"
        id="hand_hold_stars_prefix__dg"
      />
      <path
        d="M1.741.19L1.46 1.454l-1.29.123a.132.132 0 00-.055.245l1.117.66L.95 3.747a.131.131 0 00.215.127l.973-.858 1.117.66a.132.132 0 00.187-.167l-.516-1.19.972-.858a.132.132 0 00-.1-.23l-1.29.123L1.99.165a.129.129 0 00-.12-.079.13.13 0 00-.129.103z"
        id="hand_hold_stars_prefix__di"
      />
      <path
        d="M1.741.19L1.46 1.454l-1.29.123a.132.132 0 00-.055.245l1.117.66L.95 3.747a.131.131 0 00.215.127l.973-.858 1.117.66a.132.132 0 00.187-.167l-.516-1.19.972-.858a.132.132 0 00-.1-.23l-1.29.123L1.99.165a.129.129 0 00-.12-.079.13.13 0 00-.129.103z"
        id="hand_hold_stars_prefix__dk"
      />
      <path
        d="M1.741.19L1.46 1.454l-1.29.123a.132.132 0 00-.055.245l1.117.66L.95 3.747a.131.131 0 00.215.127l.973-.858 1.117.66a.132.132 0 00.187-.167l-.516-1.19.972-.858a.132.132 0 00-.1-.23l-1.29.123L1.99.165a.129.129 0 00-.12-.079.13.13 0 00-.129.103z"
        id="hand_hold_stars_prefix__dm"
      />
      <path
        d="M1.741.19L1.46 1.454l-1.29.123a.132.132 0 00-.055.245l1.117.66L.95 3.747a.131.131 0 00.215.127l.973-.858 1.117.66a.132.132 0 00.187-.167l-.516-1.19.972-.858a.132.132 0 00-.1-.23l-1.29.123L1.99.165a.129.129 0 00-.12-.079.13.13 0 00-.129.103z"
        id="hand_hold_stars_prefix__do"
      />
      <path
        d="M1.741.19L1.46 1.454l-1.29.123a.132.132 0 00-.055.245l1.117.66L.95 3.747a.131.131 0 00.215.127l.973-.858 1.117.66a.132.132 0 00.187-.167l-.516-1.19.972-.858a.132.132 0 00-.1-.23l-1.29.123L1.99.165a.129.129 0 00-.12-.079.13.13 0 00-.129.103z"
        id="hand_hold_stars_prefix__dq"
      />
      <path
        d="M1.741.19L1.46 1.454l-1.29.123a.132.132 0 00-.055.245l1.117.66L.95 3.747a.131.131 0 00.215.127l.973-.858 1.117.66a.132.132 0 00.187-.167l-.516-1.19.972-.858a.132.132 0 00-.1-.23l-1.29.123L1.99.165a.129.129 0 00-.12-.079.13.13 0 00-.129.103z"
        id="hand_hold_stars_prefix__ds"
      />
      <path
        d="M1.741.19L1.46 1.454l-1.29.123a.132.132 0 00-.055.245l1.117.66L.95 3.747a.131.131 0 00.215.127l.973-.858 1.117.66a.132.132 0 00.187-.167l-.516-1.19.972-.858a.132.132 0 00-.1-.23l-1.29.123L1.99.165a.129.129 0 00-.12-.079.13.13 0 00-.129.103z"
        id="hand_hold_stars_prefix__du"
      />
      <path
        d="M1.741.19L1.46 1.454l-1.29.123a.132.132 0 00-.055.245l1.117.66L.95 3.747a.131.131 0 00.215.127l.973-.858 1.117.66a.132.132 0 00.187-.167l-.516-1.19.972-.858a.132.132 0 00-.1-.23l-1.29.123L1.99.165a.129.129 0 00-.12-.079.13.13 0 00-.129.103z"
        id="hand_hold_stars_prefix__dw"
      />
      <path
        d="M1.741.19L1.46 1.454l-1.29.123a.132.132 0 00-.055.245l1.117.66L.95 3.747a.131.131 0 00.215.127l.973-.858 1.117.66a.132.132 0 00.187-.167l-.516-1.19.972-.858a.132.132 0 00-.1-.23l-1.29.123L1.99.165a.129.129 0 00-.12-.079.13.13 0 00-.129.103z"
        id="hand_hold_stars_prefix__dy"
      />
      <path
        d="M1.741.19L1.46 1.454l-1.29.123a.132.132 0 00-.055.245l1.117.66L.95 3.747a.131.131 0 00.215.127l.973-.858 1.117.66a.132.132 0 00.187-.167l-.516-1.19.972-.858a.132.132 0 00-.1-.23l-1.29.123L1.99.165a.129.129 0 00-.12-.079.13.13 0 00-.129.103z"
        id="hand_hold_stars_prefix__dA"
      />
      <path
        d="M1.741.19L1.46 1.454l-1.29.123a.132.132 0 00-.055.245l1.117.66L.95 3.747a.131.131 0 00.215.127l.973-.858 1.117.66a.132.132 0 00.187-.167l-.516-1.19.972-.858a.132.132 0 00-.1-.23l-1.29.123L1.99.165a.129.129 0 00-.12-.079.13.13 0 00-.129.103z"
        id="hand_hold_stars_prefix__dC"
      />
      <path
        d="M1.741.19L1.46 1.454l-1.29.123a.132.132 0 00-.055.245l1.117.66L.95 3.747a.131.131 0 00.215.127l.973-.858 1.117.66a.132.132 0 00.187-.167l-.516-1.19.972-.858a.132.132 0 00-.1-.23l-1.29.123L1.99.165a.129.129 0 00-.12-.079.13.13 0 00-.129.103z"
        id="hand_hold_stars_prefix__dE"
      />
      <path
        d="M1.741.19L1.46 1.454l-1.29.123a.132.132 0 00-.055.245l1.117.66L.95 3.747a.131.131 0 00.215.127l.973-.858 1.117.66a.132.132 0 00.187-.167l-.516-1.19.972-.858a.132.132 0 00-.1-.23l-1.29.123L1.99.165a.129.129 0 00-.12-.079.13.13 0 00-.129.103z"
        id="hand_hold_stars_prefix__dG"
      />
      <path
        d="M1.741.19L1.46 1.454l-1.29.123a.132.132 0 00-.055.245l1.117.66L.95 3.747a.131.131 0 00.215.127l.973-.858 1.117.66a.132.132 0 00.187-.167l-.516-1.19.972-.858a.132.132 0 00-.1-.23l-1.29.123L1.99.165a.129.129 0 00-.12-.079.13.13 0 00-.129.103z"
        id="hand_hold_stars_prefix__dI"
      />
      <filter
        x="-4.2%"
        y="-4.2%"
        width="108.3%"
        height="108.3%"
        filterUnits="objectBoundingBox"
        id="hand_hold_stars_prefix__a"
      >
        <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation={1} in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix
          values="0 0 0 0 0.949019608 0 0 0 0 0.949019608 0 0 0 0 0.949019608 0 0 0 0.5 0"
          in="shadowBlurOuter1"
        />
      </filter>
      <circle id="hand_hold_stars_prefix__b" cx={36} cy={36} r={36} />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(2 2)">
        <use fill="#000" filter="url(#hand_hold_stars_prefix__a)" xlinkHref="#hand_hold_stars_prefix__b" />
        <use fill="#FFF" xlinkHref="#hand_hold_stars_prefix__b" />
      </g>
      <g transform="translate(16 59.166)">
        <mask id="hand_hold_stars_prefix__d" fill="#fff">
          <use xlinkHref="#hand_hold_stars_prefix__c" />
        </mask>
        <path
          d="M0 .7c0 .32 9.627.578 21.503.578S43.006 1.02 43.006.7c0-.32-9.627-.578-21.503-.578S0 .38 0 .7"
          fill="#E3E3E2"
          fillRule="nonzero"
          mask="url(#hand_hold_stars_prefix__d)"
        />
      </g>
      <path
        d="M41.202 39.663c1.166 0 2.097.912 1.86.49l3.93-3.937c.765-.768 2.228-1.825 3.294-1.63 2.057.378 2.383 2.958.98 4.365l-9.591 9.59a.963.963 0 01-.679.282l-7.568.022a1.1 1.1 0 00-.777.323l-5.695 5.707a.505.505 0 01-.716 0l-4.302-4.31a.926.926 0 01-.27-.655v-.17a.94.94 0 01.306-.696l10.302-9.381h8.926z"
        fill="#CCA582"
        fillRule="nonzero"
      />
      <path
        d="M41.202 38.694c1.166 0 2.184.901 2.219 2.07a2.133 2.133 0 01-2.13 2.2h-8.08a.388.388 0 000 .777h8.08a2.912 2.912 0 002.53-4.338l4.38-4.39c.767-.768 2-.9 2.856-.234a2.139 2.139 0 01.208 3.203l-9.547 9.547a1.11 1.11 0 01-.782.325l-7.27.023c-.444 0-.87.177-1.184.492l-5.428 5.439a.644.644 0 01-.912 0l-4.039-4.047a.619.619 0 010-.873l9.69-9.71c.309-.31.729-.484 1.166-.484h8.243z"
        fill="#E0B78D"
        fillRule="nonzero"
      />
      <g transform="translate(29.241 24.307)">
        <mask id="hand_hold_stars_prefix__f" fill="#fff">
          <use xlinkHref="#hand_hold_stars_prefix__e" />
        </mask>
        <path
          fill="#FAE4A5"
          fillRule="nonzero"
          mask="url(#hand_hold_stars_prefix__f)"
          d="M5.96 1.446l-.183-2.36-1.245 5.293.577.688z"
        />
      </g>
      <g transform="translate(29.241 24.307)">
        <mask id="hand_hold_stars_prefix__h" fill="#fff">
          <use xlinkHref="#hand_hold_stars_prefix__g" />
        </mask>
        <path
          fill="#EAB824"
          fillRule="nonzero"
          mask="url(#hand_hold_stars_prefix__h)"
          d="M7.36 4.885l.463-.772L5.777-.915l.183 2.361z"
        />
      </g>
      <g transform="translate(29.241 24.307)">
        <mask id="hand_hold_stars_prefix__j" fill="#fff">
          <use xlinkHref="#hand_hold_stars_prefix__i" />
        </mask>
        <path
          fill="#DBAB27"
          fillRule="nonzero"
          mask="url(#hand_hold_stars_prefix__j)"
          d="M11 4.591l-2.776 2.42.863.21 4.06-3.537z"
        />
      </g>
      <g transform="translate(29.241 24.307)">
        <mask id="hand_hold_stars_prefix__l" fill="#fff">
          <use xlinkHref="#hand_hold_stars_prefix__k" />
        </mask>
        <path
          fill="#FAE4A5"
          fillRule="nonzero"
          mask="url(#hand_hold_stars_prefix__l)"
          d="M7.36 4.885L11 4.591l2.147-.907-5.324.43z"
        />
      </g>
      <g transform="translate(29.241 24.307)">
        <mask id="hand_hold_stars_prefix__n" fill="#fff">
          <use xlinkHref="#hand_hold_stars_prefix__m" />
        </mask>
        <path
          fill="#FAE4A5"
          fillRule="nonzero"
          mask="url(#hand_hold_stars_prefix__n)"
          d="M5.11 5.066l-.577-.687-5.325.429 2.26.552z"
        />
      </g>
      <g transform="translate(29.241 24.307)">
        <mask id="hand_hold_stars_prefix__p" fill="#fff">
          <use xlinkHref="#hand_hold_stars_prefix__o" />
        </mask>
        <path
          fill="#FAE4A5"
          fillRule="nonzero"
          mask="url(#hand_hold_stars_prefix__p)"
          d="M3.732 10.924l.851-3.62-.82.346-1.244 5.294z"
        />
      </g>
      <g transform="translate(29.241 24.307)">
        <mask id="hand_hold_stars_prefix__r" fill="#fff">
          <use xlinkHref="#hand_hold_stars_prefix__q" />
        </mask>
        <path
          fill="#EAB824"
          fillRule="nonzero"
          mask="url(#hand_hold_stars_prefix__r)"
          d="M1.468 5.36l-2.26-.552L3.763 7.65l.82-.346z"
        />
      </g>
      <g transform="translate(29.241 24.307)">
        <mask id="hand_hold_stars_prefix__t" fill="#fff">
          <use xlinkHref="#hand_hold_stars_prefix__s" />
        </mask>
        <path
          fill="#EAB824"
          fillRule="nonzero"
          mask="url(#hand_hold_stars_prefix__t)"
          d="M8.224 7.01l1.4 3.439h-.001l1.51 1.8-2.046-5.028z"
        />
      </g>
      <g transform="translate(29.241 24.307)">
        <mask id="hand_hold_stars_prefix__v" fill="#fff">
          <use xlinkHref="#hand_hold_stars_prefix__u" />
        </mask>
        <path
          fill="#DBAB27"
          fillRule="nonzero"
          mask="url(#hand_hold_stars_prefix__v)"
          d="M3.732 10.924l-1.213 2.02 4.06-3.537-.07-.902z"
        />
      </g>
      <g transform="translate(29.241 24.307)">
        <mask id="hand_hold_stars_prefix__x" fill="#fff">
          <use xlinkHref="#hand_hold_stars_prefix__w" />
        </mask>
        <path
          fill="#EAB824"
          fillRule="nonzero"
          mask="url(#hand_hold_stars_prefix__x)"
          d="M6.508 8.505l.07.902 4.555 2.842-1.51-1.8z"
        />
      </g>
      <g transform="translate(29.241 24.307)">
        <mask id="hand_hold_stars_prefix__z" fill="#fff">
          <use xlinkHref="#hand_hold_stars_prefix__y" />
        </mask>
        <path
          fill="#FDD053"
          fillRule="nonzero"
          mask="url(#hand_hold_stars_prefix__z)"
          d="M6.357 6.554l1.002-1.67L5.96 1.447z"
        />
      </g>
      <g transform="translate(29.241 24.307)">
        <mask id="hand_hold_stars_prefix__B" fill="#fff">
          <use xlinkHref="#hand_hold_stars_prefix__A" />
        </mask>
        <path
          fill="#FDD053"
          fillRule="nonzero"
          mask="url(#hand_hold_stars_prefix__B)"
          d="M11 4.591l-3.64.294-1.003 1.669z"
        />
      </g>
      <g transform="translate(29.241 24.307)">
        <mask id="hand_hold_stars_prefix__D" fill="#fff">
          <use xlinkHref="#hand_hold_stars_prefix__C" />
        </mask>
        <path
          fill="#FDD053"
          fillRule="nonzero"
          mask="url(#hand_hold_stars_prefix__D)"
          d="M6.357 6.554L5.96 1.446l-.85 3.62z"
        />
      </g>
      <g transform="translate(29.241 24.307)">
        <mask id="hand_hold_stars_prefix__F" fill="#fff">
          <use xlinkHref="#hand_hold_stars_prefix__E" />
        </mask>
        <path
          fill="#FDD053"
          fillRule="nonzero"
          mask="url(#hand_hold_stars_prefix__F)"
          d="M6.508 8.505l3.115 1.944-3.266-3.895z"
        />
      </g>
      <g transform="translate(29.241 24.307)">
        <mask id="hand_hold_stars_prefix__H" fill="#fff">
          <use xlinkHref="#hand_hold_stars_prefix__G" />
        </mask>
        <path
          fill="#FDD053"
          fillRule="nonzero"
          mask="url(#hand_hold_stars_prefix__H)"
          d="M5.11 5.066l-3.642.294 4.889 1.194z"
        />
      </g>
      <g transform="translate(29.241 24.307)">
        <mask id="hand_hold_stars_prefix__J" fill="#fff">
          <use xlinkHref="#hand_hold_stars_prefix__I" />
        </mask>
        <path
          fill="#FDD053"
          fillRule="nonzero"
          mask="url(#hand_hold_stars_prefix__J)"
          d="M11 4.591L6.357 6.554l1.867.456z"
        />
      </g>
      <g transform="translate(29.241 24.307)">
        <mask id="hand_hold_stars_prefix__L" fill="#fff">
          <use xlinkHref="#hand_hold_stars_prefix__K" />
        </mask>
        <path
          fill="#FDD053"
          fillRule="nonzero"
          mask="url(#hand_hold_stars_prefix__L)"
          d="M6.357 6.554l3.266 3.895L8.224 7.01z"
        />
      </g>
      <g transform="translate(29.241 24.307)">
        <mask id="hand_hold_stars_prefix__N" fill="#fff">
          <use xlinkHref="#hand_hold_stars_prefix__M" />
        </mask>
        <path
          fill="#FDD053"
          fillRule="nonzero"
          mask="url(#hand_hold_stars_prefix__N)"
          d="M6.357 6.554L1.468 5.36l3.115 1.944z"
        />
      </g>
      <g transform="translate(29.241 24.307)">
        <mask id="hand_hold_stars_prefix__P" fill="#fff">
          <use xlinkHref="#hand_hold_stars_prefix__O" />
        </mask>
        <path
          fill="#FDD053"
          fillRule="nonzero"
          mask="url(#hand_hold_stars_prefix__P)"
          d="M6.357 6.554l-1.774.75-.851 3.62z"
        />
      </g>
      <g transform="translate(29.241 24.307)">
        <mask id="hand_hold_stars_prefix__R" fill="#fff">
          <use xlinkHref="#hand_hold_stars_prefix__Q" />
        </mask>
        <path
          fill="#FDD053"
          fillRule="nonzero"
          mask="url(#hand_hold_stars_prefix__R)"
          d="M3.732 10.924l2.776-2.419-.151-1.951z"
        />
      </g>
      <g transform="translate(37.517 15.204)">
        <mask id="hand_hold_stars_prefix__T" fill="#fff">
          <use xlinkHref="#hand_hold_stars_prefix__S" />
        </mask>
        <path
          fill="#FAE4A5"
          fillRule="nonzero"
          mask="url(#hand_hold_stars_prefix__T)"
          d="M3.348.873L3.112-.515l-.47 3.2.384.376z"
        />
      </g>
      <g transform="translate(37.517 15.204)">
        <mask id="hand_hold_stars_prefix__V" fill="#fff">
          <use xlinkHref="#hand_hold_stars_prefix__U" />
        </mask>
        <path
          fill="#EAB824"
          fillRule="nonzero"
          mask="url(#hand_hold_stars_prefix__V)"
          d="M4.374 2.832l.239-.481-1.5-2.866.235 1.388z"
        />
      </g>
      <g transform="translate(37.517 15.204)">
        <mask id="hand_hold_stars_prefix__X" fill="#fff">
          <use xlinkHref="#hand_hold_stars_prefix__W" />
        </mask>
        <path
          fill="#DBAB27"
          fillRule="nonzero"
          mask="url(#hand_hold_stars_prefix__X)"
          d="M6.554 2.462L5.008 4.043l.532.079L7.8 1.81z"
        />
      </g>
      <g transform="translate(37.517 15.204)">
        <mask id="hand_hold_stars_prefix__Z" fill="#fff">
          <use xlinkHref="#hand_hold_stars_prefix__Y" />
        </mask>
        <path
          fill="#FAE4A5"
          fillRule="nonzero"
          mask="url(#hand_hold_stars_prefix__Z)"
          d="M4.374 2.832l2.18-.37 1.247-.652-3.188.54z"
        />
      </g>
      <g transform="translate(37.517 15.204)">
        <mask id="hand_hold_stars_prefix__ab" fill="#fff">
          <use xlinkHref="#hand_hold_stars_prefix__aa" />
        </mask>
        <path
          fill="#FAE4A5"
          fillRule="nonzero"
          mask="url(#hand_hold_stars_prefix__ab)"
          d="M3.026 3.061l-.384-.376-3.19.541 1.393.205z"
        />
      </g>
      <g transform="translate(37.517 15.204)">
        <mask id="hand_hold_stars_prefix__ad" fill="#fff">
          <use xlinkHref="#hand_hold_stars_prefix__ac" />
        </mask>
        <path
          fill="#FAE4A5"
          fillRule="nonzero"
          mask="url(#hand_hold_stars_prefix__ad)"
          d="M2.505 6.602l.322-2.188-.476.249-.471 3.2z"
        />
      </g>
      <g transform="translate(37.517 15.204)">
        <mask id="hand_hold_stars_prefix__af" fill="#fff">
          <use xlinkHref="#hand_hold_stars_prefix__ae" />
        </mask>
        <path
          fill="#EAB824"
          fillRule="nonzero"
          mask="url(#hand_hold_stars_prefix__af)"
          d="M.845 3.431l-1.392-.205L2.35 4.663l.476-.25z"
        />
      </g>
      <g transform="translate(37.517 15.204)">
        <mask id="hand_hold_stars_prefix__ah" fill="#fff">
          <use xlinkHref="#hand_hold_stars_prefix__ag" />
        </mask>
        <path
          fill="#EAB824"
          fillRule="nonzero"
          mask="url(#hand_hold_stars_prefix__ah)"
          d="M5.008 4.044l1.025 1.96 1.007.983-1.5-2.865z"
        />
      </g>
      <g transform="translate(37.517 15.204)">
        <mask id="hand_hold_stars_prefix__aj" fill="#fff">
          <use xlinkHref="#hand_hold_stars_prefix__ai" />
        </mask>
        <path
          fill="#DBAB27"
          fillRule="nonzero"
          mask="url(#hand_hold_stars_prefix__aj)"
          d="M2.505 6.602l-.625 1.26 2.262-2.311-.09-.53z"
        />
      </g>
      <g transform="translate(37.517 15.204)">
        <mask id="hand_hold_stars_prefix__al" fill="#fff">
          <use xlinkHref="#hand_hold_stars_prefix__ak" />
        </mask>
        <path
          fill="#EAB824"
          fillRule="nonzero"
          mask="url(#hand_hold_stars_prefix__al)"
          d="M4.052 5.02l.09.53L7.04 6.988l-1.007-.984z"
        />
      </g>
      <g transform="translate(37.517 15.204)">
        <mask id="hand_hold_stars_prefix__an" fill="#fff">
          <use xlinkHref="#hand_hold_stars_prefix__am" />
        </mask>
        <path
          fill="#FDD053"
          fillRule="nonzero"
          mask="url(#hand_hold_stars_prefix__an)"
          d="M3.857 3.874l.517-1.042L3.348.872z"
        />
      </g>
      <g transform="translate(37.517 15.204)">
        <mask id="hand_hold_stars_prefix__ap" fill="#fff">
          <use xlinkHref="#hand_hold_stars_prefix__ao" />
        </mask>
        <path
          fill="#FDD053"
          fillRule="nonzero"
          mask="url(#hand_hold_stars_prefix__ap)"
          d="M6.554 2.462l-2.18.37-.517 1.042z"
        />
      </g>
      <g transform="translate(37.517 15.204)">
        <mask id="hand_hold_stars_prefix__ar" fill="#fff">
          <use xlinkHref="#hand_hold_stars_prefix__aq" />
        </mask>
        <path
          fill="#FDD053"
          fillRule="nonzero"
          mask="url(#hand_hold_stars_prefix__ar)"
          d="M3.857 3.874L3.347.873l-.32 2.187z"
        />
      </g>
      <g transform="translate(37.517 15.204)">
        <mask id="hand_hold_stars_prefix__at" fill="#fff">
          <use xlinkHref="#hand_hold_stars_prefix__as" />
        </mask>
        <path
          fill="#FDD053"
          fillRule="nonzero"
          mask="url(#hand_hold_stars_prefix__at)"
          d="M4.052 5.02l1.981.983-2.176-2.129z"
        />
      </g>
      <g transform="translate(37.517 15.204)">
        <mask id="hand_hold_stars_prefix__av" fill="#fff">
          <use xlinkHref="#hand_hold_stars_prefix__au" />
        </mask>
        <path
          fill="#FDD053"
          fillRule="nonzero"
          mask="url(#hand_hold_stars_prefix__av)"
          d="M3.026 3.061l-2.18.37 3.011.443z"
        />
      </g>
      <g transform="translate(37.517 15.204)">
        <mask id="hand_hold_stars_prefix__ax" fill="#fff">
          <use xlinkHref="#hand_hold_stars_prefix__aw" />
        </mask>
        <path
          fill="#FDD053"
          fillRule="nonzero"
          mask="url(#hand_hold_stars_prefix__ax)"
          d="M6.554 2.462L3.857 3.874l1.15.17z"
        />
      </g>
      <g transform="translate(37.517 15.204)">
        <mask id="hand_hold_stars_prefix__az" fill="#fff">
          <use xlinkHref="#hand_hold_stars_prefix__ay" />
        </mask>
        <path
          fill="#FDD053"
          fillRule="nonzero"
          mask="url(#hand_hold_stars_prefix__az)"
          d="M3.857 3.874l2.177 2.13-1.026-1.96z"
        />
      </g>
      <g transform="translate(37.517 15.204)">
        <mask id="hand_hold_stars_prefix__aB" fill="#fff">
          <use xlinkHref="#hand_hold_stars_prefix__aA" />
        </mask>
        <path
          fill="#FDD053"
          fillRule="nonzero"
          mask="url(#hand_hold_stars_prefix__aB)"
          d="M3.857 3.874L.845 3.431l1.982.982z"
        />
      </g>
      <g transform="translate(37.517 15.204)">
        <mask id="hand_hold_stars_prefix__aD" fill="#fff">
          <use xlinkHref="#hand_hold_stars_prefix__aC" />
        </mask>
        <path
          fill="#FDD053"
          fillRule="nonzero"
          mask="url(#hand_hold_stars_prefix__aD)"
          d="M3.857 3.874l-1.03.54-.322 2.188z"
        />
      </g>
      <g transform="translate(37.517 15.204)">
        <mask id="hand_hold_stars_prefix__aF" fill="#fff">
          <use xlinkHref="#hand_hold_stars_prefix__aE" />
        </mask>
        <path
          fill="#FDD053"
          fillRule="nonzero"
          mask="url(#hand_hold_stars_prefix__aF)"
          d="M2.505 6.602L4.052 5.02l-.195-1.147z"
        />
      </g>
      <g>
        <g transform="translate(25.103 18.514)">
          <mask id="hand_hold_stars_prefix__aH" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__aG" />
          </mask>
          <path
            fill="#FAE4A5"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__aH)"
            d="M4.4 1.013l.19-1.56-1.494 3.272.282.526z"
          />
        </g>
        <g transform="translate(25.103 18.514)">
          <mask id="hand_hold_stars_prefix__aJ" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__aI" />
          </mask>
          <path
            fill="#EAB824"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__aJ)"
            d="M4.852 3.442l.4-.439-.662-3.55-.19 1.56z"
          />
        </g>
        <g transform="translate(25.103 18.514)">
          <mask id="hand_hold_stars_prefix__aL" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__aK" />
          </mask>
          <path
            fill="#DBAB27"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__aL)"
            d="M7.237 3.75L5.131 4.941l.53.256 3.078-1.745z"
          />
        </g>
        <g transform="translate(25.103 18.514)">
          <mask id="hand_hold_stars_prefix__aN" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__aM" />
          </mask>
          <path
            fill="#FAE4A5"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__aN)"
            d="M4.852 3.442l2.385.308 1.502-.297-3.488-.45z"
          />
        </g>
        <g transform="translate(25.103 18.514)">
          <mask id="hand_hold_stars_prefix__aP" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__aO" />
          </mask>
          <path
            fill="#FAE4A5"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__aP)"
            d="M3.378 3.251l-.282-.526-3.488-.45 1.385.668z"
          />
        </g>
        <g transform="translate(25.103 18.514)">
          <mask id="hand_hold_stars_prefix__aR" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__aQ" />
          </mask>
          <path
            fill="#FAE4A5"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__aR)"
            d="M1.725 6.873l1.021-2.238-.573.113L.678 8.02z"
          />
        </g>
        <g transform="translate(25.103 18.514)">
          <mask id="hand_hold_stars_prefix__aT" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__aS" />
          </mask>
          <path
            fill="#EAB824"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__aT)"
            d="M.993 2.943l-1.385-.668 2.565 2.473.573-.114z"
          />
        </g>
        <g transform="translate(25.103 18.514)">
          <mask id="hand_hold_stars_prefix__aV" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__aU" />
          </mask>
          <path
            fill="#EAB824"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__aV)"
            d="M5.131 4.942l.453 2.429.738 1.378-.662-3.551z"
          />
        </g>
        <g transform="translate(25.103 18.514)">
          <mask id="hand_hold_stars_prefix__aX" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__aW" />
          </mask>
          <path
            fill="#DBAB27"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__aX)"
            d="M1.725 6.873L.678 8.02l3.08-1.744.072-.596z"
          />
        </g>
        <g transform="translate(25.103 18.514)">
          <mask id="hand_hold_stars_prefix__aZ" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__aY" />
          </mask>
          <path
            fill="#EAB824"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__aZ)"
            d="M3.83 5.68l-.073.596 2.565 2.473-.738-1.378z"
          />
        </g>
        <g transform="translate(25.103 18.514)">
          <mask id="hand_hold_stars_prefix__bb" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__ba" />
          </mask>
          <path
            fill="#FDD053"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__bb)"
            d="M3.988 4.39l.864-.949-.453-2.428z"
          />
        </g>
        <g transform="translate(25.103 18.514)">
          <mask id="hand_hold_stars_prefix__bd" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__bc" />
          </mask>
          <path
            fill="#FDD053"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__bd)"
            d="M7.237 3.75l-2.385-.31-.864.949z"
          />
        </g>
        <g transform="translate(25.103 18.514)">
          <mask id="hand_hold_stars_prefix__bf" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__be" />
          </mask>
          <path
            fill="#FDD053"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__bf)"
            d="M3.988 4.39l.411-3.377-1.021 2.238z"
          />
        </g>
        <g transform="translate(25.103 18.514)">
          <mask id="hand_hold_stars_prefix__bh" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__bg" />
          </mask>
          <path
            fill="#FDD053"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__bh)"
            d="M3.83 5.68l1.754 1.69-1.597-2.98z"
          />
        </g>
        <g transform="translate(25.103 18.514)">
          <mask id="hand_hold_stars_prefix__bj" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__bi" />
          </mask>
          <path
            fill="#FDD053"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__bj)"
            d="M3.378 3.251L.993 2.943 3.988 4.39z"
          />
        </g>
        <g transform="translate(25.103 18.514)">
          <mask id="hand_hold_stars_prefix__bl" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__bk" />
          </mask>
          <path
            fill="#FDD053"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__bl)"
            d="M7.237 3.75l-3.25.64 1.145.552z"
          />
        </g>
        <g transform="translate(25.103 18.514)">
          <mask id="hand_hold_stars_prefix__bn" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__bm" />
          </mask>
          <path
            fill="#FDD053"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__bn)"
            d="M3.988 4.39l1.596 2.98-.452-2.428z"
          />
        </g>
        <g transform="translate(25.103 18.514)">
          <mask id="hand_hold_stars_prefix__bp" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__bo" />
          </mask>
          <path
            fill="#FDD053"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__bp)"
            d="M3.988 4.39L.993 2.943l1.753 1.691z"
          />
        </g>
        <g transform="translate(25.103 18.514)">
          <mask id="hand_hold_stars_prefix__br" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__bq" />
          </mask>
          <path
            fill="#FDD053"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__br)"
            d="M3.988 4.39l-1.242.244-1.022 2.239z"
          />
        </g>
        <g transform="translate(25.103 18.514)">
          <mask id="hand_hold_stars_prefix__bt" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__bs" />
          </mask>
          <path
            fill="#FDD053"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__bt)"
            d="M1.725 6.873L3.83 5.68l.158-1.29z"
          />
        </g>
      </g>
      <g>
        <g transform="translate(43.31 22.652)">
          <mask id="hand_hold_stars_prefix__bv" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__bu" />
          </mask>
          <path
            fill="#FAE4A5"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__bv)"
            d="M5.23 1.096l.534-1.78-2.356 3.486.21.678z"
          />
        </g>
        <g transform="translate(43.31 22.652)">
          <mask id="hand_hold_stars_prefix__bx" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__bw" />
          </mask>
          <path
            fill="#EAB824"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__bx)"
            d="M5.242 4.032l.54-.422-.018-4.293-.535 1.779z"
          />
        </g>
        <g transform="translate(43.31 22.652)">
          <mask id="hand_hold_stars_prefix__bz" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__by" />
          </mask>
          <path
            fill="#DBAB27"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__bz)"
            d="M7.87 4.926l-2.62.92.545.417 3.83-1.346-1.755.009z"
          />
        </g>
        <g transform="translate(43.31 22.652)">
          <mask id="hand_hold_stars_prefix__bB" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__bA" />
          </mask>
          <path
            fill="#FAE4A5"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__bB)"
            d="M5.242 4.032l2.628.894 1.755-.009L5.783 3.61z"
          />
        </g>
        <g transform="translate(43.31 22.652)">
          <mask id="hand_hold_stars_prefix__bD" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__bC" />
          </mask>
          <path
            fill="#FAE4A5"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__bD)"
            d="M3.618 3.48l-.21-.678-3.842-1.307 1.425 1.09z"
          />
        </g>
        <g transform="translate(43.31 22.652)">
          <mask id="hand_hold_stars_prefix__bF" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__bE" />
          </mask>
          <path
            fill="#FAE4A5"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__bF)"
            d="M1.012 7.336l1.611-2.383-.67.003L-.403 8.44z"
          />
        </g>
        <g transform="translate(43.31 22.652)">
          <mask id="hand_hold_stars_prefix__bH" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__bG" />
          </mask>
          <path
            fill="#EAB824"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__bH)"
            d="M.991 2.586L-.434 1.495l2.386 3.46.671-.002z"
          />
        </g>
        <g transform="translate(43.31 22.652)">
          <mask id="hand_hold_stars_prefix__bJ" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__bI" />
          </mask>
          <path
            fill="#EAB824"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__bJ)"
            d="M5.25 5.846l.014 2.936.55 1.774-.02-4.293z"
          />
        </g>
        <g transform="translate(43.31 22.652)">
          <mask id="hand_hold_stars_prefix__bL" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__bK" />
          </mask>
          <path
            fill="#DBAB27"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__bL)"
            d="M1.012 7.336L-.403 8.44l3.83-1.346.205-.68z"
          />
        </g>
        <g transform="translate(43.31 22.652)">
          <mask id="hand_hold_stars_prefix__bN" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__bM" />
          </mask>
          <path
            fill="#EAB824"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__bN)"
            d="M3.632 6.415l-.204.68 2.386 3.461-.55-1.774z"
          />
        </g>
        <g transform="translate(43.31 22.652)">
          <mask id="hand_hold_stars_prefix__bP" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__bO" />
          </mask>
          <path
            fill="#FDD053"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__bP)"
            d="M4.073 4.945l1.17-.913-.014-2.936z"
          />
        </g>
        <g transform="translate(43.31 22.652)">
          <mask id="hand_hold_stars_prefix__bR" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__bQ" />
          </mask>
          <path
            fill="#FDD053"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__bR)"
            d="M7.87 4.926l-2.628-.894-1.169.913z"
          />
        </g>
        <g transform="translate(43.31 22.652)">
          <mask id="hand_hold_stars_prefix__bT" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__bS" />
          </mask>
          <path
            fill="#FDD053"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__bT)"
            d="M4.073 4.945L5.23 1.096 3.62 3.48z"
          />
        </g>
        <g transform="translate(43.31 22.652)">
          <mask id="hand_hold_stars_prefix__bV" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__bU" />
          </mask>
          <path
            fill="#FDD053"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__bV)"
            d="M3.632 6.415l1.632 2.367-1.19-3.837z"
          />
        </g>
        <g transform="translate(43.31 22.652)">
          <mask id="hand_hold_stars_prefix__bX" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__bW" />
          </mask>
          <path
            fill="#FDD053"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__bX)"
            d="M3.618 3.48L.991 2.586l3.082 2.36z"
          />
        </g>
        <g transform="translate(43.31 22.652)">
          <mask id="hand_hold_stars_prefix__bZ" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__bY" />
          </mask>
          <path
            fill="#FDD053"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__bZ)"
            d="M7.87 4.926l-3.797.02 1.178.9 2.619-.92z"
          />
        </g>
        <g transform="translate(43.31 22.652)">
          <mask id="hand_hold_stars_prefix__cb" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__ca" />
          </mask>
          <path
            fill="#FDD053"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__cb)"
            d="M4.073 4.945l1.19 3.837-.012-2.936z"
          />
        </g>
        <g transform="translate(43.31 22.652)">
          <mask id="hand_hold_stars_prefix__cd" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__cc" />
          </mask>
          <path
            fill="#FDD053"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__cd)"
            d="M4.073 4.945L.991 2.585l1.632 2.368z"
          />
        </g>
        <g transform="translate(43.31 22.652)">
          <mask id="hand_hold_stars_prefix__cf" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__ce" />
          </mask>
          <path
            fill="#FDD053"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__cf)"
            d="M4.073 4.945l-1.45.008-1.61 2.383z"
          />
        </g>
        <g transform="translate(43.31 22.652)">
          <mask id="hand_hold_stars_prefix__ch" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__cg" />
          </mask>
          <path
            fill="#FDD053"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__ch)"
            d="M1.012 7.336l2.62-.92.441-1.47z"
          />
        </g>
      </g>
      <g>
        <g transform="translate(31.724 14.376)">
          <mask id="hand_hold_stars_prefix__cj" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__ci" />
          </mask>
          <path
            fill="#FAE4A5"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__cj)"
            d="M2.711.588l.132-.935-.921 1.945.16.32z"
          />
        </g>
        <g transform="translate(31.724 14.376)">
          <mask id="hand_hold_stars_prefix__cl" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__ck" />
          </mask>
          <path
            fill="#EAB824"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__cl)"
            d="M2.948 2.052l.241-.258-.346-2.141-.132.935z"
          />
        </g>
        <g transform="translate(31.724 14.376)">
          <mask id="hand_hold_stars_prefix__cn" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__cm" />
          </mask>
          <path
            fill="#DBAB27"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__cn)"
            d="M4.35 2.268l-1.256.688.309.16L5.239 2.11z"
          />
        </g>
        <g transform="translate(31.724 14.376)">
          <mask id="hand_hold_stars_prefix__cp" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__co" />
          </mask>
          <path
            fill="#FAE4A5"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__cp)"
            d="M2.948 2.052l1.402.216.89-.158-2.05-.316z"
          />
        </g>
        <g transform="translate(31.724 14.376)">
          <mask id="hand_hold_stars_prefix__cr" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__cq" />
          </mask>
          <path
            fill="#FAE4A5"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__cr)"
            d="M2.082 1.918l-.16-.32-2.05-.316.807.42z"
          />
        </g>
        <g transform="translate(31.724 14.376)">
          <mask id="hand_hold_stars_prefix__ct" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__cs" />
          </mask>
          <path
            fill="#FAE4A5"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__ct)"
            d="M1.062 4.07l.63-1.33-.34.06-.92 1.945z"
          />
        </g>
        <g transform="translate(31.724 14.376)">
          <mask id="hand_hold_stars_prefix__cv" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__cu" />
          </mask>
          <path
            fill="#EAB824"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__cv)"
            d="M.68 1.701l-.808-.42 1.48 1.52.34-.06z"
          />
        </g>
        <g transform="translate(31.724 14.376)">
          <mask id="hand_hold_stars_prefix__cx" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__cw" />
          </mask>
          <path
            fill="#EAB824"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__cx)"
            d="M3.094 2.957l.237 1.463.418.838-.346-2.141z"
          />
        </g>
        <g transform="translate(31.724 14.376)">
          <mask id="hand_hold_stars_prefix__cz" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__cy" />
          </mask>
          <path
            fill="#DBAB27"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__cz)"
            d="M1.062 4.07l-.63.675L2.268 3.74l.05-.357z"
          />
        </g>
        <g transform="translate(31.724 14.376)">
          <mask id="hand_hold_stars_prefix__cB" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__cA" />
          </mask>
          <path
            fill="#EAB824"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__cB)"
            d="M2.318 3.382l-.05.357 1.481 1.519-.418-.838z"
          />
        </g>
        <g transform="translate(31.724 14.376)">
          <mask id="hand_hold_stars_prefix__cD" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__cC" />
          </mask>
          <path
            fill="#FDD053"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__cD)"
            d="M2.427 2.61l.521-.558L2.711.588z"
          />
        </g>
        <g transform="translate(31.724 14.376)">
          <mask id="hand_hold_stars_prefix__cF" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__cE" />
          </mask>
          <path
            fill="#FDD053"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__cF)"
            d="M4.35 2.268l-1.402-.216-.521.558z"
          />
        </g>
        <g transform="translate(31.724 14.376)">
          <mask id="hand_hold_stars_prefix__cH" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__cG" />
          </mask>
          <path
            fill="#FDD053"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__cH)"
            d="M2.427 2.61L2.71.588l-.63 1.33z"
          />
        </g>
        <g transform="translate(31.724 14.376)">
          <mask id="hand_hold_stars_prefix__cJ" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__cI" />
          </mask>
          <path
            fill="#FDD053"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__cJ)"
            d="M2.318 3.382L3.331 4.42l-.904-1.81z"
          />
        </g>
        <g transform="translate(31.724 14.376)">
          <mask id="hand_hold_stars_prefix__cL" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__cK" />
          </mask>
          <path
            fill="#FDD053"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__cL)"
            d="M2.082 1.918L.679 1.7l1.748.909z"
          />
        </g>
        <g transform="translate(31.724 14.376)">
          <mask id="hand_hold_stars_prefix__cN" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__cM" />
          </mask>
          <path
            fill="#FDD053"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__cN)"
            d="M4.35 2.268l-1.923.342.667.346z"
          />
        </g>
        <g transform="translate(31.724 14.376)">
          <mask id="hand_hold_stars_prefix__cP" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__cO" />
          </mask>
          <path
            fill="#FDD053"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__cP)"
            d="M2.427 2.61l.904 1.81-.237-1.463z"
          />
        </g>
        <g transform="translate(31.724 14.376)">
          <mask id="hand_hold_stars_prefix__cR" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__cQ" />
          </mask>
          <path
            fill="#FDD053"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__cR)"
            d="M2.427 2.61L.679 1.7l1.013 1.04z"
          />
        </g>
        <g transform="translate(31.724 14.376)">
          <mask id="hand_hold_stars_prefix__cT" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__cS" />
          </mask>
          <path
            fill="#FDD053"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__cT)"
            d="M2.427 2.61l-.735.13-.63 1.33z"
          />
        </g>
        <g transform="translate(31.724 14.376)">
          <mask id="hand_hold_stars_prefix__cV" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__cU" />
          </mask>
          <path
            fill="#FDD053"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__cV)"
            d="M1.062 4.07l1.256-.688.109-.772z"
          />
        </g>
      </g>
      <g>
        <g transform="translate(48.276 17.687)">
          <mask id="hand_hold_stars_prefix__cX" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__cW" />
          </mask>
          <path
            fill="#FAE4A5"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__cX)"
            d="M1.899.526l-.07-.737-.37 1.661.187.212z"
          />
        </g>
        <g transform="translate(48.276 17.687)">
          <mask id="hand_hold_stars_prefix__cZ" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__cY" />
          </mask>
          <path
            fill="#EAB824"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__cZ)"
            d="M2.362 1.594l.144-.244-.678-1.56.07.737z"
          />
        </g>
        <g transform="translate(48.276 17.687)">
          <mask id="hand_hold_stars_prefix__db" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__da" />
          </mask>
          <path
            fill="#DBAB27"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__db)"
            d="M3.52 1.483l-.872.77.276.062L4.2 1.188z"
          />
        </g>
        <g transform="translate(48.276 17.687)">
          <mask id="hand_hold_stars_prefix__dd" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__dc" />
          </mask>
          <path
            fill="#FAE4A5"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__dd)"
            d="M2.362 1.594l1.159-.11.68-.296-1.695.162z"
          />
        </g>
        <g transform="translate(48.276 17.687)">
          <mask id="hand_hold_stars_prefix__df" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__de" />
          </mask>
          <path
            fill="#FAE4A5"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__df)"
            d="M1.646 1.662l-.187-.212-1.695.162.723.16z"
          />
        </g>
        <g transform="translate(48.276 17.687)">
          <mask id="hand_hold_stars_prefix__dh" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__dg" />
          </mask>
          <path
            fill="#FAE4A5"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__dh)"
            d="M1.237 3.5l.253-1.136-.26.113-.37 1.661z"
          />
        </g>
        <g transform="translate(48.276 17.687)">
          <mask id="hand_hold_stars_prefix__dj" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__di" />
          </mask>
          <path
            fill="#EAB824"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__dj)"
            d="M.487 1.773l-.723-.161 1.466.865.26-.113z"
          />
        </g>
        <g transform="translate(48.276 17.687)">
          <mask id="hand_hold_stars_prefix__dl" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__dk" />
          </mask>
          <path
            fill="#EAB824"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__dl)"
            d="M2.648 2.254l.464 1.067.49.556-.677-1.562z"
          />
        </g>
        <g transform="translate(48.276 17.687)">
          <mask id="hand_hold_stars_prefix__dn" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__dm" />
          </mask>
          <path
            fill="#DBAB27"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__dn)"
            d="M1.237 3.5l-.376.638 1.275-1.127-.027-.281z"
          />
        </g>
        <g transform="translate(48.276 17.687)">
          <mask id="hand_hold_stars_prefix__dp" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__do" />
          </mask>
          <path
            fill="#EAB824"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__dp)"
            d="M2.11 2.73l.026.281 1.466.866-.49-.555z"
          />
        </g>
        <g transform="translate(48.276 17.687)">
          <mask id="hand_hold_stars_prefix__dr" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__dq" />
          </mask>
          <path
            fill="#FDD053"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__dr)"
            d="M2.051 2.12l.311-.526L1.9.526z"
          />
        </g>
        <g transform="translate(48.276 17.687)">
          <mask id="hand_hold_stars_prefix__dt" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__ds" />
          </mask>
          <path
            fill="#FDD053"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__dt)"
            d="M3.52 1.483l-1.158.11-.31.528z"
          />
        </g>
        <g transform="translate(48.276 17.687)">
          <mask id="hand_hold_stars_prefix__dv" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__du" />
          </mask>
          <path
            fill="#FDD053"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__dv)"
            d="M2.051 2.12L1.9.527l-.253 1.136z"
          />
        </g>
        <g transform="translate(48.276 17.687)">
          <mask id="hand_hold_stars_prefix__dx" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__dw" />
          </mask>
          <path
            fill="#FDD053"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__dx)"
            d="M2.11 2.73l1.002.592-1.06-1.201z"
          />
        </g>
        <g transform="translate(48.276 17.687)">
          <mask id="hand_hold_stars_prefix__dz" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__dy" />
          </mask>
          <path
            fill="#FDD053"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__dz)"
            d="M1.646 1.662l-1.159.11 1.564.349z"
          />
        </g>
        <g transform="translate(48.276 17.687)">
          <mask id="hand_hold_stars_prefix__dB" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__dA" />
          </mask>
          <path
            fill="#FDD053"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__dB)"
            d="M3.52 1.483l-1.469.638.597.133z"
          />
        </g>
        <g transform="translate(48.276 17.687)">
          <mask id="hand_hold_stars_prefix__dD" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__dC" />
          </mask>
          <path
            fill="#FDD053"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__dD)"
            d="M2.051 2.12l1.06 1.202-.463-1.068z"
          />
        </g>
        <g transform="translate(48.276 17.687)">
          <mask id="hand_hold_stars_prefix__dF" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__dE" />
          </mask>
          <path
            fill="#FDD053"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__dF)"
            d="M2.051 2.12L.487 1.774l1.003.591z"
          />
        </g>
        <g transform="translate(48.276 17.687)">
          <mask id="hand_hold_stars_prefix__dH" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__dG" />
          </mask>
          <path
            fill="#FDD053"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__dH)"
            d="M2.051 2.12l-.561.244-.253 1.137z"
          />
        </g>
        <g transform="translate(48.276 17.687)">
          <mask id="hand_hold_stars_prefix__dJ" fill="#fff">
            <use xlinkHref="#hand_hold_stars_prefix__dI" />
          </mask>
          <path
            fill="#FDD053"
            fillRule="nonzero"
            mask="url(#hand_hold_stars_prefix__dJ)"
            d="M1.237 3.5l.872-.77-.058-.61z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default HandHoldStarsIcon;
