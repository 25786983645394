import { useLayoutEffect, useState } from 'react';
import { debounce } from '@health-activity-ui/utils';

export const useScreenWidth = (): number => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useLayoutEffect(() => {
    const updateSize = (): void => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', debounce(updateSize, 250));
    // updateSize();
    return (): void => window.removeEventListener('resize', updateSize);
  }, []);

  return screenWidth;
};

export default useScreenWidth;
