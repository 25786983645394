import { AdvantageConfig, ADVANTAGE_BASE_URI, ICampaignPlacement, MemberInfo } from '@health-activity-ui/shared';
import { IS_LOCAL } from '@health-activity-ui/utils';
import { AxiosResponse } from 'axios';
import { Profile } from '../contexts/profile/profile.models';
import apiClient from './api-client';

export async function getAdvantageProfile(): Promise<Profile> {
  const { data } = await apiClient<Profile>(`${ADVANTAGE_BASE_URI}/profile/v2/user`, { useCache: true });

  return data;
}

export async function getUiConfig(locale: string): Promise<AdvantageConfig> {
  const { data } = await apiClient<AdvantageConfig>(`${ADVANTAGE_BASE_URI}/v3/chimera/uiconfig`, {
    useCache: true,
    params: {
      locale,
    },
  });

  return data;
}

export async function getCampaignPlacement(
  placement: string,
  limit?: number,
  locale?: string
): Promise<ICampaignPlacement[]> {
  const params = {
    ...(limit && { limit }),
    ...(locale && { locale }),
  };
  const { data } = await apiClient<ICampaignPlacement[]>(
    `${ADVANTAGE_BASE_URI}/v1/user/campaigns/placement/${placement}`,
    {
      params,
      useCache: true,
    }
  );

  return data;
}

export async function sendTrackEvent(
  campaignId: string,
  eventType: string,
  placementType: string,
  origContent: ICampaignPlacement,
  rallyId,
  sessionId
): Promise<AxiosResponse> {
  // This endpoint doesn't work for local development so no point in calling it and polute the console with errors
  if (!IS_LOCAL()) {
    const response = await apiClient(`${ADVANTAGE_BASE_URI}/campaigns/v1/trackEvent`, {
      method: 'POST',
      data: {
        campaignId,
        eventType,
        placementType,
        timestamp: Date.now(),
        origContent,
        rallyId,
        sessionId,
      },
    });

    return response;
  }
}

export const getSimpleProductEligibility = async (rallyId: string): Promise<Record<string, boolean>> => {
  const { data } = await apiClient<Record<string, boolean>>(`${ADVANTAGE_BASE_URI}/v1/productauthlistwithfeatures`, {
    params: {
      rallyId,
    },
    useCache: true,
  });
  return data;
};

export const getMemberInfo = async (): Promise<MemberInfo> => {
  const { data } = await apiClient<MemberInfo>(`${ADVANTAGE_BASE_URI}/v1/sens/memberInfo`, {
    useCache: true,
  });
  return data;
};
