import { css } from '@emotion/react';
import {
  RDS_BORDER_RADIUS_4,
  RDS_BOX_SHADOW_NEUTRAL_WITH_SPREAD_6,
  RDS_COLOR_SYSTEM_CHARCOAL,
  RDS_COLOR_WHITE,
} from '@rally/ui-themes/dist/web/rally';
import Portal from '@reach/portal';
import { Position, positionTooltip, TooltipPopup, useTooltip } from '@reach/tooltip';
import '@reach/tooltip/styles.css';
import React, { cloneElement, ReactElement, ReactNode } from 'react';

interface ToolTipProps {
  'aria-label'?: string;
  className?: string;
  children?: ReactNode;
  customStyles?: StylingProps;
  hasMarker?: boolean;
  label?: ReactNode;
  useCustomPosition?: boolean;
  customPosition?: Position;
}

interface StylingProps {
  bgColor?: string;
  boxShadow?: string;
  color?: string;
  maxWidth?: string;
}

const BORDER_WIDTH = 8;

const createCustomPosition: Position = (triggerRect, tooltipRect) => {
  const triggerCenter = triggerRect.left + triggerRect.width;
  const left = triggerCenter - tooltipRect.width - triggerRect.width * 1.5;
  const maxLeft = window.innerWidth - tooltipRect.width - 2;

  return {
    left: Math.min(Math.max(2, left), maxLeft) + window.scrollX,
    top: triggerRect.bottom - (tooltipRect.height - BORDER_WIDTH / 2) + window.scrollY,
  };
};

export const ToolTip = ({
  children,
  label,
  'aria-label': ariaLabel,
  hasMarker = false,
  customStyles = {
    maxWidth: '280px',
    bgColor: RDS_COLOR_WHITE,
    color: RDS_COLOR_SYSTEM_CHARCOAL,
    boxShadow: RDS_BOX_SHADOW_NEUTRAL_WITH_SPREAD_6,
  },
  useCustomPosition = false,
  customPosition,
  className,
  ...rest
}: ToolTipProps) => {
  const [trigger, tooltip] = useTooltip();
  const { isVisible, triggerRect } = tooltip;
  customPosition = customPosition ? customPosition : createCustomPosition;

  return (
    <>
      {cloneElement(children as ReactElement<unknown>, trigger)}

      {isVisible && (
        // The Triangle. We position it relative to the trigger, not the popup
        // so that collisions don't have a triangle pointing off to nowhere.
        // Using a Portal may seem a little extreme, but we can keep the
        // positioning logic simpler here instead of needing to consider
        // the popup's position relative to the trigger and collisions
        <Portal>
          <span
            style={
              hasMarker
                ? {
                    position: 'absolute',
                    left: triggerRect && triggerRect.left - triggerRect.width / 2 + window.scrollX,
                    top: triggerRect && triggerRect.bottom - triggerRect.height / 2 - BORDER_WIDTH + window.scrollY,
                    width: 0,
                    height: 0,
                    borderWidth: `${BORDER_WIDTH}px`,
                    borderStyle: 'solid',
                    borderColor: `transparent transparent transparent ${customStyles.bgColor}`,
                    zIndex: 2,
                  }
                : null
            }
          />
        </Portal>
      )}

      <TooltipPopup
        {...tooltip}
        label={label}
        aria-label={ariaLabel}
        className={`tooltip-popup is-rds-body-3 ${className}`}
        css={css`
          background-color: ${customStyles.bgColor};
          border: none;
          border-radius: ${RDS_BORDER_RADIUS_4};
          box-shadow: ${customStyles.boxShadow};
          color: ${customStyles.color};
          max-width: ${customStyles.maxWidth};
          white-space: normal;
        `}
        position={useCustomPosition || hasMarker ? customPosition : positionTooltip}
        {...rest}
      />
    </>
  );
};
