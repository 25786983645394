import React from 'react';

interface Props {
  color?: string;
  height?: string;
  width?: string;
}

export const YogaIcon = ({ color = '#fff', width = '25px', height = '25px' }: Props): React.ReactElement => (
  <svg width={width} height={height} viewBox="0 0 100 80" aria-hidden="true" data-testid="yoga-icon">
    <path
      style={{ fill: color }}
      d="M48 9V6c0-8-12-8-12 0v3c0 8 12 8 12 0zm33 8H3c-4 0-4 7 0 7h25l3 1 1 1 1 3v12L7 67c-4 4 2 10 6 6l23-23c3-3 8-4 12-3l12 6v17c0 5 8 5 8 0V51c0-2-1-4-3-4l-14-7V29l1-3 1-1 3-2h25c4 0 4-6 0-6z"
    />
  </svg>
);

export default YogaIcon;
