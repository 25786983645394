import classnames from 'classnames';
import colors, { ColorProps } from './colors';
import effects, { EffectsProps } from './effects';
import typography, { TypographyProps } from './typography';
import spacing, { SpacingProps } from './spacing';

export interface ClassNames {
  // Additional classes to be passed into the component
  className?: string;
}

export type HelperProps = ColorProps & EffectsProps & TypographyProps & SpacingProps & ClassNames;

// Combines all helper props for Color, Effects, Typography, Spacing and ClassNames into one
/* eslint-disable-next-line */
const createBaseRdsProps =
  (...fns) =>
  (args) =>
    fns.reduce((arg, fn) => fn(arg), args);

export default {
  defaultProps: {
    ...colors.defaultProps,
    ...effects.defaultProps,
    ...typography.defaultProps,
    ...spacing.defaultProps,
    className: undefined,
  },
  classnames: (props: HelperProps): string => {
    return classnames(
      colors.classnames(props),
      effects.classnames(props),
      typography.classnames(props),
      spacing.classnames(props),
      props.className
    );
  },
  // Props passed into base is HelperProps & all remaining props ([x: string]: any), essentially making it any.
  /* eslint-disable-next-line */
  base: (props) => createBaseRdsProps(colors.base, effects.base, typography.base, spacing.base)(props),
};
