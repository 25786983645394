import React from 'react';

export const StairGoalIcon = (): React.ReactElement => (
  <svg width={89} height={88} viewBox="0 0 89 88">
    <defs>
      <path id="prefix__a" d="M.154.002h68.408v27.266H.154z" />
      <path id="prefix__c" d="M.112.127h12.19v10.698H.111z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        d="M32.776 41.948a1.46 1.46 0 00-1.77-.712l-8.087 2.642a1.578 1.578 0 00-.97 2.051 1.584 1.584 0 002.03.924l8.013-2.876a1.455 1.455 0 00.784-2.03"
        fill="#BA8D68"
      />
      <path d="M29.134 41.847l-6.215 2.031a1.579 1.579 0 101.06 2.976l6.036-2.2-.88-2.807z" fill="#E16530" />
      <path
        d="M38.19 34.737c-.573-.445-1.532-.187-1.978.384l-6.007 6.406a1.55 1.55 0 00.271 2.177c.707.55 1.732.39 2.238-.348l5.695-6.962c.392-.571.328-1.23-.22-1.657"
        fill="#BA8D68"
      />
      <path
        d="M21.131 74.219a2.406 2.406 0 001.727-2.936l-1.396-5.352-4.622 1.444 1.999 5.862c.335 1.286 1.003 1.316 2.292.982"
        fill="#4D4D4D"
      />
      <path fill="#4D4D4D" d="M14.602 80.802l3.117 1.803 4.778-9.351-3.503-1.691z" />
      <path
        d="M19.259 44.98c-.078.981.566 1.834 1.44 1.903.873.07 1.644-.67 1.723-1.652l.29-3.645c.08-.982-.565-1.834-1.438-1.903-.874-.07-1.645.67-1.724 1.651l-.291 3.646z"
        fill="#BA8D68"
      />
      <path
        d="M22.184 34.05a3.64 3.64 0 012.286 2.85l.312 2.07a3.642 3.642 0 01-3.842 4.175 3.636 3.636 0 001.866.208 3.642 3.642 0 003.065-4.143l-.313-2.07a3.646 3.646 0 00-3.374-3.09"
        fill="#A1795D"
      />
      <path
        d="M25.112 38.922l-.313-2.07a3.64 3.64 0 00-2.285-2.85 3.642 3.642 0 00-3.842 4.176l.312 2.07a3.64 3.64 0 002.286 2.85 3.642 3.642 0 003.842-4.176"
        fill="#BA8D68"
      />
      <path
        d="M23.99 39.98l1.07.516c.332.16.692-.16.57-.507l-.553-1.583a.414.414 0 00-.762-.044l-.517 1.068a.412.412 0 00.192.55"
        fill="#BA8D68"
      />
      <path fill="#4D4D4D" d="M15.073 60.615l4.686-1.217 1.763 6.761-4.686 1.217z" />
      <path fill="#666" d="M26.14 74.419l3.202 1.645 5.25-10.025-3.603-1.477z" />
      <path
        d="M34.701 65.793a2.406 2.406 0 00-1.508-3.053l-6.834-2.237-1.508 4.603 7.14 1.806c1.26.428 2.282.14 2.71-1.119"
        fill="#666"
      />
      <path
        d="M29.758 74.454c-2.691.001-3.252.918-3.54 2.097l8.297-.005c-.29-1.179-2.065-2.094-4.757-2.092"
        fill="#DF662C"
      />
      <path fill="#DF662C" d="M26.23 76.536l3.996-.003-.002-2.085-3.996.003z" />
      <path fill="#B05026" d="M26.23 77.04l8.278-.006v-.493l-8.278.005z" />
      <path
        d="M17.556 82.228c-1.892-1.91-2.94-1.665-3.984-1.042l5.833 5.89c.636-1.034.042-2.938-1.85-4.848"
        fill="#DF662C"
      />
      <path fill="#DF662C" d="M13.592 81.184l2.809 2.837 1.486-1.466-2.809-2.837z" />
      <path fill="#B05026" d="M13.233 81.538l5.818 5.876.352-.347-5.818-5.876z" />
      <path
        d="M15.14 59.364a2.427 2.427 0 013.062-1.542l8.171 2.684-1.516 4.598-7.891-1.977c-1.273-.418-2.245-2.493-1.827-3.763"
        fill="#666"
      />
      <path fill="#666" d="M15.98 60.853l7.284.693.276-3.331-8.321-.79-.204 2.456z" />
      <path
        d="M16.188 44.418l.16-.377c.33-.301.478-.351 1.145-.37l6.076.323c1.004.015 1.785.391 1.397 3.31L23.54 58.23l-8.315-.802.963-13.01z"
        fill="#F06D31"
      />
      <path
        d="M12.296 58.1c-.192-.273-.516-.377-.725-.232-.208.146-.221.485-.03.759l.76 1.091c.192.274.516.378.724.233.208-.145.222-.485.03-.759l-.76-1.091zm-1.978-5.515a1.462 1.462 0 001.902-.159l6.03-5.992a1.577 1.577 0 00-.06-2.267 1.585 1.585 0 00-2.23.087l-5.857 6.167c-.59.64-.49 1.652.215 2.164"
        fill="#BA8D68"
      />
      <path
        d="M11.006 61.757c.725-.023 1.297-.833 1.273-1.557l.512-8.758a1.553 1.553 0 00-1.604-1.5 1.552 1.552 0 00-1.497 1.695l.085 8.987c.065.69.536 1.155 1.23 1.133M21.748 45.23c-.96.33-2.252-.487-2.553-1.124-.053-.113-.073-.236-.101-.353l3.775.193c.04.98-.405 1.038-1.12 1.284"
        fill="#BA8D68"
      />
      <path
        d="M18.1 39.3c-.702-2.446.529-4.972 2.71-5.596 1.779-.509 3.805-.497 4.92 2.001a.722.722 0 01-.665 1.011l-2.696.009c-.422.11-1.647 1.098-1.98 1.79 0 0-1.383 1.94-.853 3.168 0 0-.769-.063-1.435-2.384"
        fill="#603917"
      />
      <path d="M19.448 39.086a.836.836 0 00.555 1.008l.424-1.615a.838.838 0 00-.979.607" fill="#BA8D68" />
      <path
        d="M13.654 50.988l4.596-4.554a1.577 1.577 0 00-.06-2.267 1.585 1.585 0 00-2.23.087l-4.513 4.752 2.207 1.982z"
        fill="#F06D31"
      />
      <path
        d="M54.426 25.231c.345.906-.028 1.889-.834 2.195-.806.306-1.74-.18-2.084-1.087l-1.283-3.364c-.345-.906.028-1.888.834-2.194.806-.306 1.74.18 2.085 1.086l1.282 3.364z"
        fill="#D5A36D"
      />
      <g transform="translate(10.63 60.502)">
        <mask id="prefix__b" fill="#fff">
          <use xlinkHref="#prefix__a" />
        </mask>
        <path
          stroke="#CCC"
          strokeWidth={2}
          mask="url(#prefix__b)"
          d="M68.562.002H51.46V9.09H34.358v9.089H17.256v9.089H.154"
        />
      </g>
      <path fill="#D5A36D" d="M64.07 56.093l3.636.37-.703-10.613-3.836-.519z" />
      <path fill="#CA9869" d="M47.682 61.05l2.576 2.048 4.843-9.827-3.52-1.504z" />
      <path fill="#F06D31" d="M48.13 41.374l7.193.352.12-3.288-8.217-.4-.097 2.478z" />
      <path
        d="M64.104 21.905a1.438 1.438 0 00-1.808-.509l-7.63 3.446a1.553 1.553 0 00-.73 2.11 1.559 1.559 0 002.086.688l7.532-3.667a1.432 1.432 0 00.55-2.068"
        fill="#D5A36D"
      />
      <path
        d="M65.695 12.947c-.694-.17-1.546.447-1.716 1.14l-2.617 8.167a1.53 1.53 0 102.992.634l2.397-8.351c.122-.672-.393-1.428-1.056-1.59"
        fill="#D5A36D"
      />
      <path
        d="M47.589 25.206l.14-.378c.31-.31.454-.367 1.108-.415l5.254.027c.949.127 1.58 1.956 1.524 3.191l-.171 10.822-8.212-.413.357-12.834z"
        fill="#5C5B5E"
      />
      <path
        d="M46.512 27.265c.024 1.501.957 2.704 2.083 2.687 1.128-.018 2.022-1.25 1.998-2.75-.023-1.502-.956-2.705-2.083-2.688-1.127.018-2.021 1.25-1.998 2.75m-7.916 8.99c-.01-.329-.22-.589-.47-.581-.25.007-.445.28-.435.608l.035 1.307c.01.329.22.589.47.582.25-.008.445-.28.435-.609l-.035-1.307z"
        fill="#D5A36D"
      />
      <path
        d="M47.212 41.185l3.993 11.476-.18.428 3.755.89c.321-.708.903-1.64.586-2.661L53.82 46.34l-2.04-6.568-4.568 1.413z"
        fill="#E16530"
      />
      <path
        d="M40.134 31.06a1.439 1.439 0 001.84.379l7.365-3.98a1.553 1.553 0 00.577-2.156 1.56 1.56 0 00-2.13-.539l-7.251 4.193a1.432 1.432 0 00-.4 2.103"
        fill="#D5A36D"
      />
      <path
        d="M35.557 38.569c.61.372 1.518.013 1.89-.596l5.157-6.933c.44-.72.213-1.66-.508-2.099a1.532 1.532 0 00-2.15.59l-4.789 7.442c-.32.603-.183 1.24.4 1.596m17.614-12.055c-.928.367-2.11-.417-2.64-1.751a3.802 3.802 0 01-.116-.343l3.72.02c.085.961-.27 1.8-.964 2.074"
        fill="#D5A36D"
      />
      <path
        d="M53.796 30.246c-.112.929.099 2.621.728 2.697.63.076 2.082-1.787 2.014-2.72-.075-1.035-1.252-5.323-1.88-5.399-.63-.075-.75 4.493-.862 5.422"
        fill="#5C5B5E"
      />
      <path
        d="M65.693 43.884l-7.401-2.692v-.002l-7.965-2.864a2.389 2.389 0 00-3.056 1.434 2.381 2.381 0 001.437 3.048l7.961 2.863-.001.004 6.717 1.9 2.308-3.691z"
        fill="#F06D31"
      />
      <path
        d="M50.058 62.278c-1.13-2.559-2.241-2.708-3.488-2.488l3.484 7.888c1.004-.77 1.134-2.842.004-5.4"
        fill="#666"
      />
      <path fill="#666" d="M46.59 59.795l1.678 3.8 1.99-.876-1.677-3.8z" />
      <path fill="#CBCACA" d="M46.11 60.007l3.475 7.87.47-.208-3.475-7.87z" />
      <path
        d="M67.714 56.082c-2.802.014-3.382.972-3.677 2.2l8.639-.045c-.308-1.225-2.16-2.17-4.962-2.155"
        fill="#666"
      />
      <path fill="#666" d="M64.05 58.266l4.16-.021-.01-2.171-4.16.022z" />
      <path fill="#CBCACA" d="M64.053 58.79l8.618-.044-.003-.514-8.617.045z" />
      <path d="M50.83 24.235l-.218.258s3.268.045 3.264.04l-.143-.178" fill="#D5A36D" />
      <path
        d="M67.003 45.85a2.134 2.134 0 01-2.135 2.131 2.133 2.133 0 01-2.136-2.131c0-1.177.956-2.131 2.136-2.131 1.179 0 2.135.954 2.135 2.131"
        fill="#F06D31"
      />
      <path fill="#F06D31" d="M67.147 48.014l-.154-2.37-3.813-.15.174 2.072.052.618z" />
      <path
        d="M48.642 15.71a3.577 3.577 0 00-1.372 3.321l.277 2.041a3.59 3.59 0 004.784 2.889 3.59 3.59 0 01-5.746-2.362l-.276-2.041a3.584 3.584 0 012.333-3.849"
        fill="#C29264"
      />
      <path
        d="M47.222 21.118l-.276-2.041a3.578 3.578 0 011.372-3.322 3.59 3.59 0 014.784 2.889l.277 2.04a3.578 3.578 0 01-1.373 3.323 3.59 3.59 0 01-4.784-2.889"
        fill="#D5A36D"
      />
      <path
        d="M48.575 21.81l-.869.782a.407.407 0 01-.679-.323l.085-1.648a.407.407 0 01.709-.251l.783.867a.405.405 0 01-.03.573"
        fill="#D5A36D"
      />
      <path
        d="M52.624 16.48l.01-.111a.761.761 0 01.171-.296 1.66 1.66 0 01.39-.327c.166-.104.358-.186.578-.254a3.017 3.017 0 011.53-.034c.556.128 1.159.43 1.625.949.23.255.427.557.554.88.135.32.192.658.208.97a2.79 2.79 0 01-.111.86 2.898 2.898 0 01-.297.661c-.128.18-.241.34-.373.46a2.43 2.43 0 01-.354.292c-.1.075-.201.121-.26.156-.061.034-.098.048-.098.048l-.04-.105c-.019-.065-.061-.166-.088-.278-.028-.114-.086-.253-.126-.397-.037-.144-.1-.297-.145-.451a4.503 4.503 0 00-.378-.82 4.275 4.275 0 00-.425-.535 3.927 3.927 0 00-.26-.272c-.094-.1-.207-.195-.323-.299-.235-.203-.5-.407-.753-.59a5.001 5.001 0 00-.683-.43c-.096-.032-.168-.082-.24-.08l-.112.002"
        fill="#333"
      />
      <path
        d="M53.631 17.169c-.187.312-.756.316-1.27.008-.514-.307-.779-.81-.591-1.121.187-.312.756-.316 1.27-.009.514.308.779.81.591 1.122"
        fill="#EE5C4B"
      />
      <path
        d="M53.951 19.546c-.012-2.504-1.873-4.552-4.105-4.541-2.04.01-4.192.722-4.159 4.296l4.438-.528c.428-.012.941.1 1.447.662 0 0 1.843 1.452 1.682 2.758 0 0 .708-.271.697-2.647"
        fill="#333"
      />
      <path d="M52.619 19.715a.822.822 0 01-.246 1.105l-.847-1.41a.826.826 0 011.093.305" fill="#D5A36D" />
      <path
        d="M36.162 35.358c-.328.069-.55.326-.498.574.052.248-1.003.745-.676.676l2.668-.621c.327-.069.55-.326.497-.574-.052-.248-.36-.393-.687-.324l-1.304.27z"
        fill="#BA8D68"
      />
      <path
        d="M66.552 19.937a.492.492 0 00.335-.61L62.004 2.692a.493.493 0 00-.946.277l4.883 16.634c.076.261.35.41.611.334"
        fill="#B04F25"
      />
      <path d="M69.151 3.167l-.245.065.245-.065" fill="#FE9A0B" />
      <path fill="#E16530" d="M73.393.801l-3.293.963 2.29 7.804 3.294-.962z" />
      <path fill="#D15E2B" d="M70.98.158l-.88 1.606 2.291 7.804.88-1.606z" />
      <g transform="translate(60.97 .03)">
        <mask id="prefix__d" fill="#fff">
          <use xlinkHref="#prefix__c" />
        </mask>
        <path fill="#F06D31" mask="url(#prefix__d)" d="M12.301 7.931l-9.899 2.894L.112 3.02 10.01.127z" />
      </g>
      <path fill="#913D20" d="M63.373 10.855l1.21.66-.274-.934z" />
      <path
        d="M63.816 15.222c-.21.253-.222.588-.03.746.194.159.52.081.73-.172l.835-1.008c.21-.254.222-.588.029-.747-.193-.158-.52-.08-.728.173l-.836 1.008z"
        fill="#D5A36D"
      />
      <path
        d="M67.28 4.487l3.162 3.07c.07.07.172.099.265.073l.334-.093c.206-.056.267-.328.11-.485L66.78 2.685a.268.268 0 00-.456.126l-1.49 5.992c-.053.215.14.416.346.359l.333-.092a.273.273 0 00.19-.2l1.122-4.257a.268.268 0 01.456-.126"
        fill="#FFF"
      />
      <path
        d="M68.127 7.53l.503.529a.274.274 0 00.266.073l.334-.093c.206-.057.267-.328.11-.485l-1.67-1.669a.268.268 0 00-.456.126l-.57 2.29c-.053.215.14.416.346.36l.333-.093a.272.272 0 00.19-.2l.158-.712a.268.268 0 01.456-.126"
        fill="#FFF"
      />
    </g>
  </svg>
);

export default StairGoalIcon;
