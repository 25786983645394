import React from 'react';
import { ASSETS_PATH } from '@health-activity-ui/shared';

interface Props {
  style?: any /* eslint-disable-line */;
}

export const TwinCitiesShowdownIcon = ({ style }: Props): React.ReactElement => (
  <img
    src={`${ASSETS_PATH}/img/challengesV2/cityChallenges/TwinCitiesShowdown.png`}
    alt={'Twin City Showdown Icon'}
    style={style}
  />
);

export default TwinCitiesShowdownIcon;
