import { BannerResponse } from '@health-activity-ui/shared';

export interface CommonState {
  responseBanners: BannerResponse[];
  bannerInstance?: BannerResponse;
  allFetchHasCompleted?: boolean;
}

export enum COMMON_ACTION_TYPES {
  ALL_FETCH_HAS_COMPLETED = 'ALL_FETCH_HAS_COMPLETED',
  ADD_BANNER_RESPONSE = 'ADD_BANNER_RESPONSE',
  FIND_BANNER_INSTANCE = 'FIND_BANNER_INSTANCE',
  REMOVE_BANNER_RESPONSE = 'REMOVE_BANNER_RESPONSE',
  RESET_BANNERS = 'RESET_BANNERS',
}
