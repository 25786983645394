import { detectLanguage } from './utils';

const filterDuplicates = (arr: string[]): string[] => {
  return arr.filter((el, index, self) => self.indexOf(el) === index);
};

const fixLowercaseProperties = (arr: string[]): string[] => {
  return arr.map((el) => {
    if (!el || el.indexOf('-') === -1 || el.toLowerCase() !== el) {
      return el;
    }

    const splitEl = el.split('-');
    return `${splitEl[0]}-${splitEl[1].toUpperCase()}`;
  });
};

/**
 * @function getUserLocales
 *
 * @description Retrieves the preferred language of the user from the navigator interface
 * and returns an array of the IETF language tag.
 *
 * @return {string[]}
 * */
export const getUserLocales = (): string[] => {
  let languageList = [];
  // Rally only supports spanish right now, and the
  // api depends on a locale that's either en-US or es-US
  detectLanguage() === 'es-US' && languageList.push('es-US');

  if (typeof window !== 'undefined') {
    const navigator = window.navigator as any; /* eslint-disable-line */

    if (window.navigator.languages) {
      // For Chrome, FF and Safari
      languageList = languageList.concat(window.navigator.languages);
    }
    if (window.navigator.language) {
      // For Chrome, FF and Safari
      languageList.push(window.navigator.language);
    }
    if (navigator.userLanguage) {
      // For IE
      languageList.push(navigator.userLanguage);
    }
    if (navigator.browserLanguage) {
      // For IE
      languageList.push(navigator.browserLanguage);
    }
    if (navigator.systemLanguage) {
      // For IE
      languageList.push(navigator.systemLanguage);
    }
  }

  languageList.push('en-US'); // Fallback

  return fixLowercaseProperties(filterDuplicates(languageList));
};

/**
 * @function getUserLocale
 *
 * @description Retrieves the preferred language of the user from the navigator interface
 * and returns the IETF language tag.
 *
 * @return {string}
 * */
export const getUserLocale = (): string => {
  return getUserLocales()[0];
};

export default getUserLocale;
