import React from 'react';
import { ASSETS_PATH } from '@health-activity-ui/shared';

interface Props {
  style?: any /* eslint-disable-line */;
}

export const DCDashIcon = ({ style }: Props): React.ReactElement => (
  <img src={`${ASSETS_PATH}/img/challengesV2/cityChallenges/DCDash.png`} alt={'DC Dash Icon'} style={style} />
);

export default DCDashIcon;
