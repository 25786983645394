import classnames from 'classnames';
import React, { SyntheticEvent } from 'react';
import AvatarGroupContainer from './avatar-group-container';
import { AvatarGroupProps, AvatarSize } from './avatar-group.models';

export const AvatarGroup = ({ avatars, avatarSize = AvatarSize.Base }: AvatarGroupProps): React.ReactElement => {
  const showLastIcon = avatars.length > 3;
  avatars = avatars.slice(0, 3);

  return (
    <AvatarGroupContainer className="is-flex is-justify-content-center has-rds-mr-8">
      <div className="avatar-group is-inline-flex is-align-items-center">
        {avatars.map((avatar) => (
          <span
            className={classnames('avatar', {
              'avatar-xs': avatarSize === AvatarSize.Xs,
              'avatar-sm': avatarSize === AvatarSize.Sm,
              'avatar-lg': avatarSize === AvatarSize.Lg,
              'avatar-xl': avatarSize === AvatarSize.Xl,
              'avatar-xxl': avatarSize === AvatarSize.Xxl,
            })}
            key={avatar}
          >
            <img
              src={avatar}
              alt=""
              className="avatar-image has-rds-bg-charcoal has-rds-radius-circle is-rds-border-1"
              onError={(e: SyntheticEvent<HTMLImageElement>): void => {
                e.currentTarget.src =
                  'https://rally-non-prod-cdn-int.werally.in/avatars/f69600c3-d54d-410b-8b9d-76a485659af2.png';
              }}
            />
          </span>
        ))}

        {showLastIcon && (
          <span
            className={classnames('avatar is-inline-block', {
              'avatar-xs': avatarSize === AvatarSize.Xs,
              'avatar-sm': avatarSize === AvatarSize.Sm,
              'avatar-lg': avatarSize === AvatarSize.Lg,
              'avatar-xl': avatarSize === AvatarSize.Xl,
              'avatar-xxl': avatarSize === AvatarSize.Xxl,
            })}
          >
            <img
              src="https://cdn.statically.io/gist/alexitaylor/e5880aec6f5a672fe8eb93db68f32afc/raw/5b3f5d1550d37a6d7cd5dde7b492af495e06393d/more-horizontal.svg"
              alt=""
              className="avatar-image has-rds-radius-circle"
            />
          </span>
        )}
      </div>
    </AvatarGroupContainer>
  );
};

export default AvatarGroup;
