import { Status } from '../constants/status';
import { ChallengeTeamType } from './challenge-team.models';
import { ChallengeMemberStats, ChallengeStatus, UniversalChallengeInstance } from './challenges.interface';
import { DevicePartner, IMissionResponse, MissionCategory, MissionType, MissionUnits } from './missions.interface';
import { ActivityType, ChallengeCategory } from './shared.interface';

export interface ActivityListState {
  activities: Array<ActivityMission | ActivityChallenge>;
  allActivities: Array<ActivityMission | ActivityChallenge>;
  isDeviceConnected: boolean;
  isManualCheckinSelected: boolean;
  status: Status;
  error: Error;
  showMoreActivities(callback?: (state: ActivityListState) => void): void;
  handleFilterActivities(filter: FILTER_STATUS): void;
  updateMissions(rsp?: IMissionResponse): void;
  updateChallenges(challengeInstance: UniversalChallengeInstance, challengeStats: ChallengeMemberStats): void;
  filterStatus: FILTER_STATUS;
  hasActivities: boolean;
  hasChallenges: boolean;
  hasMissions: boolean;
  paginateCount: number;
  // CONSTANT VALUE
  PAGINATE_AMOUNT: number;
  hasMoreActivities: boolean;
  deviceSettings: DeviceSettings;
}

export enum FILTER_STATUS {
  ALL = 'All Activities',
  MISSIONS = 'Missions',
  CHALLENGES = 'Challenges',
  COMMUNITIES = 'Communities',
  PROGRAMS = 'Programs',
}

export interface FilterPaginateActivitiesArgs {
  activities: Array<ActivityMission | ActivityChallenge>;
  allActivities: Array<ActivityMission | ActivityChallenge>;
  filterStatus: FILTER_STATUS;
  cursorEnd: number;
}

export interface FilterPaginateActivities {
  newActivities: Array<ActivityMission | ActivityChallenge>;
  hasMoreActivities: boolean;
}

export interface DeviceSettings {
  dataTypeSettings: [];
  programIds: [];
  manualClients: [];
}

export interface ActivityMission {
  missionId: string;
  instanceId: string;
  isChallenge: boolean;
  activityType: ActivityType;
  isMission: boolean;
  name: string;
  category: MissionCategory;
  imageSrc: string;
  successCriteriaWeekly: number;
  completedDays: number;
  missionsDetailsUrl: string;
  completedWeeks: number;
  totalWeeks: number;
  totalWeeklySuccessCriteria: number;
  checkInType: MissionType;
  isFocused: boolean;
  filters: string[];
  dailySuccessCriteria: number;
  dailyMax: number;
  dailyMin: number;
  deviceEnabled: boolean;
  unit: MissionUnits;
  currentDayAmount: number;
  currentDayNumberCheckIns: number;
  question: string;
  dataSource?: DevicePartner;
  lastDataUpdate?: Date | string;
}

export interface ActivityChallenge {
  challengeId: string;
  isChallenge: boolean;
  isMission: boolean;
  name: string;
  activityType: ActivityType;
  activityLabel: string;
  rank: number;
  milestone: number;
  currentDayTotal: number;
  unit: string;
  singular: string;
  logoUrl: string;
  endDate: Date;
  startDate: Date;
  status: ChallengeStatus;
  isInviteOnly: boolean;
  isTeam: boolean;
  teamId: string;
  total: number;
  average: number;
  isCityWalk: boolean;
  isUserCreated: boolean;
  category: ChallengeCategory;
  scoringMethod: string;
  dailyEntryCap: number;
  lastCheckIn: Date;
  isDualCheckinAllowed: boolean;
  allowManualCheckin: boolean;
  isDeviceOnly: boolean;
  challengeType: ChallengeTeamType;
}
