import React from 'react';

interface Props {
  width?: number;
  height?: number;
}

export const StressIcon = ({ width = 200, height = 165 }: Props): React.ReactElement => (
  <svg viewBox="0 0 200 165" width={width} height={height}>
    <ellipse fill="#E5E5E4" cx={99.6} cy={137.2} rx={39.5} ry={5.7} />
    <path fill="#231F20" d="M126.2 73.6c-12.3 0-22.2 9.9-22.2 22.2h22.2V73.6z" />
    <path fill="#36C8B5" d="M122.78 74.345l14.989-6.145 6.903 16.84-14.99 6.146z" />
    <circle transform="rotate(-67.289 156.083 70.465)" fill="#231F20" cx={156.1} cy={70.5} r={9} />
    <circle transform="rotate(-22.286 126.205 82.701)" fill="#36C8B5" cx={126.2} cy={82.7} r={9} />
    <path fill="#36C8B5" d="M73.8 73.6V137s10 3.4 26.2 3.4 26.3-3.4 26.3-3.4V73.6H73.8z" />
    <path fill="#36C8B5" d="M73.8 73.6C86 73.6 96 83.6 96 95.8H73.8V73.6z" />
    <circle fill="#E4BA90" cx={100} cy={62.4} r={21.2} />
    <path
      fill="#634909"
      d="M100 40.7c2.9 0 5.7.4 8.2 1.3 2.5.8 4.8 2 6.7 3.4s3.5 3.2 4.5 5.1c1.1 1.9 1.7 4 1.7 6.2s1.1 11.1 0 13c-1.1 1.9-4.3-2.3-6.2-.9-1.9 1.4-4.2 2.6-6.7 3.4-2.5.8-5.3 1.3-8.2 1.3-2.9 0-5.7-.4-8.2-1.3-2.5-.8-4.8-2-6.7-3.4s-5.2 2.8-6.2.9c-1.1-1.9 0-10.8 0-13 0-2.2.6-4.3 1.7-6.2 1.1-1.9 2.6-3.6 4.5-5.1 1.9-1.4 4.2-2.6 6.7-3.4 2.5-.9 5.3-1.3 8.2-1.3z"
    />
    <path fill="#E4BA90" d="M122.805 66.39l7.027-16.789 29.797 12.471-7.027 16.79z" />
    <circle transform="rotate(-67.289 126.297 57.997)" fill="#E4BA90" cx={126.3} cy={58} r={9} />
    <circle transform="rotate(-67.289 156.083 70.465)" fill="#E4BA90" cx={156.1} cy={70.5} r={9} />
    <path fill="#36C8B5" d="M55.162 84.92l6.903-16.84L77.24 74.3l-6.903 16.84z" />
    <circle transform="rotate(-22.711 43.909 70.466)" fill="#231F20" cx={43.9} cy={70.5} r={9} />
    <circle transform="rotate(-67.71 73.791 82.712)" fill="#36C8B5" cx={73.8} cy={82.7} r={9} />
    <path fill="#E4BA90" d="M40.463 62.12l29.796-12.471 7.027 16.79-29.796 12.47z" />
    <circle transform="rotate(-22.711 73.696 57.999)" fill="#E4BA90" cx={73.7} cy={58} r={9} />
    <circle transform="rotate(-22.711 43.909 70.466)" fill="#E4BA90" cx={43.9} cy={70.5} r={9} />
    <path
      fill="#E4BA90"
      d="M40.443 78.845l6.903-16.84 14.713 6.03-6.904 16.841zm97.302-10.718l14.898-6.106 6.903 16.84-14.897 6.107z"
    />
    <path
      fill="#9CDAEE"
      d="M76.1 36.2c1.9.8 3.6 1.3 3.7 1.3 0 0 1.5.5 1.8.2.3-.3-.2-1.8-.2-1.8 0-.1-.5-1.8-1.3-3.7-1.2-2.8-2.6-4.4-4.1-4.9-.9-.3-2.4 0-3.6 1.2S71 31.2 71.2 32c.5 1.6 2.1 3 4.9 4.2zm45.9-4.1c-.8 1.9-1.3 3.6-1.3 3.7 0 0-.5 1.5-.2 1.8.3.3 1.8-.2 1.8-.2.1 0 1.8-.5 3.7-1.3 2.8-1.2 4.4-2.6 4.9-4.1.3-.9 0-2.4-1.2-3.6-1.2-1.2-2.8-1.4-3.6-1.2-1.5.5-2.9 2.1-4.1 4.9zM97.6 31c.8 1.9 1.7 3.4 1.7 3.5 0 0 .8 1.4 1.2 1.4.4 0 1.2-1.4 1.2-1.4 0-.1 1-1.6 1.7-3.5 1.1-2.8 1.3-4.9.5-6.4-.4-.8-1.7-1.7-3.4-1.7s-2.9.9-3.4 1.7c-.8 1.5-.7 3.6.5 6.4z"
    />
    <path
      fill="#E4BA90"
      d="M129.6 55l-3.7-4.7c-1-1.3-.8-3.1.5-4.1 1.3-1 3.1-.8 4.1.5l3.7 4.7c1 1.3.8 3.1-.5 4.1-1.3.9-3.1.7-4.1-.5zm-63.1-3.1l3-5.1c.8-1.4 2.6-1.9 4-1.1 1.4.8 1.9 2.6 1.1 4l-3 5.1c-.8 1.4-2.6 1.9-4 1.1-1.4-.8-1.9-2.6-1.1-4z"
    />
  </svg>
);

export default StressIcon;
