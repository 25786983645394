import { PropsWithChildren } from '@health-activity-ui/shared';
import classnames from 'classnames';
import React from 'react';
import { Element, ElementProps } from '../../../../element/element';

// https://designsystem.int.werally.in/foundation/responsive-layout
export type ColumnSizes =
  | 'three-quarters'
  | 'two-thirds'
  | 'half'
  | 'one-third'
  | 'one-quarter'
  | 'full'
  | '0'
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | '11'
  | '12';

export interface ResponsiveSizes {
  size?: ColumnSizes;
  offset?: ColumnSizes;
}

export interface ColumnProps extends ElementProps, PropsWithChildren {
  // the size to render a single column to
  size?: ColumnSizes;

  // add horizontal space equivalent to a column size
  offset?: ColumnSizes;

  // size of a single column on mobile
  mobile?: ResponsiveSizes;

  // size of a single column on touch (all sizes before desktop)
  touch?: ResponsiveSizes;

  // size of a single column on tablet only
  tablet?: ResponsiveSizes;

  // size of a single column on tablet portrait only
  tabletPortrait?: ResponsiveSizes;

  // size of a single column on desktop only
  desktop?: ResponsiveSizes;

  // size of a single column on widescreen
  widescreen?: ResponsiveSizes;
}

// Generates a 'section' element that acts as a 'row' which should be a direct descendant of body / main.
export const Col = ({
  className = undefined,
  renderAs = 'div',
  domRef = undefined,
  children = null,
  size = undefined,
  offset = undefined,
  mobile = {
    size: undefined,
    offset: undefined,
  },
  touch = {
    size: undefined,
    offset: undefined,
  },
  tablet = {
    size: undefined,
    offset: undefined,
  },
  tabletPortrait = {
    size: undefined,
    offset: undefined,
  },
  desktop = {
    size: undefined,
    offset: undefined,
  },
  widescreen = {
    size: undefined,
    offset: undefined,
  },
  ...allProps
}: ColumnProps) => (
  <Element
    {...allProps}
    renderAs={renderAs}
    className={classnames(className, 'column', {
      [`is-${size}`]: size,
      [`is-${touch.size}-touch`]: touch.size,
      [`is-${mobile.size}-mobile`]: mobile.size,
      [`is-${tabletPortrait.size}-tablet-portrait`]: tabletPortrait.size,
      [`is-${tablet.size}-tablet`]: tablet.size,
      [`is-${desktop.size}-desktop`]: desktop.size,
      [`is-${widescreen.size}-widescreen`]: widescreen.size,
      [`is-offset-${offset}`]: offset,
      [`is-offset-${touch.offset}-touch`]: touch.offset,
      [`is-offset-${mobile.offset}-mobile`]: mobile.offset,
      [`is-offset-${tabletPortrait.offset}-tablet-portrait`]: tabletPortrait.offset,
      [`is-offset-${tablet.offset}-tablet`]: tablet.offset,
      [`is-offset-${desktop.offset}-desktop`]: desktop.offset,
      [`is-offset-${widescreen.offset}-widescreen`]: widescreen.offset,
    })}
  >
    {children}
  </Element>
);

export default Col;
