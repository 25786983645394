import React from 'react';
import { PropsWithChildren } from '@health-activity-ui/shared';

interface ErrorProps extends PropsWithChildren {
  errorMessage?: string;
}

// TODO Will need mocks for this
export const Error = ({ errorMessage = 'Error.' }: ErrorProps) => (
  <p className="has-rds-text-danger is-rds-h5">
    <span role="img" aria-label="Error" className="has-rds-mr-16">
      🚧
    </span>
    {errorMessage}
  </p>
);

export default Error;
