import { PropsWithChildren } from '@health-activity-ui/shared';
import classnames from 'classnames';
import React from 'react';
import { Element, ElementProps } from '../../element/element';

export interface LevelProps extends ElementProps, PropsWithChildren {
  // by default a level stacks verticall on mobile, if you want it to remain horizontal on mobile then set this to true
  isMobile?: boolean;
}

// Generates a 'level' class on a div element to act as a container for level items
export const Level = ({
  className = undefined,
  renderAs = 'div',
  children = null,
  isMobile = false,
  ...allProps
}: LevelProps) => (
  <Element
    {...allProps}
    renderAs={renderAs}
    className={classnames('level', className, {
      [`is-mobile`]: isMobile,
    })}
  >
    {children}
  </Element>
);

export default Level;
