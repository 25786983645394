import { DevicePartner, DevicePartnerDisplayName } from '@health-activity-ui/shared';

export const hasTrackingSetUp = (device: DevicePartner | DevicePartnerDisplayName) => {
  return ![
    DevicePartner.Manual,
    DevicePartner.NotProvided,
    DevicePartnerDisplayName.Manual,
    DevicePartnerDisplayName.NotProvided,
  ].includes(device);
};
