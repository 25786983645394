import { PropsWithChildren } from '@health-activity-ui/shared';
import classnames from 'classnames';
import React from 'react';
import { Element, ElementProps } from '../../element/element';

export interface ColumnsProps extends ElementProps, PropsWithChildren {
  // automatically 'snakes' columns to the next row
  isMultiline?: boolean;

  // vertically align a column
  isVcentered?: boolean;

  // horizontally center a group of columns
  isCentered?: boolean;

  // columns by default are only activated on tablet and higher, is-mobile makes columns not stack in mobile as well
  isMobile?: boolean;

  // columns display as flex for desktop and above and columns display as blocks for everything below desktop
  isDesktop?: boolean;

  // columns display as flex for tablet and above and columns display as blocks for everything below tablet
  isTablet?: boolean;

  // remove space between columns
  isGapless?: boolean;
}

// Generates a 'section' element that acts as a 'row' which should be a direct descendant of body / main.
export const Columns = ({
  className = undefined,
  renderAs = 'div',
  domRef = undefined,
  children = null,
  isMultiline = false,
  isVcentered = false,
  isCentered = false,
  isMobile = false,
  isDesktop = false,
  isTablet = false,
  isGapless = false,
  ...allProps
}: ColumnsProps) => (
  <Element
    {...allProps}
    renderAs={renderAs}
    className={classnames('columns', className, {
      'is-multiline': isMultiline,
      'is-vcentered': isVcentered,
      'is-centered': isCentered,
      'is-mobile': isMobile,
      'is-desktop': isDesktop,
      'is-tablet': isTablet,
      'is-gapless': isGapless,
    })}
  >
    {children}
  </Element>
);

export default Columns;
